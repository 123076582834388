<template>
	<div v-if="permisoVista('tickets-catalogs','r')">
		<v-tabs v-model="tab" background-color="transparent" grow centered>
			<v-tab
				@click="updatePurchasingAreas()"
			>
				Purchasing Areas
			</v-tab>
			<v-tab
				@click="updateProjects()"
			>
				Projects
			</v-tab>

			<v-tab
				@click="updatePurchaseClass()"
			>
				Purchase Classes
			</v-tab>

			<v-tab
				@click="updateGLAccounts()"
			>
				GL Accounts
			</v-tab>

			<v-tab
				@click="udpateCostCenter()"
			>
				Cost Centers
			</v-tab>

			<v-tabs-items v-model="tab" touchless>
				<v-tab-item>
					<PurchasingAreas ref="purchasingAreas" />
				</v-tab-item>

				<v-tab-item>
					<Projects ref="projects" />
				</v-tab-item>

				<v-tab-item>
					<PurchaseClasses ref="purchaseClasses" />
				</v-tab-item>

				<v-tab-item>
					<GLAccounts ref="glAccounts" />
				</v-tab-item>

				<v-tab-item>
					<CostCenters ref="costCenters" />
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>
	</div>
</template>

<script>
import CostCenters from './Catalogs/CostCenters.vue';
import GLAccounts from './Catalogs/GLAccounts.vue';
import Projects from './Catalogs/Projects.vue';
import PurchaseClasses from './Catalogs/PurchaseClasses.vue';
import PurchasingAreas from './Catalogs/PurchasingAreas.vue';

export default {
	components: {
		Projects,
		PurchaseClasses,
		GLAccounts,
		CostCenters,
		PurchasingAreas
	},
	data(){
		return {
			tab: 0,
		}
	},
	methods: {
		initialize() {
            this.updatePurchasingAreas();
		},
		updatePurchasingAreas(){
            if(typeof this.$refs.purchasingAreas === 'undefined') return;
            this.$refs.purchasingAreas.initialize();
        },
        updateProjects(){
            if(typeof this.$refs.projects === 'undefined') return;
            this.$refs.projects.initialize();
        },
		updatePurchaseClass(){
            if(typeof this.$refs.purchaseClasses === 'undefined') return;
            this.$refs.purchaseClasses.initialize();
        },
		updateGLAccounts(){
            if(typeof this.$refs.glAccounts === 'undefined') return;
            this.$refs.glAccounts.initialize();
        },
		udpateCostCenter(){
            if(typeof this.$refs.costCenters === 'undefined') return;
            this.$refs.costCenters.initialize();
        },
	},
};
</script>