<template>
  <v-col cols="12">
    <template>
      <v-row justify="center">
        <v-dialog v-model="eliminar" persistent max-width="500px" :key="eliminar">
          <v-card>
            <v-card-title class="headline">¿Está seguro que desea eliminar esta persona?</v-card-title>
            <v-card-text>Esta acción no se puede revertir y será permanente.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="eliminar = false; delItem = '';">Cancelar</v-btn>
              <v-btn color="red darken-1" text @click="eliminar = false; deleteItemDB()">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-dialog v-model="dialog" persistent max-width="600px" :key="dialog">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="8">
                <v-autocomplete
                  v-model="editedItem.idPuesto"
                  :items="puestos"
                  label="Puesto"
                  item-text="nombre"
                  item-value="idPuesto"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4" class="pb-0">
                <p class="mb-0">¿Colabora en mantenimiento?</p>
                <v-switch
                  v-model="editedItem.mantenimiento"
                  :label="editedItem.mantenimiento ? 'Si' : 'No'"
                  class="mt-0"
                  color="success"
                  hide-details
                ></v-switch>
              </v-col>
              <v-col justify="center" cols="12" sm="12" md="12">
                <v-autocomplete
                  v-model="editedItem.idTurno"
                  :items="turnos"
                  label="Turno"
                  item-text="nombre"
                  item-value="idTurno"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="editedItem.idDepartamento"
                  label="[Opcional] Departamento"
                  :items="departamentos"
                  :item-text="nombreDepto"
                  item-value="idDepartamento"
                  clearable
                  @change="onChangeDepartamento(editedItem)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="editedItem.idsSubdepartamentos"
                  label="[Opcional] Subdepartamentos"
                  :items="subdepartamentosFiltrados"
                  :item-text="nombreDepto"
                  item-value="idSubdepartamento"
                  clearable
                  multiple
                  chips
                  deletable-chips
                ></v-autocomplete>
              </v-col>
              <v-col justify="center" cols="6" sm="6" md="6">
                <v-text-field v-model="editedItem.codigo" label="Código de la Persona"></v-text-field>
              </v-col>
              <v-col justify="center" cols="6" sm="6" md="6">
                <v-text-field v-model="editedItem.codigoX5" label="Código en X5"></v-text-field>
              </v-col>
              <v-col justify="center" cols="6" sm="6" md="6">
                <v-text-field v-model="editedItem.nombre" label="Nombre de la Persona"></v-text-field>
              </v-col>
              <v-col justify="center" cols="6" sm="6" md="6">
                <v-text-field v-model="editedItem.paterno" label="Apellido Paterno de la Persona"></v-text-field>
              </v-col>
              <v-col justify="center" cols="6" sm="6" md="6">
                <v-text-field v-model="editedItem.materno" label="Apellido Materno de la Persona"></v-text-field>
              </v-col>
              <v-col justify="center" cols="6" sm="6" md="6">
                <v-text-field v-model="editedItem.rfc" label="RFC de la Persona"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.telefono1"
                  label="Teléfono 1"
                  :counter="editedItem.telefono1!=null"
                  maxlength="10"
                  clearable
                  :error-messages="errorTelefono1"
                  @focus="errorTelefono1=''"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.telefono2"
                  label="Teléfono 2"
                  :counter="editedItem.telefono2!=null"
                  maxlength="10"
                  clearable
                  :error-messages="errorTelefono2"
                  @focus="errorTelefono2=''"
                ></v-text-field>
              </v-col>
              <v-col justify="center" cols="6" sm="6" md="6" class="mb-4">
                <datePicker
                  v-model="editedItem.incorporacion"
                  label="Fecha de Incorporación"
                  format="YYYY-MM-DD"
                  clearable
                  maxToday
                ></datePicker>
              </v-col>
              <v-col justify="center" cols="12" sm="12" md="12" class="mb-4" v-if="editedItem.idPersona > 0">
                <datePicker
                  v-model="editedItem.fechaBaja"
                  label="Fecha de Baja"
                  format="YYYY-MM-DD"
                  clearable
                  maxToday
                ></datePicker>
              </v-col>
              <v-col justify="center" cols="12" sm="12" md="12" v-if="permisoVista('puestos','$')">
                <v-text-field type="number" min="0" v-model="editedItem.costoHora" label="Costo por Hora del Puesto" hide-details></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="editedItem.areas"
                  label="Áreas"
                  :items="areas"
                  item-text="nombre"
                  item-value="idArea"
                  hide-details
                  multiple
                  chips
                  deletable-chips
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col justify="center" cols="12" sm="12" md="12">
                <v-autocomplete
                  v-model="editedItem.habilidades"
                  :items="habilidades"
                  label="Habilidades"
                  item-text="nombre"
                  chips
                  multiple
                  item-value="idHabilidad"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <ul>
              <li class="red--text" v-for="ex in errores" :key="ex">{{ ex }}</li>
            </ul>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog=false; errores=[];">Cancelar</v-btn>
          <v-btn color="red darken-1" text @click="save()" :disabled="saving" :loading="saving">
            Guardar
            <template v-slot:loader>
              <v-progress-circular
                indeterminate
                :width="2"
                :size="24"
                color="red"
              ></v-progress-circular>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
			v-model="cargaMasiva"
			persistent
			max-width="40%"
		>
			<v-card>
				<v-card-title>
					Carga Masiva de Personas 
				</v-card-title>
				<template v-if="estadoCarga == 0">
					<v-card-text>
						<v-file-input
      			  v-model="ficheroCarga"
      			  label="Formato de Carga Masiva de Personas (.csv)"
      			  accept=".csv"
      			  counter
      			  show-size
      			  @change="cambioFicheroCarga"
      			></v-file-input>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn @click="cerrarCargaMasiva" text color="green">Cancelar</v-btn>
						<v-btn @click="realizarCargaMasiva" text color="red">Guardar</v-btn>
					</v-card-actions>
				</template>
				<template v-if="estadoCarga == 1">
					<v-card-text class="text-center">
						<v-progress-circular
							indeterminate
							size="80"
							width="6"
							color="primary"
						></v-progress-circular>
						<p class="text-h6">Cargando</p>
					</v-card-text>
				</template>
				<template v-if="estadoCarga == 2">
					<v-card-text class="text-center py-0">
						<v-col cols="12" md="12" sm="12">
              <template v-if="erroresCargaMasiva.length == 0">
                <v-icon
                  size="100"
                  color="success"
                >
                  mdi-check-circle
                </v-icon>
                <p class="text-h6">Carga Realizada Correctamente</p>
              </template>
              <template v-else>
                <p class="text-h6">Han ocurrido errores al realizar la carga masiva: </p>
                <p v-for="(item, index) in erroresCargaMasiva" :key="index">
                  <v-icon color="red">mdi-alert-circle-outline</v-icon> {{item}}
                </p>
              </template>
            </v-col>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
							<v-btn text color="green" @click="cerrarCargaMasiva">Aceptar</v-btn>
						<v-spacer></v-spacer>
					</v-card-actions>
				</template>
				<template v-if="estadoCarga == 3">
					<v-card-text class="text-center">
						<v-icon
							size="100"
							color="red"
						>
							mdi-close-circle-outline
						</v-icon>
						<div class="text-h6">Ha sucedido un Error. Intente nuevamente.</div>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
							<v-btn text color="green" @click="cerrarCargaMasiva">Aceptar</v-btn>
						<v-spacer></v-spacer>
					</v-card-actions>
				</template>

			</v-card>
		</v-dialog>
    

    <v-dialog
			v-model="actualizacionMasivaX5"
			persistent
			max-width="40%"
		>
			<v-card>
				<v-card-title>
					Actualización Masiva Id X5 
				</v-card-title>
				<template v-if="estadoActualizacion == 0">
					<v-card-text>
						<v-file-input
      			  v-model="ficheroActualizacion"
      			  label="Formato de Actualización Masiva (.csv)"
      			  accept=".csv"
      			  counter
      			  show-size
      			  @change="cambioFicheroActualizacion"
      			></v-file-input>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn @click="cerrarActualizacionMasiva" text color="green">Cancelar</v-btn>
						<v-btn @click="realizarActualizacionMasiva" text color="red">Guardar</v-btn>
					</v-card-actions>
				</template>
				<template v-if="estadoActualizacion == 1">
					<v-card-text class="text-center">
						<v-progress-circular
							indeterminate
							size="80"
							width="6"
							color="primary"
						></v-progress-circular>
						<p class="text-h6">Cargando</p>
					</v-card-text>
				</template>
				<template v-if="estadoActualizacion == 2">
					<v-card-text class="text-center py-0">
						<v-col cols="12" md="12" sm="12">
              <template v-if="erroresActualizacionMasiva.length == 0">
                <v-icon
                  size="100"
                  color="success"
                >
                  mdi-check-circle
                </v-icon>
                <p class="text-h6">Actualización Masiva Realizada Correctamente</p>
              </template>
              <template v-else>
                <p class="text-h6">Han ocurrido errores al realizar la actualizaciónn masiva: </p>
                <p v-for="(item, index) in erroresActualizacionMasiva" :key="index">
                  <v-icon color="red">mdi-alert-circle-outline</v-icon> {{item}}
                </p>
              </template>
            </v-col>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
							<v-btn text color="green" @click="cerrarActualizacionMasiva">Aceptar</v-btn>
						<v-spacer></v-spacer>
					</v-card-actions>
				</template>
				<template v-if="estadoActualizacion == 3">
					<v-card-text class="text-center">
						<v-icon
							size="100"
							color="red"
						>
							mdi-close-circle-outline
						</v-icon>
						<div class="text-h6">Ha sucedido un Error. Intente nuevamente.</div>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
							<v-btn text color="green" @click="cerrarActualizacionMasiva">Aceptar</v-btn>
						<v-spacer></v-spacer>
					</v-card-actions>
				</template>

			</v-card>
		</v-dialog>
    <v-row class="justify-space-between align-center">
      <v-col cols="4">
        <v-select
          v-model="filtros.fechaBaja"
          label="Estado"
          :items="estadosEmpleado"
          hide-details
          clearable
          @change="getPersonas()"
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
      </v-col>
    </v-row>
    <v-col cols="12" class="d-flex">
      <v-btn color="primary" dark class="mb-2 mr-auto" @click="dialog=true" v-if="permisoVista('personas','c')">Nueva Persona</v-btn>
      <download-btn
        color="primary"
        text
        @click="descargarFormatoCargaMasiva"
        label="Descargar Formato<br>de Carga Masiva"
      />
      <v-btn 
        color="primary" 
        text 
        @click="cargaMasiva = true"
      >
        Carga Masiva de<br>Personas
      </v-btn>
      <v-btn 
        color="primary" 
        text 
        @click="actualizacionMasivaX5 = true"
      >
        Actualización<br>Masiva ID X5
      </v-btn>
    </v-col>
    <v-data-table
      v-if="permisoVista('personas','r')"
      :headers="headersFiltrados"
      :items="personas"
      :search="search"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
    >
      <template v-slot:[`item.codigo`]="{ item }">
        {{item.codigo != null ? item.codigo : 'N/D' }}
      </template>
      <template v-slot:[`item.estado`]="{ item }">
        {{item.estado ? "Activo" : 'Inactivo' }}
      </template>
      <template v-slot:[`item.costoHora`]="{ item }">
        ${{item.costoHora.toFixed(2)}}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('personas','u')"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          edit
        </v-icon>
         </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('personas','d')"
          small
          @click="deleteItem(item)"
        >
          delete
        </v-icon>
         </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="alerta"
      top
      color="error"
    >
      Existe una Persona con el Mismo RFC
      <v-btn
        text
        @click="alerta = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
  </v-col>
  
</template>

<script>
import axios from "axios";
import datePicker from "@/components/DatePicker";
import { VueDatePicker } from "@mathieustan/vue-datepicker";
import DownloadBtn from "../../DownloadBtn.vue";
import moment from 'moment';
import { mapState } from 'vuex';

  export default {
    components: {
      datePicker: datePicker,
      DownloadBtn
    },
    data: () => ({
      departamentos: [],
      subdepartamentos: [],
      errorTelefono1: "",
      errorTelefono2: "",
      rules: {
        telefono: (value) => {
          const pattern = /^\d{10}$/
          return pattern.test(value);
        },
      },
      estadosEmpleado: [
        {
          text: "Activos",
          value: false
        },
        {
          text: "Inactivos",
          value: true
        }
      ],
      filtros: {
        fechaBaja: null,
      },
      areas: [],
      erroresCargaMasiva: [],
      estadoCarga: 0,
      ficheroCarga: null,
      cargaMasiva: false,
      saving: false,
      puestos: [],
      loadingPuesto: false,
      habilidades:[],
      loadingHabilidad: false,
      dialog: false,
      alerta: false,
      eliminar: false,
      search: '',
      headers: [
        {
          text: 'Estado',
          align: 'left',
          sortable: true,
          value: 'estado',
        },
        {
          text: 'Código',
          align: 'left',
          sortable: true,
          value: 'codigo',
        },
        {
          text: 'Nombre',
          align: 'left',
          sortable: true,
          value: 'nombre',
        },
        {
          text: 'Paterno',
          align: 'left',
          sortable: true,
          value: 'paterno',
        },
        {
          text: 'Materno',
          align: 'left',
          sortable: true,
          value: 'materno',
        },
        {
          text: 'Puesto',
          align: 'left',
          sortable: true,
          value: 'puesto',
        },
        {
          text: 'Turno',
          align: 'left',
          sortable: true,
          value: 'turno',
        },
        {
          text: 'RFC',
          align: 'left',
          sortable: true,
          value: 'rfc',
        },
        {
          text: 'Costo por Hora',
          align: 'center',
          sortable: true,
          value: 'costoHora',
        },
        { text: 'Acciones', value: 'action', sortable: false, width:'20%', align:'center' },
      ],
      loading: false,
      personas: [],
      delItem: '',
      action: 0,
      errores: [],
      editedIndex: -1,
      editedItem: {
        nombre: '',
        paterno:'',
        materno: '',
        rfc: '',
        idDepartamento: null,
        idsSubdepartamentos: null,
        codigo: null,
        idPuesto:-1,
        idTurno:-1,
        habilidades: [],
        costoHora:"0.00",
        telefono1: null,
        telefono2: null,
        incorporacion: null,
        fechaBaja: null,
        areas: [],
        mantenimiento: false,
        codigoX5: null
      },
      defaultItem: {
        nombre: '',
        paterno:'',
        materno: '',
        rfc: '',
        codigo: null,
        idDepartamento: null,
        idsSubdepartamentos: null,
        idPuesto:-1,
        idTurno:-1,
        habilidades: [],
        costoHora: "0.00",
        telefono1: null,
        telefono2: null,
        incorporacion: null,
        fechaBaja: null,
        areas: [],
        mantenimiento: false,
        codigoX5: null
      },
      loadingTurno:false,
      turnos:[],
      actualizacionMasivaX5: false,
      estadoActualizacion: 0,
      ficheroActualizacion: null,
      erroresActualizacionMasiva: []
    }),

    computed: {
      ...mapState(["token"]),
      headersFiltrados(){
        return this.permisoVista('personas','$')
          ? this.headers
          : this.headers.filter(head=>head.value!='costoHora')
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Nueva Persona' : 'Editar Persona'
      },
      subdepartamentosFiltrados(){
        return this.editedItem.idDepartamento == null
          ? []
          : this.subdepartamentos.filter( subdepto => subdepto.departamento.idDepartamento == this.editedItem.idDepartamento );
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      nombreDepto: ({ codigo, nombre }) => codigo ? `[${codigo}] - ${nombre}`.trim() : `${nombre}`.trim(),
      onChangeDepartamento(editedItem){
        this.$set(editedItem,'idsSubdepartamentos',null);
      },
      realizarCargaMasiva(){
        if (this.ficheroCarga == null) return;
        this.estadoCarga = 1;
        let formData = new FormData();
        formData.append("formfile", this.ficheroCarga);
        axios
          .post("/Personas/CargaMasivaOperadores", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then((response) => {
            this.erroresCargaMasiva = response.data;
            this.estadoCarga = 2;
            this.initialize();
          })
          .catch(error => {
            this.estadoCarga = 3;
            console.log(error);
          });
      },
      cerrarCargaMasiva(){
        this.cargaMasiva = false;
        this.estadoCarga = 0;
        this.ficheroCarga = null;
        this.erroresCargaMasiva = [];
      },
      cambioFicheroCarga(event) {
        if (typeof event === "undefined" || event == null)
          this.ficheroCarga = null;
        else this.ficheroCarga = event;
      },
      realizarActualizacionMasiva(){
        if (this.ficheroActualizacion == null) return;
        this.estadoActualizacion = 1;
        let formData = new FormData();
        formData.append("formfile", this.ficheroActualizacion);
        axios
          .post("/Personas/CargaMasivaCodigoX5", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then((response) => {
            this.erroresActualizacionMasiva= response.data;
            this.estadoActualizacion = 2;
            this.initialize();
          })
          .catch(error => {
            this.estadoActualizacion = 3;
            console.log(error);
          });
      },
      cerrarActualizacionMasiva(){
        this.actualizacionMasivaX5 = false;
        this.estadoActualizacion = 0;
        this.ficheroActualizacion = null;
        this.erroresActualizacionMasiva = [];
      },
      cambioFicheroActualizacion(event) {
        if (typeof event === "undefined" || event == null)
          this.ficheroActualizacion = null;
        else this.ficheroActualizacion = event;
      },
      descargarFormatoCargaMasiva(){
        var datetime = moment().format('YYYYMMDDHHmmss');
        this.$utils.axios.downloadFile('/Personas/PlantillaCargaMasivaOperadores?access_token='+this.token,'GET','formato-cargamasiva-operadores-'+datetime+'.csv','text/csv');
      },
      validFloat(value){
        return isNaN(parseFloat(value))?0.0:parseFloat(value);
      },
      initialize () {
        this.getPuestos();
        this.getHabiliades();
        this.getTurnos();
        this.getAreas();
        this.getPersonas();
        this.getDepartamentos();
        this.getSubdepartamentos();
      },
      getDepartamentos(){
        axios
          .get('/Departamento')
          .then( response => {
            this.departamentos = response.data;
          })
          .catch(console.log);
      },
      getSubdepartamentos(){
        axios
          .get('/Subdepartamento')
          .then( response => {
            this.subdepartamentos = response.data;
          })
          .catch(console.log);
      },
      getPersonas(){
        this.loading = true;
        axios
        .get("/Personas",{
          params: this.filtros
        })
        .then(response =>  {
          this.personas = response.data.map( persona => ({
            ...persona,
            telefono1: persona.telefono ? persona.telefono.split(',')[0] ? persona.telefono.split(',')[0] : null : null,
            telefono2: persona.telefono ? persona.telefono.split(',')[1] ? persona.telefono.split(',')[1] : null : null
          }));
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
      },
      getAreas(){
        axios
          .get('/Areas')
          .then( response => {
            this.areas = response.data.filter( area => area.idArea > 1 );
          })
          .catch( error => {
            console.log(error);
          })
      },
      getTurnos(){
        let me = this;
        me.loadingTurno = true;
        axios
        .get("/Turnos")
        .then(response =>  {
          me.turnos = response.data;
          me.loadingTurno = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      getPuestos(){
        let me = this;
        me.loadingPuesto = true;
        axios
        .get("/Puestos")
        .then(response =>  {
          me.puestos = response.data;
          me.loadingPuesto = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      getHabiliades(){
        let me = this;
        axios
        .get("/Habilidades")
        .then(response =>  {
          me.habilidades = response.data;
          me.loadingHabilidad = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      editItem (item) {
        this.action = 1;
        this.editedIndex = this.personas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.eliminar = true;
        this.delItem = item;
      },
      deleteItemDB(){
        let me = this;
        let item = this.delItem;
        this.delItem = '';
        axios
          .put("/Personas/Desactivar/" + item.idPersona)
          .then(response =>  {
            me.initialize();
          })
          .catch(error => {
            console.log(error);
          });
      },
      close () {
        this.action = 0,
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          this.editedItem.habilidades = [];
        }, 300)
        this.errorTelefono1 = "";
        this.errorTelefono2 = "";
      },
      makeValidation(string, min, max, msgError){
        if (string.length < min || string.length > max)
        {
            return msgError;
        }
        else return null;
      },
      validate(){
        this.errorTelefono1 = "";
        this.errorTelefono2 = "";
        this.errores =  [];

        if(this.editedItem.idPuesto <= 0) this.errores.push("Debe de seleccionar un puesto");

        var val = null;
        val = this.makeValidation(this.editedItem.nombre,2,100,"El Nombre debe tener al menos 2 caracteres y menos de 100.");
        if(val != null) this.errores.push(val);

        val = this.makeValidation(this.editedItem.paterno,2,100,"El Apellido Paterno debe tener al menos 2 caracteres y menos de 100.");
        if(val != null) this.errores.push(val);

        if(this.editedItem.rfc != null && this.editedItem.rfc != " " && this.editedItem.rfc.length > 0){
          val = this.makeValidation(this.editedItem.rfc,13,20,"El RFC debe tener minimo 13 caracteres y menos de 20.");
          if(val != null) this.errores.push(val);
        }

        if(this.editedItem.telefono1 != null){
          if(!this.rules.telefono(this.editedItem.telefono1)){
            this.errorTelefono1 = "Teléfono inválido";
            this.errores.push("El teléfono #1 introducido es inválido");
          }
        }

        if(this.editedItem.telefono2 != null){
          if(!this.rules.telefono(this.editedItem.telefono2)){
            this.errorTelefono2 = "Teléfono inválido";
            this.errores.push("El teléfono #2 introducido es inválido");
          }
        }

        if(this.errores.length == 0) return true;
        else return false;
      },
      save () {
        this.action = 0;
        if(this.validate()){
          const telefonos = [ this.editedItem.telefono1,this.editedItem.telefono2 ];

          let me = this;
          me.saving = true;
          if (this.editedIndex > -1)
          {
            //Código para editar
            axios
              .put("/Personas/Actualizar/"+me.personas[me.editedIndex].idPersona, {
                IdPuesto: me.editedItem.idPuesto,
                IdTurno: me.editedItem.idTurno,
                Nombre: me.editedItem.nombre,
                Paterno: me.editedItem.paterno,
                Materno: me.editedItem.materno,
                idDepartamento: this.editedItem.idDepartamento,
                idsSubdepartamentos: this.editedItem.idsSubdepartamentos,
                Codigo: (me.editedItem.codigo != null && me.editedItem.codigo != " " && me.editedItem.codigo.length > 0) ? me.editedItem.codigo : null,
                RFC: (me.editedItem.rfc != null && me.editedItem.rfc != " " && me.editedItem.rfc.length > 0) ? me.editedItem.rfc : null,
                Habilidades: me.editedItem.habilidades,
                CostoHora: me.validFloat(me.editedItem.costoHora),
                Incorporacion: moment(this.editedItem.incorporacion).format("YYYY-MM-DD"),
                fechaBaja: moment(this.editedItem.fechaBaja).format("YYYY-MM-DD"),
                idsAreas: this.editedItem.areas,
                telefono: telefonos.join(','),
                mantenimiento: this.editedItem.mantenimiento,
                codigoX5: this.editedItem.codigoX5
              })
              .then(response =>  {
                me.saving = false;
                me.close();
                me.initialize();
              })
              .catch(error => {
                me.saving = false;
                if (error.response) {
                  if(error.response.status == 409){
                    me.alerta = true;
                    me.errores.push("* Existe una Persona con el Mismo RFC o Código");
                  }
                }
              });
          }
          else
          {
            axios
              .post("/Personas/Crear", {
                IdPuesto: me.editedItem.idPuesto,
                IdTurno: me.editedItem.idTurno,
                Nombre: me.editedItem.nombre,
                Paterno: me.editedItem.paterno,
                Materno: me.editedItem.materno,
                idDepartamento: this.editedItem.idDepartamento,
                idsSubdepartamentos: this.editedItem.idsSubdepartamentos,
                Codigo: (me.editedItem.codigo != null && me.editedItem.codigo != " " && me.editedItem.codigo.length > 0) ? me.editedItem.codigo : null,
                RFC: (me.editedItem.rfc != null && me.editedItem.rfc != " " && me.editedItem.rfc.length > 0) ? me.editedItem.rfc : null,
                Habilidades: me.editedItem.habilidades,
                CostoHora: me.validFloat(me.editedItem.costoHora),
                Incorporacion: moment(this.editedItem.incorporacion).format("YYYY-MM-DD"),
                idsAreas: this.editedItem.areas,
                telefono: telefonos.join(','),
                mantenimiento: this.editedItem.mantenimiento,
                codigoX5: this.editedItem.codigoX5
              })
              .then(response =>  {
                me.saving = false;
                me.close();
                me.initialize();
              })
              .catch(error => {
                me.saving = false;
                if (error.response) {
                  if(error.response.status == 409){
                    me.alerta = true;
                    me.errores.push("Existe una Persona con el Mismo RFC o Código");
                  }
                }
              });
          }
        }
      },
    },
  }
</script>