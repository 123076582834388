<template>
  <div>
    <v-dialog v-model="dialogPorcentajeAvance" persistent max-width="40%">
      <v-card>
        <v-card-title> Porcentaje Mínimo de Avance </v-card-title>
        <v-card-text>
          <PorcentajeAvance
            :porcentajeItem="porcentajeItem"
            :key="`perc-${perkey}`"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
              color="success"
              text
              @click="cerrarDialog()"
              >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogOperacionDetalle" max-width="80%" persistent>
      <v-card>
        <v-card-title>Operaciones Realizadas</v-card-title>
        <v-card-text>
          <Operaciones 
            ref="oper"
            :modeDetalle="true"
            :idTrabajoOrden="detallesItem.idTrabajoOrden"
            :idArea="detallesItem.idArea"
            :key="`op-${opkey}`"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="success"
            @click="cerrarDialogOperacionDetalle()"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="detalles" persistent max-width="90%" :key="'d'+detalles.toString()">
      <v-card>
        <v-card-title v-if="!clavePersonalizada">Detalles de {{watchedItem.ot}}</v-card-title>
        <v-card-title v-else>Detalles de la ODT con Clave {{watchedItem.clave}}</v-card-title>
        <v-card-text>
          <v-data-table
            :items="watchedItem.articulos"
            :headers="headersDetalle"
          >
            <template v-slot:item="{ item }">
            <tr>
              <td class="text-center">{{item.nombre}}</td>
              <td :class="'text-center justify-center '+(item.faltantesMP.length == 0?'green  white--text':'yellow black--text')">
                <div v-if="item.faltantesMP.length == 0">
                  Disponible
                </div>
                <div v-else>
                  <v-tooltip bottom color="black" class="white--text">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">Insuficiente</span>
                    </template>
                    <span class="white--text text-left">
                      <ul>
                        <li v-for="(msg, idx) in item.faltantesMP" :key="'faltante'+item.idArticulo+'-'+idx">{{msg}}</li>
                      </ul>
                    </span>
                  </v-tooltip>
                </div>
              </td>
              <td :class="'text-center ' +colorAvance(item.porcentaje)">{{item.porcentaje.toFixed(2)}} %</td>
              <td class="text-center" v-if="permisoVista('listas','$')">${{item.precioUnitario.toFixed(2)}}</td>
              <td class="text-center">
                <div v-if="!!item.editarCantidad" class="d-flex align-center justify-space-between">
                  <v-text-field
                    :value="item.cantidad"
                    placeholder="Cantidad"
                    type="number"
                    hide-spin-buttons
                    class="mr-3 centered-input"
                    :class="!!item.errorNuevaCantidad && 'v-input--is-focused'"
                    @input="item.nuevaCantidad = $event"
                    :disabled="!!item.savingNuevaCantidadOTD"
                    :loading="!!item.savingNuevaCantidadOTD"
                    :error="!!item.errorNuevaCantidad"
                    @focus="resetErrorNuevaCantidad(item)"
                  ></v-text-field>
                  <div>
                    <v-tooltip color="black" class="white--text" bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on"
                          style="font-size: 1.125rem !important"
                          @click="guardarNuevaCantidadOTD(item)"
                          :disabled="!!item.savingNuevaCantidadOTD"
                          color="success"
                        >
                          mdi-content-save
                        </v-icon>
                      </template>
                      <span class="white--text">Guardar</span>
                    </v-tooltip>
                    <v-tooltip color="black" class="white--text" bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on"
                          @click="modoEditarCantidadOTD(item,false)"
                          style="font-size: 1.125rem !important"
                          :disabled="!!item.savingNuevaCantidadOTD"
                          color="error"
                        >
                          mdi-cancel
                        </v-icon>
                      </template>
                      <span class="white--text">Cancelar</span>
                    </v-tooltip>
                  </div>
                </div>
                <div v-if="!!!item.editarCantidad" class="d-flex align-center justify-center">
                  <p class="mb-0 mr-2">{{item.cantidad}}</p>
                  <v-tooltip color="black" class="white--text" bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on"
                        v-if="item.estadoProduccion == 0 && permisoVista('listas','u')"
                        @click="modoEditarCantidadOTD(item,true)"
                        style="font-size: 1.125rem !important"
                        color="primary"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span class="white--text">Editar</span>
                  </v-tooltip>
                </div>
              </td>
              <td class="text-center" v-if="permisoVista('listas','$')">${{item.costoEstimadoTotal.toFixed(2)}}</td>
              <td class="text-center" v-if="permisoVista('listas','$')">${{item.costoEstimado.toFixed(2)}}</td>
              <td class="text-center" v-if="permisoVista('listas','$')">${{(item.costoReal == null ? 0.0 : item.costoReal).toFixed(2)}}</td>
              <td class="text-center">{{item.fechaFinalizacion}}</td>
              <td class="text-center">
                <div v-for="(area,idx) in item.areas" :key="'a'+item.idTrabajoOrdenDetalle+'-'+idx" :class="colorAvance(area.porcentaje)">
                  <v-tooltip bottom color="black" class="white--text">
                    <template v-slot:activator="{ on }">
                      <span 
                        v-on="on" 
                        @mouseover="area.hover = true;" 
                        @mouseleave="area.hover = false;"
                        :class="area.hover && 'font-weight-black'"
                        style="cursor: pointer;"
                        @click="showDialogOperacionDetalle(item.idTrabajoOrden,area.idArea)"
                        >{{area.porcentaje.toFixed(2)}}% {{area.area}}</span
                      >
                    </template>
                    <span class="white--text text-left">
                      <ul>
                        <li v-for="(msg, idx) in area.piezas" :key="'avance-'+idx">{{msg}}</li>
                      </ul>
                    </span>
                  </v-tooltip>
                </div>
              </td>
              <td :class="'text-center '+colors2[item.estadoProduccion]+' '+textcolors2[item.estadoProduccion]">
                <div v-if="item.estadoProduccion == 0">
                  <v-icon small>mdi-pause</v-icon>No Iniciado
                  <br>
                  <a v-if="permisoVista('listas','c')" href="#" class="blue--text" @click="launchItemOTD(item)">
                    <v-icon class="blue--text">mdi-rocket</v-icon>
                    Iniciar Producción
                  </a>
                </div>
                <div v-if="item.estadoProduccion == 1"><v-icon color="white" small>mdi-hammer</v-icon>&nbsp;En Progreso</div>
                <div v-if="item.estadoProduccion == 2"><v-icon class="white--text" small>mdi-alert</v-icon>&nbsp;Pausado</div>
                <div v-if="item.estadoProduccion == 3"><v-icon class="white--text" small>mdi-check</v-icon>&nbsp;Completado</div>
              </td>
              <td class="text-center">
                <v-tooltip color="black" class="white--text" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on"
                      v-if="(item.estadoProduccion == 1 || item.estadoProduccion == 2) && permisoVista('listas','u')"
                      small
                      @click="pauseItemOTD(item)"
                    >
                      {{item.estadoProduccion == 1? 'mdi-pause':'mdi-play'}}
                    </v-icon>
                  </template>
                  <span class="white--text">{{item.estadoProduccion == 1? 'Pausar':'Reanudar'}}</span>
                </v-tooltip>
                <v-tooltip color="black" class="white--text" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on"
                      v-if="permisoVista('listas','u')"
                      small
                      @click="finishEarlyItemOTD(item)"
                    >
                      mdi-close-thick
                    </v-icon>
                  </template>
                  <span class="white--text">Finalizar Prematuramente</span>
                </v-tooltip>
                <v-tooltip color="black" class="white--text" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on"
                      v-if="permisoVista('listas','$')"
                      small
                      @click="desgloseCostosOTD(item)"
                    >
                      mdi-currency-usd
                    </v-icon>
                  </template>
                  <span class="white--text">Desglose de Costos</span>
                </v-tooltip>
                <v-tooltip color="black" class="white--text" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on"
                      v-if="permisoVista('listas','u')"
                      small
                      @click="showDialogPorcentajeAvance(item)"
                    >
                      mdi-percent
                    </v-icon>
                  </template>
                  <span class="white--text">Porcentajes Mínimos de Avance</span>
                </v-tooltip>
              </td>
            </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDetalles()">Cerrar</v-btn>
        </v-card-actions>
        <apex-chart-dialog
          ref="chartDetalles"
        ></apex-chart-dialog>
      </v-card>
    </v-dialog>
    <v-dialog v-model="launch" persistent max-width="800px" :key="'l'+launch.toString()">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text v-if="verificando" class="text-center">
          <h3>Verificando Materia Prima</h3>
          <v-progress-circular
            :size="75"
            color="green"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
        <v-card-text v-else class="text-center">
          <h3 v-if="!clavePersonalizada">¿Desea Iniciar la Producción de la {{launchedItem.ot}}?</h3>
          <h3 v-else>¿Desea Iniciar la Producción de la ODT con Clave {{launchedItem.clave}}?</h3>
          <!--
          /*
          0: Sin Iniciar
          1: Lanzando
          2: Error
          3: Lanzado
          */
          -->
          <v-row v-if="launchState == 0">
            <v-col v-if="faltaMP" cols="12" md="12" sm="12" class="red--text">
              <h4>Cantidad Insuficiente de Materia Prima para garantizar el cumplimiento de la ODT.</h4>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="green" @click="launchProduccion()">Si</v-btn>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="red" @click="closeLaunch()">No</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="launchState == 1 || verificando" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h4>Cargando</h4>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-progress-circular
                :size="75"
                color="green"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row v-if="launchState == 2" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h5>Se ha producido un Error. Intente una vez más.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="red" text @click="closeLaunch()">Cerrar</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="launchState == 21" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h5>Se ha producido un Error.</h5>
              <h6>Los siguientes artículos no tienen al menos una receta de producción</h6>
              <ul>
                <li class="red--text" v-for="(articulo,idx) in recetasFaltantes" :key="'articulo'+idx">{{articulo.codigo}} - {{articulo.nombre}}</li>
              </ul>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="red" text @click="closeLaunch()">Cerrar</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="launchState == 3" class="justify-center">
            <v-col class="text-center">
              <lottie :options="checkOptions" :height="200" :width="200"/>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <h5 v-if="!clavePersonalizada">La {{launchedItem.ot}} se ha agregado a Producción Correctamente.</h5>
              <h5 v-els>La ODT con Clave {{launchedItem.clave}} se ha agregado a Producción Correctamente.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="green" text @click="closeLaunch()">Aceptar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="pause" persistent max-width="800px" :key="'p'+pause.toString()">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-center">
          <h3 v-if="!clavePersonalizada">¿Desea {{pausedItem.estadoProduccion == 1 ?'Pausar':'Reanudar'}} la Producción de la ODT-{{pausedItem.idTrabajoOrden}}?</h3>
          <h3 v-else>¿Desea {{pausedItem.estadoProduccion == 1 ?'Pausar':'Reanudar'}} la Producción de la ODT con Clave {{pausedItem.clave}}?</h3>
          <!--
          /*
          0: Sin Iniciar
          1: Lanzando
          2: Error
          3: Lanzado
          */
          -->
          <v-row v-if="pauseState == 0" class="justify-center">
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="green" @click="launchPause()">Si</v-btn>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="red" @click="closePause()">No</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="pauseState == 1" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h4>Cargando</h4>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-progress-circular
                :size="75"
                color="green"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row v-if="pauseState == 2" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h5>Se ha producido un Error. Intente una vez más.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="red" text @click="closePause()">Cerrar</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="pauseState == 3" class="justify-center">
            <v-col class="text-center">
              <lottie :options="checkOptions" :height="200" :width="200"/>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <h5 v-if="!clavePersonalizada">La ODT-{{pausedItem.idTrabajoOrden}} se ha {{pausedItem.estadoProduccion == 1 ?'Pausado':'Reanudado'}} Correctamente.</h5>
              <h5 v-else>La ODT con Clave {{pausedItem.clave}} se ha {{pausedItem.estadoProduccion == 1 ?'Pausado':'Reanudado'}} Correctamente.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="green" text @click="closePause()">Aceptar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="launchOTD" persistent max-width="800px" :key="'l'+launch.toString()">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text v-if="verificando" class="text-center">
          <h3>Verificando Materia Prima</h3>
          <v-progress-circular
            :size="75"
            color="green"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
        <v-card-text v-else class="text-center">
          <h3 v-if="!clavePersonalizada">¿Desea Iniciar la Producción del Artículo {{launchedItemOTD.nombre}} de la {{watchedItem.ot}}?</h3>
          <h3 v-else>¿Desea Iniciar la Producción del Artículo {{launchedItemOTD.nombre}} de la ODT con Clave {{watchedItem.clave}}?</h3>
          <!--
          /*
          0: Sin Iniciar
          1: Lanzando
          2: Error
          3: Lanzado
          */
          -->
          <v-row v-if="launchState == 0">
            <v-col v-if="faltaMP" cols="12" md="12" sm="12" class="red--text">
              <h4>Cantidad Insuficiente de Materia Prima para garantizar el cumplimiento de la ODT.</h4>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="green" @click="launchProduccionOTD()">Si</v-btn>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="red" @click="closeLaunchOTD()">No</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="launchState == 1 || verificando" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h4>Cargando</h4>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-progress-circular
                :size="75"
                color="green"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row v-if="launchState == 2" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h5>Se ha producido un Error. Intente una vez más.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="red" text @click="closeLaunchOTD()">Cerrar</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="launchState == 21" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h5>Se ha producido un Error.</h5>
              <h6>Los siguientes artículos no tienen al menos una receta de producción</h6>
              <ul>
                <li class="red--text" v-for="(articulo,idx) in recetasFaltantes" :key="'articulo'+idx">{{articulo.codigo}} - {{articulo.nombre}}</li>
              </ul>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="red" text @click="closeLaunch()">Cerrar</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="launchState == 3" class="justify-center">
            <v-col class="text-center">
              <lottie :options="checkOptions" :height="200" :width="200"/>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <h5 v-if="!clavePersonalizada">La {{launchedItem.ot}} se ha agregado a Producción Correctamente.</h5>
              <h5 v-els>La ODT con Clave {{launchedItem.clave}} se ha agregado a Producción Correctamente.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="green" text @click="closeLaunchOTD()">Aceptar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="pauseOTD" persistent max-width="800px" :key="'p'+pause.toString()">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-center">
          <h3 v-if="!clavePersonalizada">¿Desea {{pausedItemOTD.estadoProduccion == 1 ?'Pausar':'Reanudar'}} la Producción del Artículo {{pausedItemOTD.nombre}} de la ODT-{{watchedItem.idTrabajoOrden}}?</h3>
          <h3 v-else>¿Desea {{pausedItemOTD.estadoProduccion == 1 ?'Pausar':'Reanudar'}} la Producción del Artículo {{pausedItemOTD.nombre}} de la ODT con Clave {{watchedItem.clave}}?</h3>
          <!--
          /*
          0: Sin Iniciar
          1: Lanzando
          2: Error
          3: Lanzado
          */
          -->
          <v-row v-if="pauseState == 0" class="justify-center">
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="green" @click="launchPauseOTD()">Si</v-btn>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="red" @click="closePauseOTD()">No</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="pauseState == 1" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h4>Cargando</h4>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-progress-circular
                :size="75"
                color="green"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row v-if="pauseState == 2" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h5>Se ha producido un Error. Intente una vez más.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="red" text @click="closePauseOTD()">Cerrar</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="pauseState == 3" class="justify-center">
            <v-col class="text-center">
              <lottie :options="checkOptions" :height="200" :width="200"/>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <h5 v-if="!clavePersonalizada">La próduccion del Artículo {{pausedItemOTD.nombre}} de La ODT-{{watchedItem.idTrabajoOrden}} se ha {{pausedItemOTD.estadoProduccion == 2 ?'Pausado':'Reanudado'}} Correctamente.</h5>
              <h5 v-else>La Producción del Artículo {{pausedItemOTD.nombre}} de la ODT con Clave {{watchedItem.clave}} se ha {{pausedItemOTD.estadoProduccion == 2 ?'Pausado':'Reanudado'}} Correctamente.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="green" text @click="closePauseOTD()">Aceptar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogFinishEarly" persistent max-width="800px" :key="'fe'+dialogFinishEarly.toString()">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-center">
          <h3 v-if="!clavePersonalizada">¿Desea Finalizar Prematuramente la Producción de la ODT-{{finishedEarlyItem.idTrabajoOrden}}?</h3>
          <h3 v-else>¿Desea Finalizar Prematuramente la Producción de la ODT con Clave {{finishedEarlyItem.clave}}?</h3>
          <!--
          /*
          0: Sin Iniciar
          1: Lanzando
          2: Error
          3: Lanzado
          */
          -->
          <v-row v-if="finishedEarlyState == 0" class="justify-center">
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="green" @click="launchFinishedEarly()">Si</v-btn>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="red" @click="closeFinishedEarly()">No</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="finishedEarlyState == 1" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h4>Cargando</h4>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-progress-circular
                :size="75"
                color="green"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row v-if="finishedEarlyState == 2" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h5>Se ha producido un Error. Intente una vez más.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="red" text @click="closeFinishedEarly()">Cerrar</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="finishedEarlyState == 3" class="justify-center">
            <v-col class="text-center">
              <lottie :options="checkOptions" :height="200" :width="200"/>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <h5 v-if="!clavePersonalizada">La próduccion de La ODT-{{finishedEarlyItem.idTrabajoOrden}} se ha Finalizado Prematuramente Correctamente.</h5>
              <h5 v-else>La Producción de la ODT con Clave {{finishedEarlyItem.clave}} se ha Finalizado Prematuramente Correctamente.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="green" text @click="closeFinishedEarly()">Aceptar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogFinishEarlyOTD" persistent max-width="800px" :key="'p'+dialogFinishEarlyOTD.toString()">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-center">
          <h3 v-if="!clavePersonalizada">¿Desea Finalizar Prematuramente la Producción del Artículo {{finishedEarlyItem.nombre}} de la ODT-{{OTItem.idTrabajoOrden}}?</h3>
          <h3 v-else>¿Desea Finalizar Prematuramente la Producción del Artículo {{finishedEarlyItem.nombre}} de la ODT con Clave {{OTItem.clave}}?</h3>
          <!--
          /*
          0: Sin Iniciar
          1: Lanzando
          2: Error
          3: Lanzado
          */
          -->
          <v-row v-if="finishedEarlyState == 0" class="justify-center">
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="green" @click="launchFinishedEarlyOTD()">Si</v-btn>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="red" @click="closeFinishedEarlyOTD()">No</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="finishedEarlyState == 1" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h4>Cargando</h4>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-progress-circular
                :size="75"
                color="green"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row v-if="finishedEarlyState == 2" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h5>Se ha producido un Error. Intente una vez más.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="red" text @click="closeFinishedEarlyOTD()">Cerrar</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="finishedEarlyState == 3" class="justify-center">
            <v-col class="text-center">
              <lottie :options="checkOptions" :height="200" :width="200"/>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <h5 v-if="!clavePersonalizada">La próduccion del Artículo {{finishedEarlyItem.nombre}} de La ODT-{{OTItem.idTrabajoOrden}} ha Finalizado Prematuramente Correctamente.</h5>
              <h5 v-else>La Producción del Artículo {{finishedEarlyItem.nombre}} de la ODT con Clave {{OTItem.clave}} ha Finalizado Prematuramente Correctamente.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="green" text @click="closeFinishedEarlyOTD(true)">Aceptar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <apex-chart-dialog
      ref="chartGlobal"
    ></apex-chart-dialog>
    <!-- <detalles
      :show="dialog"
      @close="closeDialog()"
    ></detalles> -->
    <v-dialog v-model="eliminar" persistent max-width="500px" :key="eliminar">
      <v-card>
        <v-card-title class="headline">¿Está seguro que desea eliminar esta ODT?</v-card-title>
        <v-card-text>Esta acción no se puede revertir y será permanente.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="eliminar = false; deletedItem = null;">Cancelar</v-btn>
          <v-btn color="red darken-1" text @click="eliminar = false; deleteItemDB()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row
      class="pa-0 ma-0"
      v-if="permisoVista('listas','r')"
    >
      <v-col :cols="nColsCard" :md="nColsCard" :sm="nColsCard">
        <v-card class="fill-height d-flex">
          <v-card-text class="text-center align-self-center">
            <div class="subtitle-1 font-weight-bold">
              # ODT Producción
            </div>
            <ICountUp
              v-if="!loadingEstadisticas"
              :delay="1000"
              :endVal="estadisticas.numOTS"
              class="subtitle-1 font-weight-medium"
            />
            <div v-else class="text-center">
              <v-progress-circular
                :size="25"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="nColsCard" :md="nColsCard" :sm="nColsCard" v-if="permisoVista('listas','$')">
        <v-card class="fill-height d-flex">
          <v-card-text class="text-center align-self-center">
            <div class="subtitle-1 font-weight-bold">
              $ Monto Estimado de la Producción
            </div>
            <ICountUp
              v-if="!loadingEstadisticas"
              :delay="1000"
              :endVal="estadisticas.montoTotalEstimadoProduccion"
              class="subtitle-1 font-weight-medium"
              :options="{
                decimalPlaces: 2,
                separator: ',',
                decimal: '.',
                prefix:'$'
              }"
            />
            <div v-else class="text-center">
              <v-progress-circular
                :size="25"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="nColsCard" :md="nColsCard" :sm="nColsCard" v-if="permisoVista('listas','$')">
        <v-card class="fill-height d-flex">
          <v-card-text class="text-center align-self-center">
            <div class="subtitle-1 font-weight-bold">
              $ Monto Real de la Producción
            </div>
            <ICountUp
              v-if="!loadingMontoReal"
              :delay="1000"
              :endVal="montoTotalRealProduccion"
              class="subtitle-1 font-weight-medium"
              :options="{
                decimalPlaces: 2,
                separator: ',',
                decimal: '.',
                prefix:'$'
              }"
            />
            <div v-else class="text-center">
              <v-progress-circular
                :size="25"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="nColsCard" :md="nColsCard" :sm="nColsCard">
        <v-card class="fill-height d-flex">
          <v-card-text class="text-center align-self-center">
            <div class="subtitle-1 font-weight-bold">
              % Total de Avance
            </div>
            <ICountUp
              v-if="!loadingEstadisticas"
              :delay="1000"
              :endVal="estadisticas.porcentajeTotalProduccion"
              class="subtitle-1 font-weight-medium"
              :options="{
                decimalPlaces: 2,
                decimal: '.',
                suffix: '%'
              }"
            />
            <div v-else class="text-center">
              <v-progress-circular
                :size="25"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <materias-primas-consumidas ref="rawMaterial" />
    <OrdenarAbiertas ref="ordenar" @reset="initialize" />
    <ModificarSubpasos 
      ref="modsubs"
      :idTrabajoOrden="detallesItem.idTrabajoOrden" 
      @cerrar="cerrarDialogModificarSubpasos()" 
    />
    <v-data-table
      v-if="permisoVista('listas','r')"
      :headers="headers"
      :items="trabajoOrdenes"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
      class="elevation-0"
      ref="ots"
      :server-items-length="abiertas_total"
			:items-per-page="abiertas_items_per_page"
			:page="abiertas_actual_page"
			@update:page="abiertas_update_page_action"
			@update:items-per-page="(ipp) => (abiertas_items_per_page = ipp)"
			:footer-props="{
				disableItemsPerPage: true,
				disablePagination: this.loading,
			}"
			:disable-sort="true"
			:disable-pagination="loading"
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <!-- <v-btn color="primary" dark class="mb-2" @click="dialog = true;" v-if="permisoVista('listas','c')">Nueva Orden de Trabajo</v-btn> -->
          <v-btn
            :disabled="loading"
            color="primary"
            class="mb-2"
            v-if="permisoVista('listas', 'u')"
            @click="mostrarDialogOrdenar()"
          >
            <v-icon left>mdi-sort</v-icon>Ordenar
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            @keyup="getTrabajoOrdenesOnEnterAction"
            @click:clear="clearSearchAction"
            @click:append="abiertas_update_page_action(1)"
            :clearable="true"
            :disabled="loading"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item="{ item }">
        <tr :key="itemKey(item)">
          <td class="text-center">
            {{clavePersonalizada?item.clave:item.ot}}
          </td>
          <td class="text-center">
            {{item.lineaComercial}}
          </td>
          <td class="text-center">
            {{item.cliente}}
          </td>
          <td class="text-center" v-if="permisoVista('listas','$')">
            $ {{item.costoEstimadoTotal.toFixed(2)}}
          </td>
          <td class="text-center" v-if="permisoVista('listas','$')">
            $ {{item.costoEstimado.toFixed(2)}}
          </td>
          <td class="text-center" v-if="permisoVista('listas','$')">
            $ {{item.costoReal.toFixed(2)}}
          </td>
          <td :class="'text-center ' +colorAvance(item.porcentajeCompletado)">
            {{item.porcentajeCompletado.toFixed(2)}}%
          </td>
          <td class="text-center">
            <div v-if="item.fechaFinalizacion != null">
              {{item.fechaFinalizacion}}
            </div>
            <div v-else>
              No Disponible
            </div>
          </td>
          <td class="text-center">
            {{item.fechaEntrega}}
          </td>
          <td :class="'text-center '+colors1[item.disponibilidadMP]+' '+textcolors1[item.disponibilidadMP]">
            <div v-if="item.disponibilidadMP == 0">
              <v-tooltip left color="black" class="white--text">
                <template v-slot:activator="{ on }">
                  <span v-on="on">Insuficiente</span>
                </template>
                <span class="white--text text-left">
                  <ul>
                    <li v-for="(msg, idx) in item.faltantesMP" :key="'faltante'+item.idArticulo+'-'+idx">{{msg}}</li>
                  </ul>
                </span>
              </v-tooltip>
            </div>
            <div v-else-if="item.disponibilidadMP == 1" class="white--text"> Disponible</div>
            <div v-else-if="item.disponibilidadMP == 2" class="white--text"> No Disponible</div>
            <div v-else>Cargando</div>
          </td>
          <td :class="'text-center '+colors2[item.estadoProduccion]+' '+textcolors2[item.estadoProduccion]">
            <div v-if="item.lanzando == true">
              <v-icon class="red--text">mdi-rocket-launch</v-icon>&nbsp;Lanzando a Producción
            </div>
            <div v-else-if="item.estadoProduccion == 0">
              <v-icon small>mdi-pause</v-icon>No Iniciado
              <br>
              <a v-if="permisoVista('listas','c')" href="#" class="blue--text" @click="launchItem(item)">
                <v-icon class="blue--text">mdi-rocket</v-icon>
                Iniciar Producción
              </a>
            </div>
            <div v-else-if="item.estadoProduccion == 1"><v-icon color="white" small>mdi-hammer</v-icon>&nbsp;En Progreso</div>
            <div v-else-if="item.estadoProduccion == 2"><v-icon class="white--text" small>mdi-alert</v-icon>&nbsp;Pausado</div>
            <div v-else-if="item.estadoProduccion == 3"><v-icon class="white--text" small>mdi-check</v-icon>&nbsp;Completado</div>
            <div v-else-if="item.estadoProduccion == 4"><v-icon class="white--text" small>mdi-circle-half-full</v-icon>&nbsp;Producción Parcial</div>
            
          </td>
          <td class="text-center">
            <v-tooltip bottom color="black" class="white--text">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"
                  v-if="(item.estadoProduccion == 1 || item.estadoProduccion == 2) && permisoVista('listas','u')"
                  small
                  @click="pauseItem(item)"
                >
                  {{item.estadoProduccion == 1? 'mdi-pause':'mdi-play'}}
                </v-icon>
              </template>
              <span class="white--text">{{item.estadoProduccion == 1? 'Pausar':'Reanudar'}}</span>
            </v-tooltip>
            &nbsp;
            <v-tooltip bottom color="black" class="white--text">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"
                   v-if="permisoVista('listas','u')"
                  small
                  @click="finishEarlyItem(item)"
                >
                  mdi-close-thick
                </v-icon>
              </template>
              <span class="white--text">Finalizar Prematuramente</span>
            </v-tooltip>
            &nbsp;
            <v-tooltip bottom color="black" class="white--text">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"
                  v-if="permisoVista('listas','r')"
                  small
                  @click="watchItem(item)"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span class="white--text">Ver detalles</span>
            </v-tooltip>
            &nbsp;
            <v-tooltip bottom color="black" class="white--text">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"
                  v-if="permisoVista('listas','d')"
                  small
                  @click="deleteItem(item)"
                >
                  delete
                </v-icon>
              </template>
              <span class="white--text">Eliminar</span>
            </v-tooltip>
            <v-tooltip color="black" class="white--text" bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"
                  v-if="permisoVista('listas','$')"
                  small
                  @click="desgloseCostosOT(item)"
                >
                  mdi-currency-usd
                </v-icon>
              </template>
              <span class="white--text">Desglose de Costos</span>
            </v-tooltip>
            <v-tooltip color="black" class="white--text" bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"
                  v-if="permisoVista('listas','u')"
                  small
                  @click="showModificarSubpasos(item)"
                >
                  mdi-shuffle-variant
                </v-icon>
              </template>
              <span class="white--text">Modificar Subpasos</span>
            </v-tooltip>
            <v-tooltip color="black" class="white--text" bottom v-if="item.estadoProduccion > 0">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"
                  v-if="permisoVista('listas','r')"
                  class="ml-1"
                  small
                  @click="showDialogMateriasPrimas(item)"
                >
                  mdi-warehouse
                </v-icon>
              </template>
              <span class="white--text">Materias Primas<br>Consumidas</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
      <template v-slot:footer="{ props }">
        <v-col cols="12" class="d-flex justify-end align-center border-footer">
          <p class="mb-0 mr-8" style="font-size: 12px !important">Filas por página:</p>
          <v-text-field
            :value="props.pagination.itemsPerPage"
            dense
            hide-details
            disabled
            class="centered-input footer-input mt-0 mr-10"
          ></v-text-field>
          <p class="mb-0 mr-10" style="font-size: 12px !important">
            {{props.pagination.itemsLength ? getPageText(props.pagination): '-'}}
          </p>
          <v-pagination
            v-model="abiertas_actual_page"
            :length="props.pagination.pageCount"
            :total-visible="7"
            :disabled="loading"
          ></v-pagination>
        </v-col>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="alerta"
      top
      color="error"
    >
      Existe una Área Activa con el mismo nombre
      <v-btn
        text
        @click="alerta = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import detalles from "./Abiertos/Detalles";
import { mapState } from 'vuex';
import Lottie from 'vue-lottie';
import * as checkData from '@/assets/check.json';
import {Howl, Howler} from 'howler';
import Sortable from 'sortablejs';
import ICountUp from 'vue-countup-v2';
import apexChartDialog from '@/components/ApexChartDialog';
import OrdenarAbiertas from './OrdenarAbiertas.vue';
import Operaciones from '../../Reportes/Operaciones.vue';
import PorcentajeAvance from './PorcentajeAvance.vue';
import ModificarSubpasos from './ModificarSubpasos.vue';
import MateriasPrimasConsumidas from './MateriasPrimasConsumidas.vue';

export default {
   components: {
      detalles: detalles,
      'lottie': Lottie,
      ICountUp,
      'apex-chart-dialog': apexChartDialog,
      OrdenarAbiertas,
      Operaciones,
      PorcentajeAvance,
      ModificarSubpasos,
      MateriasPrimasConsumidas
    },
    data: () => ({
      perkey: 0,
      porcentajeItem: -1,
      dialogPorcentajeAvance: false,
      detallesItem: {
        idTrabajoOrden: 0,
        idArea: 0,
      },
      defaultDetallesItem: {
        idTrabajoOrden: 0,
        idArea: 0,
      },
      opkey: 0,
      dialogOperacionDetalle: false,
      loadingMontoReal: false,
      montoTotalRealProduccion: 0.0,
      abiertas: [],
      //Paginacion abiertas y filtros (server side)
      abiertas_total: null,
      //abiertas_items_per_page: 10,
      abiertas_actual_page: 1,
      //
      itemKeys: new WeakMap(),
      currentItemKey: 0,
      launch: false,
      launchState: 0,
      launchedItem: {
        idTrabajoOrden: -1,
        disponibilidadProducto: 0,
        disponibilidadMP: 0,
        estadoProducion: 0,
        articulos:[],
      },
      colors1:['yellow','green','red'],
      textcolors1:[,'black--text','white--text'],
      colors2:['','orange','red','green','blue'],
      textcolors2:['','white--text','white--text','white--text','white--text'],
      expanded: [],
      checkOptions: {animationData: checkData.default, loop:false, speeed: 0.5},
      saving: false,
      dialog: false,
      alerta: false,
      eliminar: false,
      search: '',
      headers: [
        {
          text: 'ODT',
          align: 'center',
          sortable: false,
          value: 'ot',
        },
        {
          text: 'Línea Comercial',
          align: 'center',
          sortable: false,
          value: 'lineaComercial'
        },
        {
          text: 'Cliente',
          align: 'center',
          sortable: false,
          value: 'cliente'
        },
        {
          text: 'Costo Estimado Total',
          align: 'center',
          sortable: true,
          value: 'costoEstimadoTotal'
        },
        {
          text: 'Costo Estimado Actual',
          align: 'center',
          sortable: true,
          value: 'costoEstimado'
        },
        {
          text: 'Costo Real',
          align: 'center',
          sortable: false,
          value: 'costoReal'
        },
        {
          text: "% Completado",
          align: 'center',
          sortable: false,
          value: 'porcentajeCompletado'
        },
        {
          text: 'Fecha de Finalización',
          align: 'center',
          sortable: false,
          value: 'fecha',
        },
        {
          text: 'Fecha Compromiso',
          align: 'center',
          sortable: false,
          value: 'fechaEntrega'
        },
        {
          text: 'M.P.',
          align: 'center',
          sortable: false,
          value: 'disponibilidadMP'
        },
        {
          text: 'Producción',
          align: 'center',
          sortable: false,
          value: 'estadoProduccion'
        },
        { text: 'Acciones', value: 'action', sortable: false, align:'center'},
      ],
      headersDetalle: [
        {
          text: 'Artículo',
          align: 'center',
          sortable: true,
          value: 'nombre',
          width:'10%'
        },
        {
          text: 'M.P.',
          align: 'center',
          sortable: false,
          width:'10%'
        },
        {
          text: '% Completado',
          align:'center',
          sortable: true,
          value: 'porcentaje',
          width:'8%'
        },
        {
          text: 'Precio Unitario',
          align: 'center',
          sortable: true,
          value: 'precioUnitario',
          width:'8%'
        },
        {
          text: 'Cantidad',
          align: 'center',
          sortable: true,
          value: 'cantidad',
          width:'8%'
        },
        {
          text: 'Costo Estimado Total',
          align: 'center',
          sortable: true,
          value: 'costoEstimadoTotal',
          width:'10%'
        },
        {
          text: 'Costo Estimado',
          align: 'center',
          sortable: true,
          value: 'costoEstimado',
          width:'10%'
        },
        {
          text: 'Costo Real',
          align: 'center',
          sortable: true,
          value: 'costoReal',
          width:'10%'
        },
        {
          text: 'Fecha de Finalización',
          align: 'center',
          sortable: true,
          value: 'fechaFinalizacion',
          width:'8%'
        },
        {
          text: 'Áreas de Trabajo',
          align: 'center',
          sortable: true,
          value: 'areas'
        },
        {
          text: 'Producción',
          align: 'center',
          sortable: false,
          value: 'estadoProduccion'
        },
        {
          text: 'Acciones',
          align: 'center',
          sortable: false,
          value: 'actions'
        },
      ],
      headersDesglose:[
        {
          text: 'Concepto',
          align: 'center',
          sortable: true,
          value: 'concepto',
          width:'40%'
        },
        {
          text: 'Tiempo',
          align: 'center',
          sortable: true,
          value: 'tiempo',
          width:'20%'
        },
        {
          text: 'Cantidad',
          align: 'center',
          sortable: true,
          value: 'cantidad',
          width:'20%'
        },
        {
          text: 'Costo',
          align: 'rigth',
          sortable: true,
          value: 'costoTotal',
          width:'20%'
        },
      ],
      loading: false,
      deletedItem: {},
      defaultItem: {
        idTrabajoOrden: -1,
        disponibilidadProducto: 0,
        disponibilidadMP: 0,
        estadoProducion: 0,
        articulos:[],
        costosGrafica:{
          series:[]
        }
      },
      estadisticas:{
        numOTS: 0,
        montoTotalEstimadoProduccion: 0.0,
        porcentajeTotalProduccion: 0.0,
      },
      detalles: false,
      trabajoOrdenes: [],
      sound: null,
      verificando:true,
      faltaMP: false,
      pauseState: 0,
      pause:false,
      pausedItem: {
        idTrabajoOrden: -1,
        disponibilidadProducto: 0,
        disponibilidadMP: 0,
        estadoProducion: 0,
        articulos:[],
      },
      pauseOTD:false,
      launchOTD:false,
      launchedItemOTD:{
        areas: [],
        cantidad: 0,
        costo: 0,
        estadoProduccion: 0,
        faltantesMP: [],
        fechaFinalizacion: null,
        idArticulo: 0,
        idTrabajoOrdenDetalle: 0,
        montoTotal: 0,
        nombre: "",
        porcentaje: 0.0,
        precioUnitario: 0.0,
      },
      pausedItemOTD:{
        areas: [],
        cantidad: 0,
        costo: 0,
        estadoProduccion: 0,
        faltantesMP: [],
        fechaFinalizacion: null,
        idArticulo: 0,
        idTrabajoOrdenDetalle: 0,
        montoTotal: 0,
        nombre: "",
        porcentaje: 0.0,
        precioUnitario: 0.0,
      },
      defaultItemOTD:{
        areas: [],
        cantidad: 0,
        costo: 0,
        estadoProduccion: 0,
        faltantesMP: [],
        fechaFinalizacion: null,
        idArticulo: 0,
        idTrabajoOrdenDetalle: 0,
        montoTotal: 0,
        nombre: "",
        porcentaje: 0.0,
        precioUnitario: 0.0,
        desgloseCostosEstimados: null,
        desgloseCostosReales: null,
        costosGrafica:{
          series:[]
        }
      },
      posWachedItem: -1,
      posWatchedDetailItem: -1,
      desgloseOTDDialog: false,
      desgloseOTDialog:false,
      defaultChartOptions: {
        chart: {
          type: 'bar',
          height: 430
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
          }
        },
        colors: ['#008FFB', '#FF4560'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },
        
        grid: {
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        yaxis: {
          min: 0,
          max: 0,
          title: {
            // text: 'Age',
          },
        },
        tooltip: {
          shared: false,
          x: {
            formatter: function (val) {
              return val
            }
          },
          y: {
            formatter: function (val) {
              return "$ " + Math.abs(val).toFixed(2);
            }
          }
        },
        xaxis: {
          categories: [],
          title: {
            text: 'Costo'
          },
          labels: {
            formatter: function (val) {
              return "$ " + Math.abs(Math.round(val)).toFixed(2);
            }
          }
        },
      },
      recetasFaltantes:[],
      finishedEarlyItem: {
        idTrabajoOrden: -1,
        clave: "",
      },
      defaultFinishedEarlyItem: {
        idTrabajoOrden: -1,
        clave: "",
      },
      dialogFinishEarly: false,
      finishedEarlyState: 0,
      dialogFinishEarlyOTD:false,
      OTItem: {
        idTrabajoOrden: -1,
        disponibilidadProducto: 0,
        disponibilidadMP: 0,
        estadoProducion: 0,
        articulos:[],
        costosGrafica:{
          series:[]
        }
      },
      loadingEstadisticas: false,
    }),
    computed: {
      abiertas_fetch_url() {
        let url = `/TrabajoOrdenes?paginar=true&Page=${this.abiertas_actual_page}&Size=${this.abiertas_items_per_page}`;
        if(this.search.length > 0) url += ("&codigo="+this.search);
        return url;
      },
      watchedItem(){
        if(this.posWachedItem >= 0 && this.$utils.isValid(this.trabajoOrdenes[this.posWachedItem])){
          var response = JSON.parse(JSON.stringify(this.trabajoOrdenes[this.posWachedItem]));
          return response;
        }
        else {
          var response = Object.assign({}, this.defaultItem);
          response.articulos = [];
          response.chartOptions = Object.assign({},this.defaultChartOptions);
          return response;
        }
      },
      watchedDetailItem(){
        if(this.posWatchedDetailItem >= 0 && this.$utils.isValid(this.watchedItem.articulos[this.posWatchedDetailItem])){
          var response = Object.assign({},this.watchedItem.articulos[this.posWatchedDetailItem]);
          return response;
        }
        else {
          var response = Object.assign({}, this.defaultItemOTD);
          response.chartOptions = Object.assign({},this.defaultChartOptions);
          return response;
        }
      },
      nColsCard(){
        return this.permisoVista('listas','$')?3:6;
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Nueva Orden de Trabajo' : 'Editar Orden de Trabajo'
      },
      numOTS(){
        return this.trabajoOrdenes.filter(ot => ot.estadoProduccion == 1).length;
      },
      montoTotalProduccion(){
        var montoTotal = 0;
        this.trabajoOrdenes
          .filter(ot => ot.estadoProduccion == 1 || ot.estadoProduccion == 3)
          .forEach(ot => {
            montoTotal += ot.costoEstimadoTotal;
          });
        return montoTotal;
      },
      avanceTotal(){
        var porcentajeTotal = 0;
        this.trabajoOrdenes
          .filter(ot => ot.estadoProduccion == 1)
          .forEach(ot => {
            porcentajeTotal += ot.porcentajeCompletado;
          });
        
        if(isNaN(porcentajeTotal / this.numOTS)) return 0;
        else return porcentajeTotal / this.numOTS;
      },
      ...mapState({
        nombreEmpresa: 'nombreEmpresa',
        clavePersonalizada: 'clavePersonalizada',
        abiertas_items_per_page: 'numOTS',
      }),
    },

    // mounted(){
    //   if(this.permisoVista('listas','u')){
    //     new Sortable(
    //       this.$refs.ots.$el.getElementsByTagName('tbody')[0],
    //       {
    //         draggable: '.sortableRow',
    //         handle: '.sortHandle',
    //         onEnd: this.dragReorder
    //       }
    //     )
    //   }
    // },

    created () {
      // if(this.clavePersonalizada){
      //   this.headers[1].text = "Clave";
      //   this.headers[1].value = 'clave';
      // }
      //this.initialize();

      if(!this.permisoVista('listas','$')){
        this.headers = this.headers.filter(element => {
          return element.value != 'costoEstimado' && element.value != 'costoReal' && element.value != 'costoEstimadoTotal';
        });

        this.headersDetalle = this.headersDetalle.filter(element => {
          return element.value != 'costoReal' && element.value != 'costoEstimado' && element.value != 'costoEstimadoTotal' && element.value != 'precioUnitario' && element.value != 'montoTotal';
        });
      }
      this.getTrabajoOrdenesEstadisticas();
      this.getMontoTotalRealProduccion();
    },

    methods: {
      resetErrorNuevaCantidad(item){
        this.$set(item,'errorNuevaCantidad',false);
      },
      validarNuevaCantidadOTD(item){
        this.$set(item,'errorNuevaCantidad',false);

        if(
          isNaN(parseFloat(item.nuevaCantidad)) ||
          parseFloat(item.nuevaCantidad) <= 0
        ){
          this.$set(item,'errorNuevaCantidad',true);
        }

        return !item.errorNuevaCantidad;
      },
      guardarNuevaCantidadOTD(item){
        if(item.nuevaCantidad === undefined){
          this.modoEditarCantidadOTD(item,false);
          return;
        }
        if(!this.validarNuevaCantidadOTD(item)) return;

        this.$set(item,'savingNuevaCantidadOTD',true);

        axios
          .put('/TrabajoOrdenes/ModificarCantidadOTD',{
            idTrabajoOrdenDetalle: item.idTrabajoOrdenDetalle,
            cantidad: parseFloat(item.nuevaCantidad)
          })
          .then( () => {
            this.$set(item,'cantidad',parseFloat(item.nuevaCantidad));
            this.$set(item,'savingNuevaCantidadOTD',false);
            this.modoEditarCantidadOTD(item,false);
            this.initialize();
          })
          .catch( error => {
            this.$set(item,'savingNuevaCantidadOTD',false);
            console.log(error);
          });
      },
      modoEditarCantidadOTD(item,value){
        this.$set(item,'editarCantidad',value);
        this.$set(item,'errorNuevaCantidad',false);
      },
      showDialogMateriasPrimas(item){
        this.$refs.rawMaterial.showDialog();
        this.$nextTick( () => {
          this.$refs.rawMaterial.initialize(item);
        });
      },
      getPageText: ({ pageStart, pageStop, itemsLength }) => `${pageStart+1}-${pageStop} de ${itemsLength}`,
      showModificarSubpasos(item){
        this.detallesItem.idTrabajoOrden = item.idTrabajoOrden;
        if(this.$refs.modsubs != undefined){
          this.$nextTick( () => {
            this.$refs.modsubs.showDialog();
          });
        }
      },
      cerrarDialogModificarSubpasos(){
        this.detallesItem.idTrabajoOrden = 0;
      },
      cerrarDialog(){
        this.dialogPorcentajeAvance = false;
        this.porcentajeItem = -1;
      },
      showDialogPorcentajeAvance(item){
        this.porcentajeItem = Object.assign({},item);
        this.dialogPorcentajeAvance = true;
        this.perkey++;
      },
      showDialogOperacionDetalle(idTrabajoOrden, idArea){
        this.detallesItem = Object.assign({},{
          idTrabajoOrden,
          idArea
        });
        this.dialogOperacionDetalle = true;
        this.opkey++;
      },
      cerrarDialogOperacionDetalle(){
        this.dialogOperacionDetalle = false;
        this.detallesItem = Object.assign({},this.defaultDetallesItem);
      },
      getMontoTotalRealProduccion(){
        this.loadingMontoReal = true;
        axios
        .get("/TrabajoOrdenes/MontoTotalProduccion")
        .then(response => {
          this.loadingMontoReal = false;
          this.montoTotalRealProduccion = response.data;
        })
        .catch(error => {
          this.loadingMontoReal = false;
          console.log(error);
        })
      },
      getTrabajoOrdenesEstadisticas(){
        this.loadingEstadisticas = true;
        axios
        .get("/TrabajoOrdenes/Estadisticas")
        .then(response => {
          this.loadingEstadisticas = false;
          this.estadisticas = response.data;
        })
        .catch(error => {
          this.loadingEstadisticas = false;
          console.log(error);
        })
      },
      mostrarDialogOrdenar(){
        this.$refs.ordenar.mostrarDialog();
      },
      colorAvance(porcentaje){
        if(porcentaje <= 0.0)
          return 'red--text';
        else if(porcentaje < 100.0)
          return 'orange--text';
        else
          return 'green--text';
      },
      dragReorder ({oldIndex, newIndex}) {
        const movedItem = this.trabajoOrdenes.splice(oldIndex, 1)[0]
        this.trabajoOrdenes.splice(newIndex, 0, movedItem)
      
        var ids = this.trabajoOrdenes.map(element => {
          return element.idTrabajoOrden;
        });

        axios
        .put("/TrabajoOrdenes/CambiarPrioridad",ids)
        .then(() => {
          this.getTiempoFinalizacion();
        })
        .catch(error => {
          console.log(error);
        })
      },
      itemKey (item) {
        if (!this.itemKeys.has(item)) this.itemKeys.set(item, ++this.currentItemKey)
        return this.itemKeys.get(item)
      },
      desgloseCostosOT(item){
        this.$refs.chartGlobal.show("Desglose de Costos - "+item.clave,item.costosGrafica.limits,item.costosGrafica.categories,item.costosGrafica.series);;
      },
      desgloseCostosOTD(item){
        this.posWatchedDetailItem = this.watchedItem.articulos.indexOf(item);
        this.$refs.chartDetalles.show("Desglose de Costos - "+item.nombre+" - "+(this.clavePersonalizada ? this.watchedItem.clave : this.watchedItem.ot),item.costosGrafica.limits,item.costosGrafica.categories,item.costosGrafica.series);
      },
      cerrarOTDesglose(){
        this.desgloseOTDialog = false;
        this.posWachedItem = -1;
      },
      cerrarOTDDesglose(){
        this.desgloseOTDDialog = false;
        this.posWatchedDetailItem = -1;
      },
      cambio(event){
        var orden = event.toLowerCase().split('-');
        if(orden[0] == 'avance'){
          axios
            .get("/TrabajoOrdenes/PorcentajeCompletado/"+orden[1])
            .then(response => {
              var ot = this.trabajoOrdenes.find(element => element.idTrabajoOrden == parseInt(orden[1]));
              if(typeof ot != 'undefined'){
                var index = this.trabajoOrdenes.indexOf(ot);
                this.trabajoOrdenes[index].porcentajeCompletado = parseFloat(response.data.porcentaje);

                response.data.detalles.forEach(detalle =>{
                  var pos = this.trabajoOrdenes[index].articulos.findIndex(articulo => articulo.idTrabajoOrdenDetalle == detalle.idTrabajoOrdenDetalle);
                  this.trabajoOrdenes[index].articulos[pos].porcentaje = detalle.porcentaje;
                  this.trabajoOrdenes[index].articulos[pos].areas = detalle.areas;
                });
              
                if(!this.clavePersonalizada){
                  this.$notify({
                    group: 'produccion2',
                    title: 'Avance en Progreso de Orden de Trabajo',
                    text: 'Ha avanzado el progreso de la ODT-'+orden[1]+', ahora es del '+parseFloat(response.data.porcentaje).toFixed(2)+'%',
                    type: 'info',
                  });
                }
                else{
                  this.$notify({
                    group: 'produccion2',
                    title: 'Avance en Progreso de Orden de Trabajo',
                    text: 'Ha avanzado el progreso de la ODT con Clave '+this.trabajoOrdenes[index].clave+', ahora es del '+parseFloat(response.data.porcentaje).toFixed(2)+'%',
                    type: 'info',
                  });
                }
              }
            })
            .catch(error => {
              console.log(error);
            });
          this.getTiempoFinalizacion();
          
        }
        if(orden[0] == 'completo'){
          var ot = this.trabajoOrdenes.find(element => element.idTrabajoOrden == parseInt(orden[1]));

          if(ot != null){
            if(!this.clavePersonalizada){
              this.$notify({
                group: 'produccion',
                title: 'Orden de Trabajo Terminada',
                text: 'Se ha completado satisfactoriamente la ODT-'+orden[1],
                type: 'success',
              });
            }
            else{
              this.$notify({
                group: 'produccion',
                title: 'Orden de Trabajo Terminada',
                text: 'Se ha completado satisfactoriamente la ODT con Clave '+ot.clave,
                type: 'success',
              });
            }
            this.sound.play();
          }
          
          this.initialize();
        }
        if(orden[0] == 'otd' && orden[1] == 'terminada'){
          var idxOT = this.trabajoOrdenes.findIndex(element => element.idTrabajoOrden == parseInt(orden[2]));

          if(idxOT >= 0){
            var idxOTD = this.trabajoOrdenes[idxOT].articulos.findIndex(element => element.idTrabajoOrdenDetalle == parseInt(orden[3]));
            if(idxOTD >= 0) this.trabajoOrdenes[idxOT].articulos[idxOTD].estadoProducion = 3;
          }
        }
      },
      getAbiertas() {
        //* Realizamos peticion HTTP
        return new Promise((resolve, reject) => {
          axios
            .get(this.abiertas_fetch_url)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      },
      async initialize() {
        this.loading = true;
        try {
          let response = await this.getAbiertas();
          this.trabajoOrdenes = this.subprocesar(response.data.result);
          this.abiertas_total = response.data.total;
          this.loading = false;
        } catch (exception) {
          console.error(exception);
        }
      },
      async abiertas_update_page_action(page) {
        this.abiertas_actual_page = page;
        this.loading = true;
        try {
          let response = await this.getAbiertas();
          this.trabajoOrdenes = this.subprocesar(response.data.result);
          this.abiertas_total = response.data.total;
          this.loading = false;
        } catch (exception) {
          console.log(exception);
        }
      },
      subprocesar ( openOTS ) {
        this.sound = new Howl({
          src: ['/sounds/notification.webm','/sounds/notification.mp3']
        });
        const newOpenOTS = openOTS.map(element => {
          if(element.idCliente == null) element.cliente = this.nombreEmpresa;
          element.fechaFinalizacion = null;
          element.faltantesMP = [];
          element.disponibilidadMP = null;
          element.articulos.map(e => {
            e.fechaFinalizacion = null;
            e.faltantesMP = [];
            return e;
          })
          return element;
        });
        this.getFaltantesMP();
        this.getTiempoFinalizacion();
        return newOpenOTS;
      },
      getTiempoFinalizacion(){
        axios
          .get("/TrabajoOrdenes/TiempoFinalizacion")
          .then(res => {
            res.data.forEach(element => {
              var posOT = this.trabajoOrdenes.findIndex(e => e.idTrabajoOrden == element.idTrabajoOrden);
              if(posOT >= 0){
                this.trabajoOrdenes[posOT].fechaFinalizacion = element.fecha;
                element.detalles.forEach(detalle => {
                  var posDetalle = this.trabajoOrdenes[posOT].articulos.findIndex(e => e.idTrabajoOrdenDetalle == detalle.idTrabajoOrdenDetalle);
                  if(posDetalle >= 0){
                    this.trabajoOrdenes[posOT].articulos[posDetalle].fechaFinalizacion = detalle.fecha;
                  }
                });
              }
            });
          })
          .catch(er => {
            console.log(er);
          })
      },
      getFaltantesMP(){
        axios
        .get("/TrabajoOrdenes/FaltantesMP")
        .then(response => {
          this.trabajoOrdenes.forEach((ot,posOT) => {
            var idxOTF = response.data.findIndex(otf => otf.idTrabajoOrden == ot.idTrabajoOrden);
            if(idxOTF < 0 ){
              if(this.trabajoOrdenes[posOT].estadoProduccion == 1 || this.trabajoOrdenes[posOT].estadoProduccion == 4){
                this.trabajoOrdenes[posOT].disponibilidadMP = 1;
                this.trabajoOrdenes[posOT].faltantesMP = [];
              }
              else{
                this.trabajoOrdenes[posOT].disponibilidadMP = 2;
                this.trabajoOrdenes[posOT].faltantesMP = [];
              }
            }
            else{
              this.trabajoOrdenes[posOT].disponibilidadMP = 0;
              this.trabajoOrdenes[posOT].faltantesMP = response.data[idxOTF].faltantes;

              response.data[idxOTF].detalles.forEach(detalle => {
                var posOTD = this.trabajoOrdenes[posOT].articulos.findIndex(otd => otd.idTrabajoOrdenDetalle == detalle.idTrabajoOrdenDetalle);
                if(posOTD >= 0){
                  this.trabajoOrdenes[posOT].articulos[posOTD].faltantesMP = detalle.faltantes;
                }
              });
            }
          })
        })
        .catch(error => {
          console.log(error);
        })
      },
      pauseItem(item){
        this.pausedItem = item;
        this.pause = true;
        this.pauseState = 0;
      },
      launchPause(){
        this.pauseState = 1;
        if(this.pausedItem.estadoProduccion == 1){
          axios
          .put("/TrabajoOrdenes/Pausar/"+this.pausedItem.idTrabajoOrden)
          .then(response => {
            this.initialize();
            this.pauseState = 3;
          })
          .catch(error => {
            this.pauseState = 2;
            console.log(error);
          });
        }
        else{
          axios
          .put("/TrabajoOrdenes/Reanudar/"+this.pausedItem.idTrabajoOrden)
          .then(response => {
            this.initialize();
            this.pauseState = 3;
          })
          .catch(error => {
            this.pauseState = 2;
            console.log(error);
          });
        }
      },
      closePause(){
        this.pausedItem = Object.assign({},this.defaultItem);
        this.pauseState = 0;
        this.pause = false;
      },
      watchItem(item){
        this.posWachedItem = this.trabajoOrdenes.indexOf(item);
        this.detalles = true;
      },
      launchItem(item){
        this.verificando = false;
        this.launch = true;
        this.launchedItem = item;
        this.faltaMP = false;
      },
      deleteItem(item){
        this.deletedItem = item;
        this.eliminar = true;
      },
      /*
        0: Sin Iniciar
        1: Lanzando
        2: Error
        3: Lanzado
      */
      launchProduccion(){
        this.launchState = 1;
        axios
          .post("/TrabajoOrdenes/Producir/"+this.launchedItem.idTrabajoOrden)
          .then(response => {
            this.launchState = 3;
            this.initialize();
          })
          .catch(error => {
            if (error.response.status == 409) {
              this.launchState = 21;
              this.recetasFaltantes = error.response.data;
            }
            else{
              this.launchState = 2;
            }
            console.log(error);
          });
      },
      closeLaunch(){
        this.launchedItem = Object.assign({},this.defaultItem);
        this.launchedItem.articulos = [];
        this.launch = false;
        this.launchState = 0;
      },
      closeDetalles(){
        this.detalles = false;
        this.posWachedItem = -1;
      },
      closeDialog(value) {
        this.dialog = value;
        if(!this.dialog){
          this.initialize();
        }
      },
      deleteItemDB(){
        axios
        .put("/TrabajoOrdenes/Desactivar/"+this.deletedItem.idTrabajoOrden)
        .then(response => {
          this.eliminar = false;
          this.deletedItem = null;
          this.initialize();
        })
        .catch(error => {
          console.log(error);
          this.eliminar = false;
          this.deletedItem = null;
        });
      },
      launchItemOTD(item){
        this.verificando = false;
        this.launchOTD = true;
        this.faltaMP = false;
        this.launchedItemOTD = item;
      },
      launchProduccionOTD(){
        this.launchState = 1;
        axios
        .post("/TrabajoOrdenes/Producir/"+this.launchedItemOTD.idTrabajoOrden+"?IdTrabajoOrdenDetalle="+this.launchedItemOTD.idTrabajoOrdenDetalle)
        .then(response => {
          this.launchState = 3;
          this.initialize();
          this.launchedItemOTD.estadoProduccion = 1;
        })
        .catch(error => {
          if (error.response.status == 409) {
              this.launchState = 21;
              this.recetasFaltantes = error.response.data;
            }
            else{
              this.launchState = 2;
            }
          console.log(error);
        });
      },
      closeLaunchOTD(){
        this.launchedItemOTD = Object.assign({},this.defaultItemOTD);
        this.launchOTD = false;
        this.launchState = 0;
      },
      pauseItemOTD(item){
        this.pausedItemOTD = item;
        this.pauseOTD = true;
        this.pauseState = 0;
      },
      launchPauseOTD(){
        this.pauseState = 1;
        if(this.pausedItemOTD.estadoProduccion == 1){
          axios
          .put("/TrabajoOrdenes/Pausar/"+this.pausedItemOTD.idTrabajoOrden+"?idTrabajoOrdenDetalle="+this.pausedItemOTD.idTrabajoOrdenDetalle)
          .then(response => {
            this.initialize();
            this.pausedItemOTD.estadoProduccion = 2;
            this.pauseState = 3;
          })
          .catch(error => {
            this.pauseState = 2;
            console.log(error);
          });
        }
        else{
          axios
          .put("/TrabajoOrdenes/Reanudar/"+this.pausedItemOTD.idTrabajoOrden+"?idTrabajoOrdenDetalle="+this.pausedItemOTD.idTrabajoOrdenDetalle)
          .then(response => {
            this.initialize();
            this.pausedItemOTD.estadoProduccion = 1;
            this.pauseState = 3;
          })
          .catch(error => {
            this.pauseState = 2;
            console.log(error);
          });
        }
      },
      closePauseOTD(){
        this.pausedItemOTD = Object.assign({},this.defaultItemOTD);
        this.pauseState = 0;
        this.pauseOTD = false;
      },
      launchFinishedEarly(){
        this.finishedEarlyState = 1;
        axios
        .put("/TrabajoOrdenes/TerminarAnticipadamenteOT/"+this.finishedEarlyItem.idTrabajoOrden)
        .then(() => {
          this.finishedEarlyState = 3;
          this.initialize();
        })
        .catch(error => {
          this.finishedEarlyState = 2;
        })
      },
      finishEarlyItem(item){
        this.finishedEarlyItem = item;
        this.dialogFinishEarly = true;
        this.finishedEarlyState = 0;
      },
      closeFinishedEarly(){
        this.finishedEarlyItem = Object.assign({},this.defaultFinishedEarlyItem);
        this.dialogFinishEarly = false;
        this.finishedEarlyState = 0;
      },
      launchFinishedEarlyOTD(){
        this.finishedEarlyState = 1;
        axios
        .put("/TrabajoOrdenes/TerminarAnticipadamenteOTD/"+this.watchedDetailItem.idTrabajoOrdenDetalle)
        .then(() => {
          this.finishedEarlyState = 3;
          if(this.watchedItem.articulos.length == 1) this.detalles = false;
        })
        .catch(error => {
          console.log(error)
          this.finishedEarlyState = 2;
        })
      },
      finishEarlyItemOTD(item){
        this.posWatchedDetailItem = this.watchedItem.articulos.indexOf(item);
        this.OTItem = Object.assign({},this.watchedItem);
        this.finishedEarlyItem = item;
        this.dialogFinishEarlyOTD = true;
        this.finishedEarlyState = 0;
      },
      closeFinishedEarlyOTD(){
        this.dialogFinishEarlyOTD = false;
        this.finishedEarlyItem = Object.assign({},this.defaultFinishedEarlyItem);
        this.dialogFinishEarlyOTD = false;
        this.finishedEarlyState = 0;
        this.OTItem = Object.assign({},this.defaultItem);
      },
      getTrabajoOrdenesOnEnterAction($event) {
        if ($event instanceof KeyboardEvent) {
          //  Enter
          if ($event.keyCode === 13) this.abiertas_update_page_action(1);
        }
      },
      clearSearchAction() {
        this.search = "";
        this.abiertas_update_page_action(1);
      },
    },
  }
</script>

<style scoped>
.fill-height{
  height: 100%;
}
.justify-content{
  justify-content: center !important;
}

.centered-input >>> input {
	text-align: center !important;
}

.footer-input {
  font-size: 12px !important;
  max-width: 40px !important;
}

.border-footer {
  border-top-width: thin;
  border-top-style: solid;
  border-top-color: rgba(0, 0, 0, 0.12);
}
</style>