<template>
	<v-col cols="12">
		<v-dialog
			v-model="eliminar"
			persistent
			max-width="500px"
			:key="eliminar"
		>
			<v-card>
				<v-card-title
					>¿Está seguro que desea eliminar esta <br />Lista de
					Comprobación?</v-card-title
				>
				<v-card-text
					>Esta acción no se puede revertir y será
					permanente.</v-card-text
				>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click="
							eliminar = false;
							delItem = '';
						"
						>Cancelar</v-btn
					>
					<v-btn
						color="red darken-1"
						text
						@click="
							eliminar = false;
							deleteItemDB();
						"
						>Aceptar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog" persistent max-width="80%" :key="dialog">
			<v-card>
				<v-card-title>
					<span class="headline">{{ formTitle }}</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="4">
								<v-text-field
									v-model="editedItem.nombre"
									label="Nombre"
									:error-messages="errorNombre"
									@focus="errorNombre = ''"
								></v-text-field>
							</v-col>
							<v-col cols="4">
								<v-text-field
									v-model="editedItem.codigo"
									label="Código"
									:error-messages="errorCodigo"
									@focus="errorCodigo = ''"
								></v-text-field>
							</v-col>
							<v-col cols="4">
								<v-checkbox
									v-model="editedItem.obligatorio"
									label="Obligatorio"
								></v-checkbox>
							</v-col>
							<v-col cols="6">
								<v-autocomplete
									v-model="editedItem.areas"
									:items="areas"
									item-text="nombre"
									item-value="idArea"
									label="Área(s) a comprobar"
									multiple
									chips
									small-chips
									deletable-chips
									clearable
									:error-messages="errorAreas"
									@focus="errorAreas = ''"
								></v-autocomplete>
							</v-col>
							<v-col cols="6">
								<v-autocomplete
									v-model="editedItem.articulos"
									:items="articulos"
									:item-text="nombreArticulo"
									item-value="idArticulo"
									label="Artículo(s) a comprobar"
									multiple
									chips
									small-chips
									deletable-chips
									clearable
									:error-messages="errorArticulos"
									@focus="errorArticulos = ''"
								></v-autocomplete>
							</v-col>
							<v-col cols="12">
								<v-data-table
									:items="editedItem.comprobaciones"
									:headers="headersComprobaciones"
									no-data-text="No hay comprobaciones disponibles"
									hide-default-footer
									:items-per-page="-1"
								>
									<template
										v-slot:item.indice="{ index }"
									>
										{{ index + 1 }}
									</template>
									<template
										v-slot:item.comprobacion="{
											item,
											index,
										}"
									>
										<v-textarea
											v-model="item.comprobacion"
											auto-grow
											rows="1"
											row-height="5"
											:error-messages="
												errorDescripcion[index]
											"
											@focus="
												errorDescripcion[index] = ''
											"
										></v-textarea>
									</template>
									<template
										v-slot:item.tipoComprobacion="{
											item,
											index,
										}"
									>
										<v-col class="pa-0" cols="12">
											<v-select
												v-model="item.tipoComprobacion"
												:items="tiposComprobaciones"
												item-text="name"
												item-value="value"
												:error-messages="
													errorTipo[index]
												"
												@focus="errorTipo[index] = ''"
											></v-select>
										</v-col>
										<v-col
											class="pa-0"
											cols="12"
											v-if="item.tipoComprobacion == 2"
										>
											<div class="d-flex">
												<v-col
													cols="3"
													class="
														px-1
														d-flex
														justify-center
													"
												>
													<v-text-field
														v-model="item.min"
														class="mt-0 pt-0"
														label="Intervalo min."
														type="number"
														style="width: 60px"
														:step="step"
														hide-details
														@input="
															(value) => {
																cambioMin(
																	value,
																	item
																);
															}
														"
													></v-text-field>
												</v-col>
												<v-col
													cols="6"
													class="
														px-1
														pt-6
														pb-0
														d-flex
														justify-center
													"
												>
													<v-range-slider
														v-model="item.range"
														:max="item.max"
														:min="item.min"
														hide-details
														class="
															pt-2
															align-center
														"
														:step="step"
														thumb-label="always"
														thumb-size="40"
														:key="sliderKey"
													></v-range-slider>
												</v-col>
												<v-col
													cols="3"
													class="
														px-1
														d-flex
														justify-center
													"
												>
													<v-text-field
														v-model="item.max"
														class="mt-0 pt-0"
														label="Intervalo max."
														type="number"
														style="width: 60px"
														:step="step"
														hide-details
														@input="
															(value) => {
																cambioMax(
																	value,
																	item
																);
															}
														"
													></v-text-field>
												</v-col>
											</div>
										</v-col>
										<v-col 
											v-if="item.tipoComprobacion == 2"
											cols="12" 
											class="d-flex justify-space-between pa-0"
										>
											<v-col
												cols="3"
												class="
													px-1
													pt-0
												"
											>
												<v-text-field
													:value="item.range[0]"
													class="mt-0 pt-0"
													dense
													:step="step"
													type="number"
													label="Rango aceptable min."
													hide-details
													@input="(value) => {
														cambioRango(value,item,0)
													}"
												></v-text-field>
											</v-col>
											<v-col
												cols="3"
												class="
													px-1
													pt-0
												"
											>
												<v-text-field
													:value="item.range[1]"
													class="mt-0 pt-0"
													dense
													:step="step"
													type="number"
													label="Rango aceptable max."
													hide-details
													@input="(value) => {
														cambioRango(value,item,1)
													}"
												></v-text-field>
											</v-col>
										</v-col>
									</template>
									<template v-slot:item.imagen="{ item }">
										<v-file-input
											v-model="item.imagenBlob"
											:key="keyImagen"
											accept="image/*"
											:label="
												item.imagen!=null
													? 'Imagen cargada'
													: 'Subir imagen (opcional)'
											"
											clearable
											:loading="!!item.loading"
											:disabled="!!item.loading"
											:hide-details="item.imagen!=null"
											@change="($event)=>{subirImagen($event,item)}"
											@click:clear="clearImagenes(item)"
										></v-file-input>
										<v-btn
											v-if="item.imagen!=null" 
											block 
											color="primary"
											text
											class="my-0"
											:loading="loadingPreview"
											:disabled="loadingPreview"
											@click="showImage(item)"
										>
											Vista Previa
											<v-icon right>mdi-eye</v-icon>
										</v-btn>
									</template>
									<template v-slot:item.action="{ item }">
										<v-tooltip
											
											bottom
											color="black"
											class="white--text"
										>
											<template v-slot:activator="{ on }">
												<v-icon
													v-on="on"
													small
													class="mr-2"
													@click="borrarComp(item)"
												>
													delete
												</v-icon>
											</template>
											<span class="white--text"
												>Eliminar</span
											>
										</v-tooltip>
									</template>
								</v-data-table>
							</v-col>
							<v-col cols="3">
								<v-btn
									color="primary"
									text
									@click="agregarItem()"
								>
									<v-icon left>mdi-plus</v-icon>
									Agregar comprobación
								</v-btn>
							</v-col>
						</v-row>
						<ul>
							<li
								class="red--text"
								v-for="ex in errores"
								:key="ex"
							>
								{{ ex }}
							</li>
						</ul>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="close()"
						>Cancelar</v-btn
					>
					<v-btn
						color="red darken-1"
						text
						@click="save()"
						:disabled="saving"
						:loading="saving"
					>
						Guardar
						<template v-slot:loader>
							<v-progress-circular
								indeterminate
								:width="2"
								:size="24"
								color="red"
							></v-progress-circular>
						</template>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog
			v-model="dialogShowImage"
			max-width="50%"
			@click:outside="closeShowImage()"
		>
			<v-card>
				<v-card-title class="py-1 d-flex justify-end">
					<v-btn
						color="primary" 
						large
						text 
						@click="closeShowImage()"
					>
						Cerrar
						<v-icon right>mdi-close-thick</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text>
					<v-col cols="12" md="12" sm="12" class="text-center">
						<img
							:src="currentImage"
							style="max-width: 100%;"
						/>
					</v-col>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col cols="4" class="mt-0 pt-0">
				<v-btn color="primary" @click="showDialog()"
					>Nueva Lista de Comprobación</v-btn
				>
			</v-col>
			<v-col cols="6" class="mt-0 pt-0 ml-auto">
				<!--
				<v-text-field
					class="pt-2"
					v-model="search"
					append-icon="search"
					label="Buscar"
					single-line
					hide-details
				></v-text-field>
				-->
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6" class="pt-0 pb-5">
				<v-autocomplete
					v-model="filtros.idArea"
					:items="areas"
					item-text="nombre"
					item-value="idArea"
					label="Área"
					clearable
					hide-details
					multiple
					@change="listas_update_page_action(1)"
				></v-autocomplete>
			</v-col>
			<v-col cols="6" class="pt-0 pb-5">
				<v-autocomplete
					v-model="filtros.idArticulo"
					:items="articulos"
					:item-text="nombreArticulo"
					item-value="idArticulo"
					label="Artículo"
					clearable
					hide-details
					multiple
					@change="listas_update_page_action(1)"
				></v-autocomplete>
			</v-col>
		</v-row>
		<v-data-table
			:headers="headers"
			:items="listas"
			:search="search"
			:loading="loading"
			loading-text="Cargando... Espere, Por Favor."
			:server-items-length="listas_total"
			:items-per-page="listas_items_per_page"
			:page="listas_actual_page"
			@update:page="listas_update_page_action"
			@update:items-per-page="(ipp) => (listas_items_per_page = ipp)"
			:footer-props="{
				disableItemsPerPage: true,
				disablePagination: this.loading,
			}"
			:disable-sort="true"
			:disable-pagination="loading"
		>
			<template v-slot:item.articulos="{ item }">
				<div v-if="item.articulos == null">
					<p class="mb-0">N/A</p>
				</div>
				<div v-if="item.articulos.length == 0">
					<p class="mb-0">Cualquier Artículo</p>
				</div>
				<div v-if="item.articulos != null && item.articulos.length > 0">
					<ul class="py-3">
						<li
							v-for="(articulo, idx) in item.articulos"
							:key="`articulo-${idx}`"
							class="pb-1"
							style="font-size: 14px !important"
						>
							{{ articulo }}
						</li>
					</ul>
				</div>
			</template>
			<template v-slot:item.areas="{ item }">
				<div v-if="item.areas == null">
					<p class="mb-0">N/A</p>
				</div>
				<div v-if="item.areas.length == 0">
					<p class="mb-0">Cualquier Área</p>
				</div>
				<div v-if="item.areas != null && item.areas.length > 0">
					<ul class="py-3">
						<li
							v-for="(area, idx) in item.areas"
							:key="`area-${idx}`"
							class="pb-1"
							style="font-size: 14px !important"
						>
							{{ area }}
						</li>
					</ul>
				</div>
			</template>
			<template v-slot:item.action="{ item }">
				<v-tooltip
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							class="mr-2"
							@click="editItem(item)"
						>
							edit
						</v-icon>
					</template>
					<span class="white--text">Editar</span>
				</v-tooltip>

				<v-tooltip
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon v-on="on" small @click="deleteItem(item)">
							delete
						</v-icon>
					</template>
					<span class="white--text">Eliminar</span>
				</v-tooltip>
			</template>
			<template v-slot:no-data>
				<v-btn color="primary" @click="initialize">Refrescar</v-btn>
			</template>
		</v-data-table>

		<v-snackbar v-model="alerta" top color="error">
			Existe una Lista de Comprobación con el mismo nombre
			<v-btn text @click="alerta = false"> Cerrar </v-btn>
		</v-snackbar>
	</v-col>
</template>

<script>
import axios from "axios";
import { mapState } from 'vuex';

export default {
	data() {
		return {
			loadingPreview: false,
			currentImage: "",
			dialogShowImage: false,
			keyImagen:0,
			tiposComprobaciones: [
				{
					name: "Dicotómica (Si o No)",
					value: 1,
				},
				{
					name: "Valor Numérico",
					value: 2,
				},
			],
			headersComprobaciones: [
				{
					text: "#",
					align: "left",
					sortable: false,
					width: "5%",
					value: "indice",
				},
				{
					text: "Comprobación",
					align: "center",
					sortable: false,
					width: "35%",
					value: "comprobacion",
				},
				{
					text: "Tipo de Comprobación",
					align: "center",
					sortable: false,
					width: "35%",
					value: "tipoComprobacion",
				},
				{
					text: "Imagen",
					align: "center",
					sortable: false,
					width: "20%",
					value: "imagen",
				},
				{
					text: "",
					align: "center",
					sortable: false,
					width: "5%",
					value: "action",
				},
			],
			sliderKey: 0,
			showTabla: false,
			savingOrdenar: false,
			modoOrdenar: false,
			areasOrden: [],
			drag: false,
			saving: false,
			dialog: false,
			alerta: false,
			eliminar: false,
			search: "",
			headers: [
				{
					text: "Nombre de la lista",
					align: "center",
					sortable: true,
					value: "nombre",
				},
				{
					text: "Artículos a comprobar",
					align: "center",
					sortable: true,
					value: "articulos",
				},
				{
					text: "Áreas a comprobar",
					align: "center",
					sortable: true,
					value: "areas",
				},
				{
					text: "Acciones",
					value: "action",
					sortable: false,
					width: "10%",
					align: "center",
				},
			],
			loading: false,
			areas: [],
			articulos: [],
			delItem: "",
			action: 0,
			errores: [],
			editedIndex: -1,
			editedItem: {
				id: -1,
				nombre: "",
				abreviatura: "",
				nombre: "",
				codigo: "",
				comprobaciones: [],
				articulos: [],
				areas: [],
				obligatorio: false,
			},
			defaultItem: {
				id: -1,
				nombre: "",
				abreviatura: "",
				nombre: "",
				codigo: "",
				comprobaciones: [],
				articulos: [],
				areas: [],
				obligatorio: false,
			},
			itemComprobacion: {
				id: -1,
				comprobacion: "",
				tipoComprobacion: null,
				range: [3, 7],
				min: 1,
				max: 10,
			},
			step: 0.001,
			errorNombre: "",
			errorCodigo: "",
			errorAreas: "",
			errorArticulos: "",
			errorDescripcion: [],
			errorTipo: [],
			errorMin: [],
			errorMax: [],
			filtros: {
				idArea: null,
				idArticulo: null,
			},
			//Paginacion de Listas (server side)
			listas_total: null,
			listas_items_per_page: 10,
			listas_actual_page: 1,
			listas: [],
		};
	},

	computed: {
		...mapState(["token"]),
		formTitle() {
			return this.editedIndex === -1
				? "Nueva Lista de Comprobación"
				: "Editar Lista de Comprobación";
		},
	},

	mounted() {
		this.initialize();
	},

	methods: {
		closeShowImage(){
			this.dialogShowImage = false;
			this.currentImage = "";
		},
		showImage(item){
			const url = `/Comprobaciones/ImagenComprobacion?imagen=${item.imagen}&access_token=${this.token}`;
			axios({
				url: url,
				method: 'GET',
				responseType: 'blob',
				data: null
			})
			.then( async response => {
				let blob = new Blob([response.data], { type: 'application/octet-stream' });
				this.currentImage = await this.$utils.images.blobToBase64(blob);
				this.dialogShowImage = true;
			})
			.catch( error => {
				console.log(error);
			})
		},
		clearImagenes(item){
			item.imagenBlob = null;
			item.imagen = null;
			this.keyImagen++;
		},
		subirImagen($event,item){
			if($event == null) return;

			let formData = new FormData();
            formData.append("imagen", $event);
			item.loading = true;
			axios
				.post('/Comprobaciones/GuardarImagenComprobacionRespuesta', formData, {
					headers: {
                        "Content-Type": "multipart/form-data"
                    }
				})
				.then( response => {
					item.imagen = response.data;
					item.loading = false;
				})
				.catch( error => {
					item.imagenBlob = null;
					item.imagen = null;
					item.loading = false;
					console.log(error);
				})
				.finally( () => {
					this.keyImagen++;
				})
		},
		cambioRango(value,item,pos){
			const numericValue = parseFloat(value);
			if(numericValue > item.min && numericValue < item.max){
				item.range[pos] = numericValue;
				this.sliderKey++;
			}
		},
		cambioMin(value, item) {
			if (value != "" && !isNaN(value)) {
				if (parseFloat(item.min) >= parseFloat(item.max)) {
					item.range[0] = parseFloat(item.max) - this.step;
				}
				this.sliderKey++;
			} else {
				item.min = "0";
				item.range[0] = 0;
			}
		},
		cambioMax(value, item) {
			if (value != "" && !isNaN(value)) {
				if (parseFloat(item.max) <= parseFloat(item.min)) {
					item.range[1] = parseFloat(item.min) + this.step;
				}
				this.sliderKey++;
			} else {
				item.max = "1";
				item.range[1] = 1;
			}
		},
		nombreArticulo: (value) =>
			value.codigo != null
				? `[${value.codigo}] - ${value.nombre}`
				: `${value.nombre}`,
		borrarComp(item) {
			this.currentImage = "";
			const indice = this.editedItem.comprobaciones.indexOf(item);
			this.editedItem.comprobaciones.splice(indice, 1);
		},
		initialize() {
			this.getAreas();
			this.getArticulos();
			this.listas_update_page_action(this.listas_actual_page);
		},
		getArticulos() {
			axios
				.get("/Articulos/ArticulosNombres?componentes=true&activo=true")
				.then((response) => {
					this.articulos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getAreas() {
			axios
				.get("/Areas")
				.then((response) => {
					this.areas = response.data.filter(a => a.idArea > 1 && a.condicion == true);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		agregarItem() {
			this.editedItem.comprobaciones.push(
				Object.assign({}, this.itemComprobacion)
			);
		},
		showDialog() {
			this.action = 0;
			this.editedIndex = -1;
			this.dialog = true;
		},
		editItem(item) {
			this.action = 1;
			this.editedIndex = this.listas.indexOf(item);
			this.editedItem = Object.assign({}, this.defaultItem);
			this.editedItem.id = item.idComprobacionLista,
			this.editedItem.nombre = item.nombre;
			this.editedItem.codigo = item.codigo;
			this.editedItem.obligatorio = item.obligatorio;
			this.editedItem.areas = item.idsAreas;
			this.editedItem.articulos = item.idsArticulos;
			this.editedItem.comprobaciones = [];
			item.comprobaciones.forEach(c => {
				this.editedItem.comprobaciones.push({
					id: c.idComprobacion,
					comprobacion: c.texto,
					tipoComprobacion: c.tipoComprobacion,
					min: (c.tipoComprobacion == 2) ? c.minimo+"" : "1",
					max: (c.tipoComprobacion == 2) ? c.maximo+"" : "10",
					range: (c.tipoComprobacion == 2) ? [c.minimoAceptado,c.maximoAceptado] : [3,7],
					imagenBlob: c.imagen!=null?new File([""],c.imagen):null,
					imagen: c.imagen
				});
				//console.log([c.minimoAceptado,c.maximoAceptado]);
			});

			
			this.dialog = true;
		},

		deleteItem(item) {
			this.eliminar = true;
			this.delItem = item;
		},
		deleteItemDB() {
			axios
				.put(
					`/Comprobaciones/Desactivar/${this.delItem.idComprobacionLista}`
				)
				.then(() => {
					this.delItem = "";
					this.initialize();
				})
				.catch((error) => {
					console.log(error);
				});
		},
		close() {
			this.dialog = false;
			this.action = 0;
			this.editedIndex = -1;
			this.defaultItem.comprobaciones = [];
			this.editedItem = Object.assign({}, this.defaultItem);
			this.errores = [];
			this.errorNombre = "";
			this.errorCodigo = "";
			this.errorAreas = "";
			this.errorArticulos = "";
			this.currentImage = "";
			this.errorDescripcion = [];
			this.errorTipo = [];
			this.errorMin = [];
			this.errorMax = [];
		},
		validate() {
			this.errores = [];
			this.errorNombre = "";
			this.errorCodigo = "";
			this.errorAreas = "";
			this.errorArticulos = "";
			this.errorDescripcion = [];
			this.errorTipo = [];
			this.errorMin = [];
			this.errorMax = [];

			if (this.editedItem.nombre == "") {
				this.errorNombre =
					"Debe indicar un nombre para la lista de comprobación.";
			}

			if (this.editedItem.codigo == "") {
				this.errorCodigo =
					"Debe indicar un código para la lista de comprobación";
			}

			/*
			if (this.editedItem.areas.length == 0) {
				this.errorAreas =
					"Debe seleccionar al menos un área a comprobar";
			}

			if (this.editedItem.articulos.length == 0) {
				this.errorArticulos =
					"Debe seleccionar al menos un artículo a comprobar";
			}
			*/

			if (this.editedItem.comprobaciones.length == 0) {
				this.errores.push("Debe agregar al menos una comprobación.");
			} else {
				this.editedItem.comprobaciones.forEach((comp, index) => {
					if (comp.comprobacion == "") {
						this.errorDescripcion[index] =
							"Debe indicar una descripción";
					}
					if (comp.tipoComprobacion == null) {
						this.errorTipo[index] =
							"Debe indicar el tipo de comprobación";
					}

					if (comp.tipoComprobacion == 2) {
						if (comp.min == null || comp.min == "") {
							this.errorMin[index] = "Requerido";
						}
						if (comp.max == null || comp.max == "") {
							this.errorMax[index] = "Requerido";
						}
					}
				});
			}

			return (
				this.errores.length == 0 &&
				this.errorNombre == "" &&
				this.errorCodigo == "" &&
				this.errorAreas == "" &&
				this.errorArticulos == "" &&
				this.errorDescripcion.length == 0 &&
				this.errorTipo.length == 0 &&
				this.errorMin.length == 0 &&
				this.errorMax.length == 0
			);
		},
		save() {
			this.action = 0;
			if (this.validate()) {
				this.saving = true;
				if (this.editedIndex > -1) {
					//Actualizar
					var comprobaciones = [];
					this.editedItem.comprobaciones.forEach(c => {
						comprobaciones.push({
							idComprobacion: c.id,
							texto: c.comprobacion,
							tipoComprobacion: c.tipoComprobacion,
							minimo: (c.tipoComprobacion == 2) ? parseFloat(c.min) : null,
							maximo: (c.tipoComprobacion == 2) ? parseFloat(c.max) : null,
							minimoAceptado: (c.tipoComprobacion == 2) ? c.range[0] : null,
							maximoAceptado: (c.tipoComprobacion == 2) ? c.range[1] : null,
							imagen: c.imagen
						});
					});

					var obj = {
						idComprobacionLista: this.editedItem.id,
						nombre: this.editedItem.nombre,
						codigo: this.editedItem.codigo,
						obligatorio: this.editedItem.obligatorio,
						comprobaciones: comprobaciones,
						idsAreas: (this.$utils.isValid(this.editedItem.areas) && this.editedItem.areas.length > 0) ? this.editedItem.areas : [],
						idsArticulos: (this.$utils.isValid(this.editedItem.articulos) && this.editedItem.articulos.length > 0) ? this.editedItem.articulos : [],
					};
					
					axios
						.put("/Comprobaciones/Actualizar",obj)
						.then(() => {
							this.saving = false;
							this.close();
							this.initialize();
						})
						.catch((error) => {
							this.saving = false;
							if (error.response) {
								if (error.response.status == 409) {
									this.alerta = true;
								}
							}
						});
				} else {
					//Crear
					var comprobaciones = [];
					this.editedItem.comprobaciones.forEach(c => {
						comprobaciones.push({
							texto: c.comprobacion,
							tipoComprobacion: c.tipoComprobacion,
							minimo: (c.tipoComprobacion == 2) ? parseFloat(c.min) : null,
							maximo: (c.tipoComprobacion == 2) ? parseFloat(c.max) : null,
							minimoAceptado: (c.tipoComprobacion == 2) ? c.range[0] : null,
							maximoAceptado: (c.tipoComprobacion == 2) ? c.range[1] : null,
							imagen: c.imagen
						});
					});

					var obj = {
						nombre: this.editedItem.nombre,
						codigo: this.editedItem.codigo,
						obligatorio: this.editedItem.obligatorio,
						comprobaciones: comprobaciones,
						idsAreas: (this.$utils.isValid(this.editedItem.areas) && this.editedItem.areas.length > 0) ? this.editedItem.areas : [],
						idsArticulos: (this.$utils.isValid(this.editedItem.articulos) && this.editedItem.articulos.length > 0) ? this.editedItem.articulos : [],
					};

					axios
						.post("/Comprobaciones/Crear",obj)
						.then(() => {
							this.saving = false;
							this.close();
							this.initialize();
						})
						.catch((error) => {
							this.saving = false;
							if (error.response) {
								if (error.response.status == 409) {
									this.alerta = true;
								}
							}
						});
				}
			}
		},
		getListasComprobacion() {
			return new Promise((resolve, reject) => {
				
				var url = `/Comprobaciones/Listar?paginar=true&size=${this.listas_items_per_page}&page=${this.listas_actual_page}`;
				
				if(this.$utils.isValid(this.filtros.idArea) && this.filtros.idArea.length > 0){
					this.filtros.idArea.forEach(idA => {url += `&IdsAreas=${idA}`});
				}

				if(this.$utils.isValid(this.filtros.idArticulo) && this.filtros.idArticulo.length > 0){
					this.filtros.idArticulo.forEach(idA => {url += `&IdsArticulos=${idA}`});
				}
				
				axios
					.get(url)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		async listas_update_page_action(page) {
			this.listas_actual_page = page;
			this.loading = true;
			try {
				let response = await this.getListasComprobacion();
				this.listas = response.data.result;
				this.listas_total = response.data.total;
				this.loading = false;
			} catch (exception) {
				console.log(exception);
			}
		},
	},
};
</script>