<template>
  <v-col cols="12" v-if="permisoVista('movimientos','r')">
    <notifications group="inventario" position="bottom right" :duration="10000"/>
    <v-row  v-if="estado">
      <div style="max-width:350px; max-height:197px; margin-left: auto; margin-right: auto;">
        <qrcode-stream @decode="onDecode"></qrcode-stream>
      </div>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-text-field
          v-model="codigo"
          label="Código de Identificación"
          hide-details
          @keydown.enter="getCode"
          @keyup="setCodeStyle"
          maxlength="11"
          :error="!validCode(codigo) && codigo.length >= 1 || (codigo.length >= 1 && codigo.length < 11) || codigo.length > 11"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" md="3" sm="3">
        <v-text-field
          v-model="viewedItem.almacen"
          label="Almacén"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="3" md="3" sm="3">
        <v-text-field
          v-model="viewedItem.articulo"
          label="Artículo"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="3" md="3" sm="3">
        <v-text-field
          v-model="viewedItem.codigo"
          label="Clave"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="3" md="3" sm="3">
        <v-text-field
          v-model="viewedItem.lote"
          label="# Lote"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :items="viewedItem.proveedores"
      :headers="headersProveedores"
      :items-per-page="-1"
      hide-default-footer
    >
    </v-data-table>
    <br>
    <br>
    <v-data-table
      :items="viewedItem.movimientos"
      :headers="headersMovimientos"
      :items-per-page="-1"
      hide-default-footer
    >
      <template v-slot:item.tipoMovimiento="{ item }">
        {{getTipoMovimiento(item.tipoMovimiento)}}
      </template>
      <template v-slot:item.cantidad="{ item }">
        {{ item.cantidad.toFixed(2) }}
      </template>
      <template v-slot:item.detalles="{ item }">
        <div v-if="item.trabajoOrden != null">
          <b>Orden de Trabajo: </b>{{item.trabajoOrden}}
        </div>
        <div v-if="item.articulo != null">
          <b>Artículo a Producir: </b>{{item.articulo}}
        </div>
      </template>
    </v-data-table>
    <div v-if="viewedItem.lotesOT!=null">
      <v-data-table
        v-if="viewedItem.lotesOT.length > 0"
        :items="viewedItem.lotesOT"
        :headers="headersLotes"
        :items-per-page="-1"
        hide-default-footer
      >
        <template v-slot:item.lotes="{ item }">
          <ul>
            <li 
              v-for="(lote,idx) in item.lotes" 
              :key="`lote-${idx}`"
            >
              {{ lote!=null?lote:'N/D' }}
            </li>
          </ul>
        </template>
      </v-data-table>
    </div>
  </v-col>
</template>

<script>
import axios from "axios";
import { QrcodeStream } from 'vue-qrcode-reader'
import {Howl, Howler} from 'howler';
import { mapState } from 'vuex';

export default {
  components:{
    QrcodeStream,
  },
  
  data: () => ({
    estado:true,
    headersLotes: [
      {
        text: 'Artículo',
        value: 'articulo',
        align:'center',
        width:'50%',
      },
      {
        text: '# Lote',
        value: 'lotes',
        align:'center',
        width:'50%',
      },
    ],
    tipoMovimientos:[
      {
        nombre:"Salida Parcial",
        idTipoMovimiento:6
      },
      {
        nombre:"Salida",
        idTipoMovimiento:1
      },
      {
        nombre:"Entrada",
        idTipoMovimiento:2
      },
      {
        nombre:"Excedente",
        idTipoMovimiento:3
      },
      {
        nombre:"Reintegro",
        idTipoMovimiento:4
      }
    ],
    codigo:'',
    soundBeep:null,
    soundError:null,
    viewedItem:{
      almacen: "",
      articulo: "",
      codigo: "",
      idAlmacen: -1,
      idArticulo: -1,
      idElemento: -1,
      movimientos: [],
      proveedores: [],
      lote: "",
      lotesOT: null
    },
    defaultItem:{
      almacen: "",
      articulo: "",
      codigo: "",
      idAlmacen: -1,
      idArticulo: -1,
      idElemento: -1,
      movimientos: [],
      proveedores: [],
      lote: "",
      lotesOT: null
    },
    headersProveedores:[
      {
        text: 'Tipo de Proveedor',
        value: 'proveedorTipo',
        align:'center',
        width:'50%',
      },
      {
        text: 'Proveedor',
        value: 'proveedor',
        align:'center',
        width:'50%',
      },
    ],
    headersMovimientos:[
      {
        text: 'Fecha',
        value: 'fechaCreacion',
        align:'center',
        width:'20%',
      },
      {
        text: 'Tipo de Movimiento',
        value: 'tipoMovimiento',
        align:'center',
        width:'20%',
      },
      {
        text: 'Cantidad',
        value: 'cantidad',
        align:'center',
        width:'20%',
      },
      {
        text: 'Detalles',
        value: 'detalles',
        align: 'center',
      }
    ]
  }),

  computed:{
    ...mapState({
      nombreEmpresa: 'nombreEmpresa',
    }),
  },
  created(){
    this.initialize();
  },
  methods:{
    initialize(){
      this.soundBeep = new Howl({
        src: ['/sounds/beep.webm','/sounds/beep.mp3']
      });
      this.soundError = new Howl({
        src: ['/sounds/error.webm','/sounds/error.mp3']
      });
    },
    estadoLector(value){
      this.estado = value;
    },
    onDecode(code){  
      axios
      .get("/Inventario/InformacionBasica?Codigo="+code+"&Movimientos=true")
      .then(response => {
        response.data.codigo = response.data.codigo.match(/.{1,3}/g).join('-');
        this.viewedItem = response.data;
        this.viewedItem.lote = this.viewedItem.lote!=null ? this.viewedItem.lote : 'N/D';
        this.soundBeep.play();
        
      })
      .catch(error => {
        this.soundError.play();
        this.$notify({
          group: 'inventario',
          title: 'No Existe un Elemento con el Código '+code.match(/.{1,3}/g).join('-'),
          type: 'error',
        });
      });
    },
    getTipoMovimiento(tipo){
      var x = this.tipoMovimientos.find(z => z.idTipoMovimiento == tipo);
      if(x == null) return "x";
      else return x.nombre;
    },
    setCodeStyle(){
      if(this.codigo.length >= 1){
        try{
          this.codigo = this.codigo.toUpperCase().replace(/-/g, '').match(/.{1,3}/g).join('-');
        }
        catch{
          this.codigo = '';
        }
      }
    },
    getCode(){
      var codigo = this.codigo.toUpperCase().replace(/-/g, '');
      if(codigo.length == 9 && this.onlyNumberAndLetters(codigo)){
        this.onDecode(codigo);
        this.codigo = "";
      }
    },
    validCode(str){
      return this.onlyNumberAndLetters(str.replace(/-/g, ''));
    },
    onlyNumberAndLetters(str) {
      return str.match("^[A-Z0-9-]+$");
    }
  }
}
</script>