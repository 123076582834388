<template>
  <v-container>
    <v-row>
			<v-col
				cols="4"
				md="4"
				sm="4"
				class="align-self-center"
				style="overflow: visible !important"
			>
				<VueCtkDateTimePicker
					id="inicio"
					v-model="filtros.inicio"
					label="Desde"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="obtenerDatos"
				/>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="align-self-center">
				<VueCtkDateTimePicker
					id="fin"
					v-model="filtros.fin"
					:min-date="filtros.inicio"
					label="Hasta (Opcional)"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
          overlay
					buttonNowTranslation="Ahora"
					@input="obtenerDatos"
				/>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="align-self-center">
				<v-autocomplete
					v-model="filtros.instancia"
					class="mt-2"
					label="Máquina"
					:items="maquinasIns"
					:item-text="marcaModelo"
					item-value="idMaquinaIns"
					@change="obtenerDatos"
          clearable
				>
					<template v-slot:item="data">
						<v-list-item-content>
							<v-list-item-title>{{
								data.item.marca.trim() +
								" - " +
								data.item.modelo.trim()
							}}</v-list-item-title>
							<v-list-item-subtitle>{{
								data.item.nombre.trim()
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</template>
				</v-autocomplete>
			</v-col>
		</v-row>
    <v-row v-if="realizarPeticion" class="px-0 ma-0">
      <v-col cols="12" class="ma-0 text-right text-subtitle-2">
        Proxima Actualización en {{tiempo}}
      </v-col>
    </v-row>

    <div>
      <!-- Grafica lineal -->
      <template>
        <v-row>
          <v-col cols="3" md="3" sm="3">
            <v-card class="fill-height d-flex">
              <v-card-text
                class="text-center align-self-center py-1"
              >
                <div class="subtitle-1 font-weight-bold">
                  Piezas Ok Ultimas 24 Horas
                </div>
                <ICountUp
                  :delay="1000"
                  :endVal="datos.ok24H"
                  class="subtitle-1 font-weight-medium"
                  :options="{
                    decimalPlaces: 0,
                    decimal: '.',
                    suffix: ' Pza'
                  }"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3" md="3" sm="3">
            <v-card class="fill-height d-flex">
              <v-card-text
                class="text-center align-self-center py-1"
              >
                <div class="subtitle-1 font-weight-bold">
                  Piezas Ok Ultimas 12 Horas
                </div>
                <ICountUp
                  :delay="1000"
                  :endVal="datos.ok12H"
                  class="subtitle-1 font-weight-medium"
                  :options="{
                    decimalPlaces: 0,
                    decimal: '.',
                    suffix: ' Pza'
                  }"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3" md="3" sm="3">
            <v-card class="fill-height d-flex">
              <v-card-text
                class="text-center align-self-center py-1"
              >
                <div class="subtitle-1 font-weight-bold">
                  Piezas Ok Ultimas 8 Horas
                </div>
                <ICountUp
                  :delay="1000"
                  :endVal="datos.ok8H"
                  class="subtitle-1 font-weight-medium"
                  :options="{
                    decimalPlaces: 0,
                    decimal: '.',
                    suffix: ' Pza'
                  }"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3" md="3" sm="3">
            <v-card class="fill-height d-flex">
              <v-card-text
                class="text-center align-self-center py-1"
              >
                <div class="subtitle-1 font-weight-bold">
                  Piezas Ok 1 Ultimas Horas
                </div>
                <ICountUp
                  :delay="1000"
                  :endVal="datos.ok1H"
                  class="subtitle-1 font-weight-medium"
                  :options="{
                    decimalPlaces: 0,
                    decimal: '.',
                    suffix: ' Pza'
                  }"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3" md="3" sm="3">
            <v-card class="fill-height d-flex">
              <v-card-text
                class="text-center align-self-center py-1"
              >
                <div class="subtitle-1 font-weight-bold">
                  Piezas No Ok Ultimas 24 Horas
                </div>
                <ICountUp
                  :delay="1000"
                  :endVal="datos.noOk24H"
                  class="subtitle-1 font-weight-medium"
                  :options="{
                    decimalPlaces: 0,
                    decimal: '.',
                    suffix: ' Pza'
                  }"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3" md="3" sm="3">
            <v-card class="fill-height d-flex">
              <v-card-text
                class="text-center align-self-center py-1"
              >
                <div class="subtitle-1 font-weight-bold">
                  Piezas No Ok Ultimas 12 Horas
                </div>
                <ICountUp
                  :delay="1000"
                  :endVal="datos.noOk12H"
                  class="subtitle-1 font-weight-medium"
                  :options="{
                    decimalPlaces: 0,
                    decimal: '.',
                    suffix: ' Pza'
                  }"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3" md="3" sm="3">
            <v-card class="fill-height d-flex">
              <v-card-text
                class="text-center align-self-center py-1"
              >
                <div class="subtitle-1 font-weight-bold">
                  Piezas No Ok Ultimas 8 Horas
                </div>
                <ICountUp
                  :delay="1000"
                  :endVal="datos.noOk8H"
                  class="subtitle-1 font-weight-medium"
                  :options="{
                    decimalPlaces: 0,
                    decimal: '.',
                    suffix: ' Pza'
                  }"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3" md="3" sm="3">
            <v-card class="fill-height d-flex">
              <v-card-text
                class="text-center align-self-center py-1"
              >
                <div class="subtitle-1 font-weight-bold">
                  Piezas No Ok 1 Ultimas Horas
                </div>
                <ICountUp
                  :delay="1000"
                  :endVal="datos.noOk1H"
                  class="subtitle-1 font-weight-medium"
                  :options="{
                    decimalPlaces: 0,
                    decimal: '.',
                    suffix: ' Pza'
                  }"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <br>
        <v-progress-linear v-if="cargando" color="primary" height="5" indeterminate ></v-progress-linear>
        <apexchart type="line" height="350" :options="chartOptions2" :series="series2"></apexchart>
      </template>

      <!-- Grafica de Barras -->
      <template>
        <v-row>
          <v-col cols="3" md="3" sm="3">
            <v-card class="fill-height d-flex">
              <v-card-text
                class="text-center align-self-center"
              >
                <div class="subtitle-1 font-weight-bold">
                  Total de Piezas
                </div>
                <ICountUp
                  :delay="1000"
                  :endVal="datos.cantOk + datos.cantNoOk"
                  class="subtitle-1 font-weight-medium"
                  :options="{
                    decimalPlaces: 0,
                    decimal: '.',
                    suffix: ' Pza'
                  }"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3" md="3" sm="3">
            <v-card class="fill-height d-flex">
              <v-card-text
                class="text-center align-self-center"
              >
                <div class="subtitle-1 font-weight-bold">
                  Piezas Ok
                </div>
                <ICountUp
                  :delay="1000"
                  :endVal="datos.cantOk"
                  class="subtitle-1 font-weight-medium"
                  :options="{
                    decimalPlaces: 0,
                    decimal: '.',
                    suffix: ' Pza'
                  }"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3" md="3" sm="3">
            <v-card class="fill-height d-flex">
              <v-card-text
                class="text-center align-self-center"
              >
                <div class="subtitle-1 font-weight-bold">
                  Piezas No Ok
                </div>
                <ICountUp
                  :delay="1000"
                  :endVal="datos.cantNoOk"
                  class="subtitle-1 font-weight-medium"
                  :options="{
                    decimalPlaces: 0,
                    decimal: '.',
                    suffix: ' Pza'
                  }"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3" md="3" sm="3">
            <v-card class="fill-height d-flex">
              <v-card-text
                class="text-center align-self-center"
              >
                <div class="subtitle-1 font-weight-bold">
                  Tiempo Promedio
                </div>
                <ICountUp
                  :delay="1000"
                  :endVal="datos.tiempoPromedio"
                  class="subtitle-1 font-weight-medium"
                  :options="{
                    decimalPlaces: 2,
                    decimal: '.',
                    suffix: ' seg'
                  }"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3" md="3" sm="3">
            <v-card class="fill-height d-flex">
              <v-card-text
                class="text-center align-self-center"
              >
                <div class="subtitle-1 font-weight-bold">
                  Tiempo Promedio Sin Fallas
                </div>
                <ICountUp
                  :delay="1000"
                  :endVal="datos.tiempoPromedioSinFallos"
                  class="subtitle-1 font-weight-medium"
                  :options="{
                    decimalPlaces: 2,
                    decimal: '.',
                    suffix: ' seg'
                  }"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3" md="3" sm="3">
            <v-card class="fill-height d-flex">
              <v-card-text
                class="text-center align-self-center"
              >
                <div class="subtitle-1 font-weight-bold">
                  Tiempo Promedio Entre Fallas
                </div>
                <ICountUp
                  :delay="1000"
                  :endVal="datos.tiempoPromedioConFallos"
                  class="subtitle-1 font-weight-medium"
                  :options="{
                    decimalPlaces: 2,
                    decimal: '.',
                    suffix: ' seg'
                  }"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3" md="3" sm="3">
            <v-card class="fill-height d-flex">
              <v-card-text
                class="text-center align-self-center"
              >
                <div class="subtitle-1 font-weight-bold">
                  IC 95% Tiempo Promedio
                </div>
                <ICountUp
                  :delay="1000"
                  :endVal="datos.tiempoPromedio95IC"
                  class="subtitle-1 font-weight-medium"
                  :options="{
                    decimalPlaces: 2,
                    decimal: '.',
                    suffix: ' seg'
                  }"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="3"
            md="3"
            sm="3"
          >
            <v-row class="ma-0 pa-0">
              <v-col cols="12" class="ma-0 px-0 text-center">
                <div class="text-subtitle-2">Agrupar Cada (Tiempos Ciclos)</div>
                <v-select
                  v-model="filtros.rango"
                  :items="rangos"
                  dense
                  hide-details
                  @change="obtenerDatos"
                ></v-select>
              </v-col>
              <v-col cols="12" class="ma-0 px-0 text-center">
                <div class="text-subtitle-2">Agrupar Cada (Tiempos Ciclos)</div>
                <v-select
                  v-model="filtros.intervalo"
                  :items="bloques"
                  dense
                  hide-details
                  @change="obtenerDatos"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <br>
        <v-progress-linear v-if="cargando" color="primary" height="5" indeterminate ></v-progress-linear>
        <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
      </template>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import ICountUp from "vue-countup-v2";
import moment from "moment";

export default {
  components: {
		ICountUp,
	},
  data: () => ({
    filtros: {
			inicio: null,
			fin: null,
			instancia: null,
      rango:2,
      intervalo:60
		},
    interval: null,
    segundos: 180,
    cargando: false,
    series:[],
    chartOptions: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
      },
      fill: {
        opacity: 1
      }
    },
    chartOptionsDefault: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: [],
      },
      fill: {
        opacity: 1
      }
    },
    datos:{
      cantNoOk: 0,
      cantOk: 0,
      data: [],
      dsTiempoPromedio: 0,
      labels: [],
      tiempoPromedio: 0,
      tiempoPromedio95IC: 0,
      tiempoPromedioConFallos: 0,
      tiempoPromedioSinFallos: 0,
      noOk1H: 0,
      noOk8H: 0,
      noOk12H: 0,
      noOk24H: 0,
      ok1H: 0,
      ok8H: 0,
      ok12H: 0,
      ok24H: 0,
      piezasNoOk: [],
      piezasOk: []
    },
    datosDefault:{
      cantNoOk: 0,
      cantOk: 0,
      data: [],
      dsTiempoPromedio: 0,
      labels: [],
      tiempoPromedio: 0,
      tiempoPromedio95IC: 0,
      tiempoPromedioConFallos: 0,
      tiempoPromedioSinFallos: 0,
      noOk1H: 0,
      noOk8H: 0,
      noOk12H: 0,
      noOk24H: 0,
      ok1H: 0,
      ok8H: 0,
      ok12H: 0,
      ok24H: 0,
      piezasNoOk: [],
      piezasOk: []
    },
    chartOptions2: {
      chart: {
        id: 'grupos',
        height: 350,
        type: 'line',
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'straight'
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      markers: {
        size: 1
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false
        }
      },
      tooltip: {
        x: {
          formatter: function (val) {
            return moment(val).format("YYYY-MM-DD HH:mm:ss");
          }
        }
      },
    },
    series2:[],
    maquinasIns:[],
    bloques:[
      {
        value:1,
        text: "1 Minuto"
      },
      {
        value:2,
        text: "2 Minutos"
      },
      {
        value:5,
        text: "5 Minutos"
      },
      {
        value:10,
        text: "10 Minutos"
      },
      {
        value:30,
        text: "30 Minutos"
      },
      {
        value:60,
        text: "1 Hora"
      }
      /*,
      {
        value:120,
        text: "2 Horas"
      },
      {
        value:180,
        text: "3 Horas"
      },
      {
        value:360,
        text: "6 Horas"
      },
      {
        value:720,
        text: "12 Horas"
      },
      {
        value:1440,
        text: "24 Horas"
      }
      */
    ],
    rangos:[
      {
        value:1,
        text: "1 Segundo"
      },
      {
        value:2,
        text: "2 Segundos"
      },
      {
        value:5,
        text: "5 Segundos"
      },
      {
        value:10,
        text: "10 Segundos"
      },
      {
        value:30,
        text: "30 Segundos"
      },
      {
        value:60,
        text: "1 Minuto"
      },
      {
        value:120,
        text: "2 Minutos"
      },
      {
        value:180,
        text: "3 Minutos"
      },
      {
        value:300,
        text: "5 Minutos"
      },
      {
        value:600,
        text: "10 Minutos"
      }
    ],
  }),
  computed:{
    realizarPeticion(){
      return this.$utils.isValid(this.filtros.inicio) && this.$utils.isValid(this.filtros.instancia) && this.filtros.instancia >= 1;
    },
    tiempo(){
      return this.$utils.strings.toTime(this.segundos);
    }
  },
  methods:{
    marcaModelo: (item) => item.marca.trim() + " - " + item.modelo.trim(),
    getData(){
      this.cargando = true;

      this.datos = Object.assign({},this.datosDefault);

      var url = `/AnaliticaMonterrey?idMaquinaIns=${this.filtros.instancia}&fechaInicio=${this.filtros.inicio}&rango=${this.filtros.rango}&intervalo=${this.filtros.intervalo}`;
      if(this.$utils.isValid(this.filtros.fin)) url +=`&fechaFin=${this.filtros.fin}`;

      axios
      .get(url)
      .then(response => {
        
        var chartOptions = Object.assign({},this.chartOptionsDefault);
        chartOptions.xaxis.categories = response.data.labels;
        this.chartOptions = chartOptions;

        //console.log(response.data.labels);
        
        
        this.series = [];
        this.series.push({
          name: 'Frecuencia',
          data: response.data.data
        });
        
        this.series2 = [];
        this.series2.push({
          name: "Piezas Ok",
          data: response.data.piezasOk,
        });

        this.series2.push({
          name: "Piezas No Ok",
          data: response.data.piezasNoOk,
        });

        this.datos = Object.assign({},response.data);

        setTimeout(() => {
          this.cargando = false;
        },2000)
      })
      .catch(error => {
        console.log(error);
        this.cargando = false;
        this.resetValues();
      })
    },
    getMaquinaIns(){
      axios
      .get("/MaquinaInstancias")
      .then(response => {
        this.maquinasIns = response.data;
      })
      .catch(error => {
        console.log(error);
      })
    },
    obtenerDatos(){
      if(!this.realizarPeticion || this.cargando){
        this.resetValues();
        return;
      }

      this.segundos = 180;

      this.getData();
    },
    resetValues(){
      this.series = [];
      this.series2 = [];
    }

  },
  mounted(){
    this.getMaquinaIns();

    this.interval = setInterval(() => {
      //if(!this.active) return;

      if(this.segundos == 0) this.obtenerDatos();
      else this.segundos -= 1;
    },1000);
  },
  destroyed(){
    clearInterval(this.interval);
  }
}
</script>