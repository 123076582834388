<template>
	<v-col cols="12">
		<v-dialog v-model="dialog" persistent max-width="25%">
			<v-card>
				<v-card-title
					>{{
						editedItem.idAlertaEmail == 0 ? "Registrar Nuevo " : "Editar "
					}}
					Email</v-card-title
				>
				<v-card-text>
					<v-row>
						<v-col cols="12">
							<v-text-field
								v-model="editedItem.email"
								label="Email"
								clearable
								:error-messages="errorEmail"
								@focus="errorEmail = ''"
							></v-text-field>
						</v-col>
                        <v-col 
                            v-if="editedItem.idAlertaEmail > 0"
                            cols="12" 
                            class="text-center" 
                        >
							<p class="text-center text-subtitle-1 text--primary">Activo</p>
							<v-btn-toggle
								v-model="editedItem.condicion"
								mandatory
							>
								<v-btn 
                                    :elevation="editedItem.condicion==true?2:0" 
                                    :value="true" 
                                    :color="editedItem.condicion==true?'success':'grey'"
                                    >
                                        <v-icon left color="white">
                                            mdi-check
                                        </v-icon>
                                        Sí
                                </v-btn>
								<v-btn 
                                    :elevation="editedItem.condicion==false?2:0" 
                                    :value="false" 
                                    :color="editedItem.condicion==false?'grey darken-4':'grey'"
                                    >
                                        <v-icon left color="white">
                                            mdi-close
                                        </v-icon>
                                        No
                                </v-btn>
							</v-btn-toggle>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="saving"
						@click="closeDialog"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="saving"
						:loading="saving"
						@click="save"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col cols="4">
				<v-btn color="primary" :disabled="loading" @click="crearNuevo"
					>Registrar Nuevo Email</v-btn
				>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
					:items="emails"
					:headers="headersEmails"
					:loading="loading"
					hide-default-footer
					:items-per-page="-1"
				>
                    <template v-slot:item.condicion="{ item }">
                        <v-chip
                            class="px-6"
                            label
                            style="min-width: 120px; justify-content: center"
                            :color="item.condicion?'success':'secondary'"
                        >
                            <v-icon left>
                                {{item.condicion?'mdi-check':'mdi-close'}}
                            </v-icon>
                            {{item.condicion?'SÍ':'NO'}}
                        </v-chip>
                    </template>
					<template v-slot:item.actions="{ item }">
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon class="mr-2" v-on="on" small @click="editItem(item)">
									edit
								</v-icon>
							</template>
							<span class="white--text">Editar</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			emails: [],
			headersEmails: [
				{
					text: "Email",
					align: "center",
					value: "email",
				},
                {
					text: "Activo",
					align: "center",
					value: "condicion",
				},
				{
					text: "Acciones",
					align: "center",
					value: "actions",
				},
			],
			loading: false,
            saving: false,
			editedItem: {
				idAlertaEmail: 0,
                condicion: false,
				email: "",
			},
			defaultEditedItem: {
				idAlertaEmail: 0,
                condicion: false,
				email: "",
			},
			errorEmail: "",
            dialog: false
		};
	},
	mounted() {
		this.initialize();
	},
	methods: {
		validate() {
			this.errorEmail = "";
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

			if (!pattern.test(this.editedItem.email)) {
				this.errorEmail = "Debe indicar un email valido";
			}

			return this.errorEmail == "";
		},
		save() {
			if (!this.validate()) return;

            this.saving = true;

            axios({
                url: this.editedItem.idAlertaEmail == 0 
                    ? '/AnaliticaMonterreyEmail/CrearAlertaEmail'
                    : `/AnaliticaMonterreyEmail/ActualizarAlertaEmail/${this.editedItem.idAlertaEmail}`,
                method: this.editedItem.idAlertaEmail == 0 
                    ? 'POST'
                    : 'PUT',
                data: this.editedItem
            })
            .then( () => {
                this.saving = false;
                this.getEmails();
                this.closeDialog();
            })
            .catch( error => {
                this.saving = false;
                if(error.response){
                    if(error.response.status == 409){
                        this.errorEmail = "El email ya esta registrado"
                    }
                }
                console.log(error);
            })
		},
		closeDialog() {
			this.dialog = false;
			this.editedItem = { ...this.defaultEditedItem };
			this.errorEmail = "";
		},
		crearNuevo() {
			this.editedItem = { ...this.defaultEditedItem };
			this.dialog = true;
		},
		editItem(item) {
			this.editedItem = { ...item };
			this.dialog = true;
		},
		initialize() {
			this.getEmails();
		},
		getEmails() {
			this.loading = true;

			axios
				.get("/AnaliticaMonterreyEmail/ListarAlertaEmails")
				.then((response) => {
					this.emails = response.data;
					this.loading = false;
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
	},
};
</script>

<style>
</style>