<template>
  <v-col cols="12" class="px-8" v-if="permisoVista('dashboard','r')">
    <v-row :class="!active&&'mb-4'">
      <v-col cols="3" md="3" sm="3" class="align-self-end">
        <datePicker
          v-model="desde"
          format="YYYY-MM-DD"
          :maxDate="fin"
          label="Desde (Obligatorio)"
          clearable
          maxToday
          @input="getMaquinaIns();obtenerDatos()"
          :disabled="cargando"
        ></datePicker>
      </v-col>
      <v-col cols="3" md="3" sm="3" class="align-self-end">
        <datePicker
          v-model="hasta"
          :minDate="desde"
          format="YYYY-MM-DD"
          label="Hasta (Obligatorio)"
          clearable
          maxToday
          @input="getMaquinaIns();obtenerDatos()"
          :disabled="cargando"
        ></datePicker>
      </v-col>
      <v-col cols="6" md="6" sm="6" class="align-self-end">
        <v-autocomplete
          v-model="idsMaquinasIns"
          label="Instancias de Máquinas (Obligatorio)"
          :items="maquinasIns"
          item-value="idMaquinaIns"
          :item-text="nombreMaquina"
          multiple
          clearable
          @change="obtenerDatos()"
          :disabled="cargando"
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row v-if="realizarPeticion && active" class="px-0 ma-0">
      <v-col cols="12" class="ma-0 text-right text-subtitle-2">
        Proxima Actualización en {{tiempo}}
      </v-col>
    </v-row>
    <v-progress-linear
      v-if="cargando"
			color="primary"
			height="5"
			indeterminate
		></v-progress-linear>
    <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
    <br>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>Producción Objetivo por Día</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table>
            <template v-slot:default>
            <thead>
              <tr>
                <th>Fecha / Máquina</th>
                <th class="text-center" v-for="maquinaIns in maquinas" :key="`maqIns-${maquinaIns.idMaquinaIns}`">{{maquinaIns.nombre}}</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(fecha,idx) in categories">
                <tr :key="`fecha-${idx}`">
                  <td>
                    {{fecha}}
                  </td>
                  <td class="text-center" v-for="(maquinaIns,idy) in maquinas" :key="`dato-${idx}-${idy}`">
                    <number-input
                    :value="obtenerValor(idx,idy)"
                    controls
                    inline
                    size="small"
                    min="0"
                    center
                    @change="cambioValor($event,idx,idy)"
                  ></number-input>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>Producción Objetivo Intervalo</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table>
            <template v-slot:default>
            <thead>
              <tr>
                <th>Fecha / Máquina</th>
                <th class="text-center" v-for="maquinaIns in maquinas" :key="`maqIns-${maquinaIns.idMaquinaIns}`">{{maquinaIns.nombre}}</th>
              </tr>
            </thead>
            <tbody>
                <tr>
                  <td>
                    {{desde}} <-> {{hasta}}
                  </td>
                  <td class="text-center" v-for="(maquinaIns,idy) in maquinas" :key="`dato-${idy}`">
                    <number-input
                    :value="obtenerValor(idx,idy)"
                    controls
                    inline
                    size="small"
                    min="0"
                    center
                    @change="cambioValorRango($event,maquinaIns)"
                  ></number-input>
                  </td>
                </tr>
            </tbody>
          </template>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-col>
</template>

<script>
import axios from "axios"

export default {
  props:{
    active:{
      type: Boolean,
      default: false,
    }
  },
  computed:{
    realizarPeticion(){
      if(this.desde != null && this.hasta != null && this.$utils.isValid(this.idsMaquinasIns) && this.idsMaquinasIns.length > 0) return true;
      else return false;
      
    },
    url(){
      var url = `/Dashboard/Grafica3?Desde=${this.desde}&Hasta=${this.hasta}`;
      if(this.idsMaquinasIns.length > 0){
        this.idsMaquinasIns.forEach(idMaquinaIns => {
          url += `&IdsMaquinasIns=${idMaquinaIns}`;
        })
      }
      return url;
    },
    chartOptions(){
      return {  
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: this.categories,
        }
      }
    },
    tiempo(){
      return this.$utils.strings.toTime(this.segundos);
    }
  },
  data: () => ({
    interval: null,
    segundos: 60,
    cargando: false,
    desde: null,
    hasta:null,
    categories: [],
    series: [],
    maquinasIns:[],
    idsMaquinasIns: [],
    maquinas: [],
    objetivos: [],
  }),
  methods:{
    nombreMaquina: maquina => ` [${maquina.nombre}] - ${maquina.marca} ${maquina.modelo}`,
    obtenerValor(x,y){
      if(this.$utils.isValid(this.objetivos[x])){
        if(this.$utils.isValid(this.objetivos[x][y])){
          return this.$utils.isValid(this.objetivos[x][y]) ? this.objetivos[x][y] : 0;
        }
        else return 0;
      }
      else return 0;
    },
    obtenerDatos(){
      if(!this.realizarPeticion || this.cargando) {
        this.maquinas = [];
        this.series = [];
        this.objetivos = [];
        this.categories = [];
        return;
      };

      this.cargando = true;
      this.segundos = 60;

      axios
      .get(this.url)
      .then(response => {
        this.maquinas = response.data.maquinaInstancias;
        this.series = response.data.series;
        this.objetivos = response.data.objetivos;
        this.categories = response.data.fechas;
        this.cargando = false;
      })
      .catch(error => {
        this.cargando = false;
        console.log(error);
      })
    },
    getMaquinaIns(){
      if (this.desde==null || this.hasta==null) {
        this.maquinasIns = [];
        return;
      }
      axios
      .get("/MaquinaInstancias/MaquinasConOperaciones",{
        params:{
          fechaInicio: this.desde,
          fechaFin: this.hasta
        }
      })
      .then(response => {
        this.maquinasIns = response.data;
      })
      .catch(error => {
        console.log(error);
      })
    },
    cambioValor(event,idxFechas,idxMaquinaIns){
      if(event <= 0 || this.obtenerValor(idxFechas,idxMaquinaIns) == event) return;
      
      axios
      .post("/MaquinaInstanciaObjetivos/CrearActualizar",{
        idMaquinaIns: this.maquinas[idxMaquinaIns].idMaquinaIns,
        cantidadObjetivo: event, 
        fecha: this.categories[idxFechas].substr(this.categories[idxFechas].indexOf("] ")+2)
      })
      .then(() => {
        this.obtenerDatos();
      })
      .catch(error => {
        console.log(error);
      })
    },
    cambioValorRango(event,maquinaIns){
      if(event <= 0) return;

      axios
      .put("/MaquinaInstanciaObjetivos/CrearActualizarRangoFechasObjetivo",{
        idMaquinaIns: maquinaIns.idMaquinaIns,
        cantidad: event, 
        rangoInicio: this.desde,
        rangoFin: this.hasta,
      })
      .then(() => {
        this.obtenerDatos();
      })
      .catch(error => {
        console.log(error);
      })
    }
  },
  mounted(){
    this.interval = setInterval(() => {
      if(!this.active) return;

      if(this.segundos == 0) {
        this.obtenerDatos();
      }
      else this.segundos -= 1;
    },1000);
  },
  destroyed(){
    clearInterval(this.interval);
  }
}
</script>