<template>
	<v-col cols="12">
		<v-dialog v-model="dialog" persistent max-width="30%">
			<v-card>
				<v-card-title>Editar Configuración</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12">
							<v-text-field
								v-model="editedItem.area"
								label="Área"
								clearable
                :disabled="saving"
								:error-messages="errorArea"
								@focus="errorArea = ''"
							></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field
								v-model="editedItem.lineaCorto"
								label="Nombre de Linea X5"
								clearable
                :disabled="saving"
								:error-messages="errorLinea"
								@focus="errorLinea = ''"
							></v-text-field>
						</v-col>
						<v-col cols="6" class="text-center">
							<p class="text-center text-subtitle-1 text--primary">¿Es Crítica?</p>
							<v-btn-toggle
								v-model="editedItem.critico"
								mandatory
							>
								<v-btn 
                  :elevation="editedItem.critico==true?2:0" 
                  :value="true" 
                  :color="editedItem.critico==true?'error':'grey'"
                >
                  <v-icon left color="white">
                      mdi-check
                  </v-icon>
                  Sí
                  </v-btn>
								<v-btn 
                	:elevation="editedItem.critico==false?2:0" 
                	:value="false" 
                	:color="editedItem.critico==false?'blue':'grey'"
                >
                	<v-icon left color="white">
                	    mdi-close
                	</v-icon>
                	No
                </v-btn>
							</v-btn-toggle>
						</v-col>
						<v-col cols="6" class="text-center">
							<p class="text-center text-subtitle-1 text--primary">Activa</p>
							<v-btn-toggle
								v-model="editedItem.condicion"
								mandatory
							>
								<v-btn 
                	:elevation="editedItem.condicion==true?2:0" 
                	:value="true" 
                	:color="editedItem.condicion==true?'success':'grey'"
                >
                	<v-icon left color="white">
                	    mdi-check
                	</v-icon>
                	Sí
                </v-btn>
								<v-btn 
                	:elevation="editedItem.condicion==false?2:0" 
                	:value="false" 
                	:color="editedItem.condicion==false?'grey darken-4':'grey'"
                >
                  <v-icon left color="white">
                      mdi-close
                  </v-icon>
                  No
                </v-btn>
							</v-btn-toggle>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text color="success" @click="closeDialog" :disabled="saving">Cancelar</v-btn>
					<v-btn text color="error" @click="save" :disabled="saving" :loading="saving">Guardar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
      <v-col cols="12">
        <v-data-table
          :items="configLineas"
          :headers="headersConfigLineas"
          :loading="loading"
					hide-default-footer
					:items-per-page="-1"
          >
            <template v-slot:item.critico="{ item }">
              <v-chip
                class="px-6"
                label
                dark
                style="min-width: 120px; justify-content: center"
                :color="item.critico?'error':'blue'"
              >
                <v-icon left>
                  {{item.critico?'mdi-check':'mdi-close'}}
                </v-icon>
                {{item.critico?'SÍ':'NO'}}
              </v-chip>
            </template>
            <template v-slot:item.condicion="{ item }">
              <v-chip
                class="px-6"
                label
                style="min-width: 120px; justify-content: center"
                :color="item.condicion?'success':'secondary'"
              >
                <v-icon left>
                  {{item.condicion?'mdi-check':'mdi-close'}}
                </v-icon>
                {{item.condicion?'SÍ':'NO'}}
              </v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom color="black" class="white--text">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small @click="editItem(item)">
                    edit
                  </v-icon>
                </template>
                <span class="white--text">Editar</span>
              </v-tooltip>
            </template>
          </v-data-table>
      </v-col>
		</v-row>
	</v-col>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
      errorArea: '',
			errorLinea: '',
			loading: false,
			configLineas: [],
			headersConfigLineas: [
				{
					text: "Área",
					align: "center",
					value: "area",
				},
				{
					text: "Linea",
					align: "center",
					value: "linea",
				},
				{
					text: "Nombre de Linea X5",
					align: "center",
					value: "lineaCorto",
				},
				{
					text: "Crítica",
					align: "center",
					value: "critico",
				},
				{
					text: "Activa",
					align: "center",
					value: "condicion",
				},
				{
					text: "Acciones",
					align: "center",
					value: "actions",
				},
			],
			editedItem: {
				idLineaArea: 0,
				lineaCorto: "",
				linea: "",
				area: "",
				critico: false,
				condicion: true,
			},
			defaultEditedItem: {
				idLineaArea: 0,
				lineaCorto: "",
				linea: "",
				area: "",
				critico: false,
				condicion: true,
			},
      saving: false
		};
	},
	mounted() {
		this.initialize();
	},
	methods: {
    validate(){
      this.errorArea = "";
      if(this.editedItem.area == null || this.editedItem.area == ""){
          this.errorArea = "Debe indicar el nombre del área";
      }
      return this.errorArea == '';
    },
    save(){
      if(!this.validate()) return;
      this.saving = true;
      axios
      .put('/AnaliticaMonterreyLinea/EditarLineaArea',this.editedItem)
      .then( () => {
        this.saving = false;
        this.getConfiguracionesLineas();
        this.closeDialog();
      })
      .catch(error => {
        this.saving = false;
        console.log(error);
      })
    },
		closeDialog() {
			this.dialog = false;
			this.editedItem = { ...this.defaultEditedItem };
            this.errorArea = "";
		},
		editItem(item) {
			this.editedItem = { ...item };
			this.dialog = true;
		},
		initialize() {
			this.getConfiguracionesLineas();
		},
		getConfiguracionesLineas() {
			this.loading = true;
			axios
				.get("/AnaliticaMonterreyLinea/ListarLineaAreas")
				.then((response) => {
					this.configLineas = response.data;
					this.loading = false;
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
	},
};
</script>