<template>
	<v-col cols="12" class="mt-4">
		<v-row justify="center">
			<v-dialog
				v-model="eliminar"
				persistent
				max-width="550px"
				:key="eliminar"
			>
				<v-card>
					<v-card-title class="headline"
						>¿Está seguro que desea eliminar este Subtipo de
						Paro?</v-card-title
					>
					<v-card-text
						>Esta acción no se puede revertir y será
						permanente.</v-card-text
					>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="blue darken-1"
							text
							@click="
								eliminar = false;
								delItem = '';
							"
							>Cancelar</v-btn
						>
						<v-btn
							color="red darken-1"
							text
							@click="
								eliminar = false;
								deleteItemDB();
							"
							>Aceptar</v-btn
						>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-row>
		<v-data-table
			v-if="permisoVista('subparotipos', 'r')"
			:headers="headers"
			:items="subParoTipos"
			:search="search"
			:loading="loading"
			loading-text="Cargando... Espere, Por Favor."
		>
			<template v-slot:top>
				<v-toolbar flat color="white">
					<v-dialog
						v-model="dialog"
						persistent
						max-width="500px"
						:key="dialog"
					>
						<template v-slot:activator="{ on }">
							<v-btn
								color="primary"
								dark
								class="mb-2"
								v-on="on"
								v-if="permisoVista('subparotipos', 'c')"
								>Nuevo Submotivo de Incidente</v-btn
							>
						</template>
						<v-card>
							<v-card-title>
								<span class="headline">{{ formTitle }}</span>
							</v-card-title>
							<v-card-text>
								<v-container>
									<v-row>
										<v-col
											justify="center"
											cols="12"
											sm="12"
											md="12"
										>
											<v-autocomplete
												label="Seleccione el Factor OEE"
												:items="oeeFactores"
												item-text="nombre"
												@change="cambioOEE"
												clearable
												@click:clear="limpiarOEE"
												return-object
											></v-autocomplete>
										</v-col>
										<v-col
											justify="center"
											cols="12"
											sm="12"
											md="12"
										>
											<v-autocomplete
												v-model="editedItem.idParoTipo"
												label="Seleccione el Motivo de Incidente"
												:items="paros"
												item-text="nombre"
												item-value="idParoTipo"
											></v-autocomplete>
										</v-col>
										<v-col
											justify="center"
											cols="12"
											sm="12"
											md="12"
										>
											<v-text-field
												v-model="editedItem.nombreSubParoTipo"
												label="Nombre del Submotivo de Incidente"
											></v-text-field>
										</v-col>
										<v-col
											justify="center"
											cols="12"
											sm="12"
											md="12"
										>
											<v-text-field
												v-model="editedItem.codigoSubParoTipo"
												label="Código del Submotivo de Incidente (Opcional)"
											></v-text-field>
										</v-col>
										<v-col cols="12" sm="12" md="12">
											<p class="mb-0">
												¿Llamado a Mantenimiento?
											</p>
											<div class="d-flex">
												<v-btn-toggle
												v-model="editedItem.mantenimiento"
												class="my-3"
												>
												<v-btn
													depressed
													small
													class="ma-0"
													:color="
													editedItem.mantenimiento == 0
														? 'success'
														: 'grey lighten-1'
													"
													>Si</v-btn
												>
												<v-btn
													depressed
													small
													class="ma-0"
													:color="
													editedItem.mantenimiento == 1
														? 'error'
														: 'grey lighten-1'
													"
													>No</v-btn
												>
												</v-btn-toggle>
											</div>
										</v-col>
									</v-row>
									<ul>
										<li
											class="red--text"
											v-for="ex in errores"
											:key="ex"
										>
											{{ ex }}
										</li>
									</ul>
								</v-container>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn
									color="blue darken-1"
									text
									@click="
										dialog = false;
										errores = [];
									"
									>Cancelar</v-btn
								>
								<v-btn
									color="red darken-1"
									text
									@click="save()"
									:disabled="saving"
									:loading="saving"
								>
									Guardar
									<template v-slot:loader>
										<v-progress-circular
											indeterminate
											:width="2"
											:size="24"
											color="red"
										></v-progress-circular>
									</template>
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
					<v-spacer></v-spacer>
					<v-text-field
						v-model="search"
						append-icon="search"
						label="Buscar"
						single-line
						hide-details
					></v-text-field>
				</v-toolbar>
			</template>
			<template v-slot:item.action="{ item }">
				<v-tooltip
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							v-if="permisoVista('subparotipos', 'u')"
							small
							class="mr-2"
							@click="editItem(item)"
						>
							edit
						</v-icon>
					</template>
					<span class="white--text">Editar</span>
				</v-tooltip>
				<v-tooltip
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							v-if="permisoVista('subparotipos', 'd')"
							small
							@click="deleteItem(item)"
						>
							delete
						</v-icon>
					</template>
					<span class="white--text">Eliminar</span>
				</v-tooltip>
			</template>
			<template v-slot:no-data>
				<v-btn color="primary" @click="initialize">Refrescar</v-btn>
			</template>
		</v-data-table>
		<v-snackbar v-model="alerta" top color="error">
			Existe un Submotivo de Incidente Activo con el mismo nombre o clave
			<v-btn text @click="alerta = false"> Cerrar </v-btn>
		</v-snackbar>
	</v-col>
</template>

<script>
import axios from "axios";

export default {
	data: () => ({
		paros: [],
		saving: false,
		dialog: false,
		alerta: false,
		eliminar: false,
		search: "",
		headers: [
			{
				text: "Código",
				align: "left",
				sortable: true,
				value: "codigoSubParoTipo",
			},
			{
				text: "Submotivo de Incidente",
				align: "left",
				sortable: true,
				value: "nombreSubParoTipo",
			},
			{
				text: "Motivo de Incidente",
				align: "left",
				sortable: true,
				value: "nombreParoTipo",
			},
			{
				text: "Acciones",
				value: "action",
				sortable: false,
				align: "center",
			},
		],
		loading: false,
		subParoTipos: [],
		delItem: null,
		action: 0,
		errores: [],
		editedIndex: -1,
		editedItem: {
			idParoTipo: 0,
			idSubParoTipo: 0,
			nombreParoTipo: '',
			nombreSubParoTipo: '',
			codigoSubParoTipo: '',
			mantenimiento: 0
		},
		defaultItem: {
			idParoTipo: 0,
			idSubParoTipo: 0,
			nombreParoTipo: '',
			nombreSubParoTipo: '',
			codigoSubParoTipo: '',
			mantenimiento: 0
		},
		oeeFactores:[]
	}),

	computed: {
		formTitle() {
			return this.editedIndex === -1
				? "Nuevo Submotivo de Incidente"
				: "Editar Submotivo de Incidente";
		},
	},

	watch: {
		dialog(val) {
			val || this.close();
		},
	},

	created() {
		this.initialize();
	},

	methods: {
		initialize() {
			this.getSubparosTipos();
			this.getOEEParosTipos();
		},
		getOEEParosTipos(){
			axios
				.get('/SubParoTipos/OEEParoTipos')
				.then(response =>  {
					this.oeeFactores = response.data;
				})
				.catch( error => {
					console.log( error );
				})
		},
		getSubparosTipos(){
			this.loading = true;
			axios
				.get("/SubParoTipos")
				.then((response) => {
					this.subParoTipos = response.data.map( subparo => ({
						...subparo,
						mantenimiento: +!subparo.mantenimiento
					}));
					this.loading = false;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		editItem(item) {
			this.action = 1;
			this.editedIndex = this.subParoTipos.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialog = true;
		},

		deleteItem(item) {
			this.eliminar = true;
			this.delItem = Object.assign({}, item);
		},
		deleteItemDB() {
			axios
				.put(`/SubParoTipos/DesactivarSubParoTipo/${this.delItem.idSubParoTipo}`)
				.then(() => {
          this.initialize();
          this.delItem = [];
				})
				.catch((error) => {
					console.log(error);
				});
		},
		close() {
			(this.action = 0), (this.dialog = false);
			setTimeout(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			}, 300);
		},
		validate() {
			this.errores = [];

			if (
				this.editedItem.idParoTipo <= 0 ||
				this.editedItem.idParoTipo == null
			)
			{
				this.errores.push(
					"Debe seleccionar el Motivo de Incidente."
				);
			}
			
			if (
				this.editedItem.nombreSubParoTipo.length < 2 ||
				this.editedItem.nombreSubParoTipo.length > 50
			) {
				this.errores.push(
					"El nombre debe tener más de 2 caracteres y menos de 50."
				);
			}

			if (
				this.editedItem.mantenimiento == null
			)
			{
				this.errores.push(
					"Debe indicar si el Incidente llamará a Mantenimiento."
				);
			}
			return this.errores.length==0;
		},
		save() {
			this.action = 0;
			if (this.validate()) {
				this.saving = true;
				if (this.editedIndex > -1) {
					//Código para editar
					axios
						.put(
							`/SubParoTipos/ActualizarSubParoTipo/${this.subParoTipos[this.editedIndex].idSubParoTipo}`,
							{
								idParoTipo: this.editedItem.idParoTipo,
								nombre: this.editedItem.nombreSubParoTipo,
								codigo: this.$utils.strings.isNullOrEmpty(this.editedItem.codigoSubParoTipo) ? null : this.editedItem.codigoSubParoTipo,
                				mantenimiento: !this.editedItem.mantenimiento
							}
						)
						.then((response) => {
							this.saving = false;
							this.close();
							this.initialize();
						})
						.catch((error) => {
							this.saving = false;
							if (error.response) {
								if (error.response.status == 409) {
									this.alerta = true;
									this.errores.push(
										"Existe un Submotivo de Incidente Activo con el Mismo Nombre o Clave"
									);
								}
							}
						});
				} else {
					axios
						.post("/SubParoTipos/CrearSubParoTipo", {
							idParoTipo: this.editedItem.idParoTipo,
							nombre: this.editedItem.nombreSubParoTipo,
							codigo: this.$utils.strings.isNullOrEmpty(this.editedItem.codigoSubParoTipo) ? null : this.editedItem.codigoSubParoTipo,
                			mantenimiento: !this.editedItem.mantenimiento
						})
						.then((response) => {
							this.saving = false;
							this.close();
							this.initialize();
						})
						.catch((error) => {
							this.saving = false;
							if (error.response) {
								if (error.response.status == 409) {
									this.alerta = true;
									this.errores.push(
										"Existe un Submotivo de Incidente Activo con el Mismo Nombre o Clave"
									);
								}
							}
						});
				}
			}
		},
		cambioOEE(event){
			this.editedItem.idParoTipo = -1;
			if(this.$utils.isValid(event)) this.paros = event.paroTipos;
			else this.paros = [];
		},
		limpiarOEE(){
			this.editedItem.idParoTipo = -1;
			this.paros = [];
		}
	},
};
</script>