<template>
	<v-col cols="12" class="px-8" v-if="permisoVista('dashboard', 'r')">
		<v-row>
			<v-col cols="12" class="pb-0">
				<p
					class="mb-0 mt-2 text-center text-h4"
					style="font-size: 30px !important"
				>
					Reporte OEE
				</p>
			</v-col>
		</v-row>
		<v-row class="mt-6">
			<v-col cols="3" md="3" sm="3" class="mt-2">
				<VueCtkDateTimePicker
					id="inicio"
					v-model="filtros.desde"
					:min-date="fechaWeekBefore"
					:max-date="filtros.hasta"
					label="Desde"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="obtenerDatos"
				/>
			</v-col>
			<v-col cols="3" md="3" sm="3" class="mt-2">
				<VueCtkDateTimePicker
					id="fin"
					v-model="filtros.hasta"
					:min-date="filtros.desde"
					:max-date="fechaNow"
					label="Hasta (opcional)"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="obtenerDatos"
				/>
			</v-col>
			<v-col cols="3" class="mt-5">
				<v-select
					v-model="filtros.intervalo"
					:items="bloques"
					label="Agrupar Cada (Tiempos Ciclos)"
					dense
					hide-details
					:disabled="cargando"
					@change="obtenerDatos"
				></v-select>
			</v-col>
			<v-col cols="3" md="3" sm="3">
				<v-autocomplete
					v-model="filtros.idsMaquinaInstancias"
					:items="maquinasIns"
					item-value="idMaquinaIns"
					:item-text="nombreMaquina"
					label="Maquinas Instancias"
					hide-details
					clearable
					:disabled="cargando"
					@change="obtenerDatos"
				>
				</v-autocomplete>
			</v-col>
		</v-row>
				<v-row>
      <v-btn 
        v-if="permisoVista('articulos','r')"
        color="blue darken-1"
        text
				:disabled="!this.realizarPeticion || desactivarDescarga"
        @click="descargarReporte">
        Descargar Reporte
        <br/> de OEE
      </v-btn>
    </v-row>
		<v-row class="justify-center ma-0 pa-0 mt-6">
			<v-col cols="3" md="3" sm="3">
				<v-card class="fill-height d-flex" color="#2a6099" dark>
					<v-card-text
						class="text-center align-self-center white--text"
					>
						<div class="subtitle-1 font-weight-bold">
							Disponibilidad
						</div>
						<ICountUp
							:delay="1000"
							:endVal="disponibilidadComputed"
							class="subtitle-1 font-weight-medium"
							:options="{
								decimalPlaces: 2,
								decimal: '.',
								suffix: '%',
							}"
						/>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="3" md="3" sm="3">
				<v-card class="fill-height d-flex" color="#2a6099" dark>
					<v-card-text
						class="text-center align-self-center white--text"
					>
						<div class="subtitle-1 font-weight-bold">
							Rendimiento
						</div>
						<ICountUp
							:delay="1000"
							:endVal="eficienciaComputed"
							class="subtitle-1 font-weight-medium"
							:options="{
								decimalPlaces: 2,
								decimal: '.',
								suffix: '%',
							}"
						/>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="3" md="3" sm="3">
				<v-card class="fill-height d-flex" color="#2a6099" dark>
					<v-card-text
						class="text-center align-self-center white--text"
					>
						<div class="subtitle-1 font-weight-bold">Calidad</div>
						<ICountUp
							:delay="1000"
							:endVal="calidadComputed"
							class="subtitle-1 font-weight-medium"
							:options="{
								decimalPlaces: 2,
								decimal: '.',
								suffix: '%',
							}"
						/>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="3" md="3" sm="3">
				<v-card class="fill-height d-flex" color="#2a6099" dark>
					<v-card-text
						class="text-center align-self-center white--text"
					>
						<div class="subtitle-1 font-weight-bold">OEE</div>
						<ICountUp
							:delay="1000"
							:endVal="oeeComputed"
							class="subtitle-1 font-weight-medium"
							:options="{
								decimalPlaces: 2,
								decimal: '.',
								suffix: '%',
							}"
						/>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-row v-if="cargando">
			<v-col cols="12" class="text-center mt-4">
				<v-progress-circular
					:size="100"
					:width="8"
					color="primary"
					indeterminate
				></v-progress-circular>
				<p class="text-h4 mt-2 text--secondary">Cargando</p>
			</v-col>
		</v-row>
		<v-row v-if="!cargando && renderizar">
			<v-col cols="6" md="6" sm="6" class="text-center">
				<div class="text-subtitle-2">Disponibilidad</div>
				<apexchart
					type="line"
					height="160"
					:options="serie1"
					:series="chartOptions1"
				></apexchart>
			</v-col>
			<v-col cols="6" md="6" sm="6" class="text-center">
				<div class="text-subtitle-2">Rendimiento</div>
				<apexchart
					type="line"
					height="160"
					:options="serie2"
					:series="chartOptions2"
				></apexchart>
			</v-col>
			<v-col cols="6" md="6" sm="6" class="text-center">
				<div class="text-subtitle-2">Calidad</div>
				<apexchart
					type="line"
					height="160"
					:options="serie3"
					:series="chartOptions3"
				></apexchart>
			</v-col>
			<v-col cols="6" md="6" sm="6" class="text-center">
				<div class="text-subtitle-2">OEE</div>
				<apexchart
					type="line"
					height="160"
					:options="serie4"
					:series="chartOptions4"
				></apexchart>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import ICountUp from "vue-countup-v2";
import moment from "moment";

export default {
	components: {
		ICountUp,
	},
	props: {
		active: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapState({
			nombreEmpresa: "nombreEmpresa",
			token: "token"
		}),
		disponibilidadComputed(){
			if(this.datos.disponibilidad == null) return 0.0;
			else return this.datos.disponibilidad;
		},
		eficienciaComputed(){
			if(this.datos.eficiencia == null) return 0.0;
			else return this.datos.eficiencia;
		},
		calidadComputed(){
			if(this.datos.calidad == null) return 0.0;
			else return this.datos.calidad;
		},
		oeeComputed(){
			if(this.datos.oee == null) return 0.0;
			else return this.datos.oee;
		},
		fechaInicioPlus() {
			return moment(this.filtros.desde)
				.add(1, "days")
				.format("YYYY-MM-DD HH:mm");
		},
		fechaWeekBefore: () => moment().subtract(2,'weeks').format("YYYY-MM-DD 00:00"),
		fechaNow: () => moment().format("YYYY-MM-DD HH:mm"),
		realizarPeticion() {
			if (
				this.$utils.isValid(this.filtros.desde) &&
				this.$utils.isValid(this.filtros.idsMaquinaInstancias)
			){
				return true;
			}else{
				this.renderizar = false;
				return false;
			}
		},
		tiempo() {
			return this.$utils.strings.toTime(this.segundos);
		},
		sameDay(){
			var desde = this.$utils.isValid(this.filtros.desde) ? moment(this.filtros.desde, "YYYY-MM-DD") : moment(1);
			var hasta = this.$utils.isValid(this.filtros.hasta) ? moment(this.filtros.hasta, "YYYY-MM-DD") : moment(1);
			return desde.isSameOrAfter(moment(),'day') || hasta.isSameOrAfter(moment(),'day');
		}
	},
	data: () => ({
		desactivarDescarga: false,
		interval: null,
		segundos: 60,
		cargando: false,
		expanded: [],
		bloques: [
			{
				value: 30,
				text: "30 Minutos",
			},
			{
				value: 60,
				text: "1 Hora",
			},
			{
				value: 720,
				text: "12 Horas",
			},
			{
				value: 1440,
				text: "1 Día",
			},
			{
				value: 10080,
				text: "1 Semana",
			},
		],
		filtros: {
			desde: null,
			hasta: null,
			idsTurnos: [],
			idsAreas: [],
			idsOTS: [],
			idsArticulos: [],
			idsMaquinaInstancias: null,
			idsPersonas: [],
			idsClientes: [],
			intervalo: 1440,
		},
		turnos: [],
		areas: [],
		ots: [],
		articulos: [],
		maquinasIns: [],
		operadores: [],
		clientes: [],
		datos: {
			calidad: 0.0,
			disponibilidad: 0.0,
			eficiencia: 0.0,
			fecha: null,
			oee: 0.0,
			operaciones: [],
			piezasNoOk: 0,
			piezasOk: 0,
			piezasTeoricas: 0,
			resumenDias: [],
			scrap: 0.0,
			tiempoOperacionSeg: 0,
			tiempoParos: 0,
			totalPiezas: 0,
		},
		datosDefault: {
			calidad: 0.0,
			disponibilidad: 0.0,
			eficiencia: 0.0,
			fecha: null,
			oee: 0.0,
			operaciones: [],
			piezasNoOk: 0,
			piezasOk: 0,
			piezasTeoricas: 0,
			resumenDias: [],
			scrap: 0.0,
			tiempoOperacionSeg: 0,
			tiempoParos: 0,
			totalPiezas: 0,
		},
		serie1: [],
		chartOptions1: {
			chart: {
				id: "disponibilidad",
				group: "oee",
				type: "line",
				height: 160,
				toolbar: {
					show: false,
				},
			},
			colors: ["#118dff"],
			xaxis: {
				type: "datetime",
				labels: {
					datetimeUTC: false,
				},
				tooltip: {
					enabled: false,
				},
			},
			yaxis: {
				labels: {
					formatter: function (val) {
						return val.toFixed(2) + "%";
					},
				},
				min: 0,
				tickAmount: 5,
			},
		},
		serie2: [],
		chartOptions2: {
			chart: {
				id: "rendimiento",
				group: "oee",
				type: "line",
				height: 160,
				toolbar: {
					show: false,
				},
			},
			colors: ["#ff8410"],
			xaxis: {
				type: "datetime",
				labels: {
					datetimeUTC: false,
				},
				tooltip: {
					enabled: false,
				},
			},
			yaxis: {
				labels: {
					formatter: function (val) {
						return val.toFixed(2) + "%";
					},
				},
				min: 0,
				tickAmount: 5,
			},
		},
		serie3: [],
		chartOptions3: {
			chart: {
				id: "calidad",
				group: "oee",
				type: "line",
				height: 160,
				toolbar: {
					show: false,
				},
			},
			colors: ["#10ff60"],
			xaxis: {
				type: "datetime",
				labels: {
					datetimeUTC: false,
				},
				tooltip: {
					enabled: false,
				},
			},
			yaxis: {
				labels: {
					formatter: function (val) {
						return val.toFixed(2) + "%";
					},
				},
				min: 0,
				tickAmount: 5,
			},
		},
		serie4: [],
		chartOptions4: {
			chart: {
				id: "OEE",
				group: "oee",
				type: "line",
				height: 160,
				toolbar: {
					show: false,
				},
			},
			colors: ["#ff372b"],
			xaxis: {
				type: "datetime",
				labels: {
					datetimeUTC: false,
				},
				tooltip: {
					enabled: false,
				},
			},
			yaxis: {
				labels: {
					formatter: function (val) {
						return val.toFixed(2) + "%";
					},
				},
				min: 0,
				tickAmount: 5,
			},
		},
		renderizar: false,
		headers: [
			{
				text: "OT",
				align: "center",
				sortable: false,
				value: "ot",
				// width: "50%",
			},
			{
				text: "Área",
				align: "center",
				sortable: false,
				value: "area",
				// width: "50%",
			},
			{
				text: "Artículo",
				align: "center",
				sortable: false,
				value: "articuloFase",
				// width: "50%",
			},
			{
				text: "Operador(es)",
				align: "center",
				sortable: false,
				value: "personas",
				// width: "50%",
			},
			{
				text: "Máquina(s)",
				align: "center",
				sortable: false,
				value: "maquinas",
				// width: "50%",
			},
			{
				text: "Disponibilidad",
				align: "center",
				sortable: false,
				value: "disponibilidad",
				// width: "50%",
			},
			{
				text: "Rendimiento",
				align: "center",
				sortable: false,
				value: "eficiencia",
				// width: "50%",
			},
			{
				text: "Calidad",
				align: "center",
				sortable: false,
				value: "calidad",
				// width: "50%",
			},
			{
				text: "OEE",
				align: "center",
				sortable: false,
				value: "oee",
				// width: "50%",
			},
			{ text: "", value: "data-table-expand" },
		],
	}),
	methods: {
		nombreArticulo: (articulo) =>
			`[${articulo.codigo}] - ${articulo.nombre}`,
		nombreMaquina: (maquina) =>
			` [${maquina.nombre}] - ${maquina.marca} ${maquina.modelo}`,
		nombrePersona: (persona) =>
			persona.nombre + " " + persona.paterno + " " + persona.materno,
		generarSerie(nombre, campo) {
			var serie = {
				name: nombre,
				data: [],
			};

			if (
				this.datos.intervalos != null && 
				this.datos.intervalos.length > 0
			) {
				serie.data = this.datos.intervalos.map((d) => {
					return {
						x: d.fecha,
						y: d[campo],
					};
				});
			}

			return [serie];
		},
		chartOptionsGenerador(id, color = "#008FFB") {
			return {
				chart: {
					id: id,
					group: "oee",
					type: "linea",
					height: 160,
					toolbar: {
						show: false,
					},
					zoom: {
						enabled: false,
					},
				},
				colors: [color],
				xaxis: {
					type: "datetime",
					labels: {
						datetimeUTC: false,
					},
					tooltip: {
						enabled: false,
					},
				},
				yaxis: {
					labels: {
						formatter: function (val) {
							return val.toFixed(2) + "%";
						},
					},
					min: 0,
					tickAmount: 5,
				},
			};
		},
		initialize() {
			this.getMaquinasIns();
		},
		resetCampo(posicion) {
			switch (posicion) {
				case 1:
					this.filtros.idsTurnos = [];
					break;
				case 2:
					this.filtros.idsAreas = [];
					break;
				case 3:
					this.filtros.idsOTS = [];
					break;
				case 4:
					this.filtros.idsArticulos = [];
					break;
				case 5:
					this.filtros.idsMaquinaInstancias = [];
					break;
				case 6:
					this.filtros.idsPersonas = [];
					break;
				case 7:
					this.filtros.idsClientes = [];
					break;
				default:
					break;
			}
		},
		obtenerDatos() {
			if (!this.realizarPeticion || this.cargando) return;
			this.cargando = true;
			this.segundos = 60;

			axios
				.get("/AnaliticaMonterrey/CalcularOEEMonterrey", {
					params: {
						idMaquinaIns: this.filtros.idsMaquinaInstancias,
						fechaInicio: this.filtros.desde,
						fechaFin: this.filtros.hasta,
						intervalo: this.filtros.intervalo,
					},
				})
				.then((response) => {
					this.datos = response.data;

					this.chartOptions1 = this.generarSerie(
						"Disponibilidad",
						"disponibilidad"
					);
					this.serie1 = this.chartOptionsGenerador(
						"Disponibilidad",
						"#118dff"
					);

					this.chartOptions2 = this.generarSerie(
						"Rendimiento",
						"eficiencia"
					);
					this.serie2 = this.chartOptionsGenerador(
						"Rendimiento",
						"#ff8410"
					);

					this.chartOptions3 = this.generarSerie(
						"Calidad",
						"calidad"
					);
					this.serie3 = this.chartOptionsGenerador(
						"Calidad",
						"#10ff60"
					);

					this.chartOptions4 = this.generarSerie("OEE", "oee");
					this.serie4 = this.chartOptionsGenerador("OEE", "#ff372b");

					this.renderizar = true;
					this.cargando = false;
				})
				.catch((error) => {
					this.cargando = false;
					console.log(error);
				});
		},
		getMaquinasIns() {
			axios
				.get("/MaquinaInstancias")
				.then((response) => {
					this.maquinasIns = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		resetInterval(){
			this.destroyInterval();
			this.interval = setInterval(() => {
				if (!this.active) return;

				if (this.segundos <= 0 && this.sameDay){
					this.obtenerDatos();
				}
				else {
					this.segundos -= 1
				}
			}, 1000);
		},
		destroyInterval(){
			clearInterval(this.interval);
			this.interval = null;
		},
		descargarReporte(){
			var datetime = moment().format('YYYYMMDDHHmmss');
			var filename = 'reporte-oee-'+datetime+'.csv';
			var mime = 'text/csv'

			this.desactivarDescarga = true;

			axios({
				url: '/AnaliticaMonterrey/ReporteOEEMonterrey',
				method: 'GET',
        responseType: 'blob',
				params: {
					idMaquinaIns: this.filtros.idsMaquinaInstancias,
					fechaInicio: this.filtros.desde,
					fechaFin: this.filtros.hasta,
					intervalo: this.filtros.intervalo,
					access_token: this.token
				},
			})
			.then((response) => {
				let blob = new Blob([response.data], { type: mime })
        let a = document.createElement("a") 
        let blobURL = URL.createObjectURL(blob)
        a.download = filename
        a.href = blobURL
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
				this.desactivarDescarga = false;
			})
			.catch((error) => {
				this.cargando = false;
				this.desactivarDescarga = false;
			});
		}
	},
	mounted() {
		this.resetInterval();
		this.initialize();
	},
	destroyed() {
		this.destroyInterval();
	},
};
</script>