import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.permisoVista('articulos2','r'))?_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,{staticClass:"mb-4"},[_c(VCol,{staticClass:"pt-0",attrs:{"cols":"6"}},[_c(VSelect,{attrs:{"items":[
						{ value: true, name: 'Activada' },
						{ value: false, name: 'Desactivada' } ],"item-text":"name","item-value":"value","label":"Producción","clearable":"","hide-details":""},on:{"change":function($event){return _vm.getEnsambles()}},model:{value:(_vm.filtros.producir),callback:function ($$v) {_vm.$set(_vm.filtros, "producir", $$v)},expression:"filtros.producir"}})],1),_c(VCol,{staticClass:"pt-0",attrs:{"cols":"6"}},[_c(VAutocomplete,{attrs:{"items":_vm.articulosLista,"item-text":_vm.nombreArticulo,"item-value":"idArticulo","label":"Artículo","clearable":"","hide-details":""},on:{"change":function($event){return _vm.getEnsambles()}},model:{value:(_vm.filtros.idArticulo),callback:function ($$v) {_vm.$set(_vm.filtros, "idArticulo", $$v)},expression:"filtros.idArticulo"}})],1)],1),_c(VDialog,{attrs:{"persistent":"","max-width":"40%"},model:{value:(_vm.dialogProducir),callback:function ($$v) {_vm.dialogProducir=$$v},expression:"dialogProducir"}},[_c(VCard,[_c(VCardText,[_c('p',{staticClass:"pt-4 text-h5 font-weight-medium"},[_vm._v(" ¿Está seguro de "+_vm._s(_vm.itemProducir.producir ? "desactivar" : "activar")+" la producción del siguiente ensamble? ")]),_c('p',{staticStyle:{"font-size":"16px !important"}},[_vm._v(" • "),(_vm.itemProducir.codigo != null)?_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"16px !important"}},[_vm._v("["+_vm._s(_vm.itemProducir.codigo)+"] - ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.itemProducir.nombre)+" ")])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"success","text":"","disabled":_vm.loadingProducir},on:{"click":function($event){return _vm.cerrarDialogProducir()}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"error","text":"","disabled":_vm.loadingProducir,"loading":_vm.loadingProducir},on:{"click":function($event){return _vm.producirEnsamble()}}},[_vm._v("Aceptar")])],1)],1)],1),_c(VDataTable,{attrs:{"items":_vm.ensambles,"headers":_vm.headers,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.producir",fn:function(ref){
					var item = ref.item;
return [_c(VChip,{staticClass:"font-weight-bold",attrs:{"color":item.producir ? 'green' : 'red',"outlined":""}},[_vm._v(" "+_vm._s(item.producir ? "Activada" : "Desactivada")+" ")])]}},{key:"item.actions",fn:function(ref){
					var item = ref.item;
return [(!item.producir && _vm.permisoVista('articulos2','u'))?_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-1",attrs:{"small":""},on:{"click":function($event){return _vm.producirItem(item)}}},on),[_vm._v(" mdi-play ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Activar Producción")])]):_vm._e(),(item.producir && _vm.permisoVista('articulos2','u'))?_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-1",attrs:{"small":""},on:{"click":function($event){return _vm.producirItem(item)}}},on),[_vm._v(" mdi-stop ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Desactivar Producción")])]):_vm._e()]}}],null,false,308854679)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }