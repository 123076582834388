<template>
	<div>
		<v-tabs v-model="tab" background-color="transparent" grow>
			<v-tab
				@click="passwordClick"
			>
				Cambiar Contraseña
			</v-tab>
			<v-tab
				@click="emailClick"
			>
				Cambiar Email
			</v-tab>

			<v-tabs-items v-model="tab" touchless>
				<v-tab-item
					eager
				>
					<CambiarPassword ref="password" />
				</v-tab-item>
				<v-tab-item
					eager
				>
					<CambiarEmail ref="email" />
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>
	</div>
</template>

<script>
import CambiarEmail from './Usuario/CambiarEmail.vue';
import CambiarPassword from './Usuario/CambiarPassword.vue';


export default {
	components: {
		CambiarPassword,
		CambiarEmail
	},
	data: () => ({
		tab: 0,
	}),
	methods: {
		// cambio(event) {
		// 	if (this.tab == 0) this.$refs.password.cambio(event);
		// 	if (this.tab == 1) this.$refs.email.cambio(event);
		// },
		initialize() {
			// this.passwordClick();
		},
		passwordClick() {
			if(typeof this.$refs.password === 'undefined') return;
			this.$refs.password.reset();
		},
		emailClick() {
			if(typeof this.$refs.email === 'undefined') return;
			this.$refs.email.reset();
		},
	},
};
</script>