<template>
	<v-col cols="12">
		<v-row>
			<v-col cols="3" md="3" sm="3" class="align-self-end">
				<datePicker
					v-model="desde"
					format="YYYY-MM-DD"
					label="Desde (Obligatorio)"
					clearable
					maxToday
					@input="obtenerDatos()"
					:disabled="cargando"
				></datePicker>
			</v-col>
			<v-col cols="3" md="3" sm="3" class="align-self-end">
				<datePicker
					v-model="hasta"
					:minDate="desde"
					format="YYYY-MM-DD"
					label="Hasta (Obligatorio)"
					clearable
					maxToday
					@input="obtenerDatos()"
					:disabled="cargando"
				></datePicker>
			</v-col>
			<v-col cols="3">
				<v-autocomplete
					v-model="idCliente"
					label="Cliente (Opcional)"
					:items="clientes"
					item-text="nombre"
					item-value="idCliente"
					hide-details
					clearable
					:disabled="cargando"
					@change="
						obtenerTrabajoOrdenes();
						obtenerDatos();
					"
				></v-autocomplete>
			</v-col>
			<v-col cols="3">
				<v-autocomplete
					v-model="odt"
					label="Orden de Trabajo (Opcional)"
					:items="ordenes"
					item-value="idTrabajoOrden"
					item-text="clave"
					hide-details
					clearable
					:loading="loadingODT"
					:disabled="cargando"
					@change="obtenerDatos"
				></v-autocomplete>
			</v-col>
		</v-row>
		<v-row v-if="desde != null && hasta != null">
			<v-col cols="4">
				<download-btn
					color="primary"
					text
					label="Descargar Reporte de<br>Piezas Terminadas"
					@click="descargarReporte"
				/>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-progress-linear v-if="cargando" color="primary" height="5" indeterminate></v-progress-linear>
				<apexchart
					v-if="!cargando"
					type="bar"
					height="350"
					:options="chartOptions"
					:series="series"
				></apexchart>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import DownloadBtn from "../../DownloadBtn.vue";
import moment from "moment";
export default {
	components: { DownloadBtn },
	data() {
		return {
			primeraVez: true,
			desde: null,
			hasta: null,
			idCliente: null,
			odt: null,
			cargando: false,
			clientes: [],
			ordenes: [],
			loadingODT: false,
			series: [],
			chartOptions: {
				chart: {
					type: "bar",
					height: 350,
					stacked: true,
					// stackType: "100%",
				},
				responsive: [
					{
						breakpoint: 480,
						options: {
							legend: {
								position: "bottom",
								offsetX: -10,
								offsetY: 0,
							},
						},
					},
				],
				xaxis: {
					categories: [],
				},
				fill: {
					opacity: 1,
				},
				legend: {
					position: "right",
					offsetX: 0,
					offsetY: 50,
				},
			},
			defaultChartOptions: {
				chart: {
					type: "bar",
					height: 350,
					stacked: true,
					// stackType: "100%",
				},
				responsive: [
					{
						breakpoint: 480,
						options: {
							legend: {
								position: "bottom",
								offsetX: -10,
								offsetY: 0,
							},
						},
					},
				],
				xaxis: {
					categories: [],
				},
				fill: {
					opacity: 1,
				},
				legend: {
					position: "right",
					offsetX: 0,
					offsetY: 50,
				},
			},
		};
	},
	computed: {
		...mapState(["nombreEmpresa", "token"]),
	},
	mounted() {
		this.initialize();
	},
	methods: {
		obtenerTrabajoOrdenes() {
			this.ordenes = [];
			this.odt = null;
			if (this.idCliente == null) return;

			this.loadingODT = true;
			axios
				.get("/TrabajoOrdenes/TrabajoOrdenesCliente", {
					params: {
						idCliente: this.idCliente,
					},
				})
				.then((response) => {
					this.ordenes = response.data;
					this.loadingODT = false;
				})
				.catch((error) => {
					this.loadingODT = false;
					console.log(error);
				});
		},
		descargarReporte() {
			let url =
				"/Reportes/ReportePiezasTerminadasXlsx?access_token=" +
				this.token;
			if (this.desde != null) url += `&Desde=${this.desde}`;
			if (this.hasta != null) url += `&Hasta=${this.hasta}`;
			if (this.idCliente != null) url += `&IdCliente=${this.idCliente}`;
			if (this.odt != null) url += `&IdTrabajoOrden=${this.odt}`;
			var datetime = moment().format("YYYYMMDDHHmmss");
			this.$utils.axios.downloadFile(
				url,
				"GET",
				"reporte-piezas-terminadas-" + datetime + ".xlsx",
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			);
		},
		initialize() {
			this.getClientes();
		},
		getClientes() {
			axios
				.get("/Clientes")
				.then((response) => {
					response.data.unshift({
						idCliente: 0,
						nombre: this.nombreEmpresa,
					});
					this.clientes = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		resetValues() {
			if(!this.primeraVez){
				this.series = [];
				this.chartOptions = Object.assign({}, this.defaultChartOptions);
				this.chartOptions.xaxis.categories = [];
			}
		},
		obtenerDatos() {
			if (this.desde == null || this.hasta == null || this.cargando) {
				this.resetValues();
				return;
			}

			this.cargando = true;

			axios
				.get("/Reportes/ReportePiezasTerminadasGrafica", {
					params: {
						Desde: this.desde,
						Hasta: this.hasta,
						IdCliente: this.idCliente,
						IdTrabajoOrden: this.odt,
					},
				})
				.then((response) => {
					let chartOptions = Object.assign(
						{},
						this.defaultChartOptions
					);
					if(this.primeraVez) this.primeraVez = false;
					chartOptions.xaxis.categories = response.data.clientes;
					this.chartOptions = chartOptions;
					this.series = response.data.series;
					this.cargando = false;
				})
				.catch((error) => {
					this.cargando = false;
					this.resetValues();
					console.log(error);
				});
		},
	},
};
</script>