<template>
  <v-dialog
    v-model="show"
    max-width="90%"
    persistent
  >
    <v-dialog v-model="dialogAlertaMP" persistent max-width="450px">
      <v-card>
        <v-card-text class="pb-0">
          <div class="text-center py-2 pt-4">
            <lottie
              id="errorAlertaMP"
              :options="failedAnimationOptions"
              :height="100"
              :width="100"
              :key="`error-${lottieKey}`"
              class="py-0"
            />
          </div>
          <p class="text-h5 text--primary text-center">Ha intentado asignar una materia prima que no forma parte de la receta.</p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="error"
            :disabled="saving"
            @click="save(true,true)"
            >Sí, autorizo<br>la salida</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn 
            text 
            color="success"
            :disabled="saving"
            :loading="saving"
            @click="cerrarDialogAlertaMP()"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAlertaMovimientoReciente" persistent max-width="600px">
      <v-card>
        <v-card-title>Alerta de Movimiento de Entrada reciente</v-card-title>
        <v-card-text class="pb-0">
          <div class="text-center">
            <lottie
              id="alertaMovimientoReciente"
              :options="attentionAnimationOptions"
              :height="200"
              :width="200"
              :key="`movreciente-${attentionAnimationKey}`"
              class="py-0"
            />
          </div>
          <p class="text-h6 text-justify text--primary font-weight-regular" style="font-size: 18px !important">
            Se está realizando una operación con un artículo al cual recientemente 
            se le hizo un movimiento de entrada de almacén. ¿Desea continuar con esta operación?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="error"
            :disabled="saving"
            @click="save(false,true)"
            >Continuar con<br>la operacion</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn 
            text 
            color="success"
            :disabled="saving"
            :loading="saving"
            @click="cerrarDialogAlertaMovimientoReciente()"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card-title>
        Nuevo Movimiento
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="movimientoItem.idTipoMovimiento"
              label="Tipo de Movimiento"
              :items="tiposMovimientos"
              item-text="nombre"
              item-value="idTipoMovimiento"
              :disabled="saving"
              @change="resetValues()"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="movimientoItem.idTipoMovimiento == 2">
          <v-col cols="12">
            <v-data-table
              :items-per-page="-1"
              :items="entradas"
              :headers="headersEntradas"
              hide-default-footer
            >
              <template v-slot:[`item.idArticulo`]="{ item }">
                <v-autocomplete
                  v-model="item.idArticulo"
                  :items="articulos"
                  :item-text="nombreArticulo"
                  item-value="idArticulo"
                  :loading="loadingArticulos"
                  :disabled="loadingArticulos || saving"
                  label="Artículo"
                  :error-messages="item.errorArticulo"
                  @focus="item.errorArticulo=''"
                >
                </v-autocomplete>
              </template>
              <template v-slot:[`item.idAlmacen`]="{ item }">
                <v-autocomplete
                  v-model="item.idAlmacen"
                  :items="almacenes"
                  :item-text="nombreAlmacen"
                  item-value="idAlmacen"
                  :loading="loadingAlmacenes"
                  :disabled="loadingAlmacenes || saving"
                  label="Almacén"
                  :error-messages="item.errorAlmacen"
                  @focus="item.errorAlmacen=''"
                >
                </v-autocomplete>
              </template>
              <template v-slot:[`item.idTipoCalidad`]="{ item }">
                <v-select
                  v-model="item.idTipoCalidad"
                  :items="calidadTipos"
                  item-text="nombre"
                  item-value="idCalidadTipo"
                  :loading="loadingCalidadTipos"
                  :disabled="loadingCalidadTipos || saving"
                  label="Tipo de Calidad"
                  :error-messages="item.errorTipoCalidad"
                  @focus="item.errorTipoCalidad=''"
                >
                </v-select>
              </template>
              <template v-slot:[`item.fechaRecepcion`]="{ item,index }">
                <VueCtkDateTimePicker
                  :id="`fecrec-${index}`"
                  v-model="item.fechaRecepcion"
                  format="YYYY-MM-DD HH:mm:ss"
                  formatted="YYYY-MM-DD HH:mm:ss"
                  label="Fecha de Recepción"
                  noHeader
                  overlay
                  class="datetime-transparent"
                  :class="item.errorFecha=='' && 'mb-3'"
                  buttonNowTranslation="Ahora"
                  :error="item.errorFecha!=''"
                  @input="resetErrorFecha(item,'fechaRecepcion','errorFecha')"
                />
                <p v-if="item.errorFecha" class="red--text text-left pt-1 mb-0" style="font-size: 12px !important">{{
									item.errorFecha
								}}</p>
              </template>
              <template v-slot:[`item.cantidad`]="{ item }">
                <v-text-field
                  v-model.number="item.cantidad"
                  type="number"
                  class="centered-input"
                  hide-spin-buttons
                  :disabled="saving"
                  :error-messages="item.errorCantidad"
                  @focus="item.errorCantidad=''"
                ></v-text-field>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small :disabled="saving" @click="deleteItem(entradas,item)">mdi-delete</v-icon>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12">
            <v-btn
              @click="agregarEntrada"
              color="primary"
              text
              :disabled="saving"
            >
              <v-icon left>mdi-plus</v-icon>Agregar Entrada
            </v-btn>
            <v-spacer></v-spacer>
          </v-col>
        </v-row>
        <v-row v-if="movimientoItem.idTipoMovimiento == 1">
          <v-col cols="12">
            <v-data-table
              :items-per-page="-1"
              :items="salidas"
              :headers="headersSalidas"
              hide-default-footer
            >
              <template v-slot:[`item.idArticulo`]="{ item }">
                <v-autocomplete
                  v-model="item.idArticulo"
                  :items="articulos"
                  :item-text="nombreArticulo"
                  item-value="idArticulo"
                  :loading="loadingArticulos"
                  :disabled="loadingArticulos || saving"
                  label="Artículo"
                  :error-messages="item.errorArticulo"
                  @focus="item.errorArticulo=''"
                ></v-autocomplete>
              </template>
              <template v-slot:[`item.idAlmacen`]="{ item }">
                <v-autocomplete
                  v-model="item.idAlmacen"
                  :items="almacenes"
                  :item-text="nombreAlmacen"
                  item-value="idAlmacen"
                  :loading="loadingAlmacenes"
                  :disabled="loadingAlmacenes || saving"
                  label="Almacén"
                  :error-messages="item.errorAlmacen"
                  @focus="item.errorAlmacen=''"
                >
                </v-autocomplete>
              </template>
              <template v-slot:[`item.fechaSalida`]="{ item,index }">
                <VueCtkDateTimePicker
                  :id="`fecsal-${index}`"
                  v-model="item.fechaSalida"
                  format="YYYY-MM-DD HH:mm:ss"
                  formatted="YYYY-MM-DD HH:mm:ss"
                  label="Fecha de Salida"
                  noHeader
                  overlay
                  class="datetime-transparent"
                  :class="item.errorFecha=='' && 'mb-3'"
                  buttonNowTranslation="Ahora"
                  :error="item.errorFecha!=''"
                  @input="resetErrorFecha(item,'fechaSalida','errorFecha')"
                />
                <p v-if="item.errorFecha" class="red--text text-left pt-1 mb-0" style="font-size: 12px !important">{{
									item.errorFecha
								}}</p>
              </template>
              <template v-slot:[`item.cantidad`]="{ item }">
                <v-text-field
                  v-model.number="item.cantidad"
                  type="number"
                  class="centered-input"
                  hide-spin-buttons
                  :error-messages="item.errorCantidad"
                  :disabled="saving"
                  @focus="item.errorCantidad=''"
                ></v-text-field>
              </template>
              <template v-slot:[`item.tipoSalida`]="{ item }">
                <v-select
                  v-model="item.tipoSalida"
                  label="Tipo de Salida"
                  :items="tiposSalidas"
                  :error-messages="item.errorTipoSalida"
                  :disabled="saving"
                  @focus="item.errorTipoSalida=''"
                  @change="changeTipoSalida(item)"
                ></v-select>
              </template>
              <template v-slot:[`item.informacionAdicional`]="{ item }">
                <v-row class="py-3">
                  <v-col cols="12" class="py-0" v-if="item.tipoSalida == 1">
                    <v-autocomplete
                      v-model="item.idTrabajoOrden"
                      label="Orden de Trabajo"
                      :items="trabajoOrdenes"
                      item-text="nombre"
                      item-value="idTrabajoOrden"
                      :disabled="loadingOTS || saving"
                      :loading="loadingOTS"
                      :error-messages="item.errorODT"
                      @change="changeTrabajoOrden($event,item)"
                      @focus="item.errorODT=''"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" class="py-0" v-if="item.tipoSalida == 1">
                    <v-autocomplete
                      v-model="item.idsTrabajoOrdenDetalles"
                      label="Artículo a Producir"
                      :items="trabajoOrdenDetalles"
                      item-text="articulo"
                      item-value="idTrabajoOrdenDetalle"
                      multiple
                      chips
                      clearable
                      deletable-chips
                      small-chips
                      :disabled="saving"
                      :error-messages="item.errorOTDetalle"
                      @focus="item.errorOTDetalle=''"
                      @click:clear="item.idsTrabajoOrdenDetalles = null"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" class="py-0" v-if="item.tipoSalida == 1">
                    <v-autocomplete
                      v-model="item.idMaquina"
                      label="Máquina"
                      :items="maquinas"
                      :item-text="maquinaLabel"
                      item-value="idMaquinaIns"
                      :disabled="loadingMaquinas || saving"
                      :loading="loadingMaquinas"
                      :error-messages="item.errorMaquina"
                      @focus="item.errorMaquina=''"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" class="py-0" v-if="item.tipoSalida == 1 || item.tipoSalida == 2">
                    <v-autocomplete
                      v-model="item.idOperador"
                      :label="(item.tipoSalida == 1) ? `Persona (Opcional)` : `Recibió`"
                      :items="personas"
                      item-value="idPersona"
                      :item-text="operadorLabel"
                      clearable
                      :disabled="loadingPersonas || saving"
                      :loading="loadingPersonas"
                      @click:clear="item.idOperador = null"
                      :error-messages="item.errorPersona"
                      @focus="item.errorPersona=''"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small :disabled="saving" @click="deleteItem(salidas,item)">mdi-delete</v-icon>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12">
            <v-btn
              @click="agregarSalida"
              color="primary"
              text
              :disabled="saving"
            >
              <v-icon left>mdi-plus</v-icon> Agregar Salida
            </v-btn>
            <v-spacer></v-spacer>
          </v-col>
        </v-row>
        <v-row v-if="errorSinElementos">
          <v-col cols="12" class="py-0">
            <p class="red--text">• Debe agregar al menos una {{ movimientoItem.idTipoMovimiento == 1 ? 'Salida' : 'Entrada' }}</p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
          text 
          color="success"
          @click="close()"
          :disabled="saving"
        >
          Cancelar
        </v-btn>
        <v-btn 
          text 
          color="error"
          @click="save()"
          :disabled="saving"
          :loading="saving"
        >
          Guardar
        </v-btn>          
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import Lottie from 'vue-lottie';
import * as failedAnimation from '@/assets/failed.json';
import * as attentionAnimation from '@/assets/attention.json';

export default {
  components: {
    'lottie': Lottie,
  },
  props:{
    show:{
      type:Boolean,
      default: false,
    }
  },
  data: () => ({
    dialogAlertaMovimientoReciente: false,
    attentionAnimationKey: 0,
    attentionAnimationOptions: {
      animationData: attentionAnimation.default,
      loop:false,
      speeed: 2
    },
    failedAnimationOptions: {
      animationData: failedAnimation.default,
      loop:false,
      speeed: 2
    },
    dialogAlertaMP: false,
    lottieKey: 0,
    saving: false,
    trabajoOrdenDetalles: [],
    soundError: null,
    errorSinElementos: false,
    articulos: [],
    loadingArticulos: false,
    calidadTipos: [],
    loadingCalidadTipos: false,
    almacenes: [],
    loadingAlmacenes: false,
    articulosTipos: [],
    loadingArticuloTipos: false,
    trabajoOrdenes: [],
    loadingOTS: false,
    personas: [],
    loadingPersonas: false,
    maquinas: [],
    loadingMaquinas: false,
    movimientoItem: {
      idTipoMovimiento: -1,
      idArticulo: -1,
    },
    tiposMovimientos:[
      {
        nombre:"Entrada",
        idTipoMovimiento:2
      },
      {
        nombre:"Salida",
        idTipoMovimiento:1
      },
      /*,
      {
        nombre:"Reintegro",
        idTipoMovimiento:4
      }
      {
        nombre:"Excedente",
        idTipoMovimiento:3
      }
      */
    ],
    entradas: [],
    entradaItem: {
      idArticulo: null,
      idAlmacen: null,
      idTipoCalidad: null,
      fechaRecepcion: null,
      descripcion: null,
      cantidad: 0.0,
      errorArticulo: '',
      errorAlmacen: '',
      errorTipoCalidad: '',
      errorCantidad: '',
      errorFecha: ''
    },
    headersEntradas: [
      {
        text: 'Artículo',
        value: 'idArticulo',
        align:'center',
        sortable: false,
        width: "26%",
      },
      {
        text: 'Almacén',
        value: 'idAlmacen',
        align:'center',
        sortable: false,
        width: "18%",
      },{
        text: 'Subalmacen',
        value: 'idSubalmacen',
        align:'center',
        sortable: false,
        width: "18%",
      },
      {
        text: 'Tipo de Calidad',
        value: 'idTipoCalidad',
        align:'center',
        sortable: false,
        width: "18%",
      },
      {
        text: 'Fecha de Recepción',
        value: 'fechaRecepcion',
        align:'center',
        sortable: false,
        width: "18%",
      },
      {
        text: 'Cantidad',
        value: 'cantidad',
        align:'center',
        sortable: false,
        width: "10%",
      },
      {
        text: 'Acciones',
        value: 'actions',
        align:'center',
        sortable: false,
        width: "10%",
      }
    ],
    tiposSalidas: [
      {
        value: 1,
        text: "A Producción",
      },
      {
        value: 2,
        text: "A Mantenimiento",
      },
      /*
      {
        value: 3,
        text: "A Proveedor",
      },
      {
        value: 3,
        text: "Ventas al Publico",
      },
      */
    ],
    salidas: [],
    salidaItem: {
      idArticulo: null,
      idAlmacen: null,
      tipoSalida: null,
      fechaSalida: null,
      cantidad: 0,
      idTrabajoOrden: null,
      idsTrabajoOrdenDetalles: [],
      idMaquina: null,
      idOperador: null,
      errorArticulo: '',
      errorAlmacen: '',
      errorFecha: '',
      errorCantidad: '',
      errorTipoSalida: '',
      errorODT: '',
      errorOTDetalle: '',
      errorMaquina: '',
      errorPersona: '',
    },
    headersSalidas: [
      {
        text: 'Artículo',
        value: 'idArticulo',
        align:'center',
        sortable: false,
        width: "15%",
      },
      {
        text: 'Almacén',
        value: 'idAlmacen',
        align:'center',
        sortable: false,
        width: "15%",
      },
      {
        text: 'Fecha de Salida',
        value: 'fechaSalida',
        align:'center',
        sortable: false,
        width: "15%",
      },
      {
        text: 'Cantidad',
        value: 'cantidad',
        align:'center',
        sortable: false,
        width: "10%",
      },
      {
        text: 'Tipo de Salida',
        value: 'tipoSalida',
        align:'center',
        sortable: false,
        width: "15%",
      },
      {
        text: 'Información Adicional',
        value: 'informacionAdicional',
        align:'center',
        sortable: false,
        width: "20%",
      },
      {
        text: 'Acciones',
        value: 'actions',
        align:'center',
        sortable: false,
        width: "5%",
      }
    ],
  }),
  mounted(){
    this.initialize();
  },
  watch: {
    show(){
      if(this.show){
        this.movimientoItem.idTipoMovimiento = null;
        this.movimientoItem.idArticulo = null;
      }
    }
  },
  methods: {
    operadorLabel: ({nombre,paterno,materno}) => `${nombre || ''} ${paterno || ''} ${materno || ''}`.trim(),
    maquinaLabel: ({nombre,marca,modelo}) =>  `[${nombre}] - ${marca} ${modelo}`.trim(),
    nombreArticulo: ({codigo,nombre}) => codigo ? `[${codigo}] - ${nombre}`:`${nombre}`.trim(),
    nombreAlmacen: ({codigo,nombre}) => codigo ? `[${codigo}] - ${nombre}`:`${nombre}`.trim(),
    cerrarDialogAlertaMovimientoReciente(){
      this.dialogAlertaMovimientoReciente = false;
    },
    async checkMovimientoReciente({idAlmacen, idArticulo}){
      let result = false;
      try{
        const response = await axios.get(`/Inventario/MovimientoRecienteAlmancen`,{
          params: {
            idAlmacen,
            idArticulo
          }
        });
        result = response.movimientoReciente;
      }
      catch(error){
        console.log(error);
      }
      finally {
        return result;
      }
    },
    cerrarDialogAlertaMP(){
      this.dialogAlertaMP = false;
    },
    changeTipoSalida(item){
      this.$set(item,'idTrabajoOrden',null);
      this.$set(item,'idsTrabajoOrdenDetalles',null);
      this.$set(item,'idMaquina',null);
      this.$set(item,'idOperador',null);
      this.$set(item,'errorODT','');
      this.$set(item,'errorOTDetalle','');
      this.$set(item,'errorMaquina','');
      this.$set(item,'errorPersona','');
    },
    changeTrabajoOrden(idTrabajoOrden,item){
      this.$set(item,'idsTrabajoOrdenDetalles',null);
      this.setDetalles(idTrabajoOrden);
    },
    setDetalles(idTrabajoOrden){
      const trabajoOrden = this.trabajoOrdenes.find(odt => odt.idTrabajoOrden == idTrabajoOrden);
      if(trabajoOrden == null){
        this.trabajoOrdenDetalles = [];
        return;
      } 
      this.trabajoOrdenDetalles = trabajoOrden.detalles;
      const idsAlmacenes = this.almacenes.filter(a => a.idCliente == null).map(a => a.idAlmacen);
      
      if(trabajoOrden.maquila){
        idsAlmacenes.push(trabajoOrden.idAlmacen);
      }

      // const nElementosRemover = this.salidas.filter(a => idsAlmacenes.indexOf(a.idAlmacen) == -1).length;

      // if(nElementosRemover > 0){
      //   this.salidas = this.salidas.filter(a => {
      //     return idsAlmacenes.indexOf(a.idAlmacen) > 0;
      //   });
      //   this.soundError.play();
      //   this.$notify({
      //     group: 'inventario',
      //     title: 'Se ha'+(nElementosRemover > 1?'n':'')+' removido '+nElementosRemover+' elemento'+(nElementosRemover > 1?'s':'')+' que no se pueden usar para esta ODT',
      //     type: 'error',
      //   });
      // }
    },
    resetValues(){
      this.entradas = [];
      this.salidas = [];
      this.errorSinElementos = false;
    },
    close(status = 0){
      this.show = false;
      this.$nextTick( () => {
        this.$emit('change',status);
        this.resetValues();
      })
    },
    resetErrorFecha(item,key,errorKey){
      if(item[key] != null){
          this.$set(item,errorKey,'');
      }
    },
    validate(){
      this.errorSinElementos = false;
      let errores = false;

      if(this.movimientoItem.idTipoMovimiento == 1){
        if(this.salidas.length == 0){
          this.errorSinElementos = true;
        }
        else {
          this.salidas.forEach( salida => {
            this.$set(salida,'errorArticulo','');
            this.$set(salida,'errorAlmacen','');
            this.$set(salida,'errorFecha','');
            this.$set(salida,'errorCantidad','');
            this.$set(salida,'errorTipoSalida','');
            this.$set(salida,'errorODT','');
            this.$set(salida,'errorOTDetalle','');
            this.$set(salida,'errorMaquina','');
            this.$set(salida,'errorPersona','');

            if(salida.idArticulo == null){
              this.$set(salida,'errorArticulo','Requerido');
              errores = true;
            }

            if(salida.idAlmacen == null){
              this.$set(salida,'errorAlmacen','Requerido');
              errores = true;
            }

            if(salida.fechaSalida == null){
              this.$set(salida,'errorFecha','Requerido');
              errores = true;
            }

            if(isNaN(parseFloat(salida.cantidad))){
              this.$set(salida,'errorCantidad','Cantidad invalida');
              errores = true;
            }
            else if(parseFloat(salida.cantidad) <= 0){
              this.$set(salida,'errorCantidad','Cantidad invalida');
              errores = true;
            }

            if(salida.tipoSalida == null){
              this.$set(salida,'errorTipoSalida','Requerido');
              errores = true;
            }
            else if(salida.tipoSalida == 1){
              if(salida.idTrabajoOrden == null){
                this.$set(salida,'errorODT','Requerido');
                errores = true;
              }

              if(salida.idsTrabajoOrdenDetalles == null || salida.idsTrabajoOrdenDetalles?.length == 0){
                this.$set(salida,'errorOTDetalle','Requerido');
                errores = true;
              }

              if(salida.idMaquina == null){
                this.$set(salida,'errorMaquina','Requerido');
                errores = true;
              }
            }
            else if(salida.tipoSalida == 2){
              if(salida.idOperador == null){
                this.$set(salida,'errorPersona','Requerido');
                errores = true;
              }
            }
          });
        }
      }
      else if(this.movimientoItem.idTipoMovimiento == 2){
        if(this.entradas.length == 0){
          this.errorSinElementos = true;
        }
        else {
          this.entradas.forEach(entrada => {
          this.$set(entrada,'errorArticulo','');
          this.$set(entrada,'errorAlmacen','');
          this.$set(entrada,'errorTipoCalidad','');
          this.$set(entrada,'errorFecha','');
          this.$set(entrada,'errorCantidad','');

          if(entrada.idArticulo == null){
            this.$set(entrada,'errorArticulo','Requerido');
            errores = true;
          }

          if(entrada.idAlmacen == null){
            this.$set(entrada,'errorAlmacen','Requerido');
            errores = true;
          }

          if(entrada.idTipoCalidad == null){
            this.$set(entrada,'errorTipoCalidad','Requerido');
            errores = true;
          }

          if(entrada.fechaRecepcion == null){
            this.$set(entrada,'errorFecha','Requerido');
            errores = true;
          }

          if(isNaN(parseFloat(entrada.cantidad))){
            this.$set(entrada,'errorCantidad','Cantidad invalida');
            errores = true;
          }
          else if(parseFloat(entrada.cantidad) <= 0){
            this.$set(entrada,'errorCantidad','Cantidad invalida');
            errores = true;
          }

        });

        }
      }
      return !errores && !this.errorSinElementos;
    },
    async save(confirm=false,confirmedReciente=false){
      if(!this.validate()) return;

      this.saving = true;

      if(this.movimientoItem.idTipoMovimiento == 2){
        if(!confirmedReciente){
          let forceReturn = false;
          for(const entrada of this.entradas){
            const movimientoReciente = await this.checkMovimientoReciente(entrada);
            if(movimientoReciente){
              this.saving = false;
              this.dialogAlertaMovimientoReciente = true;
              this.attentionAnimationKey++;
              forceReturn = true;
              break;
            }
          }
          if(forceReturn) return;
        }
      }

      axios({
        url: this.movimientoItem.idTipoMovimiento == 1 
          ? `/Inventario/CrearMovimientoBasicoSalida?confirmacion=${confirm}`
          : '/Inventario/CrearMovimientoBasicoEntrada',
        method: 'POST',
        data: this.movimientoItem.idTipoMovimiento == 1 
          ? this.salidas
          : this.entradas
      })
        .then( () => {
          this.cerrarDialogAlertaMovimientoReciente();
          this.cerrarDialogAlertaMP();
          this.saving = false;
          this.close(1);
        })
        .catch( error => {
          this.saving = false;
          if(error.response){
            if(error.response.status==409){
              this.dialogAlertaMP = true;
              this.lottieKey++;
            }
          }
          console.log(error);
        })

    },
    deleteItem(arreglo,item){
      const index = arreglo.indexOf(item);
      arreglo.splice(index,1);
    },
    async getArticuloTipos(){
      try {
        this.loadingArticuloTipos = true;
        const response = await axios.get('/Articulos/ArticuloTipos');
        this.articulosTipos = response.data;
        this.loadingArticuloTipos = false;
      } catch (error) {
        this.loadingArticuloTipos = false;
        console.log(error);
      }
    },
    async getArticulos(){
      this.loadingArticulos = true;
      await this.getArticuloTipos();
      axios
        .get("/Articulos/ArticulosNombres")
        .then(response => {
          const articulos = [];
          this.articulosTipos
              .sort( (a,b) => a.idArticuloTipo - b.idArticuloTipo )
              .forEach( (artip,idx) => {
                if(idx > 0) articulos.push({divider: true});
                articulos.push({ header: artip.nombre });
                const articfiltrados = response.data
                  .filter( articulo => articulo.idArticuloTipo == artip.idArticuloTipo )
                  .map( articulo => ({
                    ...articulo,
                    group: artip.nombre
                  }));
                articulos.push( ...articfiltrados );
              });
          this.articulos = articulos;
          this.loadingArticulos = false;
        })
        .catch(error => {
          this.loadingArticulos = false;
          console.log(error);
        })
    },
    getAlmacenes(){
      this.loadingAlmacenes = true;
      axios
      .get("/Almacenes")
      .then(response => {
        response.data.forEach(e => {
          if(e.idCliente == null){
            e.cliente = this.nombreEmpresa;
          }
          this.loadingAlmacenes = false;
        });
        this.almacenes = response.data;
      })
      .catch(error => {
        console.log(error);
        this.loadingAlmacenes = false;
      })
    },
    getCalidadTipos(){
      axios
      .get("/CalidadTipos")
      .then(response => {
        this.calidadTipos=response.data;
      })
      .catch(error => {
        console.log(error);
      })
    },
    getOTS(){
      this.loadingOTS = true;
      axios
        .get("TrabajoOrdenes/ListarOT?otsCerradas=true")
        .then(response => {
          this.trabajoOrdenes = response.data;
          this.loadingOTS = false;
        })
        .catch(error => {
          this.loadingOTS = false;
          console.log(error);
        })
    },
    getPersonas(){
      this.loadingPersonas = true;
      axios
      .get("/Personas")
      .then(response =>  {
        this.personas = response.data;
        this.loadingPersonas = false;
      })
      .catch(error => {
        console.log(error);
        this.loadingPersonas = false;
      });
    },
    getMaquinas(){
      this.loadingMaquinas = true;
      axios
      .get("/MaquinaInstancias")
      .then(response =>  {
        this.maquinas = response.data;
        this.loadingMaquinas = false;
      })
      .catch(error => {
        console.log(error);
        nothis.loadingMaquinas = false;
      });
    },
    agregarEntrada(){
      this.entradas.push(Object.assign({},this.entradaItem));
      this.errorSinElementos = false;
    },
    agregarSalida(){
      this.salidas.push(Object.assign({},this.salidaItem));
      this.errorSinElementos = false;
    },
    initialize(){
      this.getArticulos();
      this.getAlmacenes();
      this.getCalidadTipos();
      this.getOTS();
      this.getPersonas();
      this.getMaquinas();
    }
  }
}
</script>
<style scoped>
.datetime-transparent >>> .field-input {
  background-color: transparent;
}

.centered-input >>> input {
	text-align: center !important;
}
</style>