<template fluid>
  <div>
    <v-overlay v-if="isInvalidBrowsers()" opacity="0.96" z-index="10">
      <v-row>
        <v-row justify="center" align="center" style="text-align: center !important;">
        <v-col class="text-center" cols="12" md="12" sm="12">
          <img src="/img/logotipo-300x115.png"/>
        </v-col>
        <v-col class="text-center" cols="12" md="12" sm="12">
          <div class="text-center h3">Mejora tu Experiencia en ManagrX</div>
          <div class="text-center h5">
            El Navegador Web que estas usando actualmente no es soportado por nosotros.
            <br>
            Intenta con alguna de las siguientes opciones para tener una mejor experiencia en ManagrX.
          </div>
          <v-row>
            <v-col cols="3" md="3" sm="12" class="text-center">
              <a href="https://www.google.com/intl/es/chrome/" target="_blank">
                <v-img src="/img/navegadores/chrome.png" max-width="150px" max-height="150px" class="img-browser"/>
              </a>
              <div class="h5">Google Chrome</div>
            </v-col>
            <v-col cols="3" md="3" sm="12">
              <a href="https://www.mozilla.org/es-MX/firefox/new/" target="_blank">
                <v-img src="/img/navegadores/firefox.png" max-width="150px" max-height="150px" class="img-browser"/>
              </a>
              <div class="h5">Mozilla Firefox</div>
            </v-col>
            <v-col cols="3" md="3" sm="12">
              <a href="https://www.microsoft.com/en-us/edge" target="_blank">
                <v-img src="/img/navegadores/edge.png" max-width="150px" max-height="150px" class="img-browser"/>
              </a>            
              <div class="h5">Microsoft Edge</div>
            </v-col>
            <v-col cols="3" md="3" sm="12">
              <a href="https://www.opera.com/es" target="_blank">
                <v-img src="/img/navegadores/opera.png" max-width="150px" max-height="150px" class="img-browser"/>
              </a>
              <div class="h5">Opera</div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      </v-row>
    </v-overlay>
    <v-overlay v-if="$store.state.netError" opacity="0.96" z-index="10">
      <v-row justify="center" align="center" style="text-align: center !important;">
        <v-col class="text-center" cols="12" md="12" sm="12">
          <v-icon x-large>
            mdi-wifi-off
          </v-icon>
        </v-col>
        <v-col class="text-center" cols="12" md="12" sm="12">
          <div class="text-center h3">Conexión Fallida</div>
          <div class="text-center h5">Revise su conexión a Internet.</div>
        </v-col>
      </v-row>
    </v-overlay>
    <signal-r
      v-if="rutasAprendizaje"
      path="/hub/aprendizaje" @change="acciones"
    ></signal-r>
    <main>
      <transition mode="out-in">
        <router-view />
      </transition>
    </main>
  </div>
</template>

<style lang="scss">
@import "@/styles/index.scss";

/* Remove in 1.2 */
.v-datatable thead th.column.sortable i {
  vertical-align: unset;
}
</style>
<script>
import SignalR from '@/components/SignalR.vue';
import { mapState } from "vuex"

// checks to see if auth jwt token is valid or has expired, if it gets back 401 error log out
export default {
  components: { SignalR },
  computed:{
    ...mapState(["rutasAprendizaje"])
  },
  created: function() {
    this.$http.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (401 === error.response.status) {
          if (this.$store.getters.authorized) {
            this.$store.dispatch("refreshtoken");
          } else {
            return Promise.reject(error);
          }
        } else {
          return Promise.reject(error);
        }
      }
    );

    this.$store
				.dispatch("getIdsTurnos")
				.then()
				.catch((err) => {
					console.log(err);
				});
  },
  methods: {
    isInvalidBrowsers(){
      return (this.$browserDetect.isIE);
    },
    acciones(event){
      if(event.toLowerCase() == 'recargar'){
        this.$store.dispatch("refreshtoken")
        .then(() => {
          location.reload();
        })
        .catch(error => {
          console.log(error);
        })
      }
    }
  }
};
</script>

<style scoped>

.img-browser{
  display: inline-block !important;
}
</style>
