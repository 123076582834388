<template>
	<v-container
		fluid
		class="d-flex justify-center align-center flex-column"
		style="min-height: 70vh"
	>
		<div class="animation-container">
			<lottie
				id="cube"
				:options="cubeAnimationOptions"
				:width="500"
				:key="`cube-${cubeKey}`"
				class="py-0"
			/>
		</div>
		<div class="launch-container">
			<v-btn
				x-large
				color="primary"
				class="custom-btn"
				:disabled="deshabilitarBoton"
				@click="lanzarSimulacion"
			>
				{{
					estadoSimulacion.simulacion
						? "Simulación en curso"
						: "Lanzar simulación"
				}}
			</v-btn>
			<div class="text-center py-2">
				<template v-if="estadoSimulacion.simulacion">
					<p class="text--primary" style="font-size: 16px !important">
						Inicio de la simulación:
						{{ estadoSimulacion.fechaInicio || "N/A" }}
					</p>
					<p class="text--primary" style="font-size: 16px !important">
						Finalización aproximada de la simulación:
						{{ estadoSimulacion.fechaFinAproximado || "N/A" }}
					</p>
				</template>
				<template v-else>
					<p class="text--primary" style="font-size: 16px !important">
						Última simulación realizada:
						{{ estadoSimulacion.fechaInicio || "N/A" }}
					</p>
					<p class="text--primary" style="font-size: 16px !important">
						Duración de la última simulación:
						{{ estadoSimulacion.duracionSimulacion || "N/A" }}
					</p>
				</template>
			</div>
		</div>
	</v-container>
</template>

<script>
import Lottie from "vue-lottie";
import * as cubeAnimation from "@/assets/cube.json";
import axios from "axios";

export default {
	components: {
		lottie: Lottie,
	},
	props: {
		loadingValidation: {
			type: Boolean,
			default: false,
		},
		validationSuccess: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			cubeKey: 0,
			cubeAnimationOptions: {
				animationData: cubeAnimation.default,
				loop: false,
				speed: 1,
				autoplay: false,
			},
			loadingEstadoSimulacion: false,
			estadoSimulacion: {
				simulacion: false,
				fechaInicio: "",
				fechaFinAproximado: "",
				duracionSimulacion: "",
				fechaProgramada: "",
			},
		};
	},
	computed: {
		validationLoading() {
			return this.loadingValidation;
		},
		deshabilitarBoton() {
			return (
				this.validationLoading ||
				!this.validationSuccess ||
				this.loadingEstadoSimulacion ||
				this.estadoSimulacion.simulacion
			);
		},
	},
	mounted() {
		this.initialize();
	},
	methods: {
		startAnimation() {
			this.$set(this.cubeAnimationOptions, "loop", true);
			this.$set(this.cubeAnimationOptions, "autoplay", true);
			this.cubeKey++;
		},
		stopAnimation() {
			this.$set(this.cubeAnimationOptions, "loop", false);
			this.$set(this.cubeAnimationOptions, "autoplay", false);
			this.cubeKey++;
		},
		lanzarSimulacion() {
			this.loadingEstadoSimulacion = true;

			axios
				.get("/TrabajoOrdenes/GenerarTiemposProduccion")
				.then(() => {
					this.estadoSimulacion.simulacion = true;
					this.startAnimation();
					this.getEstadoSimulacion(false);
				})
				.catch((error) => {
					this.loadingEstadoSimulacion = false;
					console.log(error);
				});
		},
		initialize() {
			this.getEstadoSimulacion();
		},
		getEstadoSimulacion(validateAnimation = true) {
			this.loadingEstadoSimulacion = true;
			axios
				.get("/TrabajoOrdenes/EstadoSimulacion")
				.then((response) => {
					this.loadingEstadoSimulacion = false;
					this.estadoSimulacion = response.data;
					if (validateAnimation) {
						if (this.estadoSimulacion.simulacion)
							this.startAnimation();
						else this.stopAnimation();
					}
				})
				.catch((error) => {
					this.loadingEstadoSimulacion = false;
					this.stopAnimation();
					console.log(error);
				});
		},
	},
};
</script>

<style scoped>
.custom-btn {
	min-width: 500px !important;
	min-height: 75px !important;
}

.custom-btn >>> span {
	font-size: 20px !important;
}

.animation-container {
	position: relative;
	top: 0px;
}

.launch-container {
	position: relative;
	top: -110px;
}
</style>