<template>
	<div>
		<v-tabs v-model="tab" background-color="transparent" grow>
			<v-tab
				@click="departamentosClick"
			>
				Departamentos
			</v-tab>
			<v-tab
				@click="subdepartamentosClick"
			>
				Subdepartamentos
			</v-tab>

			<v-tabs-items v-model="tab" touchless>
				<v-tab-item eager>
                    <Departamentos ref="deptos" />
				</v-tab-item>
				<v-tab-item eager>
                    <Subdepartamentos ref="subdeptos" />
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>
	</div>
</template>

<script>
import Departamentos from "./Departamentos/Departamentos.vue";
import Subdepartamentos from './Departamentos/Subdepartamentos.vue';

export default {
	components: {
		Departamentos,
		Subdepartamentos,
	},
	data(){
        return {
            tab: 0,
        }
	},
	methods: {
		initialize() {
			this.departamentosClick();
            this.subdepartamentosClick();
		},
		departamentosClick() {
			if (typeof this.$refs.deptos === "undefined") return;
			this.$refs.deptos.initialize();
		},
		subdepartamentosClick() {
			if (typeof this.$refs.subdeptos === "undefined") return;
			this.$refs.subdeptos.initialize();
		},
	},
};
</script>