<template>
	<div>
		<v-tabs v-model="tab" background-color="transparent" grow>
			<v-tab
				v-tooltip.bottom="modoAyuda && $help.calidad.incidencias.incidencias"
				v-if="permisoVista('incidencias','r')"
				@click="incidenciasClick"
			>
				Incidencias
			</v-tab>
			<v-tab
				v-tooltip.bottom="modoAyuda && $help.calidad.incidencias.sinEspecificar"
				v-if="permisoVista('defectonodesglozados','r')"
				@click="sinDesgloseClick"
			>
				Incidencias Sin Especificar
			</v-tab>

			<v-tabs-items v-model="tab" touchless>
				<v-tab-item
					v-if="permisoVista('incidencias','r')"
					eager
				>
					<Paros ref="incid" />
				</v-tab-item>
				<v-tab-item
					v-if="permisoVista('defectonodesglozados','r')"
					eager
				>
					<SinDesglosar ref="sindesg" />
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import Paros from './Paros/Paros.vue';
import SinDesglosar from './Paros/SinDesglosar.vue';

export default {
	components: {
		Paros,
		SinDesglosar,
	},
	data: () => ({
		tab: 0,
	}),
	computed:{
		...mapState(["modoAyuda"])
	},
	methods: {
		// leerParametros(){
		// 	if (this.$route.query.idArea != undefined){
		// 		this.$refs.incid.filtroDetalleIncidencia(this.$route.query.idArea);
		// 		this.$router.push('/dashboard/calidad');
		// 	}
		// },
		initialize() {
			this.incidenciasClick();
			this.sinDesgloseClick();
		},
		incidenciasClick(){
			if(typeof this.$refs.incid === 'undefined') return;
			this.$refs.incid.initialize();
		},
		sinDesgloseClick(){
			if(typeof this.$refs.sindesg === 'undefined') return;
			this.$refs.sindesg.initialize();
		},
	},
};
</script>