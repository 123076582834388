<template>
  <v-col cols="12" md="12" sm="12">
    <v-dialog
      v-model="descargarDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-center">
          <lottie :options="checkOptions" :height="200" :width="200"/>
          <a class="headline" target="__blank" :href="'/api/Inventario/DescargarQR/'+ficheroDescargar+'?access_token='+token"><v-icon color="primary" large>mdi-download</v-icon> Descargar Códigos QR</a>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="ficheroDescargar = ''; descargarDialog = false; ">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" class="pt-0 d-flex justify-space-between">
        <download-btn
          v-if="permisoVista('movimientos', 'r')"
          color="blue darken-1"
          text
          @click="descargarReporte"
          label="Descargar Reporte<br>de Resumen de Movimientos XLSX"
        ></download-btn>
        <download-btn
          v-if="permisoVista('movimientos', 'r')"
          color="blue darken-1"
          text
          @click="descargarReportePDF"
          label="Descargar Reporte<br>de Resumen de Movimientos PDF"
        ></download-btn>
        <v-btn
          v-if="trazabilidadInventario"
          text
          color="primary"
          style="font-size: 12px !important"
          v-html="`Nuevo Movimiento<br>entre Almacenes`"
          @click="$emit('showDialogMovimientoAlmacenes')"
        ></v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headersFiltrados"
      :items="items"
      :loading="loading"
      :sort-by="['fecha']"
      :sort-desc="[true]"
      :page.sync="page"
      :item-class="rowSospechoso"
    >
      <template v-slot:item.icono="{ item }">
        <v-icon v-if="item.idTipoMovimiento==6" :color="colores[0]" small>{{'mdi-'+iconos[0]}}</v-icon>
        <v-icon v-else-if="item.idTipoMovimiento==7" color="orange" small>{{'mdi-swap-horizontal-bold'}}</v-icon>
        <v-icon v-else :color="colores[item.idTipoMovimiento -1]" small>{{'mdi-'+iconos[item.idTipoMovimiento -1]}}</v-icon>
      </template>
      <template v-slot:item.fecha="{ item }">
        {{item.fechaLabel}}
      </template>
      <template v-slot:item.fecha="{ item }">
        {{item.fechaLabel}}
      </template>
      <template v-slot:item.numerodeFactura="{ item }">
        {{item.numerodeFactura == null ? 'N/A' : item.numerodeFactura}}
      </template>
      <template v-slot:item.articulo="{ item }">
        {{
          item.codigoArticulo!=null
          ? `[${item.codigoArticulo}] - ${item.articulo}`
          : `${item.articulo}`
        }}
      </template>
      <template v-slot:item.cantidad="{ item }">
        {{item.cantidad%1!=0?item.cantidad.toFixed(4):item.cantidad}} {{item.unidad}}
      </template>
      <template v-slot:item.cliente="{ item }">
        {{item.cliente != null ? item.cliente: nombreEmpresa}}
      </template>
      <template v-slot:item.trabajoOrden="{ item }">
        {{item.trabajoOrden != null ? item.trabajoOrden: (item.idTipoMovimiento == 1)?'N/D':'N/A' }}
      </template>
      <template v-slot:item.articuloProducir="{ item }">
        {{item.articuloProducir != null ? item.articuloProducir: (item.idTipoMovimiento == 1)?'N/D':'N/A' }}
      </template>
      <template v-slot:item.cliente="{ item }">
        {{ item.cliente != null ? item.cliente: item.idTipoMovimiento == 1 ? nombreEmpresa : 'N/A'}}
      </template>
      <template v-slot:item.sospechoso="{ item }">
        {{!!item.sospechoso?'Si':'No'}}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip v-if="permisoVista('movimientos', 'r')"  bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              v-show="validarDescarga(item)"
              small
              class="mr-2"
              @click="downloadItem(item)"
            >
              mdi-download
            </v-icon>
          </template>
          <span class="white--text">Descargar Etiquetas</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import axios from "axios"
import { mapState } from "vuex"
import moment from "moment";
import Lottie from 'vue-lottie';
import * as checkData from '@/assets/check.json';
import DownloadBtn from '../../../DownloadBtn.vue';

export default {
  props: {
    validarDescarga: {
      type: Function,
      default: () => false
    }
  },
  components:{
    'lottie': Lottie,
    DownloadBtn
  },
  data: () => ({
    checkOptions: {
      animationData: checkData.default,
      loop:false,
      speeed: 0.5
    },
    page:1,
    iconos:['arrow-left','arrow-right','plus','redo','cached'],
    colores:['red','green','black','orange','brow'],
    items:[],
    loading: false,
    parametros:"",
    headers: [
      {
        text: '',
        value: 'icono',
        width:'2%',
        sortable: false,
      },
      {
        text: 'Fecha',
        value: 'fecha',
        width:'10%',
        align: 'center',
      },
      {
        text: '# Factura',
        value: 'numerodeFactura',
        width:'10%',
        align: 'center',
      },
      {
        text: 'Tipo Movimiento',
        value: 'tipoMovimiento',
        width:'10%',
        align: 'center',
      },
      {
        text: "Almacén",
        align: "center",
        value: "almacen"
      },
      {
        text: "Subalmacén",
        align: "center",
        value: "subalmacen"
      },
      {
        text: 'Artículo',
        value: 'articulo',
        width:'25%',
        align: 'center',
      },
      {
        text: 'Cantidad',
        value: 'cantidad',
        width:'10%',
        align: 'center',
      },
      {
        text: 'Cliente',
        value: 'cliente',
        width:'10%',
        align: 'center',
      },
      {
        text: 'Orden de Trabajo',
        value: 'trabajoOrden',
        width:'11%',
        align: 'center',
      },
      {
        text: 'Artículo Producir',
        value: 'articuloProducir',
        width:'16%',
        align: 'center',
      },
      {
        text: 'Sospechoso',
        value: 'sospechoso',
        align: 'center',
      },
      {
        text: 'Acciones',
        value: 'actions',
        width:'6%',
        align: 'center',
      },
    ],
    ficheroDescargar:"",
    descargarDialog:false,
  }),
  computed: {
    ...mapState({
      nombreEmpresa: "nombreEmpresa",
      token: "token",
      trazabilidadInventario: "trazabilidadInventario",
      movimientosFactura: "movimientosFactura"
    }),
    headersFiltrados(){
      
      /* if(this.trazabilidadInventario) return this.headers;
      else return this.headers.filter(h => h.value != 'actions'); */

      if(!this.trazabilidadInventario) this.headers = this.headers.filter(h => h.value != 'actions');
      
      if(!this.movimientosFactura) this.headers = this.headers.filter(h => h.value != 'numerodeFactura');
      
      return this.headers;
    }
  },
   methods: {
    rowSospechoso(item){
      return !!item.sospechoso?'red--text':'black--text';
    },
    getData(parametros){
      this.parametros = parametros;
      this.loading = true;
      this.items = [];
      axios
        .get("/Inventario/Movimientos"+parametros)
        .then((response) => {
          this.page = 1;
          this.loading = false;
          this.items = response.data;
          
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    downloadItem(item){
      axios
      .get("/Inventario/ReimprimirQRCodes/"+item.idMovimiento)
      .then(response => {
        this.ficheroDescargar = response.data;
        this.descargarDialog = true;
      })
      .catch(error => {
        console.log(error);
      });
    },
    descargarReporte(){
      var datetime = moment().format('YYYYMMDDHHmmss');
      this.$utils.axios.downloadFile("/Inventario/MovimientosExportar"+this.parametros,'GET','resumen-movimientos-'+datetime+'.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    },
    descargarReportePDF(){
      var datetime = moment().format('YYYYMMDDHHmmss');
      this.$utils.axios.downloadFile("/Inventario/MovimientosResumenPdf"+this.parametros,'GET','resumen-movimientos-'+datetime+'.pdf','application/pdf');
    },
  }
}
</script>