import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCol,{attrs:{"cols":"12"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"35%"},model:{value:(_vm.dialogActualizar),callback:function ($$v) {_vm.dialogActualizar=$$v},expression:"dialogActualizar"}},[_c(VCard,[_c(VCardText,[_c('p',{staticClass:"pt-4 text-h5 font-weight-medium"},[_vm._v(" ¿Está seguro de desactivar la Salida Automática del siguiente artículo? ")]),_c('p',{staticStyle:{"font-size":"16px !important"}},[_vm._v(" • "),(_vm.itemSalida.codigo != null)?_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"16px !important"}},[_vm._v("["+_vm._s(_vm.itemSalida.codigo)+"] - ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.itemSalida.nombre)+" ")])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"success","text":"","disabled":_vm.updatingSalida},on:{"click":function($event){return _vm.closeDialogActualizar()}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"error","text":"","disabled":_vm.updatingSalida,"loading":_vm.updatingSalida},on:{"click":function($event){return _vm.actualizarSalida()}}},[_vm._v("Aceptar")])],1)],1)],1),_c(VDataTable,{attrs:{"items":_vm.salidas,"headers":_vm.salidasHeaders,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.articulo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNombreArticulo(item))+" ")]}},{key:"item.salidaAutomatica",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticClass:"font-weight-bold",attrs:{"color":item.salidaAutomatica ? 'green' : 'red',"outlined":""}},[_vm._v(" "+_vm._s(item.salidaAutomatica ? "Activada" : "Desactivada")+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-1",attrs:{"small":""},on:{"click":function($event){return _vm.showDialogActualizar(item)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Desactivar")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }