<template>
	<v-container fluid>
		<v-dialog v-model="dialogDelete" persistent max-width="450px">
			<v-card>
				<v-card-title style="word-break: break-word"
					>Are you sure that you want to delete this
					element?</v-card-title
				>
				<v-card-text>
					This action will be permanent and cannot be reversed
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="deleting"
						@click="closeDialog()"
						>Cancel</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="deleting"
						:loading="deleting"
						@click="deleteElement()"
						>Delete</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog" persistent max-width="450px">
			<v-card>
				<v-card-title
					>{{
						editedItem.idPurchaseClass == null ? "Create" : "Edit"
					}}
					Purchase Class</v-card-title
				>
				<v-card-text>
					<v-container fluid>
						<v-row>
							<v-col cols="12">
								<v-text-field
									v-model.trim="editedItem.nombre"
									label="Name"
									clearable
									:error-messages="errorItem.errorName"
									:disabled="saving"
									@focus="$set(errorItem, 'errorName', null)"
								></v-text-field>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="saving"
						@click="closeDialog()"
						>Cancel</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="saving"
						:loading="saving"
						@click="save()"
						>Save</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row v-if="permisoVista('tickets-catalogs','c')">
			<v-col cols="12" class="pb-0">
				<v-btn color="primary" :disabled="loading" @click="showDialog()"
					>Create Purchase Class</v-btn
				>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" class="pt-0">
				<v-data-table
					:items="purchaseClasses"
					:headers="purchaseClassesHeaders"
					:loading="loading"
					loading-text="Loading..."
				>
					<template v-slot:[`item.actions`]="{ item }">
						<v-tooltip v-if="permisoVista('tickets-catalogs','u')" bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									class="mr-2"
									:disabled="loading"
									@click="showDialog(item)"
								>
									mdi-pencil
								</v-icon>
							</template>
							<span class="white--text">Edit</span>
						</v-tooltip>
						<v-tooltip v-if="permisoVista('tickets-catalogs','d')" bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									class="mr-2"
									:disabled="loading"
									@click="showDialog(item, true)"
								>
									delete
								</v-icon>
							</template>
							<span class="white--text">Delete</span>
						</v-tooltip>
					</template>
					<template v-slot:no-data> No data available </template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			deleting: false,
			dialogDelete: false,
			saving: false,
			dialog: false,
			errorItem: {
				errorName: null,
			},
			editedItem: {
				idPurchaseClass: null,
				nombre: null,
			},
			defaultEditedItem: {
				idPurchaseClass: null,
				nombre: null,
			},
			purchaseClasses: [],
			purchaseClassesHeaders: [
				{
					text: "Name",
					align: "center",
					value: "nombre",
				},
				{
					text: "Actions",
					align: "center",
					value: "actions",
					width: "5%",
				},
			],
			loading: false,
		};
	},
	mounted() {
		this.initialize();
	},
	methods: {
		deleteElement() {
			this.deleting = true;

			axios
				.put(
					`/PurchaseClass/Desactivar/${this.editedItem.idPurchaseClass}`
				)
				.then(() => {
					this.deleting = false;
					this.closeDialog();
					this.initialize();
				})
				.catch((error) => {
					this.deleting = false;
					console.log(error);
				});
		},
		resetErrors() {
			Object.keys(this.errorItem).forEach(
				(key) => (this.errorItem[key] = null)
			);
		},
		validate() {
			this.resetErrors();

			if (!this.editedItem.nombre) this.errorItem.errorName = "Required";

			return Object.values(this.errorItem).every(
				(value) => value == null
			);
		},
		save() {
			if (!this.validate()) return;

			this.saving = true;

			axios({
				url:
					this.editedItem.idPurchaseClass == null
						? "/PurchaseClass/Crear"
						: `/PurchaseClass/Actualizar/${this.editedItem.idPurchaseClass}`,
				method:
					this.editedItem.idPurchaseClass == null ? "POST" : "PUT",
				data: this.editedItem,
			})
				.then(() => {
					this.saving = false;
					this.closeDialog();
					this.initialize();
				})
				.catch((error) => {
					console.log(error);
					this.saving = false;
					if (error.response?.status == 409) {
						this.errorItem.errorName = "Already exists";
					}
				});
		},
		closeDialog() {
			this.dialog = false;
			this.dialogDelete = false;

			setTimeout(() => {
				this.editedItem = Object.assign({}, this.defaultEditedItem);
				this.resetErrors();
			}, 200);
		},
		showDialog(item = null, modeDelete = false) {
			this.editedItem = Object.assign({}, item || this.defaultEditedItem);

			if (modeDelete) this.dialogDelete = true;
			else this.dialog = true;
		},
		initialize() {
			this.getPurchaseClasses();
		},
		getPurchaseClasses() {
			this.loading = true;

			axios
				.get("/PurchaseClass/GetPurchaseClass")
				.then((response) => {
					this.purchaseClasses = response.data;
				})
				.catch((error) => {
					this.purchaseClasses = [];
					console.log(error);
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>