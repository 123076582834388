<template>
	<v-col cols="12">
		<QRBarCodeReader
			v-model="dialogScanner"
			ref="barcodeScanner"
			@decode="leerCodigo"
		/>
		<v-dialog v-model="dialogReportar" persistent max-width="500px">
			<v-card>
				<v-card-title>Reportar Ticket</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col
								cols="12"
								class="py-0"
							>
							
								<v-autocomplete
									v-model="editedItem.idCausa"
									label="Causa del Ticket"
									:items="causasFiltradas()"
									:item-text="nombreCausa"
									item-value="idCausa"
									clearable
									:error-messages="errorCausa"
									@focus="errorCausa = ''"
									@change="filtroSubcausas()"
								></v-autocomplete>
							</v-col>
							<v-col
								cols="12"
								v-if="editedItem.idCausa == 0"
								class="py-0"
							>
							{{ editedItem.otraCausa }}
								<v-text-field
									v-model="editedItem.otraCausa"
									label="Indique Otra Causa"
									clearable
									:error-messages="errorOtraCausa"
									@focus="errorOtraCausa = ''"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								class="py-0"
								v-if="editedItem.idCausa > 0 && !cualquierSolucion"
							>
								<v-autocomplete
									v-model="editedItem.idSubcausa"
									label="SubCausa del Ticket (Opcional)"
									:items="subcausasFiltradas"
									item-value="idSubCausa"
                  					:item-text="nombreSubCausa"
									clearable
									:error-messages="errorSubCausa"
									@focus="errorCausa = ''"
								></v-autocomplete>
							</v-col>
							<v-col
								cols="12"
								v-if="editedItem.idSubcausa == 0 || editedItem.idCausa == 0"
								class="py-0"
							>
								<v-text-field
									v-model="editedItem.otraSubCausa"
									label="Indique Otra SubCausa (Opcional)"
									clearable
									:error-messages="errorOtraSubCausa"
									@focus="errorOtraSubCausa = ''"
								></v-text-field>
							</v-col>
							<v-col cols="12"
								v-if="editedItem.idCausa > 0 && (editedItem.idSubcausa > 0 || editedItem.idSubcausa == null)"
								class="py-0"
							>
								<v-autocomplete
									v-model="editedItem.idSolucion"
									label="Solución del Ticket"
									:items="solucionesFiltradas()"
									:item-text="nombreSolucion"
									item-value="idSolucion"
									clearable
									:error-messages="errorSolucion"
									@focus="errorSolucion = ''"
								></v-autocomplete>
							</v-col>
							<v-col
								cols="12"
								v-if="editedItem.idSolucion == 0 || editedItem.idSubcausa == 0 || editedItem.idCausa == 0"
								class="py-0"
							>
								<v-text-field
									v-model="editedItem.otraSolucion"
									label="Indique Otra Solución"
									clearable
									:error-messages="errorOtraSolucion"
									@focus="errorOtraSolucion = ''"
								></v-text-field>
							</v-col>
							<template
								v-if="factoresFiltrados() != null"
							>
								<v-col cols="12" class="py-0">
									<v-autocomplete
										v-for="(factor,idxf) in factoresFiltrados().factores"
										:key="'factor-'+idxf"
										:label="factor.nombre"
										:items="factor.subFactores"
										item-value="idSubFactor"
										item-text="nombre"
										clearable
										@click:clear="eliminarFactor(factor.idFactor)"
										@change="agregarActualizarFactor(factor.idFactor, $event)"
									>
									</v-autocomplete>
								</v-col>
							</template>
							<v-col
									v-if="errorFactor && factoresFiltrados() != null"
									cols="12"
									class="py-0 red--text"
								>
									Debe de seleccionar {{factoresFiltrados().factores.length}} Factores
								</v-col>
							<v-col cols="12" class="py-0">
								<v-autocomplete
									v-model="editedItem.idsPersonas"
									:items="personasFiltradas()"
									item-value="idPersona"
									:item-text="nombreOperador"
									label="Personas"
									multiple
									chips
									deletable-chips
									:error-messages="errorPersonas"
									@focus="errorPersonas = ''"
								>
								</v-autocomplete>
							</v-col>
							<v-col cols="12" class="py-0"
								v-if="editedItem.area != null && (editedItem.departamento || '').toUpperCase() == 'MANTENIMIENTO'"
							>
								<v-autocomplete
									v-model="editedItem.idEquipo"
									:items="equiposFiltrados()"
									item-value="idEquipo"
									:item-text="nombreEquipo"
									label="Equipo"
									append-outer-icon="mdi-qrcode-scan"
									:error-messages="errorEquipo"
									@focus="errorEquipo = ''"
									@click:append-outer="showDialogScanner"
								></v-autocomplete>
							</v-col>
							<v-col cols="12" class="py-0">
								<v-row class="ma-0 pa-0">
									<v-col cols="3" />
									<v-col cols="6" class="text-center">
										<div>Número de Operación</div>
										<v-otp-input
											v-model="editedItem.numOperacion"
											type="number"
										  length="3"
										></v-otp-input>

									</v-col>
									<v-col cols="3" />
								</v-row>
							</v-col>
							<v-col cols="12" class="py-0">
								<v-textarea
									v-model="editedItem.comentarios"
									label="Comentarios"
									auto-grow
									rows="3"
									clearable
									outlined
								></v-textarea>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="saving"
						@click="cerrarDialog()"
						>Cancelar</v-btn
					>
					<v-btn text color="error" :disabled="saving" @click="save()"
						>Reportar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogDetalles" persistent max-width="500px">
			<v-card>
				<v-card-title>Detalles del Ticket</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12">
								<v-data-table
									:items="[editedItem]"
									hide-default-footer
									:items-per-page="-1"
								>
									<template v-slot:body="{ items }">
										<tbody>
											<tr
												v-for="(
													header, idx
												) in ticketsDetallesHeadersAvanzado()"
												:key="`header-${idx}`"
											>
												<td class="font-weight-bold">
													{{ header.text }}
												</td>
												<td
													v-for="(
														item, idx2
													) in items"
													:key="`td-${idx}-${idx2}`"
												>
													<template v-if="header.value == 'personas'">
														<div v-if="item.personas != null && item.personas.length > 0">
															<ul>
																<li v-for="(persona, idx) in item.personas" :key="'per-'+idx">{{persona.nombre}}</li>
															</ul>
														</div>
														<div v-else>
															No Especificado
														</div>
													</template>
													<div v-else>
														{{ printCelda(header,item) }}
													</div>
												</td>
											</tr>
										</tbody>
									</template>
								</v-data-table>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text color="success" @click="cerrarDialog()"
						>Cerrar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="editarDetalles" persistent max-width="500px">
			<v-card>
				<v-card-title>Editar detalles del Ticket</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12" md="12" xl="12" class="py-0">
								<v-text-field label="RecordId" readonly v-model="editedItemticket.recordID" ></v-text-field>
							</v-col>
							<v-col
								cols="12"
								class="py-0"
							>
								<v-autocomplete
									v-model="editedItemticket.idCausa"
									label="Causa del Ticket"
									:items="causasFiltradasEditTicket()"
									:item-text="nombreCausa"
									item-value="idCausa"
									clearable
									:error-messages="errorCausa"
									@focus="errorCausa = ''"
									@change="filtroSubcausas()"
								></v-autocomplete>
							</v-col>
							<v-col
								cols="12"
								v-if="editedItemticket.idCausa == 0"
								class="py-0"
							>
								<v-text-field
									v-model="editedItemticket.otraCausa"
									label="Indique Otra Causa"
									clearable
									:error-messages="errorOtraCausa"
									@focus="errorOtraCausa = ''"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								class="py-0"
								v-if="editedItemticket.idCausa > 0 && !cualquierSolucion"
							>
								<v-autocomplete
									v-model="editedItemticket.idSubCausa"
									label="SubCausa del Ticket (Opcional)"
									:items="subcausasFiltradas"
									item-value="idSubCausa"
                  					:item-text="nombreSubCausa"
									clearable
									:error-messages="errorSubCausa"
									@focus="errorCausa = ''"
								></v-autocomplete>
							</v-col>
							<v-col
								cols="12"
								v-if="editedItemticket.idSubcausa == 0 || editedItemticket.idCausa == 0"
								class="py-0"
							>
								<v-text-field
									v-model="editedItemticket.otraSubCausa"
									label="Indique Otra SubCausa (Opcional)"
									clearable
									:error-messages="errorOtraSubCausa"
									@focus="errorOtraSubCausa = ''"
								></v-text-field>
							</v-col>
							<v-col cols="12"
								v-if="editedItemticket.idCausa > 0 && (editedItemticket.idSubcausa > 0 || editedItemticket.idSubcausa == null)"
								class="py-0"
							>
								<v-autocomplete
									v-model="editedItemticket.idSolucion"
									label="Solución del Ticket"
									:items="solucionesFiltradasEditTicket()"
									:item-text="nombreSolucion"
									item-value="idSolucion"
									clearable
									:error-messages="errorSolucion"
									@focus="errorSolucion = ''"
								></v-autocomplete>
							</v-col>
							<v-col
								cols="12"
								v-if="editedItemticket.idSolucion == 0 || editedItemticket.idSubcausa == 0 || editedItemticket.idCausa == 0"
								class="py-0"
							>
								<v-text-field
									v-model="editedItemticket.otraSolucion"
									label="Indique Otra Solución"
									clearable
									:error-messages="errorOtraSolucion"
									@focus="errorOtraSolucion = ''"
								></v-text-field>
							</v-col>
							<template
								v-if="factoresFiltradosTicekts() != null"
							>
								<v-col cols="12" class="py-0">
									<v-autocomplete
										v-for="(factor,idxf) in factoresFiltradosTicekts().factores"
										:key="'factorxdf-'+idxf"
										:label="factor.nombre"
										:items="factor.subFactores"
										v-model="editedItemticket.factores[idxf].idSubFactor"
										item-value="idSubFactor"
										item-text="nombre"
										clearable
										@click:clear="eliminarFactor(factor.idFactor)"
										@change="ActualizarFactorTicket(factor.idFactor, editedItemticket.factores[idxf].idSubFactor, idxf, factor.nombre, factor)"
									>
									</v-autocomplete>
								</v-col>
							</template>
							<v-col
									v-if="errorFactor && factoresFiltrados() != null"
									cols="12"
									class="py-0 red--text"
								>
									Debe de seleccionar {{factoresFiltrados().factores.length}} Factores
								</v-col>
							<v-col cols="12" class="py-0">
								<v-autocomplete
									v-model="editedItemticket.idsPersonas"
									:items="personasFiltradasEditTicket()"
									item-value="idPersona"
									:item-text="nombreOperador"
									label="Personas"
									multiple
									chips
									deletable-chips
									:error-messages="errorPersonas"
									@focus="errorPersonas = ''"
								>
								</v-autocomplete>
							</v-col>
							<v-col cols="12" class="py-0"
								v-if="editedItemticket.area != null && (editedItemticket.departamento || '').toUpperCase() == 'MANTENIMIENTO'"
							>
								<v-autocomplete
									v-model="editedItemticket.idEquipo"
									:items="equiposFiltrados()"
									item-value="idEquipo"
									:item-text="nombreEquipo"
									label="Equipo"
									append-outer-icon="mdi-qrcode-scan"
									:error-messages="errorEquipo"
									@focus="errorEquipo = ''"
									@click:append-outer="showDialogScanner"
								></v-autocomplete>
							</v-col>
							<v-col cols="12" class="py-0">
								<v-row class="ma-0 pa-0">
									<v-col cols="3" />
									<v-col cols="6" class="text-center">
										<div>Número de Operación</div>
										<v-otp-input
											v-model="editedItemticket.numOperacion"
											type="number"
										  length="3"
										></v-otp-input>

									</v-col>
									<v-col cols="3" />
								</v-row>
							</v-col>
							<v-col cols="12" class="py-0">
								<v-textarea
									v-model="editedItemticket.comentarios"
									label="Comentarios"
									auto-grow
									rows="3"
									clearable
									outlined
								></v-textarea>
							</v-col>
						</v-row>						
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text color="success" :disabled="loadSaveEdit" :loading="loadSaveEdit" @click="editarDetalles = false"
						>Cerrar</v-btn
					>
					<v-btn text color="error" :disabled="loadSaveEdit" :loading="loadSaveEdit" @click="saveEdit()"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row class="px-2">
			<v-col cols="3" class="pt-5">
				<VueCtkDateTimePicker
					id="filtroDesde"
					v-model="filtros.inicio"
					label="Desde"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="getTicketsAction(1)"
				/>
			</v-col>
			<v-col cols="3" class="pt-5">
				<VueCtkDateTimePicker
					id="filtroHasta"
					v-model="filtros.fin"
					:min-date="filtros.inicio"
					label="Hasta"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="getTicketsAction(1)"
				/>
			</v-col>
			<v-col cols="3">
				<v-autocomplete
					v-model="filtros.linea"
					label="Linea"
					:items="lineas"
					hide-details
					clearable
					@change="getTicketsAction(1)"
				></v-autocomplete>
			</v-col>
			<v-col cols="3">
				<v-autocomplete
					v-model="filtros.departamento"
					label="Departamento"
					:items="departamentos"
					hide-details
					clearable
					@change="getTicketsAction(1)"
				></v-autocomplete>
			</v-col>
			<v-col cols="2">
				<v-autocomplete
					v-model="filtros.recordId"
					label="Record"
					:items="recordsIds"
					hide-details
					clearable
					@change="getTicketsAction(1)"
				></v-autocomplete>
			</v-col>
			<v-col cols="3">
				<v-autocomplete
					v-model="filtros.lineaArea"
					label="Área"
					:items="areas"
					hide-details
					clearable
					@change="getTicketsAction(1)"
				></v-autocomplete>
			</v-col>
			<v-col cols="2">
				<v-select
					v-model="filtros.idEstado"
					label="Estado"
					:items="tiposEstado"
					hide-details
					clearable
					@change="getTicketsAction(1)"
				></v-select>
			</v-col>
			<v-col cols="2">
				<v-select
					v-model="filtros.estado"
					label="Estado de Finalización"
					:items="tiposFinalizacion"
					hide-details
					clearable
					@change="getTicketsAction(1)"
				></v-select>
			</v-col>
			<v-col cols="3">
				<v-select
					v-model="filtros.idTurno"
					label="Turnos"
					:items="turnos"
					hide-details
					clearable
					item-text="nombre"
          			item-value="idTurno"
					:loading="loadingTurnos"
					:disabled="loadingTurnos"
					@change="getTicketsAction(1)"
				></v-select>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" class="pa-0 d-flex align-center justify-space-between">
				<v-btn 
					color="primary"
					text
					class="my-0"
					:disabled="downloading || loading"
					:loading="downloading"
					@click="descargarReporte"
				>
					Descargar Reporte
					<br/> de Tickets
				</v-btn>
				<v-btn 
					color="primary"
					text
					:disabled="downloading || loading"
					:loading="downloading"
					@click="descargarReporteX5"
				>
					Descargar Reporte
					<br/> X5
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
					:items="tickets"
					:headers="ticketsHeaders"
					:loading="loading"
					loading-text="Cargando... Espere, Por Favor."
					:server-items-length="tickets_total"
					:items-per-page="tickets_items_per_page"
					:page="tickets_actual_page"
					@update:page="getTicketsAction"
					@update:items-per-page="
						(ipp) => (tickets_items_per_page = ipp)
					"
					:footer-props="{
						disableItemsPerPage: true,
						disablePagination: this.loading,
					}"
					:disable-sort="true"
					:disable-pagination="loading"
				>
					<template v-slot:[`item.personas`]="{ item }">
						<ul v-if="!item.estado && item.personas.length > 0">
							<li v-for="(persona,key) in item.personas" :key="'per-'+key">{{persona.nombre}}</li>
						</ul>
						<div v-else-if="!item.estado && item.personas == 0">
							Sin Especificar
						</div>
						<div v-else>
							Por Definir
						</div>
					</template>
					<template v-slot:[`item.estado`]="{ item }">
						{{ item.estado ? "Abierto" : "Cerrado" }}
					</template>
					<template v-slot:[`item.area`]="{ item }">
						{{ item.area == null ? 'N/A' : item.area }}
					</template>
					<template v-slot:[`item.duracionSeg`]="{ item }">
						{{ getTiempoFormat(item.duracionSeg) }}
					</template>
					<template v-slot:[`item.causa`]="{ item }">
						{{item.otraCausa==null?item.causa:item.otraCausa}}
					</template>
					<template v-slot:[`item.subcausa`]="{ item }">
						{{item.otraSubCausa==null?(item.subcausa == null ? "Sin Especificar" :item.subcausa):item.otraSubCausa}}
					</template>
					<template v-slot:[`item.solucion`]="{ item }">
						{{item.otraSolucion==null?item.solucion:item.otraSolucion}}
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									@click="showDialogAction(item)"
								>
									{{
										item.estado
											? "mdi-file-document-edit"
											: "mdi-eye"
									}}
								</v-icon>
							</template>
							<span class="white--text">{{
								item.estado ? "Reportar" : "Ver Detalles"
							}}</span>
						</v-tooltip>
						<v-icon v-if="!item.estado && $store.state.idRol == 1" small class="mr-2" @click="showDialogEdit(item)"> mdi-pencil </v-icon>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapState } from 'vuex';
import QRBarCodeReader from '../../../QRBarCodeReader.vue';
import qs from "qs";

export default {
	components: {
		QRBarCodeReader
	},
	data() {
		return {
			loadSaveEdit:false,
			recordsIds:[],
			editarDetalles: false,
			turnos: null,
			loadingTurnos: false,
			dialogScanner: false,
			downloading: false,
			saving: false,
			lineas: [],
			areas: [],
			departamentos: [],
			subcausasLista: [],
			subcausasFiltradas:[],
			errorCausa: "",
			errorSolucion: "",
			errorOtraCausa: "",
			errorOtraSubCausa: "",
			errorOtraSolucion: "",
			errorPersonas: "",
			causas: [],
			soluciones: [],
			dialogReportar: false,
			dialogDetalles: false,
			editedItem: {
				estado: null,
				recordID: null,
				linea: null,
				departamento: null,
				staff: null,
				inicio: null,
				fin: null,
				duracionSeg: null,
				idCausa: null,
				causa: null,
				otraCausa: null,
				idSubCausa: null,
				subcausa: null,
				otraSubCausa: null,
				idSolucion: null,
				solucion: null,
				otraSolucion: null,
				comentarios: null,
				fechaOrden: null,
				idsPersonas: [],
				idSubcausa: null,
				numOperacion: null,
				area: null,
				personas: [],
				factores: [],
				idEquipo: -1,
			},
			editedItemticket: {
				estado: null,
				recordID: null,
				linea: null,
				departamento: null,
				staff: null,
				inicio: null,
				fin: null,
				duracionSeg: null,
				idCausa: null,
				causa: null,
				otraCausa: null,
				idSubCausa: null,
				subcausa: null,
				otraSubCausa: null,
				idSolucion: null,
				solucion: null,
				otraSolucion: null,
				comentarios: null,
				fechaOrden: null,
				idsPersonas: [],
				idSubcausa: null,
				numOperacion: null,
				area: null,
				personas: [],
				factores: [],
				idEquipo: -1,
			},
			defaultEditedItem: {
				estado: null,
				recordID: null,
				linea: null,
				departamento: null,
				staff: null,
				inicio: null,
				fin: null,
				duracionSeg: null,
				idCausa: null,
				causa: null,
				otraCausa: null,
				idSubCausa: null,
				subcausa: null,
				otraSubCausa: null,
				idSolucion: null,
				solucion: null,
				otraSolucion: null,
				comentarios: null,
				fechaOrden: null,
				idsPersonas: [],
				idSubcausa: null,
				numOperacion: null,
				area: null,
				personas: [],
				factores: [],
				idEquipo: -1,
			},
			editTicket:{
				estado: null,
				recordID: null,
				linea: null,
				departamento: null,
				staff: null,
				inicio: null,
				fin: null,
				duracionSeg: null,
				idCausa: null,
				causa: null,
				otraCausa: null,
				idSubCausa: null,
				subcausa: null,
				otraSubCausa: null,
				idSolucion: null,
				solucion: null,
				otraSolucion: null,
				comentarios: null,
				fechaOrden: null,
				idsPersonas: [],
				idSubcausa: null,
				numOperacion: null,
				area: null,
				personas: [],
				factores: [],
				idEquipo: -1,
			},
			filtros: {
				inicio: null,
				fin: null,
				linea: null,
				departamento: null,
				idEstado: null,
				lineaArea: null,
				estado: null,
				idTurno: null,
				recordId:null
			},
			tiposFinalizacion: [
				{
					text: "En Progreso",
					value: 1,
				},
				{
					text: "Terminados",
					value: 2,
				},
			],
			tiposEstado: [
				{
					text: "Abierto",
					value: true,
				},
				{
					text: "Cerrado",
					value: false,
				},
			],
			ticketsDetallesHeaders: [
				{
					text: "Estado",
					align: "center",
					value: "estado",
				},
				{
					text: "Record",
					align: "center",
					value: "recordID",
				},
				{
					text: "Línea",
					align: "center",
					value: "linea",
				},
				{
					text: "Departamento",
					align: "center",
					value: "departamento",
				},
				{
					text: "Área",
					align: "center",
					value: "area",
				},
				{
					text: "Staff",
					align: "center",
					value: "staff",
				},
				{
					text: "Fecha de Inicio",
					align: "center",
					value: "inicio",
				},
				{
					text: "Fecha de Fin",
					align: "center",
					value: "fin",
				},
				{
					text: "Fecha de Orden",
					align: "center",
					value: "fechaOrden",
				},
				{
					text: "Duración",
					align: "center",
					value: "duracionSeg",
				},
				{
					text: "Causa",
					align: "center",
					value: "causa",
				},
				{
					text: "SubCausa",
					align: "center",
					value: "subcausa",
				},
				{
					text: "Solución",
					align: "center",
					value: "solucion",
				},
				{
					text: "Equipo",
					align: "center",
					value: "equipo",
				},
				{
					text: "Identificación de Activos",
					align: "center",
					value: "identificadorActivos",
				},
				{
					text: "# Operacion",
					align: "center",
					value: "numOperacion",
				},
				{
					text: "Personas",
					align: "center",
					value: "personas",
				},
				{
					text: "Comentarios",
					align: "center",
					value: "comentarios",
				},
			],
			ticketsHeaders: [
				{
					text: "Estado",
					align: "center",
					value: "estado",
				},
				{
					text: "Record",
					align: "center",
					value: "recordID",
				},
				{
					text: "Línea",
					align: "center",
					value: "linea",
				},
				{
					text: "Departamento",
					align: "center",
					value: "departamento",
				},
				{
					text: "Área",
					align: "center",
					value: "area",
				},
				{
					text: "Staff",
					align: "center",
					value: "staff",
				},
				{
					text: "Fecha de Inicio",
					align: "center",
					value: "inicio",
				},
				{
					text: "Fecha de Fin",
					align: "center",
					value: "fin",
				},
				{
					text: "Duración",
					align: "center",
					value: "duracionSeg",
				},
				{
					text: "Causa",
					align: "center",
					value: "causa",
				},
				{
					text: "SubCausa",
					align: "center",
					value: "subcausa",
				},
				{
					text: "Solución",
					align: "center",
					value: "solucion",
				},
				{
					text: "Personas",
					align: "center",
					value: "personas",
				},
				{
					text: "Acciones",
					align: "center",
					value: "actions",
				},
			],
			//Paginacion de Tickets (server side)
			tickets_total: null,
			tickets_items_per_page: 10,
			tickets_actual_page: 1,
			tickets: [],
			loading: false,
			personas: [],
			errores: [],
			factores: [],
			errorFactor:false,
			equipos: [],
			errorEquipo: ""
		};
	},
	computed:{
		...mapState(["token"]),
		cualquierSolucion(){
			if(this.editedItem.idCausa <= 0) return false;
			var e = this.causas.find(x => x.idCausa == this.editedItem.idCausa);
			if(typeof e == 'undefined' || e == null) return false;
			return e.abierto;
		}
	},
	mounted() {
		this.initialize();
		this.getCausas();
			this.getSoluciones();
			this.getLineas();
			this.getDepartamentos();
			this.getAreas();
			this.getPersonas();
			this.getSubcausas();
			this.getFactores();
			this.getEquipos();
			this.getTurnos();
		this.getRecordsIds();
	},
	methods: {
		nombreOperador: (operador) => `[${operador.codigo ?? "N/D"}] - ${operador.nombre} ${operador.paterno ?? ""} ${operador.materno ?? ""}`,
		nombreEquipo: (equipo) => `[${equipo.numero}] - ${equipo.identificador} - ${equipo.descripcion}`,
		nombreCausa: (causa) =>`${causa.codigo == null? `${causa.causa}`: `[${causa.codigo}] - ${causa.causa}`}`,
    	nombreSubCausa: (subcausa) =>`${subcausa.codigosubcausa == null? `${subcausa.subcausa}`: `[${subcausa.codigosubcausa}] - ${subcausa.subcausa}`}`,
		nombreSolucion: (solucion) =>`${solucion.codigo == null? `${solucion.solucion}`: `[${solucion.codigo}] - ${solucion.solucion}`}`,
		leerCodigo(value){
			const parsed = qs.parse(value);
			const { assetId } = JSON.parse(parsed.defaultProperties);

			if(!assetId) return;

			const equipo = this.equiposFiltrados().find(
				(equipo) => equipo.codigoX5?.toUpperCase().trim() == assetId.toUpperCase().trim()
			);

			if(!equipo) return;

			this.editedItem.idEquipo = equipo.idEquipo;
			
			this.$refs.barcodeScanner.onClickCancelar();
		},
		showDialogScanner(){
			this.dialogScanner = true;
		},
		descargarReporte(){
			var datetime = moment().format('YYYYMMDDHHmmss');
			const filename = 'reporte-tickets-'+datetime+'.xlsx';
			const mime = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
			this.downloading = true;
			axios({
				url: `/CausaSolucionRecords/XlsReporteTickets`,
				method: 'GET',
				responseType: 'blob',
				params: {
					access_token: this.token,
					...this.filtros
				}
			})
			.then(response => {
				let blob = new Blob([response.data], { type: mime })
				let a = document.createElement("a") 
				let blobURL = URL.createObjectURL(blob)
				a.download = filename
				a.href = blobURL
				document.body.appendChild(a)
				a.click()
				document.body.removeChild(a)
			})
			.catch(error => {
				console.log(error);
			})
			.finally( () => {
				this.downloading = false;
			})
		},
		descargarReporteX5(){
			var datetime = moment().format('YYYYMMDDHHmmss');
			const filename = 'reporte-tickets-'+datetime+'.csv';
			const mime = "text/csv";
			this.downloading = true;
			axios({
				url: `/CausaSolucionRecords/CsvCausaSolucion`,
				method: 'GET',
				responseType: 'blob',
				params: {
					access_token: this.token,
					...this.filtros
				}
			})
			.then(response => {
				let blob = new Blob([response.data], { type: mime })
				let a = document.createElement("a") 
				let blobURL = URL.createObjectURL(blob)
				a.download = filename
				a.href = blobURL
				document.body.appendChild(a)
				a.click()
				document.body.removeChild(a)
			})
			.catch(error => {
				console.log(error);
			})
			.finally( () => {
				this.downloading = false;
			})
		},
		printCelda(header,item){
			if(header.value.startsWith('factor-')){
				var idx = parseInt(header.value.split('-')[1]);
				return item["factores"][idx]["subFactor"];
			}
			if(header.value == "numOperacion"){
				return item["numOperacion"] ?? "Sin Especificar"
			}
			if(header.value == "estado"){
				return item[header.value]
					? "Abierto"
					: "Desactivado";
			}
			else if(header.value == "fechaOrden"){
				return moment(item[header.value]).format('YYYY-MM-DD HH:mm');
			}
			else if(header.value == "duracionSeg"){
				return this.getTiempoFormat(item[header.value]);
			}
			else if(header.value == "causa"){
				return item["otraCausa"] ?? item[header.value];
			}
			else if(header.value == "subcausa"){
				return item["otraSubCausa"] ?? item[header.value];
			}
			else if(header.value == "solucion"){
				return item["otraSolucion"] ?? item[header.value];
			}
			else if(header.value == "comentarios"){
				return item[header.value] ?? "N/A";
			}
			else if(header.value == "equipo"){
				return item[header.value] ?? "N/D";
			}
			else if(header.value == "identificadorActivos"){
				return item[header.value] ?? "N/D";
			}
			else return item[header.value];
		},
		getTiempoFormat(tiempo) {
			if (tiempo == null || tiempo == 0) return "N/D";
			return moment.utc(tiempo * 1000).format("HH:mm:ss");
		},
		validate() {
			this.errores = [];
			this.errorCausa = "";
			this.errorSolucion = "";
			this.errorOtraCausa = "";
			this.errorOtraSubCausa = "";
			this.errorOtraSolucion = "";
			this.errorPersonas = "";
			this.errorEquipo = "";
			this.errorFactor = false;

			if (this.editedItem.idCausa == null) {
				this.errorCausa = "Debe Seleccionar una Causa";
				this.errores.push("Debe Seleccionar una Causa");
			}

			if (this.editedItem.idCausa > 0 && this.editedItem.idSubCausa > 0 && this.editedItem.idSolucion == null) {
				this.errorSolucion = "Debe Seleccionar una Solución";
				this.errores.push("Debe Seleccionar una Solución");
			}

			if(this.factoresFiltrados() != null){
				if(this.factoresFiltrados().factores.length > this.editedItem.factores.length){
					this.errorFactor = true;
					this.errores.push("Falta Seleccionar al menos un Factor");
				}
			}

			if(this.personasFiltradas().length > 0 && (this.editedItem.idsPersonas == null || this.editedItem.idsPersonas.length == 0)){
				this.errorPersonas = "Debe Indicar Mínimo una Persona";
				this.errores.push("Debe Indicar Mínimo una Persona");
			}

			if( (this.editedItem.idCausa == 0) && (!this.$utils.isValid(this.editedItem.otraCausa) || this.editedItem.otraCausa.length <= 1)){
				this.errorOtraCausa = "Debe de Indicar una Causa";	
				this.errores.push("Debe de Indicar una Causa");
			}

			if( (this.editedItem.idSubcausa == 0 || this.editedItem.idCausa == 0) &&  (!this.$utils.isValid(this.editedItem.otraSubCausa) || this.editedItem.otraSubCausa.length <= 1)){
				this.errorOtraSubCausa = "Debe de Indicar una SubCausa";
				this.errores.push("Debe de Indicar una SubCausa");
			}

			if( (this.editedItem.idSolucion == 0 || this.editedItem.idSubcausa == 0 || this.editedItem.idCausa == 0) && (!this.$utils.isValid(this.editedItem.otraSolucion) || this.editedItem.otraSolucion.length <= 1)){
				this.errorOtraSolucion = "Debe de Indicar una Solución";
				this.errores.push("Debe de Indicar una Solución");
			}

			if( (this.editedItem.departamento  || "").toUpperCase() == 'MANTENIMIENTO' && ( typeof this.editedItem.idEquipo == "undefined" || this.editedItem.idEquipo <= 0)){
				this.errorEquipo = "Debe de Indicar un Equipo";
				this.errores.push("Debe de Indicar un Equipo");
			}

			return this.errores.length == 0;
		},
		validateTicketEdit() {
			this.errores = [];
			this.errorCausa = "";
			this.errorSolucion = "";
			this.errorOtraCausa = "";
			this.errorOtraSubCausa = "";
			this.errorOtraSolucion = "";
			this.errorPersonas = "";
			this.errorEquipo = "";
			this.errorFactor = false;

			if (this.editedItemticket.idCausa == null) {
				this.errorCausa = "Debe Seleccionar una Causa";
				this.errores.push("Debe Seleccionar una Causa");
			}

			if (this.editedItemticket.idCausa > 0 && this.editedItemticket.idSubCausa > 0 && this.editedItemticket.idSolucion == null) {
				this.errorSolucion = "Debe Seleccionar una Solución";
				this.errores.push("Debe Seleccionar una Solución");
			}

			if(this.factoresFiltradosTicekts() != null){
				if(this.factoresFiltradosTicekts().factores.length > this.editedItemticket.factores.length){
					this.errorFactor = true;
					this.errores.push("Falta Seleccionar al menos un Factor");
				}
			}

			if(this.personasFiltradasEditTicket().length > 0 && (this.editedItemticket.idsPersonas == null || this.editedItemticket.idsPersonas.length == 0)){
				this.errorPersonas = "Debe Indicar Mínimo una Persona";
				this.errores.push("Debe Indicar Mínimo una Persona");
			}

			if( (this.editedItemticket.idCausa == 0) && (!this.$utils.isValid(this.editedItemticket.otraCausa) || this.editedItemticket.otraCausa.length <= 1)){
				this.errorOtraCausa = "Debe de Indicar una Causa";	
				this.errores.push("Debe de Indicar una Causa");
			}

			if( (this.editedItemticket.idSubcausa == 0 || this.editedItemticket.idCausa == 0) &&  (!this.$utils.isValid(this.editedItemticket.otraSubCausa) || this.editedItemticket.otraSubCausa.length <= 1)){
				this.errorOtraSubCausa = "Debe de Indicar una SubCausa";
				this.errores.push("Debe de Indicar una SubCausa");
			}

			if( (this.editedItemticket.idSolucion == 0 || this.editedItemticket.idSubcausa == 0 || this.editedItemticket.idCausa == 0) && (!this.$utils.isValid(this.editedItemticket.otraSolucion) || this.editedItemticket.otraSolucion.length <= 1)){
				this.errorOtraSolucion = "Debe de Indicar una Solución";
				this.errores.push("Debe de Indicar una Solución");
			}

			if( (this.editedItemticket.departamento  || "").toUpperCase() == 'MANTENIMIENTO' && ( typeof this.editedItemticket.idEquipo == "undefined" || this.editedItemticket.idEquipo <= 0)){
				this.errorEquipo = "Debe de Indicar un Equipo";
				this.errores.push("Debe de Indicar un Equipo");
			}

			return this.errores.length == 0;
		},
		save() {
			if (!this.validate()) return;

			this.saving = true;
			axios
				.post("/CausaSolucionRecords/Crear", {
					recordId: this.editedItem.recordID,
					idCausa: this.editedItem.idCausa,
					idSubcausa: this.editedItem.idSubcausa,
					idSolucion: this.editedItem.idSolucion,
					otraCausa: this.editedItem.otraCausa,
					otraSubCausa: this.editedItem.otraSubCausa,
					otraSolucion: this.editedItem.otraSolucion,
					comentarios: this.editedItem.comentarios,
					idsPersonas: this.editedItem.idsPersonas,
					numOperacion: this.editedItem.numOperacion,
					factores: this.editedItem.factores,
					idEquipo: ((this.editedItem.departamento || "").toUpperCase() == 'MANTENIMIENTO') ? this.editedItem.idEquipo : null
				})
				.then(() => {
					this.saving = false;
					this.cerrarDialog();
					this.initialize(this.tickets_actual_page);
				})
				.catch((error) => {
					this.saving = false;
					console.log(error);
				});
		},
		cerrarDialog() {
			this.dialogReportar = false;
			this.dialogDetalles = false;
			this.editarDetalles = false	;
			this.editedItem = { ...this.defaultEditedItem };
			this.editedItemticket = { ...this.defaultEditedItem };
			this.editedItem.idsPersonas = [];
			this.errorCausa = "";
			this.errorSolucion = "";
			this.errorOtraCausa = "";
			this.errorOtraSubCausa = "";
			this.errorEquipo = "";
			this.errorOtraSolucion = "";
			this.errorPersonas = "";
			this.errorFactor = false;
		},
		showDialogAction(item) {
			this.editedItem = { ...item };
			if (item.estado) {
				this.dialogReportar = true;
			} else {
				this.dialogDetalles = true;
			}
		},
		showDialogEdit(item) {
			this.editarDetalles = true;
			this.editedItemticket = { ...item };
			this.editedItemticket.idsPersonas = item.personas.length > 0 ? item.personas.map(persona => persona.idPersona) : [];
			this.editedItemticket.factores = item.factores.length > 0 ? item.factores : []

			if (this.factoresFiltradosTicekts() != null) {
				var arrenBusqueda = this.factoresFiltradosTicekts().factores;
				var factorActual = this.editedItemticket.factores

				var idFactoresActual = factorActual.map(item => item.idFactor);
				var idFactoresBusqueda = arrenBusqueda.map(item => item.idFactor);
				var idFactoresFaltantes = idFactoresBusqueda.filter(id => !idFactoresActual.includes(id));
				idFactoresFaltantes.forEach(id => {
					var factorFaltante = arrenBusqueda.find(item => item.idFactor === id);
					factorActual.push({
						idFactor: factorFaltante.idFactor,
						posicion: factorFaltante.posicion,
						factor: factorFaltante.nombre,
						idSubFactor: -1,
						subFactor: null
					});
				});

				factorActual.sort((a, b) => a.idFactor - b.idFactor)

				this.editedItemticket.factores = factorActual
			}
			
			this.filtroSubcausas();
		},
		saveEdit(){
			if (!this.validateTicketEdit()) return;

			this.loadSaveEdit = true;

			var objeto = {
				idEquipo: this.editedItemticket.idEquipo,
				idCausa: this.editedItemticket.idCausa,
				idSubcausa: this.editedItemticket.idSubCausa,
				idSolucion: this.editedItemticket.idSolucion,
				idsPersonas: this.editedItemticket.idsPersonas,
				factores: this.editedItemticket.factores
					.filter(item => item.idSubFactor !== null && item.idSubFactor >= 0)
					.map(item => ({
						idFactor: item.idFactor,
						idSubFactor: item.idSubFactor
					})),
				otraCausa: this.editedItemticket.otraCausa,
				otraSubCausa: this.editedItemticket.otraSubCausa,
				otraSolucion: this.editedItemticket.idSolucion == null ? null : this.editedItemticket.otraSolucion,
				numOperacion: this.editedItemticket.numOperacion,
				comentarios: this.editedItemticket.comentarios,
			}

			axios
            .put(
              "/CausaSolucionRecords/Editar/" +
			  this.editedItemticket.recordID, objeto
            )
            .then((response) => {
				this.cerrarDialog();
				this.initialize(this.tickets_actual_page);
				this.loadSaveEdit = false;
            })
            .catch((error) => {
              console.log(error)
			  this.loadSaveEdit = false;
            });
		},
		initialize(page = 1) {
			this.getTicketsAction(page);
		},
		getEquipos(){
				axios
				.get('/MMCallEquipos')
				.then( response => {
					this.equipos = response.data
				})
				.catch( error => {
					console.log(error);
				});
		},
		getAreas(){
			axios
				.get('/CausaSolucionRecords/ListarLineaAreas')
				.then( response => {
					this.areas = response.data
				})
				.catch( error => {
					console.log(error);
				});
		},
		getLineas() {
			axios
				.get("/CausaSolucionRecords/ListarLinea")
				.then((response) => {
					this.lineas = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getDepartamentos() {
			axios
				.get("/CausaSolucionRecords/ListarDepartamento")
				.then((response) => {
					this.departamentos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getCausas() {
			axios
				.get("/Causas")
				.then((response) => {
					this.causas = response.data;
					this.causas.push({
						idCausa: 0,
						causa: "Otra Causa",
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getSoluciones() {
			axios
				.get("/Soluciones")
				.then((response) => {
					this.soluciones = response.data;
					this.soluciones.push({
						idSolucion: 0,
						solucion: "Otra Solución",
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getFactores() {
			axios
				.get("/Factores/Listar")
				.then((response) => {
					this.factores = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getTicketsAction(page) {
			this.tickets_actual_page = page;
			this.loading = true;
			axios
				.get("/CausaSolucionRecords/ListarRecordSolucionCausa", {
					params: {
						paginar: true,
						page: this.tickets_actual_page,
						size: this.tickets_items_per_page,
						...this.filtros,
					},
				})
				.then((response) => {
					this.tickets = response.data.result;
					this.tickets_total = response.data.total;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		getPersonas(){
			axios
			.get("/Personas")
			.then(response => {
				this.personas = response.data;
			})
			.catch(error => {
				console.log(error)
			})
		},
		getSubcausas() {
      axios
        .get("/SubCausas")
        .then((response) => {
          this.subcausasLista = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
		filtroSubcausas(){
			let IdCausa = this.dialogDetalles == true || this.dialogDetalles == true ? this.editedItem.idCausa : this.editarDetalles == true ? this.editedItemticket.idCausa : 0;
			if (IdCausa < 0) return;
			this.subcausasFiltradas = this.subcausasLista.filter(fil => fil.idCausa == IdCausa);
			this.subcausasFiltradas.push({
				idSubCausa: 0,
				subcausa: "Otra SubCausa",
				codigo: null,
			});
		},
		causasFiltradas(){
			if(this.editedItem.recordID == null) return [];
			return this.causas.filter(c => c.idCausa == 0 || (c.area == (this.editedItem.area == null ? null : this.editedItem.area.toUpperCase()) && c.departamento == this.editedItem.departamento.toUpperCase()));
		},
		causasFiltradasEditTicket(){
			if(this.editedItemticket.recordID == null) return [];
			return this.causas.filter(c => c.idCausa == 0 || (c.area == (this.editedItemticket.area == null ? null : this.editedItemticket.area.toUpperCase()) && c.departamento == this.editedItemticket.departamento.toUpperCase()));
		},
		equiposFiltrados(){
			if(this.editedItem.area == null || (this.editedItem.departamento || "").toUpperCase() != 'MANTENIMIENTO') return [];
			return this.equipos.filter(e => (e.departamento || "").toUpperCase() == (this.editedItem.area || "").toUpperCase());
		},
		solucionesFiltradas(){
			if(this.editedItem.recordID == null) return [];
			if(this.cualquierSolucion) return this.soluciones.filter(c => c.abierto == true && (c.area == (this.editedItem.area || "").toUpperCase() && c.departamento == (this.editedItem.departamento || "").toUpperCase()));
			if(this.editedItem.idSubcausa > 0) return this.soluciones.filter(s => (s.idCausa == this.editedItem.idCausa && s.idSubCausa == this.editedItem.idSubcausa) || s.idSolucion == 0)
			else return this.soluciones.filter(s => (s.idCausa == this.editedItem.idCausa && s.idSubCausa == 0) || s.idSolucion == 0);
		},
		solucionesFiltradasEditTicket(){
			if(this.editedItemticket.recordID == null) return [];
			if(this.cualquierSolucion) return this.soluciones.filter(c => c.abierto == true && (c.area == (this.editedItemticket.area || "").toUpperCase() && c.departamento == (this.editedItemticket.departamento || "").toUpperCase()));
			if(this.editedItemticket.idSubcausa > 0) return this.soluciones.filter(s => (s.idCausa == this.editedItemticket.idCausa && s.idSubCausa == this.editedItemticket.idSubcausa) || s.idSolucion == 0)
			else return this.soluciones.filter(s => (s.idCausa == this.editedItemticket.idCausa && s.idSubCausa == 0) || s.idSolucion == 0);
		},
		personasFiltradas(){
				if(this.editedItem.recordID == null) return [];
				if(typeof this.editedItem.departamento == 'undefined' || this.editedItem.departamento == null || this.editedItem.departamento.length == 0) return this.personas;
				else return this.personas.filter(p => (p.departamentoNombre || "").toUpperCase()  == (this.editedItem.departamento || "").toUpperCase());
		},
		personasFiltradasEditTicket(){
				if(this.editedItemticket.recordID == null) return [];
				if(typeof this.editedItemticket.departamento == 'undefined' || this.editedItemticket.departamento == null || this.editedItemticket.departamento.length == 0) return this.personas;
				else return this.personas.filter(p => (p.departamentoNombre || "").toUpperCase()  == (this.editedItemticket.departamento || "").toUpperCase());
		},
		factoresFiltrados(){
			if(this.editedItem.recordID == null) return null;
			var f = this.factores.find(c => c.departamento == (this.editedItem.departamento || "").toUpperCase());
			return this.$utils.isValid(f) ? f : null;
		},
		factoresFiltradosTicekts(){
			if(this.editedItemticket.recordID == null) return null;
			var f = this.factores.find(c => c.departamento == (this.editedItemticket.departamento || "").toUpperCase());
			return this.$utils.isValid(f) ? f : null;
		},
		agregarActualizarFactor(idFactor, idSubFactor){
			if(idSubFactor == null) return;

			this.eliminarFactor(idFactor);

			this.editedItem.factores.push({
				idFactor: idFactor,
				idSubFactor: idSubFactor
			});
		},
		eliminarFactor(idFactor){
			this.editedItem.factores = this.editedItem.factores.filter(x => x.idFactor != idFactor);
		},
		ActualizarFactorTicket(idFactor, idSubFactor, posicion, nombre, factor){
			var searchSubFactores = factor.subFactores.find(subFactor => subFactor.idSubFactor === idSubFactor)
			if (searchSubFactores == undefined) return;
			this.editedItem.factores[posicion].subFactor = searchSubFactores.nombre;
		},
		ticketsDetallesHeadersAvanzado(){
			var headers = JSON.parse(JSON.stringify(this.ticketsDetallesHeaders));
			
			if(this.editedItem.departamento != null && (this.editedItem.departamento || "").toUpperCase() != "MANTENIMIENTO"){
				headers = headers.filter(x => x.value != "equipo" && x.value != "identificadorActivos");
			}

			if(this.$utils.isValid(this.editedItem.factores) && this.editedItem.factores.length > 0){
				this.editedItem.factores.filter(f => this.$utils.isValid(f.factor) && this.$utils.isValid(f.subFactor)).forEach((f,idx) => {
					var pos = headers.findIndex(h => h.value == 'numOperacion');
					headers.splice(pos, 0, {
						text: f.factor,
						align: "center",
						value: "factor-"+idx,
					});
				});
			}
			
			return headers;
		},
		getTurnos(){
			this.loadingTurnos = true;
			axios
        		.get("/Turnos")
        		.then(response =>  {
          			this.turnos = response.data;
          			this.loadingTurnos = false;
        		})
        	.catch(error => {
				this.loadingTurnos = false;
          		console.log(error);
        	});
		},
		getRecordsIds() {
			axios
				.get("/CausaSolucionRecords/ListaRecordsIds")
				.then((response) => {
					this.recordsIds = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>