<template>
	<v-container fluid v-if="permisoVista('purchasingprocesses','r')" class="pt-0">
		<SettingsDialog
			v-model="dialogSettings"
			:minimumDays="minimumDays"
			@refresh:minimumDays="getMinimumDays()"
		/>
		<DetailsDialog
			v-model="dialogDetails"
			:detailsItem="editedItem"
			@click:close="close()"
			@click:update="save()"
		/>
		<v-dialog v-model="dialogState" persistent max-width="500px">
			<v-card>
				<v-card-title>Change status to {{ stateItem.state ? 'Cancelled' : 'Approved' }}</v-card-title>
				<v-card-text>
					<p
						class="mb-0 text-subtitle-1"
						style="font-size: 18px !important"
					>
						Are you sure you want to change the status of the item to {{ stateItem.state ? 'cancelled' : 'approved' }} ?
					</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="changing"
						@click="closeDialogState()"
						>Cancel</v-btn
					>
					<v-btn 
						text
						color="error"
						:disabled="changing"
						:loading="changing"
						@click="changeStatus"
						>Accept</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<CreateTicket
			v-model="dialogCreate"
			:projects="projects"
			:purchaseClasses="purchaseClasses"
			:idMMCallBuyTicket="idMMCallBuyTicket"
			:dialog-type="2"
			:minimumDays="minimumDays"
			@refresh="initialize()"
		/>
		<DownloadFilesDialog
			v-model="dialogDownloads"
			:downloadItem="downloadItem"
			@click:close="closeDialogDownloads"
		/>
		<v-row v-if="permisoVista('purchasingprocesses-admin','u')">
			<v-col cols="12" class="px-2 py-0 text-right">
				<v-btn
					color="primary"
					:disabled="loadingMinDays"
					:loading="loadingMinDays"
					@click="showDialogSettings()"
				>
					<v-icon left>mdi-cog</v-icon>
					Configuración
				</v-btn>
			</v-col>
		</v-row>
		<TicketFilters
			v-model="filters"
			:projects="projects"
			:loadingProjects="loadingProjects"
			:purchaseClasses="purchaseClasses"
			:loadingPurchaseClasses="loadingPurchaseClasses"
			:status="status"
			:loadingStatus="loadingStatus"
			:stages="stages"
			:loadingStages="loadingStages"
			:poArray="poArray"
			:loadingPo="loadingPo"
			:ioNumbers="ioNumbers"
			:loadingIONumbers="loadingIONumbers"
			:farNumbers="farNumbers"
			:loadingFARNumbers="loadingFARNumbers"
			:users="users"
			:loadingUsers="loadingUsers"
			:buyers="buyers"
			:loadingBuyers="loadingBuyers"
			:purchaseAreas="purchaseAreas"
			:loadingPurchaseAreas="loadingPurchaseAreas"
			:loading="loading"
			:search="search"
			@update:search="search = $event"
			@change="getPurchasingProcesses"
		/>
		<v-row>
			<v-col cols="12">
				<v-data-table
					:items="purcharsingItems"
					:headers="purchasingHeaders"
					:loading="loading"
					:search="search"
					loading-text="Loading..."
					class="custom-width-header custom-fixed-header"
				>
					<template v-slot:[`item.state`]="{ item }">
						<v-icon
							:color="item.state ? 'success': 'error'"
							size="22"
						>
							{{ item.state ? 'mdi-check-circle' : 'mdi-close-circle' }}
						</v-icon>
					</template>
					<template v-slot:[`item.aging`]="{ item }">
						<div class="d-flex align-center justify-center">
							<v-icon
								class="mr-2"
								:class="{
									'success--text': item.aging >= 3,
									'yellow--text':
										item.aging >= 1 && item.aging <= 2,
									'error--text': item.aging <= 0,
								}"
								small
								>mdi-circle</v-icon
							>
							<p class="mb-0">{{ item.aging }} days</p>
						</div>
					</template>
					<template v-slot:[`item.emergencyApproved`]="{ item }">
						<div class="d-flex align-center justify-center">
							<p class="mb-0 mr-2">
								{{ item.emergencyApproved ? "YES" : "NO" }}
							</p>
							<v-tooltip
								v-if="item.emergencyApproved"
								bottom
								color="black"
								class="white--text"
							>
								<template v-slot:activator="{ on }">
									<v-icon
										v-on="on"
										:disabled="item.downloadingEmergencyForm || loading"
										color="primary"
										@click="showDialogDownloads(item,1)"
										>mdi-file-download</v-icon
									>
								</template>
								<span class="white--text">Download file(s)</span>
							</v-tooltip>
						</div>
					</template>
					<template v-slot:[`item.rfq`]="{ item }">
						<div class="d-flex align-center justify-center">
							<p class="mb-0 mr-2">
								{{ item.rfq ? "YES" : "NO" }}
							</p>
							<v-tooltip
								v-if="item.rfq"
								bottom
								color="black"
								class="white--text"
							>
								<template v-slot:activator="{ on }">
									<v-icon
										v-on="on"
										:disabled="item.downloadingRFQ || loading"
										color="primary"
										@click="showDialogDownloads(item,2)"
										>mdi-file-download</v-icon
									>
								</template>
								<span class="white--text">Download file(s)</span>
							</v-tooltip>
						</div>
					</template>
					<template v-slot:[`item.areas`]="{ item }">
						<p
							v-for="area,index in item.areas"
							class="mb-0"
							:key="`area-${index}`"
						>
							{{ item.areas.length > 1 ? '•' : '' }} {{ area }}
						</p>
					</template>
					<template v-slot:[`item.toolCrib`]="{ item }">
						{{ item.toolCrib || "N/A" }}
					</template>
					<template v-slot:[`item.project`]="{ item }">
						{{ item.project || "N/A" }}
					</template>
					<template v-slot:[`item.budgetQuote`]="{ item }">
						{{ item.budgetQuote ? "YES" : "NO" }}
					</template>
					<template v-slot:[`item.quantity`]="{ item }">
						{{ item.quantity.toFixed(2) }}
					</template>
					<template v-slot:[`item.farNumber`]="{ item }">
						{{ item.farNumber || "N/A" }}
					</template>
					<template v-slot:[`item.ioNumber`]="{ item }">
						{{ item.ioNumber || "N/A" }}
					</template>
					<template v-slot:[`item.buyers`]="{ item }">
						<v-autocomplete
							:items="buyers"
							item-text="nombre"
							item-value="idPersona"
							label="Buyer"
							single-line
							clearable
							:value="item.idBuyer"
							:disabled="disabledBuyerField(item)"
							:loading="item.updating"
							@change="onChangeBuyer(item,$event)"
						></v-autocomplete>
					</template>
					<template v-slot:[`item.clecks`]="{ item }">
						<v-autocomplete
							:items="clecksFiltered(item)"
							item-text="nombre"
							item-value="idPersona"
							label="Clerk"
							:hint="item.idBuyer == null ? 'Select a buyer first' : ''"
							:persistent-hint="item.idBuyer == null"
							:disabled="disabledCleckField(item)"
							:loading="item.updating"
							single-line
							clearable
							:value="item.idCleck"
							@change="onChangeCleck(item,$event)"
						></v-autocomplete>
					</template>
					<template v-slot:[`item.status`]="{ item }">
						<v-select
							label="Status"
							:items="status"
							item-text="name"
							item-value="idMMCallStatu"
							single-line
							clearable
							:disabled="disabledStatusFilter(item)"
							:loading="item.updating"
							:value="item.idStatus"
							@change="onChangeStatus(item,$event)"
						></v-select>
					</template>
					<template v-slot:[`item.stage`]="{ item }">
						<v-autocomplete
							label="Stage"
							:items="stagesFiltered(item)"
							item-text="name"
							item-value="idMMCallStage"
							single-line
							:hint="item.idStatus == null ? 'Select a status first' : ''"
							:persistent-hint="item.idStatus == null"
							:loading="item.updating"
							:disabled="disabledStageFilter(item)"
							clearable
							:value="item.idStage"
							@change="onChangeStages(item,$event)"
						></v-autocomplete>
					</template>
					<template v-slot:[`item.progress`]="{ item }">
						<v-progress-linear
							:value="item.progress"
							height="25"
							color="success"
						>
							<strong>{{ Math.ceil(item.progress) }}%</strong>
						</v-progress-linear>
					</template>
					<template v-slot:[`item.po`]="{ item }">
						<div
							v-if="!item.po"
							class="d-flex align-center justify-center"
						>
							<v-text-field
								v-model="item.temporalPO"
								label="PO"
								clearable
								class="mr-2"
								:disabled="item.updatingPO || loading || !permisoVista('purchasingprocesses','u')"
								:loading="item.updatingPO"
								:error-messages="item.errorPO"
								single-line
								@focus="$set(item,'errorPO',null)"
							></v-text-field>
							<v-icon
								size="20"
								color="success"
								:disabled="!item.temporalPO || item.updatingPO || loading || !permisoVista('purchasingprocesses','u')"
								@click="setItemPO(item,item.temporalPO)"
								>mdi-check-circle</v-icon
							>
						</div>
						<div v-else class="d-flex align-center justify-center">
							<p class="mb-0 mr-2">{{ item.po }}</p>
							<v-icon
								size="20"
								color="error"
								:disabled="item.updatingPO || loading || !permisoVista('purchasingprocesses','u')"
								@click="setItemPO(item,null)"
								>mdi-close-circle</v-icon
							>
						</div>
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-tooltip
							v-if="permisoVista('purchasingprocesses','u')"
							bottom
							color="black"
							class="white--text"
						>
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									class="mr-1"
									:disabled="loading"
									@click="showDialogCreate(item.idMMCallBuyTicket)"
									>mdi-pencil</v-icon
								>
							</template>
							<span class="white--text">Edit</span>
						</v-tooltip>
						<template v-if="permisoVista('purchasingprocesses','u')">
							<v-tooltip
								v-if="item.state"
								bottom
								color="black"
								class="white--text"
							>
								<template v-slot:activator="{ on }">
									<v-icon
										v-on="on"
										small
										class="mr-1"
										:disabled="loading"
										style="font-size: 18px"
										@click="showDialogState(item)"
									>
										mdi-alert-decagram
									</v-icon>
								</template>
								<span class="white--text">Cancel</span>
							</v-tooltip>
							<v-tooltip
								v-else-if="permisoVista('purchasingprocesses-admin','u')"
								bottom
								color="black"
								class="white--text"
							>
								<template v-slot:activator="{ on }">
									<v-icon
										v-on="on"
										small
										class="mr-1"
										:disabled="loading"
										style="font-size: 18px"
										@click="showDialogState(item)"
									>
										mdi-check-decagram
									</v-icon>
								</template>
								<span class="white--text">Approve</span>
							</v-tooltip>
						</template>
						<v-tooltip
							bottom
							color="black"
							class="white--text"
						>
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									:disabled="loading"
									@click="showDialogDetails(item)"
									>mdi-eye</v-icon
								>
							</template>
							<span class="white--text">View Details</span>
						</v-tooltip>
					</template>
					<template v-slot:no-data>
						<v-btn color="primary" @click="initialize()">
							<v-icon size="20" color="white" left
								>mdi-reload</v-icon
							>
							Refresh
						</v-btn>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import DownloadFilesDialog from './MyTickets/DownloadFilesDialog.vue';
import CreateTicket from './MyTickets/CreateTicket.vue';
import TicketFilters from './MyTickets/TicketFilters.vue';
import DetailsDialog from './MyTickets/DetailsDialog.vue';
import SettingsDialog from './MyTickets/SettingsDialog.vue';
import axios from 'axios';
import qs from "qs";

export default {
	components: { DownloadFilesDialog, CreateTicket, TicketFilters, DetailsDialog, SettingsDialog },
	data() {
		return {
			loadingMinDays: false,
			editedIndex: null,
			editeddIndex: null,
			minimumDays: 1,
			dialogSettings: false,
			loadingPurchaseAreas: false,
			purchaseAreas: [],
			loadingBuyers: false,
			buyers: [],
			loadingUsers: false,
			users: [],
			loadingFARNumbers: false,
			farNumbers: [],
			loadingIONumbers: false,
			ioNumbers: [],
			search: null,
			filters: {
				from: null,
				to: null,
				idProject: null,
				emergency: null,
				idPurchaseClass: null,
				status: [4],
				idsMMCallStage: [],
				po: [],
				ioNumbers: [],
				farNumbers: [],
				idsUsuarios: [],
				idsBuyers: [],
				idsAreas: []
			},
			poArray: [],
			loadingPo: false,
			changing: false,
			dialogState: false,
			idMMCallBuyTicket: null,
			dialogCreate: false,
			dialogDownloads: false,
			stateItem: {
				idMMCallBuyTicketItem: null,
				state: null
			},
			defaultStateItem: {
				idMMCallBuyTicketItem: null,
				state: null,
			},
			downloadItem: {
				type: null,
				files: []
			},
			defaultDownloadItem: {
				type: null,
				files: []
			},
			loadingStages: false,
			stages: [],
			loadingClecks: false,
			clecks: [],
			loadingBuyers: false,
			buyers: [],
			emergencyTypes: [
				{
					text: "Yes",
					value: true
				},
				{
					text: "No",
					value: false
				}
			],
			loadingStatus: false,
			status: [4],
			loadingPurchaseClasses: false,
			purchaseClasses: [],
			loadingProjects: false,
			projects: [],
			saving: false,
			editedItem: {
				idMMCallBuyTicketItem: null,
				idTicket: null,
				item: null,
				prDate: null,
				poDate: null,
				srvDate: null,
				aging: null,
				requester: null,
				areaManager: null,
				areas: null,
				project: null,
				purchaseClass: null,
				description: null,
				quantity: null,
				unity: null,
				comment: null,
				comments: [],
				dialogType: null,
				property: null,
				oldValue: null,
				updating: false
			},
			defaultEditedItem: {
				idMMCallBuyTicketItem: null,
				idTicket: null,
				item: null,
				prDate: null,
				poDate: null,
				srvDate: null,
				aging: null,
				requester: null,
				areaManager: null,
				areas: null,
				project: null,
				purchaseClass: null,
				description: null,
				quantity: null,
				unity: null,
				comment: null,
				comments: [],
				dialogType: null,
				property: null,
				oldValue: null,
				updating: false
			},
			status: [
				{
					text: "Open",
					value: 1,
				},
				{
					text: "Close",
					value: 2,
				},
			],
			dialogDetails: false,
			purcharsingItems: [],
			purchasingHeaders: [
				{
					text: "State",
					align: "center",
					value: "state",
					width: "25px"
				},
				{
					text: "Item",
					align: "center",
					value: "item",
					sortable: false
				},
				{
					text: "PR Request Date",
					align: "center",
					value: "prDate",
				},
				{
					text: "Material/SRV Need Date",
					align: "center",
					value: "srvDate",
				},
				{
					text: "Aging",
					align: "center",
					value: "aging",
				},
				{
					text: "Emergency Form Approved?",
					align: "center",
					value: "emergencyApproved",
					sortable: false
				},
				{
					text: "RFQ",
					align: "center",
					value: "rfq",
					sortable: false
				},
				{
					text: "Requester",
					align: "center",
					value: "requester",
					sortable: false
				},
				{
					text: "Area Manager",
					align: "center",
					value: "areaManager",
					sortable: false
				},
				{
					text: "Area",
					align: "center",
					value: "areas",
					sortable: false
				},
				{
					text: "Tool Crib Code",
					align: "center",
					value: "toolCrib",
				},
				{
					text: "Project",
					align: "center",
					value: "project",
					sortable: false
				},
				{
					text: "Budget Quote",
					align: "center",
					value: "budgetQuote",
				},
				{
					text: "General Description",
					align: "center",
					value: "generalDescription",
					sortable: false
				},
				{
					text: "Description",
					align: "center",
					value: "description",
					sortable: false
				},
				{
					text: "Qty",
					align: "center",
					value: "quantity",
				},
				{
					text: "UM",
					align: "center",
					value: "unity",
					sortable: false
				},
				{
					text: "Suggested Supplier",
					align: "center",
					value: "supplier",
					sortable: false
				},
				{
					text: "Purchase Class",
					align: "center",
					value: "purchaseClass",
					sortable: false
				},
				{
					text: "FAR Number",
					align: "center",
					value: "farNumber",
					sortable: false
				},
				{
					text: "IO Number",
					align: "center",
					value: "ioNumber",
					sortable: false
				},
				{
					text: "GL Account",
					align: "center",
					value: "glAccount",
					sortable: false
				},
				{
					text: "Cost Center",
					align: "center",
					value: "costCenter",
					sortable: false
				},
				{
					text: "Buyers",
					align: "center",
					value: "buyers",
					sortable: false,
					width: "250px"
				},
				{
					text: "Clerks",
					align: "center",
					value: "clecks",
					sortable: false,
					width: "250px"
				},
				{
					text: "Status",
					align: "center",
					value: "status",
					sortable: false,
					width: "250px"
				},
				{
					text: "Stage",
					align: "center",
					value: "stage",
					sortable: false,
					width: "250px"
				},
				{
					text: "Progress",
					align: "center",
					value: "progress",
				},
				{
					text: "PO",
					align: "center",
					value: "po",
					sortable: false
				},
				{
					text: "Created At",
					align: "center",
					value: "createAt",
				},
				{
					text: "Last Update",
					align: "center",
					value: "lastUpdate",
				},
				{
					text: "Reassignments",
					align: "center",
					value: "numReasignaciones",
				},
				{
					text: "Actions",
					align: "center",
					value: "actions",
					width: "100px",
					sortable: false
				},
			],
			loading: false,
		};
	},
	mounted() {
		this.initialize();
	},
	methods: {
		showDialogSettings(){
			this.dialogSettings = true;
		},
		disabledStageFilter(item){
			return item.idStatus == null ||
				item.updating ||
				this.loadingStages ||
				this.loading ||
				!this.permisoVista('purchasingprocesses','u');
		},
		disabledStatusFilter(item){
			return item.updating ||
				this.loading ||
				!this.permisoVista('purchasingprocesses','u');
		},
		disabledCleckField(item){
			return item.idBuyer == null ||
				item.updating ||
				this.loading ||
				!this.permisoVista('purchasingprocesses','u') ||
				!this.canReassignBuyer(item);
		},
		disabledBuyerField(item){
			return item.updating ||
				this.loading ||
				!this.permisoVista('purchasingprocesses','u') ||
				!this.canReassignBuyer(item);
		},
		canReassignBuyer(item){
			return item.reassignable;
		},
		changeStatus(){
			this.changing = true;
			
			axios
				.put(`/BuyTickets/ModificarEstado/${this.stateItem.idMMCallBuyTicketItem}`)
				.then( () => {
					this.closeDialogState();
					// this.close();
					this.getPurchasingProcesses();
					this.changing = false;
				})
				.catch( (error) => {
					console.log(error);
					this.changing = false;
				});
		},
		showDialogState({ idMMCallBuyTicketItem, state }){
			this.stateItem.idMMCallBuyTicketItem = idMMCallBuyTicketItem;
			this.stateItem.state = state;
			this.dialogState = true;
		},
		showDialogCreate(idMMCallBuyTicket = null) {
			this.idMMCallBuyTicket = idMMCallBuyTicket;
			this.dialogCreate = true;
		},
		closeDialogDownloads(){
			this.dialogDownloads = false;
			setTimeout(() => {
				this.downloadItem = structuredClone(this.defaultDownloadItem);
			}, 200);
		},
		showDialogDownloads(item,type){
			this.downloadItem.type = type;
			this.downloadItem.files = structuredClone(type == 1 ? item.emergencyFormFiles : item.rfqFiles);

			this.dialogDownloads = true;
		},
		save(){
			this.updateItemHistory(this.editedItem,this.editedItem.property,this.editedItem.oldValue);
		},
		onChangeCleck(item,newValue){
			const oldValue = item.idCleck;
			this.$set(item,'idCleck',newValue);
			this.updateItemHistory(item,'idCleck',oldValue);
		},
		onChangeBuyer(item,newValue){
			const oldValue = item.idBuyer;
			this.$set(item,'idCleck',null);
			this.$set(item,'idBuyer',newValue);
			this.updateItemHistory(item,'idBuyer',oldValue);
		},
		onChangeStages(item,newValue){
			this.editeddIndex = this.purcharsingItems.indexOf(item)
			this.purcharsingItems[this.editeddIndex].idStageAnterior = item.idStage;
			this.editedIndex = this.purcharsingItems.indexOf(item)
			this.purcharsingItems[this.editedIndex].idStatusAnterior = item.idStatus;
			const oldStatus = item.idStatus;
			const oldValue = item.idStage;
			this.$set(item,'idStatus',oldStatus);
			this.$set(item,'idStage',newValue);
			this.showDialogComment(item,'idStage',oldValue);
		},
		onChangeStatus(item,newValue){
			this.editedIndex = this.purcharsingItems.indexOf(item)
			this.purcharsingItems[this.editedIndex].idStatusAnterior = item.idStatus;
			this.editeddIndex = this.purcharsingItems.indexOf(item)
			this.purcharsingItems[this.editeddIndex].idStageAnterior = item.idStage;
			const oldStage = item.idStage;
			const oldValue = item.idStatus;
			this.$set(item,'idStage',oldStage);
			this.$set(item,'idStatus',newValue);
			this.showDialogComment(item,'idStatus',oldValue);
		},
		stagesFiltered(item){
			if(item.idStatus == null) return [];
			return this.stages.filter( (stage) => stage.idMMCallStatu == item.idStatus );
		},
		clecksFiltered(item){
			if(item.idBuyer == null) return [];
			return this.clecks.filter( (cleck) => cleck.idJefe == item.idBuyer );
		},
		downloadFile(item,fileName,type) {
			const downloadingProp = type == 1 ? 'downloadingEmergencyForm' : 'downloadingRFQ';
			this.$set(item,downloadingProp,true);
			axios({
				url: `/Documentos/Descargar/${fileName}`,
				method: "GET",
				responseType: "blob",
			})
				.then((response) => {
					let blob = new Blob([response.data], {
						type: "application/octet-stream",
					});
					let a = document.createElement("a");
					let blobURL = URL.createObjectURL(blob);
					a.download = fileName;
					a.href = blobURL;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally( () => {
					this.$set(item,downloadingProp,false);
				});
		},
		closeDialogDetails() {
			if(this.editedItem.dialogType == 1)
				this.updateItemHistory(this.editedItem,this.editedItem.property,this.editedItem.oldValue);
			
			this.dialogDetails = false;

			setTimeout(() => {
				this.editedItem = structuredClone(this.defaultEditedItem);
			}, 200);
		},
		closeDialogState(){
			this.dialogState = false;
			setTimeout(() => {
				this.stateItem = Object.assign({},this.defaultStateItem);
			}, 200);
		},
		close() { //Función creada para no cambiar el status o el stage al dar "cancel"

			this.dialogDetails = false;
			this.purcharsingItems[this.editedIndex].idStatus = this.purcharsingItems[this.editedIndex].idStatusAnterior;
			this.purcharsingItems[this.editedIndex].idStatusAnterior = null;
			this.editedIndex = null
		
			this.purcharsingItems[this.editeddIndex].idStage = this.purcharsingItems[this.editeddIndex].idStageAnterior;
			this.purcharsingItems[this.editeddIndex].idStageAnterior = null;
			this.editeddIndex = null;
			
			
		}, 
		setItemPO(item,temporalPO) {
			this.$set(item,"updatingPO",true);
			
			axios
				.put('/BuyTickets/ActualizarPo',{
					idMMCallBuyTicketItem: item.idMMCallBuyTicketItem,
					po: temporalPO
				})
				.then( () => {
					if(temporalPO == null){
						this.filters.po = this.filters.po.filter( (po) => po != item.po );
					}
					this.$set(item, "po", temporalPO);
					this.$set(item, "temporalPO", null);
					this.$set(item,"updatingPO",false);
					this.$set(item, "errorPO", null);
					this.getPo();
					this.getPurchasingProcesses();
				})
				.catch( (error) => {
					console.log(error);
					this.$set(item,"updatingPO",false);
					this.$set(item, "errorPO", 'Error');
				});
		},
		showDialogComment(item,property,oldValue) {
			this.editedItem = item;
			this.editedItem.dialogType = 1;
			this.editedItem.property = property;
			this.editedItem.oldValue = oldValue;
			this.dialogDetails = true;
		},
		showDialogDetails(item){
			this.editedItem = structuredClone(item);
			this.editedItem.idMMCallBuyTicketItem = item.idMMCallBuyTicketItem;
			this.editedItem.dialogType = 2;
			this.dialogDetails = true;
		},
		initialize() {
			this.getPurchasingProcesses();
			this.getProjects();
			this.getPurchaseClasses();
			this.getStatus();
			this.getBuyers();
			this.getClecks();
			this.getStages();
			this.getPo();
			this.getIONumbers();
			this.getFARNumbers();
			this.getUsers();
			this.getBuyers();
			this.getPurchaseAreas();
			this.getMinimumDays();
		},
		getMinimumDays(){
			this.loadingMinDays = true;

			axios
				.get('/BuyTickets/DiaMinimo')
				.then( (response) => {
					this.minimumDays = response.data;
				})
				.catch( (error) => {
					this.minimumDays = 1;
					console.log(error);
				})
				.finally( () => {
					this.loadingMinDays = false;
				});
		},
		getPurchaseAreas(){
			this.loadingPurchaseAreas = true;

			axios
				.get('/BuyTickets/GetCompraAreas')
				.then( (response) => {
					this.purchaseAreas = response.data;
				})
				.catch( (error) => {
					console.log(error);
					this.purchaseAreas = [];
				})
				.finally( () => {
					this.loadingPurchaseAreas = false;
				});
		},
		getBuyers(){
			this.loadingBuyers = true;

			axios
				.get('/BuyTickets/Jefes')
				.then( (response) => {
					this.buyers = response.data;
				})
				.catch( (error) => {
					this.buyers = [];
					console.log(error);
				})
				.finally( () => {
					this.loadingBuyers = false;
				});
		},
		getUsers(){
			this.loadingUsers = true;

			axios
				.get('/BuyTickets/UsuariosResumen')
				.then( (response) => {
					this.users = response.data;
				})
				.catch( (error) => {
					console.log(error);
					this.users = [];
				})
				.finally( () => {
					this.loadingUsers = false;
				});
		},
		getFARNumbers(){
			this.loadingFARNumbers = true;

			axios
				.get('/BuyTickets/GetFarNumbers')
				.then( (response) => {
					this.farNumbers = response.data;
				})
				.catch( (error) => {
					console.log(error);
					this.farNumbers = [];
				})
				.finally( () => {
					this.loadingFARNumbers = false;
				});
		},
		getIONumbers(){
			this.loadingIONumbers = true;

			axios
				.get('/BuyTickets/GetIONumbers')
				.then( (response) => {
					this.ioNumbers = response.data;
				})
				.catch( (error) => {
					console.log(error);
					this.ioNumbers = [];
				})
				.finally( () => {
					this.loadingIONumbers = false;
				});
		},
		getPo(){
			this.loadingPo = true;

			axios
				.get('/BuyTickets/GetPo')
				.then( (response) => {
					this.poArray = response.data;
				})
				.catch((error) => {
					console.log(error);
					this.poArray = [];
				})
				.finally( () => {
					this.loadingPo = false;
				});
		},
		updateItemHistory(item,property,oldValue){
			this.$set(item,'updating',true);
			const payload = {
				idMMCallBuyTicketItem: item.idMMCallBuyTicketItem,
				idBuyer: item.idBuyer,
				idCleck: item.idCleck,
				idMMCallStatu: item.idStatus,
				idMMCallStage: item.idStage,
				comentario: item.comment
			}
			axios
				.post('/BuyTickets/ActualizarHistoriaItem',payload)
				.then( () => {
					this.$set(item,'comment',null);
					if(item.hasOwnProperty('dialogType')){
						this.dialogDetails = false;
						this.getPurchasingProcesses();
						
						setTimeout(() => {
							this.editedItem = structuredClone(this.defaultEditedItem);
						}, 200);
					}
				})
				.catch( (error) => {
					console.log(error);
					this.$set(item,property,oldValue);
				})
				.finally( () => {
					this.$set(item,'updating',false);
				});
		},
		getClecks(){
			this.loadingClecks = true;

			axios
				.get('/BuyTickets/TodosSubordinados')
				.then( (response) => {
					this.clecks = response.data;
				})
				.catch( (error) => {
					this.clecks = [];
					console.log(error);
				})
				.finally( () => {
					this.loadingClecks = false;
				})
		},
		getBuyers(){
			this.loadingBuyers = true;

			axios
				.get('/BuyTickets/Jefes')
				.then( (response) => {
					this.buyers = response.data;
				})
				.catch( (error) => {
					this.buyers = [];
					console.log(error);
				})
				.finally( () => {
					this.loadingBuyers = false;
				});
		},
		getStatus(){
			this.loadingStatus = true;

			axios
				.get('/BuyTickets/Status')
				.then( (response) => {
					this.status = response.data;
				})
				.catch( (error) => {
					this.status = [];
					console.log(error);
				})
				.finally( () => {
					this.loadingStatus = false;
				})
		},
		getStages(){
			this.loadingStages = true;
			
			axios
				.get('/BuyTickets/StagesTodos')
				.then( (response) => {
					this.stages = response.data
				})
				.catch( (error) => {
					this.stages = [];
					console.log(error);
				})
				.finally( () => {
					this.loadingStages = false;
				});
		},
		getPurchaseClasses() {
			this.loadingPurchaseClasses = true;

			axios
				.get("/PurchaseClass/GetPurchaseClass")
				.then((response) => {
					this.purchaseClasses = response.data;
				})
				.catch((error) => {
					this.purchaseClasses = [];
					console.log(error);
				})
				.finally(() => {
					this.loadingPurchaseClasses = false;
				});
		},
		getProjects() {
			this.loadingProjects = true;

			axios
				.get("/Project/GetProjects")
				.then((response) => {
					this.projects = response.data;
				})
				.catch((error) => {
					this.projects = [];
					console.log(error);
				})
				.finally(() => {
					this.loadingProjects = false;
				});
		},
		getPurchasingProcesses() {
			this.loading = true;

			axios
				.get('/BuyTickets/Purchasing',{
					params: {
						...this.filters,
						idsMMCallStage: this.filters.idsMMCallStage.map( (stage) => stage.idMMCallStage )
					},
					paramsSerializer: (params) => qs.stringify(params, { indices: false, skipNulls: true }),
				})
				.then( (response) => {
					this.purcharsingItems = response.data;
					this.purcharsingItems = this.purcharsingItems.map(item => ({
						...item,
						idStageAnterior: null,
						idStatusAnterior: null //Datos agregados para poder cancelar el cambio de status y stage
					}))
					//console.log(this.purcharsingItems);
				})
				.catch( (error) => {
					this.purcharsingItems = [];
					console.log(error);
				})
				.finally( () => {
					this.loading = false;
				});
		},
	},
};
</script>
<style scoped>
.custom-fixed-header >>> thead th:first-child {
	position: sticky;
	top: 0;
	left: 0;
	z-index: 1;
	background-color: white;
}

.custom-fixed-header >>> tbody td:first-child {
	position: sticky;
	left: 0;
	z-index: 1;
}

.custom-fixed-header >>> tbody tr:nth-child(even) td:first-child {
	background-color: white;
}

.custom-fixed-header >>> tbody tr:nth-child(odd) td:first-child {
	background-color: #f1f1f1;
}

.custom-fixed-header >>> thead th:nth-child(2) {
	position: sticky;
	top: 0;
	left: 72px;
	z-index: 1;
	background-color: white;
	border-right: 1px solid #CCC;
}

.custom-fixed-header >>> tbody td:nth-child(2) {
	position: sticky;
	left: 72px;
	z-index: 1;
	border-right: 1px solid #CCC;
}

.custom-fixed-header >>> tbody tr:nth-child(even) td:nth-child(2) {
	background-color: white;
}

.custom-fixed-header >>> tbody tr:nth-child(odd) td:nth-child(2) {
	background-color: #f1f1f1;
}

.custom-fixed-header >>> tbody tr:nth-child(even):hover {
	background-color: white !important;
}

.custom-fixed-header >>> tbody tr:nth-child(odd):hover {
	background-color: #f1f1f1 !important;
}

.comment-title {
	word-break: break-word;
	font-size: 20px !important;
	line-height: 24px !important;
}

.custom-width-header >>> th {
	min-width: 150px;
}
</style>