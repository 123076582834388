<template>
  <v-col cols="12" v-if="permisoVista('usomaquinas','r')">
    <v-row>
      <v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
        <v-autocomplete
          v-model="filtros.categoria"
          :items="categorias"
          item-value="idMaquinaCategoria"
          item-text="categoria"
          label="Categoría"
          hide-details
          clearable
          @change="changeCategoria"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
        <v-autocomplete
          v-model="filtros.maquina"
          :items="maquinasFiltradas"
          item-value="idMaquina"
          :item-text="marcaModelo"
          label="Máquina"
          hide-details
          clearable
          @change="changeMaquina"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
        <v-autocomplete
          v-model="filtros.instancia"
          :items="instanciasFiltradas"
          item-value="idMaquinaIns"
          item-text="nombre"
          label="Máquina Instancia"
          clearable
          hide-details
          @change="historial_update_page_action(1)"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="6" md="6" sm="6" class="align-self-end">
        <VueCtkDateTimePicker
          v-model="filtros.inicio"
          label="Desde"
          format="YYYY-MM-DD HH:mm"
          formatted="YYYY-MM-DD HH:mm"
          noHeader
          overlay
          buttonNowTranslation="Ahora"
          @input="historial_update_page_action(1)"
        />
      </v-col>
      <v-col cols="6" md="6" sm="6" class="align-self-end">
        <VueCtkDateTimePicker
          v-model="filtros.fin"
          :min-date="filtros.inicio"
          label="Hasta"
          format="YYYY-MM-DD HH:mm"
          formatted="YYYY-MM-DD HH:mm"
          noHeader
          overlay
          buttonNowTranslation="Ahora"
          @input="historial_update_page_action(1)"
        />
      </v-col>
    </v-row>
    <download-btn
      color="blue darken-1"
      text 
      @click="descargarReporte"
      label="Descargar Reporte<br>de Uso de Máquinas"
    ></download-btn>
    <v-row v-if="estadisticas.length > 0">
      <v-col cols="6" md="6" sm="6">
        <apexchart type="pie" width="380" :options="usoTiempoChartOptions" :series="usoTiempoSeries" ref="chartSegundos"></apexchart>
      </v-col>
      <v-col cols="6" md="6" sm="6">
        <apexchart type="pie" width="380" :options="usoCantidadChartOptions" :series="usoCantidadSeries" ref="chartCantidad"></apexchart>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" md="6" sm="6">
        <apexchart type="bar" height="380" :options="MTTRchartOptions" :series="MTTRSeries" ref="chartMTTR"></apexchart>
      </v-col>
      <v-col cols="6" md="6" sm="6">
        <v-card v-if="loadingMTBF" elevation="0">
            <br>
            <br>
            <div class="text-h6 text-center">Cargando MTBF</div>
            <br>
            <div class="text-center">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
            <br>
            <br>
        </v-card>
        <apexchart v-else type="bar" height="380" :options="MTBFchartOptions" :series="MTBFSeries" ref="chartMTTR"></apexchart>
      </v-col>
    </v-row>
    <br>
    <v-data-table
      :headers="headers"
      :items="historial"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
      :server-items-length="historial_total"
      :items-per-page="historial_items_per_page"
      :page="historial_actual_page"
      @update:page="historial_update_page_action"
      @update:items-per-page="(ipp) => historial_items_per_page = ipp"
      @update:sort-by="historial_sort_by_action"
      :footer-props="{disableItemsPerPage:true, disablePagination: this.loading }"
      :disable-sort="true"
    >
      <template v-slot:item.categoria="{ item }">
         {{(item.categoria == null)?"SIN ASIGNAR":item.categoria}}
      </template>
      <template v-slot:item.marcaModelo="{ item }">
         {{item.marca}} - {{item.modelo}}
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import axios from "axios";
import moment from "moment";
import DownloadBtn from '../../DownloadBtn.vue';

export default {
	components: { DownloadBtn },
  data: () => ({
    historial:[],
    categorias:[],
    maquinas:[],
    instancias:[],
    estadisticas:[],
    mttr:[],
    mtbf:[],
    loadingMTBF: false,
    filtros:{
      categoria: null,
      maquina:null,
      instancia:null,
      inicio:null,
      fin:null,
    },
    loading:false,
    historial_total:0,
    historial_items_per_page:10,
    historial_actual_page:1,
    headers:[
      {
        text:"Categoría",
        align:'center',
        sort:false,
        value:'categoria',
        width:'8.33%',
      },
      {
        text:"Máquina",
        align:'center',
        sort:false,
        value:'marcaModelo',
        width:'8.33%',
      },
      {
        text:"Máquina Instancia",
        align:'center',
        sort:false,
        value:'nombreMaquinaIns',
        width:'8.33%',
      },
      {
        text:"Inicio",
        align:'center',
        sort:false,
        value:'inicio',
        width:'8.33%',
      },
      {
        text:"Fin",
        align:'center',
        sort:false,
        value:'fin',
        width:'8.33%',
      },
      {
        text:"Duración",
        align:'center',
        sort:false,
        value:'duracion',
        width:'8.33%',
      },
      {
        text:"Realizo",
        align:'center',
        sort:false,
        value:'realizo',
        width:'8.33%',
      },
      /*
      {
        text:"Área",
        align:'center',
        sort:false,
        value:'area',
        width:'8.33%',
      },
      {
        text:"Artículo Objetivo",
        align:'center',
        sort:false,
        value:'articuloTOD',
        width:'8.33%',
      },
      {
        text:"Artículo Producido",
        align:'center',
        sort:false,
        value:'articuloOperacion',
        width:'8.33%',
      },
      */
    ],
    defaultTiempoChartOptions: {
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: [],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      yaxis: {
        labels: {
          formatter: function (value) {
            var tiempo = Math.floor(value);
            var horas = Math.trunc(tiempo/3600);
            tiempo -= horas * 3600;
            if(horas <10) horas = "0"+horas;
            var minutos =  Math.trunc(tiempo / 60);
            tiempo -= minutos * 60;
            if(minutos <10) minutos = "0"+minutos;
            if(tiempo <10) tiempo = "0"+tiempo;
            return horas+":"+minutos+":"+tiempo;
          }
        }
      }
    },
    usoTiempoChartOptions:{
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: [],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },
    usoTiempoSeries:[],
    usoCantidadChartOptions:{
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: [],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },
    usoCantidadSeries:[],
    usoCantidadUnidades:[],
    defaultCantidadChartOptions: {
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: [],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      yaxis: {
        labels: {
          formatter:(value,opts) =>{
            return value;
          },
        }
      }
    },
    MTBFSeries: [{
      data: [],
      name: "MTBF"
    }],
    MTBFchartOptions: {
      chart: {
        height: 380,
        type: 'bar',
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            fontSize: '12px'
          }
        }
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            var tiempo = Math.floor(value);
            var horas = Math.trunc(tiempo/3600);
            tiempo -= horas * 3600;
            if(horas <10) horas = "0"+horas;
            var minutos =  Math.trunc(tiempo / 60);
            tiempo -= minutos * 60;
            if(minutos <10) minutos = "0"+minutos;
            if(tiempo <10) tiempo = "0"+tiempo;
            return horas+":"+minutos+":"+tiempo;
          }
        }
      }
    },
    defaultMTBFChartOptions: {
      chart: {
        height: 380,
        type: 'bar',
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            fontSize: '12px'
          }
        }
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            var tiempo = Math.floor(value);
            var horas = Math.trunc(tiempo/3600);
            tiempo -= horas * 3600;
            if(horas <10) horas = "0"+horas;
            var minutos =  Math.trunc(tiempo / 60);
            tiempo -= minutos * 60;
            if(minutos <10) minutos = "0"+minutos;
            if(tiempo <10) tiempo = "0"+tiempo;
            return horas+":"+minutos+":"+tiempo;
          }
        }
      }
    },
    MTTRSeries: [{
      data: [],
      name: "MTTR"
    }],
    MTTRChartOptions: {
      chart: {
        height: 380,
        type: 'bar',
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            fontSize: '12px'
          }
        }
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            var tiempo = Math.floor(value);
            var horas = Math.trunc(tiempo/3600);
            tiempo -= horas * 3600;
            if(horas <10) horas = "0"+horas;
            var minutos =  Math.trunc(tiempo / 60);
            tiempo -= minutos * 60;
            if(minutos <10) minutos = "0"+minutos;
            if(tiempo <10) tiempo = "0"+tiempo;
            return horas+":"+minutos+":"+tiempo;
          }
        }
      }
    },
    defaultMTTRChartOptions: {
      chart: {
        height: 380,
        type: 'bar',
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            fontSize: '12px'
          }
        }
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            var tiempo = Math.floor(value);
            var horas = Math.trunc(tiempo/3600);
            tiempo -= horas * 3600;
            if(horas <10) horas = "0"+horas;
            var minutos =  Math.trunc(tiempo / 60);
            tiempo -= minutos * 60;
            if(minutos <10) minutos = "0"+minutos;
            if(tiempo <10) tiempo = "0"+tiempo;
            return horas+":"+minutos+":"+tiempo;
          }
        }
      }
    }
  }),
  created(){
    this.initialize();
  },
  computed:{
    instanciasFiltradas(){
      if(this.$utils.isValid(this.filtros.maquina)){
        return this.instancias.filter(instancia => {
          return instancia.idMaquina == this.filtros.maquina;
        })
      }
      else return this.instancias;
    },
    maquinasFiltradas(){
      if(this.$utils.isValid(this.filtros.categoria)){
        if(this.filtros.categoria == 0){
          return this.instancias.filter(instancia => {
            return instancia.idMaquinaCategoria == null;
          })
        }
        else{
          return this.instancias.filter(instancia => {
            return instancia.idMaquinaCategoria == this.filtros.categoria;
          })
        }
      }
      else return this.instancias;
    },
    historial_fetch_url(){
      return `/Mantenimientos/ReporteMaquinasUso?paginar=true&page=${this.historial_actual_page}&size=${this.historial_items_per_page}` + this.historial_filtros_url;
    },
    historial_estadisticas_fetch_url(){
      var filtros = this.historial_filtros_url;
      if(filtros.length > 0 && filtros[0] == '&'){
        filtros = filtros.substr(1);
      }
      return `/Mantenimientos/ReporteTiempoMaquina?`+filtros;
    },
    mttr_fetch_url(){
      var filtros = this.historial_filtros_url;
      if(filtros.length > 0 && filtros[0] == '&'){
        filtros = filtros.substr(1);
      }
      return `/DisponibilidadEquipo/MTTR?`+filtros;
    },
    mtbf_fetch_url(){
      var filtros = this.historial_filtros_url;
      if(filtros.length > 0 && filtros[0] == '&'){
        filtros = filtros.substr(1);
      }
      return `/DisponibilidadEquipo/MTBF?`+filtros;
    },
    historial_filtros_url() {
      var url = "";

      if(this.$utils.isValid( this.filtros.categoria )){
        url += '&IdMaquinaCategoria='+this.filtros.categoria;
      }

      if(this.$utils.isValid(this.filtros.maquina)){
        url += '&idMaquina='+this.filtros.maquina;  
      }

      if(this.$utils.isValid(this.filtros.instancia)){
        url += '&idMaquinaIns='+this.filtros.instancia;  
      }

      if(this.$utils.isValid(this.filtros.inicio)){
        url += '&inicio='+this.filtros.inicio;  
      }

      if(this.$utils.isValid(this.filtros.fin)){
        url += '&fin='+this.filtros.fin;  
      }

      return url;
    },
  },
  methods:{
    marcaModelo: item => item.marca +' - '+item.modelo,
    initialize(){
      this.getMaquinas();
      this.getInstancias();
      this.historial_update_page_action(1);
    },
    getMaquinas(){
      axios
      .get("/Maquinas")
      .then(response =>  {
        this.maquinas = response.data.filter(function(el) {
          return (el.esMaquina == true);
        });
        this.categorias = this.maquinas.map( elemento => ({
          idMaquinaCategoria: (elemento.idMaquinaCategoria == null)?0:elemento.idMaquinaCategoria,
          categoria: elemento.categoria
        }));
      })
      .catch(error => {
        console.log(error);
      });
    },
    getInstancias(){
      axios
      .get("/MaquinaInstancias")
      .then(response =>  {
        this.instancias = response.data;
      })
      .catch(error => {
        console.log(error);
      });
    },
    changeCategoria(){
      this.filtros.maquina = null;
      this.filtros.instancia = null;
      this.historial_update_page_action(1);
    },
    changeMaquina(){
      this.filtros.instancia = null;
      this.historial_update_page_action(1);
    },
    async historial_update_page_action(page) {
      this.historial_actual_page = page;
      this.loading = true;
      try {
        let response = await this.getHistorial();
        this.historial = response.data.result;
        this.historial_total = response.data.total;
        this.loading = false;
      } catch (exception) {
        console.log(exception);
      }
        
      this.getEstadisticas()
      .then(response => {
        this.estadisticas = response.data;
        var usoTiempoChartOptions = Object.assign({},this.defaultTiempoChartOptions);
        usoTiempoChartOptions.labels = this.estadisticas.map(e => {
          //return e.marca + " - " + e.modelo + " [" +e.codigo +"]"
          return e.codigo;
        });
        this.usoTiempoChartOptions = usoTiempoChartOptions;
        this.usoTiempoSeries = this.estadisticas.map(e => e.segundos);
        setTimeout(() => {
          this.$refs.chartSegundos.refresh();
        },350);
        var usoCantidadChartOptions = Object.assign({},this.defaultCantidadChartOptions);
        usoCantidadChartOptions.labels = this.estadisticas.map(e => {
          //return e.marca + " - " + e.modelo + " [" +e.codigo +"]"
          return e.codigo;
        });
        this.usoCantidadChartOptions = usoCantidadChartOptions;
        this.usoCantidadSeries = this.estadisticas.map(e => e.cantidad);
        this.usoCantidadUnidades = this.estadisticas.map(e => e.unidad);
        this.usoCantidadChartOptions.yaxis.labels.formatter = (value,opts) => {
          return value.toFixed(2) +" "+ this.usoCantidadUnidades[opts.seriesIndex]
        }
        setTimeout(() => {
          this.$refs.chartCantidad.refresh();
        },350);
      })
      .catch(error => {
        console.log(error);
      });
      
      this.getMTTR()
      .then(response => {
          this.mttr = response.data;
          var MTTRChartOptions = Object.assign({},this.defaultMTTRChartOptions);
          MTTRChartOptions.xaxis.categories = this.mttr.map(x => x.codigo);
          var MTTRSeries = [{
              data: this.mttr.map(x => x.segundos),
              name: "MTTR"
          }];
          this.MTTRchartOptions = MTTRChartOptions;
          this.MTTRSeries = MTTRSeries;
        setTimeout(() => {
          //this.$refs.chartMTTR.refresh();
        },350);
      })
      .catch(error => {
        console.log(error);
      })
    
      this.loadingMTBF = true;
      this.getMTBF()
      .then(response => {
        this.mtbf = response.data;
        var MTBFChartOptions = Object.assign({},this.defaultMTBFChartOptions);
        MTBFChartOptions.xaxis.categories = this.mtbf.map(x => x.codigo);
        var MTBFSeries = [{
            data: this.mtbf.map(x => x.segundos),
            name: "MTBF"
        }];
        this.MTBFchartOptions = MTBFChartOptions;
        this.MTBFSeries = MTBFSeries;
        this.loadingMTBF = false;
      })
      .catch(error => {
        console.log(error);
      })
    },
    getHistorial() {
      //* Realizamos peticion HTTP
      return new Promise((resolve, reject) => {
        axios
          .get(this.historial_fetch_url)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    getEstadisticas() {
      //* Realizamos peticion HTTP
      return new Promise((resolve, reject) => {
        axios
          .get(this.historial_estadisticas_fetch_url)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    getMTTR() {
      //* Realizamos peticion HTTP
      return new Promise((resolve, reject) => {
        axios
          .get(this.mttr_fetch_url)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    getMTBF() {
      //* Realizamos peticion HTTP
      return new Promise((resolve, reject) => {
        axios
          .get(this.mtbf_fetch_url)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    historial_sort_by_action($sort) {
    },
    descargarReporte() {
			var datetime = moment().format("YYYYMMDDHHmmss");
			var url = this.historial_filtros_url;
			if (url.length > 0 && url[0] == "&") url = "?" + url.substring(1);
			this.$utils.axios.downloadFile(
				"/Mantenimientos/XLSReporteMaquinasUso" + url,
				"GET",
				"historial-uso-maquinas-" + datetime + ".xlsx",
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			);
		},
  }
}
</script>