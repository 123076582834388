<template>
  <v-col cols="12">
    <template>
      <v-row justify="center">
        <v-dialog v-model="eliminar" persistent max-width="500px" :key="eliminar">
          <v-card>
            <v-card-title class="headline">¿Está seguro que desea eliminar este cliente?</v-card-title>
            <v-card-text>Esta acción no se puede revertir y será permanente.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="eliminar = false; delItem = '';">Cancelar</v-btn>
              <v-btn color="red darken-1" text @click="eliminar = false; deleteItemDB()">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-data-table
      v-if="permisoVista('clientes','r')"
      :headers="headers"
      :items="clientes"
      :search="search"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-dialog v-model="dialog" persistent max-width="500px" :key="dialog">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on" v-if="permisoVista('clientes','c')">Nuevo Cliente</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.nombre" hide-details label="Nombre o Razón Social del Cliente"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.rfc" hide-details label="RFC del Cliente"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.telefono" hide-details label="Teléfono del Cliente"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.email" hide-details label="Correo Electrónico del Cliente"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-select
                        v-model="editedItem.comercialLineas"
                        label="Líneas Comerciales"
                        :items="lineascomerciales"
                        item-text="nombre"
                        item-value="idComercialLinea"
                        multiple
                        hide-details
                      ></v-select>
                    </v-col>
                  </v-row>
                  <ul>
                    <li class="red--text" v-for="ex in errores" :key="ex">{{ ex }}</li>
                  </ul>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog=false; errores=[];">Cancelar</v-btn>
                <v-btn color="red darken-1" text @click="save()" :disabled="saving" :loading="saving">
                  Guardar
                  <template v-slot:loader>
                    <v-progress-circular
                      indeterminate
                      :width="2"
                      :size="24"
                      color="red"
                    ></v-progress-circular>
                  </template>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('clientes','u')"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          edit
        </v-icon>
         </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="alerta"
      top
      color="error"
    >
      Existe un Cliente Activa con el mismo RFC
      <v-btn
        text
        @click="alerta = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
  </v-col>
  
</template>

<script>
import axios from "axios";

  export default {
    data: () => ({
      saving: false,
      dialog: false,
      alerta: false,
      eliminar: false,
      search: '',
      headers: [
        {
          text: 'Cliente',
          align: 'left',
          sortable: true,
          value: 'nombre',
        },
        {
          text: 'RFC',
          align: 'center',
          sortable: true,
          value: 'rfc',
        },
        {
          text: 'Teléfono',
          align: 'center',
          sortable: true,
          value: 'telefono',
        },
        {
          text: 'Correo Electrónico',
          align: 'center',
          sortable: true,
          value: 'email',
        },
        { text: 'Acciones', value: 'action', sortable: false, width:'20%', align:'center' },
      ],
      loading: false,
      clientes: [],
      delItem: '',
      action: 0,
      errores: [],
      editedIndex: -1,
      editedItem: {
        nombre: '',
        rfc: '',
        telefono: '',
        email:'',
        comercialLineas:[],
      },
      defaultItem: {
        nombre: '',
        rfc: '',
        telefono: '',
        email:'',
        comercialLineas:[],
      },
      loadingComercialLineas:false,
      lineascomerciales:[],
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Cliente' : 'Editar Cliente'
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        this.getComercialLineas();
        let me = this;
        me.loading = true;
        axios
        .get("/Clientes")
        .then(response =>  {
          me.clientes = response.data;
          me.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
      },

      editItem (item) {
        this.action = 1;
        this.editedIndex = this.clientes.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.eliminar = true;
        this.delItem = item;
      },
      deleteItemDB(){
        let me = this;
        let item = this.delItem;
        this.delItem = '';
        axios
          .put("/Clientes/Desactivar/" + item.idCliente)
          .then(response =>  {
            me.initialize();
          })
          .catch(error => {
            console.log(error);
          });
      },
      close () {
        this.action = 0,
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },
      validate(){
        this.errores =  [];
        var error = false;
        if (this.editedItem.nombre.length < 2 ||this.editedItem.nombre.length > 200){
          this.errores.push("El nombre debe tener más de 2 caracteres y menos de 200.");
          error = true;
        }
        if(this.editedItem.rfc != null && this.editedItem.rfc.length > 0){
          if(this.editedItem.rfc.length < 8 || this.editedItem.rfc.length > 20){
            this.errores.push("El RFC debe tener mínimo 8 caracteres y menos de 20.");
            error = true;
          }
        }
        
        if(this.editedItem.telefono != null && this.editedItem.telefono.length > 0){
          if(this.editedItem.telefono.length < 5){
            this.errores.push("El Teléfono debe tener mínimo 5 dígitos.");
            error = true;
          }
          else{
            if(!this.soloNumeros(this.editedItem.telefono)){
              this.errores.push("El Teléfono debe tener solo dígitos.");
              error = true;
            }
          }
        }

        if(this.editedItem.email != null && this.editedItem.email.length > 0){
          if(this.editedItem.email.length <7){
            this.errores.push("El Correo Electrónico debe tener mínimo 7 caracteres.");
            error = true;
          }
          else{
            if(!this.validEmail(this.editedItem.email)){
              this.errores.push("El Correo Electrónico no cumple con la sintaxis");
              error = true;
            }

          }
        }

        return (!error);
      },
      soloNumeros(cadena){
        return (/^\d+$/.test(cadena));
      },
      validEmail(cadena){
         return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(cadena));
      },
      save () {
        this.action = 0;
        if(this.validate()){
          let me = this;
          me.saving = true;
          if (this.editedIndex > -1)
          {
            //Código para editar
            axios
              .put("/Clientes/Actualizar/"+me.clientes[me.editedIndex].idCliente, {
                Nombre: me.editedItem.nombre,
                RFC: (me.editedItem.rfc != null && me.editedItem.rfc.length > 0)?me.editedItem.rfc:null,
                Telefono: (me.editedItem.telefono != null && me.editedItem.telefono.length > 0)?me.editedItem.telefono:null,
                Email: (me.editedItem.email != null && me.editedItem.email.length > 0)? me.editedItem.email: null,
                ComercialLineas:me.editedItem.comercialLineas,
              })
              .then(response =>  {
                me.saving = false;
                me.close();
                me.initialize();
              })
              .catch(error => {
                me.saving = false;
                if (error.response) {
                  if(error.response.status == 409){
                    me.alerta = true;
                    me.errores.push("Existe un Cliente Activa con el Mismo RFC");
                  }
                }
              });
          }
          else
          {
            axios
              .post("/Clientes/Crear", {
                Nombre: me.editedItem.nombre,
                RFC: me.editedItem.rfc,
                Telefono: me.editedItem.telefono,
                Email: me.editedItem.email,
                ComercialLineas:me.editedItem.comercialLineas,
              })
              .then(response =>  {
                me.saving = false;
                me.close();
                me.initialize();
              })
              .catch(error => {
                me.saving = false;
                if (error.response) {
                  if(error.response.status == 409){
                    me.alerta = true;
                    me.errores.push("Existe un Cliente Activa con el Mismo RFC");
                  }
                }
              });
          }
        }
      },
      getComercialLineas () {
        let me = this;
        me.loadingComercialLineas = true;
        axios
        .get("/ComercialLineas")
        .then(response =>  {
          me.lineascomerciales = response.data;
          me.loadingComercialLineas = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
    },
  }
</script>