<template>
	<v-container fluid class="pa-0">
		<v-dialog v-model="dialogDescarga" persistent max-width="500px">
			<v-card>
				<v-card-title style="word-break: break-word"
					>Descargar Reporte de Tareas de Mantenimiento</v-card-title
				>
				<v-card-text>
					<v-container fluid>
						<v-row>
							<v-col cols="12">
								<datePicker
									v-model="filtrosDescarga.fechaInicio"
									format="YYYY-MM-DD"
									label="[Obligatorio] Fecha de Inicio"
									clearable
									:disabled="downloading"
									@input="errorFechaInicio = ''"
								></datePicker>
								<p
									v-if="errorFechaInicio"
									class="mb-0 red--text"
								>
									{{ errorFechaInicio }}
								</p>
							</v-col>
							<v-col cols="12">
								<datePicker
									v-model="filtrosDescarga.fechaFin"
									format="YYYY-MM-DD"
									label="[Opcional] Fecha de Fin"
									clearable
									:disabled="downloading"
									@input="errorFechaFin = ''"
								></datePicker>
								<p v-if="errorFechaFin" class="mb-0 red--text">
									{{ errorFechaFin }}
								</p>
							</v-col>
							<v-col cols="12">
								<v-autocomplete
									v-model="filtrosDescarga.idsPersonas"
									:label="computedLabelIdsPersonas"
									:items="operadores"
									:item-text="nombrePersona"
									item-value="idPersona"
									multiple
									clearable
									:disabled="downloading"
									chips
									deletable-chips
								></v-autocomplete>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="downloading"
						@click="cerrarDialogDescarga"
						>Cerrar</v-btn
					>
					<v-btn
						text
						color="primary"
						:disabled="downloading"
						:loading="downloading"
						@click="descargarReporte"
						>Descargar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col cols="4" style="padding-top: 22px">
				<datePicker
					v-model="filtros.fecha"
					format="YYYY-MM-DD"
					label="[Obligatorio] Fecha"
					clearable
					@input="getData"
					:disabled="loading"
				></datePicker>
			</v-col>
			<v-col cols="4" style="padding-top: 22px">
				<v-select
					v-model="filtros.tipoMantenimiento"
					label="[Opcional] Tipo de Mantenimiento"
					:items="tiposMantenimientos"
					hide-details
					clearable
					@change="getData"
					:disabled="loading"
				></v-select>
			</v-col>
			<v-col cols="4">
				<v-autocomplete
					v-model="filtros.idsSubdepartamentos"
					label="[Opcional] Subdepartamentos"
					:items="subdepartamentos"
					:item-text="nombreSubdepto"
					item-value="idSubdepartamento"
					hide-details
					clearable
					multiple
					chips
					deletable-chips
					:loading="loadingSubdeptos"
					:disabled="loading"
					@change="getData"
				></v-autocomplete>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="4" class="py-0">
				<v-btn
					color="blue darken-2"
					text
					@click="showDialogDescargar"
					:disabled="loading || filtros.fecha == null || !hayDatos"
				>
					Descargar Reporte de<br />Tareas de Mantenimiento
				</v-btn>
			</v-col>
		</v-row>
		<div
			v-if="!escenarioValido"
			class="mt-6 loading-container d-flex justify-center"
		>
			<div
				v-if="filtros.fecha == null"
				class="align-self-center text-center"
			>
				<v-icon size="140" color="grey">mdi-table-cog</v-icon>
				<p
					class="font-weight-bold"
					style="font-size: 2rem !important; color: #9e9e9e"
				>
					Seleccione una fecha
				</p>
			</div>
			<div v-else-if="loading" class="align-self-center text-center">
				<v-progress-circular
					indeterminate
					size="100"
					width="7"
					color="blue darken-2"
				></v-progress-circular>
				<p class="text-h4">Cargando</p>
			</div>
			<div v-else-if="!hayDatos" class="align-self-center text-center">
				<v-icon size="140" color="grey">mdi-table-cog</v-icon>
				<p
					class="font-weight-bold"
					style="font-size: 2rem !important; color: #9e9e9e"
				>
					{{
						operadores.length == 0
							? "No hay operadores disponibles"
							: "No hay datos disponibles"
					}}
				</p>
			</div>
		</div>
		<div v-else class="mt-6 d-flex justify-center flex-column">
			<div style="height: 0.75rem">
				<v-progress-linear
					v-if="saving"
					indeterminate
					color="blue darken-2"
				></v-progress-linear>
			</div>
			<div class="d-flex justify-center">
				<ColumnTablero
					v-for="(tarea, index) in tareas"
					:key="`column-${index}`"
					class="mr-3 operadores-container"
					:item="tarea"
					:saving="saving"
					@change="procesarAsignacionTarea"
				/>
				<div class="d-flex operadores-container">
					<ColumnTablero
						v-for="operador in operadores"
						:key="operador.idPersona"
						class="mr-3"
						:color="operador.color"
						:item="operador"
						:saving="saving"
						@change="procesarAsignacionTarea"
					/>
				</div>
			</div>
		</div>
	</v-container>
</template>

<script>
import { defineAsyncComponent } from "vue";
import axios from "axios";
import qs from "qs";
import moment from "moment";
import { mapState } from "vuex";

export default {
	components: {
		ColumnTablero: defineAsyncComponent(() =>
			import("./Tablero/ColumnTablero.vue")
		),
	},
	data() {
		return {
			lastAdded: null,
			lastRemoved: null,
			errorFechaInicio: "",
			errorFechaFin: "",
			errorPersonas: "",
			downloading: false,
			filtrosDescarga: {
				fechaInicio: undefined,
				fechaFin: undefined,
				idsPersonas: [],
			},
			defaultFiltrosDescarga: {
				fechaInicio: undefined,
				fechaFin: undefined,
				idsPersonas: [],
			},
			dialogDescarga: false,
			subdepartamentos: [],
			loadingSubdeptos: false,
			tareas: [],
			operadores: [],
			filtros: {
				fecha: undefined,
				tipoMantenimiento: undefined,
				idsSubdepartamentos: [],
			},
			tiposMantenimientos: [
				{
					text: "Preventivo",
					value: 1,
				},
				{
					text: "Correctivo",
					value: 2,
				},
				{
					text: "Inspección",
					value: 3,
				},
			],
			loading: false,
			saving: false,
		};
	},
	computed: {
		...mapState(["token"]),
		escenarioValido() {
			return !(
				this.loading ||
				this.filtros.fecha == null ||
				!this.hayDatos
			);
		},
		computedLabelIdsPersonas() {
			return this.filtrosDescarga.idsPersonas.length > 0
				? "[Opcional] Personas"
				: "[Opcional] Personas: Todas";
		},
		hayDatos() {
			//Caso 1: Si hay tareas debe haber operadores
			const caso1 =
				this.tareas[0].tareasAsignadas.length > 0 &&
				this.operadores.length > 0;
			//Caso 2: Si hay operadores debe haber tareas dentro de los mismos operadores o en tareas sin asignar
			const caso2 =
				(this.operadores.length > 0 &&
					this.tareas[0].tareasAsignadas.length > 0) ||
				(this.operadores.length > 0 &&
					this.operadores.reduce(
						(acumulador, objeto) =>
							acumulador + objeto.tareasAsignadas.length,
						0
					) > 0);

			return caso1 || caso2;
		},
	},
	mounted() {
		this.initialize();
	},
	methods: {
		cerrarDialogDescarga() {
			this.dialogDescarga = false;
			this.$nextTick().then(() => {
				this.filtrosDescarga = JSON.parse(
					JSON.stringify(this.defaultFiltrosDescarga)
				);
				this.errorFechaInicio = "";
				this.errorFechaFin = "";
				this.errorPersonas = "";
			});
		},
		nombrePersona: ({ nombre, paterno, materno }) =>
			`${nombre ? `${nombre} ` : ""}${paterno ? `${paterno} ` : ""}${
				materno ? `${materno} ` : ""
			}`.trim(),
		showDialogDescargar() {
			this.filtrosDescarga = JSON.parse(
				JSON.stringify(this.defaultFiltrosDescarga)
			);
			this.filtrosDescarga.fechaInicio = this.filtros.fecha;
			this.dialogDescarga = true;
		},
		validateDescarga() {
			this.errorFechaInicio = "";
			this.errorFechaFin = "";
			this.errorPersonas = "";

			if (this.filtrosDescarga.fechaInicio == null) {
				this.errorFechaInicio = "Requerido";
			}

			// if(this.filtrosDescarga.fechaFin == null){
			// 	this.errorFechaFin = 'Requerido';
			// }

			return this.errorFechaInicio == "";
		},
		descargarReporte() {
			if (!this.validateDescarga()) return;

			this.downloading = true;

			const datetime = moment().format("YYYYMMDDHHmmss");
			axios({
				url: "/Mantenimientos/ImprimirAsignaciones",
				params: {
					...this.filtrosDescarga,
					token: this.token,
				},
				paramsSerializer: (params) =>
					qs.stringify(params, { indices: false }),
				method: "GET",
				responseType: "blob",
			})
				.then((response) => {
					let blob = new Blob([response.data], {
						type: "application/zip",
					});
					let a = document.createElement("a");
					let blobURL = URL.createObjectURL(blob);
					a.download = `reporte-tareas-mantenimiento-${datetime}.zip`;
					a.href = blobURL;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
					this.downloading = false;
					this.cerrarDialogDescarga();
				})
				.catch((error) => {
					this.downloading = false;
					console.log(error);
				});
		},
		nombreSubdepto: ({ codigo, nombre }) =>
			codigo ? `[${codigo}] - ${nombre}`.trim() : `${nombre}`.trim(),
		initialize() {
			this.getData();
			this.getSubdepartamentos();
		},
		getSubdepartamentos() {
			this.loadingSubdeptos = true;
			axios
				.get("/Subdepartamento")
				.then((response) => {
					this.subdepartamentos = response.data;
				})
				.catch(console.log)
				.finally(() => {
					this.loadingSubdeptos = false;
				});
		},
		esAsignacionValida() {
			if (!this.lastAdded.item.hasOwnProperty("idPersona")) return true;

			//En caso contrario la tarea se puede asignar a cualquier operador si es un colaborador
			if(this.lastAdded.item.colaborador) return true;

			//Si la tarea es de un subdepartamento solo se puede asignar a operadores con subdepartamentos validos
			if (this.lastAdded.element.idSubdepartamento == null) return true;
			
			//La tarea solo se puede asignar a operadores con subdepartamentos validos
			return this.lastAdded.item.idsSubdepartamentos.includes(
				this.lastAdded.element.idSubdepartamento
			);
		},
		revertirUltimoMovimiento() {
			const indexLastAdded =
				this.lastAdded.item.tareasAsignadas.findIndex(
					(tarea) =>
						tarea.idMantenimientoCalendarioInspeccion ==
							this.lastAdded.element
								.idMantenimientoCalendarioInspeccion &&
						tarea.idMantenimientoTarea ==
							this.lastAdded.element.idMantenimientoTarea
				);

			this.lastAdded.item.tareasAsignadas.splice(indexLastAdded, 1);

			this.lastRemoved.item.tareasAsignadas.splice(
				this.lastRemoved.oldIndex,
				0,
				{
					...this.lastRemoved.element,
				}
			);
		},
		procesarAsignacionTarea(event, item) {
			const eventKey = Object.keys(event)[0];
			if (eventKey == "added") {
				this.lastAdded = {
					element: event.added.element,
					item,
				};
			} else if (eventKey == "removed") {
				this.lastRemoved = {
					element: event.removed.element,
					oldIndex: event.removed.oldIndex,
					item,
				};
			}

			if (eventKey != "removed") return;

			//Validar asignacion de tarea con el subdepartamento destino
			if (!this.esAsignacionValida()) {
				this.revertirUltimoMovimiento();
				return;
			}

			this.asignarTarea();
		},
		asignarTarea() {
			this.saving = true;
			axios
				.put("/Mantenimientos/AsignarTareaOInspeccion", {
					idMantenimientoTarea:
						this.lastAdded.element.idMantenimientoTarea,
					idMantenimientoCalendarioInspeccion:
						this.lastAdded.element
							.idMantenimientoCalendarioInspeccion,
					idPersona: this.lastAdded.item?.idPersona,
				})
				.catch((error) => {
					console.log(error);
					this.revertirUltimoMovimiento();
				})
				.finally(() => {
					this.saving = false;
				});
		},
		getData(enableLoading = true) {
			if (this.filtros.fecha == null) return;

			if (enableLoading) this.loading = true;

			axios
				.get("/Mantenimientos/ListarTareasMantenimiento", {
					params: this.filtros,
					paramsSerializer: (params) =>
						qs.stringify(params, { indices: false }),
				})
				.then((response) => {
					this.tareas = [
						{
							tareasAsignadas: response.data.tareasSinAsignar,
						},
					];
					this.operadores = response.data.operadores;
				})
				.catch(console.log)
				.finally(() => {
					if (enableLoading) this.loading = false;
				});
		},
	},
};
</script>

<style scoped>
.operadores-container {
	overflow-x: auto;
}

.loading-container {
	height: calc(100vh - 21.875rem);
}
</style>