import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCol,{attrs:{"cols":"12","md":"12","sm":"12"}},[_c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.descargarDialog),callback:function ($$v) {_vm.descargarDialog=$$v},expression:"descargarDialog"}},[_c(VCard,[_c(VCardTitle),_c(VCardText,{staticClass:"text-center"},[_c('lottie',{attrs:{"options":_vm.checkOptions,"height":200,"width":200}}),_c('a',{staticClass:"headline",attrs:{"target":"__blank","href":'/api/Inventario/DescargarQR/'+_vm.ficheroDescargar+'?access_token='+_vm.token}},[_c(VIcon,{attrs:{"color":"primary","large":""}},[_vm._v("mdi-download")]),_vm._v(" Descargar Códigos QR")],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"green","text":""},on:{"click":function($event){_vm.ficheroDescargar = ''; _vm.descargarDialog = false;}}},[_vm._v("Cerrar")])],1)],1)],1),_c(VRow,[_c(VCol,{staticClass:"pt-0 d-flex justify-space-between",attrs:{"cols":"12"}},[(_vm.permisoVista('movimientos', 'r'))?_c('download-btn',{attrs:{"color":"blue darken-1","text":"","label":"Descargar Reporte<br>de Resumen de Movimientos XLSX"},on:{"click":_vm.descargarReporte}}):_vm._e(),(_vm.permisoVista('movimientos', 'r'))?_c('download-btn',{attrs:{"color":"blue darken-1","text":"","label":"Descargar Reporte<br>de Resumen de Movimientos PDF"},on:{"click":_vm.descargarReportePDF}}):_vm._e(),(_vm.trazabilidadInventario)?_c(VBtn,{staticStyle:{"font-size":"12px !important"},attrs:{"text":"","color":"primary"},domProps:{"innerHTML":_vm._s("Nuevo Movimiento<br>entre Almacenes")},on:{"click":function($event){return _vm.$emit('showDialogMovimientoAlmacenes')}}}):_vm._e()],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headersFiltrados,"items":_vm.items,"loading":_vm.loading,"sort-by":['fecha'],"sort-desc":[true],"page":_vm.page,"item-class":_vm.rowSospechoso},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.icono",fn:function(ref){
var item = ref.item;
return [(item.idTipoMovimiento==6)?_c(VIcon,{attrs:{"color":_vm.colores[0],"small":""}},[_vm._v(_vm._s('mdi-'+_vm.iconos[0]))]):(item.idTipoMovimiento==7)?_c(VIcon,{attrs:{"color":"orange","small":""}},[_vm._v(_vm._s('mdi-swap-horizontal-bold'))]):_c(VIcon,{attrs:{"color":_vm.colores[item.idTipoMovimiento -1],"small":""}},[_vm._v(_vm._s('mdi-'+_vm.iconos[item.idTipoMovimiento -1]))])]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fechaLabel)+" ")]}},{key:"item.numerodeFactura",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.numerodeFactura == null ? 'N/A' : item.numerodeFactura)+" ")]}},{key:"item.articulo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.codigoArticulo!=null ? ("[" + (item.codigoArticulo) + "] - " + (item.articulo)) : ("" + (item.articulo)))+" ")]}},{key:"item.cantidad",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cantidad%1!=0?item.cantidad.toFixed(4):item.cantidad)+" "+_vm._s(item.unidad)+" ")]}},{key:"item.cliente",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cliente != null ? item.cliente: item.idTipoMovimiento == 1 ? _vm.nombreEmpresa : 'N/A')+" ")]}},{key:"item.trabajoOrden",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.trabajoOrden != null ? item.trabajoOrden: (item.idTipoMovimiento == 1)?'N/D':'N/A')+" ")]}},{key:"item.articuloProducir",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.articuloProducir != null ? item.articuloProducir: (item.idTipoMovimiento == 1)?'N/D':'N/A')+" ")]}},{key:"item.sospechoso",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(!!item.sospechoso?'Si':'No')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.permisoVista('movimientos', 'r'))?_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.validarDescarga(item)),expression:"validarDescarga(item)"}],staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.downloadItem(item)}}},on),[_vm._v(" mdi-download ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Descargar Etiquetas")])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }