import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.value,"persistent":_vm.persistent,"max-width":_vm.dialogWidth}},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.title))]),_c(VCardText,[_c('StreamBarcodeReader',{ref:"codereader",on:{"decode":_vm.onDecode}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"block":"","outlined":"","color":"green"},on:{"click":_vm.onClickCancelar}},[_vm._v(" Cancelar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }