<template>
	<v-container fluid>
		<v-dialog v-model="dialog" persistent max-width="500px">
			<v-card>
				<v-card-title>{{ computedTitle }}</v-card-title>
				<v-card-text>
					<v-container fluid>
						<v-row>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.numero"
									label="Número"
									type="number"
									hide-spin-buttons
									clearable
									:disabled="saving"
									:error-messages="errores.errorNumero"
									@focus="errores.errorNumero = null"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.identificador"
									label="Identificador Activos"
									clearable
									:disabled="saving"
									:error-messages="errores.errorIdentificador"
									@focus="errores.errorIdentificador = null"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.descripcion"
									label="Descripción Activos"
									clearable
									:disabled="saving"
									:error-messages="errores.errorDescripcion"
									@focus="errores.errorDescripcion = null"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-autocomplete
									v-model="editedItem.departamento"
									label="Departamento"
									:items="departamentos"
									clearable
									:disabled="saving"
									:error-messages="errores.errorDepartamento"
									@focus="errores.errorDepartamento = null"
								></v-autocomplete>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.nombreLinea"
									label="Linea"
									clearable
									:disabled="saving"
									:error-messages="errores.errorLinea"
									@focus="errores.errorLinea = null"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.codigoX5"
									label="Código X5"
									clearable
									:disabled="saving"
									:error-messages="errores.errorX5"
									@focus="errores.errorX5 = null"
								></v-text-field>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="saving"
						@click="closeDialog"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="saving"
						:loading="saving"
						@click="save"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col cols="12" class="d-flex justify-space-between">
				<v-col cols="4">
					<v-btn
						color="primary"
						:disabled="loadingEquipos"
						@click="showDialog()"
						>Nuevo Equipo</v-btn
					>
				</v-col>
				<v-col cols="4">
					<v-text-field
						v-model="search"
						label="Buscar"
						append-icon="mdi-magnify"
						hide-details
						clearable
						:disabled="loadingEquipos"
					></v-text-field>
				</v-col>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" class="px-6">
				<v-data-table
					:items="equipos"
					:loading="loadingEquipos"
					:headers="equiposHeaders"
					:search="search"
				>
					<template v-slot:[`item.actions`]="{ item }">
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									@click="showDialog(item)"
								>
									mdi-pencil
								</v-icon>
							</template>
							<span class="white--text">Editar</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			saving: false,
			lineas: [],
			departamentos: [
				"SIN ESPECIFICAR",
				"BATERIAS",
				"ENSAMBLE",
				"MOTORES",
			],
			errores: {
				errorNumero: null,
				errorIdentificador: null,
				errorDescripcion: null,
				errorDepartamento: null,
				errorLinea: null,
				errorX5: null,
			},
			search: null,
			equipos: [],
			loadingEquipos: false,
			equiposHeaders: [
				{
					text: "Número",
					value: "numero",
				},
				{
					text: "Identificador Activos",
					value: "identificador",
				},
				{
					text: "Descripción Activos",
					value: "descripcion",
				},
				{
					text: "Departamento",
					value: "departamento",
				},
				{
					text: "Nombre de Linea",
					value: "nombreLinea",
				},
				{
					text: "Código X5",
					value: "codigoX5",
				},
				{
					text: "Acciones",
                    align: "center",
					value: "actions",
				},
			],
			dialog: false,
			editedItem: {
				idEquipo: null,
				numero: null,
				identificador: null,
				descripcion: null,
				departamento: null,
				nombreLinea: null,
				codigoX5: null,
			},
			defaultEditedItem: {
				idEquipo: null,
				numero: null,
				identificador: null,
				descripcion: null,
				departamento: null,
				nombreLinea: null,
				codigoX5: null,
			},
		};
	},
	computed: {
		computedTitle() {
			return this.editedItem.idEquipo == null
				? "Crear Equipo"
				: "Editar Equipo";
		},
	},
	mounted() {
		this.initialize();
	},
	methods: {
		initialize() {
			this.getEquipos();
		},
		resetErrores() {
			Object.keys(this.errores).forEach(
				(key) => (this.errores[key] = null)
			);
		},
		validate() {
			this.resetErrores();

			if (this.editedItem.numero == null)
				this.errores.errorNumero = "Requerido";
			else if (isNaN(parseInt(this.editedItem.numero)))
				this.errores.errorNumero = "Inválido";
			else if (parseInt(this.editedItem.numero) <= 0)
				this.errores.errorNumero = "Debe ser mayor a cero";

			if (this.editedItem.identificador == null)
				this.errores.errorIdentificador = "Requerido";

			if (this.editedItem.descripcion == null)
				this.errores.errorDescripcion = "Requerido";

			if (this.editedItem.departamento == null)
				this.errores.errorDepartamento = "Requerido";

			if (this.editedItem.nombreLinea == null)
				this.errores.errorLinea = "Requerido";

			if (this.editedItem.codigoX5 == null)
				this.errores.errorX5 = "Requerido";

			return Object.values(this.errores).every((value) => value == null);
		},
		save() {
			if (!this.validate()) return;

			this.saving = true;

			axios({
				url:
					this.editedItem.idEquipo == null
						? "/MMCallEquipos/Crear"
						: `/MMCallEquipos/Actualizar/${this.editedItem.idEquipo}`,
				method: this.editedItem.idEquipo == null ? "POST" : "PUT",
				data: {
                    ...this.editedItem,
                    numero: parseInt(this.editedItem.numero)
                },
			})
				.then(() => {
					this.saving = false;
					this.closeDialog();
					this.initialize();
				})
				.catch((error) => {
					this.saving = false;
					console.log(error);
				});
		},
		showDialog(item = null) {
			this.editedItem = JSON.parse(
				JSON.stringify(item || this.defaultEditedItem)
			);

			this.dialog = true;
		},
		closeDialog() {
			this.dialog = false;

			setTimeout(() => {
				this.editedItem = JSON.parse(
					JSON.stringify(this.defaultEditedItem)
				);
				this.resetErrores();
			}, 200);
		},
		getEquipos() {
			this.loadingEquipos = true;

			axios
				.get("/MMCallEquipos")
				.then((response) => {
					this.equipos = response.data;
				})
				.catch((error) => {
					this.equipos = [];
					console.log(error);
				})
				.finally(() => {
					this.loadingEquipos = false;
				});
		},
	},
};
</script>