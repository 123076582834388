<template>
	<v-col cols="12" v-if="permisoVista('mantenimiento-inspecciones','r')">
		<v-dialog v-model="dialogTerminar" persistent max-width="60%">
			<v-card>
				<v-card-title>Terminar Inspección</v-card-title>
				<v-card-text>
					<Comprobaciones ref="checklist" />
					<ul class="mt-3">
						<li class="red--text" v-for="ex in errores" :key="ex">
							{{ ex }}
						</li>
					</ul>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="green"
						text
						:disabled="loadingFinish"
						small
						@click="cerrarDialogTerminar"
						>Cancelar</v-btn
					>
					<v-btn
						color="red"
						text
						small
						:disabled="loadingFinish"
						:loading="loadingFinish"
						@click="terminarTarea"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-data-table
			:headers="headers"
			:items="inspecciones"
			:loading="loading"
			loading-text="Cargando... Espere, Por Favor."
			:footer-props="{
				disableItemsPerPage: true,
				disablePagination: this.loading,
			}"
			:disable-sort="true"
		>
			<template v-slot:item.inicioCronometro="{ item }">
				<chronometer :time="item.inicioCronometro"></chronometer>
			</template>
			<template v-slot:item.actions="{ item }">
				<v-tooltip
					v-if="permisoVista('mantenimiento-inspecciones','u')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							@click="terminarMantenimiento(item)"
						>
							stop
						</v-icon>
					</template>
					<span class="white--text">Terminar</span>
				</v-tooltip>
			</template>
		</v-data-table>
	</v-col>
</template>

<script>
import axios from "axios";
import chronometer from "@/components/Chronometer.vue";
import Comprobaciones from '../Comprobaciones.vue';

export default {
	components: {
		chronometer,
		Comprobaciones,
	},
	data() {
		return {
            terminarItem: {
                idMantenimientoCalendarioInspeccion: 0
            },
            defaultTerminarItem: {
                idMantenimientoCalendarioInspeccion: 0
            },
			errores: [],
			recomendacion: "",
			inspecciones: [],
			headers: [
				{
					text: "Código",
					align: "center",
					sort: false,
					value: "codigo",
				},
				{
					text: "Nombre",
					align: "center",
					sort: false,
					value: "nombre",
				},
				{
					text: "Responsable",
					align: "center",
					sort: false,
					value: "responsable",
				},
				{
					text: "Máquina",
					align: "center",
					sort: false,
					value: "maquina",
				},
				{
					text: "Inicio",
					align: "center",
					sort: false,
					value: "inicio",
				},
                {
					text: "Tiempo Transcurrido",
					align: "center",
					sort: false,
					value: "inicioCronometro",
				},
				{
					text: "Acciones",
					align: "center",
					sort: false,
					value: "actions",
				},
			],
			dialogTerminar: false,
			loadingFinish: false,
		};
	},
	mounted() {
		this.initialize();
	},
	methods: {
		nombreMaquina: (item) =>
			`${item.marca} - ${item.modelo} - ${item.clave}`,
		cerrarDialogTerminar(){
			this.dialogTerminar = false;
			this.errores = [];
            this.terminarItem = Object.assign( {},this.defaultTerminarItem );
			this.initialize();
		},
		validar(comprobaciones){
			this.errores = [];

			if( Array.isArray(comprobaciones) ){
				this.errores = [ ...this.errores,...comprobaciones ];
			}
			
			return this.errores.length == 0;
		},
		terminarTarea(){
			const item = this.$refs.checklist.terminarTarea();
			if ( !this.validar(item) ) return;
            const obj = {
                idMantenimientoCalendarioInspeccion: this.terminarItem.idMantenimientoCalendarioInspeccion,
                comprobaciones: item.comprobaciones
            };
			this.loadingFinish = true;
			axios
				.put("/Mantenimientos/TerminarInspeccion", obj)
				.then(() => {
					this.loadingFinish = false;
					this.cerrarDialogTerminar();
				})
				.catch((error) => {
					this.loadingFinish = false;
					console.log(error);
				});
		},
		initialize() {
			this.getMantenimientos();
		},
		getMantenimientos() {
			axios
				.get("/Mantenimientos/GetInspeccionesEnCurso")
				.then((response) => {
					this.inspecciones = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		terminarMantenimiento(item) {
			this.dialogTerminar = true;
            this.terminarItem = Object.assign( {},item );
			this.$nextTick( () => {
				this.$refs.checklist.terminarInspecciones(item.idMantenimientoInspeccion,null);
			});
		},
	},
};
</script>