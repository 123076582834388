<template>
	<v-dialog :value="value" :persistent="persistent" :max-width="dialogWidth">
		<v-card>
			<v-card-title>{{ title }}</v-card-title>
			<v-card-text>
				<StreamBarcodeReader
					ref="codereader"
					@decode="onDecode"
				></StreamBarcodeReader>
			</v-card-text>
			<v-card-actions>
				<v-btn block outlined color="green" @click="onClickCancelar">
					Cancelar
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
    components: {
        StreamBarcodeReader
    },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		dialogWidth: {
			type: String,
			default: "500px",
		},
		persistent: {
			type: Boolean,
			default: true,
		},
		title: {
			type: String,
			default: "Leer Código",
		},
	},
	data() {
		return {
			decodeListener: null,
		};
	},
	watch: {
		value() {
			if (!this.value) return;
			
            if (this.decodeListener != null)
				this.$refs.codereader.$on("decode", this.decodeListener);
		},
	},
	destroyed() {
		this.decodeListener = null;
	},
	methods: {
		onDecode(value) {
			this.$emit("decode", value);
		},
		onClickCancelar() {
			this.$emit("input", false);
			this.decodeListener = this.$refs.codereader.$listeners.decode;
			this.$refs.codereader.$off("decode", this.decodeListener);
		},
	},
};
</script>