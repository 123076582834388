<template>
	<div>
		<v-tabs v-model="tab" background-color="transparent" grow>
			<v-tab
				v-if="permisoVista('reportar-factura', 'r')"
				@click="reportarClick"
			>
				Reportar Facturas
			</v-tab>
			<v-tab
				v-if="permisoVista('facturas-sindesglosar', 'r')"
				@click="sinDesglosarClick"
			>
				Facturas sin Desglosar
			</v-tab>

			<v-tab
				v-if="permisoVista('movimientos-sindesglosar', 'r')"
				@click="movimientosClick"
			>
				Movimientos Sin Desglosar
			</v-tab>

			<v-tabs-items v-model="tab" touchless>
				<v-tab-item
					eager
					v-if="permisoVista('reportar-factura', 'r')"
				>
					<ReportarFacturas ref="reportar" />
				</v-tab-item>
				<v-tab-item
					eager
					v-if="permisoVista('facturas-sindesglosar', 'r')"
				>
					<SinDesglosar ref="sindesglosar" />
				</v-tab-item>
				<v-tab-item
					eager
					v-if="permisoVista('movimientos-sindesglosar', 'r')"
				>
					<MovimientoSinDesglosar ref="movimientosindesglosar" />
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>
	</div>
</template>

<script>
import ReportarFacturas from "./Facturas/ReportarFacturas.vue";
import SinDesglosar from './Facturas/SinDesglosar.vue';
import MovimientoSinDesglosar from './MovimientoSinDesglosar.vue';

export default {
	components: { 
		ReportarFacturas, 
		SinDesglosar,
		MovimientoSinDesglosar 
	},
	data: () => ({
		tab: 0,
	}),
	methods: {
		initialize() {
			// this.preventivoClick();
		},
		sinDesglosarClick() {
			if(typeof this.$refs.sindesglosar === 'undefined') return;
			this.$refs.sindesglosar.initialize();
		},
		movimientosClick() {
			if(typeof this.$refs.movimientosindesglosar === 'undefined') return;
			this.$refs.movimientosindesglosar.initialize();
		},
	},
};
</script>