<template>
  <v-dialog
    v-model="show"
    max-width="1500px"
    persistent
  >
    <signalr path="/hub/inventario" @change="notificacionInventario"></signalr>
    <notifications group="inventario" position="bottom right" :duration="10000"/>
    <v-snackbar
      v-model="error"
      color="red"
      top
    >
      <ul>
        <li v-for="(error,idx) in errores" :key="'error-'+idx">{{error}}</li>
      </ul>
    </v-snackbar>
    <v-dialog v-model="dialogAlertaMP" persistent max-width="450px">
      <v-card>
        <v-card-text class="pb-0">
          <div class="text-center py-2 pt-4">
            <lottie
              id="errorAlertaMP"
              :options="failedAnimationOptions"
              :height="100"
              :width="100"
              :key="`error-${lottieKey}`"
              class="py-0"
            />
          </div>
          <p class="text-h5 text--primary text-center">Ha intentado asignar una materia prima que no forma parte de la receta.</p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="error"
            :disabled="saving"
            @click="save(true,true)"
            >Sí, autorizo<br>la salida</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn 
            text 
            color="success"
            :disabled="saving"
            :loading="saving"
            @click="cerrarDialogAlertaMP()"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAlertaMovimientoReciente" persistent max-width="600px">
      <v-card>
        <v-card-title>Alerta de Movimiento de Entrada reciente</v-card-title>
        <v-card-text class="pb-0">
          <div class="text-center">
            <lottie
              id="alertaMovimientoReciente"
              :options="attentionAnimationOptions"
              :height="200"
              :width="200"
              :key="`movreciente-${attentionAnimationKey}`"
              class="py-0"
            />
          </div>
          <p class="text-h6 text-justify text--primary font-weight-regular" style="font-size: 18px !important">
            Se está realizando una operación con un artículo al cual recientemente 
            se le hizo un movimiento de entrada de almacén. ¿Desea continuar con esta operación?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="error"
            :disabled="saving"
            @click="save(false,true)"
            >Continuar con<br>la operacion</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn 
            text 
            color="success"
            :disabled="saving"
            :loading="saving"
            @click="cerrarDialogAlertaMovimientoReciente()"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card-title>
        Nuevo Movimiento
      </v-card-title>
      <v-card-text v-if="state == 0">
        <v-row v-if="movimientoItem.idTipoMovimiento == 2">
          <v-col cols="4" md="4" sm="4">
            <v-select
              v-model="movimientoItem.idTipoMovimiento"
              label="Tipo de Movimiento"
              :items="tipoMovimientosMov"
              item-text="nombre"
              item-value="idTipoMovimiento"
              @change="resetOT; reset;"
            ></v-select>
          </v-col>
          <v-col cols="4" md="4" sm="4">
            <v-text-field
              v-model="movimientoItem.lote"
              label="[Opcional] Lote"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="4" md="4" sm="4">
            <v-autocomplete
              v-model="movimientoItem.idArticulo"
              label="Artículo"
              :items="articulos"
              item-value="idArticulo"
              :item-text="nombreArticulo"
              :loading="loadingArticulos"
              :disabled="loadingArticulos"
              @change="getArticuloTipo();setUnidad(movimientoItem.idArticulo)"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="movimientoItem.idTipoMovimiento != 2">
          <v-col cols="6" md="6" sm="6">
            <v-row>
              <v-col cols="12" md="12" sm="12" :class="movimientoItem.idTipoMovimiento != 2?'mb-0 pb-0':''">
                <v-select
                  v-model="movimientoItem.idTipoMovimiento"
                  label="Tipo de Movimiento"
                  :items="tipoMovimientosMov"
                  item-text="nombre"
                  item-value="idTipoMovimiento"
                  @change="resetOT"
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="(movimientoItem.idTipoMovimiento == 1 || movimientoItem.idTipoMovimiento == 6)">
              <v-col cols="12" md="12" sm="12" class="my-0 py-0">
                <v-autocomplete
                  label="Tipo de Salida"
                  v-model="movimientoItem.tipoSalida"
                  :items="tiposSalidas"
                  item-value="idTipoSalida"
                  item-text="nombre"
                  clearable
                  @change="changeTipoSalida()"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="((movimientoItem.idTipoMovimiento == 1 || movimientoItem.idTipoMovimiento == 6) && movimientoItem.tipoSalida == 1)">
               <v-col cols="12" md="12" sm="12" class="my-0 py-0">
                <v-autocomplete
                  v-model="movimientoItem.idTrabajoOrden"
                  label="Orden de Trabajo"
                  :items="trabajoOrdenes"
                  item-value="idTrabajoOrden"
                  item-text="nombre"
                  @change="($event) => {
                    movimientoItem.idsTrabajoOrdenDetalle = [];
                    setDetalles($event);
                  }"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row  v-if="((movimientoItem.idTipoMovimiento == 1 || movimientoItem.idTipoMovimiento == 6) && movimientoItem.tipoSalida == 1)">
              <v-col cols="12" md="12" sm="12" class="my-0 py-0">
                <v-autocomplete
                  v-model="movimientoItem.idsTrabajoOrdenDetalle"
                  label="Artículo a Producir"
                  :items="trabajoOrdenDetalles"
                  item-value="idTrabajoOrdenDetalle"
                  item-text="articulo"
                  multiple
                  chips
                  clearable
                  deletable-chips
                  @click:clear="movimientoItem.idsTrabajoOrdenDetalle = [];"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="((movimientoItem.idTipoMovimiento == 1 || movimientoItem.idTipoMovimiento == 6) && movimientoItem.tipoSalida == 1)">
              <v-col cols="12" md="12" sm="12" class="my-0 py-0">
                <v-autocomplete
                  v-model="movimientoItem.idMaquina"
                  label="Máquina"
                  :items="maquinas"
                  item-value="idMaquinaIns"
                  :item-text="maquinaLabel"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="((movimientoItem.idTipoMovimiento == 1 || movimientoItem.idTipoMovimiento == 6) && movimientoItem.tipoSalida == 1)">
              <v-col cols="12" md="12" sm="12" class="my-0 py-0">
                <v-autocomplete
                  v-model="movimientoItem.idOperador"
                  label="Operador (Opcional)"
                  :items="operadores"
                  item-value="idPersona"
                  :item-text="operadorLabel"
                  clearable
                  @click:clear="onClearOperador"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="((movimientoItem.idTipoMovimiento == 1 || movimientoItem.idTipoMovimiento == 6) && movimientoItem.tipoSalida == 3)">
              <v-col cols="12" md="12" sm="12" class="my-0 py-0">
                <v-autocomplete
                  v-model="movimientoItem.idOperador"
                  label="Recibió"
                  :items="operadores"
                  item-value="idPersona"
                  :item-text="operadorLabel"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="(movimientoItem.idTipoMovimiento == 1 && movimientoItem.tipoSalida == 5)">
              <v-col cols="12" md="12" sm="12" class="my-0 py-0">
                <v-autocomplete
                  v-model="movimientoItem.idPublicoVenta"
                  label="Orden de Venta"
                  :items="ordenesVentaPublico"
                  item-value="idPublicoVenta"
                  item-text="clave"
                  clearable
                  :error-messages="errorOrdenVenta"
                  @focus="errorOrdenVenta=''"
                  @change="limpiarArticulosTabla()"
                  :loading="loadPublicoVenta"
                  :disabled="loadPublicoVenta"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="(movimientoItem.idTipoMovimiento == 1 || movimientoItem.idTipoMovimiento == 6) && movimientoItem.tipoSalida == 2">
              <v-col cols="12" md="12" sm="12" class="my-0 py-0">
                <v-autocomplete
                  v-model="movimientoItem.idCliente"
                  label="Cliente"
                  :items="clientes"
                  item-value="idCliente"
                  item-text="nombre"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" md="6" sm="6"
            v-if="(movimientoItem.idTipoMovimiento == 1 || movimientoItem.idTipoMovimiento == 6) || movimientoItem.idTipoMovimiento == 4"
          >
            <v-row>
              <div>
              <qrcode-stream @decode="onDecode"></qrcode-stream>
              </div>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" sm="6">
                <v-text-field
                  v-model="codigo"
                  label="Código de Identificación"
                  v-if="(movimientoItem.idTipoMovimiento == 1 || movimientoItem.idTipoMovimiento == 6) || movimientoItem.idTipoMovimiento == 4"
                  hide-details
                  @keydown.enter="getCode"
                  @keyup="setCodeStyle"
                  maxlength="11"
                  :error="!validCode(codigo) && codigo.length >= 1 || (codigo.length >= 1 && codigo.length < 11) || codigo.length > 11"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-select
                  v-model="selectCode"
                  label="Lectura Remota"
                  :loading="loadingLecturasRemotas"
                  :items="lecturasRemotas"
                  item-value="codigo"
                  item-text="codigo"
                  @input="selectedCode"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>{{data.item.codigo}}</v-list-item-title>
                      <v-list-item-subtitle>Artículo: {{data.item.articulo}}</v-list-item-subtitle>
                      <v-list-item-subtitle>Almácen: {{data.item.almacen}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="movimientoItem.idTipoMovimiento == 2">
          <v-col cols="4" md="4" sm="4" class="pt-5">
            <VueCtkDateTimePicker
              v-model="movimientoItem.fechaRecepcion"
              label="Fecha de Recepción"
              format="YYYY-MM-DD HH:mm"
              formatted="YYYY-MM-DD HH:mm"
              noHeader
              overlay
              id="fecrec"
              buttonNowTranslation="Ahora"
            />
          </v-col>
          <v-col cols="4" md="4" sm="4">
            <v-autocomplete
              v-model="movimientoItem.idAlmacen"
              label="Almacén"
              :items="almacenes"
              :item-text="almacenText"
              item-value="idAlmacen"
              @change="changeAlmacen()"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4" md="4" sm="4">
            <v-autocomplete
              v-model="movimientoItem.idSubalmacen"
              label="[Opcional] Subalmacén"
              :items="subalmacenesComputed"
              :item-text="nombreSubalmacen"
              item-value="idSubalmacen"
              clearable
            ></v-autocomplete>
          </v-col>
          <!-- <v-col cols="6" md="6" sm="6">
            <VueCtkDateTimePicker
              v-model="movimientoItem.fechaIngreso"
              label="Fecha de Ingreso"
              format="YYYY-MM-DD HH:mm"
              formatted="YYYY-MM-DD HH:mm"
              noHeader
              overlay
              id="fecing"
              buttonNowTranslation="Ahora"
            />
          </v-col> -->
        </v-row>
        <v-row v-if="movimientoItem.idTipoMovimiento == 2">
          
          <v-col cols="6" md="6" sm="6">
            <v-autocomplete
              v-model="movimientoItem.idCalidadTipo"
              label="Tipo de Calidad"
              :items="calidadTipos"
               item-text="nombre"
              item-value="idCalidadTipo"
            ></v-autocomplete>
          </v-col>
          <v-col v-if="movimientoItem.idArticuloTipo == 3" cols="6" md="6" sm="6" class="my-0 py-0">
            <v-autocomplete
              v-model="movimientoItem.idTrabajoOrden"
              label="[opcional] Orden de Trabajo"
              :items="trabajoOrdenes"
              item-value="idTrabajoOrden"
              item-text="nombre"
              clearable
              @change="($event) => {
                movimientoItem.idTrabajoOrdenDetalle=-1;
                setDetalles($event);
              }"
              @click:clear="
                movimientoItem.idTrabajoOrden=-1;
                movimientoItem.idTrabajoOrdenDetalle=-1
              "
            ></v-autocomplete>
          </v-col>
          <v-col v-if="movimientoItem.idArticuloTipo == 3" cols="6" md="6" sm="6" class="my-0 py-0">
            <v-autocomplete
              v-model="movimientoItem.idTrabajoOrdenDetalle"
              label="[opcional] Artículo a Producir"
              :items="trabajoOrdenDetalles"
              item-value="idTrabajoOrdenDetalle"
              item-text="articulo"
              clearable
              @click:clear="movimientoItem.idTrabajoOrdenDetalle=-1"
            ></v-autocomplete>
          </v-col>
          <v-col v-if="movimientoItem.idTipoMovimiento == 2" cols="6" md="6" sm="6">
            <v-textarea  label="Descripción" auto-grow rows="1" v-model="movimientoItem.descripcion" counter="110" maxlength="110"></v-textarea>
          </v-col>
        </v-row>
        
        <div v-if="movimientoItem.idTipoMovimiento == 2">
          <div class="text-center">
            <h3>Proveedores</h3>
          </div>
          <br>
          <v-data-table
            :items="movimientoItem.proveedores"
            :headers="headersProveedores"
            hide-default-footer
            :items-per-page="-1"
          >
            <template v-slot:item.tipoProveedor="{ item }">
              <v-autocomplete
                v-model="item.idProveedorTipo"
                :items="proveedorTipos"
                :item-text="proveedorTipoLabel"
                item-value="idProveedorTipo"
                :class="(item.idProveedorTipo <= 0)?' v-input--is-focused':''"
                :error="(item.idProveedorTipo <= 0)"
                dense
              >
              </v-autocomplete>
            </template>
            <template v-slot:item.proveedor ="{ item }">
              <v-autocomplete
                v-model="item.idProveedor"
                :items="listaProveedores"
                :item-text="proveedorLabel"
                item-value="idProveedor"
                :class="(item.idProveedor <= 0)?' v-input--is-focused':''"
                :error="(item.idProveedor <= 0)"
                dense
              >
              </v-autocomplete>
            </template>
            <template v-slot:item.actions ="{ item }">
              <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
                small
                @click="deleteProveedor(item)"
              >
                mdi-delete
              </v-icon>
           </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
            </template>
          </v-data-table>
          <v-btn
            text
            color="primary"
            @click="newProveedor"
          >
            Agregar Proveedor
          </v-btn>
          <br>
          <br>
          <div class="text-center">
            <h3>Elementos</h3>
          </div>
          <br>
          <v-data-table
            :items="movimientoItem.elementos"
            :headers="headersElementos"
            hide-default-footer
            :items-per-page="-1"
          >
            <template v-slot:item.multiplicar="{ item }">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" md="12" sm="12" class="ma-0 pa-0">
                  <v-text-field
                    v-model="item.multiplicar"
                    type="number"
                    min="0"
                    dense
                    :error="validarCantidad(item.multiplicar,0.999999)"
                    :class="validarCantidad(item.multiplicar,0.999999)?' v-input--is-focused':''"
                  ></v-text-field>
                </v-col>
              </v-row>

            </template>
            <template v-slot:item.cantidad="{ item }">
              <v-row class="ma-0 pa-0">
                <v-col cols="8" md="8" sm="8" class="ma-0 pa-0">
                  <v-text-field
                    v-model="item.cantidad"
                    type="number"
                    min="0"
                    dense
                    :error="validarCantidad(item.cantidad)"
                    :class="validarCantidad(item.cantidad)?' v-input--is-focused':''"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="4" sm="4" class="ma-0 pa-0" style="align-self:center !important;">
                  <v-container class="ma-0 pa-0 text-center">
                    {{$utils.strings.pluralizar(unidad,item.cantidad)}}
                  </v-container>
                </v-col>
              </v-row>

            </template>
            <template v-slot:[`item.lote`]="{ item }">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" md="12" sm="12" class="ma-0 pa-0">
                  <v-text-field
                    v-model="item.lote"
                    dense
                    placeholder="[Opcional] Lote"
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
                small
                @click="deleteElemento(item)"
              >
                mdi-delete
              </v-icon>
               </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
            </template>
          </v-data-table>
          <v-btn
            text
            color="primary"
            @click="newElemento"
          >
            Agregar Elemento
          </v-btn>

          <div class="text-right">
            <div class="display-1">
              Total: {{totalElementos.toFixed(2)}} {{$utils.strings.pluralizar(unidad,totalElementos)}}
            </div>
          </div>
        </div>
        <v-row v-if="(movimientoItem.idTipoMovimiento == 1 || movimientoItem.idTipoMovimiento == 6)">
          <v-col cols="12" md="12" sm="12">
            <div class="text-center">
              <h3>Salidas</h3>
            </div>
            <br>
            <v-data-table
              :headers="
                movimientoItem.idTipoMovimiento == 1
                ? (movimientoItem.tipoSalida == 2 || movimientoItem.tipoSalida == 3)
                  ? headersSalidasCliente
                  : movimientoItem.tipoSalida == 5
                    ? headersVentasPublico
                    : headersSalidas
                : movimientoItem.idTipoMovimiento == 6
                  ? movimientoItem.tipoSalida == 2
                    ? headersSalidas
                    : headersSalidasCliente
                  : headersSalidas
              "
              :items="movimientoItem.salidas"
              :items-per-page="-1"
              hide-default-footer
            >
              <template v-slot:item.codigo="{ item }">
                {{item.codigo.match(/.{1,3}/g).join('-')}}
              </template>
              <template v-slot:item.cantidadRequerida="{ item }">
                <p class="mb-0">{{ `${item.cantidadRequerida.toFixed(2)} ${item.abreviatura}` }}</p>
              </template>
              <template v-slot:item.cantidad="{ item }">
                <v-text-field-number
                  v-model="item.cantidad"
                  :label="'Cantidad (Maxímo: ' +item.maximo.toFixed(2) + ' '+item.abreviatura+ ')' "
                  :max="item.maximo"
                  :errorStatus="erroresCantidades.includes(item.idArticulo)"
                  hide-details
                  dense
                  decimal
                  @focus="
                    erroresCantidades=erroresCantidades.filter(idArticulo => idArticulo != item.idArticulo)
                  "
                >
                </v-text-field-number>
              </template>
              <template v-slot:item.detalles="{ item }">
                <ul>
                  <li v-for="(proveedor,idx) in item.proveedores" :key="'proveedor-'+item.codigo+'-'+idx">
                    <b>{{proveedor.proveedorTipo}}:</b> {{proveedor.proveedor}}
                  </li>
                </ul>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
                  small
                  @click="deleteSalida(item)"
                >
                  delete
                </v-icon>
                 </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row v-if="movimientoItem.idTipoMovimiento == 4">
          <v-col cols="12" md="12" sm="12">
            <div class="text-center">
              <h3>Reintegros</h3>
            </div>
            <br>
            <v-data-table
              :items="movimientoItem.reintegros"
              :items-per-page="-1"
              :headers="headersReintegros"
              hide-default-footer
              :expanded.sync="expanded"
              item-key="idElemento"
              show-expand
            >
              <template v-slot:item.indice="{ index }">
                <p class="mb-0">{{index+1}}</p>
              </template>
              <template v-slot:item.cantidad="{ item, index }">
                <v-row>
                  <v-col cols="8" md="8" sm="8" class="ma-0">
                    <v-text-field
                      v-model="item.cantidad"
                      :label="'Maximo: '+ item.maximo"
                      type="number"
                      min="0"
                      dense
                      hide-details
                      :error="
                        validarCantidad(item.cantidad,0.0) || 
                        parseFloat(item.cantidad) > item.maximo || 
                        (expanded.indexOf(item)==-1&&validarCantidadMenor(item))
                      "
                      :class="
                        (validarCantidad(item.cantidad,0.0) || 
                        parseFloat(item.cantidad) > item.maximo || 
                        (expanded.indexOf(item)==-1&&validarCantidadMenor(item)))
                        ?' v-input--is-focused'
                        :''
                      "
                      @input="validarCantidadDesglose(index)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" md="4" sm="4" class="ma-0 pa-0 align-self-center">
                    {{$utils.strings.pluralizar(item.unidad,item.cantidad)}}
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom color="black" class="white--text text-center">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
                  small
                  @click="deleteReintegro(item)"
                >
                  delete
                </v-icon>
                 </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
              </template>
              <template v-slot:item.data-table-expand="{ item, isExpanded }">
                <v-tooltip v-if="item.trabajoOrdenes.ordenesTrabajoDetalle.length>0" bottom color="black" class="white--text text-center">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="desglosarReintegro(item, isExpanded)">
                      {{isExpanded?'mdi-close':'mdi-chevron-down'}}
                    </v-icon>
                  </template>
                  <span class="white--text">{{isExpanded?'Cancelar':'Desglosar'}}</span>
                </v-tooltip>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="px-4" style="background-color: white !important">
                  <h3 class="text--secondary text-center mb-1 mt-2">Desgloses</h3>
                  <v-data-table
                    :items="item.trabajoOrdenes.ordenesTrabajoDetalle"
                    :headers="headersOTD"
                    :items-per-page="-1"
                    hide-default-footer
                  >
                    <template v-slot:item.indice="{ index }">
                      <p class="mb-0">{{index+1}}</p>
                    </template>
                    <template v-slot:item.cantidad="{ item: itemDetalle }">
                      <v-text-field
                        v-model="itemDetalle.cantidad"
                        type="number"
                        class="centered-input px-10"
                        min="0"
                        dense
                        :error="itemDetalle.valido===false"
                        :class="itemDetalle.valido===false?'v-input--is-focused':''"
                        :suffix="$utils.strings.pluralizar(item.unidad,itemDetalle.cantidad)"
                        @input="validarCantidadDesglose(item)"
                      ></v-text-field>
                    </template>
                  </v-data-table>
                  <ul class="my-4">
                    <li 
                      v-for="mensaje,idx in item.msgDesgloses"
                      :key="idx"
                      class="red--text"
                    >
                      {{mensaje}}
                    </li>
                  </ul>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="state == 1" class="text-center">
        <lottie id="alertaSaved" :options="checkOptions" :height="200" :width="200"/>
          <h3>Movimiento Guardado Correctamente</h3>
          <a class="headline" target="__blank" :href="'/api/Inventario/DescargarQR/'+qrPdf+'?access_token='+token"><v-icon color="primary" large>mdi-download</v-icon> Descargar Códigos QR</a>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green" text @click="close(state)">{{state == 0?'Cancelar':'Cerrar'}}</v-btn>
        <v-btn v-if="state == 0" color="red darken-1" text @click="save()" :disabled="saving" :loading="saving">
          Guardar
          <template v-slot:loader>
            <v-progress-circular
              indeterminate
              :width="2"
              :size="24"
              color="red"
            ></v-progress-circular>
          </template>
        </v-btn>
      </v-card-actions>
      </v-card>
    </v-dialog>
</template>


<script>
import axios from "axios";
import Lottie from 'vue-lottie';
import * as checkData from '@/assets/check.json';
import * as attentionAnimation from '@/assets/attention.json';
import * as failedAnimation from '@/assets/failed.json';
import { QrcodeStream } from 'vue-qrcode-reader'
import {Howl, Howler} from 'howler';
import { mapState } from 'vuex';
import VTextFieldNumber from "@/components/VTextFieldNumber";
import signalr from "@/components/SignalR";

export default {
  components:{
    'lottie': Lottie,
    QrcodeStream,
    VTextFieldNumber,
    signalr
  },
  props:{
    show:{
      type:Boolean,
      default: false,
    }
  },
  data: () => ({
    subalmacenes: [],
    articulosTipos: [],
    attentionAnimationKey: 0,
    attentionAnimationOptions: {
      animationData: attentionAnimation.default,
      loop:false,
      speeed: 2
    },
    dialogAlertaMovimientoReciente: false,
    movimientoRecienteItem: {
      movimientoReciente: false,
      usuario: "",
      fecha: "",
      cantidad: ""
    },
    defaultMovimientoRecienteItem: {
      movimientoReciente: false,
      usuario: "",
      fecha: "",
      cantidad: ""
    },
    errorOrdenVenta: "",
    erroresCantidades: [],
    ordenesVentaPublico: [],
    loadPublicoVenta :false,
    msgDesgloses: [],
    desgloseIndex: -1,
    headersOTD: [
      {
        text: '#',
        value: 'indice',
        width: "5%",
        align: 'center',
        sortable: false,
      },
      {
        text: 'Articulo a Producir',
        value: 'articuloProducir',
        align: 'center',
        width: "70%",
        sortable: false,
      },
      {
        text: 'Cantidad',
        value: 'cantidad',
        align: 'center',
        width: "30%",
        sortable: false,
      },
    ],
    expanded: [],
    failedAnimationOptions: {
      animationData: failedAnimation.default,
      loop:false,
      speeed: 2
    },
    dialogAlertaMP: false,
    lottieKey: 0,
    codigo:'',
    state: 0,
    qrPdf:'',
    clientes:[],
    articulos:[],
    almacenes:[],
    calidadTipos:[],
    loadingArticulos:false,
    trabajoOrdenes:[],
    trabajoOrdenDetalles:[],
    errores:[],
    error: false,
    saving:false,
    tipoMovimientos:[
      {
        nombre:"Entrada",
        idTipoMovimiento:2
      },
      {
        nombre:"Salida",
        idTipoMovimiento:1
      },
      {
        nombre:"Salida Parcial",
        idTipoMovimiento:6
      },
      {
        nombre:"Reintegro",
        idTipoMovimiento:4
      },
      {
        nombre:"Excedente",
        idTipoMovimiento:3
      }
    ],
    unidad:'',
    movimientoItem:{
      idPublicoVenta: null,
      idArticulo:-1,
      idTipoMovimiento: -1,
      cantidad:0,
      idTrabajoOrden:-1,
      idTrabajoOrdenDetalle:-1,
      idsTrabajoOrdenDetalle: [],
      unidad:"",
      fechaRecepcion:null,
      fechaIngreso:null,
      idAlmacen: -1,
      idSubalmacen: null,
      idCalidadTipo: -1,
      proveedores:[],
      elementos:[],
      idMaquina:-1,
      idOperador:-1,
      salidas:[],
      reintegros:[],
      descripcion:"",
      tipoSalida:-1,
      idCliente: -1,
      lote: "",
      idArticuloTipo: -1
    },
    defaultItem:{
      idPublicoVenta: null,
      idArticulo:-1,
      idTipoMovimiento: -1,
      cantidad:0,
      idTrabajoOrden:-1,
      idTrabajoOrdenDetalle:-1,
      idsTrabajoOrdenDetalle: [],
      unidad:"",
      fechaRecepcion:null,
      fechaIngreso:null,
      idAlmacen: -1,
      proveedores:[],
      elementos:[],
      idMaquina:-1,
      idOperador:-1,
      salidas:[],
      reintegros:[],
      descripcion:"",
      tipoSalida:-1,
      idCliente: -1,
      lote: "",
      idArticuloTipo: -1
    },
    headersElementos:[
      {
        text: 'Número de Envases',
        value: 'multiplicar',
        align:'center',
        width:'30%',
        sortable: false,
      },
      {
        text: 'Volumen del Envase',
        value: 'cantidad',
        align:'center',
        width:'30%',
        sortable: false,
      },
      {
        text: '[Opcional] Lote',
        value: 'lote',
        align:'center',
        width:'30%',
        sortable: false,
      },
      {
        text: 'Acciones',
        value: 'actions',
        align:'center',
        width:'10%',
        sortable: false,
      },
    ],
    elementoDefault:{
      numElemento: -1,
      multiplicar: 1,
      cantidad: 1,
      lote: null
    },
    headersProveedores:[
      {
        text: 'Tipo de Proveedor',
        value: 'tipoProveedor',
        align: 'center',
        width: '40%',
        sortable: false,
      },
      {
        text: 'Proveedor',
        value: 'proveedor',
        align: 'center',
        width: '40%',
        sortable: false,
      },
      {
        text: 'Acciones',
        value: 'actions',
        align: 'center',
        width: '20%',
        sortable: false,
      }
    ],
    headersVentasPublico:[
      {
        text: 'Clave',
        value: 'codigo',
        align: 'center',
      },
      {
        text: 'Almacén',
        value: 'almacen',
        align: 'center',
      },
      {
        text: 'Subalmacén',
        value: 'subalmacen',
        align: 'center',
      },
      {
        text: 'Artículo',
        value: 'articulo',
        align: 'center',
      },
      {
        text: 'Detalles',
        value: 'detalles',
        align: 'center',
      },
      {
        text: 'Cantidad Requerida',
        value: 'cantidadRequerida',
        align: 'center',
      },
      {
        text: 'Cantidad',
        value: 'cantidad',
        align: 'center',
      },
      {
        text: 'Acciones',
        value: 'actions',
        align: 'center',
      }
    ],
    headersSalidasCliente:[
      {
        text: 'Clave',
        value: 'codigo',
        align: 'center',
      },
      {
        text: 'Almacén',
        value: 'almacen',
        align: 'center',
      },
      {
        text: 'Subalmacén',
        value: 'subalmacen',
        align: 'center',
      },
      {
        text: 'Cantidad',
        value: 'cantidad',
        align: 'center',
      },
      {
        text: 'Artículo',
        value: 'articulo',
        align: 'center',
      },
      {
        text: 'Detalles',
        value: 'detalles',
        align: 'center',
      },
      {
        text: 'Acciones',
        value: 'actions',
        align: 'center',
      }
    ],
    headersSalidas:[
      {
        text: 'Clave',
        value: 'codigo',
        align: 'center',
      },
      {
        text: 'Almacén',
        value: 'almacen',
        align: 'center',
      },
      {
        text: 'Subalmacén',
        value: 'subalmacen',
        align: 'center',
      },
      {
        text: 'Artículo',
        value: 'articulo',
        align: 'center',
      },
      {
        text: 'Detalles',
        value: 'detalles',
        align: 'center',
      },
      {
        text: 'Acciones',
        value: 'actions',
        align: 'center',
      }
    ],
    headersReintegros:[
      {
        text: '#',
        value: 'indice',
        align: 'center',
        width: "5%",
        sortable: false,
      },
      {
        text: 'Clave',
        value: 'codigo',
        align: 'center',
      },
      {
        text: 'Almacén',
        value: 'almacen',
        align: 'center',
      },
      {
        text: 'Subalmacén',
        value: 'subalmacen',
        align: 'center',
      },
      {
        text: 'Artículo',
        value: 'articulo',
        align: 'center',
      },
      {
        text: 'Cantidad',
        value: 'cantidad',
        align: 'center',
      },
      {
        text: 'Acciones',
        value: 'actions',
        align: 'center',
      },
      { 
        text: 'Desglosar',
        width: "5%",
        align: 'center',
        value: 'data-table-expand' 
      },
    ],
    proveedorTipos:[],
    listaProveedores:[],
    proveedorDefault:{
      idProveedorTipo:-1,
      idProveedor: -1,
    },
    checkOptions: {
      animationData: checkData.default,
      loop:false,
      speeed: 0.5
    },
    operadores:[],
    maquinas:[],
    soundBeep:null,
    soundError:null,
    lecturasRemotas:[],
    loadingLecturasRemotas: false,
    selectCode: null,
  }),
  created () {
    this.initialize()
  },
  computed:{
    subalmacenesComputed(){
      if(this.movimientoItem.idAlmacen == null) return [];
      return this.subalmacenes.filter( subalmacen => subalmacen.idAlmacen == this.movimientoItem.idAlmacen);
    },
    articulosOrdenVenta(){
      const articulos = [];
      this.ordenesVentaPublico.forEach( venta => {
        if(venta.idPublicoVenta == this.movimientoItem.idPublicoVenta){
          articulos.push(...venta.articulos);
        }
      });
      return articulos;
    },
    movimientoItemSalidas(){
      return this.movimientoItem.salidas;
    },
    tiposSalidas(){
      var tipos = [];

      tipos.push({
        idTipoSalida:1,
        nombre: "A Producción",
      },{
        idTipoSalida:2,
        nombre: "A Cliente",
      },{
        idTipoSalida:3,
        nombre: "A Mantenimiento",
      },{
          idTipoSalida:4,
          nombre: "A Proveedor",
        },{
          idTipoSalida:5,
          nombre: "Venta al Público",
        }
      );

      /* if(this.salidasProduccion || this.salidasReceta)tipos.push({
        idTipoSalida:1,
        nombre: "A Producción",
      });

      if(this.salidasReceta)tipos.push({
        idTipoSalida:2,
        nombre: "A Cliente",
      });

      if(this.salidasProduccion || this.salidasMantenimiento || this.salidasMantenimiento) tipos.push({
        idTipoSalida:3,
        nombre: "A Mantenimiento",
      });
      
      if(
        this.movimientoItem.salidas.length > 0 && 
        (this.movimientoItem.idTipoMovimiento == 1 || this.movimientoItem.idTipoMovimiento == 6)
      ){
        tipos.push({
          idTipoSalida:4,
          nombre: "A Proveedor",
        });
      }

      if(
        this.movimientoItem.idTipoMovimiento == 1
      ){
        tipos.push({
          idTipoSalida:5,
          nombre: "Venta al Público",
        });
      } */

      
      return tipos;
    },
    salidasMantenimiento(){
      if(this.movimientoItem.salidas.length == 0) return false;
      return (this.movimientoItem.salidas.filter(a => a.produccion == false && a.receta == false).length > 0);
    },
    salidasProduccion(){
      if(this.movimientoItem.salidas.length == 0) return false;
      return (this.movimientoItem.salidas.filter(a => a.produccion == true).length > 0);
    },
    salidasReceta(){
      if(this.movimientoItem.salidas.length == 0) return false;
      return (this.movimientoItem.salidas.filter(a => a.receta == true).length > 0);
    },
    tipoMovimientosMov(){
      return this.tipoMovimientos.filter(element => {
        return element.idTipoMovimiento != 3
      });
    },

    totalElementos(){
      if(this.movimientoItem.elementos.length == 0) return 0;
      var cantidad = 0.0;
      this.movimientoItem.elementos.forEach(element => {
        if(!isNaN(parseFloat(element.multiplicar)) && !isNaN(parseFloat(element.cantidad))){
          cantidad += parseFloat(element.multiplicar) * parseFloat(element.cantidad);
        }
      });
      return cantidad;
    },
    ...mapState({
      nombreEmpresa: 'nombreEmpresa',
      idUser: 'idUser',
      token: 'token'
    }),
  },
  watch:{
    show(){
      if(this.show){
        this.initialize();
      }
    },
    movimientoItemSalidas(){
      if(this.movimientoItem.tipoSalida == 5) return;
      if(this.movimientoItem.salidas.length == 0){
        this.movimientoItem.tipoSalida = -1;
      }
    }

  },
  methods:{
    nombreSubalmacen: ({ clave, subalmacen }) => `[${clave}] - ${subalmacen}`.trim(),
    nombreArticulo: (value) => value.codigo!=null?`[${value.codigo}] - ${value.nombre}`:`${value.nombre}`,
    changeAlmacen(){
      this.movimientoItem.idSubalmacen = null;
    },
    changeTipoSalida(){
      /* if(this.movimientoItem.tipoSalida == 5){
        this.erroresCantidades = [];
        this.movimientoItem.salidas = [];
        this.movimientoItem.idPublicoVenta = null;
        this.errorOrdenVenta = "Debe seleccionar una Orden de Venta";
      } */
    },
    limpiarArticulosTabla(){
      this.movimientoItem.salidas = [];
      this.erroresCantidades = [];
      if(this.movimientoItem.idPublicoVenta == null){
        this.errorOrdenVenta = "Debe seleccionar una Orden de Venta";
      }
    },
    validarCantidadDesglose(item = null){
      const desgloseIndex = (typeof item == 'number') ? item : this.movimientoItem.reintegros.indexOf(item);
      if(desgloseIndex == -1) return; //No se encontro el desglose
      if(this.expanded.indexOf(this.movimientoItem.reintegros[desgloseIndex]) == -1) return; //el reintegro no se ha desglosado 
      this.movimientoItem.reintegros[desgloseIndex].msgDesgloses = [];
      if(this.movimientoItem.reintegros[desgloseIndex].cantidad <= 0) {
        this.movimientoItem.reintegros[desgloseIndex].msgDesgloses.push(`Reintegro #${desgloseIndex+1}: Debe ingresar una cantidad valida`);
        return;
      }
      const sumValida = this.validarSumatoria(desgloseIndex);
      this.movimientoItem.reintegros[desgloseIndex].trabajoOrdenes.ordenesTrabajoDetalle.forEach( odt => {
        odt.valido = sumValida;
      });
    },
    validarSumatoria(desgloseIndex){
      let sumatoria = 0;
      try {
        this.movimientoItem.reintegros[desgloseIndex].trabajoOrdenes.ordenesTrabajoDetalle.forEach( (odt,idx) => {
          if(isNaN(parseFloat(odt.cantidad)) || parseFloat(odt.cantidad) < 0){
            odt.valido = false;
            this.movimientoItem.reintegros[desgloseIndex].msgDesgloses.push(`Reintegro #${desgloseIndex+1}: Ingrese una cantidad valida en el Desglose #${idx+1}`);
            throw 'BreakException';
          }
          else sumatoria += parseFloat(odt.cantidad);
        });
      } catch (error) {
        return false;
      }
      if((sumatoria+parseFloat(this.movimientoItem.reintegros[desgloseIndex].cantidad)) > this.movimientoItem.reintegros[desgloseIndex].maximo){
        this.movimientoItem.reintegros[desgloseIndex].msgDesgloses.push(`Reintegro #${desgloseIndex+1}: La sumatoria de las cantidades de los desgloses y la cantidad de reintegro no debe ser mayor a la cantidad maxima de ${this.movimientoItem.reintegros[desgloseIndex].maximo} ${this.$utils.strings.pluralizar(this.movimientoItem.reintegros[desgloseIndex].unidad,this.movimientoItem.reintegros[desgloseIndex].maximo,false)}`);
        return false;
      }
      if((sumatoria+parseFloat(this.movimientoItem.reintegros[desgloseIndex].cantidad)) < this.movimientoItem.reintegros[desgloseIndex].maximo){
        this.movimientoItem.reintegros[desgloseIndex].msgDesgloses.push(`Reintegro #${desgloseIndex+1}: La sumatoria de las cantidades de los desgloses y la cantidad de reintegro debe ser igual a la cantidad de ${this.movimientoItem.reintegros[desgloseIndex].maximo} ${this.$utils.strings.pluralizar(this.movimientoItem.reintegros[desgloseIndex].unidad,this.movimientoItem.reintegros[desgloseIndex].maximo,false)}`);
        return false;
      }
      return true;
    },
    handleExpansion(item, state){
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    desglosarReintegro(item, state){
      this.handleExpansion(item,state);
      if(!state){
        item.trabajoOrdenes.ordenesTrabajoDetalle.forEach( odt => {
          odt.cantidad = 0;
        });
        this.validarCantidadDesglose(item);
      }
      else{
        item.trabajoOrdenes.ordenesTrabajoDetalle.forEach( odt => {
          odt.cantidad = 0;
          odt.valido = null;
        });
        item.msgDesgloses = [];
      }
    },
    cerrarDialogAlertaMovimientoReciente(){
      this.dialogAlertaMovimientoReciente = false;
      this.movimientoRecienteItem = Object.assign({},this.defaultMovimientoRecienteItem);
    },
    cerrarDialogAlertaMP(){
      this.dialogAlertaMP = false;
    },
    getArticuloTipo(){
      this.movimientoItem.idTrabajoOrden = -1;
      this.movimientoItem.idTrabajoOrdenDetalle = -1;
      this.movimientoItem.idsTrabajoOrdenDetalle = [];
      const search =  this.articulos.find(articulo => articulo.idArticulo == this.movimientoItem.idArticulo);
      this.movimientoItem.idArticuloTipo = search != undefined ? search.idArticuloTipo : -1;
    },
    nombre(item){
      return  "["+item.codigo+"] - "+item.nombre;
    },
    proveedorLabel(item){
      return "["+item.codigo+"] - "+ item.nombre;
    },
    proveedorTipoLabel(item){
      return "["+item.codigo+"] - "+ item.nombre;
    },
    operadorLabel(item){
      return item.nombre +" "+ item.paterno +" "+ item.materno;
    },
    maquinaLabel(item){
      return "["+item.nombre +"] - "+item.marca + " " + item.modelo; 
    },
    onClearOperador(){
      this.movimientoItem.idOperador = -1;
    },
    initialize(){
      this.soundBeep = new Howl({
        src: ['/sounds/beep.webm','/sounds/beep.mp3']
      });
      this.soundError = new Howl({
        src: ['/sounds/error.webm','/sounds/error.mp3']
      });
      this.getArticuloTipos();
      this.getArticulos();
      this.getOTs();
      this.getProveedores();
      this.getProveedorTipos();
      this.getAlmacenes();
      this.getSubalmacenes();
      this.getOperadores();
      this.getMaquinas();
      this.getCalidadTipos();
      this.getClientes();
      this.getOrdenesVentaPublico();
      this.getLecturasRemotas();
    },
    getOrdenesVentaPublico(){
      this.loadPublicoVenta = true;
      axios
        .get('/Inventario/OrdenVentaPublico')
        .then( response => {
          this.ordenesVentaPublico = response.data.filter(venta => venta.clave != '');
          this.loadPublicoVenta = false;
        })
        .catch( error => {
          console.log(error);
          this.loadPublicoVenta = false;
        })
    },
    getClientes(){
      axios
      .get("/Clientes")
      .then(response => {
        this.clientes = response.data;
      })
      .catch(error => {
        console.log(error);
      });
    },
    getProveedores(){
      axios
      .get("/Proveedores")
      .then(response => {
        this.listaProveedores = response.data;
      })
      .catch(error => {
        console.log(error);
      });
    },
    getProveedorTipos(){
      axios
      .get("/ProveedorTipos")
      .then(response => {
        this.proveedorTipos = response.data;
      })
      .catch(error => {
        console.log(error);
      })
    },
    resetOT(){
      this.movimientoItem.idTrabajoOrden = -1;
      this.movimientoItem.idTrabajoOrdenDetalle = -1;
      this.movimientoItem.idsTrabajoOrdenDetalle = [];
    },
    async getArticuloTipos(){
      try {
        const response = await axios.get('/Articulos/ArticuloTipos');
        this.articulosTipos = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    getArticulos(){
      this.loadingArticulos = true;
      axios
        .get("/Articulos/ArticulosNombres")
        .then(response => {
          const articulos = [];
          this.articulosTipos
              .sort( (a,b) => a.idArticuloTipo - b.idArticuloTipo )
              .forEach( (artip,idx) => {
                if(idx > 0) articulos.push({divider: true});
                articulos.push({ header: artip.nombre });
                const articfiltrados = response.data
                  .filter( articulo => articulo.idArticuloTipo == artip.idArticuloTipo )
                  .map( articulo => ({
                    ...articulo,
                    group: artip.nombre
                  }));
                articulos.push( ...articfiltrados );
              });
          this.articulos = articulos;
          this.loadingArticulos = false;
        })
        .catch(error => {
          this.loadingArticulos = false;
          console.log(error);
        })
    },
    getOTs(){
      axios
        .get("TrabajoOrdenes/ListarOT?otsCerradas=true")
        .then(response => {
          this.trabajoOrdenes = response.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    getSubalmacenes() {
			axios
				.get("/Subalmacenes")
				.then((response) => {
					this.subalmacenes = response.data;
				})
				.catch(console.log);
		},
    getAlmacenes(){
      axios
      .get("/Almacenes")
      .then(response => {
        response.data.forEach(e => {
          if(e.idCliente == null){
            e.cliente = this.nombreEmpresa;
          }
        });
        this.almacenes = response.data;
      })
      .catch(error => {
        console.log(error);
      })
    },
    getCalidadTipos(){
      axios
      .get("/CalidadTipos")
      .then(response => {
        this.calidadTipos=response.data;
      })
      .catch(error => {
        console.log(error);
      })
    },
    getMaquinas(){
      axios
      .get("/MaquinaInstancias")
      .then(response =>  {
        this.maquinas = response.data;
      })
      .catch(error => {
        console.log(error);
      });
    },
    getOperadores(){
      axios
      .get("/Personas/Operadores")
      .then(response =>  {
        this.operadores = response.data;
      })
      .catch(error => {
        console.log(error);
      });
    },
    almacenText(item){
      return "["+item.nombre+"] - "+item.cliente; 
    },
    calidadTipoText(item){
      return item.nombre; 
    },
    setDetalles(idTrabajoOrden){
      var pos = this.trabajoOrdenes.findIndex(element => element.idTrabajoOrden == idTrabajoOrden);
      if(pos >= 0){
        var trabajoOrden = this.trabajoOrdenes[pos];
        this.trabajoOrdenDetalles = trabajoOrden.detalles;
        var idsAlmancenes = this.almacenes.filter(a => a.idCliente == null).map(a => a.idAlmacen);
        
        if(trabajoOrden.maquila){
          idsAlmancenes.push(trabajoOrden.idAlmacen);
        }

        var nElementosRemover = this.movimientoItem.salidas.filter(a => {
          return idsAlmancenes.indexOf(a.idAlmacen) == -1;
        }).length;

        if(nElementosRemover > 0){
          this.movimientoItem.salidas = this.movimientoItem.salidas.filter(a => {
            return idsAlmancenes.indexOf(a.idAlmacen) > 0;
          });
          this.soundError.play();
          this.$notify({
            group: 'inventario',
            title: 'Se ha'+(nElementosRemover > 1?'n':'')+' removido '+nElementosRemover+' elemento'+(nElementosRemover > 1?'s':'')+' que no se pueden usar para esta ODT',
            type: 'error',
          });
        }
      }
      else this.trabajoOrdenDetalles = [];
    },
    setUnidad(idArticulo){
      var pos = this.articulos.findIndex(a => a.idArticulo == idArticulo);
      if(pos >= 0){
        this.unidad = this.articulos[pos].unidad;
      }
      else{
        this.unidad = "";
      }
    },
    reset(){
      this.state = 0;
      this.codigo = '';
      this.movimientoItem = Object.assign({},this.defaultItem);
      this.movimientoItem.elementos = [];
      this.movimientoItem.proveedores = [];
      this.movimientoItem.salidas = [];
      this.movimientoItem.reintegros = [];
      this.unidad = "";
      this.errorOrdenVenta = "";
      this.erroresCantidades = [];
      this.expanded = [];
    },
    close(status = 0){
      this.reset();
      this.show = false;
      this.$emit('change',status);
    },
    parseToFloat(string){
      if(isNaN(parseFloat(string))) return 0.0;
      else return parseFloat(string);
    },
    validate(){
      this.errorOrdenVenta = "";
      this.erroresCantidades = []
      this.errores = [];
      if(this.movimientoItem.idTipoMovimiento <= 0){
        this.errores.push("Debe de seleccionar un Tipo de Movimiento");
      }
      else{
        if((this.movimientoItem.idTipoMovimiento == 1 || this.movimientoItem.idTipoMovimiento == 6)){
          if(this.movimientoItem.tipoSalida > 0){
            if(this.movimientoItem.tipoSalida == 1){
              if(this.movimientoItem.idTrabajoOrden <= 0){
                this.errores.push("Debe de Seleccionar una Orden de Trabajo");
              }
              if(this.movimientoItem.idsTrabajoOrdenDetalle.length == 0){
                this.errores.push("Debe de Seleccionar al menos un Artículo a Producir");
              }
              if(this.movimientoItem.idMaquina <= 0){
                this.errores.push("Debe de Seleccionar una Máquina a Producir");
              }
              if(this.movimientoItem.salidas.length == 0){
                this.errores.push("Debe Ingresar al menos un Elemento");
              }
              if(this.movimientoItem.idTipoMovimiento == 6){
                if(this.movimientoItem.salidas.length == 0){
                  this.errores.push("Debe Ingresar al menos un Elemento");
                }
                else{
                  this.movimientoItem.salidas.forEach((salida,idx) => {
                    if(salida.cantidad > salida.maximo){
                      this.errores.push("La cantidad del Elemento en el renglón "+(idx+1)+" es mayor de lo permitido");
                    }
                  })
                }
              }
            }

            else if(this.movimientoItem.tipoSalida == 2){
              if(this.movimientoItem.idCliente <= 0){
                this.errores.push("Debe de Seleccionar un Cliente");
              }
              if(this.movimientoItem.salidas.length == 0){
                this.errores.push("Debe Ingresar al menos un Elemento");
              }
              else{
                this.movimientoItem.salidas.forEach((salida,idx) => {
                  if(salida.cantidad > salida.maximo){
                    this.errores.push("La cantidad del Elemento en el renglón "+(idx+1)+" es mayor de lo permitido");
                  }
                })

              }
            }
            else if(this.movimientoItem.tipoSalida == 3){
              if(this.movimientoItem.idOperador <= 0){
                this.errores.push("Debe de Seleccionar Quien Recibió");
              }
              if(this.movimientoItem.salidas.length == 0){
                this.errores.push("Debe Ingresar al menos un Elemento");
              }
              else{
                this.movimientoItem.salidas.forEach((salida,idx) => {
                  if(salida.cantidad > salida.maximo){
                    this.errores.push("La cantidad del Elemento en el renglón "+(idx+1)+" es mayor de lo permitido");
                  }
                })
              }
            }
            else if( this.movimientoItem.tipoSalida == 5 ){
              if(this.movimientoItem.idPublicoVenta == null){
                this.errorOrdenVenta = "Debe seleccionar una Orden de Venta";
                this.errores.push(this.errorOrdenVenta);
              }

              if(this.movimientoItem.salidas.length == 0){
                this.errores.push("Debe Ingresar al menos un Elemento");
              }
              else{
                const articulosSalidasUnique = [];
                const uniqueArticulosSet = [...new Set(this.movimientoItem.salidas.map(salida => salida.idArticulo))]
                uniqueArticulosSet.forEach( salida => {
                  this.articulosOrdenVenta.forEach( articulo => {
                    if(articulo.idArticulo == salida){
                      articulosSalidasUnique.push({
                        idArticulo: salida,
                        cantidad: 0,
                        articulo: this.nombreArticulo(articulo)
                      });
                    }
                  });
                });
                
                // if(articulosSalidasUnique.length != this.articulosOrdenVenta.length){
                //   this.errores.push("Faltan Elementos para surtir la Orden de Venta");
                // }
                this.movimientoItem.salidas.forEach((salida,idx) => {
                  if(salida.cantidad > salida.maximo){
                    this.errores.push("La cantidad del Elemento en el renglón "+(idx+1)+" es mayor de lo permitido");
                  }
                  if(salida.cantidad <= 0){
                    this.errores.push("La cantidad del Elemento en el renglón "+(idx+1)+" es menor de lo permitido");
                  }

                  //Llenado de sumatorias
                  articulosSalidasUnique.forEach( articulo => {
                    if(salida.idArticulo == articulo.idArticulo){
                      articulo.cantidadRequerida = salida.cantidadRequerida;
                      articulo.abreviatura = salida.abreviatura;
                      articulo.cantidad += salida.cantidad;
                    }
                  })
                });

                //Validan sumatorias
                articulosSalidasUnique.forEach( articulo => {
                  if(articulo.cantidad > articulo.cantidadRequerida ){
                    this.erroresCantidades.push(articulo.idArticulo);
                    this.errores.push(`La sumatoria de cantidades del artículo "${articulo.articulo}" es mayor a la Cantidad Requerida de ${articulo.cantidadRequerida} ${this.$utils.strings.pluralizar(articulo.abreviatura,articulo.cantidadRequerida)}`);
                  }
                });
              }
            }
          }
          else{
            this.errores.push("Debe de Seleccionar un Tipo de Salida");
          }
          
        }
        if(this.movimientoItem.idTipoMovimiento == 2){
          if(this.movimientoItem.idArticulo <= 0){
            this.errores.push("Debe de seleccionar un Artículo");
          }

          if(this.movimientoItem.fechaRecepcion == null || this.movimientoItem.fechaRecepcion.length < 16){
            this.errores.push("Falta Ingresar la Fecha de Recepción");
          }
          /* if(this.movimientoItem.fechaIngreso == null || this.movimientoItem.fechaIngreso.length < 16){
            this.errores.push("Falta Ingresar la Fecha de Ingreso");
          } */

          if(this.movimientoItem.idAlmacen <= 0){
            this.errores.push("Falta Seleccionar un Almacén");
          }
          if(this.movimientoItem.idCalidadTipo <= 0){
            this.errores.push("Falta Seleccionar un Tipo de Calidad");
          }

          if(this.movimientoItem.idTrabajoOrden <= 0 && this.movimientoItem.idTrabajoOrdenDetalle > 0){
            this.errores.push("Debe de Seleccionar una Orden de Trabajo");
          }

          if(this.movimientoItem.idTrabajoOrden > 0 && this.movimientoItem.idTrabajoOrdenDetalle <= 0){
            this.errores.push("Debe de Seleccionar un Artículo a Producir");
          }

          if(this.movimientoItem.proveedores.length == 0){
            this.errores.push("Debe Ingresar al menos un Proveedor");
          }
          else{
            var x = 1;
            this.movimientoItem.proveedores.forEach(element => {
              if(element.idProveedorTipo <= 0 || element.idProveedor <= 0){
                this.errores.push("Falta seleccionar Tipo de Proveedor y/o Proveedor en la Fila "+x);
                x += 1;
              }
            });
          }

          if(this.movimientoItem.elementos.length == 0){
            this.errores.push("Debe Ingresar al menos un Elemento");
          }
          else{
            var x = 1;
            this.movimientoItem.elementos.forEach(element => {
              if(isNaN(parseFloat(element.cantidad))){
                this.errores.push("La Cantidad Ingresada en la Fila "+x+ " no es valida")
              }
              else if(parseFloat(element.cantidad) <= 0.0){
                this.errores.push("La Cantidad Ingresada en la Fila "+x+ " debe ser mayor a 0.0")
              }
            });
          }
        }
        if(this.movimientoItem.idTipoMovimiento == 4){
          if(this.movimientoItem.reintegros.length == 0){
            this.errores.push("Debe Ingresar al menos un Reintegro");
          }
          else{
            this.movimientoItem.reintegros.forEach((element,idx) => {
              if(this.expanded.indexOf(element) == -1){
                if(isNaN(parseFloat(element.cantidad))){
                  this.errores.push(`Reintegro #${idx+1}: Debe ingresar una cantidad valida`);
                }
                if(parseFloat(element.cantidad)>element.maximo){
                  this.errores.push(`Reintegro #${idx+1}: La Cantidad ingresada no debe ser mayor a la cantidad maxima`);
                }
                if(parseFloat(element.cantidad)<=0){
                  this.errores.push(`Reintegro #${idx+1}: La Cantidad ingresada no debe ser menor o igual a 0`);
                }
                else if(this.validarCantidadMenor(element)){
                  this.errores.push(`Reintegro #${idx+1}: Debe desglosar la cantidad restante`);
                }
              }
              if(element.msgDesgloses.length > 0) this.errores.push(...element.msgDesgloses);
            });
          }
        }
      }
      this.error = this.errores.length > 0; 
      return this.errores.length == 0;
    },
    async save(confirm=false,confirmedReciente=false){
      if(this.validate()){
        this.saving = true;
        
        var obj = {
          idPublicoVenta: null,
          IdArticulo: this.movimientoItem.idArticulo,
          TipoMovimiento: this.movimientoItem.idTipoMovimiento,
          IdTrabajoOrden: null,
          IdTrabajoOrdenDetalle: null,
          IdsTrabajoOrdenDetalle: null,
          IdCalidadTipo:null,
          Proveedores: null,
          Elementos: null,
          FechaRecepcion:null,
          FechaIngreso: null,
          Salidas:null,
          IdMaquina: null,
          IdOperador: null,
          Reintegros:[],
          lote: null,
          confirmacion: confirm,
        };

        if((this.movimientoItem.idTipoMovimiento == 1 || this.movimientoItem.idTipoMovimiento == 6)){
          obj.TipoSalida = this.movimientoItem.tipoSalida;
          if(this.movimientoItem.tipoSalida == 1){
            obj.IdTrabajoOrden = this.movimientoItem.idTrabajoOrden==-1?null:this.movimientoItem.idTrabajoOrden;
            obj.IdsTrabajoOrdenDetalle = this.movimientoItem.idsTrabajoOrdenDetalle.length==0?null:this.movimientoItem.idsTrabajoOrdenDetalle;
            obj.IdMaquina = this.movimientoItem.idMaquina;
            obj.IdOperador = (this.movimientoItem.idOperador <= 0)?null:this.movimientoItem.idOperador;
            obj.Salidas = this.movimientoItem.idTipoMovimiento == 1 
              ? this.movimientoItem.salidas.map(e => ({
                  IdElemento: e.idElemento,
                  Cantidad: e.maximo
                }))
              : this.movimientoItem.salidas.map(e => ({
                  IdElemento: e.idElemento,
                  Cantidad: e.cantidad
                }))
          }
          else if(this.movimientoItem.tipoSalida == 2){
            obj.IdCliente = this.movimientoItem.idCliente;
            obj.Salidas = this.movimientoItem.salidas.map(e => {
              return {
                IdElemento: e.idElemento,
                Cantidad: e.cantidad,
              }
            });
          }
          else if(this.movimientoItem.tipoSalida == 3){
            obj.IdOperador = (this.movimientoItem.idOperador <= 0)?null:this.movimientoItem.idOperador;
            obj.Salidas = this.movimientoItem.salidas.map(e => {
              return {
                IdElemento: e.idElemento,
                Cantidad: e.cantidad,
              }
            });
          }else if(this.movimientoItem.tipoSalida == 4){
            obj.Salidas = this.movimientoItem.idTipoMovimiento == 1 
              ? this.movimientoItem.salidas.map(e => ({
                  IdElemento: e.idElemento,
                  Cantidad: e.maximo
                }))
              : this.movimientoItem.salidas.map(e => ({
                  IdElemento: e.idElemento,
                  Cantidad: e.cantidad
                }))
          }
          else if(this.movimientoItem.tipoSalida == 5){
            obj.idPublicoVenta = this.movimientoItem.idPublicoVenta;
            obj.Salidas = this.movimientoItem.salidas.map(e => {
              return {
                IdElemento: e.idElemento,
                Cantidad: e.cantidad,
              }
            });
          }
          
        };

        //Movimiento de Entrada
        if(this.movimientoItem.idTipoMovimiento == 2){

          //Verifica si es un movimiento reciente
          if(!confirmedReciente){
            await this.checkMovimientoReciente(this.movimientoItem);

            if(this.movimientoRecienteItem.movimientoReciente){
              this.saving = false;
              this.dialogAlertaMovimientoReciente = true;
              this.attentionAnimationKey++;
              return;
            }
          }
          
          obj.lote = this.movimientoItem.lote;
          obj.FechaRecepcion = this.movimientoItem.fechaRecepcion;
          /* obj.FechaIngreso = this.movimientoItem.fechaIngreso; */
          obj.FechaIngreso = this.movimientoItem.fechaRecepcion;
          obj.IdAlmacen = this.movimientoItem.idAlmacen;
          obj.idSubalmacen = this.movimientoItem.idSubalmacen;
          obj.IdCalidadTipo = this.movimientoItem.idCalidadTipo;
          obj.IdTrabajoOrden = this.movimientoItem.idTrabajoOrden==-1?null:this.movimientoItem.idTrabajoOrden;
          obj.IdTrabajoOrdenDetalle = this.movimientoItem.idTrabajoOrdenDetalle==-1?null:this.movimientoItem.idTrabajoOrdenDetalle;
          obj.Descripcion = this.movimientoItem.descripcion;
          obj.Proveedores = this.movimientoItem.proveedores.map(element => {
            return {
              IdProveedorTipo: element.idProveedorTipo,
              IdProveedor: element.idProveedor 
            }
          });
          obj.Elementos = [];
          this.movimientoItem.elementos.forEach(element => {
            const lote = element.lote || this.movimientoItem.lote || null;
            if(parseInt(element.multiplicar) == 1){
              obj.Elementos.push({
                cantidad: parseFloat(element.cantidad),
                numLote: lote
              });
            }
            else if(parseInt(element.multiplicar) > 1){
                obj.Elementos = obj.Elementos.concat(Array(parseInt(element.multiplicar)).fill({
                cantidad: parseFloat(element.cantidad),
                numLote: lote
              }));
            }
          });
        }

        if(this.movimientoItem.idTipoMovimiento == 4){
          obj.Reintegros = this.movimientoItem.reintegros.map(e =>({
            IdElemento: e.idElemento,
            Cantidad: parseFloat(e.cantidad),
            ots: e.trabajoOrdenes.ordenesTrabajoDetalle.map(odt => ({
                ...odt,
                cantidad: parseFloat(odt.cantidad),
                idArticulo: e.idArticulo
              })),
            idMovimientoPublicoVenta: e.idMovimientoPublicoVenta
          }));
        }

        axios
          .post("/Inventario/CrearMovimiento",obj)
          .then(response => {
            this.cerrarDialogAlertaMovimientoReciente();
            this.cerrarDialogAlertaMP();
            this.saving = false;
            if(this.movimientoItem.idTipoMovimiento == 2 || this.movimientoItem.idTipoMovimiento == 6){
              this.qrPdf = response.data;
              this.state = 1; 
            }
            else{
              this.close(1);
            }          
          })
          .catch(error => {
            this.saving = false;
            if(error.response){
              if(error.response.status==409){
                this.dialogAlertaMP = true;
                this.lottieKey++;
              }
            }
            console.log(error);
          })
      }
    },
    checkMovimientoReciente({idAlmacen, idArticulo}){
      return new Promise( async(resolve) => {
        try{
          const response = await axios.get(`/Inventario/MovimientoRecienteAlmancen`,{
            params: {
              idAlmacen,
              idArticulo
            }
          });
          this.movimientoRecienteItem = response.data;
        }
        catch(error){
          console.log(error);
        }
        finally{
          resolve();
        }
      });
    },
    newElemento(){
      this.movimientoItem.elementos.push(Object.assign({},this.elementoDefault));
      var x = 1;
      this.movimientoItem.elementos.forEach(element => {
        element.numElemento = x;
        x += 1;
      });
    },
    deleteElemento(item){
      var x = 1;
      this.movimientoItem.elementos = this.movimientoItem.elementos.filter(element => {
        return element.numElemento !=  item.numElemento;
      });
      this.movimientoItem.elementos.forEach(element => {
        element.numElemento = x;
        x += 1; 
      });
    },
    newProveedor(){
      this.movimientoItem.proveedores.push(Object.assign({},this.proveedorDefault));
    },
    deleteProveedor(item){
      var pos = this.movimientoItem.proveedores.indexOf(item);
      if (pos > -1) {
        this.movimientoItem.proveedores.splice(pos, 1);
      }
    },
    deleteReintegro(item){
      var pos = this.movimientoItem.reintegros.indexOf(item);
      if(pos > -1){
        this.movimientoItem.reintegros.splice(pos,1);
        const indexExpanded = this.expanded.indexOf(item);
        if(indexExpanded > -1) this.expanded.splice(indexExpanded, 1);
      }
    },
    validarCantidadMenor(item){
      if(item.idMovimientoPublicoVenta==null){
        return parseFloat(item.cantidad) < item.maximo;
      }

      return false;
    },
    validarCantidad(cantidad, minimo = 0.0){
      if(isNaN(parseFloat(cantidad))) return true;

      if(parseFloat(cantidad) <= minimo) return true;

      return false;
    },
    deleteSalida(item){
      var pos = this.movimientoItem.salidas.indexOf(item);
      if (pos > -1) {
        this.movimientoItem.salidas.splice(pos, 1);
      }
    },
    onDecode(code){
      if((this.movimientoItem.idTipoMovimiento == 1 || this.movimientoItem.idTipoMovimiento == 6) || this.movimientoItem.idTipoMovimiento == 4){
        if(this.movimientoItem.salidas.find(s => s.codigo == code) != null || this.movimientoItem.reintegros.find(s => s.codigo == code) != null){
          this.soundError.play();
          this.$notify({
            group: 'inventario',
            title: 'Se ha seleccionado un Elemento con el Código '+code.match(/.{1,3}/g).join('-'),
            type: 'error',
          });
          return;
        }
        if(this.movimientoItem.idTipoMovimiento == 1 && this.movimientoItem.tipoSalida == 5){
          if(this.movimientoItem.idPublicoVenta == null){
            this.errorOrdenVenta = "Debe seleccionar una Orden de Venta";
            this.soundError.play();
            this.$notify({
              group: 'inventario',
              title: 'Debe seleccionar una Orden de Venta',
              type: 'error',
            });
            return;
          }
        }
      }
      axios
      .get("/Inventario/InformacionBasica?Codigo="+code+"&tipoMovimiento="+this.movimientoItem.idTipoMovimiento)
      .then(response => {
        if((this.movimientoItem.idTipoMovimiento == 1 || this.movimientoItem.idTipoMovimiento == 6)){
          if(response.data.enUso){
            this.soundError.play();
            this.$notify({
              group: 'inventario',
              title: 'No se puede agregar el Elemento con el Código '+code.match(/.{1,3}/g).join('-')+" se encuentra en uso en otra ODT",
              type: 'error',
            });
            return;
          }

          let indexArticuloOrdenVenta = -1;

          if(this.movimientoItem.idTipoMovimiento == 1 && this.movimientoItem.tipoSalida == 5){
            indexArticuloOrdenVenta = this.articulosOrdenVenta
                                          .map(venta => venta.idArticulo)
                                          .indexOf(response.data.idArticulo);
            if(indexArticuloOrdenVenta == -1){
              this.soundError.play();
              this.$notify({
                group: 'inventario',
                title: 'No se puede agregar el Elemento porque no coincide con ningun artículo de la Orden de Venta seleccionada',
                type: 'error',
              });
              return;
            }
            else if( this.articulosOrdenVenta[indexArticuloOrdenVenta].cantidadRestante == 0 ){
              this.soundError.play();
              this.$notify({
                group: 'inventario',
                title: 'No se puede agregar el Elemento porque ya se surtió la cantidad requerida del artículo',
                type: 'error',
              });
              return;
            }
            
          }

          if(response.data.maximo <= 0){
            this.soundError.play();
            this.$notify({
              group: 'inventario',
              title: 'La existencia de este Elemento es 0.0 '+response.data.abreviatura,
              type: 'error',
            });
            return;
          }
          
          var idsAlmancenes = this.almacenes.filter(a => a.idCliente == null).map(a => a.idAlmacen);
          var pos = this.trabajoOrdenes.findIndex(element => element.idTrabajoOrden == this.movimientoItem.idTrabajoOrden);
          if(pos >= 0 && this.trabajoOrdenes[pos].maquila){
            idsAlmancenes.push(this.trabajoOrdenes[pos].idAlmacen);
          }
          if(idsAlmancenes.indexOf(response.data.idAlmacen) >= 0){
            response.data.cantidad = 0;
            if( indexArticuloOrdenVenta > -1 ){
              response.data.cantidadRequerida = this.articulosOrdenVenta[indexArticuloOrdenVenta].cantidadRestante;
            }
            this.movimientoItem.salidas.push(response.data);
            this.soundBeep.play();
          }
          else{
            this.soundError.play();
            this.$notify({
              group: 'inventario',
              title: 'No se puede agregar el Elemento con el Código '+code.match(/.{1,3}/g).join('-'),
              type: 'error',
            });
          }
        }
        if(this.movimientoItem.idTipoMovimiento == 4){
          if(!response.data.enUso){
            this.soundError.play();
            this.$notify({
              group: 'inventario',
              title: 'No se puede agregar el Elemento con el Código '+code.match(/.{1,3}/g).join('-')+" se encuentra en uso en otra ODT",
              type: 'error',
            });
            return;
          }
          else{
            response.data.cantidad = 0.0;
            response.data.msgDesgloses = [];
            response.data.trabajoOrdenes.ordenesTrabajoDetalle = response.data.trabajoOrdenes.ordenesTrabajoDetalle != null
              ? response.data.trabajoOrdenes.ordenesTrabajoDetalle.map( odt => ({
                  ...odt,
                  idTrabajoOrden: response.data.trabajoOrdenes.idTrabajoOrden,
                  cantidad: 0
                }))
              : [];
            this.movimientoItem.reintegros.push(response.data);
            this.soundBeep.play();
          }
        }
      })
      .catch(error => {
        this.soundError.play();
        this.$notify({
          group: 'inventario',
          title: 'No Existe un Elemento con el Código '+code.match(/.{1,3}/g).join('-'),
          type: 'error',
        });
      });
    },
    setCodeStyle(){
      if(this.codigo.length >= 1){
        try{
          this.codigo = this.codigo.toUpperCase().replace(/-/g, '').match(/.{1,3}/g).join('-');
        }
        catch{
          this.codigo = '';
        }
      }
    },
    getCode(){
      var codigo = this.codigo.toUpperCase().replace(/-/g, '');
      if(codigo.length == 9 && this.onlyNumberAndLetters(codigo)){
        this.onDecode(codigo);
        this.codigo = "";
      }
    },
    validCode(str){
      return this.onlyNumberAndLetters(str.replace(/-/g, ''));
    },
    onlyNumberAndLetters(str) {
      return str.match("^[A-Z0-9-]+$");
    },
    notificacionInventario(event) {
      var orden = event.toLowerCase().split('-');
      if(orden[0] == 'lecturaremota'){
        if(this.idUser == parseInt(orden[1])){
          this.getLecturasRemotas();
        }
      }
    },
    getLecturasRemotas(){
      this.loadingLecturasRemotas = true;
      axios
      .get("/Inventario/ListarQRRemotos")
      .then(response => {
        this.lecturasRemotas = response.data;
        this.loadingLecturasRemotas = false;
      })
      .catch(error => {
        this.loadingLecturasRemotas = false;
        console.log(error);
      })
    },
    selectedCode(event){
      this.onDecode(event);
      setTimeout(() => {
        this.selectCode = null;
      },200);
    }
  }
}
</script>
<style scoped>
  .centered-input >>> input {
    text-align: center
  }
</style>