<template>
	<v-col cols="12" v-if="permisoVista('articulos2','r')">
		<v-row class="mb-4">
			<v-col cols="6" class="pt-0">
				<v-select
					v-model="filtros.producir"
					:items="[
						{ value: true, name: 'Activada' },
						{ value: false, name: 'Desactivada' },
					]"
					item-text="name"
					item-value="value"
					label="Producción"
					clearable
					hide-details
					@change="getEnsambles()"
				></v-select>
			</v-col>
			<v-col cols="6" class="pt-0">
				<v-autocomplete
					v-model="filtros.idArticulo"
					:items="articulosLista"
					:item-text="nombreArticulo"
					item-value="idArticulo"
					label="Artículo"
					clearable
					hide-details
					@change="getEnsambles()"
				></v-autocomplete>
			</v-col>
		</v-row>
		<v-dialog v-model="dialogProducir" persistent max-width="40%">
			<v-card>
				<v-card-text>
					<p class="pt-4 text-h5 font-weight-medium">
						¿Está seguro de
						{{ itemProducir.producir ? "desactivar" : "activar" }}
						la producción del siguiente ensamble?
					</p>
					<p style="font-size: 16px !important">
						•
						<span
							v-if="itemProducir.codigo != null"
							style="font-size: 16px !important"
							class="font-weight-bold"
							>[{{ itemProducir.codigo }}] -
						</span>
                        {{ itemProducir.nombre }}
					</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="success"
						text
						:disabled="loadingProducir"
						@click="cerrarDialogProducir()"
						>Cancelar</v-btn
					>
					<v-btn
						color="error"
						text
						:disabled="loadingProducir"
						:loading="loadingProducir"
						@click="producirEnsamble()"
						>Aceptar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-data-table :items="ensambles" :headers="headers" :loading="loading">
			<template v-slot:item.producir="{ item }">
				<v-chip 
                    :color="item.producir ? 'green' : 'red'" 
                    outlined 
                    class="font-weight-bold"
                    >
					{{ item.producir ? "Activada" : "Desactivada" }}
				</v-chip>
			</template>
			<template v-slot:item.actions="{ item }">
				<v-tooltip
					
					bottom
					color="black"
					class="white--text"
					v-if="!item.producir && permisoVista('articulos2','u')"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							class="mr-1"
							@click="producirItem(item)"
						>
							mdi-play
						</v-icon>
					</template>
					<span class="white--text">Activar Producción</span>
				</v-tooltip>
				<v-tooltip
					
					bottom
					color="black"
					class="white--text"
					v-if="item.producir && permisoVista('articulos2','u')"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							class="mr-1"
							@click="producirItem(item)"
						>
							mdi-stop
						</v-icon>
					</template>
					<span class="white--text">Desactivar Producción</span>
				</v-tooltip>
			</template>
		</v-data-table>
	</v-col>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			loadingProducir: false,
			itemProducir: {
				idArticulo: 0,
				codigo: "",
				nombre: "",
				producir: false,
			},
			defaultItemProducir: {
				idArticulo: 0,
				codigo: "",
				nombre: "",
				producir: false,
			},
			articulos: [],
			articulosLista: [],
			loading: false,
			filtros: {
				idArticulo: null,
				producir: null,
			},
			ensambles: [],
			headers: [
				{
					text: "Código",
					align: "left",
					sortable: true,
					value: "codigo",
				},
				{
					text: "Nombre",
					align: "left",
					sortable: true,
					value: "nombre",
				},
				{
					text: "Producción",
					align: "center",
					sortable: true,
					value: "producir",
				},
				{
					text: "Acciones",
					value: "actions",
					sortable: false,
					width: "10%",
					align: "center",
				},
			],
		};
	},
	mounted() {
		this.initialize();
	},
	methods: {
		nombreArticulo: (articulo) =>
			articulo.codigo != null
				? `[${articulo.codigo}] ${articulo.nombre}`
				: `${articulo.nombre}`,
		producirItem(item, value) {
			this.itemProducir = Object.assign({}, item);
			this.dialogProducir = true;
		},
		initialize() {
			this.getEnsambles();
            this.getArticulosLista();
		},
		getEnsambles() {
			this.loading = true;
			axios
				.get("/Inventario/GetArticulosProducir", {
					params: {
						IdArticulo: this.filtros.idArticulo,
						Producir: this.filtros.producir,
					},
				})
				.then((response) => {
					this.loading = false;
					this.ensambles = response.data;
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
        getArticulosLista(){
            axios
				.get("/Inventario/GetArticulosProducir")
				.then((response) => {
					this.articulosLista = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
        },
		producirEnsamble() {
			this.loadingProducir = true;
			axios
				.put("/Inventario/EstadoArticuloProducir", {
					idArticulo: this.itemProducir.idArticulo,
					producir: !this.itemProducir.producir,
				})
				.then(() => {
					this.loadingProducir = false;
                    this.getEnsambles();
					this.cerrarDialogProducir();
				})
				.catch((error) => {
					this.loadingProducir = false;
					console.log(error);
				});
		},
		cerrarDialogProducir() {
			this.itemProducir = Object.assign({}, this.defaultItemProducir);
			this.dialogProducir = false;
		},
	},
};
</script>

<style>
</style>