<template>
	<v-col cols="12">
		<signalr path="/hub/paros" @change="cambios"></signalr>
		<v-dialog v-model="dialogReporteExt" persistent max-width="80%">
			<v-card>
				<v-card-title>Reporte de Incidencia Extemporaneo</v-card-title>
				<v-card-text>
					<v-expansion-panels v-model="panel" class="pb-4">
      					<v-expansion-panel>
        					<v-expansion-panel-header v-slot="{ open }">
								<v-row no-gutters>
									<v-col cols="6">
										<p class="mb-0">
											{{ 
												JSON.stringify(reporteItem.operacion) == '{}'
												? 'Seleccione una Operación'
												: '1 Operación seleccionada'
											}}
										</p>
									</v-col>
									<v-col
									cols="12"
									class="text--secondary"
									:class="
											JSON.stringify(reporteItem.operacion) != '{}'
											&& 'pt-3'
									"
									>
										<v-fade-transition leave-absolute>
											<v-row
												v-if="
													!open && 
													JSON.stringify(reporteItem.operacion) != '{}'
												"
												no-gutters
												style="width: 100%"
											>
												<v-col cols="3" class="py-2">
													<span class="font-weight-bold">• Orden De Trabajo:</span> {{ reporteItem.operacion.trabajoOrden }}
												</v-col>
												<v-col cols="3" class="py-2">
													<span class="font-weight-bold">• Artículo Producir:</span><br/> 
													 {{reporteItem.operacion.cantidadProducir.toFixed(2)}} 
													 {{reporteItem.operacion.unidadProducir}} - 
													 {{reporteItem.operacion.articuloProducir}}
												</v-col>
												<v-col cols="3" class="py-2">
													<span class="font-weight-bold">• Área:</span> {{ reporteItem.operacion.area }}
												</v-col>
												<v-col cols="3" class="py-2">
													<span class="font-weight-bold">• Componente:</span> {{ reporteItem.operacion.articulo }}
												</v-col>
												<v-col cols="3" class="py-2">
													<span class="font-weight-bold">• Cantidad:</span> 
													{{ reporteItem.operacion.cantidad.toFixed(2) }} 
													{{reporteItem.operacion.unidad}}
												</v-col>
												<v-col cols="3" class="py-2">
													<span class="font-weight-bold">• Inicio:</span> {{ reporteItem.operacion.inicio.replace('T',' ') }}
												</v-col>
												<v-col cols="3" class="py-2">
													<span class="font-weight-bold">• Fin:</span> {{ reporteItem.operacion.fin.replace('T',' ') }}
												</v-col>
												<v-col cols="3" class="py-2">
													<span class="font-weight-bold">• Operadores:</span>
													<ul>
														<li 
															v-for="(operador,idx) in reporteItem.operacion.operadores" 
															:key="'op-'+idx"
															>{{operador}}</li
														>
													</ul>
												</v-col>
												<v-col cols="3" class="py-2">
													<span class="font-weight-bold">• Máquinas:</span>
													<span v-if="reporteItem.operacion.maquinas.length==0">
														N/A
													</span>
													<ul>
														<li 
															v-for="(maquina,idx) in reporteItem.operacion.maquinas" 
															:key="'m-'+idx"
															>{{maquina.nombre}}</li
														>
													</ul>
												</v-col>
											</v-row>
										</v-fade-transition>
									</v-col>
								</v-row>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<Operaciones
									ref="repOpsExt"
									:modeSelect="true"
									:key="chkey"
									:operaciones_items_per_page="5"
									@change="rowOperacionesSelected"
								/>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
					<v-row class="my-0 py-0 pl-4">
						<v-col cols="4" md="4" sm="4" class="py-0">
							<v-autocomplete
								v-model="reporteItem.idParoTipo"
								:items="tipoParos"
								item-value="idParoTipo"
								item-text="nombre"
								label="Motivo de la Incidencia"
								hide-details
								clearable
								:disabled="
									JSON.stringify(reporteItem.operacion) == '{}'
								"
								@change="changeTipoParo"
							></v-autocomplete>
						</v-col>
						<v-col cols="4" md="4" sm="4" class="py-0">
							<v-autocomplete
								v-model="reporteItem.idSubParoTipo"
								:items="reporteTipoSubparos()"
								item-value="idSubParoTipo"
								item-text="nombreSubParoTipo"
								label="Submotivo de la Incidencia (opcional)"
								hide-details
								clearable
								:disabled="
									JSON.stringify(reporteItem.operacion) == '{}'
								"
							></v-autocomplete>
						</v-col>
						<v-col cols="4" md="4" sm="4" class="py-0">
							<v-autocomplete
								v-model="reporteItem.idMaquinaIns"
								label="Máquina (opcional)"
								:items="instanciasFiltradas"
								:item-text="marcaModelo"
								item-value="idMaquinaIns"
								:disabled="
									JSON.stringify(reporteItem.operacion) == '{}'
								"
								clearable
							>
								<template v-slot:item="data">
									<v-list-item-content>
										<v-list-item-title>{{
											data.item.marca +
											" - " +
											data.item.modelo
										}}</v-list-item-title>
										<v-list-item-subtitle>{{
											data.item.nombre
										}}</v-list-item-subtitle>
										<v-list-item-content>{{
											$utils.strings.truncate(
												data.item.descripcionGeneral,
												50
											)
										}}</v-list-item-content>
									</v-list-item-content>
								</template>
							</v-autocomplete>
						</v-col>
						<v-col cols="4" md="4" sm="4" class="py-0 pt-4">
							<VueCtkDateTimePicker
								v-model="reporteItem.inicio"
								:min-date="reporteItem.operacion.inicio"
								:max-date="reporteItem.operacion.fin"
								label="Inicio"
								format="YYYY-MM-DD HH:mm"
								formatted="YYYY-MM-DD HH:mm"
								noHeader
								overlay
								:key="chkey1"
								buttonNowTranslation="Ahora"
								:disabled="
									JSON.stringify(reporteItem.operacion) == '{}'
								"
							/>
						</v-col>
						<v-col cols="4" md="4" sm="4" class="py-0 pt-4">
							<VueCtkDateTimePicker
								v-model="reporteItem.fin"
								:min-date="reporteItem.inicio"
								:max-date="reporteItem.operacion.fin"
								label="Termino"
								format="YYYY-MM-DD HH:mm"
								formatted="YYYY-MM-DD HH:mm"
								noHeader
								overlay
								:key="chkey2"
								buttonNowTranslation="Ahora"
								:disabled="
									JSON.stringify(reporteItem.operacion) == '{}'
								"
							/>
						</v-col>
						<v-col cols="2" class="py-0">
							<p class="mb-0 text-center">
								¿Bloquear Producción?
							</p>
							<div class="d-flex justify-center">
								<v-btn-toggle
									v-model="reporteItem.bloqueoProduccion"
									class="my-3"
								>
									<v-btn
										depressed
										small
										class="ma-0"
										:disabled="
											JSON.stringify(reporteItem.operacion) == '{}'
										"
										:color="
											reporteItem.bloqueoProduccion == 0
												? 'success'
												: 'grey lighten-1'
										"
										>Si</v-btn
									>
									<v-btn
										depressed
										small
										class="ma-0"
										:disabled="
											JSON.stringify(reporteItem.operacion) == '{}'
										"
										:color="
											reporteItem.bloqueoProduccion == 1
												? 'error'
												: 'grey lighten-1'
										"
										>No</v-btn
									>
								</v-btn-toggle>
							</div>
						</v-col>
						<v-col cols="2" class="py-0">
							<p class="text-center">Cantidad Reportada</p>
							<number-input
								v-model="reporteItem.cantidadReportada"
								controls
								inline
								size="small"
								:min="0"
								center
								:disabled="
									JSON.stringify(reporteItem.operacion) == '{}'
								"
							></number-input>
						</v-col>
					</v-row>
					<ul class="pt-4">
						<li
							class="red--text"
							v-for="(error, idx) in erroresReported"
							:key="'error-' + idx"
						>
							{{ error }}
						</li>
					</ul>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="green"
						:disabled="savingReporteExt"
						@click="cerrarDialogReporteExt"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="red"
						:disabled="savingReporteExt"
						:loading="savingReporteExt"
						@click="guardarReporteExt"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row v-if="permisoVista('incidencias','c')">
			<v-col cols="4" class="pt-3 pb-0">
				<v-btn
					class="my-0"
					color="primary"
					@click="showDialogReporteExt()"
					>Nuevo Reporte</v-btn
				>
			</v-col>
		</v-row>
		<v-row class="text-center">
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-select
					v-model="filtros.bloqueoProduccion"
					:items="tipoIncidencias"
					item-value="id"
					label="Tipo de Incidencia"
					hide-details
					clearable
					@change="paros_update_page_action(1)"
				></v-select>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-select
					v-model="filtros.idOEEFactor"
					:items="oeeFactores"
					item-value="idOEEFactor"
					item-text="nombre"
					label="Factor OEE"
					hide-details
					clearable
					@change="paros_update_page_action(1)"
				></v-select>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idParoTipo"
					:items="tipoParosFiltrados()"
					item-value="idParoTipo"
					item-text="nombre"
					label="Motivo del Incidencia"
					hide-details
					clearable
					@change="changeParoTipo"
				></v-autocomplete>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idSubParoTipo"
					:items="subTipoParoFiltrados()"
					item-value="idSubParoTipo"
					item-text="nombreSubParoTipo"
					label="Submotivo del Incidencia"
					hide-details
					clearable
					@change="paros_update_page_action(1)"
				></v-autocomplete>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idArea"
					:items="areas"
					item-value="idArea"
					item-text="nombre"
					label="Área"
					hide-details
					clearable
					@change="paros_update_page_action(1)"
				></v-autocomplete>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="align-self-end">
				<VueCtkDateTimePicker
					id="inicio"
					v-model="filtros.inicio"
					label="Desde"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="paros_update_page_action(1)"
				/>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="align-self-end">
				<VueCtkDateTimePicker
					id="fin"
					v-model="filtros.fin"
					:min-date="filtros.inicio"
					label="Hasta"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="paros_update_page_action(1)"
				/>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.clave"
					:items="claves"
					item-value="idTrabajoOrden"
					item-text="clave"
					label="OT"
					hide-details
					clearable
					@change="paros_update_page_action(1)"
				></v-autocomplete>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idArticuloProducir"
					:items="articulosProducir"
					item-value="idArticulo"
					item-text="nombre"
					label="Artículo Producir"
					hide-details
					clearable
					@change="paros_update_page_action(1)"
				></v-autocomplete>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idArticuloFase"
					:items="articulosFase"
					item-value="idArticulo"
					item-text="nombre"
					label="Artículo Fase"
					hide-details
					clearable
					@change="paros_update_page_action(1)"
				></v-autocomplete>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idReporto"
					:items="operadores"
					item-value="idPersona"
					:item-text="nombrePersona"
					label="Reportado Por"
					hide-details
					clearable
					@change="paros_update_page_action(1)"
				></v-autocomplete>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0 mb-4">
				<v-autocomplete
					v-model="filtros.idResponsable"
					:items="personas"
					item-value="idPersona"
					:item-text="nombrePersona"
					label="Persona Responsable"
					hide-details
					clearable
					@change="paros_update_page_action(1)"
				></v-autocomplete>
			</v-col>
		</v-row>
		<v-col cols="12">
			<download-btn
				v-if="permisoVista('incidencias','r')"
				text
				color="primary"
				className="mt-0"
				@click="descargarReporte()"
				label="Descargar Reporte de<br/>Incidencias Desglosadas"
			></download-btn>
			<!-- <v-btn
				v-if="permisoVista('incidencias','r')"
				text
				color="primary"
				class="mt-0"
				@click="descargarReporte()"
				:disabled="downloadingReport"
				:loading="downloadingReport"
			>
				Descargar Reporte de<br/>Incidencias Desglosadas
			</v-btn> -->
		</v-col>
		<v-dialog v-model="dialogMant" max-width="90%" persistent>
			<v-card>
					<v-toolbar color="primary" dark>
					<v-btn icon dark @click="cerrarDialogMant">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title style="color: white"
						>Seleccionar Mantenimientos</v-toolbar-title
					>
					<v-spacer></v-spacer>
					<v-btn dark text @click="clickGuardarSelected">
						Seleccionar
					</v-btn>
				</v-toolbar>
				<v-card-text>
					<Historial ref="historyMan" :seleccionable="true" :paros="true" />
				</v-card-text>
			</v-card>
		</v-dialog>
		
		<v-dialog v-model="dialogResponsables" persistent max-width="40%">
			<v-card>
				<v-card-title>Reportar Responsables</v-card-title>
				<v-card-text>
					<v-row class="d-flex justify-center">
						<v-col cols="10" md="10" sm="10">
							<v-autocomplete
								v-model="itemResponsable.idPersona"
								:items="involucradosPersonas"
								:item-text="nombrePersona"
								item-value="idPersona"
								label="Responsables"
								chips
								clearable
								deletable-chips
								multiple
							>
								<template v-slot:item="data">
									<v-list-item-content>
										<v-list-item-title 
											v-html="data.item.nombre + ' ' + data.item.paterno + ' ' + data.item.materno"
										></v-list-item-title>
										<v-list-item-subtitle 
											v-for="(area,index) in data.item.idArea" 
											:key="index"
										>{{ nombreArea(area) }}</v-list-item-subtitle>
									</v-list-item-content>
								</template>
							</v-autocomplete>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="red"
						:disabled="loadingResponsables"
						@click="cerrarDialogResponsables"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="green"
						:disabled="loadingResponsables || !habilitarBtnResp"
						:loading="loadingResponsables"
						@click="saveResponsables"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogRectificar" persistent max-width="40%">
			<v-card>
				<v-card-title>Rectificar</v-card-title>
				<v-card-text>
					<v-row class="d-flex justify-center">
						<v-col cols="10" md="10" sm="10">
							<v-autocomplete
								v-model="itemRectificar.idParoTipo"
								:items="tipoParos"
								item-text="nombre"
								item-value="idParoTipo"
								label="Motivo de Incidente"
								@change="resetSubParo"
							></v-autocomplete>
						</v-col>
						<v-col cols="10" md="10" sm="10">
							<v-autocomplete
								v-model="itemRectificar.idSubParoTipo"
								:items="filtroSubparos"
								item-text="nombreSubParoTipo"
								item-value="idSubParoTipo"
								label="Submotivo de Incidente"
								clearable
							></v-autocomplete>
						</v-col>
						<v-col cols="10" md="10" sm="10">
							<v-autocomplete
								v-model="itemRectificar.idMaquinaIns"
								:items="maquinasIntervinieron"
								item-text="nombre"
								item-value="idMaquinaIns"
								label="Máquina Responsable (Opcional)"
								clearable
							></v-autocomplete>
						</v-col>
						<v-col cols="10" md="10" sm="10">
							<v-text-field
								v-model="cantidadReportada"
								label="Cantidad reportada"
								type="number"
								min="0"
								:disabled="loadingRectificar"
							></v-text-field>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="red"
						:disabled="loadingRectificar"
						@click="cerrarDialogRectificar"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="green"
						:disabled="
							habilitarBtnRectif
						"
						:loading="loadingRectificar"
						@click="saveRectificar"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-data-table
			:headers="headersFiltrados"
			:items="paros"
			:loading="loading"
			loading-text="Cargando... Espere, Por Favor."
			:server-items-length="paros_total"
			:items-per-page="paros_items_per_page"
			:page="paros_actual_page"
			@update:page="paros_update_page_action"
			@update:items-per-page="(ipp) => (paros_items_per_page = ipp)"
			:footer-props="{
				disableItemsPerPage: true,
				disablePagination: this.loading,
			}"
			:disable-sort="true"
			:disable-pagination="loading"
		>
			<template v-slot:item.tipoIncidencia="{ item }">
				<div v-if="item.tipoIncidencia == 'Paro'" class="red--text font-weight-bold">
					{{item.tipoIncidencia}}
				</div>
				<div v-else>
					{{item.tipoIncidencia}}
				</div>
			</template>
			<template v-slot:item.motivoParo="{ item }">
				<div>
					<div class="font-weight-bold">
						{{item.motivoParo}}
					</div>
					<template v-if="item.subMovitoParo != null">
						{{item.subMovitoParo}}
					</template>
				</div>
			</template>
			<template v-slot:item.reportadoPorOperadores="{ item }">
				<ul
					class="text-left pl-0"
					v-if="item.reportadoPorOperadores.length > 0"
				>
					<li
						v-for="(operador, index) in item.reportadoPorOperadores"
						:key="`op-${index}`"
					>
						{{ operador }}
					</li>
				</ul>
				<div v-else>N/D</div>
			</template>
			<template v-slot:item.proceso="{ item }">
				<div>
					Artículo: {{item.articuloProducir}}
					<br>
					Fase: {{item.articuloFase}}
					<br>
					Área: {{item.nombreArea}}
				</div>
			</template>
			<!--
			<template v-slot:item.codigo="{ item }">
				{{ item.codigo === null ? "N/D" : item.codigo }}
			</template>
			<template v-slot:item.marca="{ item }">
				{{ item.marca === null ? "N/D" : item.marca }}
			</template>
			<template v-slot:item.modelo="{ item }">
				{{ item.modelo === null ? "N/D" : item.modelo }}
			</template>
			-->
			<template v-slot:item.maquina="{ item }">
				<div>
					Marca: {{ item.marca === null ? "N/D" : item.marca }}
					<br>
					Modelo: {{ item.marca === null ? "N/D" : item.marca }}
					<br>
					Código: {{ item.codigo === null ? "N/D" : item.codigo }}
				</div>
			</template>
			<!--
			<template v-slot:item.costosMantenimientoTarea="{ item }">
				$ {{ parseFloat(item.costosMantenimientoTarea).toFixed(2) }}
			</template>
			<template v-slot:item.costoScrap="{ item }">
				$ {{ parseFloat(item.costoScrap).toFixed(2) }}
			</template>
			<template v-slot:item.costoOportunidad="{ item }">
				$ {{ parseFloat(item.costoOportunidad).toFixed(2) }}
			</template>
			<template v-slot:item.costoTotal="{ item }">
				$ {{ parseFloat(item.costoTotal).toFixed(2) }}
			</template>
			-->
			<template v-slot:item.costos="{ item }">
				<div>
					<template v-if="parseFloat(item.costosMantenimientoTarea) > 0">
							Mantenimiento: $ {{ parseFloat(item.costosMantenimientoTarea).toFixed(2) }}
							<br>
					</template>
					<template v-if="parseFloat(item.costoScrap) > 0">
						Scrap: $ {{ parseFloat(item.costoScrap).toFixed(2) }}
						<br>
					</template>
					<template v-if="parseFloat(item.costoRetrabajo) > 0">
						Retrabajo: $ {{ parseFloat(item.costoRetrabajo).toFixed(2) }}
						<br>
					</template>
					<template v-if="parseFloat(item.costoOportunidad) > 0">
						Oportunidad: $ {{ parseFloat(item.costoOportunidad).toFixed(2) }}
						<br>
					</template>
					<div class="font-weight-bold">
						Total : $ {{ parseFloat(item.costoTotal).toFixed(2) }}
					</div>
				</div>
			</template>			
			<template v-slot:item.personaResponsables="{ item }">
				<ul
					class="text-left pl-0"
					v-if="item.personaResponsables.length > 0"
				>
					<li
						v-for="(persona, index) in item.personaResponsables"
						:key="`op-${index}`"
					>
						{{ persona }}
					</li>
				</ul>
				<div v-else>Sin Definir</div>
			</template>
			<template v-slot:item.actions="{ item }">
				<v-tooltip
					v-if="permisoVista('incidencias','u')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-show="item.idRectifico === null"
							v-on="on"
							small
							class="mr-2"
							@click="rectificar(item)"
							>mdi-check-bold</v-icon
						>
					</template>
					<span class="white--text">Rectificar</span>
				</v-tooltip>
				<v-tooltip
					v-if="permisoVista('incidencias','u')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							v-show="
								item.personaResponsables.length === 0 ||
								item.personaResponsables === null
							"
							small
							class="mr-2"
							@click="reportarResponsables(item)"
							>mdi-account-group</v-icon
						>
					</template>
					<span class="white--text">Reportar Responsables</span>
				</v-tooltip>
				<v-tooltip
					v-if="item.idRectifico === null && permisoVista('incidencias', 'u')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							class="mr-2"
							color="grey lighten-1"
							>mdi-recycle</v-icon
						>
					</template>
					<span class="white--text">Reportar Scrap<br>Es necesario primero rectificar</span>
				</v-tooltip>
				<v-tooltip
					v-else-if="item.idRectifico != null && permisoVista('incidencias', 'u')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							v-show="(item.cantidadScrap || 0) + (item.cantidadRetrabajo || 0)  < item.cantidadReportada"
							small
							class="mr-2"
							@click="reportarScrap(item)"
							>mdi-recycle</v-icon
						>
					</template>
					<span class="white--text">Reportar Scrap</span>
				</v-tooltip>
				<v-tooltip
					v-if="item.idRectifico === null && permisoVista('incidencias', 'u')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							class="mr-2"
							color="grey lighten-1"
							>mdi-rotate-left</v-icon
						>
					</template>
					<span class="white--text">Reportar Retrabajo<br>Es necesario primero rectificar</span>
				</v-tooltip>
				<v-tooltip
					v-else-if="item.idRectifico != null && permisoVista('incidencias', 'u')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							v-show="(item.cantidadScrap || 0) + (item.cantidadRetrabajo || 0)  < item.cantidadReportada"
							small
							class="mr-2"
							@click="reportarRetrabajo(item)"
							>mdi-rotate-left</v-icon
						>
					</template>
					<span class="white--text">Reportar Retrabajo</span>
				</v-tooltip>
				<v-tooltip
					v-if="permisoVista('incidencias','u')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							v-show="item.costosMantenimientoTarea === 0"
							small
							class="mr-2"
							@click="reportarMantenimiento(item)"
							>mdi-tools</v-icon
						>
					</template>
					<span class="white--text">Reportar Mantenimiento</span>
				</v-tooltip>
				<!--
				CT:{{ item.cantidadReportada}} S:{{(item.cantidadScrap || 0) }} R:{{ (item.cantidadRetrabajo || 0) }}
				-->
			</template>
		</v-data-table>
		<scrap ref="scrap" @reload="initialize"></scrap>
		<retrabajo ref="retrabajo" :areas="areas" @reload="initialize"></retrabajo>
	</v-col>
</template>

<script>
import axios from "axios";
import Historial from '@/components/DashViews/Mantenimiento/Historial.vue';
import Operaciones from '../../Reportes/Operaciones.vue';
import Scrap from './Scrap.vue'
import Retrabajo from './Retrabajo.vue';
import signalr from "@/components/SignalR";
import moment from "moment";
import DownloadBtn from '../../../DownloadBtn.vue';

export default {
	components: {
		Historial,
		Scrap,
		Retrabajo,
		signalr,
		Operaciones,
		DownloadBtn,
	},
	data() {
		return {
			downloadingReport: false,
			chkey1: 0,
			chkey2: 1,
			erroresReported: [],
			savingReporteExt: false,
			panel: 0,
			instancias: [],
			reporteItem: {
				idParoTipo: null,
				idSubParoTipo: null,
				idMaquinaIns: null,
				bloqueoProduccion: null,
				cantidadReportada: 0,
				operacion: {},
				inicio: null,
				fin: null
			},
			defaultReporteItem: {
				idParoTipo: null,
				idSubParoTipo: null,
				idMaquinaIns: null,
				bloqueoProduccion: null,
				cantidadReportada: 0,
				operacion: {},
				inicio: null,
				fin: null
			},
			chkey: 0,
			dialogReporteExt: false,
			involucradosPersonas: [],
			scrapTop: 0,
			articulosFase: [],
			articulosProducir: [],
			claves: [],
			operadores: [],
			areas: [],
			filtros: {
				idOEEFactor: -1,
				idParoTipo: null,
				idSubParoTipo: null,
				clave: null,
				idArticuloProducir: null,
				idArticuloFase: null,
				idArea: null,
				idReporto: null,
				idResponsable: null,
				inicio: null,
				fin: null,
				bloqueoProduccion: null,
			},
			personas: [],
			validaciones: [],
			tipoSubparos: [],
			tipoParos: [],
			habilitarBtnMant: false,
			loadingMant: false,
			dialogMant: false,
			habilitarBtnResp: false,
			habilitarBtnRec: false,
			cantidadReportada: 0,
			loadingRectificar: false,
			dialogRectificar: false,
			loadingResponsables: false,
			dialogResponsables: false,
			itemMant: {
				idParo: 0,
				idsMantenimientoTareas: []
			},
			defaultItemMant: {
				idParo: 0,
				idsMantenimientoTareas: []
			},
			itemResponsable: {
				idParo: 0,
				idPersona: [],
			},
			defaultItemResponsable: {
				idParo: 0,
				idPersona: [],
			},
			itemRectificar: {
				idParo: 0,
				idParoTipo: 0,
				idSubParoTipo: 0,
				idMaquinaIns: null,
				cantidadReportada: 0,
			},
			defaultItemRectificar: {
				idParo: 0,
				idParoTipo: 0,
				idSubParoTipo: 0,
				idMaquinaIns: null,
				cantidadReportada: 0,
			},
			maquinasIntervinieron:[],
			loading: false,
			paros: [],
			oeeFactores: [],
			//? Paginacion paros y filtros (server side)
			paros_total: null,
			paros_items_per_page: 10,
			paros_actual_page: 1,
			headers: [
				{
					text: "Tipo",
					align: "center",
					sortable: false,
					value: "tipoIncidencia",
				},
				{
					text: "Motivo",
					align: "center",
					sortable: false,
					value: "motivoParo",
				},
				{
					text: "Clave",
					align: "center",
					sortable: false,
					value: "clave",
				},
				{
					text: "Proceso",
					align: "center",
					sortable: false,
					value: "proceso",
					width: '20%'
				},
				/*
				{
					text: "Artículo",
					align: "center",
					sortable: false,
					value: "articuloProducir",
				},
				{
					text: "Fase",
					align: "center",
					sortable: false,
					value: "articuloFase",
				},
				{
					text: "Área",
					align: "center",
					sortable: false,
					value: "nombreArea",
				},
				*/
				{
					text: "Reportado por",
					align: "center",
					sortable: false,
					value: "reportadoPorOperadores",
				},
				{
					text: "Máquina",
					align: "center",
					sortable: false,
					value: "maquina",
					width: '10%'
				},
				/*
				{
					text: "Código",
					align: "center",
					sortable: false,
					value: "codigo",
				},
				{
					text: "Marca",
					align: "center",
					sortable: false,
					value: "marca",
				},
				{
					text: "Modelo",
					align: "center",
					sortable: false,
					value: "modelo",
				},
				*/
				{
					text: "Costos Estimados",
					align: "center",
					sortable: false,
					value: "costos",
					width: '13%'
				},
				/*
				{
					text: "Costo Mantenimiento",
					align: "center",
					sortable: false,
					value: "costosMantenimientoTarea",
				},
				{
					text: "Costo Scrap",
					align: "center",
					sortable: false,
					value: "costoScrap",
				},
				{
					text: "Costo Retrabajo",
					align: "center",
					sortable: false,
					value: "costoRetrabajo",
				},
				{
					text: "Costo Oportunidad",
					align: "center",
					sortable: false,
					value: "costoOportunidad",
				},
				{
					text: "Costo Total",
					align: "center",
					sortable: false,
					value: "costoTotal",
				},
				*/
				{
					text: "Personas Responsables",
					align: "center",
					sortable: false,
					value: "personaResponsables",
					width: '10%'
				},
				{
					text: "Inicio",
					align: "center",
					sortable: false,
					value: "inicio",
					width: '9%'
				},
				{
					text: "Fin",
					align: "center",
					sortable: false,
					value: "fin",
					width: '9%'
				},
				{
					text: "Acciones",
					align: "center",
					sortable: false,
					value: "actions",
					width: '7%'
				},
			],
			tipoIncidencias:[
				{
					id: true,
					text: "Paro"
				},
				{
					id: false,
					text: "Reporte"
				},
			],
			rules: {
				numValido: (num) => {
					if (!isNaN(parseFloat(num)) && parseFloat(num) >= 0)
						return true;
					return "Cantidad Invalida.";
				},
			},
		}
	},
	computed: {
		headersFiltrados(){
			return this.permisoVista('incidencias','$')
				? this.headers
				: this.headers.filter( head => head.value != 'costos' )
		},
		instanciasFiltradas(){
			let maquinasFiltradas = [];
			if(JSON.stringify(this.reporteItem.operacion) != "{}"){
				this.instancias.forEach(ins => {
					this.reporteItem.operacion.maquinas.forEach( maq => {
						if (ins.idMaquinaIns == maq.idMaquinaIns) maquinasFiltradas.push(ins);
					});
				});
			}
			return maquinasFiltradas;
		},
		paros_fetch_url() {
			let url = `/Paros?Paginar=true&Page=${this.paros_actual_page}&Size=${this.paros_items_per_page}`;

			if (this.$utils.isValid(this.filtros.idOEEFactor) && this.filtros.idOEEFactor >= 0) {
				url += "&IdOEEFactor=" + this.filtros.idOEEFactor;
			}

			if (this.$utils.isValid(this.filtros.idParoTipo)) {
				url += "&IdParoTipo=" + this.filtros.idParoTipo;
			}

			if (this.$utils.isValid(this.filtros.idSubParoTipo)) {
				url += "&IdSubParoTipo=" + this.filtros.idSubParoTipo;
			}

			if (this.$utils.isValid(this.filtros.clave)) {
				url += "&IdTrabajoOrden=" + this.filtros.clave;
			}

			if (this.$utils.isValid(this.filtros.idArticuloProducir)) {
				url += "&IdArticuloProducir=" + this.filtros.idArticuloProducir;
			}

			if (this.$utils.isValid(this.filtros.idArticuloFase)) {
				url += "&IdArticuloFase=" + this.filtros.idArticuloFase;
			}

			if (this.$utils.isValid(this.filtros.idArea)) {
				url += "&IdArea=" + this.filtros.idArea;
			}

			if (this.$utils.isValid(this.filtros.idReporto)) {
				url += "&IdReporto=" + this.filtros.idReporto;
			}

			if (this.$utils.isValid(this.filtros.idResponsable)) {
				url += "&IdResponsable=" + this.filtros.idResponsable;
			}

			if (this.$utils.isValid(this.filtros.inicio)) {
				url += "&Inicio=" + this.filtros.inicio;
			}

			if (this.$utils.isValid(this.filtros.fin)) {
				url += "&Fin=" + this.filtros.fin;
			}
			
			if (this.$utils.isValid(this.filtros.bloqueoProduccion)) {
				url += "&BloqueoProduccion=" + this.filtros.bloqueoProduccion;
			}

			return url;
		},
		filtroSubparos() {
			return this.tipoSubparos.filter(
				(subparo) =>
					subparo.idParoTipo === this.itemRectificar.idParoTipo
			);
		},
		validacionRec() {
			if (this.validaciones[0] && this.validaciones[1]) return true;
			else return false;
		},
		habilitarBtnRectif(){
			if (
				this.loadingRectificar || 
				isNaN(parseFloat(this.cantidadReportada)) ||
				parseFloat(this.cantidadReportada) < 0 || 
				this.itemRectificar.idParoTipo == 0
			)
				return true;
			else
				return false;
		}
	},
	created() {
		this.initialize();
		this.getOEEFactores();
		this.getTipoParos();
		this.getTipoSubParos();
		this.getPersonas();
		this.getOperadores();
		this.getClaves();
		this.getArticulosProducir();
		this.getArticulosFase();
		this.getInstancias();
	},
	mounted(){
		this.getAreas();
	},
	watch: {
		"itemResponsable.idPersona": function (after) {
			after.length === 0
				? (this.habilitarBtnResp = false)
				: (this.habilitarBtnResp = true);
		},
	},
	methods: {
		marcaModelo: (item) => item.marca.trim() + " - " + item.modelo.trim(),
		removeEmptyObj(obj){
			Object.keys(obj).forEach(k =>
				(obj[k] && typeof obj[k] === 'object') && this.removeEmpty(obj[k]) ||
				(!obj[k] && obj[k] !== undefined) && delete obj[k] ||
				(obj[k] == -1) && delete obj[k]
			);
			return obj;
		},
		descargarReporte() {
			this.downloadingReport = true;
			var datetime = moment().format("YYYYMMDDHHmmss");
			const filtrados = this.removeEmptyObj(this.filtros);
			var url = new URLSearchParams(filtrados).toString();
			this.$utils.axios.downloadFile(
				"/Paros/XlsParosRealizados?" + url,
				"GET",
				"reporte-incidencias-" + datetime + ".xlsx",
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			);
			this.downloadingReport = false;
		},
		rowOperacionesSelected(selectedItems){
			this.reporteItem.operacion = Object.assign({},selectedItems[0]);
			this.chkey2++;
			this.chkey1++;
			this.reporteItem.idMaquinaIns = null;
			this.reporteItem.operacion.inicio = moment(this.reporteItem.operacion.inicio).format('YYYY-MM-DD HH:mm');
			this.reporteItem.operacion.fin = moment(this.reporteItem.operacion.fin).format('YYYY-MM-DD HH:mm');
			this.reporteItem.inicio = this.reporteItem.operacion.inicio;
			this.reporteItem.fin = this.reporteItem.operacion.fin;
		},
		validateReporteExt(){
			this.erroresReported = [];

			if(this.reporteItem.idParoTipo == null){
				this.erroresReported.push('Debe indicar el Motivo de la Incidencia.');
			}

			if(JSON.stringify(this.reporteItem.operacion) == '{}'){
				this.erroresReported.push('Debe seleccionar una Operación.');
			}

			if(this.reporteItem.bloqueoProduccion==null){
				this.erroresReported.push('Debe indicar si desea Bloquear la Producción.');
			}

			if(
				isNaN(this.reporteItem.cantidadReportada) ||
				this.reporteItem.cantidadReportada < 0
			){
				this.erroresReported.push('Debe introducir una Cantidad Reportada valida.');
			}

			if(this.reporteItem.inicio==null){
				this.erroresReported.push('Debe indicar una fecha de Inicio que este dentro del rango de fechas de la operación seleccionada.');
			}

			if(this.reporteItem.fin==null){
				this.erroresReported.push('Debe indicar una fecha de Fin que este dentro del rango de fechas de la operación seleccionada');
			}

			return this.erroresReported.length==0;
		},
		guardarReporteExt(){
			if( !this.validateReporteExt()) return;
			this.savingReporteExt = true;
			const obj = {
				idParoTipo: this.reporteItem.idParoTipo,
  				idSubParoTipo: this.reporteItem.idSubParoTipo,
  				idOperacionReal: this.reporteItem.operacion.idOperacionReal,
  				idMaquinaIns: this.reporteItem.idMaquinaIns,
  				bloqueoProducion: !this.reporteItem.bloqueoProduccion,
  				cantidadReportada: this.reporteItem.cantidadReportada,
  				inicio: this.reporteItem.inicio,
  				fin: this.reporteItem.fin,
			};
			axios
				.post("/Paros/ParoExtemporaneo",obj)
				.then( () => {
					this.savingReporteExt = false;
					this.cerrarDialogReporteExt();
				})
				.catch( error => {
					this.savingReporteExt = false;
					console.log( error );
					if (error.response) {
						if (error.response.status == 400) {
							this.erroresReported.push(
								"La fecha de Inicio y la fecha de Fin deben estar dentro del rango de fechas de la operación seleccionada"
							);
						}
					}
				})
		},
		getInstancias(){
			axios
				.get("/MaquinaInstancias")
				.then( response => {
					this.instancias = response.data;
				})
				.catch( error => {
					console.log(error);
				})
		},
		cerrarDialogReporteExt(){
			this.dialogReporteExt = false;
			this.reporteItem = Object.assign( {},this.defaultReporteItem);
			this.erroresReported = [];
		},
		showDialogReporteExt(){
			this.dialogReporteExt = true;
			this.chkey += 1;
			this.panel = 0;
		},
		filtroDetalleIncidencia(idArea){
			this.filtros.idArea = idArea;
			this.filtros.inicio = moment().subtract(5, 'minutes').format('YYYY-MM-DD HH:mm');
			this.paros_update_page_action(1);
		},
		reportarScrap(item){
			this.$refs.scrap.reportarScrap(item);
		},
		nombreArea(idArea){
			const area = this.areas.find( area => area.idArea === idArea );
			return area !== undefined ? area.nombre : 'Sin Designar';
		},
		clickGuardarSelected() {
			const items = this.$refs.historyMan.clickSeleccionados();
			this.loadingMant = true;
			this.itemMant.idsMantenimientoTareas = items.map((mantenimiento) => mantenimiento.idMantenimientoTarea );
			axios
				.post( '/Paros/ReportarParoMantenimientoTarea', this.itemMant )
				.then( () => {
					this.loadingMant = false;
					this.cerrarDialogMant();
					this.initialize();
				})
				.catch( error => {
					this.loadingMant = false;
					console.log( error );
				})
		},
		reportarMantenimiento(item){
			this.itemMant.idParo = item.idParo;
			this.dialogMant = true;
		},
		cerrarDialogMant() {
			this.dialogMant = false;
			setTimeout(() => {
				if(this.$refs.historyMan !== undefined)
					this.$refs.historyMan.fullresetSeleccionados();
			}, 250);
			this.itemMant = Object.assign({}, this.defaultItemMant);
			this.loadingMant = false;
		},
		getArticulosFase() {
			axios
				.get("/Articulos/ArticulosNombres?componentes=true")
				.then((response) => {
					this.articulosFase = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getArticulosProducir() {
			axios
				.get("/Articulos/OTS")
				.then((response) => {
					this.articulosProducir = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getClaves() {
			axios
				.get("/TrabajoOrdenes/TrabajoOrdenHeaders")
				.then((response) => {
					this.claves = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getOperadores() {
			axios
				.get("/Personas/Operadores")
				.then((response) => {
					this.operadores = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		changeTipoParo(){
			this.reporteItem.idSubParoTipo = null;
		},
		changeParoTipo() {
			this.filtros.idSubParoTipo = null;
			this.paros_update_page_action(1);
		},
		leerParametros(){
			if (this.$route.query.idArea != undefined){
				this.filtroDetalleIncidencia(this.$route.query.idArea);
				this.$router.push('/dashboard/calidad');
			}
		},
		getAreas() {
			axios
				.get("/Areas")
				.then((response) => {
					this.areas = response.data;
					this.leerParametros();
				})
				.catch((error) => {
					console.log(error);
				});
		},
		nombrePersona: (persona) => persona.nombre + " " + persona.paterno + " " + persona.materno,
		getPersonas() {
			axios
				.get("/Personas")
				.then((response) => {
					this.personas = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getInvolucrados(idParo){
			this.loadingInvolucrados = true;
			axios
				.get( `/Paros/Colaboradores/${idParo}` )
				.then( response => {
					const involucrados = response.data.map( involucrado => ({
						idArea: involucrado.idArea,
						...this.personas.find( persona => persona.idPersona === involucrado.idPersona),
						group: 'Involucrados'
					}));
					this.involucradosPersonas = [
						{ header: 'Involucrados' },
						...involucrados,
						{ divider: true },
						{ header: 'Otros Operadores' },
						...this.personas
					];
					this.loadingInvolucrados = false;
				})
				.catch( error => {
					this.loadingInvolucrados = false;
					console.log( error );
				})
		},
		saveResponsables() {
			this.loadingResponsables = true;
			axios
				.post("/Paros/ReportarResponsableParo", this.itemResponsable)
				.then(() => {
					this.loadingResponsables = false;
					this.cerrarDialogResponsables();
					this.initialize();
				})
				.catch((error) => {
					this.loadingResponsables = false;
					console.log(error);
				});
		},
		reportarResponsables(item) {
			this.itemResponsable.idParo = item.idParo;
			this.getInvolucrados(item.idParo);
			this.dialogResponsables = true;
		},
		cerrarDialogResponsables() {
			this.dialogResponsables = false;
			this.involucradosPersonas = [];
			this.itemResponsable = Object.assign(
				{},
				this.defaultItemResponsable
			);
		},
		resetSubParo() {
			this.itemRectificar.idSubParoTipo = null;
		},
		getOEEFactores() {
			axios
				.get("/OEEFactores")
				.then((response) => {
					this.oeeFactores = response.data;
					this.oeeFactores.push({
						idOEEFactor: 0,
						nombre: "SIN ESPECIFICAR",
						descripcion: "Sin Especificar",
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getTipoParos() {
			axios
				.get("/ParoTipos")
				.then((response) => {
					this.tipoParos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getTipoSubParos() {
			axios
				.get("/SubParoTipos")
				.then((response) => {
					this.tipoSubparos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		saveRectificar() {
			this.loadingRectificar = true;
			
			this.itemRectificar.cantidadReportada = parseFloat(this.cantidadReportada);
			
			if(!this.$utils.isValid(this.itemRectificar.idMaquinaIns)) this.itemRectificar.idMaquinaIns = null;

			axios
				.put("/Paros/Rectificar", this.itemRectificar)
				.then(() => {
					this.loadingRectificar = false;
					this.cerrarDialogRectificar();
					this.initialize();
				})
				.catch((error) => {
					this.loadingRectificar = false;
					console.log(error);
				});
		},
		rectificar(item) {
			this.itemRectificar.idParo = item.idParo;
			this.itemRectificar.idParoTipo = item.idParoTipo;
			this.itemRectificar.idSubParoTipo = item.idSubParoTipo;
			this.itemRectificar.idMaquinaIns = item.idMaquinaInstancia;
			this.maquinasIntervinieron = item.maquinasIntervinieron;
			this.cantidadReportada = item.cantidadReportada;
			this.dialogRectificar = true;
		},
		cerrarDialogRectificar() {
			this.dialogRectificar = false;
			this.cantidadReportada = 0;
			this.itemRectificar = Object.assign({}, this.defaultItemRectificar);
			this.maquinasIntervinieron = [];
		},
		async initialize() {
			this.loading = true;
			try {
				let response = await this.getParos();
				this.paros = this.mixOperadores(response.data.result);
				this.paros_total = response.data.total;
				this.loading = false;
			} catch (exception) {
				console.error(exception);
			}
		},
		async paros_update_page_action(page) {
			this.paros_actual_page = page;
			this.loading = true;
			try {
				let response = await this.getParos();
				this.paros = this.mixOperadores(response.data.result);
				this.paros_total = response.data.total;
				this.loading = false;
			} catch (exception) {
				console.log(exception);
			}
		},
		getParos() {
			//* Realizamos peticion HTTP
			return new Promise((resolve, reject) => {
				axios
					.get(this.paros_fetch_url)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		mixOperadores(prs) {
			return prs.map((item) => {
				for (const elemento in item) {
					if (elemento === "reportadoPor") {
						if (
							item[elemento] === null ||
							item[elemento].length === 0 ||
							JSON.stringify(item[elemento]) === "[]"
						)
							item["reportadoPorOperadores"] =
								item["operadoresReales"];
						else
							item["reportadoPorOperadores"] =
								item["reportadoPor"];
					}
				}
				return item;
			});
		},
		reportarRetrabajo(item){
			this.$refs.retrabajo.mostrarRetrabajo(item);
		},
		cambios(event){
			this.paros_update_page_action(this.paros_actual_page);
		},
		tipoParosFiltrados(){
			if(this.$utils.isValid(this.filtros.idOEEFactor) && this.filtros.idOEEFactor >= 0){
				var idOEEFactor = (this.filtros.idOEEFactor == 0) ? null : this.filtros.idOEEFactor;
				return this.tipoParos.filter(tp => tp.idOEEFactor == idOEEFactor);
			}
			else {
				return this.tipoParos;
			}
		},
		subTipoParoFiltrados(){
			if(this.$utils.isValid(this.filtros.idParoTipo)) return this.tipoSubparos.filter(tp => tp.idParoTipo ==  this.filtros.idParoTipo);
			else return this.tipoSubparos;
		},
		reporteTipoSubparos(){
			return this.$utils.isValid(this.reporteItem.idParoTipo)
			? this.tipoSubparos.filter( sub => sub.idParoTipo == this.reporteItem.idParoTipo )
			: []
		}
	},
};
</script>