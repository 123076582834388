<template>
	<v-container fluid class="pa-0">
		<v-dialog
			v-model="dialogReporteTiempoReal"
			persistent
			max-width="700px"
		>
			<v-card>
				<v-card-title>Reporte en Tiempo Real</v-card-title>
				<v-card-text>
					<v-container fluid>
						<v-row>
							<v-col cols="12">
								<v-data-table
									:items="[editedItem]"
									:headers="headersDetallesItemsFiltred"
									hide-default-footer
								>
									<template v-slot:[`item.tipo`]="{ item }">
										{{ getTextTipo(item.tipo) }}
									</template>
									<template v-slot:[`item.nombre`]="{ item }">
										{{ getNombreMantenimiento(item) }}
									</template>
								</v-data-table>
							</v-col>
							<v-col cols="12">
								<v-autocomplete
									v-model="editedItem.responsable"
									label="Responsable"
									:items="personas"
									item-value="idPersona"
									:item-text="nombrePersona"
									:disabled="reportingReal"
									:error-messages="
										editedItem.errorResponsable
									"
									@focus="
										$set(editedItem, 'errorResponsable', '')
									"
								></v-autocomplete>
							</v-col>
							<v-col
								v-if="editedItem.tipo != 3"
								cols="12"
								md="12"
								sm="12"
							>
								<v-autocomplete
									v-model="editedItem.mecanicos"
									:items="personas"
									item-value="idPersona"
									:item-text="nombrePersona"
									label="Realizado por"
									multiple
									chips
									deletable-chips
									:disabled="reportingReal"
									:error-messages="editedItem.errorMecanicos"
									@focus="
										$set(editedItem, 'errorMecanicos', '')
									"
								></v-autocomplete>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="reportingReal"
						@click="cerrarDialogReportes"
						>Cerrar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="reportingReal"
						:loading="reportingReal"
						@click="reportarTiempoReal"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog
			v-model="dialogReporteExtemporaneo"
			persistent
			max-width="700px"
		>
			<v-card>
				<v-card-title>Reporte Extemporáneo</v-card-title>
				<v-card-text>
					<v-container fluid>
						<v-row>
							<v-col cols="12">
								<v-data-table
									:items="[editedItem]"
									:headers="headersDetallesItemsFiltred"
									hide-default-footer
								>
									<template v-slot:[`item.tipo`]="{ item }">
										{{ getTextTipo(item.tipo) }}
									</template>
									<template v-slot:[`item.nombre`]="{ item }">
										{{ getNombreMantenimiento(item) }}
									</template>
								</v-data-table>
							</v-col>
							<v-col cols="12">
								<v-autocomplete
									v-model="editedItem.responsable"
									label="Responsable"
									:items="personas"
									item-value="idPersona"
									:item-text="nombrePersona"
									:disabled="reportingExtemporaneo"
									:error-messages="
										editedItem.errorResponsable
									"
									@focus="
										$set(editedItem, 'errorResponsable', '')
									"
								></v-autocomplete>
							</v-col>
							<v-col
								v-if="editedItem.tipo != 3"
								cols="12"
								md="12"
								sm="12"
							>
								<v-autocomplete
									v-model="editedItem.mecanicos"
									:items="personas"
									item-value="idPersona"
									:item-text="nombrePersona"
									label="Realizado por"
									multiple
									chips
									deletable-chips
									:disabled="reportingExtemporaneo"
									:error-messages="editedItem.errorMecanicos"
									@focus="
										$set(editedItem, 'errorMecanicos', '')
									"
								></v-autocomplete>
							</v-col>
							<v-col cols="12">
								<VueCtkDateTimePicker
									v-model="editedItem.fechaInicio"
									label="Inicio"
									format="YYYY-MM-DD HH:mm"
									formatted="YYYY-MM-DD HH:mm"
									noHeader
									overlay
									buttonNowTranslation="Ahora"
									:error="editedItem.errorFechaInicio != null"
									@input="
										resetErrorFecha(
											editedItem,
											'fechaInicio',
											'errorFechaInicio'
										)
									"
								/>
								<p
									v-if="editedItem.errorFechaInicio"
									class="red--text pt-1 mb-0"
									style="font-size: 12px !important"
								>
									{{ editedItem.errorFechaInicio }}
								</p>
							</v-col>
							<v-col cols="12">
								<VueCtkDateTimePicker
									v-model="editedItem.fechaFin"
									:min-date="editedItem.fechaInicio"
									label="Termino"
									format="YYYY-MM-DD HH:mm"
									formatted="YYYY-MM-DD HH:mm"
									noHeader
									overlay
									buttonNowTranslation="Ahora"
									:error="editedItem.errorFechaFin != null"
									@input="
										resetErrorFecha(
											editedItem,
											'fechaFin',
											'errorFechaFin'
										)
									"
								/>
								<p
									v-if="editedItem.errorFechaFin"
									class="red--text pt-1 mb-0"
									style="font-size: 12px !important"
								>
									{{ editedItem.errorFechaFin }}
								</p>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" class="pa-0">
								<Comprobaciones
									ref="checklist"
									:key="compExtKey"
								/>
							</v-col>
						</v-row>
						<v-row v-if="editedItem.tipo != 3">
							<v-col cols="12">
								<v-textarea
									v-model="editedItem.recomendacion"
									outlined
									label="Recomendación"
									auto-grow
									:disabled="reportingExtemporaneo"
									rows="1"
									hide-details
								></v-textarea>
							</v-col>
						</v-row>
						<v-row v-if="erroresComprobaciones.length > 0">
							<v-col cols="12">
								<ul>
									<li
										class="red--text"
										v-for="(
											error, idx
										) in erroresComprobaciones"
										:key="'error-' + idx"
									>
										{{ error }}
									</li>
								</ul>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="reportingExtemporaneo"
						@click="cerrarDialogReportes"
						>Cerrar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="reportingExtemporaneo"
						:loading="reportingExtemporaneo"
						@click="reportarExtemporaneo"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogDetalles" persistent max-width="80%">
			<v-card>
				<v-card-title
					>Actividades {{ nombreMaquina(detallesItem) }} del día
					{{ detallesItem.fecha }}</v-card-title
				>
				<v-card-text>
					<v-row>
						<v-col cols="12">
							<v-data-table
								:items="detallesItem.detalles"
								:headers="headersDetallesItems"
								:items-per-page="-1"
								hide-default-footer
							>
								<template v-slot:[`item.tipo`]="{ item }">
									{{ getTextTipo(item.tipo) }}
								</template>
								<template v-slot:[`item.estado`]="{ item }">
									{{ textoEstadoDetalle(item.estado) }}
								</template>
								<template v-slot:[`item.nombre`]="{ item }">
									{{ getNombreMantenimiento(item) }}
								</template>
								<template v-slot:[`item.actions`]="{ item }">
									<div
										v-if="item.estado == 1"
										class="
											d-flex
											justify-center
											align-center
										"
									>
										<v-btn
											text
											color="error"
											@click="
												onClickReporteExtemporaneo(item)
											"
											>Reporte<br />Extemporáneo</v-btn
										>
										<v-btn
											text
											color="primary"
											@click="
												onClickReporteTiempoReal(item)
											"
											>Reporte<br />Tiempo Real</v-btn
										>
									</div>
								</template>
							</v-data-table>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text color="success" @click="cerrarDialog()"
						>Cerrar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col cols="4">
				<datePicker
					v-model="filtros.fechaInicio"
					format="YYYY-MM-DD"
					label="[Obligatorio] Fecha de Inicio"
					clearable
					:maxDate="filtros.fechaFin"
					@input="getDatos"
					:disabled="loading"
				></datePicker>
			</v-col>
			<v-col cols="4">
				<datePicker
					v-model="filtros.fechaFin"
					format="YYYY-MM-DD"
					label="[Obligatorio] Fecha de Fin"
					clearable
					:minDate="filtros.fechaInicio"
					@input="getDatos"
					:disabled="loading"
				></datePicker>
			</v-col>
			<v-col cols="4">
				<v-autocomplete
					v-model="filtros.idSubdepartamento"
					label="[Opcional] Subdepartamento"
					clearable
					:items="subdepartamentos"
					:item-text="nombreSubdepto"
					item-value="idSubdepartamento"
					hide-details
					:disabled="loading || loadingSubdeptos"
					:loading="loadingSubdeptos"
					@change="getDatos"
				></v-autocomplete>
			</v-col>
			<v-col cols="4">
				<v-autocomplete
					v-model="filtros.idsAreas"
					label="[Opcional] Áreas"
					hide-details
					clearable
					:items="areas"
					item-value="idArea"
					item-text="nombre"
					multiple
					small-chips
					deletable-chips
					:disabled="loading || loadingAreas"
					:loading="loadingAreas"
					@change="getDatos"
				></v-autocomplete>
			</v-col>
			<v-col cols="4">
				<v-autocomplete
					v-model="filtros.idsMaquinas"
					label="[Opcional] Máquinas"
					hide-details
					clearable
					:items="maquinas"
					item-value="idMaquina"
					:item-text="maquinaNombre"
					multiple
					small-chips
					deletable-chips
					:disabled="loading || loadingMaquinas"
					:loading="loadingMaquinas"
					@change="getDatos"
				></v-autocomplete>
			</v-col>
			<v-col cols="4">
				<v-autocomplete
					v-model="filtros.idsCategorias"
					label="[Opcional] Categorías"
					hide-details
					clearable
					:items="categorias"
					item-value="idMaquinaCategoria"
					item-text="nombre"
					multiple
					small-chips
					deletable-chips
					:disabled="loading || loadingCategorias"
					:loading="loadingCategorias"
					@change="getDatos"
				></v-autocomplete>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="4" class="py-2">
				<download-btn
					color="blue darken-2"
					text
					@click="descargarReporte"
					label="Descargar Calendario<br/>de Mantenimiento"
					:disabled="escenarioInvalido"
				></download-btn>
			</v-col>
		</v-row>
		<div
			v-if="escenarioInvalido"
			class="preview-container d-flex justify-center"
		>
			<div
				v-if="filtros.fechaInicio == null || filtros.fechaFin == null"
				class="align-self-center text-center"
			>
				<v-icon size="100" color="grey">mdi-calendar-clock</v-icon>
				<p
					class="font-weight-bold"
					style="font-size: 2rem !important; color: #9e9e9e"
				>
					Seleccione las fechas
				</p>
			</div>
			<div v-else-if="loading" class="align-self-center text-center">
				<v-progress-circular
					indeterminate
					size="100"
					width="7"
					color="blue darken-2"
				></v-progress-circular>
				<p class="text-h4">Cargando</p>
			</div>
			<div
				v-else-if="maquinasInstancias.length == 0"
				class="align-self-center text-center"
			>
				<v-icon size="100" color="grey">mdi-calendar-clock</v-icon>
				<p
					class="font-weight-bold"
					style="font-size: 2rem !important; color: #9e9e9e"
				>
					No hay datos disponibles
				</p>
			</div>
		</div>
		<v-row v-else>
			<v-col cols="12" class="pt-0">
				<v-simple-table class="custom-max-header" fixed-header>
					<template v-slot:default>
						<thead>
							<tr>
								<th
									class="text-center text--secondary"
									style="
										font-size: 1.0625rem !important;
										width: 350px
									"
								>
									Máquina Instancia
								</th>
								<th
									v-for="(header, index) in headerFechas"
									:key="`header-${index}`"
									class="
										text-center text--secondary
										py-2
										text-th
									"
									:class="customBackgroundColor(header)"
								>
									<span class="vertical-text">{{
										header
									}}</span>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(
									maquina, indexMaquina
								) in maquinasInstancias"
								:key="maquina.idMaquinaInstancia"
								class="text-center"
							>
								<td>
									{{ nombreMaquina(maquina) }}
								</td>
								<td
									v-for="(
										operacion, indexOperacion
									) in maquina.operaciones"
									:key="`operacion-${indexMaquina}-${indexOperacion}`"
									:class="
										customBackgroundColor(operacion.fecha)
									"
								>
									<v-tooltip
										v-if="operacion.estado > 0"
										bottom
										color="black"
										class="white--text"
									>
										<template v-slot:activator="{ on }">
											<v-icon
												v-on="on"
												size="30"
												:color="
													colorEstado(
														operacion.estado
													)
												"
												@click="
													verDetalles(
														maquina,
														operacion
													)
												"
											>
												mdi-cog
											</v-icon>
										</template>
										<span class="white--text">
											Estado: {{ textoEstado(operacion.estado) }}<br>
											Ver Detalles
										</span>
									</v-tooltip>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapState } from "vuex";
import qs from "qs";
import Comprobaciones from "./Comprobaciones.vue";

export default {
	components: { Comprobaciones },
	data() {
		return {
			dialogReporteTiempoReal: false,
			reportingReal: false,
			reportingExtemporaneo: false,
			erroresComprobaciones: [],
			compExtKey: 0,
			personas: [],
			dialogReporteExtemporaneo: false,
			editedItem: {},
			loadingCategorias: false,
			categorias: [],
			maquinas: [],
			loadingMaquinas: false,
			loadingAreas: false,
			areas: [],
			dialogDetalles: false,
			headersDetallesItems: [
				{
					text: "Tipo",
					align: "center",
					value: "tipo",
				},
				{
					text: "Estado",
					align: "center",
					value: "estado",
				},
				{
					text: "Nombre",
					align: "center",
					value: "nombre",
				},
				{
					text: "Máquina",
					align: "center",
					value: "maquina",
				},
				{
					text: "Fecha Inicio",
					align: "center",
					value: "inicio",
				},
				{
					text: "Duración",
					align: "center",
					value: "duracion",
				},
				{
					text: "Acciones",
					align: "center",
					value: "actions",
				},
			],
			detallesItem: {
				idMaquinaInstancia: null,
				marca: null,
				modelo: null,
				codigo: null,
				fecha: null,
				detalles: [],
			},
			defaultDetallesItem: {
				idMaquinaInstancia: null,
				marca: null,
				modelo: null,
				codigo: null,
				fecha: null,
				detalles: [],
			},
			subdepartamentos: [],
			loadingSubdeptos: false,
			loading: false,
			filtros: {
				idsAreas: [],
				idsMaquinas: [],
				idsCategorias: [],
				fechaInicio: undefined,
				fechaFin: undefined,
				idSubdepartamento: undefined,
			},
			headerFechas: [],
			maquinasInstancias: [],
		};
	},
	beforeMount(){
		this.precargarFechas();
	},
	mounted() {
		this.initialize();
	},
	computed: {
		...mapState(["token"]),
		headersDetallesItemsFiltred(){
			return this.headersDetallesItems.filter( 
				header => header.value != "estado" && header.value != "actions"
			);
		},
		diaActual() {
			return moment().format("DD/MM/YYYY");
		},
		escenarioInvalido() {
			return (
				this.filtros.fechaInicio == null ||
				this.filtros.fechaFin == null ||
				this.loading ||
				this.maquinasInstancias.length == 0
			);
		},
	},
	methods: {
		precargarFechas(){
			moment.locale('es');
			const currentDate = moment();
			const weekStart = currentDate.clone().startOf('week').format('YYYY-MM-DD');;
			const weekEnd = currentDate.clone().endOf('week').format('YYYY-MM-DD');;
			this.$set(this.filtros,'fechaInicio',weekStart);
			this.$set(this.filtros,'fechaFin',weekEnd);
		},
		resetErrores() {
			this.$set(this.editedItem, "errorResponsable", null);
			this.$set(this.editedItem, "errorMecanicos", null);
			this.$set(this.editedItem, "errorFechaInicio", null);
			this.$set(this.editedItem, "errorFechaFin", null);
			this.erroresComprobaciones = [];
		},
		validateReal() {
			this.resetErrores();
			let hayErrores = false;

			if (this.editedItem.responsable == null) {
				this.$set(this.editedItem, "errorResponsable", "Requerido");
				hayErrores = true;
			}

			if (this.editedItem.tipo != 3) {
				if (
					this.editedItem.mecanicos == null ||
					this.editedItem.mecanicos?.length == 0
				) {
					this.$set(this.editedItem, "errorMecanicos", "Requerido");
					hayErrores = true;
				}
			}

			return !hayErrores;
		},
		reportarTiempoReal() {
			if (!this.validateReal()) return;

			this.reportingReal = true;

			const payload = {
				idMantenimientoCalendarioInspeccion:
					this.editedItem.idMantenimientoCalendarioInspeccion,
				idMantenimientoTarea: this.editedItem.idMantenimientoTarea,
				idResponsable: this.editedItem.responsable,
				responsable: this.editedItem.responsable,
				mecanicos: this.editedItem.mecanicos,
			};

			axios({
				url:
					this.editedItem.tipo != 3
						? "/Mantenimientos/IniciarTareaMantenimientoPreventivo"
						: "/Mantenimientos/IniciarInspeccion",
				method: this.editedItem.tipo != 3 ? "POST" : "PUT",
				data: payload,
			})
				.then(() => {
					this.reportingReal = false;
					this.cerrarDialogReportes();
					this.cerrarDialog();
					this.getDatos();
				})
				.catch((error) => {
					this.reportingReal = false;
					console.log(error);
				});
		},
		validateExtemporaneo(comprobaciones) {
			this.resetErrores();
			let hayErrores = false;

			if (this.editedItem.responsable == null) {
				this.$set(this.editedItem, "errorResponsable", "Requerido");
				hayErrores = true;
			}

			if (this.editedItem.tipo != 3) {
				if (
					this.editedItem.mecanicos == null ||
					this.editedItem.mecanicos?.length == 0
				) {
					this.$set(this.editedItem, "errorMecanicos", "Requerido");
					hayErrores = true;
				}
			}

			if (this.editedItem.fechaInicio == null) {
				this.$set(this.editedItem, "errorFechaInicio", "Requerido");
				hayErrores = true;
			}

			if (this.editedItem.fechaFin == null) {
				this.$set(this.editedItem, "errorFechaFin", "Requerido");
				hayErrores = true;
			}

			if (Array.isArray(comprobaciones)) {
				this.erroresComprobaciones = comprobaciones.slice();
			}

			return !hayErrores && this.erroresComprobaciones.length == 0;
		},
		reportarExtemporaneo() {
			const item = this.$refs.checklist.terminarTarea();
			if (!this.validateExtemporaneo(item)) return;

			this.reportingExtemporaneo = true;

			const payload = {
				idMantenimientoTarea: this.editedItem.idMantenimientoTarea,
				responsable: this.editedItem.responsable,
				mecanicos: this.editedItem.mecanicos,
				inicio: this.editedItem.fechaInicio,
				fin: this.editedItem.fechaFin,
				recomendacion: this.editedItem.recomendacion,
				idMantenimientoCalendarioInspeccion:
					this.editedItem.idMantenimientoCalendarioInspeccion,
				fechaInicioRealizo: this.editedItem.fechaInicio,
				fechaFinRealizo: this.editedItem.fechaFin,
				idResponsable: this.editedItem.responsable,
				comprobaciones: item.comprobaciones,
			};

			axios({
				url:
					this.editedItem.tipo != 3
						? "/Mantenimientos/Completado"
						: "/Mantenimientos/InspeccionExtemporanea",
				method: this.editedItem.tipo != 3 ? "POST" : "PUT",
				data: payload,
			})
				.then(() => {
					this.reportingExtemporaneo = false;
					this.cerrarDialogReportes();
					this.$refs.checklist.resetComprobaciones();
					this.cerrarDialog();
					this.getDatos();
				})
				.catch((error) => {
					this.reportingExtemporaneo = false;
					console.log(error);
				});
		},
		resetErrorFecha(item, key, errorKey) {
			if (item[key] != null) {
				this.$set(item, errorKey, null);
			}
		},
		nombrePersona: ({ nombre, paterno, materno }) =>
			`${nombre ? `${nombre} ` : ""}${paterno ? `${paterno} ` : ""}${
				materno ? `${materno} ` : ""
			}`.trim(),
		maquinaNombre: ({ marca, modelo }) => `${marca} - ${modelo}`.trim(),
		getNombreMantenimiento: ({ codigo, nombre }) =>
			`[${codigo}] - ${nombre}`.trim(),
		nombreMaquina: ({ codigo, marca, model }) =>
			`[${codigo}] - ${marca} ${model}`.trim(),
		cerrarDialogReportes() {
			this.dialogReporteExtemporaneo = false;
			this.dialogReporteTiempoReal = false;
			this.$nextTick(() => {
				this.editedItem = {};
				this.resetErrores();
			});
		},
		onClickReporteExtemporaneo(item) {
			this.editedItem = Object.assign({}, item);
			this.dialogReporteExtemporaneo = true;
			this.compExtKey++;
			this.$nextTick(() => {
				if (item.tipo != 3) {
					this.$refs.checklist.terminarMantenimiento(
						item.idMantenimientoTarea
					);
				} else {
					this.$refs.checklist.terminarInspecciones(
						null,
						item.idMantenimientoCalendarioInspeccion
					);
				}
			});
		},
		onClickReporteTiempoReal(item) {
			this.editedItem = Object.assign({}, item);
			this.dialogReporteTiempoReal = true;
		},
		textoEstadoDetalle(estado){
			switch (estado) {
				case 1:
					return "Pendiente";
				case 2:
					return "En Progreso";
				case 3:
					return "Finalizado";
				default:
					return "N/D";
			}
		},
		getTextTipo(tipo) {
			switch (tipo) {
				case 1:
					return "Preventivo";
				case 2:
					return "Correctivo";
				case 3:
					return "Inspección";
				default:
					return "N/A";
			}
		},
		nombreSubdepto: ({ codigo, nombre }) =>
			codigo ? `[${codigo}] - ${nombre}`.trim() : nombre.trim(),
		customBackgroundColor(fecha) {
			return fecha == this.diaActual ? "today-background-color" : "";
		},
		cerrarDialog() {
			this.dialogDetalles = false;
			this.$nextTick(() => {
				this.detallesItem = JSON.parse(
					JSON.stringify(this.defaultDetallesItem)
				);
			});
		},
		verDetalles(maquina, operacion) {
			this.detallesItem.idMaquinaInstancia = maquina.idMaquinaInstancia;
			this.detallesItem.marca = maquina.marca;
			this.detallesItem.model = maquina.model;
			this.detallesItem.codigo = maquina.codigo;
			this.detallesItem.fecha = operacion.fecha;
			this.detallesItem.detalles = operacion.detalles.slice();
			this.dialogDetalles = true;
		},
		textoEstado(estado){
			switch (estado) {
				case 2:
					return "En Progreso";
				case 3:
					return "Finalizadas";
				case 4:
					return "Pendientes con retraso"
				default:
					return "Pendientes";
			}
		},
		colorEstado(estado) {
			switch (estado) {
				case 2:
					return "orange";
				case 3:
					return "success";
				case 4:
					return "red";
				default:
					return;
			}
		},
		descargarReporte() {
			var datetime = moment().format("YYYYMMDDHHmmss");
			axios({
				url: `/Mantenimientos/XlsxCalendarioMantenimiento`,
				params: {
					...this.filtros,
					token: this.token,
				},
				paramsSerializer: (params) =>
					qs.stringify(params, { indices: false }),
				method: "GET",
				responseType: "blob",
			})
				.then((response) => {
					let blob = new Blob([response.data], {
						type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					});
					let a = document.createElement("a");
					let blobURL = URL.createObjectURL(blob);
					a.download = `calendario-mantenimientos-${datetime}.xlsx`;
					a.href = blobURL;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		initialize() {
			this.getAreas();
			this.getSubdepartamentos();
			this.getCategorias();
			this.getMaquinas();
			this.getPersonas();
			this.getDatos();
		},
		getPersonas() {
			axios
				.get("/Personas/Operadores")
				.then((response) => {
					this.personas = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getCategorias() {
			this.loadingCategorias = true;
			axios
				.get("/MaquinaCategorias")
				.then((response) => {
					this.categorias = response.data;
				})
				.catch(console.log)
				.finally(() => {
					this.loadingCategorias = false;
				});
		},
		getMaquinas() {
			this.loadingMaquinas = true;
			axios
				.get("/Maquinas")
				.then((response) => {
					this.maquinas = response.data;
				})
				.catch(console.log)
				.finally(() => {
					this.loadingMaquinas = false;
				});
		},
		getAreas() {
			this.loadingAreas = true;

			axios
				.get("/Areas")
				.then((response) => {
					this.areas = response.data.filter(
						(area) => area.idArea > 1
					);
				})
				.catch(console.log)
				.finally(() => {
					this.loadingAreas = false;
				});
		},
		getSubdepartamentos() {
			this.loadingSubdeptos = true;
			axios
				.get("/Subdepartamento")
				.then((response) => {
					this.subdepartamentos = response.data;
				})
				.catch(console.log)
				.finally(() => {
					this.loadingSubdeptos = false;
				});
		},
		getDatos() {
			if (
				this.filtros.fechaInicio == null ||
				this.filtros.fechaFin == null
			)
				return;

			this.loading = true;

			axios
				.get("/Mantenimientos/CalendarioMantenimiento", {
					params: this.filtros,
					paramsSerializer: (params) =>
						qs.stringify(params, { indices: false }),
				})
				.then((response) => {
					this.headerFechas = response.data.fechas;
					this.maquinasInstancias = response.data.maquinasInstancias;
				})
				.catch(console.log)
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>
<style scoped>
.custom-max-header >>> .v-data-table__wrapper {
	max-height: 600px;
}

.preview-container {
	height: calc(100vh - 21.875rem);
}

.vertical-text {
	writing-mode: vertical-rl;
	transform: rotate(180deg);
	text-align: left;
	min-height: min-content;
	white-space: nowrap;
}

.text-th {
	font-size: 1.0625rem !important;
}

.today-background-color {
	background-color: #fff59d !important;
}

table th + th {
	border-left: 1px solid #dddddd;
}
table td + td {
	border-left: 1px solid #dddddd;
}
</style>