import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"persistent":"","width":"45%"},model:{value:(_vm.erroresSimulacionDialog),callback:function ($$v) {_vm.erroresSimulacionDialog=$$v},expression:"erroresSimulacionDialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Errores de Configuración de Simulación")])]),_c(VCardText,[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"items":_vm.validacionItems,"headers":_vm.headersErroresValidacion,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.indice",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.error",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item)+" ")]}}],null,true)})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.erroresSimulacionDialog = false}}},[_vm._v(" Cerrar ")])],1)],1)],1),_c(VSlideYTransition,[(_vm.loadingValidation || !_vm.validationSuccess)?_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,[_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VAlert,{attrs:{"dense":"","color":_vm.alertColor,"outlined":""}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"grow"},[(_vm.loadingValidation)?_c('p',{staticClass:"mb-0"},[(_vm.loadingValidation)?_c(VIcon,{attrs:{"large":"","color":"primary"}},[_vm._v("mdi-cube-scan")]):(
											!_vm.loadingValidation &&
											!_vm.validationSuccess
										)?_c(VIcon,{attrs:{"large":"","color":"error"}},[_vm._v("mdi-alert")]):_vm._e(),_vm._v(" Se está realizando la "),_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"14px !important"}},[_vm._v("validación")]),_vm._v(" de la configuración de la "),_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"14px !important"}},[_vm._v("simulación")]),_vm._v(". ")],1):(
										!_vm.loadingValidation &&
										!_vm.validationSuccess
									)?_c('p',{staticClass:"mb-0"},[_c(VIcon,{attrs:{"large":"","color":"red"}},[_vm._v("mdi-alert")]),_vm._v(" Se han detectado "),_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"14px !important"}},[_vm._v("errores")]),_vm._v(" de configuración que impiden "),_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"14px !important"}},[_vm._v("realizar la simulación")]),_vm._v(". ")],1):_vm._e()]),_c(VCol,{staticClass:"shrink"},[_c(VBtn,{staticClass:"ma-2",attrs:{"loading":_vm.loadingValidation,"disabled":_vm.loadingValidation,"color":"error"},on:{"click":function($event){_vm.erroresSimulacionDialog = true}}},[_vm._v(" Ver detalles ")])],1)],1)],1)],1)],1)],1):_vm._e()],1),_c(VTabs,{attrs:{"background-color":"transparent","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTab,{on:{"click":_vm.realizarSimulacionClick}},[_vm._v(" Realizar Simulación ")]),_c(VTab,{on:{"click":_vm.configSimulationClick}},[_vm._v(" Configuración ")]),_c(VTabsItems,{attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,{attrs:{"eager":""}},[_c('LanzarSimulacion',{ref:"launchSim",attrs:{"loadingValidation":_vm.loadingValidation,"validationSuccess":_vm.validationSuccess}})],1),_c(VTabItem,{attrs:{"eager":""}},[_c('ConfigSimulacion',{ref:"configSim",on:{"refreshValidation":_vm.validarSimulacion}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }