<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="6">
        <v-select
          v-model="anhio"
          :items="years"
          label="Indicar Año (Obligatorio)"
          hide-details
          @change="clear"
          :loading="loading1"
          @click:clear="anhio = -1; semana = -1;"
          clearable
        >
        </v-select>
      </v-col>
      <v-col cols="6">
        <v-autocomplete
          v-model="semana"
          :items="weeks"
          item-value="week"
          item-text="name"
          label="Indicar Semana (Obligatorio)"
          hide-details
          @change="getData"
          :loading="loading1"
          :disabled="anhio == -1"
          @click:clear="semana = -1;"
          clearable
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
			<v-col cols="4">
				<v-btn 
					text 
					color="primary" 
					class="my-0"
					:disabled="anhio==null || semana==null || anhio==-1 || semana==-1 || downloading"
					:loading="downloading"
					@click="descargarReporte()"
					>Descargar Reporte de<br />Líneas Críticas</v-btn
				>
			</v-col>
		</v-row>
    <v-row class="mt-0" v-if="(anhio!=null && semana!=null && anhio!=-1 && semana!=-1) && !loading2">
      <v-col cols="12" class="pt-0">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th></th>
                <th class="text-center" colspan="7">Daily</th>
                <th class="text-center" colspan="5">Weekly</th>
                <th class="text-center" colspan="7">Daily</th>
                <th class="text-center" colspan="5">Weekly</th>
                <th class="text-center">Daily</th>
                <th class="text-center">Weekly</th>
              </tr>
              <tr>
                <th class="text-center">
                  Name
                </th>
                <th class="text-center">
                  % D.T. Monday
                </th>
                <th class="text-center">
                  % D.T. Tuesday
                </th>
                <th class="text-center">
                  % D.T. Wednesday
                </th>
                <th class="text-center">
                  % D.T. Thursday
                </th>
                <th class="text-center">
                  % D.T. Friday
                </th>
                <th class="text-center">
                  % D.T. Saturday
                </th>
                <th class="text-center">
                  % D.T. Sunday
                </th>
                <th class="text-center">
                  % W.D.T Acumulado
                </th>
                <th class="text-center">
                  % DT W Materiales
                </th>
                <th class="text-center">
                  % DT W Mntto.
                </th>
                <th class="text-center">
                  % DT W Manuf.
                </th>
                <th class="text-center">
                  % DT W Calidad.
                </th>
                <th class="text-center">
                  HR D.T. Monday
                </th>
                <th class="text-center">
                  HR D.T. Tuesday
                </th>
                <th class="text-center">
                  HR D.T. Wednesday
                </th>
                <th class="text-center">
                  HR D.T. Thursday
                </th>
                <th class="text-center">
                  HR D.T. Friday
                </th>
                <th class="text-center">
                  HR D.T. Saturday
                </th>
                <th class="text-center">
                  HR D.T. Sunday
                </th>
                <th class="text-center">
                  HR W.D.T. 
                </th>
                <th class="text-center">
                  HR D.T. W Materiales
                </th>
                <th class="text-center">
                  HR D.T. W Mntto.
                </th>
                <th class="text-center">
                  HR D.T. W Manuf.
                </th>
                <th class="text-center">
                  HR D.T. W Calidad.
                </th>
                <th class="text-center">
                  Aviability day
                </th>
                <th class="text-center">
                  Aviability week
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in lineasCriticas"
                :key="item.line"
              >
                <td class="text-center"> {{ item.line }} </td>
                <td class="text-center" :style="`${getBackgroundColor(item.perMonday)}`"> {{ item.perMonday.toFixed(2) }} </td>
                <td class="text-center" :style="`${getBackgroundColor(item.perTuesday)}`"> {{ item.perTuesday.toFixed(2) }} </td>
                <td class="text-center" :style="`${getBackgroundColor(item.perWednesday)}`"> {{ item.perWednesday.toFixed(2) }} </td>
                <td class="text-center" :style="`${getBackgroundColor(item.perThursday)}`"> {{ item.perThursday.toFixed(2) }} </td>
                <td class="text-center" :style="`${getBackgroundColor(item.perFriday)}`"> {{ item.perFriday.toFixed(2) }} </td>
                <td class="text-center" :style="`${getBackgroundColor(item.perSaturday)}`"> {{ item.perSaturday.toFixed(2) }} </td>
                <td class="text-center" :style="`${getBackgroundColor(item.perSunday)}`"> {{ item.perSunday.toFixed(2) }} </td>
                <td class="text-center" :style="`${getBackgroundColor(item.perWeek)}`"> {{ item.perWeek.toFixed(2) }} </td>
                <td class="text-center" :style="`${getBackgroundColor(item.perWeekMateriales)}`"> {{ item.perWeekMateriales.toFixed(2) }} </td>
                <td class="text-center" :style="`${getBackgroundColor(item.perWeekMantenimiento)}`"> {{ item.perWeekMantenimiento.toFixed(2) }} </td>
                <td class="text-center" :style="`${getBackgroundColor(item.perWeekManufactura)}`"> {{ item.perWeekManufactura.toFixed(2) }} </td>
                <td class="text-center" :style="`${getBackgroundColor(item.perWeekCalidad)}`"> {{ item.perWeekCalidad.toFixed(2) }} </td>
                <td :class="`text-center`"> {{ item.numMonday.toFixed(2) }} </td>
                <td :class="`text-center`"> {{ item.numTuesday.toFixed(2) }} </td>
                <td :class="`text-center`"> {{ item.numWednesday.toFixed(2) }} </td>
                <td :class="`text-center`"> {{ item.numThursday.toFixed(2) }} </td>
                <td :class="`text-center`"> {{ item.numFriday.toFixed(2) }} </td>
                <td :class="`text-center`"> {{ item.numSaturday.toFixed(2) }} </td>
                <td :class="`text-center`"> {{ item.numSunday.toFixed(2) }} </td>
                <td :class="`text-center`"> {{ item.numWeek.toFixed(2) }} </td>
                <td :class="`text-center`"> {{ item.numWeekMateriales.toFixed(2) }} </td>
                <td :class="`text-center`"> {{ item.numWeekMantenimiento.toFixed(2) }} </td>
                <td :class="`text-center`"> {{ item.numWeekManufactura.toFixed(2) }} </td>
                <td :class="`text-center`"> {{ item.numWeekCalidad.toFixed(2) }} </td>
                <td :class="`text-center`"> {{ item.aviabilityDay }} </td>
                <td :class="`text-center`"> {{ item.aviabilityWeek }} </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="12">
        <apexchart type="bar" height="350" :options="chartOptions1" :series="series1"></apexchart>
      </v-col>
      <v-col cols="12">
        <apexchart type="bar" height="350" :options="chartOptions2" :series="series2"></apexchart>
      </v-col>
    </v-row>
    <v-row v-if="loading2">
      <v-col class="text-center" cols="12" md="12" sm="12">
        <h1>Cargando</h1>
      </v-col>
      <v-col class="text-center" cols="12" md="12" sm="12">
        <v-progress-circular
          :size="140"
          :width="7"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row v-if="(anhio==null || semana==null || anhio==-1 || semana==-1) && !loading2" class="mb-2">
			<v-col cols="12">
				<v-alert text dense color="blue darken-2" class="mb-0">
					<div class="d-flex align-center justify-center py-3">
						<p class="mb-0" style="font-size: 16px !important">
							Debe indicar el Año y la Semana
						</p>
					</div>
				</v-alert>
			</v-col>
		</v-row>
  </v-col>
</template>

<script>
import axios from "axios"
import { mapState } from 'vuex';
import moment from 'moment';

export default {
  data: () => ({
    anhios: [],
    anhio: -1,
    semana: -1,
    chartOptionsDefault: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: '% Percentage'
        },
        labels: {
          formatter: function (val) {
            return val.toFixed(2)+"%";
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val.toFixed(2) +"%";
          }
        }
      }
    },
    chartOptions1: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: '% Percentage'
        },
        labels: {
          formatter: function (val) {
            return val.toFixed(2) +"%";
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val.toFixed(2) +"%";
          }
        }
      }
    },
    chartOptions2: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: '% Percentage'
        },
        labels: {
          formatter: function (val) {
            return val.toFixed(2) +"%";
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val.toFixed(2) +"%";
          }
        }
      }
    },
    series1: [],
    series2: [],
    lineasCriticas: [],
    loading1: false,
    loading2: false,
    downloading: false,
  }),
  computed:{
    ...mapState(["token"]),
    years() {
			return this.anhios == null ? [] : this.anhios.map((y) => y.year).reverse();
		},
    weeks() {
			if (this.anhio == null) {
				this.semana = null;
				return [];
			}

			const result = this.anhios.filter(
				(y) => y.year == this.anhio
			);
			return result.length == 1 ? result[0].weeks.reverse() : [];
		},

    /*
        getSemanas(){
      var idx = this.anhios.findIndex(a => a.year == this.anhio);
      if(idx == -1) return [];
      else return this.anhios.filter(x => x.year == this.anhio)[0].weeks
    },
    */
  },
  mounted(){
    this.getHeaders();
  },
  methods:{
    getBackgroundColor(value){
      if(value < 4) return "background-color: #81C784";
      else if(value > 5) return "background-color: #E57373";
      else return "background-color: #FFF176";
		},
    descargarReporte(){
      var datetime = moment().format('YYYYMMDDHHmmss');
      const filename = 'reporte-lineascriticas-'+datetime+'.xlsx';
      const mime = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      this.downloading = true;
      axios({
        url: `/AnaliticaMonterrey/XlsxReporteLineasCriticas/${this.anhio}/${this.semana}?access_token=${this.token}`,
        method: 'GET',
        responseType: 'blob'
      })
      .then(response => {
        let blob = new Blob([response.data], { type: mime })
        let a = document.createElement("a") 
        let blobURL = URL.createObjectURL(blob)
        a.download = filename
        a.href = blobURL
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        this.downloading = false;
      })
      .catch(error => {
        this.downloading = false;
        console.log(error);
      })
    },
    initialize(){
      this.getHeaders();
    },
    getHeaders(){
      this.loading1 = true;
      axios
      .get("/AnaliticaMonterrey/Headers")
      .then(response => {
        this.anhios = response.data.years;
        this.loading1 = false;
      })
      .catch(error => {
        console.log(error);
        this.loading1 = false;
      })
    },

    clear(){
      this.semana = -1;
    },
    colorBackground(limite, valor){
      if(valor < limite) return 'green lighten-3';
      else return 'red lighten-3';
    },
    colorText(limite, valor){
      if(valor < limite) return 'black--text';
      else return 'red--text text--darken-4';
    },
    getData(){
      if(this.anhio <= 0 || this.semana <= 0) return;
      this.loading2 = true;
      axios
      .get(`/AnaliticaMonterrey/ReporteLineasCriticas/${this.anhio}/${this.semana}`)
      .then(response => {
        this.lineasCriticas = response.data.lineasCriticas;
        this.chartOptions1 = Object.assign({},this.chartOptionsDefault);
        this.chartOptions1.xaxis.categories = response.data.categorias;
        this.chartOptions2 = Object.assign({},this.chartOptionsDefault);
        this.chartOptions2.xaxis.categories = response.data.categorias;
        this.series1 = response.data.series1;
        this.series2 = response.data.series2;
        this.loading2 = false;
      })
      .catch(error => {
        console.log(error);
        this.loading2 = false;
      })
    }

  },

}
</script>