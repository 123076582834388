<template>
	<div>
		<v-tabs v-model="tab" background-color="transparent" grow centered>
			<v-tab href="#tab-1" @click="updateSemanal"> Semanal </v-tab>

			<v-tab href="#tab-2" @click="updateMensual"> Mensual </v-tab>

			<v-tab href="#tab-3" @click="updateComparativa"> Comparativo Mensual </v-tab>

			<v-tabs-items v-model="tab" touchless>
				<v-tab-item :key="1" :value="'tab-1'">
					<v-card flat>
						<v-card-text class="pa-0">
							<GraficasMMCall
								:selectores="selectores"
								:departamentos="departamentos"
								:areas="areas"
								:type="1"
								ref="graphweek"
							/>
						</v-card-text>
					</v-card>
				</v-tab-item>

				<v-tab-item :key="2" :value="'tab-2'">
					<v-card flat>
						<v-card-text class="pa-0">
							<GraficasMMCall
								:selectores="selectores"
								:departamentos="departamentos"
								:areas="areas"
								:type="2"
								ref="graphMonth"
							/>
						</v-card-text>
					</v-card>
				</v-tab-item>

				<v-tab-item :key="3" :value="'tab-3'">
					<v-card flat>
						<v-card-text class="pa-0">
							<ComparativoMensual
								:areas="areas"
								:departamentos="departamentos"
								ref="comparativa"
							/>
						</v-card-text>
					</v-card>
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>
	</div>
</template>


<script>
import axios from 'axios';
import GraficasMMCall from "./GraficasMMCall.vue";
import ComparativoMensual from './ComparativoMensual.vue';
export default {
	components: { GraficasMMCall, ComparativoMensual },
	data() {
		return {
			tab: 0,
			selectores: null,
			departamentos: [],
			areas: [],
		};
	},
	mounted(){
		this.initialize();
	},
	methods: {
		initialize(){
			this.getHeaders();
			this.getDepartamentos();
			this.getAreas();
		},
		updateComparativa(){
			if (typeof this.$refs.comparativa === "undefined") return;
			this.$refs.comparativa.resetValues(true);
			this.$refs.comparativa.initialize();
		},
		updateSemanal() {
			if (typeof this.$refs.graphweek === "undefined") return;
			this.$refs.graphweek.initialize();
			this.$refs.graphweek.resetValues();
		},
		updateMensual() {
			if (typeof this.$refs.graphMonth === "undefined") return;
			this.$refs.graphMonth.resetValues();
		},
		getAreas(){
			axios
				.get('/CausaSolucionRecords/ListarLineaAreas')
				.then( response => {
					this.areas = response.data
				})
				.catch( error => {
					console.log(error);
				});
		},
		getDepartamentos() {
			axios
				.get("/CausaSolucionRecords/ListarDepartamento")
				.then((response) => {
					this.departamentos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getHeaders() {
			axios
				.get("/AnaliticaMonterrey/Headers")
				.then((response) => {
					this.selectores = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>