<template>
	<v-col cols="12">
		<v-row>
			<v-col cols="6">
				<v-select
					v-model="filtros.year"
					label="Indicar Año (Obligatorio)"
					:items="years"
					hide-details
					:disabled="loadingSelectores || loading"
					:loading="loadingSelectores"
					clearable
					@change="changeYear()"
				></v-select>
			</v-col>
			<v-col cols="6">
				<v-autocomplete
					v-model="filtros.week"
					label="Indicar Semana (Obligatorio)"
					:items="weeks"
					item-text="name"
					item-value="week"
					hide-details
					clearable
					:loading="loadingSelectores"
					:disabled="filtros.year == null || loading"
					@change="
						changeWeek();
						getDatosDiarios();
					"
				></v-autocomplete>
			</v-col>
		</v-row>
		<v-row :class="datosDiarios == null && 'mb-2'">
			<v-col cols="4">
				<v-btn 
					text 
					color="primary" 
					class="my-0"
					:disabled="filtros.year==null || filtros.week==null || downloading"
					:loading="downloading"
					@click="descargarTabla()"
					>Descargar Tabla de<br />Datos Diarios</v-btn
				>
			</v-col>
		</v-row>
		<v-row v-if="datosDiarios != null && loading == false">
			<v-col cols="12">
				<v-simple-table>
					<template v-slot:default>
						<thead :key="`head-${idx}`">
							<tr>
								<th
									v-for="(head, idx) in headers.head1"
									:key="`head1-${idx}`"
									class="text-center text-subtitle-2"
									:style="head.color != null && head.color"
								>
									{{ head.text }}
								</th>
							</tr>
							<tr>
								<th
									v-for="(head, idx) in headers.head2"
									:key="`head2-${idx}`"
									class="text-center text-subtitle-2"
									:style="head.color != null && head.color"
								>
									{{ head.text }}
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(row, idx) in datosDiarios.rows"
								:key="`tr-${idx}`"
							>
								<td
									v-for="(key, idx2) in getKeysRow(row)"
									class="text-center"
									:style="`background-color: ${getBackgroundColor(row, key)}`"
									:key="`td-${idx2}`"
								>
									<template v-if="idx2 > 0">
										<div :class="row.subLine ? '' : 'text-body-2 font-weight-black'">
											{{
												idx2 > 0
													? row[key].toFixed(2)
													: row[key]
											}}
										</div>
									</template>
									<template v-else>
										<template v-if="row.subLine">
											<div class="text-caption">{{ row[key] }}</div>
										</template>
										<template v-else>
											<div class="text-body-2 font-weight-black">{{ row[key] }}</div>
										</template>
									</template>
									
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-col>
		</v-row>
		<v-row v-if="datosDiarios == null && loading == false" class="mb-2">
			<v-col cols="12">
				<v-alert text dense color="blue darken-2" class="mb-0">
					<div class="d-flex align-center justify-center py-3">
						<p class="mb-0" style="font-size: 16px !important">
							Debe indicar el Año y la Semana
						</p>
					</div>
				</v-alert>
			</v-col>
		</v-row>
		<v-row v-if="loading">
			<v-col cols="12" class="text-center">
				<h1 class="mt-4 text-center">Cargando</h1>
				<v-progress-circular
					:size="140"
					:width="7"
					color="primary"
					indeterminate
				></v-progress-circular>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import axios from "axios";
import moment from 'moment';
import { mapState } from 'vuex';

export default {
	data() {
		return {
			loading: false,
			loadingSelectores: false,
			filtros: {
				year: null,
				week: null,
			},
			datosDiarios: null,
			selectores: null,
			downloading: false,
			headers: {
				head1: [],
				head2: [
					{
						text: "LINE",
						color: "background-color: #FF9100",
					},
					{
						text: "% D.DT Monday",
						color: "background-color: #FFEA00",
					},
					{
						text: "% D.DT Tuesday",
						color: "background-color: #FFEA00",
					},
					{
						text: "% D.DT Wednesday",
						color: "background-color: #FFEA00",
					},
					{
						text: "% D.DT Thursday",
						color: "background-color: #FFEA00",
					},
					{
						text: "% D.DT Friday",
						color: "background-color: #FFEA00",
					},
					{
						text: "% D.DT Saturday",
						color: "background-color: #FFEA00",
					},
					{
						text: "% D.DT Sunday",
						color: "background-color: #FFEA00",
					},
					{
						text: "HR D.DT Monday",
						color: "background-color: #BDBDBD",
					},
					{
						text: "HR D.DT Tuesday",
						color: "background-color: #BDBDBD",
					},
					{
						text: "HR D.DT Wednesday",
						color: "background-color: #BDBDBD",
					},
					{
						text: "HR D.DT Thursday",
						color: "background-color: #BDBDBD",
					},
					{
						text: "HR D.DT Friday",
						color: "background-color: #BDBDBD",
					},
					{
						text: "HR D.DT Saturday",
						color: "background-color: #BDBDBD",
					},
					{
						text: "HR D.DT Sunday",
						color: "background-color: #BDBDBD",
					},
					{
						text: "HR W.DT",
						color: "background-color: #BF360C",
					},
					{
						text: "Aviability day",
						color: "background-color: #ffc000",
					},
					{
						text: "Aviability Week",
						color: "background-color: #BF360C",
					},
					{
						text: "% W.D.T",
						color: "background-color: #BF360C",
					},
				],
			},
		};
	},
	computed: {
		...mapState(["token"]),
		years() {
			return this.selectores == null
				? []
				: this.selectores.years.map((y) => y.year).reverse();
		},
		weeks() {
			if (this.filtros.year == null) {
				this.filtros.week = null;
				return [];
			}

			const result = this.selectores.years.filter(
				(y) => y.year == this.filtros.year
			);
			return result.length == 1 ? result[0].weeks.reverse() : [];
		},
	},
	mounted() {
		this.initialize();
	},
	methods: {
		getKeysRow(row){
			return Object.keys(row).filter(x => x.toLowerCase() != 'subline');
		},
		getBackgroundColor(row, key){
			if(key.slice(0,3) == "per"){
				if(row[key] < 4) return "#81C784";
				else if(row[key] > 5) return "#E57373";
				else return "#FFF176";
			}
		},
		descargarTabla(){
        var datetime = moment().format('YYYYMMDDHHmmss');
		const filename = 'tabla-datosdiarios-'+datetime+'.xlsx';
		const mime = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
		this.downloading = true;
		axios({
            url: `/AnaliticaMonterrey/XlsxTablaDatosDiarios/${this.filtros.year}/${this.filtros.week}?access_token=${this.token}`,
            method: 'GET',
            responseType: 'blob'
          })
          .then(response => {
            let blob = new Blob([response.data], { type: mime })
            let a = document.createElement("a") 
            let blobURL = URL.createObjectURL(blob)
            a.download = filename
            a.href = blobURL
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
			this.downloading = false;
          })
          .catch(error => {
			this.downloading = false;
            console.log(error);
          })
      },
		changeWeek() {
			if (this.filtros.week == null) this.resetValues();
		},
		changeYear() {
			this.filtros.week = null;
			this.resetValues();
		},
		initialize() {
			this.getHeaders();
			this.getDatosDiarios();
		},
		getHeaders() {
			this.loadingSelectores = true;
			axios
				.get("/AnaliticaMonterrey/Headers")
				.then((response) => {
					this.selectores = response.data;
					this.loadingSelectores = false;
				})
				.catch((error) => {
					this.loadingSelectores = false;
					console.log(error);
				});
		},
		resetValues() {
			this.loading = false;
			this.headers.head1 = [];
			this.datosDiarios = null;
		},
		getDatosDiarios() {
			if (this.filtros.year == null || this.filtros.week == null) return;
			this.loading = true;
			axios
				.get(
					`/AnaliticaMonterrey/TablaDatosDiarios/${this.filtros.year}/${this.filtros.week}`
				)
				.then((response) => {
					const keys = Object.keys(response.data).slice(
						0,
						Object.keys(response.data).length - 1
					);
					const dias = [
						{
							text: "# Week",
							color: "background-color: black; color: white",
						},
					];
					for (let i = 0; i < 2; i++) {
						keys.forEach((key) => {
							dias.push({
								text: response.data[key],
								color: null,
							});
						});
						if (i == 1) {
							dias.push(
								{},
								{},
								{},
								{
									text: this.filtros.week,
									color: "background-color: black; color: white",
								}
							);
						}
					}
					this.headers.head1 = dias;
					this.datosDiarios = response.data;
					this.loading = false;
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
	},
};
</script>