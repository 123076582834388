<template>
	<div>
		<v-tabs v-model="tab" background-color="transparent" grow>
			<v-tab
				v-tooltip.bottom="modoAyuda && $help.calidad.catalogoIncidencias.motivos"
				v-if="permisoVista('parotipos','r')"
				@click="tiposClick"
			>
				Motivos de Incidentes
			</v-tab>
			<v-tab
				v-tooltip.bottom="modoAyuda && $help.calidad.catalogoIncidencias.submotivos"
				v-if="permisoVista('subparotipos','r')"
				@click="subtiposClick"
			>
				Submotivos de Incidentes
			</v-tab>

			<v-tabs-items v-model="tab" touchless>
				<v-tab-item
					v-if="permisoVista('parotipos','r')"
					eager
				>
					<ParoTipos ref="tipos" />
				</v-tab-item>
				<v-tab-item
					v-if="permisoVista('subparotipos','r')"
					eager
				>
					<SubParoTipos ref="subtipos" />
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import ParoTipos from './Paros/ParoTipos.vue';
import SubParoTipos from './Paros/SubParoTipos.vue';

export default {
	components: {
		ParoTipos,
		SubParoTipos
	},
	data: () => ({
		tab: 0,
	}),
	mounted:{
		...mapState(["modoAyuda"])
	},
	methods: {
		// cambio(event) {
		// 	if (this.tab == 0) this.$refs.tipos.cambio(event);
		// 	if (this.tab == 1) this.$refs.subtipos.cambio(event);
		// },
		initialize() {
			// this.tiposClick();
		},
		tiposClick() {
			if(typeof this.$refs.tipos === 'undefined') return;
			this.$refs.tipos.initialize();
		},
		subtiposClick() {
			if(typeof this.$refs.subtipos === 'undefined') return;
			this.$refs.subtipos.initialize();
		},
	},
};
</script>