<template>
	<v-col cols="12" class="px-8" v-if="permisoVista('dashboard','r')">
		<v-row>
			<v-col cols="3">
				<v-autocomplete
					v-model="filtros.idArea"
					:items="areas"
					item-text="nombre"
					item-value="idArea"
					label="Área"
					hide-details
					clearable
					@change="obtenerDatos"
					:disabled="cargando"
				></v-autocomplete>
			</v-col>
			<v-col cols="3">
				<v-autocomplete
					v-model="filtros.idArticuloFamilia"
					:items="familias"
					item-text="nombre"
					item-value="idArticuloFamilia"
					label="Familia de Productos"
					hide-details
					clearable
					@change="obtenerDatos"
					:disabled="cargando"
				></v-autocomplete>
			</v-col>
			<v-col cols="3">
				<datePicker
					v-model="filtros.fecha"
					format="YYYY-MM-DD"
					label="Fecha"
					clearable
					maxToday
					@input="obtenerDatos"
					:disabled="cargando"
				></datePicker>
			</v-col>
			<v-col cols="3">
				<v-autocomplete
					v-model="filtros.idTurno"
					:items="turnos"
					item-text="nombre"
					item-value="idTurno"
					label="Turno"
					hide-details
					clearable
					@change="obtenerDatos"
					:disabled="cargando"
				></v-autocomplete>
			</v-col>
		</v-row>
		<v-row v-if="realizarPeticion" class="px-0 ma-0">
      <v-col cols="12" class="ma-0 text-right text-subtitle-2">
        Proxima Actualización en {{tiempo}}
      </v-col>
    </v-row>
		<v-row class="justify-center ma-0 pa-0">
			<v-col cols="3" md="3" sm="3">
				<v-card class="fill-height d-flex" color="#2a6099" dark>
					<v-card-text
						class="text-center align-self-center white--text"
					>
						<div class="subtitle-1 font-weight-bold">
							Disponibilidad
						</div>
						<ICountUp
							:delay="1000"
							:endVal="this.informacion.disponibilidad * 100"
							class="subtitle-1 font-weight-medium"
							:options="{
                decimalPlaces: 2,
                decimal: '.',
								suffix: '%'
              }"
						/>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="2" md="2" sm="2">
				<v-card class="fill-height d-flex" color="#2a6099" dark>
					<v-card-text
						class="text-center align-self-center white--text"
					>
						<div class="subtitle-1 font-weight-bold">
							Rendimiento
						</div>
						<ICountUp
							:delay="1000"
							:endVal="this.informacion.rendimiento * 100"
							class="subtitle-1 font-weight-medium"
							:options="{
                decimalPlaces: 2,
                decimal: '.',
								suffix: '%'
              }"
						/>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="2" md="2" sm="2">
				<v-card class="fill-height d-flex" color="#2a6099" dark>
					<v-card-text
						class="text-center align-self-center white--text"
					>
						<div class="subtitle-1 font-weight-bold">Calidad</div>
						<ICountUp
							:delay="1000"
							:endVal="this.informacion.calidad * 100"
							class="subtitle-1 font-weight-medium"
							:options="{
                decimalPlaces: 2,
                decimal: '.',
								suffix: '%'
              }"
						/>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="2" md="2" sm="2">
				<v-card class="fill-height d-flex" color="#2a6099" dark>
					<v-card-text
						class="text-center align-self-center white--text"
					>
						<div class="subtitle-1 font-weight-bold">OEE</div>
						<ICountUp
							:delay="1000"
							:endVal="this.informacion.oee * 100"
							class="subtitle-1 font-weight-medium"
							:options="{
                decimalPlaces: 2,
                decimal: '.',
								suffix: '%'
              }"
						/>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="2" md="2" sm="2">
				<v-card class="fill-height d-flex" color="#2a6099" dark>
					<v-card-text
						class="text-center align-self-center white--text"
					>
						<div class="subtitle-1 font-weight-bold">TPE</div>
						<ICountUp
							:delay="1000"
							:endVal="this.informacion.pt * 100"
							class="subtitle-1 font-weight-medium"
							:options="{
                decimalPlaces: 2,
                decimal: '.',
								suffix: '%'
              }"
						/>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-simple-table>
					<template v-slot:default>
						<thead style="background-color: #bdbdbd !important">
							<tr>
								<th class="text-center celdaHeader">Orden</th>
								<th class="text-center celdaHeader"># Parte</th>
								<th class="text-center celdaHeader">
									Descripción
								</th>
								<th class="text-center celdaHeader">
									Piezas Programadas
								</th>
								<th class="text-center celdaHeader">
									Total Piezas OK
								</th>
								<th class="text-center celdaHeader">
									Total Piezas No OK
								</th>
								<th class="text-center celdaHeader">
									Total Ensamblado
								</th>
								<th class="text-center celdaHeader">
									Tiempo de Operación
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(item, index) in tabla"
								:key="`${item.id}-${index}`"
							>
								<td class="text-center celdaBorde">{{ index + 1 }}</td>
								<td class="text-center celdaBorde">
									{{ item.codigoArticulo }}
								</td>
								<td
									class="text-center white--text"
									style="
										background-color: #2a6099 !important;
										border-bottom: 3px solid white !important;
									"
								>
									<strong>{{ item.nombreArticulo }}</strong>
								</td>
								<td class="text-center celdaBorde">
									<number-input
										:value="item.piezasProgramadas"
                    controls
                    inline
                    size="small"
                    min="0"
                    center
                    @change="cambioPiezasProgramadas($event,item)"
                  ></number-input>
								</td>
								<td class="text-center celdaBorde">{{ item.piezasOK }}</td>
								<td class="text-center celdaBorde">
									{{ item.piezasNoOK }}
								</td>
								<td class="text-center celdaBorde">
									{{ item.totalEnsamblado }}
								</td>
								<td class="text-center celdaBorde">
									{{ $utils.strings.toTime(item.tiempoOperacion) }}
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-col>
		</v-row>
		<v-row>
			<!-- <v-col cols="12"> -->
			<v-expansion-panels multiple>
				<v-expansion-panel>
					<v-expansion-panel-header>Tiempo de Paros</v-expansion-panel-header>
					<v-expansion-panel-content>
						<apexchart v-if="series.length > 0" type="pie" height="350" :options="chartOptions" :series="series" ref="chart"></apexchart>
						<v-simple-table>
							<template v-slot:default>
								<thead>
									<tr>
										<th class="text-center">
											Orden
										</th>
										<th
											v-for="(paro, index) in tipoParos"
											:key="`tiparo-${index}`"
											class="text-center"
										>
											{{ paro }}
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(item, index) in tabla2"
										:key="`item-${index}`"
										class="text-center"
									>
										<td class="text-center">{{index +1}}</td>
										<td
											v-for="(subitem, index) in item"
											:key="`sub-${index}`"
											class="text-center"
										>
											{{ $utils.strings.toTime(subitem) }}
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<v-expansion-panel>
					<v-expansion-panel-header>OEE Reporte de Producción</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-simple-table>
							<template v-slot:default>
								<thead>
									<tr>
										<th class="text-center"></th>
										<th colspan="3" class="text-center">
											<strong>Resultados de Eficiencia</strong>
										</th>
										<th colspan="4" class="text-center">
											<strong>Resultados de Calidad</strong>
										</th>
										<th class="text-center">
										</th>
									</tr>
									<tr>
										<th class="text-center">Orden</th>
										<th class="text-center">
											Total de Piezas Producidas (Pza)
										</th>
										<th class="text-center">
											Total de Piezas Teóricas (Pza)
										</th>
										<th class="text-center">
											<strong>Eficiencia (%)</strong>
										</th>
										<th class="text-center">
											Total de Piezas Ok
										</th>
										<th class="text-center">
											Total de Piezas NOk
										</th>
										<th class="text-center">
											Procentaje de Scrap (%)
										</th>
										<th class="text-center">
                      <strong>Calidad (%)</strong>
                    </th>
										<th class="text-center">
                      <strong>OEE</strong>
                    </th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(item, index) in tabla3"
										:key="`${item.ot}-${index}`"
									>
										<td class="text-center">{{ index + 1 }}</td>
										<td class="text-center">{{ item.totalPiezasProducidas }}</td>
										<td class="text-center">{{ item.totalPiezasTeoricas }}</td>
										<td class="text-center">{{ (item.eficiencia * 100).toFixed(2) }} %</td>
										<td class="text-center">{{ item.piezasOk }}</td>
										<td class="text-center">{{ item.piezasNoOk }}</td>
										<td class="text-center">{{ (item.scrap * 100).toFixed(2) }} %</td>
										<td class="text-center">{{ (item.calidad * 100).toFixed(2) }} %</td>
										<td class="text-center">{{ (item.oee * 100).toFixed(2) }} %</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<v-expansion-panel>
					<v-expansion-panel-header>Cumplimiento al Programa de Producción</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-simple-table>
							<template v-slot:default>
								<thead>
									<tr>
										<th colspan="6" class="text-center">
                      <strong>Resultados Generales de Cumplimiento</strong>
                    </th>
                  </tr>
									<tr>
										<th class="text-center">Orden</th>
										<th class="text-center">
											Número de Parte
										</th>
										<th class="text-center">
											Total de Piezas Programadas
										</th>
										<th class="text-center">
											Piezas Entregadas OK
										</th>
										<th class="text-center">
											Cumplimiento Real OK
										</th>
										<th class="text-center">
											Cumplimiento Mix (PT)
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(item, index) in tabla4"
										:key="`${item.ot}-${index}`"
									>
										<td class="text-center">{{ index +1 }}</td>
										<td class="text-center">{{ item.numeroDeParte }}</td>
                    <td class="text-center">{{ item.piezasProgramadas }}</td>
                    <td class="text-center">{{ item.piezasOk }}</td>
                    <td class="text-center">{{ item.cumplimientoOk }}</td>
                    <td class="text-center">{{ (item.pt * 100).toFixed(2) }} %</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
			<!-- </v-col> -->
		</v-row>
	</v-col>
</template>

<script>
import axios from "axios";
import ICountUp from "vue-countup-v2";

export default {
	components: {
		ICountUp,
	},
	props: {
		active: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
    tabla4(){
      return this.informacion.cumplimientoProgramas;
    },
		tabla3() {
			return this.informacion.oeeReportes;
		},
		tabla2() {
			return this.informacion.tiempoParos;
		},
		tabla() {
			return this.informacion.articuloDetalles;
		},
		tipoParos(){
			return this.informacion.motivoParos;
		},
		realizarPeticion() {
			return (
				this.filtros.idArea != null &&
				this.filtros.idArticuloFamilia != null &&
				this.filtros.fecha != null
			);
		},
		url() {
			var url = `/OEEArticulo/OEEArticulo?IdArea=${this.filtros.idArea}&IdArticuloFamilia=${this.filtros.idArticuloFamilia}&Fecha=${this.filtros.fecha}`;
			if(this.$utils.isValid(this.filtros.idTurno)) url += `&IdTurno=${this.filtros.idTurno}`;
			return url;
		},
		tiempo(){
      return this.$utils.strings.toTime(this.segundos);
    }
	},
	data() {
		return {
			filtros: {
				idArea: null,
				idArticuloFamilia: null,
				idTurno: null,
				fecha: null,
			},
			disponibilidad: 0,
			interval: null,
			segundos: 60,
			cargando: false,
			areas: [],
			familias: [],
			turnos: [],
			informacion:{
				articuloDetalles: [],
				calidad: 0,
				cumplimientoProgramas: [],
				cumplimientoRealOk: 0,
				disponibilidad: 0,
				motivoParos: [],
				oee: 0,
				oeeReportes: [],
				piezasEntregadasOk: 0,
				piezasNoOk: 0,
				piezasOk: 0,
				piezasProducidad: 0,
				piezasProgramadas: 0,
				piezasTeoricas: 0,
				pt: 0,
				rendimiento: 0,
				scrap: 0,
				tiempoParos: []
			},
			defaultInformacion:{
				articuloDetalles: [],
				calidad: 0,
				cumplimientoProgramas: [],
				cumplimientoRealOk: 0,
				disponibilidad: 0,
				motivoParos: [],
				oee: 0,
				oeeReportes: [],
				piezasEntregadasOk: 0,
				piezasNoOk: 0,
				piezasOk: 0,
				piezasProducidad: 0,
				piezasProgramadas: 0,
				piezasTeoricas: 0,
				pt: 0,
				rendimiento: 0,
				scrap: 0,
				tiempoParos: [],
			},
			labels:[],
			series:[],
      chartOptions: {
        chart: {
          width: '100%',
          type: 'pie',
        },
        labels: [],
				legend: {
  			  position: 'bottom'
  			},
				tooltip: {
          shared: false,
          y: {
            formatter: function (val) {
              function zeroPrefix(num, digit) {
								if(digit <= 0) return '0';
								if(num > Math.pow(10,digit-1)) return `${num}`;

								var zero = '';
								for(var i = 0; i < digit; i++) {
									zero += '0';
								}
								return (zero + num).slice(-digit);
							}

							var horas = Math.trunc(val / 60);
							var minutos = val - (horas * 60);

							return (horas<= 9?zeroPrefix(horas,2):horas)+":"+zeroPrefix(minutos,2);
            }
          }
        }
      },
			defaultChartOptions: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: [],
				legend: {
  			  position: 'bottom'
  			},
				tooltip: {
          shared: false,
          y: {
            formatter: function (val) {
              function zeroPrefix(num, digit) {
								if(digit <= 0) return '0';
								if(num > Math.pow(10,digit-1)) return `${num}`;

								var zero = '';
								for(var i = 0; i < digit; i++) {
									zero += '0';
								}
								return (zero + num).slice(-digit);
							}

							var horas = Math.trunc(val / 60);
							var minutos = val - (horas * 60);

							return (horas<= 9?zeroPrefix(horas,2):horas)+":"+zeroPrefix(minutos,2);
            }
          }
        },
      }
		};
	},
	methods: {
		initialize() {
			this.getAreas();
			this.getFamilias();
			this.getTurnos();
		},
		getAreas() {
			axios
				.get("/Areas")
				.then((response) => {
					this.areas = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getFamilias() {
			axios
				.get("/ArticuloFamilias")
				.then((response) => {
					this.familias = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getTurnos() {
			axios
				.get("/Turnos")
				.then((response) => {
					this.turnos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		obtenerDatos() {
			if (!this.realizarPeticion || this.cargando) return;

			this.cargando = true;
			this.segundos = 60;

			axios
				.get(this.url)
				.then((response) => {
					this.informacion = response.data;
					this.series = response.data.grafica.series;
					this.chartOptions = Object.assign({},this.defaultChartOptions);
					this.chartOptions.labels = response.data.grafica.labels; 
					this.cargando = false;
					setTimeout(() => {
          	this.$refs.chart?.refresh();
        	},500);
				})
				.catch((error) => {
					this.cargando = false;
					this.informacion = Object.assign({},this.defaultInformacion);
					console.log(error);
				});
		},
		cambioPiezasProgramadas($event,item){
			if($event != item.piezasProgramadas){
				axios
				.post("/OEEArticulo/PiezaProgramada",{
					IdArea: this.filtros.idArea,
					IdTurno: this.filtros.idTurno,
					IdArticulo: item.idArticulo,
					Fecha: this.filtros.fecha,
					Cantidad: $event
				})
				.then(() => {
					this.obtenerDatos();
				})
				.catch(error => {
					console.log(error);
				})
			}
		},
	},
	mounted() {
		this.interval = setInterval(() => {
			if (!this.active) return;

			if(this.segundos == 0) this.obtenerDatos();
      else this.segundos -= 1;
		}, 1000);
		this.initialize();
	},
	destroyed() {
		clearInterval(this.interval);
	},
};
</script>
<style scoped>
.celdaBorde {
	border-bottom: 2px solid #2a6099 !important;
}
.celdaHeader {
	font-size: 16px !important;
	color: #1f1f1f !important;
}
/* .v-data-table::v-deep th {
	font-size: 16px !important;
	color: #1f1f1f !important;
} */
</style>