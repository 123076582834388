<template>
  <!-- <v-container> -->
    <v-row justify-sm="center">
      <v-card 
        class="mt-4"
        :width="anchoMaximo"
        fill
      >
        <v-tabs show-arrows v-model="tab" :background-color="colorTabs" centered dark icons-and-text>
          <v-tabs-slider></v-tabs-slider>

          <v-tab v-tooltip.bottom="modoAyuda && $help.seguridad.control" href="#tab-1" v-if="mostrarVista('control')" @click="updateControl()">
            Comando &<br>Control
            <v-icon>mdi-camera-metering-matrix</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.seguridad.puntosImpresion" href="#tab-2" @click="updatePuntosImpresion()">
            Puntos de<br>Impresión
            <v-icon>mdi-printer</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.seguridad.accesodispositivos" href="#tab-3" v-if="mostrarVista('accesodispositivos')" @click="updateAccesoDispositivos()">
            Acceso de<br>Dispositivos
            <v-icon>mdi-shield-key</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.seguridad.permisos" href="#tab-4"  v-if="mostrarVista('permisos')" @click="updatePermisos()">
            Permisos
            <v-icon>mdi-key</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.seguridad.logs" href="#tab-5" v-if="mostrarVista('logs')" @click="updateLogs()">
            Logs
            <v-icon>mdi-file</v-icon>
          </v-tab>

        </v-tabs>

        <v-tabs-items v-model="tab">

          <v-tab-item :key="1" :value="'tab-1'" v-if="mostrarVista('control')">
            <v-card flat color="gray">
              <v-card-text>
                <Control ref="cyc" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="2" :value="'tab-2'">
            <v-card flat color="gray">
              <v-card-text>
                <PuntosImpresion ref="printpoints" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="3" :value="'tab-3'" v-if="mostrarVista('accesodispositivos')">
            <v-card flat color="gray">
              <v-card-text>
                <seguridadAccesoDispositivo ref="accesodispositivos"></seguridadAccesoDispositivo>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="4" :value="'tab-4'" v-if="mostrarVista('permisos')">
            <v-card flat color="gray">
              <v-card-text>
                <seguridadRol ref="permisos"></seguridadRol>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="5" :value="'tab-5'"  v-if="mostrarVista('logs')">
            <v-card flat color="gray">
              <v-card-text>
                <seguridadLog ref="logs"></seguridadLog>
              </v-card-text>
            </v-card>
          </v-tab-item>

        </v-tabs-items>
      </v-card>
    </v-row>
  <!-- </v-container> -->
</template>

<script>
import rol from "./Seguridad/Rol.vue";
import log from "./Seguridad/Log.vue";
import accesodispositivo from "./Seguridad/AccesoDispositivo"
import Control from './Control.vue';
import { mapState } from 'vuex';
import PuntosImpresion from './Seguridad/PuntosImpresion.vue';

export default {
  data() {
    return {
      tab: null,
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    };
  },
  mounted(){
    this.leerParametros();
  },
  watch:{
    $route (to, from){
      this.leerParametros();
    }
  },
  methods:{
    leerParametros(){
      if (this.$route.query.tab !== undefined){
        this.tab = this.$route.query.tab;
        this.$router.push('/dashboard/seguridad')
      }
    },
    updatePuntosImpresion(){
      if(typeof this.$refs.printpoints === 'undefined') return;
      this.$refs.printpoints.initialize();
    },
    updatePermisos(){
      if(typeof this.$refs.permisos === 'undefined') return;
      this.$refs.permisos.initialize();
    },
    updateLogs(){
      if(typeof this.$refs.logs === 'undefined') return;
      this.$refs.logs.initialize();
    },
    updateAccesoDispositivos(){
      if(typeof this.$refs.accesodispositivos === 'undefined') return;
      this.$refs.accesodispositivos.initialize();
    },
    updateControl(){
      if(typeof this.$refs.cyc === 'undefined') return;
      this.$refs.cyc.initialize();
    }
  },
  computed: {
    ...mapState(["anchoMaximo","colorTabs","modoAyuda"])
  },
  components: {
    seguridadRol: rol,
    seguridadLog: log,
    seguridadAccesoDispositivo: accesodispositivo,
    Control,
    PuntosImpresion
  }
};
</script>