import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.permisoVista('optimizacion','r'))?_c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[(_vm.loading)?_c('div',[_c('div',{staticClass:"d-flex justify-center align-center flex-column",staticStyle:{"min-height":"80vh"}},[_c(VProgressCircular,{attrs:{"size":90,"width":6,"color":"primary","indeterminate":""}}),_c('p',{staticClass:"text-center text-h4"},[_vm._v("Cargando")])],1)]):_vm._e(),(!_vm.loading)?_c('div',[_c('div',{staticClass:"d-flex justify-center"},[_c('div',{staticClass:"d-flex overflow-x-auto"},[(_vm.areasProduccion.length == 0)?_c('div',[_c(VCardTitle,{staticClass:"justify-center",attrs:{"primary-title":""}},[_c('div',{staticClass:"text-center d-flex flex-column align-center justify-center"},[_c('div',[_c(VIcon,{staticStyle:{"font-size":"100px"},attrs:{"color":"yellow darken-2"}},[_vm._v(" mdi-alert ")])],1),_c('p',{staticClass:"text-center text-h5"},[_vm._v(" No hay datos que mostrar, al parecer aún no se ha realizado ninguna simulación ")])])])],1):_vm._l((_vm.areasProduccion),function(area,idxArea){return _c('div',{key:("area-" + idxArea),staticClass:"pa-6 mr-3",staticStyle:{"background-color":"rgba(207, 216, 220, 0.5)"}},[_c('p',{staticClass:"text-center text-h5 font-weight-bold"},[_vm._v(" "+_vm._s(area.nombre)+" ")]),_c('div',{staticClass:"d-flex",staticStyle:{"min-height":"80vh"}},_vm._l((area.lineas),function(linea,idxLinea){return _c('div',{key:("linea-" + idxArea + "-" + idxLinea),class:(idxLinea < area.lineas.length - 1 ? 'mr-3 ' : '') +
                    linea.color +
                    ' pa-3 rounded-lg column-width mr-3 text-center'},[_c('p',{staticClass:"white--text text-center text-h6 font-weight-medium",staticStyle:{"font-size":"1.125rem !important"}},[_vm._v(" "+_vm._s(linea.nombre)+" ")]),_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticStyle:{"width":"24px"}}),(linea.maquinas != null && linea.maquinas.length > 0)?_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"white"}},'v-icon',attrs,false),on),[_vm._v("mdi-robot-industrial-outline ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(" Maquinas: "),_c('ul',_vm._l((linea.maquinas),function(m,idxM){return _c('li',{key:'m'+idxM},[_vm._v(_vm._s(m))])}),0)])]):_c('div',{staticStyle:{"width":"24px"}}),(linea.operaciones.length > 0 && _vm.permisoVista('optimizacion','u'))?_c('div',[(!linea.modoEditar)?_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"white"},on:{"click":function($event){return _vm.setModoEditar(linea)}}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Editar")])]):_vm._e(),(!!linea.modoEditar)?[(_vm.permisoVista('optimizacion','u'))?_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"white","disabled":!!linea.savingOrden},on:{"click":function($event){return _vm.saveNuevoOrden(linea)}}},'v-icon',attrs,false),on),[_vm._v("mdi-content-save ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Guardar")])]):_vm._e(),_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"white","disabled":!!linea.savingOrden},on:{"click":function($event){return _vm.cancelModoEditar(linea)}}},'v-icon',attrs,false),on),[_vm._v("mdi-cancel ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Cancelar")])])]:_vm._e()],2):_c('div',{staticStyle:{"width":"24px"}})],1),(!!linea.savingOrden)?_c(VCol,{staticClass:"pa-0 pt-3",attrs:{"cols":"12"}},[_c(VProgressLinear,{attrs:{"indeterminate":"","color":"white"}})],1):_vm._e(),_c('draggable',{attrs:{"list":linea.operaciones,"animation":200,"ghost-class":"ghost-card","disabled":!linea.modoEditar || !!linea.savingOrden},on:{"change":function($event){return _vm.onChangeDraggable(linea,linea.operaciones)}}},_vm._l((linea.operaciones),function(operacion){return _c('OrdenTrabajoCard',{key:operacion.idOperacion,staticClass:"mt-3",style:(!linea.modoEditar ? 'cursor: default' : 'cursor: grab'),attrs:{"operacion":operacion,"dependencias":_vm.dependencias},on:{"change":_vm.setDependencias}})}),1)],1)}),0)])})],2)])]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }