<template>
	<div>
		<v-tabs v-model="tab" background-color="transparent" grow>
			<v-tab
				href="#tab-1"
				@click="clickTickets()"
			>
				Tickets
			</v-tab>

			<v-tab
				href="#tab-2"
			>
				Catálogos
			</v-tab>

			<v-tabs-items v-model="tab" touchless>
				<v-tab-item :key="1" :value="'tab-1'" eager>
        	<TicketsMMCall ref="tickets" />
				</v-tab-item>
				
				<v-tab-item :key="2" :value="'tab-2'" eager>
        	<ListaCatalogos ref="catalogos" />
				</v-tab-item>

			</v-tabs-items>
		</v-tabs>
	</div>
</template>

<script>
import ListaCatalogos from './TicketsMMCall/ListaCatalogos.vue';
import TicketsMMCall from "./TicketsMMCall/TicketsMMCall.vue";

export default {
	components: { 
    TicketsMMCall,
    ListaCatalogos
  },
	data: () => ({
		tab: 0,
	}),
	methods: {
		initialize() {
			this.clickTickets();
		},
		clickTickets(){
			if (typeof this.$refs.tickets === "undefined") return;
			this.$refs.tickets.initialize();
		}
	},
};
</script>