import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table',{style:('width:100%;border-collapse: collapse; border-style: hidden; box-shadow: 0 0 0 1px '+_vm.borderColor+';')},[_c('tbody',[_c('tr',[_c(VTooltip,{staticClass:"white--text",attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('td',_vm._g(_vm._b({staticClass:"text-center",style:('border: '+_vm.borderSize+'px solid '+_vm.borderColor+'; padding-top: 10px; padding-bottom: 10px; background-color: #4CAF50; width:'+_vm.value.porcentajeNormal+'%; height: '+_vm.height+'px;')},'td',attrs,false),on))]}}])},[_c('span',{staticClass:"white--text"},[_c('strong',[_vm._v("Ordinario: ")]),_vm._v("$ "+_vm._s(_vm.value.normal.toFixed(2))+" ")])]),(_vm.value.retrabajo > 0.0)?_c(VTooltip,{staticClass:"white--text",attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('td',_vm._g(_vm._b({staticClass:"text-center",style:('border: '+_vm.borderSize+'px solid '+_vm.borderColor+'; padding-top: 10px; padding-bottom: 10px; background-color: #2196F3; width:'+_vm.value.porcentajeRetrabajo+'%; height: '+_vm.height+'px;')},'td',attrs,false),on))]}}],null,false,2239982540)},[_c('span',{staticClass:"white--text"},[_c('strong',[_vm._v("Retrabajo: ")]),_vm._v("$ "+_vm._s(_vm.value.retrabajo.toFixed(2))+" ")])]):_vm._e(),(_vm.value.retrabajo > 0.0)?_c(VTooltip,{staticClass:"white--text",attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('td',_vm._g(_vm._b({staticClass:"text-center",style:('border: '+_vm.borderSize+'px solid '+_vm.borderColor+'; padding-top: 10px; padding-bottom: 10px; background-color: #F44336; width:'+_vm.value.porcentajeScrap+'%; height: '+_vm.height+'px;')},'td',attrs,false),on))]}}],null,false,660129920)},[_c('span',{staticClass:"white--text"},[_c('strong',[_vm._v("Scrap: ")]),_vm._v("$ "+_vm._s(_vm.value.scrap.toFixed(2))+" ")])]):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }