<template>
	<v-col cols="12">
		<v-dialog v-model="dialogDelete" persistent max-width="40%">
			<v-card>
				<v-card-text>
					<p class="text-h5 pt-4 font-weight-medium text--primary">
						¿Está seguro que desea eliminar esta Causa?
					</p>
					<p class="subtitle-1 text--primary">
						Esta acción no se puede revertir y será permanente.
					</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="deleting"
						@click="cerrarDialog()"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="deleting"
						:loading="deleting"
						@click="deleteCausa()"
						>Eliminar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog" persistent max-width="500px">
			<v-card>
				<v-card-title
					>{{
						editedItem.idCausa == 0 ? "Crear Nueva" : "Editar"
					}}
					Causa</v-card-title
				>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12">
								<v-autocomplete
									v-model="editedItem.departamento"
									:items="departamentos"
									label="Departamento"
									clearable
								></v-autocomplete>
							</v-col>
							<v-col cols="12">
								<v-autocomplete
									v-model="editedItem.area"
									:items="areas"
									label="Área"
									clearable
								></v-autocomplete>
							</v-col>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.codigo"
									label="Código de la Causa (Opcional)"
									clearable
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.causa"
									label="Nombre de la Causa"
									clearable
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-switch
									v-model="editedItem.abierto"
									label="¿Cualquier Opción?"
									clearable
								></v-switch>
							</v-col>
							<v-col cols="12" v-if="errores.length > 0">
								<ul>
									<li v-for="(error, idx) in errores" :key="'e-'+idx" class="red--text">
										{{error}}
									</li>
								</ul>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="saving"
						@click="cerrarDialog()"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="saving"
						:loading="saving"
						@click="saveCausa()"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row class="justify-space-between">
			<v-col cols="4">
				<v-btn
					color="primary"
					:disabled="loading"
					@click="showDialog()"
          class="px-8"
				>
					Nueva Causa
				</v-btn>
			</v-col>
			<v-col cols="6">
				<v-text-field
					v-model="search"
					append-icon="search"
					label="Buscar"
					single-line
          clearable
					hide-details
				></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
					:items="causas"
					:headers="causasHeaders"
          :search="search"
					:loading="loading"
				>
					<template v-slot:item.abierto="{ item }">
						{{item.abierto ? "Sí":"No"}}
					</template>

					<template v-slot:item.codigo="{ item }">
						{{(item.codigo == null)?"N/A":item.codigo}}
					</template>
					
					<template v-slot:item.actions="{ item }">
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									class="mr-2"
									@click="showDialogEdit(item)"
								>
									edit
								</v-icon>
							</template>
							<span class="white--text">Editar</span>
						</v-tooltip>
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									class="mr-2"
									@click="showDialogDelete(item)"
								>
									delete
								</v-icon>
							</template>
							<span class="white--text">Eliminar</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
	data() {
		return {
      search: "",
			errores: [],
			saving: false,
			dialogDelete: false,
			causasHeaders: [
				{
					text: "Departamento",
					align: "center",
          width: "18%",
					value: "departamento",
				},
				{
					text: "Area",
					align: "center",
          width: "18%",
					value: "area",
				},
				{
					text: "Código de la Causa",
					align: "center",
          width: "18%",
					value: "codigo",
				},
				{
					text: "Causa",
					align: "center",
          width: "18%",
					value: "causa",
				},
				{
					text: "¿Cualquier Opción?",
					align: "center",
          width: "18%",
					value: "abierto",
				},
				{
					text: "Acciones",
					align: "center",
          width: "10%",
					value: "actions",
				},
			],
			editedItem: {
				idCausa: 0,
				departamento: null,
				area: null,
				causa: "",
				abierto: false,
				condicion: true,
			},
			defaultEditedItem: {
				idCausa: 0,
				departamento: null,
				area: null,
				causa: "",
				abierto: false,
				condicion: true,
			},
			loading: false,
			deleting: false,
			causas: [],
			areas: [],
			departamentos: [],
		};
	},
	mounted() {
		this.initialize();
	},
	methods: {
		validate() {
			this.errores = [];

			if (this.editedItem.causa == null || this.editedItem.causa == "") {
				this.errores.push("La Causa es Obligatoria");
			} else if (this.editedItem.causa.length < 5) {
				this.errores.push("La causa debe tener mínimo 5 caracteres");
			}

			if(this.editedItem.departamento == null || this.editedItem.departamento.length <= 0) {
				this.errores.push("El Departamento es Obligatorio");
		  }

			if(this.editedItem.area == null || this.editedItem.area.length <= 0) {
				this.errores.push("El Área es Obligatoria");
		  }

			return this.errores.length == 0;
		},
		saveCausa() {
			if (!this.validate()) return;
			this.saving = true;
			axios({
				url:
					this.editedItem.idCausa == 0
						? "/Causas/Crear"
						: `/Causas/Actualizar/${this.editedItem.idCausa}`,
				method: this.editedItem.idCausa == 0 ? "POST" : "PUT",
				data: this.editedItem,
			})
				.then(() => {
					this.saving = false;
					this.cerrarDialog();
					this.initialize();
				})
				.catch((error) => {
					this.saving = false;
					if(error.response){
						if(error.response.status == 409){
							this.errores.push("Existe una Causa con el mismo Nombre o Código");
						}
					}
					console.log(error);
				});
		},
		showDialogEdit(item) {
			this.editedItem = { ...item };
			this.dialog = true;
		},
		showDialogDelete(item) {
			this.editedItem = { ...item };
			this.dialogDelete = true;
		},
		deleteCausa() {
			this.deleting = true;
			axios
				.put(`/Causas/Eliminar/${this.editedItem.idCausa}`)
				.then(() => {
					this.deleting = false;
					this.cerrarDialog();
					this.initialize();
				})
				.catch((error) => {
					this.deleting = false;
					console.log(error);
				});
		},
		cerrarDialog() {
			this.dialog = false;
			this.dialogDelete = false;
			this.editedItem = { ...this.defaultEditedItem };
			this.errores = [];
		},
		initialize() {
			this.getCausas();
			this.getAreas();
			this.getDepartamentos();
		},
		showDialog() {
			this.editedItem = { ...this.defaultEditedItem };
			this.dialog = true;
		},
		getCausas() {
			this.loading = true;
			axios
				.get("/Causas")
				.then((response) => {
					this.causas = response.data;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		getAreas() {
			this.loading = true;
			axios
				.get("/CausaSolucionRecords/ListarLineaAreas")
				.then((response) => {
					this.areas = response.data.map(element => {
						return element.toUpperCase();
						});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getDepartamentos() {
			axios
				.get("/CausaSolucionRecords/ListarDepartamento")
				.then((response) => {
					this.departamentos = response.data.map(element => {
            return element.toUpperCase();
            });
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>