<template></template>

<script>
import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType
} from "@aspnet/signalr";
import { mapState } from "vuex";

export default {
  name: "SignalR",
  data: () => ({
    connection: ""
  }),
  props: {
    path: {
      type: String,
      default: ""
    }
  },
  watch: {},
  methods: {},
  computed: {
    ...mapState(["token"])
  },
  mounted() {
    this.connection = new HubConnectionBuilder()
      .withUrl(this.path, {
        accessTokenFactory: () => this.token
      })
      .configureLogging(LogLevel.Warning)
      .build();
    this.connection
      .start()
      .then(() => {
        console.log("conexion con hub OK");
      })
      .catch(err => {
        console.error("conexion con hub ERROR: ", err);
      });

    let _this = this;
    this.connection.on("change", function(message) {
      _this.$emit("change", message);
    });
  }
};
</script>