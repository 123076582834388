<template>
	<v-col cols="12">
		<v-row>
			<v-col cols="3" class="mt-2">
				<VueCtkDateTimePicker
					id="inicio"
					v-model="filtros.inicio"
					:max-date="filtros.fin"
					label="Desde"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="getDatos()"
				/>
			</v-col>
			<v-col cols="3" class="mt-2">
				<VueCtkDateTimePicker
					id="fin"
					v-model="filtros.fin"
					:min-date="fechaInicioPlus"
					label="Hasta (Opcional)"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="getDatos()"
				/>
			</v-col>
			<v-col cols="3" class="mt-5">
				<v-select
					v-model="filtros.intervalo"
					:items="bloques"
					label="Agrupar Cada (Tiempos Ciclos)"
					dense
					hide-details
					@change="getDatos()"
				></v-select>
			</v-col>
			<v-col cols="3">
				<v-autocomplete
					v-model="filtros.instancia"
					label="Máquinas"
					:items="requesters"
					item-text="nombre"
					item-value="idRequester"
					hide-details
					multiple
					chips
					deletable-chips
					clearable
					@change="getDatos()"
				>
				</v-autocomplete>
			</v-col>
		</v-row>
		<v-row v-if="filtros.inicio!=null" class="px-0 ma-0">
			<v-col cols="12" class="ma-0 text-right text-subtitle-2">
				Proxima Actualización en {{tiempo}}
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<p
					class="
						mb-0
						text-center
						subtitle-1
						font-weight-bold
						text--secondary
					"
					style="font-size: 18px !important"
				>
					# Incidentes Por Hora
				</p>
				<apexchart
					id="graficaIncidentes"
					type="line"
					height="350"
					:options="chartOptions"
					:series="series"
				></apexchart>
			</v-col>
			<v-col cols="12">
				<p
					class="
						mb-0
						text-center
						subtitle-1
						font-weight-bold
						text--secondary
					"
					style="font-size: 18px !important"
				>
					Tiempo promedio de atención por responsable de soluciones
				</p>
				<apexchart
					id="grafica1"
					:key="`grafica1-${grafica1Key}`"
					type="line"
					height="350"
					:options="chartOptionsG1"
					:series="seriesG1"
				></apexchart>
			</v-col>
			<v-col cols="12">
				<p
					class="
						mb-0
						text-center
						subtitle-1
						font-weight-bold
						text--secondary
					"
					style="font-size: 18px !important"
				>
					Tiempo total de solicitudes abiertas
				</p>
				<apexchart
					id="grafica2"
					type="line"
					height="350"
					:options="chartOptionsG2"
					:series="seriesG2"
				></apexchart>
			</v-col>
			<v-col cols="12">
				<p
					class="
						mb-0
						text-center
						subtitle-1
						font-weight-bold
						text--secondary
					"
					style="font-size: 18px !important"
				>
					Número de solicitudes resueltas
				</p>
				<apexchart
					id="grafica3"
					type="line"
					height="350"
					:options="chartOptionsG3"
					:series="seriesG3"
				></apexchart>
			</v-col>
			<v-col cols="12">
				<p
					class="
						mb-0
						text-center
						subtitle-1
						font-weight-bold
						text--secondary
					"
					style="font-size: 18px !important"
				>
					Número de solicitudes abiertas
				</p>
				<apexchart
					id="grafica4"
					type="line"
					height="350"
					:options="chartOptionsG4"
					:series="seriesG4"
				></apexchart>
			</v-col>
			<v-col cols="12">
				<p
					class="
						mb-0
						text-center
						subtitle-1
						font-weight-bold
						text--secondary
					"
					style="font-size: 18px !important"
				>
					Solicitudes atendidas por responsable
				</p>
				<apexchart
					id="grafica5"
					type="line"
					height="350"
					:options="chartOptionsG5"
					:series="seriesG5"
				></apexchart>
			</v-col>
			<v-col cols="12">
				<p
					class="
						mb-0
						text-center
						subtitle-1
						font-weight-bold
						text--secondary
					"
					style="font-size: 18px !important"
				>
					Responsables con solicitudes abiertas
				</p>
				<apexchart
					id="grafica6"
					type="line"
					height="350"
					:options="chartOptionsG6"
					:series="seriesG6"
				></apexchart>
			</v-col>
			<v-col cols="12">
				<p
					class="
						mb-0
						text-center
						subtitle-1
						font-weight-bold
						text--secondary
					"
					style="font-size: 18px !important"
				>
					Tiempo total por responsable de soluciones
				</p>
				<apexchart
					id="grafica7"
					type="line"
					height="350"
					:options="chartOptionsG7"
					:series="seriesG7"
				></apexchart>
			</v-col>
			<v-col cols="12">
				<p
					class="
						mb-0
						text-center
						subtitle-1
						font-weight-bold
						text--secondary
					"
					style="font-size: 18px !important"
				>
					Tiempo de espera de solicitudes por máquina
				</p>
				<apexchart
					id="grafica8"
					type="line"
					height="350"
					:options="chartOptionsG8"
					:series="seriesG8"
				></apexchart>
			</v-col>
			<v-col cols="12">
				<p
					class="
						mb-0
						text-center
						subtitle-1
						font-weight-bold
						text--secondary
					"
					style="font-size: 18px !important"
				>
					Tiempo promedio de atención de solicitudes por máquina
				</p>
				<apexchart
					id="grafica9"
					type="line"
					height="350"
					:options="chartOptionsG9"
					:series="seriesG9"
				></apexchart>
			</v-col>
			<v-col cols="12">
				<p
					class="
						mb-0
						text-center
						subtitle-1
						font-weight-bold
						text--secondary
					"
					style="font-size: 18px !important"
				>
					Cantidad de solicitudes por máquina
				</p>
				<apexchart
					id="grafica10"
					type="line"
					height="350"
					:options="chartOptionsG10"
					:series="seriesG10"
				></apexchart>
			</v-col>
			<v-col cols="12">
				<p
					class="
						mb-0
						text-center
						subtitle-1
						font-weight-bold
						text--secondary
					"
					style="font-size: 18px !important"
				>
					Máquinas con solicitudes abiertas
				</p>
				<apexchart
					id="grafica11"
					type="line"
					height="350"
					:options="chartOptionsG11"
					:series="seriesG11"
				></apexchart>
			</v-col>
			<v-col cols="12">
				<p
					class="
						mb-0
						text-center
						subtitle-1
						font-weight-bold
						text--secondary
					"
					style="font-size: 18px !important"
				>
					Tiempo promedio de atención por responsable de soluciones
				</p>
				<apexchart
					id="grafica12"
					type="line"
					height="350"
					:options="chartOptionsG12"
					:series="seriesG12"
				></apexchart>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
					:items="mmcall"
					:headers="mmcallHeaders"
					:loading="loading"
					loading-text="Cargando... Espere, Por Favor."
				>
					<template v-slot:item.tiempoTotal="{ item }">
						{{formatSeconds(item.tiempoTotal)}}
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
	data() {
		return {
			bloques:[
				{
					value:30,
					text: "30 Minutos"
				},
				{
					value:60,
					text: "1 Hora"
				},
				{
					value:720,
					text: "12 Horas"
				},
				{
					value:1440,
					text: "1 Día"
				},
				{
					value:10080,
					text: "1 Semana"
				}
			],
			filtros: {
				inicio: null,
				fin: null,
				intervalo: 1440,
				instancia: null,
			},
			defaultFiltros: {
				inicio: null,
				fin: null,
				intervalo: 1440,
				instancia: null,
			},
			grafica1Key: 0,
			loading: false,
			requesters: [],
			mmcallHeaders: [
				{
					text: "Tipo de Evento",
					align: "center",
					sortable: false,
					value: "tipoEvento",
				},
				{
					text: "Máquina",
					align: "center",
					sortable: false,
					value: "maquina",
				},
				{
					text: "Cantidad",
					align: "center",
					sortable: false,
					value: "cantidad",
				},
				{
					text: "Inicio",
					align: "center",
					sortable: false,
					value: "inicio",
				},
				{
					text: "Fin",
					align: "center",
					sortable: false,
					value: "fin",
				},
				{
					text: "Duracion",
					align: "center",
					sortable: false,
					value: "tiempoTotal",
				},
			],
			chartOptions: {
				chart: {
					id: "incidentesHora",
					type: "line",
					height: 350,
					zoom: {
						enabled: true,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "straight",
				},
				grid: {
					borderColor: "#e7e7e7",
					row: {
						colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
						opacity: 0.5,
					},
				},
				markers: {
					size: 1,
				},
				xaxis: {
					type: "datetime",
					labels: {
						datetimeUTC: false,
					},
					// categories: [],
				},
				tooltip: {
					x: {
						formatter: function (val) {
							return moment(val).format("YYYY-MM-DD HH:mm:ss");
						},
					},
				},
			},
			defaultChartOptions: {
				chart: {
					type: "line",
					height: 350,
					zoom: {
						enabled: true,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "straight",
				},
				grid: {
					borderColor: "#e7e7e7",
					row: {
						colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
						opacity: 0.5,
					},
				},
				markers: {
					size: 1,
				},
				xaxis: {
					type: "datetime",
					labels: {
						datetimeUTC: false,
					},
					// categories: [],
				},
				tooltip: {
					x: {
						formatter: function (val) {
							return moment(val).format("YYYY-MM-DD HH:mm:ss");
						},
					},
				},
			},
			defaultChartOptionsG: {
				chart: {
					type: "bar",
					height: 350,
					zoom: {
						enabled: true,
					},
				},
				dataLabels: {
					enabled: false,
				},
				colors: [ () => this.getColor() ],
				stroke: {
					show: true,
					width: 2,
					colors: ['transparent']
				},
				markers: {
					size: 1,
				},
				xaxis: {
					categories: [],
				},
				fill: {
					opacity: 1,
				},
			},
			chartOptionsG1: {
				chart: {
					type: "line",
					height: 350,
					zoom: {
						enabled: true,
					},
				},
				dataLabels: {
					enabled: false,
				},
				colors: [],
				stroke: {
					curve: "straight",
					colors: ["transparent"], // takes an array which will be repeated on columns
				},
				grid: {
					borderColor: "#e7e7e7",
					row: {
						opacity: 0.5,
					},
				},
				markers: {
					size: 1,
				},
				xaxis: {
					// type: "datetime",
					// labels: {
					// 	datetimeUTC: false,
					// },
					categories: [],
				},
				yaxis: {
					labels: {
						formatter: function(val) {
							return moment.utc(val*1000).format('HH:mm:ss')
						}
					}
				}
				// tooltip: {
				// 	x: {
				// 		formatter: function (val) {
				// 			return moment(val).format("YYYY-MM-DD HH:mm:ss");
				// 		},
				// 	},
				// },
			},
			chartOptionsG2: {
				chart: {
					type: "line",
					height: 350,
					zoom: {
						enabled: true,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "straight",
				},
				grid: {
					borderColor: "#e7e7e7",
					row: {
						colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
						opacity: 0.5,
					},
				},
				markers: {
					size: 1,
				},
				xaxis: {
					// type: "datetime",
					// labels: {
					// 	datetimeUTC: false,
					// },
					categories: [],
				},
				yaxis: {
					labels: {
						formatter: function(val) {
							return moment.utc(val*1000).format('HH:mm:ss')
						}
					}
				}
				// tooltip: {
				// 	x: {
				// 		formatter: function (val) {
				// 			return moment(val).format("YYYY-MM-DD HH:mm:ss");
				// 		},
				// 	},
				// },
			},
			chartOptionsG3: {
				chart: {
					type: "line",
					height: 350,
					zoom: {
						enabled: true,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "straight",
				},
				grid: {
					borderColor: "#e7e7e7",
					row: {
						colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
						opacity: 0.5,
					},
				},
				markers: {
					size: 1,
				},
				xaxis: {
					// type: "datetime",
					// labels: {
					// 	datetimeUTC: false,
					// },
					categories: [],
				},
				// tooltip: {
				// 	x: {
				// 		formatter: function (val) {
				// 			return moment(val).format("YYYY-MM-DD HH:mm:ss");
				// 		},
				// 	},
				// },
			},
			chartOptionsG4: {
				chart: {
					type: "line",
					height: 350,
					zoom: {
						enabled: true,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "straight",
				},
				grid: {
					borderColor: "#e7e7e7",
					row: {
						colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
						opacity: 0.5,
					},
				},
				markers: {
					size: 1,
				},
				xaxis: {
					// type: "datetime",
					// labels: {
					// 	datetimeUTC: false,
					// },
					categories: [],
				},
				// tooltip: {
				// 	x: {
				// 		formatter: function (val) {
				// 			return moment(val).format("YYYY-MM-DD HH:mm:ss");
				// 		},
				// 	},
				// },
			},
			chartOptionsG5: {
				chart: {
					type: "line",
					height: 350,
					zoom: {
						enabled: true,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "straight",
				},
				grid: {
					borderColor: "#e7e7e7",
					row: {
						colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
						opacity: 0.5,
					},
				},
				markers: {
					size: 1,
				},
				xaxis: {
					// type: "datetime",
					// labels: {
					// 	datetimeUTC: false,
					// },
					categories: [],
				},
				// tooltip: {
				// 	x: {
				// 		formatter: function (val) {
				// 			return moment(val).format("YYYY-MM-DD HH:mm:ss");
				// 		},
				// 	},
				// },
			},
			chartOptionsG6: {
				chart: {
					type: "line",
					height: 350,
					zoom: {
						enabled: true,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "straight",
				},
				grid: {
					borderColor: "#e7e7e7",
					row: {
						colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
						opacity: 0.5,
					},
				},
				markers: {
					size: 1,
				},
				xaxis: {
					// type: "datetime",
					// labels: {
					// 	datetimeUTC: false,
					// },
					categories: [],
				},
				// tooltip: {
				// 	x: {
				// 		formatter: function (val) {
				// 			return moment(val).format("YYYY-MM-DD HH:mm:ss");
				// 		},
				// 	},
				// },
			},
			chartOptionsG7: {
				chart: {
					type: "line",
					height: 350,
					zoom: {
						enabled: true,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "straight",
				},
				grid: {
					borderColor: "#e7e7e7",
					row: {
						colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
						opacity: 0.5,
					},
				},
				markers: {
					size: 1,
				},
				xaxis: {
					// type: "datetime",
					// labels: {
					// 	datetimeUTC: false,
					// },
					categories: [],
				},
				yaxis: {
					labels: {
						formatter: function(val) {
							return moment.utc(val*1000).format('HH:mm:ss')
						}
					}
				}
				// tooltip: {
				// 	x: {
				// 		formatter: function (val) {
				// 			return moment(val).format("YYYY-MM-DD HH:mm:ss");
				// 		},
				// 	},
				// },
			},
			chartOptionsG8: {
				chart: {
					type: "line",
					height: 350,
					zoom: {
						enabled: true,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "straight",
				},
				grid: {
					borderColor: "#e7e7e7",
					row: {
						colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
						opacity: 0.5,
					},
				},
				markers: {
					size: 1,
				},
				xaxis: {
					// type: "datetime",
					// labels: {
					// 	datetimeUTC: false,
					// },
					categories: [],
				},
				yaxis: {
					labels: {
						formatter: function(val) {
							return moment.utc(val*1000).format('HH:mm:ss')
						}
					}
				}
				// tooltip: {
				// 	x: {
				// 		formatter: function (val) {
				// 			return moment(val).format("YYYY-MM-DD HH:mm:ss");
				// 		},
				// 	},
				// },
			},
			chartOptionsG9: {
				chart: {
					type: "line",
					height: 350,
					zoom: {
						enabled: true,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "straight",
				},
				grid: {
					borderColor: "#e7e7e7",
					row: {
						colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
						opacity: 0.5,
					},
				},
				markers: {
					size: 1,
				},
				xaxis: {
					// type: "datetime",
					// labels: {
					// 	datetimeUTC: false,
					// },
					categories: [],
				},
				yaxis: {
					labels: {
						formatter: function(val) {
							return moment.utc(val*1000).format('HH:mm:ss')
						}
					}
				}
				// tooltip: {
				// 	x: {
				// 		formatter: function (val) {
				// 			return moment(val).format("YYYY-MM-DD HH:mm:ss");
				// 		},
				// 	},
				// },
			},
			chartOptionsG10: {
				chart: {
					type: "line",
					height: 350,
					zoom: {
						enabled: true,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "straight",
				},
				grid: {
					borderColor: "#e7e7e7",
					row: {
						colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
						opacity: 0.5,
					},
				},
				markers: {
					size: 1,
				},
				xaxis: {
					// type: "datetime",
					// labels: {
					// 	datetimeUTC: false,
					// },
					categories: [],
				},
				// tooltip: {
				// 	x: {
				// 		formatter: function (val) {
				// 			return moment(val).format("YYYY-MM-DD HH:mm:ss");
				// 		},
				// 	},
				// },
			},
			chartOptionsG11: {
				chart: {
					type: "line",
					height: 350,
					zoom: {
						enabled: true,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "straight",
				},
				grid: {
					borderColor: "#e7e7e7",
					row: {
						colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
						opacity: 0.5,
					},
				},
				markers: {
					size: 1,
				},
				xaxis: {
					// type: "datetime",
					// labels: {
					// 	datetimeUTC: false,
					// },
					categories: [],
				},
				// tooltip: {
				// 	x: {
				// 		formatter: function (val) {
				// 			return moment(val).format("YYYY-MM-DD HH:mm:ss");
				// 		},
				// 	},
				// },
			},
			chartOptionsG12: {
				chart: {
					type: "line",
					height: 350,
					zoom: {
						enabled: true,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "straight",
				},
				grid: {
					borderColor: "#e7e7e7",
					row: {
						colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
						opacity: 0.5,
					},
				},
				markers: {
					size: 1,
				},
				xaxis: {
					// type: "datetime",
					// labels: {
					// 	datetimeUTC: false,
					// },
					categories: [],
				},
				yaxis: {
					labels: {
						formatter: function(val) {
							return moment.utc(val*1000).format('HH:mm:ss')
						}
					}
				}
				// tooltip: {
				// 	x: {
				// 		formatter: function (val) {
				// 			return moment(val).format("YYYY-MM-DD HH:mm:ss");
				// 		},
				// 	},
				// },
			},
			series: [],
			series2: [],
			seriesG1: [],
			seriesG2: [],
			seriesG3: [],
			seriesG4: [],
			seriesG5: [],
			seriesG6: [],
			seriesG7: [],
			seriesG8: [],
			seriesG9: [],
			seriesG10: [],
			seriesG11: [],
			seriesG12: [],
			mmcall: [],
			colors: [
				"#008FFB",
				"#00E396",
				"#FEB019",
				"#FF4560",
				"#775DD0",
				"#4CAF50",
				"#FF9800",
				"#1B998B",
				"#FA4443",
				"#A300D6"
			],
			colorsDummy: [
				"#008FFB",
				"#00E396",
				"#FEB019",
				"#FF4560",
				"#775DD0",
				"#4CAF50",
				"#FF9800",
				"#1B998B",
				"#FA4443",
				"#A300D6"
			],
			segundos: 0,
			intervalo: null
		};
	},
	mounted() {
		this.filtros = Object.assign({}, this.defaultFiltros);
		this.initialize();
		this.intervalo = setInterval(() => {
			if (this.segundos == 0) {
				this.getDatos();
			}
			else {
				this.segundos -= 1;
			}
		}, 1000);
	},
	destroyed(){
		clearInterval(this.intervalo);
		this.intervalo = null;
	},
	computed: {
		fechaInicioPlus(){
			return moment(this.filtros.inicio).add(1, 'days').format("YYYY-MM-DD HH:mm");
		},
		tiempo() {
			return this.$utils.strings.toTime(this.segundos);
		},
	},
	methods: {
		getColor(){
			if (this.colorsDummy.length == 0) {
				this.colorsDummy = this.colors.map(color => color);
			}
			return this.colorsDummy.pop();
		},
		formatSeconds(tiempoTotal){
			return moment.utc(tiempoTotal*1000).format('HH:mm:ss');
		},
		initialize() {
			this.getMaquinaIns();
			this.getDatos();
		},
		getMaquinaIns() {
			axios
				.get("/AnaliticaMonterrey/Requesters")
				.then((response) => {
					this.requesters = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getDatos() {
			if(this.filtros.inicio==null) {
				this.resetValues();
				return;
			}
			this.loading = true;
			this.segundos = 180;

			let idsMaquinas = "";
			this.filtros.instancia?.forEach((instancia,idx) => {
				idsMaquinas += `${idx==0?'?':'&'}ids_maquinas=${instancia}`
			});

			axios
				.get(`/AnaliticaMonterrey/InformacionMMCall${idsMaquinas}`, {
					//Filtros
					params: {
						fechaInicio: this.filtros.inicio,
						fechaFin: this.filtros.fin,
						intervalo: this.filtros.intervalo
					},
				})
				.then((response) => {
					this.mmcall = response.data.registros;

					var chartOptions = Object.assign({}, this.defaultChartOptions);
					this.chartOptions = chartOptions;
					this.series = [];
					this.series = response.data.incidentes;

					var chartOptionsG1 = JSON.parse(JSON.stringify(this.defaultChartOptionsG));
					chartOptionsG1.colors = [ () => this.getColor() ];
					chartOptionsG1.xaxis.categories = response.data.grafica1.categories;
					this.chartOptionsG1 = chartOptionsG1;
					this.seriesG1 = [];
					this.seriesG1 = response.data.grafica1.series;

					var chartOptionsG2 = JSON.parse(JSON.stringify(this.defaultChartOptionsG));
					chartOptionsG2.colors = [ () => this.getColor() ];
					chartOptionsG2.xaxis.categories = response.data.grafica2.categories;
					this.chartOptionsG2 = chartOptionsG2;
					this.seriesG2 = [];
					this.seriesG2 = response.data.grafica2.series;

					var chartOptionsG3 = JSON.parse(JSON.stringify(this.defaultChartOptionsG));
					chartOptionsG3.colors = [ () => this.getColor() ];
					chartOptionsG3.xaxis.categories = response.data.grafica3.categories;
					this.chartOptionsG3 = chartOptionsG3;
					this.seriesG3 = [];
					this.seriesG3 = response.data.grafica3.series;

					var chartOptionsG4 = JSON.parse(JSON.stringify(this.defaultChartOptionsG));
					chartOptionsG4.colors = [ () => this.getColor() ];
					chartOptionsG4.xaxis.categories = response.data.grafica4.categories;
					this.chartOptionsG4 = chartOptionsG4;
					this.seriesG4 = [];
					this.seriesG4 = response.data.grafica4.series;

					var chartOptionsG5 = JSON.parse(JSON.stringify(this.defaultChartOptionsG));
					chartOptionsG5.colors = [ () => this.getColor() ];
					chartOptionsG5.xaxis.categories = response.data.grafica5.categories;
					this.chartOptionsG5 = chartOptionsG5;
					this.seriesG5 = [];
					this.seriesG5 = response.data.grafica5.series;

					var chartOptionsG6 = JSON.parse(JSON.stringify(this.defaultChartOptionsG));
					chartOptionsG6.colors = [ () => this.getColor() ];
					chartOptionsG6.xaxis.categories = response.data.grafica6.categories;
					this.chartOptionsG6 = chartOptionsG6;
					this.seriesG6 = [];
					this.seriesG6 = response.data.grafica6.series;

					var chartOptionsG7 = JSON.parse(JSON.stringify(this.defaultChartOptionsG));
					chartOptionsG7.colors = [ () => this.getColor() ];
					chartOptionsG7.xaxis.categories = response.data.grafica7.categories;
					this.chartOptionsG7 = chartOptionsG7;
					this.seriesG7 = [];
					this.seriesG7 = response.data.grafica7.series;

					var chartOptionsG8 = JSON.parse(JSON.stringify(this.defaultChartOptionsG));
					chartOptionsG8.colors = [ () => this.getColor() ];
					chartOptionsG8.xaxis.categories = response.data.grafica8.categories;
					this.chartOptionsG8 = chartOptionsG8;
					this.seriesG8 = [];
					this.seriesG8 = response.data.grafica8.series;

					var chartOptionsG9 = JSON.parse(JSON.stringify(this.defaultChartOptionsG));
					chartOptionsG9.colors = [ () => this.getColor() ];
					chartOptionsG9.xaxis.categories = response.data.grafica9.categories;
					this.chartOptionsG9 = chartOptionsG9;
					this.seriesG9 = [];
					this.seriesG9 = response.data.grafica9.series;

					var chartOptionsG10 = JSON.parse(JSON.stringify(this.defaultChartOptionsG));
					chartOptionsG10.colors = [ () => this.getColor() ];
					chartOptionsG10.xaxis.categories = response.data.grafica10.categories;
					this.chartOptionsG10 = chartOptionsG10;
					this.seriesG10 = [];
					this.seriesG10 = response.data.grafica10.series;

					var chartOptionsG11 = JSON.parse(JSON.stringify(this.defaultChartOptionsG));
					chartOptionsG11.colors = [ () => this.getColor() ];
					chartOptionsG11.xaxis.categories = response.data.grafica11.categories;
					this.chartOptionsG11 = chartOptionsG11;
					this.seriesG11 = [];
					this.seriesG11 = response.data.grafica11.series;

					var chartOptionsG12 = JSON.parse(JSON.stringify(this.defaultChartOptionsG));
					chartOptionsG12.colors = [ () => this.getColor() ];
					chartOptionsG12.xaxis.categories = response.data.grafica12.categories;
					this.chartOptionsG12 = chartOptionsG12;
					this.seriesG12 = [];
					this.seriesG12 = response.data.grafica12.series;

					this.loading = false;
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
					this.resetValues();
				});
		},
		resetValues(){
			this.series = [];
			this.seriesG1 = [];
			this.seriesG2 = [];
			this.seriesG3 = [];
			this.seriesG4 = [];
			this.seriesG5 = [];
			this.seriesG6 = [];
			this.seriesG7 = [];
			this.seriesG8 = [];
			this.seriesG9 = [];
			this.seriesG10 = [];
			this.seriesG11 = [];
			this.seriesG12 = [];
			this.mmcall = [];
			this.colorsDummy = this.colors.map(color => color);
			this.filtros = Object.assign({}, this.defaultFiltros);
		}
	},
};
</script>