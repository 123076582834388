<template>
	<v-col cols="12" v-if="permisoVista('mantenimiento-inspecciones','r')">
		<v-data-table
			:headers="headers"
			:items="inspecciones"
			:loading="loading"
			loading-text="Cargando... Espere, Por Favor."
			:footer-props="{
				disableItemsPerPage: true,
				disablePagination: this.loading,
			}"
			:disable-sort="true"
		>
		</v-data-table>
	</v-col>
</template>

<script>
import axios from "axios";

export default {
	data() {
		return {
            terminarItem: {
                idMantenimientoCalendarioInspeccion: 0
            },
            defaultTerminarItem: {
                idMantenimientoCalendarioInspeccion: 0
            },
			errores: [],
			recomendacion: "",
			inspecciones: [],
			headers: [
				{
					text: "Código",
					align: "center",
					sort: false,
					value: "codigo",
				},
				{
					text: "Nombre",
					align: "center",
					sort: false,
					value: "nombre",
				},
				{
					text: "Responsable",
					align: "center",
					sort: false,
					value: "responsable",
				},
				{
					text: "Máquina",
					align: "center",
					sort: false,
					value: "maquina",
				},
				{
					text: "Inicio",
					align: "center",
					sort: false,
					value: "inicio",
				},
                {
					text: "Fin",
					align: "center",
					sort: false,
					value: "fin",
				},
			],
			dialogTerminar: false,
			loadingFinish: false,
		};
	},
	mounted() {
		this.initialize();
	},
	methods: {
		initialize() {
			this.getMantenimientos();
		},
		getMantenimientos() {
			axios
				.get("/Mantenimientos/GetInspeccionesTerminadas")
				.then((response) => {
					this.inspecciones = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>