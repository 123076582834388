import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.permisoVista('mantenimiento-inspecciones','r'))?_c(VCol,{attrs:{"cols":"12"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"60%"},model:{value:(_vm.dialogTerminar),callback:function ($$v) {_vm.dialogTerminar=$$v},expression:"dialogTerminar"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Terminar Inspección")]),_c(VCardText,[_c('Comprobaciones',{ref:"checklist"}),_c('ul',{staticClass:"mt-3"},_vm._l((_vm.errores),function(ex){return _c('li',{key:ex,staticClass:"red--text"},[_vm._v(" "+_vm._s(ex)+" ")])}),0)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"green","text":"","disabled":_vm.loadingFinish,"small":""},on:{"click":_vm.cerrarDialogTerminar}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"red","text":"","small":"","disabled":_vm.loadingFinish,"loading":_vm.loadingFinish},on:{"click":_vm.terminarTarea}},[_vm._v("Guardar")])],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.inspecciones,"loading":_vm.loading,"loading-text":"Cargando... Espere, Por Favor.","footer-props":{
			disableItemsPerPage: true,
			disablePagination: this.loading,
		},"disable-sort":true},scopedSlots:_vm._u([{key:"item.inicioCronometro",fn:function(ref){
		var item = ref.item;
return [_c('chronometer',{attrs:{"time":item.inicioCronometro}})]}},{key:"item.actions",fn:function(ref){
		var item = ref.item;
return [(_vm.permisoVista('mantenimiento-inspecciones','u'))?_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
		var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.terminarMantenimiento(item)}}},on),[_vm._v(" stop ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Terminar")])]):_vm._e()]}}],null,false,40790404)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }