<template>
    <v-dialog v-model="dialog" persistent max-width="60%">
        <v-card>
            <v-card-title>
                Materias Primas Consumidas
            </v-card-title>
            <v-card-subtitle class="mt-1">
                <p class="mb-0">Almacén: {{editedItem.codigoAlmacen}} - {{editedItem.nombreAlmacen}}</p>
            </v-card-subtitle>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete
                                v-model="filtros.idOtd"
                                label="Orden de Trabajo Detalle"
                                :items="editedItem.otDetalles"
                                item-text="nombre"
                                item-value="idTrabajoOrdenDetalle"
                                hide-details
                                clearable
                                @change="getRawMaterialsConsumed()"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-data-table
                                :items="rawMaterialsConsumed"
                                :headers="rawMaterialsHeaders"
                                :loading="loading"
                                :items-per-page="-1"
                                hide-default-footer
                            >
                                <template v-slot:item.articulo="{ item }">
                                    {{getNombreArticulo(item)}}
                                </template>
                                <template v-slot:item.porcentaje="{ item }">
                                    {{item.porcentaje.toFixed(2)}} %
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="success" @click="cerrarDialog()">Cerrar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios';
export default {
    data(){
        return {
            rawMaterialsHeaders: [
                {
                    text: "Artículo",
                    align: "center",
                    value: "articulo"
                },
                {
                    text: "Cantidad Necesaria",
                    align: "center",
                    value: "cantidadNecesaria"
                },
                {
                    text: "Cantidad Empleada",
                    align: "center",
                    value: "cantidadConsumida"
                },
                {
                    text: "Porcentaje",
                    align: "center",
                    value: "porcentaje"
                }
            ],
            dialog: false,
            filtros: {
                idOtd: null,
            },
            defaultFiltros: {
                idOtd: null,
            },
            rawMaterialsConsumed: [],
            editedItem: {
                idTrabajoOrden: null,
                otDetalles: [],
                codigoAlmacen: "",
                nombreAlmacen: ""
            },
            defaultEditedItem: {
                idTrabajoOrden: null,
                otDetalles: [],
                codigoAlmacen: "",
                nombreAlmacen: ""
            },
            loading: false
        }
    },
    methods: {
        getNombreArticulo: ({codigo, nombre}) => `[${codigo}] - ${nombre}`,
        resetValues(){
            this.editedItem = { ...this.defaultEditedItem };
            this.filtros = { ...this.defaultFiltros };
        },
        showDialog(){
            this.dialog = true;
        },
        cerrarDialog(){
            this.resetValues();
            this.dialog = false;
        },
        setEditedItem(item){
            this.editedItem.idTrabajoOrden = item.idTrabajoOrden;
            this.editedItem.nombreAlmacen = item.nombreAlmacen;
            this.editedItem.codigoAlmacen = item.codigoAlmacen;
            this.editedItem.otDetalles = item.articulos.map(artic => ({
                idTrabajoOrdenDetalle: artic.idTrabajoOrdenDetalle,
                nombre: artic.nombre
            }));
        },
        initialize(item){
            this.setEditedItem(item);
            this.getRawMaterialsConsumed();
        },
        getRawMaterialsConsumed(){
            this.loading = true;
            axios
                .get(`/TrabajoOrdenes/MateriasPrimasConsumidas/${this.editedItem.idTrabajoOrden}`,{
                    params: this.filtros
                })
                .then( response => {
                    this.rawMaterialsConsumed = response.data;
                })
                .catch( error => {
                    console.log(error);
                })
                .finally( () => {
                    this.loading = false;
                })
        }
    }
}
</script>