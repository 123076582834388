<template>
	<v-col cols="12">
		<v-dialog v-model="dialog" persistent max-width="40%">
			<v-card>
				<v-card-title
					>Editar {{ getWeekOrMonthSelected }} - Top #{{
						editedItem.row
					}}
					- {{ getKPIType(editedItem.type) }}</v-card-title
				>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.responsable"
									label="Responsable"
									clearable
									:error-messages="errorResponsable"
									@focus="errorResponsable = ''"
								></v-text-field>
							</v-col>
							<v-col cols="12" class="pl-2 mb-4">
								<VueCtkDateTimePicker
									id="fechaInicio"
									v-model="editedItem.fechaInicio"
									label="Fecha de Inicio"
									format="YYYY-MM-DD HH:mm"
									formatted="YYYY-MM-DD HH:mm"
									noHeader
									overlay
									buttonNowTranslation="Ahora"
									:error="errorFechaInicio != ''"
									@input="resetErrorFecha('errorFechaInicio')"
								/>
								<p
									v-if="errorFechaInicio"
									class="red--text pt-1 mb-0"
									style="font-size: 12px !important"
								>
									{{ errorFechaInicio }}
								</p>
							</v-col>
							<v-col cols="12" class="pl-2 mb-2">
								<VueCtkDateTimePicker
									id="fechaFin"
									v-model="editedItem.fechaFin"
									:min-date="editedItem.fechaInicio"
									label="Fecha de Fin"
									format="YYYY-MM-DD HH:mm"
									formatted="YYYY-MM-DD HH:mm"
									noHeader
									overlay
									buttonNowTranslation="Ahora"
									:error="errorFechaFin != ''"
									@input="resetErrorFecha('errorFechaFin')"
								/>
								<p
									v-if="errorFechaFin"
									class="red--text pt-1 mb-0"
									style="font-size: 12px !important"
								>
									{{ errorFechaFin }}
								</p>
							</v-col>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.problema"
									label="Problema"
									clearable
									
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-textarea
									v-model="editedItem.options"
									label="Acciones"
									auto-grow
									rows="2"
									clearable
									:error-messages="errorOptions"
									@focus="errorOptions = ''"
								></v-textarea>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="saving"
						@click="cerrarDialog()"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="saving"
						:loading="saving"
						@click="save()"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col cols="2">
				<v-select
					v-model="filtros.year"
					label="Indicar Año (Obligatorio)"
					:items="years"
					hide-details
					:disabled="loadingSelectores || loading"
					:loading="loadingSelectores"
					clearable
					@change="changeYear()"
				></v-select>
			</v-col>
			<v-col cols="3">
				<v-autocomplete
					v-model="filtros.monthOrWeek"
					:label="
						type == 1
							? 'Indicar Semana (Obligatorio)'
							: 'Indicar Mes (Obligatorio)'
					"
					:items="type == 1 ? weeks : mesesFiltrados"
					:item-text="type == 1 ? 'name' : 'text'"
					:item-value="type == 1 ? 'week' : 'value'"
					hide-details
					clearable
					:loading="loadingSelectores"
					:disabled="filtros.year == null || loading"
					:dense="type== 1 ? false : true"
          :chips="type== 1 ? false : true"
					:multiple="type== 1 ? false : true"
					@change="changeMonthOrWeek()"
					@click:clear="changeMonthOrWeek()"
				></v-autocomplete>
			</v-col>
			<v-col cols="2">
				<v-autocomplete
					v-model="filtros.lineaArea"
					label="Área (Opcional)"
					:items="areas"
					hide-details
					clearable
					:disabled="
						filtros.year == null || filtros.monthOrWeek == null
					"
					
				></v-autocomplete>
			</v-col>
			<v-col cols="3">
				<v-autocomplete
					v-model="filtros.departamento"
					label="Departamento (Opcional)"
					:items="departamentos"
					hide-details
					clearable
					:disabled="
						filtros.year == null || filtros.monthOrWeek == null
					"
					
				></v-autocomplete>
			</v-col>
			<v-col cols="2">
				<v-btn
  				color="primary"
					@click="getDatosGraficas()"
					>Buscar</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" class="d-flex align-center justify-space-between">
				<v-btn
					text
					color="primary"
					class="my-0"
					:disabled="!hayData || downloading || loading"
					:loading="downloading"
					@click="descargarGraficas()"
					>Descargar Gráficas</v-btn
				>
				<v-btn
					text
					color="primary"
					class="my-0"
					:disabled="!hayData || downloading || loading"
					:loading="downloading"
					@click="descargarGraficasTabloide()"
					>Descargar Gráficas<br>para Plotter</v-btn
				>
			</v-col>
		</v-row>
		<v-row v-if="hayData && !loading">
			<v-col cols="12" v-for="index in 4" :key="`colGraph-${index}`">
				<p
					class="text-h5 text-center font-weight-bold"
					:key="`title-${index}`"
				>
					{{ getWeekOrMonthSelected }} -
					{{ getKPIType(index) }}
				</p>
				<apexchart
					type="bar"
					height="350"
					:options="getChartOptions(index)"
					:series="getSeries(index)"
				></apexchart>
			</v-col>
		</v-row>
		<v-row v-if="hayData && !loading">
			<v-col cols="12" v-for="index in 3" :key="`colTable-${index}`">
				<p
					class="text-h5 text-center font-weight-bold"
					:key="`title-${index}`"
				>
					{{ getWeekOrMonthSelected }} - Top 3
					{{ getKPIType(index) }}
				</p>
				<v-data-table
					:items="index == 1 ? downtime : index == 2 ? mttr : mtbf"
					:headers="headersGraficasComputed(index)"
					:items-per-page="3"
					hide-default-footer
					:key="`table-${index}`"
					:loading="index == 1 ? loadingDownTime : index == 2 ? loadingMTTR : loadingMTBF"
				>
					<template v-slot:[`item.row`]="{ index: idx }">
						{{ idx + 1 }}
					</template>
					<template v-slot:[`item.w`]="{ item }">
						{{ item.w ? item.w : getWeekOrMonthSelected }}
					</template>
					<template v-slot:[`item.porcentaje`]="{ item }">
						{{ item.porcentaje.toFixed(2) }}
						{{ index != 1 ? "hrs" : "%" }}
					</template>
					<template v-slot:[`item.responsable`]="{ item }">
						{{ item.responsable ? item.responsable : "N/D" }}
					</template>
					<template v-slot:[`item.problema`]="{ item }">
						{{ item.problema ? item.problema : "N/D" }}
					</template>
					<template v-slot:[`item.fechaInicio`]="{ item }">
						{{ item.fechaInicio ? item.fechaInicio : "N/D" }}
					</template>
					<template v-slot:[`item.fechaFin`]="{ item }">
						{{ item.fechaFin ? item.fechaFin : "N/D" }}
					</template>
					<template v-slot:[`item.acciones`]="{ item }">
						{{ item.acciones == null ? "N/D" : item.acciones }}
					</template>
					<template v-slot:[`item.actions`]="{ item, index: top }">
						<div v-if="filtros.monthOrWeek.length > 1">
							
						</div>
						<v-tooltip v-else bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									@click="showDialogEdit(item, index, top)"
								>
									edit
									
								</v-icon>
							</template>
							<span class="white--text">Editar</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<v-row
			v-if="
				(filtros.year == null || filtros.monthOrWeek == null) &&
				!loading
			"
		>
			<v-col cols="12">
				<v-alert text dense color="blue darken-2" class="mb-0">
					<div class="d-flex align-center justify-center py-3">
						<p class="mb-0" style="font-size: 16px !important">
							Debe indicar el Año y
							{{ type == 1 ? "la Semana" : "el Mes" }}
						</p>
					</div>
				</v-alert>
			</v-col>
		</v-row>
		<v-row v-if="loading">
			<v-col cols="12" class="text-center">
				<p class="text-h4 text-center">Cargando</p>
				<v-progress-circular
					:size="80"
					:width="5"
					color="primary"
					indeterminate
				></v-progress-circular>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import axios from "axios";
import qs from "qs";

export default {
	props: {
		selectores: {
			type: Array,
			default: null,
		},
		type: {
			type: Number,
			default: 1,
		},
		departamentos: {
			type: Array,
			default: () => [],
		},
		areas: {
			type: Array,
			default: () => [],
		}
	},
	data() {
		return {
			meses:[],
			saving: false,
			seriesDownTime: [],
			seriesMTTR: [],
			seriesMTBF: [],
			seriesReliability: [],
			defaultChartOptions: {
				chart: {
					type: "bar",
					height: 350,
				},
				plotOptions: {
					bar: {
						horizontal: false,
						columnWidth: "55%",
						endingShape: "rounded",
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					show: true,
					width: 2,
					colors: ["transparent"],
				},
				xaxis: {
					labels: {
            rotate: -90
          },
					categories: [],
				},
				yaxis: {
					min: 0,
				},
				fill: {
					opacity: 1,
				},
				tooltip: {},
			},
			chartOptionsDownTime: null,
			chartOptionsMTTR: null,
			chartOptionsMTBF: null,
			chartOptionsReliability: null,
			errorFechaInicio: "",
			errorFechaFin: "",
			dialog: false,
			errorResponsable: "",
			errorOptions: "",
			editedItem: {
				row: null,
				area: null,
				responsable: null,
				fechaInicio: null,
				fechaFin: null,
				options: null,
				type: null,
				problema:null
			},
			defaultEditedItem: {
				row: null,
				area: null,
				responsable: null,
				fechaInicio: null,
				fechaFin: null,
				options: null,
				type: null,
				problema:null
			},
			mtbf: [],
			reliability: [],
			mttr: [],
			downtime: [],
			headersGraficas: [
				{
					text: "#",
					align: "center",
					value: "row",
				},
				{
					text: this.type == 1 ? "Week" : "Month",
					align: "center",
					value: "w",
				},
				{
					text: "K.P.I.",
					align: "center",
					value: "kpi",
				},
				{
					text: "",
					align: "center",
					value: "porcentaje",
				},
				{
					text: "Lines",
					align: "center",
					value: "linea",
				},
				{
					text: "Responsible",
					align: "center",
					value: "responsable",
				},
				{
					text: "Problem",
					align: "center",
					value: "problema",
				},
				{
					text: "Start Date",
					align: "center",
					value: "fechaInicio",
				},
				{
					text: "Finish Date",
					align: "center",
					value: "fechaFin",
				},
				{
					text: "Actions",
					align: "center",
					value: "acciones",
				},
				{
					text: "",
					align: "center",
					value: "actions",
				},
			],
			downloading: false,
			loadingDownTime: false,
			loadingMTBF: false,
			loadingMTTR: false,
			loadingReliability: false,
			loadingSelectores: false,
			filtros: {
				year: null,
				monthOrWeek: null,
				departamento: null,
				lineaArea: null,
			},
			bypassLoading: false,
			actual: null
		};
	},
	computed: {
		...mapState(["token"]),
		hayData() {
			return (
				(this.seriesDownTime.length > 0 &&
				this.seriesMTTR.length > 0 &&
				this.seriesMTBF.length > 0 &&
				this.seriesReliability.length > 0)
			);
		},
		mesesFiltrados(){
			if(this.type == 1)
				return [];
			else{
				if(this.filtros.monthOrWeek == null || this.filtros.monthOrWeek.length == 0)
					return this.meses;
				else{
					var max = Math.max.apply(Math, this.filtros.monthOrWeek);
					var min = Math.min.apply(Math, this.filtros.monthOrWeek);

					if(min >= 2) min -= 1;
					if(max <= 11) max += 1;
					return this.meses.map(x=>({...x, disabled:!(x.value >= min && x.value <= max)}))
				} 
			}
		},
		loading() {
			return (this.loadingDownTime || this.loadingMTTR || this.loadingMTBF || this.loadingReliability) && !this.bypassLoading;
		},
		getWeekOrMonthSelected() {
			const result =
				this.type == 1
					? this.weeks.find(
							(item) => item.week == this.filtros.monthOrWeek
					  )
					: this.filtros.monthOrWeek != null ? this.months.find((item) => item.value == this.filtros.monthOrWeek[0]) : this.months.find((item) => item.value == this.filtros.monthOrWeek);

			if (result) {
				moment.locale("en");
				return this.type == 1
					? `Week ${result.name
							.split("-")[0]
							.trim()
							.split(" ")[1]
							.trim()}`
					: this.filtros.monthOrWeek != null ?
					this.filtros.monthOrWeek.length == 1 ?
					moment().month(result.value - 1).format("MMMM") : (moment().month(Math.min.apply(Math, [...this.filtros.monthOrWeek]) - 1).format("MMMM") +" to "+ moment().month(Math.max.apply(Math, [...this.filtros.monthOrWeek]) - 1).format("MMMM")) : "N/D";
			} else return "N/D";
		},
		months() {
			moment.locale("es");
			this.meses = moment.months().map((mes, idx) => ({
				text: `${mes[0].toUpperCase()}${mes.slice(1)}`,
				value: idx + 1,
				disabled: false
			}));
			return this.meses;
		},
		years() {
			return this.selectores == null
				? []
				: this.selectores.years
						.map((y) => y.year)
						.sort((a, b) => b - a);
		},
		weeks() {
			if (this.filtros.year == null) {
				this.filtros.monthOrWeek = null;
				return [];
			}

			const result = this.selectores.years.filter(
				(y) => y.year == this.filtros.year
			);
			return result.length == 1
				? result[0].weeks.sort((a, b) => b.week - a.week)
				: [];
		},
	},
	beforeMount() {
		this.setDefaultChartOptions();
	},
	mounted() {
		this.initialize();
	},
	methods: {
		descargarGraficas(){
			var datetime = moment().format('YYYYMMDDHHmmss');
			const filename = 'reporte-graficas-'+datetime+'.pdf';
			const mime = "application/pdf";
			this.downloading = true;
			axios({
				url: `/AnaliticaMonterrey/DescargarPDFgraficas`,
				method: 'GET',
				responseType: 'blob',
				params: {
					access_token: this.token,
					tipo: this.type,
					anhio: this.filtros.year,
					mesesList: this.type == 2 ? this.filtros.monthOrWeek : null,
					numSemana:
						this.type == 1 ? this.filtros.monthOrWeek : null,
					departamento: this.filtros.departamento,
					lineaArea: this.filtros.lineaArea,
				},
				paramsSerializer: (params) => qs.stringify( params, { indices: false, skipNulls: true } )
			})
			.then(response => {
				let blob = new Blob([response.data], { type: mime })
				let a = document.createElement("a") 
				let blobURL = URL.createObjectURL(blob)
				a.download = filename
				a.href = blobURL
				document.body.appendChild(a)
				a.click()
				document.body.removeChild(a)
			})
			.catch(error => {
				console.log(error);
			})
			.finally( () => {
				this.downloading = false;
			})
		},
		descargarGraficasTabloide(){
			var datetime = moment().format('YYYYMMDDHHmmss');
			const filename = 'reporte-graficas-tabloide'+datetime+'.pdf';
			const mime = "application/pdf";
			this.downloading = true;
			axios({
				url: `/AnaliticaMonterrey/DescargarPDFGraficasTabloide`,
				method: 'GET',
				responseType: 'blob',
				params: {
					access_token: this.token,
					tipo: this.type,
					anhio: this.filtros.year,
					mesesList: this.type == 2 ? this.filtros.monthOrWeek : null,
					numSemana:
						this.type == 1 ? this.filtros.monthOrWeek : null,
					departamento: this.filtros.departamento,
					lineaArea: this.filtros.lineaArea,
				},
				paramsSerializer: (params) => qs.stringify( params, { indices: false, skipNulls: true } )
			})
			.then(response => {
				let blob = new Blob([response.data], { type: mime })
				let a = document.createElement("a") 
				let blobURL = URL.createObjectURL(blob)
				a.download = filename
				a.href = blobURL
				document.body.appendChild(a)
				a.click()
				document.body.removeChild(a)
			})
			.catch(error => {
				console.log(error);
			})
			.finally( () => {
				this.downloading = false;
			})
		},
		headersGraficasComputed(kpi) {
			return this.headersGraficas.map((head) => ({
				...head,
				text:
					kpi == 1 && head.value == "porcentaje"
						? "%"
						: kpi != 1 && head.value == "porcentaje"
						? "Hours"
						: head.text,
			}));
		},
		getKPIType(type) {
			return type == 1 ? "DOWN TIME" : type == 2 ? "MTTR" : type == 3 ? "MTBF" : "RELIABILITY";
		},
		setDefaultsDownTime() {
			this.seriesDownTime = [];
			this.chartOptionsDownTime = { ...this.defaultChartOptions };
			this.chartOptionsDownTime.colors = ["#D32F2F"];
			this.chartOptionsDownTime.fill = {
			  colors: [function({ value, seriesIndex, w }) {
			    if(value >= 0 && value <= 3.8) return '#008000';
					else return "#D32F2F";
			  }]
			};
			this.chartOptionsDownTime.xaxis = Object.assign(
				{},
				this.chartOptionsDownTime.xaxis,
				{ categories: [] }
			);
			this.chartOptionsDownTime.yaxis = Object.assign(
				{},
				this.chartOptionsDownTime.yaxis,
				{
					title: {
						text: "% Percentage",
					},
					labels: {
						formatter: function (val) {
							return val.toFixed(2) + "%";
						},
					},
					min: 0,
					y: {
						formatter: function (val) {
							return val.toFixed(2) + "%";
						},
					},
				}
			);
			this.chartOptionsDownTime.tooltip = Object.assign(
				{},
				this.chartOptionsDownTime.tooltip,
				{
					y: {
						formatter: function (val) {
							return val.toFixed(2) + "%";
						},
					},
				}
			);
		},
		setDefaultsMTTR() {
			this.seriesMTTR = [];
			this.chartOptionsMTTR = { ...this.defaultChartOptions };
			this.chartOptionsMTTR.colors = ["#F57C00"];
			this.chartOptionsMTTR.fill = {
			  colors: [function({ value, seriesIndex, w }) {
			    if(value >= 0 && value <= 0.25) return '#008000';
					else return "#D32F2F";
			  }]
			};
			this.chartOptionsMTTR.xaxis = Object.assign(
				{},
				this.chartOptionsMTTR.xaxis,
				{ categories: [] }
			);
			this.chartOptionsMTTR.yaxis = Object.assign(
				{},
				this.chartOptionsMTTR.yaxis,
				{
					title: {
						text: "Hours",
					},
					labels: {
						formatter: function (val) {
							return val.toFixed(2);
						},
					},
					min: 0,
				}
			);
			this.chartOptionsMTTR.tooltip = Object.assign(
				{},
				this.chartOptionsMTTR.tooltip,
				{
					y: {
						formatter: function (val) {
							return `${val.toFixed(2)} hrs`;
						},
					},
				}
			);
		},
		setDefaultsMTBF() {
			this.seriesMTBF = [];
			this.chartOptionsMTBF = { ...this.defaultChartOptions };
			this.chartOptionsMTBF.colors = ["#1976D2"];
			this.chartOptionsMTBF.fill = {
			  colors: [function({ value, seriesIndex, w }) {
			    if(value >= 12 && value <= 744) return '#008000';
					else return "#D32F2F";
			  }]
			};
			this.chartOptionsMTBF.xaxis = Object.assign(
				{},
				this.chartOptionsMTBF.xaxis,
				{ categories: [] }
			);
			this.chartOptionsMTBF.yaxis = Object.assign(
				{},
				this.chartOptionsMTBF.yaxis,
				{
					title: {
						text: "Hours",
					},
					labels: {
						formatter: function (val) {
							return val.toFixed(2);
						},
					},
					min: 0,
				}
			);
			this.chartOptionsMTBF.tooltip = Object.assign(
				{},
				this.chartOptionsMTBF.tooltip,
				{
					y: {
						formatter: function (val) {
							return `${val.toFixed(2)} hrs`;
						},
					},
				}
			);
		},
		setDefaultsReliability() {
			this.seriesReliability = [];
			this.chartOptionsReliability = { ...this.defaultChartOptions };
			this.chartOptionsReliability.colors = ["#1976D2"];
			this.chartOptionsReliability.fill = {
			  colors: [function({ value, seriesIndex, w }) {
			    if(value >= 12 && value <= 744) return '#008000';
					else return "#D32F2F";
			  }]
			};
			this.chartOptionsReliability.xaxis = Object.assign(
				{},
				this.chartOptionsReliability.xaxis,
				{ categories: [] }
			);
			this.chartOptionsReliability.yaxis = Object.assign(
				{},
				this.chartOptionsReliability.yaxis,
				{
					title: {
						text: "% Percentage",
					},
					labels: {
						formatter: function (val) {
							return val.toFixed(2)+"%";
						},
					},
					min: 0,
				}
			);
			this.chartOptionsReliability.tooltip = Object.assign(
				{},
				this.chartOptionsReliability.tooltip,
				{
					y: {
						formatter: function (val) {
							return `${val.toFixed(2)}%`;
						},
					},
				}
			);
		},
		setDefaultChartOptions() {
			this.setDefaultsDownTime();
			this.setDefaultsMTTR();
			this.setDefaultsMTBF();
			this.setDefaultsReliability();
		},
		getSeries(index) {
			if (index == 1) return this.seriesDownTime;
			else if (index == 2) return this.seriesMTTR;
			else if(index == 3) return this.seriesMTBF;
			else return this.seriesReliability;
		},
		getChartOptions(index) {
			if (index == 1) return this.chartOptionsDownTime;
			else if (index == 2) return this.chartOptionsMTTR;
			else if(index == 3) return this.chartOptionsMTBF;
			else return this.chartOptionsReliability;
		},
		validate() {
			this.errorResponsable = "";
			this.errorFechaInicio = "";
			this.errorFechaFin = "";
			this.errorOptions = "";

			if (this.editedItem.responsable == null) {
				this.errorResponsable = "Debe indicar el Responsable";
			} else if (this.editedItem.responsable.trim().length < 5) {
				this.errorResponsable = "Debe introducir al menos 5 caracteres";
			}

			if (this.editedItem.fechaInicio == null) {
				this.errorFechaInicio = "Debe indicar la Fecha de Inicio";
			}

			if (this.editedItem.fechaFin == null) {
				this.errorFechaFin = "Debe indicar la Fecha de Fin";
			}

			if (this.editedItem.options == null) {
				this.errorOptions = "Debe indicar las Acciones realizadas";
			} else if (this.editedItem.options.trim().length < 5) {
				this.errorOptions = "Debe introducir al menos 5 caracteres";
			}

			return (
				this.errorResponsable == "" &&
				this.errorFechaInicio == "" &&
				this.errorFechaFin == "" &&
				this.errorOptions == ""
			);
		},
		save() {
			if (!this.validate()) return;

			this.saving = true;
			axios
				.post("/ReporteResponsable/Crear", {
					tipo:
						this.editedItem.type == 1
							? "DT"
							: this.getKPIType(this.editedItem.type),
					area: this.editedItem.area,
					anhio: this.filtros.year,
					mes: this.type == 2 ? this.filtros.monthOrWeek : null,
					semana: this.type == 1 ? this.filtros.monthOrWeek : null,
					responsable: this.editedItem.responsable,
					fechaInicio: this.editedItem.fechaInicio,
					fechaFin: this.editedItem.fechaFin,
					acciones: this.editedItem.options,
					departamento: this.filtros.departamento,
					problema:this.editedItem.problema
					//lineaArea: this.filtros.lineaArea,
				})
				.then(() => {
					this.saving = false;
					this.bypassLoading = true;
					if(this.editedItem.type == 1){
						this.getDownTimeData();
					}
					else if(this.editedItem.type == 2){
						this.getMTTRData();
					}
					else if(this.editedItem.type == 3){
						this.getMTBFData();
					}else if(this.editedItem.type == 4){
						this.getReliabilityData()
					}
					this.cerrarDialog();
				})
				.catch((error) => {
					this.saving = false;
					console.log(error);
				});
		},
		resetErrorFecha(errorFecha) {
			if (errorFecha == "errorFechaInicio") {
				this.errorFechaInicio = "";
			} else {
				this.errorFechaFin = "";
			}
		},
		showDialogEdit(item, type, top) {
			this.editedItem = { ...item, options: item.acciones, type };
			this.editedItem.row = top + 1;
			this.dialog = true;
		},
		cerrarDialog() {
			this.dialog = false;
			this.editedItem = { ...this.defaultEditedItem };
			this.errorResponsable = "";
			this.errorOptions = "";
			this.errorFechaInicio = "";
			this.errorFechaFin = "";
		},
		getDownTimeData() {
			this.loadingDownTime = true;
			
			axios
				.get("/AnaliticaMonterrey/SemanaMes", {
					params: {
						tipo: this.type,
						anhio: this.filtros.year,
						mesesList: this.type == 2 ? this.filtros.monthOrWeek : null,
						numSemana:
							this.type == 1 ? this.filtros.monthOrWeek : null,
						departamento: this.filtros.departamento,
						lineaArea: this.filtros.lineaArea,
					},
					paramsSerializer: (params) => qs.stringify( params, { indices: false, skipNulls: true } )
				})
				.then((response) => {
					this.chartOptionsDownTime.xaxis = Object.assign(
						{},
						this.chartOptionsDownTime.xaxis,
						{ categories: response.data.categories }
					);

					this.seriesDownTime = response.data.series;
					this.downtime = response.data.table;
				})
				.catch((error) => {
					this.setDefaultsDownTime();
					console.log(error);
				})
				.finally(() => {
					this.loadingDownTime = false;
					if(this.bypassLoading) this.bypassLoading = false;
				});
		},
		getMTTRData() {
			this.loadingMTTR = true;
			axios
				.get("/AnaliticaMonterrey/SemanaMesMTTR", {
					params: {
						tipo: this.type,
						anhio: this.filtros.year,
						mesesList: this.type == 2 ? this.filtros.monthOrWeek : null,
						numSemana:
							this.type == 1 ? this.filtros.monthOrWeek : null,
						departamento: this.filtros.departamento,
						lineaArea: this.filtros.lineaArea,
					},
					paramsSerializer: (params) => qs.stringify( params, { indices: false, skipNulls: true } )
				})
				.then((response) => {
					this.chartOptionsMTTR.xaxis = Object.assign(
						{},
						this.chartOptionsMTTR.xaxis,
						{ categories: response.data.categories }
					);

					this.seriesMTTR = response.data.series;
					this.mttr = response.data.table;
				})
				.catch((error) => {
					this.setDefaultsMTTR();
					console.log(error);
				})
				.finally(() => {
					this.loadingMTTR = false;
					if(this.bypassLoading) this.bypassLoading = false;
				});
		},
		getMTBFData() {
			this.loadingMTBF = true;
			
			axios
				.get("/AnaliticaMonterrey/SemanaMesMTBF", {
					params: {
						tipo: this.type,
						anhio: this.filtros.year,
						mesesList: this.type == 2 ? this.filtros.monthOrWeek : null,
						numSemana:
							this.type == 1 ? this.filtros.monthOrWeek : null,
						departamento: this.filtros.departamento,
						lineaArea: this.filtros.lineaArea,
					},
					paramsSerializer: (params) => qs.stringify( params, { indices: false, skipNulls: true } )
				})
				.then((response) => {
					this.chartOptionsMTBF.xaxis = Object.assign(
						{},
						this.chartOptionsMTBF.xaxis,
						{ categories: response.data.categories }
					);

					this.seriesMTBF = response.data.series;
					this.mtbf = response.data.table;
				})
				.catch((error) => {
					this.setDefaultsMTBF();
					console.log(error);
				})
				.finally(() => {
					this.loadingMTBF = false;
					if(this.bypassLoading) this.bypassLoading = false;
				});
		},
		getReliabilityData() {
			this.loadingReliability = true;
			
			axios
				.get("/AnaliticaMonterrey/SemanaMesReliability", {
					params: {
						tipo: this.type,
						anhio: this.filtros.year,
						mesesList: this.type == 2 ? this.filtros.monthOrWeek : null,
						numSemana:
							this.type == 1 ? this.filtros.monthOrWeek : null,
						departamento: this.filtros.departamento,
						lineaArea: this.filtros.lineaArea,
					},
					paramsSerializer: (params) => qs.stringify( params, { indices: false, skipNulls: true } )
				})
				.then((response) => {
					this.chartOptionsReliability.xaxis = Object.assign(
						{},
						this.chartOptionsReliability.xaxis,
						{ categories: response.data.categories }
					);

					this.seriesReliability = response.data.series;
					this.reliability = response.data.table;
				})
				.catch((error) => {
					this.setDefaultsReliability();
					console.log(error);
				})
				.finally(() => {
					this.loadingReliability = false;
					if(this.bypassLoading) this.bypassLoading = false;
				});
		},
		getDatosGraficas() {			
			if (this.filtros.year == null) {
				this.resetValues();
				return;
			}else if(this.filtros.monthOrWeek == null){
				this.resetValues();
				return;
			}
			this.actual = this.filtros.monthOrWeek.length;
			this.getDownTimeData();

			this.getMTTRData();

			this.getMTBFData();
			this.getReliabilityData();
		},
		resetValues() {
			this.loadingDownTime = false;
			this.loadingMTTR = false;
			this.loadingMTBF = false;
			this.mtbf = [];
			this.mttr = [];
			this.downtime = [];
			this.reliability = [];
			this.loadingReliability = false;
			this.setDefaultChartOptions();
			
			this.filtros.year = null;
			this.filtros.monthOrWeek = null;
			this.filtros.departamento = null;
			this.filtros.lineaArea = null;
		},
		limpiar() {
			this.loadingDownTime = false;
			this.loadingMTTR = false;
			this.loadingMTBF = false;
			this.loadingReliability = false;
			this.mtbf = [];
			this.mttr = [];
			this.downtime = [];
			this.reliability = [];

			this.setDefaultChartOptions();
			
			//this.filtros.year = null;
			//this.filtros.monthOrWeek = null;
			/* this.filtros.departamento = null;
			this.filtros.lineaArea = null; */
		},
		changeMonthOrWeek() {
			var actual = this.actual == null ? 0 : this.actual;
			if(this.type == 1){
				this.limpiar();
			}	

			if (actual > this.filtros.monthOrWeek.length || actual < this.filtros.monthOrWeek.length){ 
				this.limpiar();
			}else if (this.filtros.monthOrWeek == null || this.filtros.monthOrWeek.length == 0){ 
				this.resetValues();
			}else if (this.filtros.year == null || this.filtros.year.length == 0) {
				this.resetValues();
				return;
			}
			
		},
		changeYear() {
			this.filtros.monthOrWeek = null;
		},
		initialize() {
		},
	},
};
</script>