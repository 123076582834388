<template>
	<!-- <v-row> -->
	<v-col cols="12" md="12" sm="12" class="pa-0 ma-0">
		<v-dialog v-model="dialogRecursos" persistent max-width="40%">
			<v-card>
				<v-card-title>Agregar Recurso</v-card-title>
				<v-card-text>
					<v-select
						v-model="itemRecurso.tipo"
						:items="tiposRecursos"
						item-text="tipo"
						item-value="id"
						label="Tipo de Recurso"
						:error-messages="itemRecurso.errorTipo"
						@focus="$set(itemRecurso,'errorTipo',null)"
						@change="onChangeTipo()"
					></v-select>
					<v-text-field
						v-if="itemRecurso.tipo == 1 || itemRecurso.tipo == 2"
						v-model="itemRecurso.recursos.titulo"
						label="Titulo"
						clearable
						:error-messages="itemRecurso.errorTitulo"
						@focus="$set(itemRecurso,'errorTitulo',null)"
					></v-text-field>
					<v-file-input
						v-model="itemRecurso.recursos.idDocumento"
						v-if="itemRecurso.tipo == 1"
						label="Seleccione un archivo"
						accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.jpg,.jpeg,.png,.gif,.svg"
						show-size
						:error-messages="itemRecurso.errorDocumento"
						@focus="$set(itemRecurso,'errorDocumento',null)"
						@change="cambioFicheroRecurso"
					></v-file-input>
					<v-text-field
						v-model="itemRecurso.recursos.links"
						v-if="itemRecurso.tipo == 2"
						label="Introduzca la URL"
						:error-messages="itemRecurso.errorLink"
						@focus="$set(itemRecurso,'errorLink',null)"
					></v-text-field>
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn
						color="error"
						text
						:disabled="subiendoArchivos"
						@click="cerrarDialogRecursos"
						>Cancelar</v-btn
					>
					<v-btn
						color="success"
						text
						:loading="subiendoArchivos"
						:disabled="subiendoArchivos"
						@click="agregarRecurso"
						>Agregar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-expansion-panels v-model="panel">
			<v-expansion-panel>
				<v-expansion-panel-header>
					Lista de Comprobación
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<v-data-table
						:headers="headers"
						:items="item.comprobaciones"
						loading-text="Cargando... Espere, Por Favor."
						:loading="loading"
						class="elevation-0"
						ref="listadotodo"
						hide-default-footer
						disable-pagination
						@hook:mounted="crearDragable"
					>
						<template v-slot:item="{ item }">
							<tr
								class="sortableRow"
								:key="`row-${item.idMantenimientoComprobacion}`"
							>
								<td class="px-1" style="width: 0.1%">
									<v-btn
										style="cursor: move"
										icon
										class="sortHandle"
										><v-icon color="black"
											>mdi-menu</v-icon
										></v-btn
									>
								</td>
								<td class="text-center">
									<v-text-field
										v-model="item.comprobacion"
									></v-text-field>
								</td>
								<td class="text-center">
									<v-chip-group column>
										<v-chip
											v-for="(documento, idx) in item.recursos.idsDocumentos"
											:key="'docs-'+idx"
											close
											outlined
											color="blue"
											:href="`/api/Documentos/Descargar/${documento.nombre}?access_token=${token}`"
											@click:close="removerRecurso(item,documento)"
										>
											<v-icon left>mdi-file-outline</v-icon>
											{{ documento.nombre }}
											<v-icon right size="18" class="pr-1">mdi-download</v-icon>
										</v-chip>
										<v-chip
											v-for="(link, idx) in item.recursos.links"
											:key="'links-'+idx"
											close
											outlined
											color="blue"
											:href="link.url.includes('http') ? link.url : `https://${link.url}`"
											target="_blank"
											@click:close="removerRecurso(item, link)"
										>
											<v-icon left>mdi-link</v-icon>
											{{ link.titulo }}
											<v-icon right size="18" class="pr-1">mdi-open-in-new</v-icon>
										</v-chip>
									</v-chip-group>
									<div class="d-block">
										<v-btn
											color="light-blue lighten-1"
											outlined
											dark
											x-small
											@click="
												showDialogRecursos(
													item.comprobacion
												)
											"
											>Agregar</v-btn
										>
									</div>
								</td>
								<td class="text-center">
									<v-tooltip
										
										bottom
										color="black"
										class="white--text"
									>
										<template v-slot:activator="{ on }">
											<v-icon
												v-on="on"
												small
												class="mr-2"
												@click="
													removerComprobacion(item)
												"
											>
												mdi-delete
											</v-icon>
										</template>
										<span class="white--text"
											>Eliminar</span
										>
									</v-tooltip>
								</td>
							</tr>
						</template>
					</v-data-table>
					<div class="text-center">
						<v-btn
							color="green"
							class="my-2"
							outlined
							@click="agregarComprobacion"
							>Agregar Comprobación</v-btn
						>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</v-col>
	<!-- </v-row> -->
</template>

<script>
import Sortable from "sortablejs";
import axios from "axios";
import { mapState } from 'vuex';

export default {
	props: {
		item: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			validTitle: false,
			panel: 0,
			panelComprobacion: false,
			subiendoArchivos: false,
			itemRecurso: {
				idMantenimientoComprobacion: -1,
				comprobacion: "",
				tipo: null,
				errorTipo: null,
				errorTitulo: null,
				errorDocumento: null,
				errorLink: null,
				recursos: {
					titulo: null,
					idDocumento: null,
					idsDocumentos: [],
					links: [],
				},
			},
			defaultItemRecurso: {
				idMantenimientoComprobacion: -1,
				comprobacion: "",
				tipo: null,
				errorTipo: null,
				errorTitulo: null,
				errorDocumento: null,
				errorLink: null,
				recursos: {
					titulo: null,
					idDocumento: null,
					idsDocumentos: [],
					links: [],
				},
			},
			newIndex: 0,
			tiposRecursos: [
				{
					id: 1,
					tipo: "Interno",
				},
				{
					id: 2,
					tipo: "Externo",
				},
			],
			dialogRecursos: false,
			loading: false,
			drag: false,
			urlValida: false,
			headers: [
				{
					sortable: false,
				},
				{
					text: "Comprobación",
					align: "center",
					sortable: false,
					value: "comprobacion",
					width: "30%",
				},
				{
					text: "Recursos",
					align: "center",
					sortable: false,
					value: "recursos",
					width: "60%",
				},
				{
					text: "",
					align: "center",
					sortable: false,
					value: "actions",
					width: "10%",
				},
			],
			rules: {
				validarURL: (value) => {
					const expression =
						/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
					const regex = new RegExp(expression);
					return value.match(regex)
				},
			},
		};
	},
	computed: {
		...mapState(['token'])
	},
	methods: {
		onChangeTipo(){
			this.$set(this.itemRecurso.recursos,'titulo',null);
			this.$set(this.itemRecurso.recursos,'idDocumento',null);
			this.$set(this.itemRecurso.recursos,'links',[]);
			this.resetErrores();
		},
		togglePanel(value){
			this.newIndex = 0;
			this.panel = value;
		},
		getListado() {
			const listado = this.item.comprobaciones.map((item) => ({
				idMantenimientoComprobacion:
					item.idMantenimientoComprobacion == undefined
						? -1
						: item.idMantenimientoComprobacion,
				comprobacion: item.comprobacion,
				idsDocumentos: item.recursos.idsDocumentos.map(
					(documento) => documento.idDocumento
				),
				links: item.recursos.links,
			}));
			return listado;
		},
		crearDragable() {
			new Sortable(
				this.$refs.listadotodo.$el.getElementsByTagName("tbody")[0],
				{
					draggable: ".sortableRow",
					handle: ".sortHandle",
					onEnd: this.dragReorder,
				}
			);
			this.panel = 0;
		},
		reset() {
			this.itemRecurso = JSON.parse(JSON.stringify(this.defaultItemRecurso));
		},
		removerComprobacion(itemDelete) {
			this.$set(this.item,'comprobaciones',this.item.comprobaciones.filter(
				(item) => JSON.stringify(item) != JSON.stringify(itemDelete)
			));
		},
		removerRecurso(itemDelete, resourceDelete) {
			this.$set(this.item,'comprobaciones',this.item.comprobaciones.map((item) => {
				if (JSON.stringify(item) == JSON.stringify(itemDelete)) {
					if (resourceDelete.idDocumento !=null) {
						this.$set(item.recursos,'idsDocumentos',item.recursos.idsDocumentos.filter(
							(doc) => doc.idDocumento != resourceDelete.idDocumento
						));
					} else {
						this.$set(item.recursos,'links',item.recursos.links.filter(
							(link) => link.titulo != resourceDelete.titulo
						));
					}
				}
				return item;
			}));
		},
		agregarComprobacion() {
			this.newIndex -= 1;
			const newObj = JSON.parse(JSON.stringify(this.defaultItemRecurso));
			newObj.idMantenimientoComprobacion = this.newIndex;
			this.item.comprobaciones.push(newObj);
		},
		resetErrores(){
			this.$set(this.itemRecurso,'errorTipo',null);
			this.$set(this.itemRecurso,'errorTitulo',null);
			this.$set(this.itemRecurso,'errorDocumento',null);
			this.$set(this.itemRecurso,'errorLink',null);
		},
		validateRecurso(){
			this.resetErrores();
			let hayErrores = false;

			if(this.itemRecurso.tipo == null){
				this.$set(this.itemRecurso,'errorTipo','Requerido');
				hayErrores = true;
			}
			else if (this.itemRecurso.tipo == 1){
				if(this.itemRecurso.recursos.titulo == null){
					this.$set(this.itemRecurso,'errorTitulo','Requerido');
					hayErrores = true;
				}
				else if(this.itemRecurso.recursos.titulo.length < 5){
					this.$set(this.itemRecurso,'errorTitulo','Mínimo 5 caracteres');
					hayErrores = true;
				}
				
				if(this.itemRecurso.recursos.idDocumento == null){
					this.$set(this.itemRecurso,'errorDocumento','Requerido');
					hayErrores = true;
				}
			}
			else if (this.itemRecurso.tipo == 2){
				if(this.itemRecurso.recursos.titulo == null){
					this.$set(this.itemRecurso,'errorTitulo','Requerido');
					hayErrores = true;
				}
				else if(this.itemRecurso.recursos.titulo.length < 5){
					this.$set(this.itemRecurso,'errorTitulo','Mínimo 5 caracteres');
					hayErrores = true;
				}

				if(
					this.itemRecurso.recursos.links == null ||
					this.itemRecurso.recursos.links?.length == 0
				){
					this.$set(this.itemRecurso,'errorLink','Requerido');
					hayErrores = true;
				}
				else if(!this.rules.validarURL(this.itemRecurso.recursos.links)){
					this.$set(this.itemRecurso,'errorLink','URL invalida');
					hayErrores = true;
				}
			}

			return !hayErrores;
		},
		agregarRecurso() {
			if(!this.validateRecurso()) return;

			if (this.itemRecurso.tipo == 2) {
				this.$set(this.item,'comprobaciones',this.item.comprobaciones.map((item) => {
					if (item.comprobacion == this.itemRecurso.comprobacion) {
						item.recursos.links.push({
							titulo: this.itemRecurso.recursos.titulo,
							url: this.itemRecurso.recursos.links
						});
					}
					return item;
				}));
				this.cerrarDialogRecursos();
			}

			if (this.itemRecurso.tipo == 1) {
				let documentos = new FormData();
				documentos.append("ficheros", this.itemRecurso.recursos.idDocumento);

				this.subiendoArchivos = true;

				axios
					.post(
						`/Documentos/Cargar?titulo=${this.itemRecurso.recursos.titulo}`,
						documentos,
						{
							headers: {
								"Content-Type": "multipart/form-data",
							},
						}
					)
					.then((response) => {
						const documentosSubidos = response.data.map((doc) => ({
							idDocumento: doc.idDocumento,
							nombre: doc.nombre,
						}));

						if (documentosSubidos.length > 0) {
							this.$set(this.item,'comprobaciones',this.item.comprobaciones.map((item) => {
								if (
									item.comprobacion ==
									this.itemRecurso.comprobacion
								) {
									item.recursos.idsDocumentos =
										item.recursos.idsDocumentos.concat(
											documentosSubidos
										);
								}
								return item;
							}));
						}
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => {
						this.subiendoArchivos = false;
						this.cerrarDialogRecursos();
					});
			}
		},
		showDialogRecursos(itemComprobacion) {
			this.itemRecurso.comprobacion = itemComprobacion;
			this.dialogRecursos = true;
		},
		cerrarDialogRecursos() {
			this.itemRecurso = JSON.parse(JSON.stringify(this.defaultItemRecurso))
			this.dialogRecursos = false;
		},
		cambioFicheroRecurso(event) {
			if (typeof event === "undefined" || event === null)
				this.itemRecurso.recursos.idDocumento = null;
			else this.itemRecurso.recursos.idDocumento = event;
		},
		dragReorder({ oldIndex, newIndex }) {
			const movedItem = this.item.comprobaciones.splice(oldIndex, 1)[0];
			this.item.comprobaciones.splice(newIndex, 0, movedItem);
		},
	},
};
</script>