<template>
	<v-row>
		<v-col cols="12">
			<v-expansion-panels v-model="panel">
				<v-expansion-panel>
					<v-expansion-panel-header>
						<div class="d-flex align-center">
							<v-badge
								color="error"
								:value="selectedFiltersCount"
								:content="selectedFiltersCount"
								overlap
								bordered
							>
								<v-icon size="22" left>mdi-filter-multiple</v-icon>
							</v-badge>
							<p
								class="mb-0"
								:class="{ 'pl-2': selectedFiltersCount > 0 }"
								style="font-size: 15px !important"
							>
								Filters
							</p>
						</div>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-row class="mt-0">
							<v-col cols="3">
								<datePicker
									v-model="value.from"
									format="YYYY-MM-DD"
									label="From"
									clearable
									:disabled="loading"
									@input="onChange()"
								></datePicker>
							</v-col>
							<v-col cols="3">
								<datePicker
									v-model="value.to"
									format="YYYY-MM-DD"
									label="To"
									:minDate="value.from"
									clearable
									:disabled="loading"
									@input="onChange()"
								></datePicker>
							</v-col>
							<v-col cols="3">
								<v-autocomplete
									v-model="value.idProject"
									label="Project"
									:items="projects"
									item-text="nombre"
									item-value="idProject"
									:disabled="loading || loadingProjects"
									:loading="loadingProjects"
									hide-details
									clearable
									@change="onChange()"
								></v-autocomplete>
							</v-col>
							<v-col cols="3">
								<v-select
									v-model="value.emergency"
									label="Emergency Form Approved"
									hide-details
									:items="emergencyTypes"
									clearable
									:disabled="loading"
									@change="onChange()"
								></v-select>
							</v-col>
							<v-col cols="3">
								<v-autocomplete
									v-model="value.idPurchaseClass"
									label="Purchase Class"
									:items="purchaseClasses"
									item-text="nombre"
									item-value="idPurchaseClass"
									hide-details
									:disabled="loading || loadingPurchaseClasses"
									:loading="loadingPurchaseClasses"
									clearable
									style="padding-top: 22px"
									@change="onChange()"
								></v-autocomplete>
							</v-col>
							<v-col cols="3">
								<v-autocomplete
									v-model="value.status"
									label="Status"
									:items="status"
									item-text="name"
									item-value="idMMCallStatu"
									hide-details
									:disabled="loading || loadingStatus"
									:loading="loadingStatus"
									multiple
									chips
									deletable-chips
									clearable
									@change="onChangeStatus"
								></v-autocomplete>
							</v-col>
							<v-col cols="3">
								<v-autocomplete
									v-model="value.idsMMCallStage"
									label="Stages"
									:items="stagesFiltered"
									item-text="name"
									item-value="idMMCallStage"
									:disabled="loading || loadingStages"
									:loading="loadingStages"
									hide-details
									multiple
									chips
									deletable-chips
									clearable
									return-object
									@change="onChange()"
								></v-autocomplete>
							</v-col>
							<v-col cols="3">
								<v-autocomplete
									v-model="value.po"
									label="PO"
									:items="poArray"
									:disabled="loading || loadingPo"
									:loading="loadingPo"
									hide-details
									multiple
									chips
									deletable-chips
									clearable
									@change="onChange()"
								></v-autocomplete>
							</v-col>
							<v-col cols="3">
								<v-autocomplete
									v-model="value.ioNumbers"
									label="IO Number"
									:items="ioNumbers"
									:disabled="loading || loadingIONumbers"
									:loading="loadingIONumbers"
									hide-details
									multiple
									chips
									deletable-chips
									clearable
									@change="onChange()"
								></v-autocomplete>
							</v-col>
							<v-col cols="3">
								<v-autocomplete
									v-model="value.farNumbers"
									label="FAR Number"
									:items="farNumbers"
									:disabled="loading || loadingFARNumbers"
									:loading="loadingFARNumbers"
									hide-details
									multiple
									chips
									deletable-chips
									clearable
									@change="onChange()"
								></v-autocomplete>
							</v-col>
							<v-col cols="3">
								<v-autocomplete
									v-model="value.idsUsuarios"
									label="User(s)"
									:items="users"
									item-text="nombre"
									item-value="idUsuario"
									:disabled="loading || loadingUsers"
									:loading="loadingUsers"
									hide-details
									multiple
									chips
									deletable-chips
									clearable
									@change="onChange()"
								></v-autocomplete>
							</v-col>
							<v-col cols="3">
								<v-autocomplete
									v-model="value.idsBuyers"
									label="Buyer(s)"
									:items="buyers"
									item-text="nombre"
									item-value="idPersona"
									:disabled="loading || loadingBuyers"
									:loading="loadingBuyers"
									hide-details
									multiple
									chips
									deletable-chips
									clearable
									@change="onChange()"
								></v-autocomplete>
							</v-col>
							<v-col cols="3">
								<v-autocomplete
									v-model="value.idsAreas"
									label="Area(s)"
									:items="purchaseAreas"
									item-text="nombre"
									item-value="idCompraArea"
									:disabled="loading || loadingPurchaseAreas"
									:loading="loadingPurchaseAreas"
									hide-details
									multiple
									chips
									deletable-chips
									clearable
									@change="onChange()"
								></v-autocomplete>
							</v-col>
							<v-col cols="3" class="py-0">
								<v-text-field
									:value="search"
									hide-details
									label="Search"
									:disabled="loading"
									clearable
									style="padding-top: 34px"
									@input="$emit('update:search', $event)"
								></v-text-field>
							</v-col>
						</v-row>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-col>
	</v-row>
</template>

<script>
export default {
	props: {
		value: {
			type: Object,
			default: () => ({
				from: null,
				to: null,
				idProject: null,
				emergency: null,
				idPurchaseClass: null,
				status: [],
				idsMMCallStage: [],
				po: [],
			}),
		},
		projects: {
			type: Array,
			default: () => [],
		},
		loadingProjects: {
			type: Boolean,
			default: false,
		},
		purchaseClasses: {
			type: Array,
			default: () => [],
		},
		loadingPurchaseClasses: {
			type: Boolean,
			default: false,
		},
		status: {
			type: Array,
			default: () => [],
		},
		loadingStatus: {
			type: Boolean,
			default: false,
		},
		stages: {
			type: Array,
			default: () => [],
		},
		loadingStages: {
			type: Array,
			default: false,
		},
		poArray: {
			type: Array,
			default: () => [],
		},
		loadingPo: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		ioNumbers: {
			type: Array,
			default: () => [],
		},
		loadingIONumbers: {
			type: Boolean,
			default: false,
		},
		farNumbers: {
			type: Array,
			default: () => [],
		},
		loadingFARNumbers: {
			type: Boolean,
			default: false,
		},
		users: {
			type: Array,
			default: () => [],
		},
		loadingUsers: {
			type: Boolean,
			default: false,
		},
		buyers: {
			type: Array,
			default: () => [],
		},
		loadingBuyers: {
			type: Boolean,
			default: false,
		},
		purchaseAreas: {
			type: Array,
			default: () => [],
		},
		loadingPurchaseAreas: {
			type: Boolean,
			default: false,
		},
		search: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			panel: null,
			emergencyTypes: [
				{
					text: "Yes",
					value: true,
				},
				{
					text: "No",
					value: false,
				},
			],
		};
	},
	computed: {
		selectedFiltersCount(){
			return Object.values(this.value).reduce((acc,value) => !value || value.length == 0 ? acc : acc + 1,0);
		},
		stagesFiltered() {
			if (this.value.status.length == 0) return this.stages;
			return this.stages.filter((stage) =>
				this.value.status.includes(stage.idMMCallStatu)
			);
		},
	},
	methods: {
		onChangeStatus() {
			this.value.idsMMCallStage = this.value.idsMMCallStage.filter(
				(stage) => this.value.status.includes(stage.idMMCallStatu)
			);
			this.onChange();
		},
		onChange() {
			this.$emit("change");
		},
	},
};
</script>