<template>
	<div>
		<v-tabs v-model="tab" background-color="transparent" grow center-active>
			<v-tab href="#tab-1" @click="clickCatalogoEquipos()">
				Catálogo de <br /> Equipos
			</v-tab>
			<v-tab href="#tab-2" @click="clickActualizacionMasivaEquipos()">
				Actualización Masiva de <br /> Equipos
			</v-tab>

			<v-tab href="#tab-3" @click="clickCatalogoCausas()">
				Catálogo de <br /> Causas
			</v-tab>

			<v-tab href="#tab-4" @click="clickCatalogoSubcausas()">
				Catálogo de <br /> Subcausas
			</v-tab>

			<v-tab href="#tab-5" @click="clickCatalogoSoluciones()">
				Catálogo de <br /> Soluciones
			</v-tab>
			<v-tab href="#tab-6" @click="clickCatalogoCargamasivasoluciones()">
				Carga Masiva de Causas, <br /> SubCausas y Soluciones
			</v-tab>
			<v-tab href="#tab-7" @click="clickCatalogoFactores()">
				Catalogo de<br /> Factores
			</v-tab>
			<v-tab href="#tab-8" @click="clickCatalogoSubfactores()">
				Catalogo de<br /> Subfactores
			</v-tab>
			<v-tab href="#tab-9" @click="clickCatalogoCargaMasivafactores()">
				Carga Masiva de Causas, <br /> Factores soluciones
			</v-tab>

			<v-tabs-items v-model="tab" touchless>
				<v-tab-item :key="1" :value="'tab-1'" eager>
					<CatalogoEquipos ref="catequipos" />
				</v-tab-item>

				<v-tab-item :key="2" :value="'tab-2'" eager>
					<ActualizacionMasivaEquipos ref="actuMasEquip" />
				</v-tab-item>

				<v-tab-item :key="3" :value="'tab-3'" eager>
					<CatalogoCausas ref="catcausas" />
				</v-tab-item>

				<v-tab-item :key="4" :value="'tab-4'" eager>
					<CatalogoSubcausas ref="catsubcausas" />
				</v-tab-item>

				<v-tab-item :key="5" :value="'tab-5'" eager>
					<CatalogoSoluciones ref="catsoluciones" />
				</v-tab-item>

				<v-tab-item :key="6" :value="'tab-6'" eager>
					<CargaMasivaSoluciones ref="cargamasivasoluciones" />
				</v-tab-item>
				<v-tab-item :key="7" :value="'tab-7'" eager>
					<CatalogoFactores ref="catalogofactores" />
				</v-tab-item>
				<v-tab-item :key="8" :value="'tab-8'" eager>
					<CatalogoSubfactores ref="catalogoSubfactores" />
				</v-tab-item>
				<v-tab-item :key="9" :value="'tab-9'" eager>
					<FactoresCargaMasivaSoluciones ref="cargamasivafactoressoluciones" />
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>
	</div>
</template>

<script>
import CargaMasivaSoluciones from './CargaMasivaSoluciones.vue';
import CatalogoCausas from './CatalogoCausas.vue';
import CatalogoSubcausas from './CatalogoSubcausas.vue';
import CatalogoSoluciones from './CatalogoSoluciones.vue';
import CatalogoFactores from './CatalogoFactores.vue';
import CatalogoSubfactores from './CatalogoSubfactores.vue';
import FactoresCargaMasivaSoluciones from './FactoresCargaMasivaSoluciones.vue';
import CatalogoEquipos from './CatalogoEquipos.vue';
import ActualizacionMasivaEquipos from './ActualizacionMasivaEquipos.vue'

export default {
	components: { 
        CatalogoCausas,
		CatalogoSubcausas,
        CatalogoSoluciones,
		CargaMasivaSoluciones,
		CatalogoFactores,
		CatalogoSubfactores,
		FactoresCargaMasivaSoluciones,
		CatalogoEquipos,
		ActualizacionMasivaEquipos
    },
	data: () => ({
		tab: 0,
	}),
	methods: {
		initialize() {
			//this.clickCatalogoSubcausas();
			//this.clickCatalogoCausas();
			//this.clickCatalogoSoluciones();
			
		},
		clickCatalogoEquipos(){
			if (typeof this.$refs.catequipos === "undefined") return;
			this.$refs.catequipos.initialize();
		},
		clickActualizacionMasivaEquipos(){
			if (typeof this.$refs.actuMasEquip === "undefined") return;
			this.$refs.actuMasEquip.initialize();
		},
		clickCatalogoCausas() {
			if (typeof this.$refs.catcausas === "undefined") return;
			this.$refs.catcausas.initialize();
		},
		clickCatalogoSubcausas() {
			if (typeof this.$refs.catsubcausas === "undefined") return;
			this.$refs.catsubcausas.initialize();
		},
		clickCatalogoSoluciones(){
			if (typeof this.$refs.catsoluciones === "undefined") return;
			this.$refs.catsoluciones.initialize();
		},
		clickCatalogoCargamasivasoluciones(){
			if (typeof this.$refs.cargamasivasoluciones === "undefined") return;
			this.$refs.cargamasivasoluciones.initialize();
		},
		clickCatalogoFactores(){
			if (typeof this.$refs.catalogofactores === "undefined") return;
			this.$refs.catalogofactores.initialize();
		},
		clickCatalogoSubfactores(){
			if (typeof this.$refs.catalogoSubfactores === "undefined") return;
			this.$refs.catalogoSubfactores.initialize();
		},
		clickCatalogoCargaMasivafactores(){
			if (typeof this.$refs.cargamasivafactoressoluciones === "undefined") return;
			this.$refs.cargamasivafactoressoluciones.initialize();
		}
	},
};
</script>