<template>
  <v-row>
    <v-col cols="12">
      <div class="text-center text-h3">
        Reporte de Piezas Terminadas
      </div>
      <v-progress-linear
        v-if="cargando"
        indeterminate
        color="blue darken-2"
      ></v-progress-linear>
      <Bar
        :chart-options="chartOptions"
        :chart-data="chartData"
      />
    </v-col>
  </v-row>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import axios from "axios"

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels)

export default {
  components: { 
    Bar
  },
  data: () => ({
    chartData: {
      labels: [],
      datasets: [
        {
          label: 'Piezas',
          backgroundColor: '#03A9F4',
          data: [],
        }
      ]
    },
    defaultChartData: {
      labels: [],
      datasets: [
        {
          label: 'Piezas',
          backgroundColor: '#03A9F4',
          data: [],
        }
      ]
    },
    chartOptions: {
      plugins: {
        legend: {
          title: false
        },
        datalabels: {
          anchor: 'end',
          align: 'start',
          color: 'white',
          display: function(context) {
            return context.dataset.data[context.dataIndex] >= 1;
          },
          font: {
            weight: 'bold',
            size: '30'
          },
          formatter: (value) => `${value} ${value == 1 ? 'pza' : 'pzas'}`
        }
      },
      indexAxis: 'y',
      responsive: true,
      maintainAspectRatio: false,
      height: '90%',
      scales: {
        y: {
          ticks: {
            font: {
              size: 20,
              weight: 'bold'
            }
          }
        }
      }
    },
    cargando: false,
    interval: null,
  }),
  mounted(){
    this.getData();
    this.interval = setInterval(() => {
      this.getData();
    },300000)
  },
  destroyed(){
    clearInterval(this.interval);
  },
  methods:{
    getData(){
      this.cargando = true;
      axios
      .get('/Reportes/ReportePiezasTerminadasProyeccion')
      .then(response => {
        this.cargando = false;
        var temp = Object.assign({},this.defaultChartData);
        temp.labels = response.data.labels.map(item => {
          const [almacen,codigo,articulo] = item.split('-',3);
          return [almacen.trim(),`${codigo.trim()} - ${articulo.trim()}`];
        });
        temp.datasets[0].data = response.data.data;
        this.chartData = temp;
      })
      .catch(error => {
        this.cargando = false;
        console.log(error);
      })
    }
  }
}
</script>