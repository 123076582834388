<template>
  <v-col cols="12">
    <v-card flat>
      <v-card-text v-if="errorCargaMasiva">
        <v-col cols="12" md="12" sm="12" class="text-center">
          <h3>Se ha producido un Error. Intente una vez más.</h3>
        </v-col>
        <v-col cols="12" md="12" sm="12" class="text-center">
          <v-btn
            depressed
            color="red"
            text
            @click="
              cargaMasivaInventario = false;
              cargaCargaCompleta = false;
              errorCargaMasiva = false;
              selectedUpdateFile = null;
            "
            >Aceptar</v-btn
          >
        </v-col>
      </v-card-text>
      <v-card-text v-if="cargaCargaCompleta">
        <v-col cols="12" md="12" sm="12" class="text-center">
          <h3>
            Carga Realizada Satisfactoriamente
          </h3>
          <v-btn
            depressed
            color="green"
            text
            @click="
              cargaMasivaInventario = false;
              cargaCargaCompleta = false;
              errorCargaMasiva = false;
              selectedUpdateFile = null;
            "
            >Aceptar</v-btn
          >
        </v-col>
      </v-card-text>
      <v-card-text
        v-if="!cargaCargaCompleta && !errorCargaMasiva"
        class="text-center"
      >
        <p class="mb-0 text-h6 text--secondary text-left">
          Carga Masiva de Factores
        </p>
        <v-file-input
          v-model="selectedUpdateFile"
          label="Fichero Carga .csv"
          accept=".csv"
          counter
          show-size
        ></v-file-input>
        <a @click="descargarFormatoSolciones">
          <v-icon color="blue">mdi-download</v-icon>Descargar Plantilla de Carga
          Masiva de Causas, SubCausas y Soluciones
        </a>
        <br />
        <br />
        <v-row>
          <v-col cols="12" class="py-0">
            <v-btn
              block
              color="blue"
              outlined
              class="my-2 mb-4"
              @click="cargaMasivaFactoresPost"
              >Cargar archivo de factores</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  data() {
    return {
      selectedUpdateFile: null,
      cargaMasiva: false,
      cargaCargaCompleta: false,
      errorCargaMasiva: false,
    };
  },
  computed: {
    ...mapState({
      token: "token",
    }),
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {},
    cargaMasivaFactoresPost() {
      if (this.selectedUpdateFile != null) {
        let formData = new FormData();
        formData.append("file", this.selectedUpdateFile);
        axios
          .post("/Factores/CargaMasivaSoluciones", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            this.initialize();
            this.cargaCargaCompleta = true;
          })
          .catch((error) => {
            this.errorCargaMasiva = true;
            console.log(error);
          });
      }
    },
    descargarFormatoSolciones() {
      var url =
        "/Factores/FormatoCargaMasivaFactorSubFactor?access_token=" +
        this.token;
      this.$utils.axios.downloadFile(
        url,
        "GET",
        "Formato_CargaMasiva_FactorSubFactor.csv",
        "text/csv"
      );
    },
  },
};
</script>
