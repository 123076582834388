<template>
  <v-container>
    <v-dialog v-model="eliminar" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >¿Está seguro que desea eliminar esta regla?</v-card-title
        >
        <v-card-text
          >Esta acción no se puede revertir y será permanente.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              eliminar = false;
              deletedItem = Object.assign({}, defaultItem);
            "
            >Cancelar</v-btn
          >
          <v-btn color="red darken-1" text @click="deleteItemDB()"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      :headers="headers"
      :items="reglas"
      :search="search"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-dialog v-model="dialog" persistent max-width="500px" :key="dialog">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on"
                >Nueva Regla</v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="12" sm="12">
                    <v-text-field
                      v-model="area.nombre"
                      label="Área"
                      readonly
                      disabled
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <v-textarea
                      v-model="editedItem.enunciado"
                      label="Enunciado"
                      rows="1"
                      auto-grow
                      dense
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" md="12" sm="12" class="ma-0 pa-0">
                    <switchx
                      v-model="editedItem.respuesta"
                      label="Respuesta Esperada"
                    ></switchx>
                  </v-col>
                  <v-col cols="12" md="12" sm="12" class="ma-0 pa-0">
                    <switchx
                      v-model="editedItem.scrapAutomatico"
                      label="Scrapt Automatico"
                      @input="cambioEstado"
                    ></switchx>
                  </v-col>
                  <v-col cols="12" md="12" sm="12" class="ma-0 pa-0">
                    <switchx
                      v-model="editedItem.retrabajoAutomatico"
                      v-if="!editedItem.scrapAutomatico"
                      label="Retrabajo Automatico"
                    ></switchx>
                  </v-col>
                </v-row>
                <ul>
                  <li class="red--text" v-for="ex in errores" :key="ex">
                    {{ ex }}
                  </li>
                </ul>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close()"
                  >Cancelar</v-btn
                >
                <v-btn
                  color="red darken-1"
                  text
                  @click="save()"
                  :disabled="saving"
                  :loading="saving"
                >
                  Guardar
                  <template v-slot:loader>
                    <v-progress-circular
                      indeterminate
                      :width="2"
                      :size="24"
                      color="red"
                    ></v-progress-circular>
                  </template>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.respuesta="{ item }">
        <div v-if="item.respuesta" class="green--text">Sí</div>
        <div v-else class="red--text">No</div>
      </template>
      <template v-slot:item.scrapAutomatico="{ item }">
        <v-icon small>{{
          item.scrapAutomatico ? "mdi-check" : "mdi-close"
        }}</v-icon>
      </template>
      <template v-slot:item.retrabajoAutomatico="{ item }">
        <v-icon small>{{
          item.retrabajoAutomatico ? "mdi-check" : "mdi-close"
        }}</v-icon>
      </template>
      <template v-slot:item.respuesta="{ item }">
        <div v-if="item.respuesta" class="green--text">Sí</div>
        <div v-else class="red--text">No</div>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" small class="mr-2" @click="editItem(item)">
              edit
            </v-icon>
          </template>
          <span class="white--text">Editar</span>
        </v-tooltip>

        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" small @click="deleteItem(item)"> delete </v-icon>
          </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import axios from "axios";
import switchx from "@/components/Switchx";

export default {
  components: {
    switchx: switchx,
  },
  props: {
    idArea: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      reglas: [],
      headers: [
        {
          text: "Enunciado",
          align: "left",
          sortable: false,
          value: "enunciado",
          width: "50%",
        },
        {
          text: "Respuesta Esperada",
          align: "center",
          sortable: true,
          value: "respuesta",
        },
        {
          text: "Scrapt Automatico",
          align: "center",
          sortable: true,
          value: "scrapAutomatico",
        },
        {
          text: "Retrabajo Automatico",
          align: "center",
          sortable: true,
          value: "retrabajoAutomatico",
        },
        {
          text: "Acciones",
          value: "action",
          sortable: false,
          width: "10%",
          align: "center",
        },
      ],
      loading: false,
      search: "",
      dialog: false,
      errores: [],
      saving: false,
      editedIndex: -1,
      defaultItem: {
        idRegla: -1,
        enunciado: "",
        respuesta: true,
        scrapAutomatico: false,
        retrabajoAutomatico: false,
      },
      deletedItem: {
        idRegla: -1,
        enunciado: "",
        respuesta: true,
        scrapAutomatico: false,
        retrabajoAutomatico: false,
      },
      editedItem: {
        idRegla: -1,
        enunciado: "",
        respuesta: true,
        scrapAutomatico: false,
        retrabajoAutomatico: false,
      },
      area: {
        nombre: "",
        idArea: -1,
      },
      eliminar: false,
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva Regla" : "Editar Regla";
    },
  },
  mounted() {
    if (this.idArea > 1) {
      this.initialize();
    }
  },
  methods: {
    initialize() {
      axios
        .get("/Areas/Mostrar/" + this.idArea)
        .then((response) => {
          this.area = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get("/Calidad/ReglasArea/" + this.idArea)
        .then((response) => {
          this.reglas = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cambioEstado() {
      if (this.editedItem.scrapAutomatico) {
        this.editedItem.retrabajoAutomatico = false;
      }
    },
    validate() {
      this.errores = [];
      if (this.editedItem.enunciado.length < 10) {
        this.errores.push("El Enunciado debe de tener al ménos 10 caracteres");
      }

      return this.errores.length == 0;
    },
    save() {
      if (this.validate()) {
        this.saving = true;

        var obj = {
          IdArticulo: null,
          IdReceta: null,
          IdPaso: null,
          IdArea: this.area.idArea,
          Enunciado: this.editedItem.enunciado,
          Respuesta: this.editedItem.respuesta,
          ScrapAutomatico: this.editedItem.scrapAutomatico,
          RetrabajoAutomatico: this.editedItem.retrabajoAutomatico,
        };

        if (this.editedIndex == -1) {
          axios
            .post("/Calidad/CrearReglaArea", obj)
            .then((response) => {
              this.saving = false;
              this.close();
              this.initialize();
            })
            .catch((error) => {
              this.saving = false;
              console.log(error);
            });
        } else {
          axios
            .put("/Calidad/ActualizarReglaArea/" + this.editedItem.idRegla, obj)
            .then((respose) => {
              this.saving = false;
              this.close();
              this.initialize();
            })
            .catch((error) => {
              this.saving = false;
              console.log(error);
            });
        }
      }
    },
    editItem(item) {
      this.editedIndex = this.reglas.indexOf(item);
      this.editedItem = Object.assign({}, this.reglas[this.editedIndex]);
      this.dialog = true;
    },
    deleteItem(item) {
      this.deletedItem = item;
      this.eliminar = true;
    },
    deleteItemDB(item) {
      axios
        .put("/Calidad/DesactivarRegla/" + this.deletedItem.idRegla)
        .then((response) => {
          this.deletedItem = Object.assign({}, this.defaultItem);
          this.eliminar = false;
          this.initialize();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    close() {
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.dialog = false;
    },
  },
};
</script>