<template>
	<v-col cols="12">
		<v-dialog v-model="dialog" max-width="800px" persistent>
			<v-card>
				<v-card-title v-if="editedItem.idMantenimientoCorrectivo === -1"
					>Agregar Mantenimiento Correctivo</v-card-title
				>
				<v-card-title v-else
					>Editar Mantenimiento Correctivo</v-card-title
				>
				<v-card-text>
					<v-row>
						<v-col cols="6" md="6" sm="6">
							<v-text-field
								v-model="editedItem.codigo"
								label="Código"
								hide-details
							></v-text-field>
						</v-col>
						<v-col cols="6" md="6" sm="6">
							<v-text-field
								v-model="editedItem.nombre"
								label="Nombre"
								hide-details
							></v-text-field>
						</v-col>
						<v-col cols="6" md="6" sm="6">
							<v-textarea
								v-model="editedItem.descripcion"
								label="Descripción"
								hide-details
								auto-grow
								rows="1"
							></v-textarea>
						</v-col>
						<v-col cols="6" md="6" sm="6">
							<v-autocomplete
								v-model="editedItem.idMaquina"
								label="Máquina"
								:items="maquinas"
								:item-text="marcaModelo"
								item-value="idMaquina"
								hide-details
								@change="resetMaquinaIns"
							>
								<template v-slot:item="data">
									<v-list-item-content>
										<v-list-item-title>{{
											data.item.marca +
											" - " +
											data.item.modelo
										}}</v-list-item-title>
										<v-list-item-subtitle>{{
											data.item.categoria
										}}</v-list-item-subtitle>
										<v-list-item-content v-if="data.item.descripcionGeneral!=null">{{
											$utils.strings.truncate(
												data.item.descripcionGeneral,
												50
											)
										}}</v-list-item-content>
									</v-list-item-content>
								</template>
							</v-autocomplete>
						</v-col>
						<v-col cols="6">
							<v-autocomplete
								v-model="editedItem.idSubdepartamento"
								label="Subdepartamento"
								:items="subdepartamentos"
								:item-text="nombreSubdepto"
								item-value="idSubdepartamento"
								clearable
							></v-autocomplete>
						</v-col>
						<ListadoPorHacer ref="todo" :item="editedItem" />
					</v-row>
					<v-row>
						<ul>
							<li
								class="red--text"
								v-for="(error, idx) in errores"
								:key="'error' + idx"
							>
								{{ error }}
							</li>
						</ul>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="green"
						text
						:disabled="guardando"
						@click="closeMantenimiento"
						>Cancelar</v-btn
					>
					<v-btn
						color="red"
						text
						:loading="guardando"
						:disabled="guardando"
						@click="guardarMantenimiento"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogEliminar" max-width="800px">
			<v-card>
				<v-card-title>
					<v-card-title class="headline"
						>¿Está seguro que desea eliminar este
						mantenimiento?</v-card-title
					>
					<v-card-text
						>Esta acción no se puede revertir y será
						permanente.</v-card-text
					>
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="green"
						text
						@click="closeEliminarMantenimiento"
						>Cancelar</v-btn
					>
					<v-btn
						color="red darken-1"
						text
						@click="runRemoverMantenimiento"
						:disabled="deleting"
						:loading="deleting"
					>
						Eliminar
						<template v-slot:loader>
							<v-progress-circular
								indeterminate
								:width="2"
								:size="24"
								color="red"
							></v-progress-circular>
						</template>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogTarea" max-width="55%" persistent>
			<v-card>
				<v-card-title class="pa-4 pb-4 pt-5">
					Reporte Extemporaneo
				</v-card-title>
				<v-card-text style="min-height: 300px">
					<p class="text-subtitle-1 text--primary">{{ reportedItem.label }}</p>
					<v-row>
						<v-col cols="12" md="12" sm="12">
							<v-autocomplete
								v-model="reportedItem.responsable"
								:items="personas"
								item-value="idPersona"
								:item-text="nombrePersona"
								label="Responsable"
								hide-details
							>
							</v-autocomplete>
						</v-col>
						<v-col cols="12" md="12" sm="12">
							<v-autocomplete
								v-model="reportedItem.mecanicos"
								:items="personas"
								item-value="idPersona"
								:item-text="nombrePersona"
								label="Realizado por"
								multiple
								chips
								deletable-chips
								hide-details
							>
							</v-autocomplete>
						</v-col>
						<v-col cols="12" md="12" sm="12">
							<v-autocomplete
								v-model="reportedItem.instancia"
								label="Instancia"
								:items="computedIns"
								item-text="nombre"
								item-value="idMaquinaIns"
							></v-autocomplete>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="12" sm="12">
							<VueCtkDateTimePicker
								v-model="reportedItem.inicio"
								label="Inicio"
								format="YYYY-MM-DD HH:mm"
								formatted="YYYY-MM-DD HH:mm"
								noHeader
								overlay
								buttonNowTranslation="Ahora"
							/>
						</v-col>
						<v-col cols="12" md="12" sm="12">
							<VueCtkDateTimePicker
								v-model="reportedItem.fin"
								:min-date="reportedItem.inicio"
								label="Termino"
								format="YYYY-MM-DD HH:mm"
								formatted="YYYY-MM-DD HH:mm"
								noHeader
								overlay
								buttonNowTranslation="Ahora"
							/>
						</v-col>
					</v-row>
					<v-row>
						<Comprobaciones
							ref="checklist" 
						/>
					</v-row>
					<v-row>
						<v-col cols="12" md="12" sm="12">
							<v-textarea
                                v-model="reportedItem.recomendacion"
                                outlined
                                label="Recomendación"
                                auto-grow
                                rows="1"
                                hide-details
                            ></v-textarea>
						</v-col>
					</v-row>
					<ul>
						<li
							class="red--text"
							v-for="(error, idx) in erroresReported"
							:key="'error-' + idx"
						>
							{{ error }}
						</li>
					</ul>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="green"
						@click="closeReported"
						:disabled="savingReported"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="red"
						@click="completeReported"
						:disabled="savingReported"
						:loading="savingReported"
					>
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogTarea2" max-width="500px" persistent>
			<v-card>
				<v-card-title class="pa-4 pb-4 pt-5">
					Reporte En Tiempo Real
				</v-card-title>
				<v-card-text style="min-height: 300px">
					<p class="text-subtitle-1 text--primary">{{ reportedItem.label }}</p>
					<v-row>
						<v-col cols="12" md="12" sm="12">
							<v-autocomplete
								v-model="reportedItem.responsable"
								:items="personas"
								item-value="idPersona"
								:item-text="nombrePersona"
								label="Responsable"
								hide-details
							>
							</v-autocomplete>
						</v-col>
						<v-col cols="12" md="12" sm="12">
							<v-autocomplete
								v-model="reportedItem.mecanicos"
								:items="personas"
								item-value="idPersona"
								:item-text="nombrePersona"
								label="Realizado por"
								multiple
								chips
								deletable-chips
								hide-details
							>
							</v-autocomplete>
						</v-col>
						<v-col cols="12" md="12" sm="12">
							<v-autocomplete
								v-model="reportedItem.instancia"
								label="Instancia"
								:items="computedIns"
								item-text="nombre"
								item-value="idMaquinaIns"
							></v-autocomplete>
						</v-col>
					</v-row>
					<ul>
						<li
							class="red--text"
							v-for="(error, idx) in erroresReported"
							:key="'error-' + idx"
						>
							{{ error }}
						</li>
					</ul>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="green"
						@click="closeReported"
						:disabled="savingReported"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="red"
						@click="completeReported2"
						:disabled="savingReported"
						:loading="savingReported"
					>
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogProgramar" max-width="45%" persistent>
			<v-card>
				<v-card-title class="pa-4 pb-4 pt-5">
					Programar Mantenimiento Correctivo
				</v-card-title>
				<v-card-text style="min-height: 300px">
					<p class="text-subtitle-1 text--primary">{{ reportedItem.label }}</p>
					<v-row>
						<v-col cols="12" md="12" sm="12">
							<v-autocomplete
								v-model="reportedItem.instancia"
								label="Instancia"
								:items="computedIns"
								item-text="nombre"
								item-value="idMaquinaIns"
							></v-autocomplete>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="12" sm="12" class="my-3">
							<VueCtkDateTimePicker
								v-model="reportedItem.inicio"
								label="Inicio Planeado"
								format="YYYY-MM-DD HH:mm"
								formatted="YYYY-MM-DD HH:mm"
								noHeader
								overlay
								buttonNowTranslation="Ahora"
							/>
						</v-col>
						<v-col cols="12" md="12" sm="12" class="my-3">
							<VueCtkDateTimePicker
								v-model="reportedItem.fin"
								:min-date="reportedItem.inicio"
								label="Termino Planeado"
								format="YYYY-MM-DD HH:mm"
								formatted="YYYY-MM-DD HH:mm"
								noHeader
								overlay
								buttonNowTranslation="Ahora"
							/>
						</v-col>
					</v-row>
					<ul class="mt-3">
						<li
							class="red--text"
							v-for="(error, idx) in erroresReported"
							:key="'error-' + idx"
						>
							{{ error }}
						</li>
					</ul>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="green"
						@click="closeProgrammed"
						:disabled="savingProgrammed"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="red"
						@click="programarMantenimiento"
						:disabled="savingProgrammed"
						:loading="savingProgrammed"
					>
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<Recomendaciones ref="recomen" />
		<v-data-table
			:items="horarios"
			:headers="headersHorario"
			:loading="loading"
			loading-text="Cargando... Espere, Por Favor."
		>
			<template v-slot:top>
				<v-btn
					v-if="permisoVista('cat-mantenimientocorrectivo', 'c')"
					color="primary"
					@click="dialog = true"
					>Agregar Mantenimiento Correctivo</v-btn
				>
			</template>
			<template v-slot:[`item.maquina`]="{ item }">
				{{ maquinaLabel(item.idMaquina) }}
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-tooltip
					v-if="permisoVista('cat-mantenimientocorrectivo', 'c')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon v-on="on" small @click="showProgramar(item)">
							mdi-clock
						</v-icon>
					</template>
					<span class="white--text">Programar Mantenimiento</span>
				</v-tooltip>
				<v-tooltip
					v-if="permisoVista('cat-mantenimientocorrectivo', 'c')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon v-on="on" small @click="reportarTarea(item)">
							mdi-clipboard-text-play
						</v-icon>
					</template>
					<span class="white--text">Reporte Extemporaneo</span>
				</v-tooltip>
								<v-tooltip
					v-if="permisoVista('cat-mantenimientocorrectivo', 'c')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon v-on="on" small @click="reportarTarea2(item)">
							mdi-script-text-play
						</v-icon>
					</template>
					<span class="white--text">Reporte Tiempo Real</span>
				</v-tooltip>
				<v-tooltip
					v-if="permisoVista('cat-mantenimientocorrectivo', 'u')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							@click="editMantenimiento(item)"
						>
							edit
						</v-icon>
					</template>
					<span class="white--text">Editar</span>
				</v-tooltip>
				<v-tooltip
					v-if="permisoVista('cat-mantenimientocorrectivo', 'r')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							:disabled="
								item.recomendaciones == null ||
								item.recomendaciones == undefined ||
								item.recomendaciones.length == 0
							"
							v-on="on"
							small
							@click="showRecomendaciones(item)"
						>
							mdi-eye
						</v-icon>
					</template>
					<span class="white--text">Ver Recomendaciones</span>
				</v-tooltip>
				<v-tooltip
					v-if="permisoVista('cat-mantenimientocorrectivo', 'd')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							@click="removeMantenimiento(item)"
						>
							delete
						</v-icon>
					</template>
					<span class="white--text">Eliminar</span>
				</v-tooltip>
			</template>
		</v-data-table>
	</v-col>
</template>

<script>
import axios from "axios";
import Lista from '../../OTS/Lista.vue';
import ListadoPorHacer from './ListadoPorHacer.vue';
import Comprobaciones from '../Comprobaciones.vue';
import Recomendaciones from './Recomendaciones.vue';

export default {
	components: { Lista, ListadoPorHacer, Comprobaciones, Recomendaciones },
	data: () => ({
		subdepartamentos: [],
		savingProgrammed: false,
		dialogTarea2: false,
		instancias: [],
		savingReported: false,
		erroresReported: [],
		dialogTarea: false,
		guardando: false,
		dialog: false,
		dialogInsumos: false,
		editedItem: {
			idMantenimientoCorrectivo: -1,
			idSubdepartamento: null,
			codigo: "",
			nombre: "",
			descripcion: "",
			idMaquina: 0,
			comprobaciones: []
		},
		defaultEditeditem: {
			idMantenimientoCorrectivo: -1,
			idSubdepartamento: null,
			codigo: "",
			nombre: "",
			descripcion: "",
			idMaquina: 0,
			comprobaciones: []
		},
		reportedItem: {
			idMantenimientoCorrectivo: -1,
			label: "",
			codigo: "",
			nombre: "",
			descripcion: "",
			idMaquina: 0,
			responsable: 0,
			mecanicos: [],
			instancia: 0,
			inicio: null,
			fin: null,
			recomendacion: ""
		},
		defaultReportedItem: {
			idMantenimientoCorrectivo: -1,
			label: "",
			codigo: "",
			nombre: "",
			descripcion: "",
			idMaquina: 0,
			responsable: 0,
			mecanicos: [],
			instancia: 0,
			inicio: null,
			fin: null,
			recomendacion: ""
		},
		personas: [],
		maquinas: [],
		headers: [
			{
				text: "Enunciado",
				align: "left",
				sortable: false,
				value: "enunciado",
				width: "80%,",
			},
			{
				text: "Acciones",
				align: "center",
				sortable: false,
				value: "actions",
				width: "20%,",
			},
		],
		alerta: false,
		errores: [],
		horarios: [],
		loading: false,
		headersHorario: [
			{
				text: "Código",
				align: "left",
				sortable: false,
				value: "codigo",
				width: "22%,",
			},
			{
				text: "Nombre",
				align: "left",
				sortable: false,
				value: "nombre",
				width: "22%,",
			},
			{
				text: "Máquina",
				align: "left",
				sortable: false,
				value: "maquina",
				width: "22%,",
			},
			{
				text: "Acciones",
				align: "center",
				sortable: false,
				value: "actions",
				width: "12%,",
			},
		],
		dialogEliminar: false,
		eliminarItem: {
			idMantenimientoCorrectivo: -1,
			codigo: "",
			nombre: "",
			descripcion: "",
			idMaquina: 0,
		},
		deleting: false,
		saving: false,
	}),
	created() {
		this.initialize();
	},
	computed: {
		computedIns() {
			const ins = this.instancias.filter(
				(instancia) =>
					instancia.idMaquina === this.reportedItem.idMaquina
			);
			return ins;
		},
	},
	methods: {
		nombreSubdepto: ({ codigo, nombre }) => codigo ? `[${codigo}] - ${nombre}`.trim() : nombre.trim(),
    	nombrePersona: persona => persona.nombre + " " + persona.paterno + " " + persona.materno,
		showProgramar(item){
			this.reportedItem = Object.assign({}, item);
			this.reportedItem.label = this.reportedLabel();
			this.dialogProgramar = true;
		},
		showRecomendaciones(item){
			this.$refs.recomen.showDialogRecomendaciones(item);
		},
		guardarMantenimiento(){
			// this.$refs.todo.togglePanel(null);
			this.save();
		},
		getInstancias() {
			axios
				.get("/MaquinaInstancias")
				.then((response) => {
					this.instancias = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		validReported(comprobaciones) {
			this.erroresReported = [];
			if (
				this.reportedItem.responsable == undefined ||
				this.reportedItem.responsable?.length <= 0) {
				this.erroresReported.push("Debe de Seleccionar un Responsable");
			}
			if (
				this.reportedItem.mecanicos == undefined ||
				this.reportedItem.mecanicos?.length <= 0
			) {
				this.erroresReported.push(
					"Falta indicar quien realizo el Mantenimiento"
				);
			}
			if (
				this.reportedItem.inicio === null ||
				this.reportedItem.inicio == undefined
			) {
				this.erroresReported.push(
					"Falta indicar cuando se inicio el Mantenimiento"
				);
			}
			if (
				this.reportedItem.fin === null ||
				this.reportedItem.fin == undefined
			) {
				this.erroresReported.push(
					"Falta indicar cuando se finalizo el Mantenimiento"
				);
			}

			if ( this.reportedItem.instancia === 0 || this.reportedItem.instancia === null ){
				this.erroresReported.push(
					"Falta seleccionar una instancia de la máquina."
				);
			} 
			if( Array.isArray(comprobaciones) ){
				this.erroresReported = [ ...this.erroresReported,...comprobaciones ];
			}

			return this.erroresReported.length == 0;
		},
		validReported2() {
			this.erroresReported = [];
			if (this.reportedItem.responsable <= 0) {
				this.erroresReported.push("Debe de Seleccionar un Responsable");
			}
			if (this.reportedItem.mecanicos.length <= 0) {
				this.erroresReported.push(
					"Falta indicar quien realizo el Mantenimiento"
				);
			}
			if ( this.reportedItem.instancia === 0 || this.reportedItem.instancia === null ){
				this.erroresReported.push(
					"Falta seleccionar una instancia de la máquina."
				);
			} 

			return this.erroresReported.length == 0;
		},
		validProgrammed(){
			this.erroresReported = [];

			if ( this.reportedItem.instancia == 0 || this.reportedItem.instancia == null ){
				this.erroresReported.push(
					"Falta seleccionar una instancia de la máquina."
				);
			}

			if (
				this.reportedItem.inicio === null ||
				this.reportedItem.inicio == undefined
			){
				this.erroresReported.push(
					"Falta indicar el inicio planeado del mantenimiento"
				);
			}

			if (
				this.reportedItem.fin === null ||
				this.reportedItem.fin == undefined
			) {
				this.erroresReported.push(
					"Falta indicar el termino planeado del Mantenimiento"
				);
			}

			return this.erroresReported.length == 0;
		},
		programarMantenimiento(){
			if( !this.validProgrammed() ) return;
			const obj = {
				idMantenimientoCorrectivo: this.reportedItem.idMantenimientoCorrectivo,
				idMaquinaIns: this.reportedItem.instancia,
				inicioPlaneado: this.reportedItem.inicio,
				finPlaneado: this.reportedItem.fin
			};
			this.savingProgrammed = true;
			axios
				.post("/Mantenimientos/ProgramarCorrectivo",obj)
				.then( () => {
					this.savingProgrammed = false;
					this.dialogProgramar = false;
					this.reportedItem = Object.assign(
						{},
						this.defaultReportedItem
					);
					this.reportedItem.mecanicos = [];
					this.erroresReported = [];
					this.initialize();
				})
				.catch( error => {
					this.savingProgrammed = false;
					console.log( error );
				})
		},
		completeReported() {
      		const item = this.$refs.checklist.terminarTarea();
			if (this.validReported(item)) {
				var obj = {
					idMantenimientoCorrectivo: this.reportedItem
						.idMantenimientoCorrectivo,
					idsMaquinaIns: this.reportedItem.instancia,
					idPersonaResponsable: this.reportedItem.responsable,
					mecanicos: this.reportedItem.mecanicos,
					fechaInicioRealizo: this.reportedItem.inicio,
					fechaFinRealizo: this.reportedItem.fin,
					recomendacion: this.reportedItem.recomendacion,
					comprobaciones: item.comprobaciones
				};

				this.savingReported = true;
				axios
					.post(
						"/Mantenimientos/CreaMantenimientoCorrectivoTarea",
						obj
					)
					.then(() => {
						this.dialogTarea = false;
						this.reportedItem = Object.assign(
							{},
							this.defaultReportedItem
						);
						this.reportedItem.mecanicos = [];
						this.erroresReported = [];
						this.savingReported = false;
						this.initialize();
					})
					.catch((error) => {
						console.log(error);
						this.savingReported = false;
					});
			}
		},
		completeReported2() {
			if (this.validReported2()) {
				var obj = {
					idMantenimientoCorrectivo: this.reportedItem
						.idMantenimientoCorrectivo,
					idMaquinaIns: this.reportedItem.instancia,
					idPersonaResponsable: this.reportedItem.responsable,
					mecanicos: this.reportedItem.mecanicos,
				};

				this.savingReported = true;
				axios
					.post(
						"/Mantenimientos/IniciarTareaMantenimientoCorrectivo",
						obj
					)
					.then(() => {
						this.dialogTarea2 = false;
						this.reportedItem = Object.assign(
							{},
							this.defaultReportedItem
						);
						this.reportedItem.mecanicos = [];
						this.erroresReported = [];
						this.savingReported = false;
					})
					.catch((error) => {
						console.log(error);
						this.savingReported = false;
					});
			}
		},
		getPersonas() {
			axios
				.get("/Personas/Operadores")
				.then((response) => {
					this.personas = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		reportarTarea(item) {
			this.reportedItem = Object.assign({}, item);
			this.reportedItem.label = this.reportedLabel();
			this.dialogTarea = true;
			this.$nextTick( () => {
				this.$refs.checklist.comprobacionesCorrectivo(
					this.reportedItem.idMantenimientoCorrectivo
				);
			});
		},
		reportarTarea2(item){
			this.reportedItem = Object.assign({}, item);
			this.reportedItem.label = this.reportedLabel();
			this.dialogTarea2 = true;
		},
		maquinaLabel(idMaquina) {
			const maquina = this.maquinas.find(
				(maquina) => maquina.idMaquina === idMaquina
			);
			return this.$utils.isValid(maquina)
				? maquina.marca + " - " + maquina.modelo
				: "N/A";
		},
		reportedLabel() {
			return `[${this.reportedItem.codigo}] ${
				this.reportedItem.nombre
			} - ${this.maquinaLabel(this.reportedItem.idMaquina).split(" - ")[1].trim()}`;
		},
		marcaModelo: (item) => item.marca + " - " + item.modelo,
		initialize() {
			this.getMaquinas();
			this.getMantenimientos();
			this.getPersonas();
			this.getInstancias();
			this.getSubdepartamentos();
		},
		getSubdepartamentos(){
			axios
				.get('/Subdepartamento')
				.then( response => {
					this.subdepartamentos = response.data;
				})
				.catch(console.log)
		},
		closeMantenimiento() {
			this.dialog = false;
			this.editedItem = JSON.parse(JSON.stringify(this.defaultEditeditem));
			this.errores = [];
			this.getMantenimientos();
			this.$refs.todo.togglePanel(0);
		},
		closeReported() {
			this.dialogTarea = false;
			this.dialogTarea2 = false;
			this.reportedItem = Object.assign({}, this.defaultReporteditem);
			this.erroresReported = [];
		},
		closeProgrammed() {
			this.dialogProgramar = false;
			this.reportedItem = Object.assign({}, this.defaultReporteditem);
			this.erroresReported = [];
		},
		getMaquinas() {
			axios
				.get("/Maquinas")
				.then((response) => {
					this.maquinas = response.data.filter(function (el) {
						return el.esMaquina == true;
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getMantenimientos() {
			this.loading = true;
			axios
				.get("/Mantenimientos/MantenimientoCorrectivos")
				.then((response) => {
					this.loading = false;
					this.horarios = response.data;
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
		editMantenimiento(item) {
			item.comprobaciones = item.comprobaciones.map( comprobacion => {
				if ( Array.isArray(comprobacion.recursos) ){
					let idsDocumentos = [];
					let links = [];
	
					comprobacion.recursos.forEach(recurso => {
						if (recurso.idDocumento != null){
							idsDocumentos.push({
								nombre: recurso.documento,
								idDocumento: recurso.idDocumento
							});
						}
	
						if( recurso.link != null ){
							links.push({
								titulo: recurso.tituloLink,
								url: recurso.link
							});
						}
					});
	
					comprobacion.recursos = {
						idsDocumentos,
						links,
					}
				}
				return comprobacion;
			});
			this.editedItem = Object.assign({}, item);
			this.dialog = true;
		},
		removeMantenimiento(item) {
			this.dialogEliminar = true;
			this.eliminarItem = item;
		},
		runRemoverMantenimiento() {
			this.deleting = true;
			axios
				.put(
					"/Mantenimientos/EliminarMantenimientoCorrectivo/" +
						this.eliminarItem.idMantenimientoCorrectivo
				)
				.then(() => {
					this.deleting = false;
					this.closeEliminarMantenimiento();
					this.getMantenimientos();
				})
				.catch((error) => {
					this.deleting = false;
					console.log(error);
				});
		},
		closeEliminarMantenimiento() {
			this.dialogEliminar = false;
			this.eliminarItem = Object.assign({}, this.defaultEditeditem);
		},
		validate() {
			this.errores = [];
			if (
				this.editedItem.codigo.length < 5 ||
				this.editedItem.codigo.length > 100
			) {
				this.errores.push(
					"El Código debe de tener entre 5 y 100 caracteres."
				);
			}
			if (
				this.editedItem.nombre.length < 5 ||
				this.editedItem.nombre.length > 1000
			) {
				this.errores.push(
					"El Nombre debe de tener entre 5 y 1000 caracteres."
				);
			}
			if (this.editedItem.idMaquina <= 0) {
				this.errores.push("Debe de seleccionar una Máquina");
			}

			if(this.editedItem.idSubdepartamento == null){
				this.errores.push("Debe seleccionar un Subdepartamento")
			}

			return this.errores.length == 0;
		},
		save() {
			this.guardando = true;
			if (!this.validate()) {
				this.guardando = false;
				return;
			} else {
				const comprobaciones = this.$refs.todo.getListado();
				if (this.editedItem.idMantenimientoCorrectivo == -1) {
					axios
						.post("/Mantenimientos/CrearMantenimientoCorrectivo", {
							idMaquina: this.editedItem.idMaquina,
							codigo: this.editedItem.codigo,
							nombre: this.editedItem.nombre,
							descripcion: this.editedItem.descripcion,
							idSubdepartamento: this.editedItem.idSubdepartamento,
							comprobaciones
						})
						.then(() => {
							this.guardando = false;
							this.closeMantenimiento();
						})
						.catch((error) => {
							console.log(error);
							this.guardando = false;
						});
				} else {
					axios
						.put(
							"/Mantenimientos/ActualizarMantenimientoCorrectivo",
							{
								idMantenimientoCorrectivo: this.editedItem
									.idMantenimientoCorrectivo,
								idMaquina: this.editedItem.idMaquina,
								codigo: this.editedItem.codigo,
								nombre: this.editedItem.nombre,
								descripcion: this.editedItem.descripcion,
								idSubdepartamento: this.editedItem.idSubdepartamento,
								listaComprobacion: comprobaciones
							}
						)
						.then(() => {
							this.guardando = false;
							this.closeMantenimiento();
						})
						.catch((error) => {
							console.log(error);
							this.guardando = false;
						});
				}
			}
		},
	},
};
</script>