<template>
	<v-col cols="12">
		<v-snackbar v-model="alerta" top color="error">
			Existe un Horario con las mismas características.
			<v-btn text @click="alerta = false"> Cerrar </v-btn>
		</v-snackbar>
		<v-dialog v-model="dialog" max-width="800px" persistent>
			<v-card>
				<v-card-title v-if="editedItem.idMantenimientoInspeccion == -1"
					>Agregar Inspección</v-card-title
				>
				<v-card-title v-else>Editar Inspección</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="6" md="6" sm="6">
							<v-text-field
								v-model="editedItem.codigo"
								label="Código"
								hide-details
							></v-text-field>
						</v-col>
                        <v-col cols="6" md="6" sm="6">
							<v-text-field
								v-model="editedItem.nombre"
								label="Nombre"
								hide-details
							></v-text-field>
						</v-col>
						<v-col cols="12" md="12" sm="12">
							<v-textarea
								v-model="editedItem.descripcion"
								label="Descripción"
								hide-details
								auto-grow
								rows="1"
							></v-textarea>
						</v-col>
						<v-col cols="6" md="6" sm="6" class="mb-0 pb-0">
							<time-picker
								class="py-3"
								v-model="editedItem.duracion"
								label="Duración"
							></time-picker>
						</v-col>
						<v-col cols="6" md="6" sm="6" class="pb-0">
							<v-autocomplete
								v-model="editedItem.idMaquina"
								label="Máquina"
								:items="maquinas"
								:item-text="marcaModelo"
								item-value="idMaquina"
								@change="resetMaquinaIns"
								hide-details
							>
								<template v-slot:item="data">
									<v-list-item-content>
										<v-list-item-title>{{
											data.item.marca +
											" - " +
											data.item.modelo
										}}</v-list-item-title>
										<v-list-item-subtitle>{{
											data.item.categoria
										}}</v-list-item-subtitle>
										<v-list-item-content v-if="data.item.descripcionGeneral!=null">{{
											$utils.strings.truncate(
												data.item.descripcionGeneral,
												50
											)
										}}</v-list-item-content>
									</v-list-item-content>
								</template>
							</v-autocomplete>
						</v-col>
						<v-col cols="6" md="6" sm="6" class="mt-0 pt-0">
							<v-autocomplete
								chips
								multiple
								deletable-chips
								v-model="editedItem.idsMaquinaIns"
								label="Instancias"
								:items="computedIns"
								item-text="nombre"
								item-value="idMaquinaIns"
							></v-autocomplete>
						</v-col>
						<v-col cols="6" md="6" sm="6">
							<datePicker
								v-model="editedItem.fechaInicioProgramada"
								format="YYYY-MM-DD"
								label="Inicio Programado"
								clearable
								:minDate="editedItem.fechaMin"
							></datePicker>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="3">
							<p class="text-center">Días a Repetir</p>
							<number-input
								v-model="editedItem.repeticion"
								controls
								inline
								size="small"
								:min="1"
								center
							></number-input>
						</v-col>
						<v-col cols="3" md="3" sm="3">
							<p class="text-center">¿Distribuir Inspección?</p>
							<div class="d-flex justify-center">
								<v-btn-toggle
									v-model="editedItem.distribuirInspecciones"
								>
									<v-btn
										depressed
										small
										class="ma-0"
										:color="
											editedItem.distribuirInspecciones ==
											0
												? 'success'
												: 'grey lighten-1'
										"
										>Si</v-btn
									>
									<v-btn
										depressed
										small
										class="ma-0"
										:color="
											editedItem.distribuirInspecciones ==
											1
												? 'error'
												: 'grey lighten-1'
										"
										>No</v-btn
									>
								</v-btn-toggle>
							</div>
						</v-col>
						<v-col cols="6">
							<v-autocomplete
								v-model="editedItem.idSubdepartamento"
								label="Subdepartamento"
								:items="subdepartamentos"
								:item-text="nombreSubdepto"
								item-value="idSubdepartamento"
								clearable
							></v-autocomplete>
						</v-col>
					</v-row>
					<br />
					<ListadoPorHacer
						ref="todo"
						:item="editedItem"
					/>
					<v-row>
						<ul class="mt-6">
							<li
								class="red--text"
								v-for="(error, idx) in errores"
								:key="'error' + idx"
							>
								{{ error }}
							</li>
						</ul>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="green"
						text
						:disabled="guardando"
						@click="closeMantenimiento"
						>Cancelar</v-btn
					>
					<v-btn
						color="red"
						text
						:loading="guardando"
						:disabled="guardando"
						@click="guardarMantenimiento"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<dialog-horario
			@create="newHorario($event)"
			@update="updateHorario($event)"
			ref="horario"
		>
		</dialog-horario>
		<v-dialog v-model="dialogEliminar" max-width="800px">
			<v-card>
				<v-card-title>
					<v-card-title class="headline"
						>¿Está seguro que desea eliminar esta
						inspección?</v-card-title
					>
					<v-card-text
						>Esta acción no se puede revertir y será
						permanente.</v-card-text
					>
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="green"
						text
						@click="closeEliminarMantenimiento"
						>Cancelar</v-btn
					>
					<v-btn
						color="red darken-1"
						text
						@click="runRemoverMantenimiento"
						:disabled="deleting"
						:loading="deleting"
					>
						Eliminar
						<template v-slot:loader>
							<v-progress-circular
								indeterminate
								:width="2"
								:size="24"
								color="red"
							></v-progress-circular>
						</template>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-data-table
			:items="horarios"
			:headers="headersHorario"
			:loading="loading"
			loading-text="Cargando... Espere, Por Favor."
		>
			<template v-slot:top>
				<v-btn v-if="permisoVista('mantenimiento-inspecciones', 'c')" color="primary" @click="dialog = true; showDialogInspeccion();"
					>Agregar Inspección</v-btn
				>
			</template>
            <template v-slot:[`item.maquina`]="{item}">
                {{ maquinaMarcaModelo(item) }}
            </template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-tooltip
					v-if="permisoVista('mantenimiento-inspecciones', 'u')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							@click="editMantenimiento(item)"
						>
							edit
						</v-icon>
					</template>
					<span class="white--text">Editar</span>
				</v-tooltip>
				<v-tooltip
					v-if="permisoVista('mantenimiento-inspecciones', 'd')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							@click="removeMantenimiento(item)"
						>
							delete
						</v-icon>
					</template>
					<span class="white--text">Eliminar</span>
				</v-tooltip>
			</template>
		</v-data-table>
	</v-col>
</template>

<script>
import timepicker from "@/components/TimePicker";
import insumos from "../Insumos.vue";
import horario from "../Horario.vue";
import axios from "axios";
import VSwatches from "vue-swatches";
import moment from "moment";
import ListadoPorHacer from "./ListadoPorHacer.vue";
import Recomendaciones from "./Recomendaciones.vue";

export default {
	components: {
		"time-picker": timepicker,
		"dialog-insumos": insumos,
		"dialog-horario": horario,
		VSwatches,
		ListadoPorHacer,
		Recomendaciones,
	},
	data: () => ({
		subdepartamentos: [],
		ctkin: 0,
		errorFecha: false,
        personas: [],
		guardando: false,
		dialog: false,
		dialogInsumos: false,
		editedItem: {
			idMantenimientoInspeccion: -1,
			idSubdepartamento: null,
			codigo: "",
			nombre: "",
			descripcion: "",
			duracion: 0,
			idMaquina: 0,
			costo: "$0.00",
			insumos: [],
			horarios: [],
			color: "#1976D2",
			idsMaquinaIns: [],
			fechaInicioProgramada: null,
			distribuirInspecciones: 0,
			repeticion: 0,
			fechaMin: null,
			comprobaciones: []
		},
		defaultEditeditem: {
			idMantenimientoInspeccion: -1,
			idSubdepartamento: null,
			codigo: "",
			nombre: "",
			descripcion: "",
			duracion: 0,
			idMaquina: 0,
			costo: "$0.00",
			insumos: [],
			horarios: [],
			color: "#1976D2",
			idsMaquinaIns: [],
			fechaInicioProgramada: null,
			distribuirInspecciones: 0,
			repeticion: 0,
			fechaMin: null,
			comprobaciones: []
		},
		maquinas: [],
		headers: [
			{
				text: "Enunciado",
				align: "left",
				sortable: false,
				value: "enunciado",
				width: "80%,",
			},
			{
				text: "Acciones",
				align: "center",
				sortable: false,
				value: "actions",
				width: "20%,",
			},
		],
		alerta: false,
		errores: [],
		horarios: [],
		loading: false,
		headersHorario: [
			{
				text: "Código",
				align: "left",
				sortable: false,
				value: "codigo",
				width: "30%,",
			},
			{
				text: "Nombre",
				align: "left",
				sortable: false,
				value: "nombre",
				width: "30%,",
			},
			{
				text: "Máquina",
				align: "left",
				sortable: false,
				value: "maquina",
				width: "30%,",
			},
			{
				text: "Acciones",
				align: "center",
				sortable: false,
				value: "actions",
				width: "10%,",
			},
		],
		dialogEliminar: false,
		eliminarItem: {
			idMantenimientoInspeccion: -1,
			codigo: "",
			nombre: "",
			descripcion: "",
			duracion: 0,
			idMaquina: 0,
			costo: "$0.00",
			insumos: [],
			horarios: [],
			color: "#1976D2",
			idsMaquinaIns: [],
			idResponsable: 0,
			fechaInicioProgramada: null,
			distribuirInspecciones: 0,
			repeticion: 0,
		},
		deleting: false,
		dialogAtemporal: false,
		instancias: [],
		atemporalItem: {
			idMantenimientoInspeccion: -1,
			codigo: "",
			nombre: "",
			descripcion: "",
			duracion: 0,
			idMaquina: 0,
			costo: "$0.00",
			insumos: [],
			horarios: [],
			color: "#1976D2",
			idsMaquinaIns: [],
		},
		atemporalInfo: {
			inicio: null,
			instancias: [],
		},
		saving: false,
	}),
	created() {
		this.initialize();
	},
	computed: {
		computedIns() {
			const ins = this.instancias.filter(
				(instancia) => instancia.idMaquina === this.editedItem.idMaquina
			);
			return ins;
		},
		plural() {
			return (
				this.$utils.isValid(this.atemporalInfo.instancias) &&
				this.atemporalInfo.instancias.length > 1
			);
		},
		labelAtemporal() {
			if (
				this.$utils.isValid(this.atemporalInfo.inicio) &&
				this.$utils.isValid(this.atemporalInfo.instancias) &&
				this.atemporalInfo.instancias.length > 0
			) {
				var localLocale = moment(
					this.atemporalInfo.inicio,
					"YYYY-MM-DD"
				);
				localLocale.locale("es");
				return (
					"Mantenimiento programado para " +
					localLocale.format(
						"[el día] dddd DD [de] MMMM  [del] YYYY"
					)
				);
			} else return null;
		},
	},
	methods: {
		nombreSubdepto: ({ codigo, nombre }) => codigo ? `[${codigo}] - ${nombre}`.trim() : nombre.trim(),
		nombrePersona: (persona) =>
			persona.nombre + " " + persona.paterno + " " + persona.materno,
		showDialogInspeccion(){
			this.editedItem.fechaMin = moment().add(1, 'day').format("YYYY-MM-DD");
			// this.editedItem.fechaInicioProgramada = this.editedItem.fechaMin;
			this.dialog = true;
			this.ctkin++;
		},
		getPersonas() {
			axios
				.get("/Personas/Operadores")
				.then((response) => {
					this.personas = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		showRecomendaciones(item) {
			this.$refs.recomen.showDialogRecomendaciones(item);
		},
		guardarMantenimiento() {
			// this.$refs.todo.togglePanel(null);
			this.save();
		},
		resetMaquinaIns() {
			this.editedItem.idsMaquinaIns = [];
		},
        maquinaMarcaModelo(item){
            const maquina = this.maquinas.find( maquina => maquina.idMaquina == item.idMaquina );
            if(maquina !== undefined) return `${ maquina.marca } - ${ maquina.modelo }`;
            else return '';
        },
		marcaModelo: (item) => item.marca + " - " + item.modelo,
		initialize() {
			this.getMaquinas();
			this.getMantenimientos();
			this.getInstancias();
            this.getPersonas();
			this.getSubdepartamentos();
		},
		getSubdepartamentos(){
			axios
				.get('/Subdepartamento')
				.then( response => {
					this.subdepartamentos = response.data;
				})
				.catch(console.log)
		},
		openInsumos() {
			this.dialog = false;
			this.dialogInsumos = true;
		},
		closeInsumos() {
			this.dialogInsumos = false;
			this.dialog = true;
		},
		updateCosto(event) {
			if (this.dialogInsumos) this.editedItem.costo = event;
		},
		closeMantenimiento() {
			this.editedItem = JSON.parse(JSON.stringify(this.defaultEditeditem));
			this.errores = [];
			this.errorFecha = false;
			this.dialog = false;
			this.getMantenimientos();
            this.$refs.todo.togglePanel(0);
            this.$nextTick( () => {
                this.$refs.todo.reset();
            });
		},
		getMaquinas() {
			axios
				.get("/Maquinas")
				.then((response) => {
					this.maquinas = response.data.filter(function (el) {
						return el.esMaquina == true;
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getInstancias() {
			axios
				.get("/MaquinaInstancias")
				.then((response) => {
					this.instancias = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getMantenimientos() {
			this.loading = true;
			axios
				.get("/Mantenimientos/GetMantenimientoInspeccion")
				.then((response) => {
					this.loading = false;
					this.horarios = response.data.map(inspeccion => ({
                        ...inspeccion,
                        distribuirInspecciones: inspeccion.distribuirInspecciones ? 0 : 1
                    }));
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
		editMantenimiento(item) {
			item.comprobaciones = item.comprobaciones.map((comprobacion) => {
				if (Array.isArray(comprobacion.recursos)) {
					let idsDocumentos = [];
					let links = [];

					comprobacion.recursos.forEach((recurso) => {
						if (recurso.idDocumento != null) {
							idsDocumentos.push({
								nombre: recurso.documento,
								idDocumento: recurso.idDocumento,
							});
						}

						if (recurso.link != null) {
							links.push({
								titulo: recurso.tituloLink,
								url: recurso.link
							});
						}
					});

					comprobacion.recursos = {
						idsDocumentos,
						links,
					};
				}
				return comprobacion;
			});
			this.editedItem = Object.assign({}, item);
			this.editedItem.fechaMin = moment().add(1, 'day').format("YYYY-MM-DD");
			this.editedItem.fechaInicioProgramada = item.fecha;
			this.dialog = true;
			this.ctkin++;
		},
		removeMantenimiento(item) {
			this.dialogEliminar = true;
			this.eliminarItem = item;
		},
		runRemoverMantenimiento() {
			this.deleting = true;
			axios
				.put(
					`/Mantenimientos/EliminarMantenimientoInspeccion/${this.eliminarItem.idMantenimientoInspeccion}`
				)
				.then((response) => {
					this.deleting = false;
					this.closeEliminarMantenimiento();
					this.getMantenimientos();
				})
				.catch((error) => {
					this.deleting = false;
					console.log(error);
				});
		},
		closeEliminarMantenimiento() {
			this.dialogEliminar = false;
			this.eliminarItem = Object.assign({}, this.defaultEditeditem);
		},
		launchMantenimiento(item) {
			this.dialogAtemporal = true;
			this.atemporalItem = item;
		},
		closeLaunchMantenimiento() {
			this.dialogAtemporal = false;
			this.atemporalItem = Object.assign({}, this.defaultEditeditem);
			this.atemporalInfo.inicio = null;
			this.atemporalInfo.instancias = [];
		},
		instanciasFiltradas() {
			return this.instancias.filter((instancia) => {
				return instancia.idMaquina == this.atemporalItem.idMaquina;
			});
		},
		validateAtemporal() {
			this.errores = [];
			if (!this.$utils.isValid(this.atemporalInfo.inicio)) {
				this.errores.push(
					"Falta Indicar una Fecha de Inicio del Mantenimiento"
				);
			}

			if (
				!this.$utils.isValid(this.atemporalInfo.instancias) ||
				this.atemporalInfo.instancias <= 0
			) {
				this.errores.push(
					"Falta Indicar la(s) instancia(s) a las que se realizara el mantenimiento"
				);
			}

			return this.errores.length == 0;
		},
		mantenimientoAtemporal() {
			if (this.validateAtemporal()) {
				var obj = {
					IdMantenimientoPreventivo:
						this.atemporalItem.idMantenimientoInspeccion,
					IdsMaquinasInstancias: this.atemporalInfo.instancias,
					InicioMantenimiento: this.atemporalInfo.inicio,
				};

				this.saving = true;

				axios
					.post("/Mantenimientos/PreventivoAtemporal", obj)
					.then(() => {
						this.saving = false;
						this.closeLaunchMantenimiento();
					})
					.catch((error) => {
						this.saving = false;
					});
			}
		},
		updateHorario($event) {
			var posExist = this.editedItem.horarios.findIndex((h) => {
				return (
					this.$utils.arrays.equals(
						h.diasSemana,
						$event.diasSemana
					) &&
					this.$utils.arrays.equals(h.meses, $event.meses) &&
					this.$utils.arrays.equals(h.diasMes, $event.diasMes) &&
					h.horaMinuto == $event.horaMinuto
				);
			});
			var pos = this.editedItem.horarios.findIndex(
				(h) =>
					h.idMantenimientoPreventivoHorario ==
					$event.idMantenimientoPreventivoHorario
			);
			if (posExist >= 0 && pos != posExist) {
				this.alerta = true;
				return;
			}
			if (pos >= 0) {
				this.editedItem.horarios[pos] = null;
				this.editedItem.horarios[pos] = $event;
			} else {
				this.editedItem.horarios.push($event);
			}
		},
		editHorario(item) {
			this.$refs.horario.lanzar(item);
		},
		deleteHorario(item) {
			var pos = this.editedItem.horarios.indexOf(item);
			if (pos >= 0) this.editedItem.horarios.splice(pos, 1);
		},
		validate() {
			this.errores = [];
			if (
				this.editedItem.codigo.length < 5 ||
				this.editedItem.codigo.length > 100
			) {
				this.errores.push(
					"El Código debe de tener entre 5 y 100 caracteres."
				);
			}
			if (
				this.editedItem.nombre.length < 5 ||
				this.editedItem.nombre.length > 1000
			) {
				this.errores.push(
					"El Nombre debe de tener entre 5 y 1000 caracteres."
				);
			}
			if (this.editedItem.idMaquina <= 0) {
				this.errores.push("Debe de seleccionar una Máquina.");
			}

			if (this.editedItem.duracion <= 0) {
				this.errores.push("Debe de indicar la Duración de la inspección.");
			}

			if (this.editedItem.fechaInicioProgramada == null) {
				this.errores.push("Debe de indicar la Fecha de Inicio Programada.");
			}

			if(this.editedItem.idSubdepartamento == null){
				this.errores.push("Debe seleccionar un Subdepartamento")
			}

			return this.errores.length == 0;
		},
		save() {
			this.guardando = true;
			if (!this.validate()) {
				this.guardando = false;
				return;
			} else {
				const comprobaciones = this.$refs.todo.getListado();
				var obj = {
					Codigo: this.editedItem.codigo,
					Nombre: this.editedItem.nombre,
					Descripcion: this.editedItem.descripcion,
					Duracion: this.editedItem.duracion,
					idSubdepartamento: this.editedItem.idSubdepartamento,
					IdMaquina: this.editedItem.idMaquina,
					idsMaquinaIns: this.editedItem.idsMaquinaIns,
					repeticion: this.editedItem.repeticion,
					comprobaciones,
					distribuirInspecciones:
						!this.editedItem.distribuirInspecciones,
					fechaInicioProgramada:
						this.editedItem.fechaInicioProgramada,
				};

				this.errorFecha = false;

				if (this.editedItem.idMantenimientoInspeccion == -1) {
					axios
						.post(
							"/Mantenimientos/CrearMantenimientoInspeccion",
							obj
						)
						.then(() => {
							this.closeMantenimiento();
							this.guardando = false;
						})
						.catch((error) => {
							console.log(error);
							this.guardando = false;
							if (error.response) {
								if (error.response.status == 400) {
									this.errorFecha = true;
									this.errores.push(
										"El Inicio Programado no debe ser menor a la Fecha actual."
									);
								}
							}
						});
				} else {
					axios
						.put(
							`/Mantenimientos/ActualizarMantenimientoInspeccion/${this.editedItem.idMantenimientoInspeccion}`,
							obj
						)
						.then(() => {
							this.closeMantenimiento();
							this.guardando = false;
						})
						.catch((error) => {
							console.log(error);
							this.guardando = false;
							if (error.response) {
								if (error.response.status == 400) {
									this.errorFecha = true;
									this.errores.push(
										"El Inicio Programado no debe ser menor a la Fecha actual."
									);
								}
							}
						});
				}
			}
		},
	},
};
</script>

<style>
.vue-swatches__trigger__wrapper {
	border: 1px solid rgb(0, 0, 0) !important;
	border-radius: 12px !important;
}
</style>