<template>
	<v-col cols="12" v-if="permisoVista('alertasmantenimientos','r')">
		<SignalR 
			path="/hub/mantenimientoalerta" 
			@change="getAlertas()"
		/>
		<v-row class="mb-4">
			<v-col cols="4" class="mt-0 pt-0">
				<v-select
					v-model="filtros.enCurso"
					:items="enCurso"
					item-text="nombre"
					item-value="value"
					label="En Curso"
					hide-details
                    clearable
                    @change="getAlertas()"
				></v-select>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="align-self-end">
				<VueCtkDateTimePicker
					v-model="filtros.inicio"
					label="Desde"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="getAlertas()"
				/>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="align-self-end">
				<VueCtkDateTimePicker
					v-model="filtros.fin"
					:min-date="filtros.inicio"
					label="Hasta"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="getAlertas()"
				/>
			</v-col>
			<v-col cols="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idMaquinaIns"
					:items="instancias"
					:item-text="marcaModelo"
					item-value="idMaquinaIns"
                    label="Máquina"
					clearable
                    hide-details
                    @change="getAlertas()"
				>
					<template v-slot:item="data">
						<v-list-item-content>
							<v-list-item-title>{{
								data.item.marca + " - " + data.item.modelo
							}}</v-list-item-title>
							<v-list-item-subtitle>{{
								data.item.nombre
							}}</v-list-item-subtitle>
							<v-list-item-content>{{
								$utils.strings.truncate(
									data.item.descripcionGeneral,
									50
								)
							}}</v-list-item-content>
						</v-list-item-content>
					</template>
				</v-autocomplete>
			</v-col>
            <v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idArea"
					:items="areas"
					item-value="idArea"
					item-text="nombre"
					label="Área"
					hide-details
					clearable
					@change="getAlertas()"
				></v-autocomplete>
			</v-col>
            <v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idPersonaRespuesta"
					:items="usuarios"
					item-value="idUsuario"
					item-text="nombre"
					label="Atendida por"
					hide-details
					clearable
					@change="getAlertas()"
				></v-autocomplete>
			</v-col>
		</v-row>
		<v-data-table
			:items="alertas"
			:headers="headersAlertas"
			:loading="loading"
		>
			<template v-slot:item.maquina="{ item }">
                <p 
                    class="mb-0" 
                    v-if="
                        item.marca!=null &&
                        item.modelo!=null &&
                        item.nombre!=null
                    "
                >
                    [{{ item.marca.trim() }} - {{ item.modelo.trim() }}] -
                    {{ item.nombre }}
                </p>
                <p 
                    class="mb-0" 
                    v-else
                >
                    N/A
                </p>
			</template>
            <template v-slot:item.personaRespuesta="{ item }">
                <p class="mb-0">
                    {{ 
                        item.personaRespuesta == "" ||
                        item.personaRespuesta == null
                        ? 'Sin atender'
                        : item.personaRespuesta
                    }}
                </p>
            </template>
		</v-data-table>
	</v-col>
</template>

<script>
import axios from "axios";
import SignalR from '../../SignalR.vue';

export default {
	components:{ SignalR },
	data() {
		return {
			filtros: {
				enCurso: true,
				inicio: null,
				fin: null,
				idMaquinaIns: null,
				idArea: null,
				idPersonaRespuesta: null,
			},
			enCurso: [
				{
					value: true,
					nombre: "Si",
				},
				{
					value: false,
					nombre: "No",
				},
			],
			alertas: [],
            instancias: [],
            areas: [],
            usuarios: [],
			headersAlertas: [
				{
					text: "Máquina",
					align: "center",
					sortable: false,
					value: "maquina",
				},
				{
					text: "Atendida por",
					align: "center",
					sortable: false,
					value: "personaRespuesta",
				},
				{
					text: "Fecha Alerta",
					align: "center",
					sortable: false,
					value: "fechaAlerta",
				},
				{
					text: "Fecha Respuesta",
					align: "center",
					sortable: false,
					value: "fechaRespuesta",
				},
			],
		};
	},
	mounted() {
		this.initialize();
	},
	methods: {
		marcaModelo: (item) => item.marca.trim() + " - " + item.modelo.trim(),
        nombrePersona: (persona) =>
			persona.nombre + " " + persona.paterno + " " + persona.materno,
		initialize() {
			this.getAlertas();
            this.getInstancias();
            this.getAreas();
            this.getUsuarios();
		},
        getUsuarios() {
			axios
				.get("/Mantenimientos/GetUsuariosRespuestaAlerta")
				.then((response) => {
					this.usuarios = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
        getAreas() {
			axios
				.get("/Areas")
				.then((response) => {
					this.areas = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getAlertas() {
            this.loading = true;
			axios
				.get("/Mantenimientos/GetMantenimientoAlerta",{
                    params: {
                        EnCurso: this.filtros.enCurso,
                        Inicio: this.filtros.inicio,
                        Fin: this.filtros.fin,
                        IdMaquinaIns: this.filtros.idMaquinaIns,
                        IdArea: this.filtros.idArea,
                        IdPersonaRespuesta: this.filtros.idPersonaRespuesta
                    }
                })
				.then((response) => {
                    this.alertas = response.data;
                    this.loading = false;
                })
				.catch((error) => {
                    this.loading = false;
					console.log(error);
				});
		},
        getInstancias() {
			axios
				.get("/MaquinaInstancias")
				.then((response) => {
					this.instancias = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>