import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ganttpost',{ref:"ganttpost"}),_c(VDialog,{key:'d'+_vm.detalles.toString(),attrs:{"persistent":"","max-width":"80%"},model:{value:(_vm.detalles),callback:function ($$v) {_vm.detalles=$$v},expression:"detalles"}},[_c(VCard,[(!_vm.clavePersonalizada)?_c(VCardTitle,[_vm._v("Detalles de "+_vm._s(_vm.watchedItem.ot))]):_c(VCardTitle,[_vm._v("Detalles de la ODT con Clave "+_vm._s(_vm.watchedItem.clave))]),_c(VCardText,[_c('apex-chart-dialog',{ref:"chartDetalles"}),_c(VDataTable,{attrs:{"items":_vm.watchedItem.articulos,"headers":_vm.headersDetalle},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.nombre))]),(_vm.permisoVista('listas','$'))?_c('td',{staticClass:"text-center"},[_vm._v("$"+_vm._s(item.precioUnitario.toFixed(2)))]):_vm._e(),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.cantidad))]),(_vm.permisoVista('listas','$'))?_c('td',{staticClass:"text-center"},[_vm._v("$"+_vm._s(item.costoEstimado.toFixed(2)))]):_vm._e(),(_vm.permisoVista('listas','$'))?_c('td',{staticClass:"text-center"},[_vm._v("$"+_vm._s(item.costoReal.toFixed(2)))]):_vm._e(),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.fechaInicioLabel))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.fechaTerminadoLabel))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.estiloTiempo(item.tiempoTotalSegundos)))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.estiloTiempo(item.tiempoTotalSegundos / item.cantidad)))]),_c('td',{staticClass:"text-center"},_vm._l((item.areas),function(area,idx){return _c('div',{key:'a'+item.idTrabajoOrdenDetalle+'-'+idx},[_vm._v(" "+_vm._s(area.area)+": "+_vm._s(_vm.estiloTiempo(area.tiempo))+" ")])}),0),_c('td',{staticClass:"text-center"},[(_vm.permisoVista('listas','$'))?_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.desgloseCostosOTDItem(item)}}},on),[_vm._v(" mdi-currency-usd ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Desglose de Costos")])]):_vm._e()],1)])]}}])})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.closeDetalles()}}},[_vm._v("Cerrar")])],1)],1)],1),(_vm.permisoVista('listas','r'))?_c(VDataTable,{ref:"ots",staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.trabajoOrdenes,"loading-text":"Cargando... Espere, Por Favor.","loading":_vm.loading,"server-items-length":_vm.trabajoOrdenes_total,"items-per-page":_vm.trabajoOrdenes_items_per_page,"page":_vm.trabajoOrdenes_actual_page,"footer-props":{disableItemsPerPage:true, disablePagination: this.loading},"disable-sort":true,"disable-pagination":_vm.loading,"hide-default-footer":""},on:{"update:page":_vm.trabajoOrdenes_update_page_action,"update:items-per-page":function (ipp) { return _vm.trabajoOrdenes_items_per_page = ipp; },"update:sort-by":_vm.trabajoOrdenes_sort_by_action},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":"","color":"white"}},[_c(VTextField,{attrs:{"append-icon":"search","label":"Buscar","clearable":true,"disabled":_vm.loading,"single-line":"","hide-details":""},on:{"keyup":_vm.getTrabajoOrdenesOnEnterAction,"click:clear":_vm.clearSearchAction,"click:append":function($event){return _vm.trabajoOrdenes_update_page_action(1)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.clavePersonalizada?item.clave:item.ot)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.lineaComercial)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.cliente)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.porcentajeCompletado.toFixed(2))+"% ")]),(_vm.permisoVista('listas','$'))?_c('td',{staticClass:"text-center"},[_vm._v(" $ "+_vm._s(item.costoEstimado.toFixed(2))+" ")]):_vm._e(),(_vm.permisoVista('listas','$'))?_c('td',{staticClass:"text-center"},[_vm._v(" $ "+_vm._s(item.costoReal.toFixed(2))+" ")]):_vm._e(),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.fechaInicioLabel)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.fechaTerminadoLabel)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.fechaEntrega)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.estiloTiempo(item.tiempoTotalSegundos))+" ")]),_c('td',{staticClass:"text-center"},[_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.estadoProduccion == 3 && _vm.permisoVista('listas','r'))?_c(VIcon,_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.ganttItem(item)}}},on),[_vm._v(" mdi-chart-timeline ")]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Gantt")])]),_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.permisoVista('listas','r'))?_c(VIcon,_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.watchItem(item)}}},on),[_vm._v(" mdi-eye ")]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Ver detalles")])]),_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.permisoVista('listas','$'))?_c(VIcon,_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.desgloseCostosItem(item)}}},on),[_vm._v(" mdi-currency-usd ")]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Desglose de Costos")])])],1)])]}},{key:"footer",fn:function(ref){
var props = ref.props;
return [_c(VCol,{staticClass:"d-flex justify-end align-center border-footer",attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0 mr-8",staticStyle:{"font-size":"12px !important"}},[_vm._v("Filas por página:")]),_c(VTextField,{staticClass:"centered-input footer-input mt-0 mr-10",attrs:{"value":props.pagination.itemsPerPage,"dense":"","hide-details":"","disabled":""}}),_c('p',{staticClass:"mb-0 mr-10",staticStyle:{"font-size":"12px !important"}},[_vm._v(" "+_vm._s(props.pagination.itemsLength ? _vm.getPageText(props.pagination): '-')+" ")]),_c(VPagination,{attrs:{"length":props.pagination.pageCount,"total-visible":7,"disabled":_vm.loading},model:{value:(_vm.trabajoOrdenes_actual_page),callback:function ($$v) {_vm.trabajoOrdenes_actual_page=$$v},expression:"trabajoOrdenes_actual_page"}})],1)]}}],null,false,2098351943)}):_vm._e(),_c('apex-chart-dialog',{ref:"chartGlobal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }