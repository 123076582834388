<template>
	<v-col cols="12">
		<v-dialog v-model="dialogDesglosar" persistent max-width="95%">
			<v-card>
				<v-card-title>Desglosar Incidencia</v-card-title>
				<v-card-text>
					<p class="mb-0 text--secondary">Clave Operación: {{ desglosarItem.claveOT }}</p>
					<p class="mb-0 text--secondary">Cantidad Operación: {{ desglosarItem.cantidad }}</p>
					<p class="mb-0 text--secondary">Inicio Operación: {{desglosarItem.fechaInicio}}</p>
					<p class="mb-4 text--secondary">Fin Operación: {{desglosarItem.fechaFin}}</p>
					<v-col cols="12">
						<v-data-table
							:items="desglosarItem.desgloses"
							:headers="headersDesglose"
							disable-sort
							hide-default-footer
							:items-per-page="-1"
						>
							<template v-slot:item.posicion="{ index }">
								{{ index + 1 }}
							</template>
							<template v-slot:item.idParoTipo="{ item, index }">
								<v-autocomplete
									v-model="item.idParoTipo"
									:items="tipoParos"
									item-value="idParoTipo"
									item-text="nombre"
									hide-details
									clearable
									dense
									@change="changeTipoParo(index)"
								></v-autocomplete>
							</template>
							<template
								v-slot:item.idSubParoTipo="{ item, index }"
							>
								<v-autocomplete
									v-model="item.idSubParoTipo"
									:items="reporteTipoSubparos(index)"
									item-value="idSubParoTipo"
									item-text="nombreSubParoTipo"
									hide-details
									clearable
									dense
								></v-autocomplete>
							</template>
							<template v-slot:item.idMaquinaIns="{ item }">
								<v-autocomplete
									v-model="item.idMaquinaIns"
									:items="instanciasFiltradas"
									:item-text="marcaModelo"
									item-value="idMaquinaIns"
									clearable
								>
									<template v-slot:item="data">
										<v-list-item-content>
											<v-list-item-title>{{
												data.item.marca +
												" - " +
												data.item.modelo
											}}</v-list-item-title>
											<v-list-item-subtitle>{{
												data.item.nombre
											}}</v-list-item-subtitle>
											<v-list-item-content>{{
												$utils.strings.truncate(
													data.item
														.descripcionGeneral,
													50
												)
											}}</v-list-item-content>
										</v-list-item-content>
									</template>
								</v-autocomplete>
							</template>
							<template v-slot:item.bloqueoProducion="{ item }">
								<v-btn-toggle
									v-model="item.bloqueoProducion"
									class="my-3"
								>
									<v-btn
										depressed
										small
										class="ma-0"
										:color="
											item.bloqueoProducion == 0
												? 'success'
												: 'grey lighten-1'
										"
										>Si</v-btn
									>
									<v-btn
										depressed
										small
										class="ma-0"
										:color="
											item.bloqueoProducion == 1
												? 'error'
												: 'grey lighten-1'
										"
										>No</v-btn
									>
								</v-btn-toggle>
							</template>
							<template v-slot:item.cantidadReportada="{ item }">
								<number-input
									v-model="item.cantidadReportada"
									controls
									inline
									size="small"
									:min="0"
									:max="maxCantidad(item.cantidadReportada)"
									center
								></number-input>
							</template>
							<template v-slot:item.inicio="{ item, index }">
								<VueCtkDateTimePicker
									v-model="item.inicio"
									:min-date="desglosarItem.fechaInicio"
									:max-date="desglosarItem.fechaFin"
									format="YYYY-MM-DD HH:mm:ss"
									formatted="YYYY-MM-DD HH:mm:ss"
									noHeader
									overlay
									:key="`ctki-${index}`"
									buttonNowTranslation="Ahora"
									label=""
									noClearButton
								/>
							</template>
							<template v-slot:item.fin="{ item, index }">
								<VueCtkDateTimePicker
                  					v-if="item.bloqueoProducion == 0"
									v-model="item.fin"
									:min-date="item.inicio"
									:max-date="desglosarItem.fechaFin"
									format="YYYY-MM-DD HH:mm:ss"
									formatted="YYYY-MM-DD HH:mm:ss"
									noHeader
									overlay
									:key="`ctkf-${index}`"
									buttonNowTranslation="Ahora"
									label=""
									noClearButton
								/>
							</template>
							<template v-slot:item.actions="{ index }">
								<v-tooltip
									
									bottom
									color="black"
									class="white--text"
								>
									<template v-slot:activator="{ on }">
										<v-icon
											v-on="on"
											small
											@click="eliminarDesglose(index)"
										>
											mdi-delete
										</v-icon>
									</template>
									<span class="white--text">Eliminar</span>
								</v-tooltip>
							</template>
						</v-data-table>
					</v-col>
					<v-col cols="4" class="py-0">
						<v-btn class="my-1" text color="primary" @click="agregarNewDesglose()"
							>Agregar Desglose</v-btn
						>
					</v-col>
					<ul class="mt-2">
						<li class="red--text" v-for="ex in erroresDesglose" :key="ex">
						{{ ex }}
						</li>
					</ul>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="green"
						:disabled="loadingDesgloses"
						@click="cerrarDialogDesglose()"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="red"
						:disabled="loadingDesgloses"
						:loading="loadingDesgloses"
						@click="saveDesgloses()"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row class="mb-5">
			<v-col cols="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idTrabajoOrden"
					:items="claves"
					item-value="idTrabajoOrden"
					item-text="clave"
					label="Clave"
					hide-details
					clearable
					@change="getIncidenciasSinDesglosar()"
				></v-autocomplete>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idArticuloProducir"
					:items="articulosProducir"
					item-value="idArticulo"
					item-text="nombre"
					label="Artículo Producir"
					hide-details
					clearable
					@change="getIncidenciasSinDesglosar()"
				></v-autocomplete>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idArticuloFase"
					:items="articulosFase"
					item-value="idArticulo"
					item-text="nombre"
					label="Artículo Fase"
					hide-details
					clearable
					@change="getIncidenciasSinDesglosar()"
				></v-autocomplete>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idArea"
					:items="areas"
					item-value="idArea"
					item-text="nombre"
					label="Área"
					hide-details
					clearable
					@change="getIncidenciasSinDesglosar()"
				></v-autocomplete>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idReporto"
					:items="operadores"
					item-value="idPersona"
					:item-text="nombrePersona"
					label="Reportado Por"
					hide-details
					clearable
					@change="getIncidenciasSinDesglosar()"
				></v-autocomplete>
			</v-col>
		</v-row>
		<v-data-table
			:headers="headers"
			:items="sindesglosar"
			:loading="loading"
			loading-text="Cargando... Espere, Por Favor."
		>
			<template v-slot:item.operadores="{ item }">
				<ul class="text-left pl-0" v-if="item.operadores.length > 0">
					<li
						v-for="(operador, index) in item.operadores"
						:key="`op-${index}`"
					>
						{{ operador }}
					</li>
				</ul>
				<div v-else>N/D</div>
			</template>
			<template v-slot:item.actions="{ item }">
				<v-tooltip
					v-if="permisoVista('defectonodesglozados','c')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							class="mr-2"
							@click="desglosar(item)"
							>mdi-file-tree</v-icon
						>
					</template>
					<span class="white--text">Desglosar</span>
				</v-tooltip>
			</template>
		</v-data-table>
	</v-col>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
	data() {
		return {
			headersDesglose: [
				{
					text: "#",
					align: "center",
					sortable: false,
					value: "posicion",
					width: "5%",
				},
				{
					text: "Motivo Incidencia",
					align: "center",
					sortable: false,
					value: "idParoTipo",
					width: "14%"
				},
				{
					text: "Submotivo Incidencia (Opcional)",
					align: "center",
					sortable: false,
					value: "idSubParoTipo",
					width: "12%"
				},
				{
					text: "Máquina (opcional)",
					align: "center",
					sortable: false,
					value: "idMaquinaIns",
					width: "12%"
				},
				{
					text: "¿Bloquear Producción?",
					align: "center",
					sortable: false,
					value: "bloqueoProducion",
					width: "10%"
				},
				{
					text: "Cantidad Reportada",
					align: "center",
					sortable: false,
					value: "cantidadReportada",
					width: "8%"
				},
				{
					text: "Inicio",
					align: "center",
					sortable: false,
					value: "inicio",
					width: "14%"
				},
				{
					text: "Fin",
					align: "center",
					sortable: false,
					value: "fin",
					width: "14%"
				},
				{
					text: "",
					align: "center",
					sortable: false,
					value: "actions",
					width: "5%",
				},
			],
			dialogDesglosar: false,
			itemDesglosed: {
				idParoTipo: 0,
				idSubParoTipo: 0,
				idMaquinaIns: null,
				bloqueoProducion: 0,
				cantidadReportada: 0,
				inicio: null,
				fin: null,
			},
			desglosarItem: {
				idDefectoNoDesglozado: 0,
				fechaInicio: null,
				fechaFin: null,
				claveOT: "",
				cantidad: 0,
				idsMaquinasIns: [],
				desgloses: [],
			},
			defaultDesglosarItem: {
				idDefectoNoDesglozado: 0,
				fechaInicio: null,
				fechaFin: null,
				claveOT: "",
				cantidad: 0,
				idsMaquinasIns: [],
				desgloses: [],
			},
			loading: false,
			headers: [
				{
					text: "Clave",
					align: "center",
					sortable: false,
					value: "claveOT",
				},
				{
					text: "Reportado por",
					align: "center",
					sortable: false,
					value: "usuarioReporto",
				},

				{
					text: "Artículo",
					align: "center",
					sortable: false,
					value: "articuloProducir",
				},
				{
					text: "Fase",
					align: "center",
					sortable: false,
					value: "articuloFase",
				},
				{
					text: "Área",
					align: "center",
					sortable: false,
					value: "area",
				},
				{
					text: "Cantidad",
					align: "center",
					sortable: false,
					value: "cantidad",
				},
				{
					text: "Personas Responsables",
					align: "center",
					sortable: false,
					value: "operadores",
				},
				{
					text: "Inicio",
					align: "center",
					sortable: false,
					value: "fechaInicio",
				},
				{
					text: "Fin",
					align: "center",
					sortable: false,
					value: "fechaFin",
				},
				{
					text: "Acciones",
					align: "center",
					sortable: false,
					value: "actions",
					width: "5%",
				},
			],
			sindesglosar: [],
			filtros: {
				idTrabajoOrden: null,
				idArticuloProducir: null,
				idArticuloFase: null,
				idArea: null,
				idOperador: null,
			},
			claves: [],
			articulosProducir: [],
			articulosFase: [],
			areas: [],
			operadores: [],
			tipoParos: [],
			tipoSubparos: [],
			instancias: [],
			erroresDesglose: [],
			loadingDesgloses: false,
		};
	},
	mounted() {
		this.initialize();
	},
	computed: {
		instanciasFiltradas(){
			let maquinasFiltradas = [];
			this.instancias.forEach(ins => {
				this.desglosarItem.idsMaquinasIns.forEach( maq => {
					if (ins.idMaquinaIns == maq) maquinasFiltradas.push(ins);
				});
			});
			return maquinasFiltradas;
		},
	},
	methods: {
		marcaModelo: (item) => item.marca + " - " + item.modelo,
		maxCantidad(current){
			let total = 0;
			this.desglosarItem.desgloses.forEach( desglose => {
				total += desglose.cantidadReportada;
			});
			if (total < this.desglosarItem.cantidad){
				return (this.desglosarItem.cantidad-total)+current;
			}else{
				return current;
			}
		},
		validate() {
			this.erroresDesglose = [];
			if (this.desglosarItem.desgloses.length == 0) {
				this.erroresDesglose.push("No se ha agregado ningún desglose");
				return false;
			} else {
				this.desglosarItem.desgloses.forEach((desglose, idx) => {
					if (desglose.idParoTipo == 0) {
						this.erroresDesglose.push(
							`Debe seleccionar el Motivo de la Incidencia en Renglon #${idx+1}`
						);
					}

					if (desglose.bloqueoProducion == undefined) {
						this.erroresDesglose.push(
							`Debe indicar si desea Bloquear la Producción en Renglon #${idx+1}`
						);
					}

					if (
						isNaN(desglose.cantidadReportada) ||
						desglose.cantidadReportada < 0
					) {
						this.erroresDesglose.push(
							`Debe introducir una Cantidad Reportada valida en Renglon #${idx+1}`
						);
					}

					if (desglose.inicio == null) {
						this.erroresDesglose.push(
							`Debe seleccionar una Fecha de Inicio valida en Renglon #${idx+1}`
						);
					}

					if(desglose.inicio != null && desglose.bloqueoProducion == 1 ){
						if(!(moment(desglose.inicio).isBetween(this.desglosarItem.fechaInicio, this.desglosarItem.fechaFin))){
							this.erroresDesglose.push(
							`Debe seleccionar una Fecha de Inicio dentro del rango de fechas de la operacion en Renglon #${idx+1}`
							);
						}
					}

					if (desglose.fin == null) {
						this.erroresDesglose.push(
							`Debe seleccionar una Fecha de Fin valida en Renglon #${idx+1}`
						);
					}

					if(desglose.fin!=null){
						if(!(moment(desglose.fin).isBefore(this.desglosarItem.fechaFin))){
							this.erroresDesglose.push(
							`Debe seleccionar una Fecha de Fin menor a la fecha final de la operacion en Renglon #${idx+1}`
							);
						}
					}
					
				});

				return this.erroresDesglose.length == 0;
			}
		},
		saveDesgloses() {
			if (!this.validate()) return;
			this.loadingDesgloses = true;
			const desgloses = this.desglosarItem.desgloses.map( desglose => ({
				...desglose,
				bloqueoProducion: !desglose.bloqueoProducion,
        		fin: !desglose.bloqueoProducion==false ? desglose.inicio : desglose.fin 
			}));
			axios
				.post(
					`/DefectoNoDesglozados/AsignarParosDefecto/${this.desglosarItem.idDefectoNoDesglozado}`,
					desgloses
				)
				.then(() => {
					this.loadingDesgloses = false;
					this.cerrarDialogDesglose();
					this.getIncidenciasSinDesglosar();
				})
				.catch((error) => {
					this.loadingDesgloses = false;
					if (error.response) {
						if (error.response.status == 400) {
							this.erroresDesglose.push(
								"Las fechas de Inicio y las fechas de Fin deben estar dentro del rango de fechas de la Operación"
							);
						}
					}
				});
		},
		eliminarDesglose(index) {
			this.desglosarItem.desgloses.splice(index, 1);
		},
		initialize() {
			this.getIncidenciasSinDesglosar();
			this.getClaves();
			this.getArticulosProducir();
			this.getArticulosFase();
			this.getAreas();
			this.getOperadores();
			this.getTipoSubParos();
			this.getTipoParos();
			this.getInstancias();
		},
		getInstancias() {
			axios
				.get("/MaquinaInstancias")
				.then((response) => {
					this.instancias = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getTipoParos() {
			axios
				.get("/ParoTipos")
				.then((response) => {
					this.tipoParos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getTipoSubParos() {
			axios
				.get("/SubParoTipos")
				.then((response) => {
					this.tipoSubparos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		reporteTipoSubparos(index) {
			return this.$utils.isValid(
				this.desglosarItem.desgloses[index].idParoTipo
			)
				? this.tipoSubparos.filter(
						(sub) =>
							sub.idParoTipo ==
							this.desglosarItem.desgloses[index].idParoTipo
				  )
				: [];
		},
		changeTipoParo(index) {
			this.desglosarItem.desgloses[index].idSubParoTipo = null;
		},
		agregarNewDesglose() {
			const obj = ({
				...this.itemDesglosed,
				inicio: this.desglosarItem.fechaInicio,
				fin: this.desglosarItem.fechaFin
			});
			this.desglosarItem.desgloses.push(
				Object.assign({}, obj)
			);
		},
		cerrarDialogDesglose() {
			this.dialogDesglosar = false;
			this.$nextTick( () => {
				this.desglosarItem = Object.assign({}, this.defaultDesglosarItem);
				this.desglosarItem.desgloses = [];
				this.desglosarItem.idsMaquinasIns = [];
				this.erroresDesglose = [];
			});
		},
		desglosar(item) {
			this.desglosarItem.idDefectoNoDesglozado =
				item.idDefectoNoDesglozado;
			this.desglosarItem.fechaInicio = item.fechaInicio;
			this.desglosarItem.fechaFin = item.fechaFin;
			this.desglosarItem.claveOT = item.claveOT;
			this.desglosarItem.cantidad = item.cantidad;
			this.desglosarItem.idsMaquinasIns = item.idsMaquinasIns;
			this.dialogDesglosar = true;
		},
		nombrePersona: (persona) =>
			persona.nombre + " " + persona.paterno + " " + persona.materno,
		getOperadores() {
			axios
				.get("/Personas/Operadores")
				.then((response) => {
					this.operadores = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getAreas() {
			axios
				.get("/Areas")
				.then((response) => {
					this.areas = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getArticulosFase() {
			axios
				.get("/Articulos/ArticulosNombres?componentes=true")
				.then((response) => {
					this.articulosFase = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getArticulosProducir() {
			axios
				.get("/Articulos/OTS")
				.then((response) => {
					this.articulosProducir = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getClaves() {
			axios
				.get("/TrabajoOrdenes/TrabajoOrdenHeaders")
				.then((response) => {
					this.claves = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getIncidenciasSinDesglosar() {
			axios
				.get("/DefectoNoDesglozados/GetDefectoNoDesglozado", {
					params: {
						IdTrabajoOrden: this.filtros.idTrabajoOrden,
						IdArticuloProducir: this.filtros.idArticuloProducir,
						IdArticuloFase: this.filtros.idArticuloFase,
						IdArea: this.filtros.idArea,
						IdOperador: this.filtros.idOperador,
					},
				})
				.then((response) => {
					this.sindesglosar = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>

<style>
.datetimepicker{
	position: fixed !important;
	top: 50% !important;
	right: -60% !important;
}
</style>