<template>
  <v-col cols="12" v-if="permisoVista('movimientos', 'r')">
    <MovimientoAlmacenes
      v-model="dialogMovimientoAlmacenes"
      @initialize="initialize()"
      ref="movAlmacenes"
    />
    <v-row v-if="trazabilidadInventario">
      <v-tabs v-model="tab" grow>
        <v-tab @click="getInformacion"> Resumen</v-tab>
        <v-tab @click="getInformacion"> Detalles</v-tab>
      </v-tabs>
    </v-row>
    <br>
    <nuevoMovimiento
      v-if="inventario"
      :show="movimiento"
      @change="nuevoMovimientoChange"
    ></nuevoMovimiento>
    <nuevoMovimientoBasico
      v-if="inventario"
      :show="movimientoBasico"
      @change="nuevoMovimientoChange"
    >
    </nuevoMovimientoBasico>
    <v-dialog
			v-model="cargaMasiva"
			persistent
			max-width="40%"
		>
			<v-card>
				<v-card-title>
					Carga Masiva de Movimientos de Entrada con QR
				</v-card-title>
				<template v-if="estadoCarga == 0">
					<v-card-text>
						<v-file-input
      			  v-model="ficheroActualizacion"
      			  label="Formato de Carga Masiva de Movimientos de Entrada con QR (.csv)"
      			  accept=".csv"
      			  counter
      			  show-size
      			  @change="cambioFicheroActualizacion"
      			></v-file-input>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn @click="cerrarCargaMasiva" text color="green">Cancelar</v-btn>
						<v-btn @click="realizarCargaMasiva" text color="red">Guardar</v-btn>
					</v-card-actions>
				</template>
				<template v-if="estadoCarga == 1">
					<v-card-text class="text-center">
						<v-progress-circular
							indeterminate
							size="80"
							width="6"
							color="primary"
						></v-progress-circular>
						<p class="text-h6">Cargando</p>
					</v-card-text>
				</template>
				<template v-if="estadoCarga == 2">
					<v-card-text class="text-center py-0">
						<v-col cols="12" md="12" sm="12">
              <template v-if="erroresCargaMasiva.length == 0">
                <lottie
                  id="inProgressAnimation"
                  :options="inProgressAnimationOptions"
                  :height="200"
                  :width="200"
                  :key="`error-${inProgressAnimationKey}`"
                  class="py-0"
                />
                <p class="text-h5 font-weight-medium">Carga Masiva en Progreso</p>
                <p class="text-h6 font-weight-regular">Se le notificará cuando la carga haya concluido.</p>
              </template>
              <template v-else>
                <v-icon
                  size="100"
                  color="red"
                >
                  mdi-close-circle
                </v-icon>
                <p class="text-h6">Han ocurrido errores al realizar la carga masiva: </p>
                <p class="text-subtitle-1" v-for="(item, index) in erroresCargaMasiva" :key="index">
                  <v-icon color="red">mdi-alert-circle-outline</v-icon> {{item}}
                </p>
              </template>
            </v-col>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
							<v-btn text color="green" @click="cerrarCargaMasiva">Aceptar</v-btn>
						<v-spacer></v-spacer>
					</v-card-actions>
				</template>
				<template v-if="estadoCarga == 3">
					<v-card-text class="text-center">
						<v-icon
							size="100"
							color="red"
						>
							mdi-close-circle
						</v-icon>
						<div class="text-h6">Ha sucedido un error. Intente nuevamente.</div>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
							<v-btn text color="green" @click="cerrarCargaMasiva">Aceptar</v-btn>
						<v-spacer></v-spacer>
					</v-card-actions>
				</template>

			</v-card>
		</v-dialog>
    <v-col v-if="inventario" cols="12" class="ma-0 pa-0 d-flex justify-space-between">
      <v-btn v-if="trazabilidadInventario && permisoVista('movimientos', 'c')"
        color="primary"
        @click="movimiento = true"
        >Nuevo Movimiento</v-btn
      >
      <v-btn v-if="!trazabilidadInventario && permisoVista('movimientos', 'c')"
        color="primary"
        @click="movimientoBasico = true"
        >Nuevo Movimiento</v-btn
      >
      <div v-if="trazabilidadInventario">
        <download-btn
					v-if="permisoVista('movimientos','r')"
      	  color="blue darken-1"
      	  text
      	  @click="descargarFormatoCargaMasiva"
      	  label="Descargar Formato<br/>De Carga Masiva"
      	></download-btn>
        <v-btn 
          color="blue darken-1" 
          text 
          @click="cargaMasiva = true"
        >
          Carga Masiva de<br>Movimientos de Entrada
        </v-btn>
      </div>
    </v-col>
    
    <v-row v-if="permisoVista('movimientos', 'r')">
      <v-col cols="2" md="2" sm="2" class="pb-0">
        <v-select
          v-model="idTipoMovimiento"
          label="Tipo de Movimiento"
          :items="tipoMovimientos"
          item-text="nombre"
          item-value="idTipoMovimiento"
          clearable
          hide-details
          @click:clear="idArticulo=-1; resetTipoMovimieto"
          @change="
            idTipoSalida = null;
            getInformacion()
          "
        >
        </v-select>
      </v-col>
      <v-col 
        v-if="
          idTipoMovimiento == 6 || 
          idTipoMovimiento == 1
        "
        cols="2"
      >
        <v-autocomplete
          label="Tipo de Salida"
          v-model="idTipoSalida"
          :items="tiposSalidasFiltrados"
          item-text="nombre"
          item-value="idTipoSalida"
          hide-details
          clearable
          @change="getInformacion"
        ></v-autocomplete>
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          v-model="idAlmacen"
          label="Almacén"
          :items="almacenes"
          :item-text="nombreAlmacen"
          item-value="idAlmacen"
          hide-details
          clearable
          :loading="loadingAlmacenes"
          @change="changeAlmacen()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          v-model="idSubalmacen"
          label="Subalmacén"
          :items="subalmacenesComputed"
          :item-text="nombreSubalmacen"
          item-value="idSubalmacen"
          hide-details
          clearable
          :loading="loadingSubalmacenes"
          @change="getInformacion"
        ></v-autocomplete>
      </v-col>
      <v-col cols="2" md="2" sm="2" class="pb-0">
        <v-select
          v-model="idArticuloTipo"
          label="Tipo de Articulo"
          :items="tipoArticulos"
          item-text="nombre"
          item-value="idArticuloTipo"
          :loading="loadArticuloTipo"
          clearable
          hide-details
          @click:clear="articulos=[]"
          @change="filtrarArticulos(); getInformacion();"
        >
        </v-select>
      </v-col>
      <v-col cols="2" md="2" sm="2" class="pb-0">
        <v-autocomplete
          v-model="idArticulo"
          label="Artículo"
          :items="articulos"
          item-value="idArticulo"
          :item-text="nombreArticulo"
          :loading="loadingArticulos"
          :disabled="loadingArticulos || idArticuloTipo==null"
          :placeholder="idArticuloTipo==null && 'Seleccione un Tipo de Artículo'"
          :persistent-placeholder="idArticuloTipo==null"
          clearable
          hide-details
          @click:clear="resetArticulo"
          @change="getInformacion"
        ></v-autocomplete>
      </v-col>
      <v-col cols="2" md="2" sm="2" class="pb-0">
        <datePicker
          v-model="minimo"
          :maxDate="maximo"
          label="Desde"
          format="YYYY-MM-DD"
          clearable
          maxToday
          @input="getInformacion"
        ></datePicker>
      </v-col>
      <v-col cols="2" md="2" sm="2" class="pb-0">
        <datePicker
          v-model="maximo"
          :minDate="minimo"
          label="Hasta"
          format="YYYY-MM-DD"
          clearable
          maxToday
          @input="getInformacion"
        ></datePicker>
      </v-col>
      <v-col cols="2" md="2" sm="2" class="pb-0">
        <v-select
          v-model="sospechoso"
          label="Sospechosos"
          :items="opcionesSospechoso"
          item-text="name"
          item-value="value"
          hide-details
          clearable
          @change="getInformacion"
        ></v-select>
      </v-col>
      <v-col
        cols="2"
      >
        <v-autocomplete
          v-model="filtroOrdenTrabajo"
          label="Orden de Trabajo"
          :items="trabajoOrdenes"
          item-text="nombre"
          item-value="idTrabajoOrden"
          hide-details
          clearable
          @change="changeODT()"
        ></v-autocomplete>
      </v-col>
      <v-col
        cols="2"
      >
        <v-autocomplete
          v-model="filtroOrdenTrabajoDetalle"
          label="Artículo a Producir"
          :items="trabajoOrdenesDetallesFiltradas"
          item-text="articulo"
          item-value="idTrabajoOrdenDetalle"
          :disabled="filtroOrdenTrabajo==null"
          :placeholder="filtroOrdenTrabajo==null && 'Seleccione una ODT'"
          :persistent-placeholder="filtroOrdenTrabajo==null"
          hide-details
          clearable
          @change="getInformacion"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-tabs-items v-model="tab" touchless>
          <v-tab-item eager>
            <resumen
              ref="movimientos"
              :validarDescarga="descargaValida"
              @showDialogMovimientoAlmacenes="showDialogMovimientoAlmacenes"
            ></resumen>
          </v-tab-item>
          <v-tab-item eager>
            <detalles
              ref="detalles"
              :validarDescarga="descargaValida"
              @showDialogMovimientoAlmacenes="showDialogMovimientoAlmacenes"
            ></detalles>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import axios from "axios";
import moment from "moment";
import datePicker from "@/components/DatePicker";
import { VueDatePicker } from "@mathieustan/vue-datepicker";
import nuevoMovimiento from "./Movimientos/NuevoMovimiento";
import { mapState } from "vuex";
import resumen from "./Movimientos/MovimientoResumen";
import detalles from "./Movimientos/MovimientoDetalles";
import DownloadBtn from '../../DownloadBtn.vue';
import NuevoMovimientoBasico from './Movimientos/NuevoMovimientoBasico.vue'
import Lottie from 'vue-lottie';
import * as inProgressAnimation from '@/assets/inProgress.json';
import MovimientoAlmacenes from './Movimientos/MovimientoAlmacenes.vue';

export default {
  data: () => ({
    dialogMovimientoAlmacenes: false,
    loadingAlmacenes: false,
    loadingSubalmacenes: false,
    idAlmacen: null,
    idSubalmacen: null,
    almacenes: [],
    subalmacenes: [],
    todasTrabajoOrdenesDetalles: [],
    filtroOrdenTrabajoDetalle: null,
    filtroOrdenTrabajo: null,
    inProgressAnimationOptions: {
      animationData: inProgressAnimation.default,
      loop:true,
      speeed: 1
    },
    inProgressAnimationKey: 0,
    erroresCargaMasiva: [],
    estadoCarga: 0,
    ficheroActualizacion: null,
    cargaMasiva: false,
    tiposSalidas: [
      {
        idTipoSalida:1,
        nombre: "A Producción",
      },
      {
        idTipoSalida:2,
        nombre: "A Cliente",
      },
      {
        idTipoSalida:3,
        nombre: "A Mantenimiento",
      },
    ],
    idTipoSalida: null,
    movimientoBasico: false,
    movimiento: false,
    movimientos: [],
    movDetalle:[],
    sospechoso: null,
    idArticulo: -1,
    idTipoMovimiento: -1,
    articulos: [],
    articulosGet: [],
    minimo: null,
    maximo: null,
    tab: 0,
    loadingArticulos: false,
    opcionesSospechoso: [
      {
        name: "Si",
        value: true
      },
      {
        name: "No",
        value: false
      }
    ],
    tipoMovimientos: [
      {
        nombre: "Salida Parcial",
        idTipoMovimiento: 6,
      },
      {
        nombre: "Salida",
        idTipoMovimiento: 1,
      },
      {
        nombre: "Entrada",
        idTipoMovimiento: 2,
      },
      {
        nombre: "Excedente",
        idTipoMovimiento: 3,
      },
      {
        nombre: "Reintegro",
        idTipoMovimiento: 4,
      },
      {
        nombre: "Transferencia",
        idTipoMovimiento: 7,
      },
    ],
    idArticuloTipo:null,
    tipoArticulos:[],
    loadArticuloTipo:false,
    trabajoOrdenes: []
  }),
  components: {
    datePicker: datePicker,
    nuevoMovimiento,
    resumen,
    detalles,
    DownloadBtn,
    'lottie': Lottie,
    nuevoMovimientoBasico: NuevoMovimientoBasico,
    MovimientoAlmacenes
  },
  computed: {
    ...mapState({
      inventario: "inventario",
      token: "token",
      trazabilidadInventario: "trazabilidadInventario"
    }),
    subalmacenesComputed(){
      return this.idAlmacen == null 
        ? this.subalmacenes
        : this.subalmacenes.filter( subalmacen => subalmacen.idAlmacen == this.idAlmacen );
    },
    trabajoOrdenesDetallesFiltradas(){
      const ots =  []
      this.trabajoOrdenes.forEach( ot => {
        if(ot.idTrabajoOrden == this.filtroOrdenTrabajo){
          ots.push(...ot.detalles);
        }
      });
      return ots;
    },
    tiposSalidasFiltrados(){
      var salidas = JSON.parse(JSON.stringify(this.tiposSalidas));
      if(this.idTipoMovimiento == 1 || this.idTipoMovimiento == 6){
        salidas.push({
          idTipoSalida:4,
          nombre: "A Proveedor",
        });
      }
      if(this.idTipoMovimiento == 1){
        salidas.push({
          idTipoSalida: 5,
          nombre: "Venta al Público"
        });
      }
      return salidas;
    }
  },
  watch:{
    tab(){
      this.getInformacion();
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    nombreAlmacen: ({ codigo, nombre }) => `[${codigo}] - ${nombre}`.trim(),
    nombreSubalmacen: ({ clave, subalmacen }) => `[${clave}] - ${subalmacen}`.trim(),
    nombreArticulo: (value) => value.codigo!=null?`[${value.codigo}] - ${value.nombre}`:`${value.nombre}`,
    showDialogMovimientoAlmacenes(){
      this.dialogMovimientoAlmacenes = true;
    },
    changeAlmacen(){
      this.idSubalmacen = null;
      this.getInformacion();
    },
    changeODT(){
      this.filtroOrdenTrabajoDetalle = null;
      this.getInformacion();
    },
    getOTs(){
      axios
        .get("TrabajoOrdenes/ListarOT?otsCerradas=true")
        .then(response => {
          this.trabajoOrdenes = response.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    descargaValida(item){
      if(item.idTipoMovimiento == 2){
        return item.detallesCant > 0 &&
              (item.qr == true || item.qr == null);
      }
      if(item.idTipoMovimiento == 6){
        return item.detallesCant > 0 &&
              (item.qr == true || item.qr != null);
      }
      if(item.idTipoMovimiento == 7){
        return item.detallesCant > 0 &&
              (item.qr == true && item.qr != null);
      }
      return false;
    },
    cambioFicheroActualizacion(event) {
      if (typeof event === "undefined" || event == null)
        this.ficheroActualizacion = null;
      else this.ficheroActualizacion = event;
    },
    cerrarCargaMasiva(){
      this.cargaMasiva = false;
      this.estadoCarga = 0;
      this.ficheroActualizacion = null;
      this.erroresCargaMasiva = [];
    },
    realizarCargaMasiva(){
      if (this.ficheroActualizacion == null) return;
      this.estadoCarga = 1;
      let formData = new FormData();
      formData.append("formfile", this.ficheroActualizacion);
      axios
        .post("/Inventario/CargaMasivaMovimientos", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then((response) => {
          this.erroresCargaMasiva = response.data;
          this.inProgressAnimationKey++;
          this.estadoCarga = 2;
          this.initialize();
        })
        .catch(error => {
          this.estadoCarga = 3;
          console.log(error);
        });
    },
    descargarFormatoCargaMasiva(){
      var datetime = moment().format('YYYYMMDDHHmmss');
      this.$utils.axios.downloadFile('/Inventario/PlantillaCargaMasivaMovimientos?access_token='+this.token,'GET','formato-cargamasiva-movimientosentrada-'+datetime+'.csv','text/csv');
    },
    nombre(item) {
      return "[" + item.codigo + "] - " + item.nombre;
    },
    initialize() {
      this.getInformacion();
      this.getArticulosTipo();
      this.getAlmacenes();
      this.getSubalmacenes();
      this.getOTs();
    },
    getAlmacenes(){
      this.loadingAlmacenes = true;
      
      axios
        .get('/Almacenes')
        .then( response => {
          this.almacenes = response.data;
        })
        .catch(console.log)
        .finally( () => {
          this.loadingAlmacenes = false;
        })
    },
    getSubalmacenes(){
      this.loadingSubalmacenes = true;
      
      axios
        .get('/Subalmacenes')
        .then( response => {
          this.subalmacenes = response.data;
        })
        .catch(console.log)
        .finally( () => {
          this.loadingSubalmacenes = false;
        })
    },
    resetArticulo() {
      this.idArticulo = -1;
    },
    resetTipoMovimieto() {
      this.idTipoMovimiento = -1;
    },
    getArticulos() {
      this.loadingArticulos = true;
      axios
        .get("/Articulos/ArticulosNombres")
        .then((response) => {
          this.articulosGet = response.data;
            this.articulos = this.articulosGet.filter(x=> x.idArticuloTipo == this.idArticuloTipo);
          this.loadingArticulos = false;
        })
        .catch((error) => {
          this.loadingArticulos = false;
          console.log(error);
        });
    },
    getArticulosTipo() {
      this.loadArticuloTipo = true;
      axios
        .get("/articulos/ArticuloTipos")
        .then((response) => {
          this.tipoArticulos = response.data;
          this.loadArticuloTipo = false;          
        })
        .catch((error) => {
          this.loadArticuloTipo = false;
          console.log(error);
        });
    },
    getParametros() {
      var parametros = "";
      var anyParametro = false;
      if (this.idTipoMovimiento >= 1) {
        parametros += "TipoMovimiento=" + this.idTipoMovimiento;
        anyParametro = true;
      }
      if (this.idArticulo >= 1) {
        if (anyParametro) parametros += "&";
        parametros += "IdArticulo=" + this.idArticulo;
        anyParametro = true;
      }
      if (this.minimo != null) {
        if (anyParametro) parametros += "&";
        parametros += "Inicio=" + this.minimo;
        anyParametro = true;
      }

      if (this.maximo != null) {
        if (anyParametro) parametros += "&";
        parametros += "Fin=" + this.maximo;
        anyParametro = true;
      }

      if (this.sospechoso != null) {
        if (anyParametro) parametros += "&";
        parametros += "sospechoso=" + this.sospechoso;
        anyParametro = true;
      }

      if( this.idTipoSalida != null ){
        if(anyParametro) parametros += "&";
        parametros += "tipoSalida=" + this.idTipoSalida;
        anyParametro = true;
      }

      if(this.idArticuloTipo != null){
        if(anyParametro) parametros += "&";
        parametros += "idArticuloTipo=" + this.idArticuloTipo;
        anyParametro = true;
      }

      if(this.filtroOrdenTrabajo != null){
        if(anyParametro) parametros += "&";
        parametros += `idTrabajoOrden=${this.filtroOrdenTrabajo}`;
        anyParametro = true;
      }

      if(this.filtroOrdenTrabajoDetalle != null){
        if(anyParametro) parametros += "&";
        parametros += `filtroOrdenTrabajoDetalle=${this.filtroOrdenTrabajoDetalle}`;
        anyParametro = true;
      }

      if(this.idAlmacen != null){
        if(anyParametro) parametros += "&";
        parametros += `idAlmacen=${this.idAlmacen}`;
        anyParametro = true;
      }

      if(this.idSubalmacen != null){
        if(anyParametro) parametros += "&";
        parametros += `idSubalmacen=${this.idSubalmacen}`;
        anyParametro = true;
      }

      if (parametros.length > 0) parametros = "?" + parametros;

      return parametros;
    },
    getInformacion(){
        if(this.tab == 0) this.$refs.movimientos.getData(this.getParametros());
        if(this.tab == 1) this.$refs.detalles.getData(this.getParametros());
    },
    nuevoMovimientoChange(event) {
      if (event == 1) {
        this.initialize();
      }
      this.movimiento = false;
      this.movimientoBasico = false;
    },
    filtrarArticulos(){
      this.idArticulo = -1;
      if(this.idArticuloTipo != null)
        this.getArticulos();
    }
  },
};
</script>