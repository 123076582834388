<template>
  <v-col cols="12" v-if="permisoVista('control','r')">
    <signalr
  	  path="/hub/produccion"
  	  @change="cambios"
  	></signalr>
    <v-dialog
      v-model="dialogLayouts"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title>Layouts</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-slide-group
                v-model="layoutPos"
                class="pa-4"
                mandatory
                show-arrows
                center-active
              >
                <v-slide-item
                  v-slot:default="{ active, toggle }"
                >
                  <v-card
                    :color="active ? 'primary' : 'grey lighten-1'"
                    class="ma-4 text-center"
                    height="100"
                    width="200"
                    @click="toggle"
                  >
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center"
                    >
                      <v-scale-transition>
                        <v-icon
                          :color="active?'white':'black'"
                          size="48"
                          v-text="'mdi-plus'"
                        ></v-icon>
                      </v-scale-transition>
                    </v-row>
                  </v-card>
                </v-slide-item>
                <v-slide-item
                  v-for="(layout,idx) in layoutsFiltrados"
                  :key="'layout-'+idx"
                  v-slot:default="{ active, toggle }"
                >
                  <v-card
                    :color="active ? 'primary' : 'grey lighten-1'"
                    class="ma-4 text-center"
                    height="100"
                    width="200"
                    @click="toggle"
                  >
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center"
                    >
                      <v-scale-transition>
                        <div :class="'text-body-1 '+(active?'white--text':'')">{{layout.nombre}}</div>
                      </v-scale-transition>
                    </v-row>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-col>
          </v-row>
          <v-row v-if="layoutPos == 0">
            <v-col cols="12" md="12" sm="12">
              <div class="headline">Crear Layout</div>
              <v-text-field
                v-model="layoutItem.nombre"
                label="Nombre del Layout"
              ></v-text-field>
            </v-col>
            <v-col v-if="idsTurnos.length > 1" cols="12" md="12" sm="12">
              <v-autocomplete
                v-model="layoutItem.idTurno"
                label="Turno"
                :items="turnosFiltrados"
                item-text="nombre"
                item-value="idTurno"
              ></v-autocomplete>
            </v-col>
            <v-col v-if="errores.length > 0" cols="12" md="12" sm="12">
              <ul>
                <li v-for="(error, idx) in errores" :key="'error-'+idx" class="red--text">{{error}}</li>
              </ul>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="text-center">
              <v-btn color="red" @click="saveLayout">Guardar Layout Actual</v-btn>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="6" md="6" sm="6">
              <v-btn block color="red" @click="dialogUpdateLayout = true; dialogLayouts = false; setLayout();">Actualizar Layout</v-btn>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-btn block color="green" @click="dialogLaunchLayout = true; dialogLayouts = false;">Aplicar Layout</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="resetLayout">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogLaunchLayout" persistent max-width="500px">
      <v-card>
        <v-card-title>
        </v-card-title>
        <v-card-text>
          <div class="text-center">
            <v-icon size="256" color="red">mdi-alert</v-icon>
          </div>
          <div class="red--text title">
            Esta función ejecutara las siguientes acciones:
            <br>
            <ul>
              <li class="subtitle-2">Desintegrar cualquier Equipo que se encuentre Activo.</li>
              <li class="subtitle-2">Cerrar las Sesiones de todos los Operadores en las Estaciones de Trabajo.</li>
              <li class="subtitle-2">Cerrar todas las Operaciones que actualmente se encuentren Ejecutando, reportando un avance de 0.0 (Piezas, Litros, Metros o Cualquier otra Unidad de Medición).</li>
              <li class="subtitle-2">Iniciará Sesión de los Operadores en las Áreas previamente definidas por el Layout a Ejecutar.</li>
              <li class="subtitle-2">Recargara la Página de Operaciones de todas las Estaciones de Trabajo que se encuentren encendidas y conectadas.</li>
            </ul>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="green" text @click="dialogLaunchLayout = false; dialogLayouts = true;">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="launchLayout">Ejecutar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogUpdateLayout" persistent max-width="500px">
      <v-card>
        <v-card-title>
        </v-card-title>
        <v-card-text v-if="idsTurnos.length == 1">
          <div class="text-center">
            <v-icon size="256" color="red">mdi-alert</v-icon>
          </div>
          <div class="red--text title">
            <div class="headline">¿Está seguro que desea actualizar el Layout {{layoutItem.nombre}}?</div>
          </div>
        </v-card-text>
        <v-card-text v-else>
          <v-autocomplete
            v-model="layoutItem.idTurno"
            label="Turno"
            :items="turnosFiltrados"
            item-text="nombre"
            item-value="idTurno"
          >
          </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-btn color="green" text @click="dialogUpdateLayout = false; dialogLayouts = true;">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="updateLayout">Actualizar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="closeAll" persistent max-width="500px">
      <v-card>
        <v-card-title>
        </v-card-title>
        <v-card-text>
          <div class="text-center">
            <v-icon size="256" color="red">mdi-alert</v-icon>
          </div>
          <div class="red--text title">
            Esta función ejecutara las siguientes acciones:
            <br>
            <ul>
              <li class="subtitle-2">Desintegrar cualquier Equipo que se encuentre Activo.</li>
              <li class="subtitle-2">Cerrar las Sesiones de todos los Operadores en las Estaciones de Trabajo.</li>
              <li class="subtitle-2">Cerrar todas las Operaciones que actualmente se encuentren Ejecutando, reportando un avance de 0.0 (Piezas, Litros, Metros o Cualquier otra Unidad de Medición).</li>
              <li class="subtitle-2">Recargara la Página de Operaciones de todas las Estaciones de Trabajo que se encuentren encendidas y conectadas.</li>
            </ul>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="green" text @click="closeAll = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="cerrarTodoRun">Ejecutar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col v-if="idsTurnos.length > 0" cols="12" md="12" sm="12" class="ma-0 pa-0">
        <v-btn 
          color="primary" 
          dark 
          block 
          @click="permisoVista('control','c') && openLayouts()"
          :style="permisoVista('control','c')?'':'cursor: default;'"
          >Layouts</v-btn
        >
      </v-col>
      <v-col cols="12" md="12" sm="12" class="ma-0 pa-0">
        <v-btn 
          color="red" 
          dark 
          block 
          @click="permisoVista('control','u') && (closeAll = true)"
          :style="permisoVista('control','u')?'':'cursor: default;'"
          >
            <v-icon color="yellow">mdi-alert</v-icon> Cerrar Todas las Sesiones y Operaciones
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="(area,idx) in areas" :key="'area-'+idx"  cols="3" md="3" sm="3">
        <v-card 
          class="py-16" 
          @click="permisoVista('control','u') && editArea(area)"
          :style="permisoVista('control','u')?'':'cursor: default;'"
        >
          <v-card-title class="justify-center headline">{{area.area}}</v-card-title>
          <v-card-subtitle v-if="area.operacionesCurso == 1" class="justify-center text-center pb-0">{{area.operacionesCurso}} Operación en Curso</v-card-subtitle>
          <v-card-subtitle v-else class="justify-center text-center pb-0">{{area.operacionesCurso}} Operaciones en Curso</v-card-subtitle>
          <v-card-subtitle class="justify-center text-center py-0">{{area.operadoresLogueados}} {{area.operadoresLogueados==1?'Operador Logueado':'Operadores Logueados'}}</v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <area-control
      v-model="showArea"
      :area="area"
      ref="areaControl"
    ></area-control>
  </v-col>
</template>

<script>
import axios from "axios";
import signalr from '@/components/SignalR';
import areaControl from "./Control/Area"
import { mapState } from "vuex";

export default {
  components:{
    'area-control':areaControl,
    signalr,
  },
  data: () => ({
    areas: [],
    area: {
      area:'',
      idArea:-1,
      operacionesCurso: 0,
    },
    layoutItem:{
      idTurno:-1,
      nombre:'',
    },
    defaultLayoutItem:{
      idTurno:-1,
      nombre:'',
    },
    closeAll:false,
    showArea: false,
    dialogLayouts:false,
    layoutPos:0,
    layouts:[],
    turnos:[],
    dialogLaunchLayout:false,
    dialogUpdateLayout:false,
    errores:[],
  }),
  watch:{
    layoutPos(){
      if(this.layoutPos == 0){
        this.layoutItem = Object.assign({},this.defaultLayoutItem);
      }
    }
  },
  computed:{
    ...mapState(["idsTurnos"]),
    turnosFiltrados(){
      return this.turnos.filter(t => this.idsTurnos.indexOf(t.idTurno) >= 0);
    },
    layoutsFiltrados(){
      return this.layouts.filter(l => this.idsTurnos.indexOf(l.idTurno) >= 0);
    }
  },
  created(){
    this.getAreas();
    this.getTurnos();
  },
  methods:{
    initialize(){},
    selectIdTurno(){
      if(this.idsTurnos.length == 1){
        return this.idsTurnos[0];
      }
      else{
        return this.layoutItem.idTurno;
      }
    },
    cerrarTodoRun(){
      axios
      .put("/ComandoYControl/LogoutMasivo")
      .then(() => {
        this.closeAll = false;
        this.getAreas();
      })
      .catch(error => {
        console.log(error);
      });
    },
    openLayouts(){
      this.dialogLayouts = true;
      this.getLayouts();
    },
    resetLayout(){
      this.dialogLayouts = false;
      this.layoutPos = 0;
      this.layoutItem = Object.assign({},this.defaultLayoutItem);
    },
    saveLayout(){
      this.errores = [];

      if(this.layoutItem.nombre.length < 4){
        this.errores.push("El Nombre del Layout debe de tener al menos 4 caracteres");
        return;
      };

      axios
      .post("/ComandoYControl/CrearLayout",{
        IdTurno: this.selectIdTurno(),
        Nombre: this.layoutItem.nombre,
      })
      .then(() => {
        this.resetLayout();
      })
      .catch(error => {
        if(error.response.status == 409){
          this.errores.push("Existe otro Layout con el mismo Nombre");
        }
        else console.log(error);
      });
    },
    setLayout(){
      this.layoutItem = this.layoutsFiltrados[this.layoutPos-1];
      if(typeof this.layoutItem === 'undefined' || this.layoutItem == null){
        this.layoutItem = Object.assign({},this.defaultLayoutItem);
      }
    },
    updateLayout(){
      axios
      .put("/ComandoYControl/ActualizarLayout",{
        IdLayout:this.layoutItem.idLayout,
        IdTurno:this.selectIdTurno(),
      })
      .then(() => {
        this.dialogUpdateLayout = false;
        this.resetLayout();
      })
      .catch(error => {
        console.log(error);
      })
    },
    launchLayout(){
      var layout = this.layoutsFiltrados[this.layoutPos-1];
      if(typeof layout === 'undefined' || layout == null){
        console.log("Error");
        return;
      }
      axios
      .put("/ComandoYControl/LanzarLayout/"+layout.idLayout)
      .then(() => {
        this.dialogLaunchLayout = false;
        this.getAreas();
        this.resetLayout();
      })
      .catch(error => {
        console.log(error);
      })
    },
    getLayouts(){
      axios
      .get("/ComandoYControl/ListarLayout")
      .then(response => {
        this.layouts = response.data;
      })
      .catch(error => {
        console.log(error);
      });
    },
    getTurnos(){
      axios
      .get('/ComandoYControl/Turnos')
      .then(response => {
        this.turnos = response.data;
      })
      .catch(error => {
        console.log(error);
      })
    },
    getAreas(){
      axios
      .get("/ComandoYControl/Areas")
      .then(response => {
        this.areas = response.data;

        if(this.area.idArea != -1){
          var nArea = this.areas.find(a => a.idArea == this.area.idArea);
          if(nArea.operacionesCurso != this.area.operacionesCurso){
            this.$refs.areaControl.actualizar(nArea);
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
    editArea(area){
      if(!this.mostrarVista('control')){
        return;
      }
      this.area = area;
      this.showArea = true;
    },
    cambios(event){
      var orden = event.toLowerCase().split('-');
      if(orden[0] == 'iniciar' || orden[0] == 'avance' || orden[0] == 'completo' ){
        this.getAreas();
      }
      this.$refs.areaControl.eventos(event);
    },
  }

}
</script>