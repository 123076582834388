<template>
	<v-card
		:color="colorCarta"
	>
		<v-card-text>
			<v-container fluid>
				<v-row>
					<v-col :cols="computedCols" class="pa-0" >
						<p 
							:class="'mb-0 text-body-2 font-weight-medium '+ getColorText"
						>
							{{
								`ODT: ${operacion.claveOT}`
							}}
						</p>
						<p
							:class="'mb-0 text-body-2 '+ getColorText"
						>
							{{
								`[${operacion.articulo.codigo}] - ${operacion.articulo.nombre}`
							}}
						</p>
						<template v-if="operacion.subpaso.length > 0">
							<p
								v-for="(subpaso, idx) in operacion.subpaso"
								:key="idx"
								:class="'mb-0 text-body-2 '+ getColorText"
							>
								{{
									`• [${subpaso.codigo}] - ${subpaso.descripcion}`
								}}
							</p>
						</template>
					</v-col>
					<v-col v-if="operacion.idsDependientes.length > 0" cols="2" class="align-self-center">
						<v-tooltip
							bottom
							color="black"
							class="white--text"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									v-bind="attrs"
									v-on="on"
									@click="setDependencias"
									:color="getColor"
									:disabled="inhabilitar"

								>mdi-graph-outline</v-icon>
							</template>
							<span class="white--text">
								Mostrar {{ this.operacion.idsDependientes.length }} Operaciones Dependientes
							</span>
						</v-tooltip>
					</v-col>
				</v-row>
			</v-container>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	props: {
		operacion: {
			type: Object,
			default: () => ({}),
		},
		dependencias: {
			type: Array,
			default: () => ([]),
		},
	},
data() {
    return {
      seleccionado: false,
    };
  },
	computed:{
		computedCols(){
			return this.operacion.idsDependientes.length > 0 ? 10 : 12;
		},
		getColor(){
			return this.seleccionado ? 'red' : 'blue';
		},
		getColorText(){
			return this.dependencias.includes(this.operacion.idOperacion) ? 'white--text' : 'black--text';
		},
		colorCarta(){
			return this.dependencias.includes(this.operacion.idOperacion) ? '#D50000' : 'white';
		},
		inhabilitar(){
			if(this.dependencias != null && this.dependencias.length > 0){
				return !this.seleccionado;
			}
			else return false;
		}	

	},	
	methods:{
		setDependencias(){
			if(!this.seleccionado) this.$emit('change',this.operacion.idsDependientes);
			else this.$emit('change',[]);
			this.seleccionado = !this.seleccionado;
		},
	}
};
</script>

<style>
</style>