<template>
  <div>
    <v-dialog v-model="dialogError" max-width="500px">
      <v-card>
        <v-card-text class="text-center justify-center">
          <v-icon size="200px" color="red">mdi-alert</v-icon>
          <div class="headline">{{mensajeError}}</div>
          <br>
          <div class="v-btn v-btn--contained blue theme--dark display-2 ma-5 pa-5" @click="dialogError = false">
            Cerrar
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="mostrarIniciarOperacion"
      max-width="500px"
      persistent
    >
      <v-card>
        <v-card-title>
          Iniciar Operación
        </v-card-title>
        <v-card-text>
          <v-row class="ma-0 pa-0">
            <v-col cols="12" md="12" sm="12">
              <v-autocomplete
                v-model="iniciarOperacionItem"
                label="Operador o Equipo"
                :loading="loadingSesiones"
                :items="sesiones"
                item-text="nombre"
                return-object
              >
                <template v-slot:item="data">
                  <template v-if="(typeof data.item) !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.nombre"></v-list-item-title>
                      <v-list-item-subtitle v-if="data.item.integrantes != null && data.item.integrantes.length > 0">
                        <ul>
                          <li v-for="(integrante,idx) in data.item.integrantes" :key="'integrante-'+idx">{{integrante.nombre}}</li>
                        </ul>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" v-if="$utils.isValid(operacion.maquinas) && operacion.maquinas.length > 0">
              <div v-for="(maquina,idx) in operacion.maquinas" :key="'maquinas'+idx">
                <h5>{{maquina.nombre}}</h5>
                <v-radio-group v-model="idsMaquinasIns[idx]">
                  <v-radio
                    v-for="instancia in maquina.maquinaInstancias"
                    :key="instancia.idMaquinaInstancia"
                    :label="instancia.nombre"
                    :value="instancia.idMaquinaInstancia"
                  ></v-radio>
                </v-radio-group>
              </div>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-switch
                label="Especificar Hora de Inicio"
                v-model="horaDiferente"
              >
              </v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="horaDiferente" cols="12" md="12" sm="12">
              <VueCtkDateTimePicker
                v-model="horaInicio"
                label="Fecha de Ingreso"
                format="YYYY-MM-DD HH:mm"
                formatted="YYYY-MM-DD HH:mm"
                noHeader
                buttonNowTranslation="Ahora"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="resetIniciarOperacion">Cancelar</v-btn>
          <v-btn color="red" :loading="loadBottom" text @click="iniciarOperacionRun">Iniciar Operación</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col cols="12" md="12" sm="12" v-if="operaciones.length === 0 && sinDatos">
      <v-card flat>
        <div class="text-center mt-16 pt-16">
        <v-progress-circular
          :size="70"
          :width="7"
          color="green"
          indeterminate
    ></v-progress-circular>
    <h2>Cargando...</h2>
        </div>
      </v-card>
    </v-col>
    <v-col v-else cols="12" md="12" sm="12" v-for="ot in operaciones" :key="'ot-'+ot.idTrabajoOrden">
      <v-alert
        outlined
        color="#26A69A"
      >
        <div v-if="ot.clave == null" class="headline text-center black--text">ODT-{{ot.idTrabajoOrden}}</div>
        <div v-else class="headline text-center black--text">ODT Clave {{ot.clave}}</div>        
        <v-col cols="12" md="12" sm="12" v-for="detalle in ot.detalles" :key="'det-'+detalle.idTrabajoOrdenDetalle">
          <v-alert
            outlined
            :color="(detalle.mensaje == null)?'#26A69A':'red'"
          >
            <div :class="(detalle.mensaje == null)?'title text-center black--text':'title text-center red--text'">{{detalle.cantidad}} - {{detalle.articulo}}</div> 
            <div v-if="!detalle.primeraArea && detalle.porcentaje < 100.0" class="headline text-center">Avance de Áreas Previas: {{detalle.porcentaje.toFixed(2)}}%</div>
            <div v-if="detalle.mensaje != null" class="title text-center red--text">Especial: {{detalle.mensaje}}</div>
            <div v-if="detalle.porcentaje >= 100.0" class="text-center">
              <v-btn v-for="op in detallesFiltrados(detalle.operaciones)" :key="'op-'+op.idOperacion"
                class="no-text-transform ma-2"
                x-large
                outlined
                rounded
                color="teal lighten-1"
                @click="iniciarOperacion(op)"
              >
                <span style="white-space: normal;">
                  {{op.cantidad}}&nbsp;<div class="red--text" style="display: contents;" v-if="op.cantidadExcedente > 0">&nbsp;+&nbsp;{{op.cantidadExcedente}}&nbsp;</div>&nbsp;-&nbsp;{{op.articulo}}
                  <br>
                  <div v-if="op.idSubpaso != null">
                    {{ `[${op.codigoSubpaso}] - ${op.nombreSubpaso}` }}
                  </div>
                  <div class="red--text" v-if="op.operacionesEnProgreso.length > 0">
                    Operadores o Equipos Asignados:&nbsp; {{op.operacionesEnProgreso.length}}
                  </div>
                </span>
                
              </v-btn>
            </div>
            <div v-else class="text-center red--text">
              <br>
              <br>
              <h3>Este Árticulo aún no esta Disponible</h3>
              <br>
              <br>
            </div>
          </v-alert>
        </v-col>
      </v-alert>
    </v-col>
    <v-col cols="12" md="12" sm="12" v-if="operaciones.length === 0 && !sinDatos">
      <v-card flat>
        <div class="h2 text-center">No hay Operaciones Pendientes en Esta Área</div>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props:{
    area:{
      type:Object,
      default:{
        area:'',
        idArea:-1,
        operacionesCurso: 0,
      },
    },
  },
  data: () => ({
    operaciones:[],
    sesiones:[],
    mostrarIniciarOperacion:false,
    operacion:{
      maquinas:[],
    },
    defaultOperacion:{
      maquinas:[],
    },
    idsMaquinasIns:[],
    iniciarOperacionItem: null,
    horaInicio: null,
    horaDiferente:false,
    dialogError: false,
    mensajeError:"",
    sinDatos:true,
    loadBottom:false
  }),
  methods:{
    actualizar(){
      this.getOperaciones();
    },
    detallesFiltrados(operaciones){
      return operaciones.filter(e => {
        return !e.iniciada && (e.cantidad + e.cantidadExcedente) > 0;
      });
    },
    getSesiones(){
      this.loadingSesiones = true;
      this.sesiones = [];
      axios
      .get("/ComandoYControl/SesionesAbiertas/"+this.area.idArea)
      .then(response => {
        this.loadingSesiones = false;
        this.sesiones = response.data;
      }) 
      .catch(error => {
        this.loadingSesiones = false;
        console.log(error);
      })
    },
    getOperaciones(){
      this.operaciones = [];
      axios
      .get("/ComandoYControl/OperacionesDisponibles/"+this.area.idArea)
      .then(response => {
        this.operaciones = response.data;
        if(this.operaciones.length == 0) this.sinDatos = false;
      })
      .catch(error => {
        console.log(error);
      });
    },
    iniciarOperacion(op){
      if(!this.permisoVista('control','c')){
        return;
      }
      this.getSesiones();
      this.operacion = op;
      this.idsMaquinas = [];
      if(this.$utils.isValid(this.operacion.maquinas) & this.operacion.maquinas.length > 0){
        var nPos = this.operacion.maquinas.length;
        for (var i = 0; i < nPos; i++) {
          this.idsMaquinasIns.push(-1);
        }
      }
      this.mostrarIniciarOperacion = true;
    },
    resetIniciarOperacion(){
      this.operacion = {
        maquinas:[]
      };
      this.idsMaquinasIns = [];
      this.iniciarOperacionItem = null;
      this.mostrarIniciarOperacion = false;
      this.horaInicio = null;
      this.horaDiferente = false;
    },
    iniciarOperacionRun(){
      if(this.iniciarOperacionItem == null || (this.iniciarOperacionItem.idOperador == null && this.iniciarOperacionItem.idEquipo == null) ){
        this.mensajeError = "Debe de indicar un operador o un equipo.";
        this.dialogError = true;
        return;
      }
      
      
      var numMaquinas = this.idsMaquinasIns.filter(mi => mi == -1).length;
      
      if(numMaquinas > 0){
        this.mensajeError = "Hay Máquinas que no se ha seleccionado la máquina que se empleara";
        this.dialogError = true;
        return;
      }


      this.loadBottom=true;
      axios
      .post("/ComandoYControl/IniciarOperacion",{
        IdArea: this.area.idArea,
        IdOperacion: this.operacion.idOperacion,
        IdOperador: this.iniciarOperacionItem.idOperador,
        IdEquipo: this.iniciarOperacionItem.idEquipo,
        Inicio: this.horaDiferente ? this.horaInicio: null,
        IdsInstanciasMaquinas: this.idsMaquinasIns
      })
      .then(response => {
        this.loadBottom=false;
        this.resetIniciarOperacion();
        this.actualizar();
      })
      .catch(error => {
        this.resetIniciarOperacion();
        if (error.response.status === 461){
          this.mensajeError = "Esta Operación se encuentra en Curso";
          this.dialogError = true;
        }
        else if(error.response.status === 462){
          this.mensajeError = "Este Operador Tiene Otra Operación en Curso";
          this.dialogError = true;
        }
        else console.log(error);
      })
    }
  },
}
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>