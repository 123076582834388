<template>
	<v-col cols="12">
		<v-dialog :value="value" persistent max-width="100%">
			<v-card>
				<v-card-title>{{ idMMCallBuyTicket == null ? 'Create' : 'Edit' }} a Ticket</v-card-title>
				<v-card-text class="pb-0">
					<v-container fluid>
						<v-row>
							<v-col cols="6" class="pb-0">
								<TicketFileInput
									v-model="createItem.emergencyFormFileIds"
									label="Emergency Form Approved (Optional)"
									:formFiles="createItem.emergencyFormFiles"
									:disabled="loading || dialogType == 2"
									multiple
									accept=".pdf"
									@update:formFiles="onUpdateEmergencyFiles"
									@update:processing="processingEmergencyFormFile = $event"
								/>
								<TicketFileInput
									v-model="createItem.rfqFileIds"
									label="RFQ (Required)"
									:formFiles="createItem.rfqFiles"
									:disabled="loading || dialogType == 2"
									multiple
									accept=".pdf,.doc,.docx,.xls,.xlsx,.png,.jpg"
									:error-messages="createItem.errorRFQFiles"
									@focus="$set(createItem,'errorRFQFiles',null)"
									@update:formFiles="$set(createItem,'rfqFiles',$event)"
									@update:processing="processingRFQFile = $event"
								/>
							</v-col>
							<v-col cols="6" class="pb-0">
								<v-textarea
									v-model="createItem.generalDescription"
									label="General Description (Required)"
									clearable
									auto-grow
									rows="5"
									outlined
									:disabled="loading || !isBuyer"
									:error-messages="createItem.errorDescription"
									@focus="$set(createItem,'errorDescription',null)"
								></v-textarea>
							</v-col>
						</v-row>
						<v-row class="my-8 fill-filters-container">
							<v-col cols="10" class="pb-6">
								<v-row>
									<v-col cols="3">
										<v-text-field
											v-model="fillFilters.supplier"
											label="Suggested Supplier"
											class="centered-input"
											hide-details
											:disabled="loading || !isBuyer"
										></v-text-field>
									</v-col>
									<v-col cols="3">
										<v-text-field
											v-model="fillFilters.vendorNumber"
											label="Vendor Number"
											:disabled="loading || !isBuyer"
											class="centered-input"
											hide-details
											clearable
										></v-text-field>
									</v-col>
									<v-col cols="3">
										<datePicker
											v-model="fillFilters.srvDate"
											format="YYYY-MM-DD"
											label="Material/SRV Need Date"
											clearable
											:class="{ 'disabled-date-picker': loading || !isAdmin }"
											:minDate="minDay"
											:disabled="loading || !isAdmin"
										></datePicker>
									</v-col>
									<v-col cols="3">
										<v-autocomplete
											v-model="fillFilters.idCompraArea"
											single-line
											label="Area"
											:items="purchaseAreas"
											item-text="nombre"
											item-value="idCompraArea"
											clearable
											:disabled="loading || !isBuyer"
											hide-details
											@change="onChangePurchaseArea(fillFilters)"
										></v-autocomplete>
									</v-col>
									<v-col cols="3">
										<v-text-field
											v-model="fillFilters.toolCrib"
											label="Tool Crib Code"
											single-line
											:disabled="loading || !isBuyer || fillFilters.idCompraArea != defaultIdToolCribArea"
											hide-details
											class="centered-input"
										></v-text-field>
									</v-col>
									<v-col cols="3">
										<v-autocomplete
											v-model="fillFilters.idProject"
											label="Project"
											:items="projects"
											item-text="nombre"
											item-value="idProject"
											clearable
											hide-details
											:disabled="loading || !isBuyer"
										></v-autocomplete>
									</v-col>
									<v-col cols="3">
										<v-autocomplete
											v-model="fillFilters.idPurchaseClass"
											label="Purchase Class"
											:items="purchaseClasses"
											item-text="nombre"
											item-value="idPurchaseClass"
											clearable
											hide-details
											:disabled="loading || !isBuyer"
											@change="onChangeFilterPurchaseClass"
										></v-autocomplete>
									</v-col>
									<v-col cols="3">
										<v-text-field
											v-model="fillFilters.farNumber"
											label="FAR Number"
											v-mask="farNumberMask"
											hide-details
											:disabled="loading || fillFilters.idPurchaseClass != 2 || !isBuyer"
										></v-text-field>
									</v-col>
									<v-col cols="3">
										<v-text-field
											v-model="fillFilters.ioNumber"
											label="IO Number"
											v-mask="ioNumberMask"
											hide-details
											:disabled="loading || fillFilters.idPurchaseClass != 2 || !isBuyer"
										></v-text-field>
									</v-col>
									<v-col cols="3">
										<v-autocomplete
											v-model="fillFilters.idCostCenter"
											label="Cost Center"
											:items="costCenters"
											:item-text="customCodeName"
											item-value="idCostCenter"
											clearable
											hide-details
											:disabled="loading || !isBuyer"
										></v-autocomplete>
									</v-col>
									<v-col cols="3">
										<v-autocomplete
											v-model="fillFilters.idGlAccount"
											label="GL Account"
											:items="glAccounts"
											:item-text="customCodeName"
											item-value="idGLAccount"
											clearable
											hide-details
											:disabled="loading || !isBuyer"
										></v-autocomplete>
									</v-col>
								</v-row>
							</v-col>
							<v-col
								cols="2"
								class="d-flex align-center justify-center"
							>
								<v-btn
									color="primary"
									:disabled="buttonDisabled || loading"
									style="min-width: 80%"
									@click="assignToItemsSelected()"
									>Assign To<br />Selected Items</v-btn
								>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-data-table
									v-model="createItem.selected"
									:items="createItem.items"
									:headers="computedHeaders"
									:items-per-page="-1"
									hide-default-footer
									class="custom-width-header"
									item-key="indexItem"
									show-select
									:loading="loading"
								>
									<template v-slot:[`item.indexItem`]="{ index }">
										{{ index + 1 }}
									</template>
									<template
										v-slot:[`item.description`]="{ item }"
									>
										<v-text-field
											v-model="item.description"
											label="Description (Required)"
											single-line
											:disabled="loading || dialogType == 2"
											class="centered-input"
											:error-messages="item.errorDescription"
											@focus="
												$set(item, 'errorDescription', null)
											"
										></v-text-field>
									</template>
									<template v-slot:[`item.quantity`]="{ item }">
										<v-text-field
											v-model="item.quantity"
											label="Qty (Required)"
											type="number"
											hide-spin-buttons
											single-line
											:disabled="loading || dialogType == 2"
											class="centered-input"
											:error-messages="item.errorQuantity"
											@focus="
												$set(item,'errorQuantity', null)
											"
										></v-text-field>
									</template>
									<template v-slot:[`item.idUnity`]="{ item }">
										<v-autocomplete
											v-model="item.idUnity"
											label="UM (Required)"
											:items="unities"
											item-text="abreviatura"
											item-value="idUnidad"
											single-line
											:disabled="loading || dialogType == 2"
											:error-messages="item.errorUnity"
											@focus="$set(item,'errorUnity',null)"
										></v-autocomplete>
									</template>
									<template v-slot:[`item.supplier`]="{ item }">
										<v-text-field
											v-model="item.supplier"
											label="Suggested Supplier (Required)"
											single-line
											:disabled="loading || !isBuyer"
											class="centered-input"
											:error-messages="item.errorSupplier"
											@focus="
												$set(item,'errorSupplier', null)
											"
										></v-text-field>
									</template>
									<template v-slot:[`item.vendorNumber`]="{ item }">
										<v-text-field
											v-model="item.vendorNumber"
											label="Vendor Number"
											single-line
											:disabled="loading || !isBuyer"
											class="centered-input"
											clearable
										></v-text-field>
									</template>
									<template v-slot:[`item.srvDate`]="{ item }">
										<datePicker
											v-model="item.srvDate"
											format="YYYY-MM-DD"
											label="Material/SRV Need Date (Required)"
											clearable
											:class="{ 'disabled-date-picker': loading || !isAdmin }"
											:disabled="loading || !isAdmin"
											:minDate="minDay"
											:error-messages="item.errorSRVDate"
											@focus="$set(item,'errorSRVDate',null)"
										></datePicker>
										<v-slide-y-transition>
											<p
												v-if="!!item.errorSRVDate"
												class="mt-1 mb-0 text-left error--text"
												style="font-size: 12px !important"
											>
												{{ item.errorSRVDate }}
											</p>
										</v-slide-y-transition>
									</template>
									<template v-slot:[`item.idCompraArea`]="{ item }">
										<v-autocomplete
											v-model="item.idCompraArea"
											single-line
											label="Area (Required)"
											:items="purchaseAreas"
											item-text="nombre"
											item-value="idCompraArea"
											:disabled="loading || !isBuyer"
											:error-messages="item.errorPurchaseArea"
											@change="onChangePurchaseArea(item)"
											@focus="$set(item,'errorPurchaseArea',null)"
										></v-autocomplete>
									</template>
									<template v-slot:[`item.toolCrib`]="{ item }">
										<v-text-field
											v-model="item.toolCrib"
											label="Tool Crib Code"
											single-line
											:disabled="loading || !isBuyer || item.idCompraArea != defaultIdToolCribArea"
											class="centered-input"
											:error-messages="item.errorToolCribCode"
											@focus="
												$set(item,'errorToolCribCode', null)
											"
										></v-text-field>
									</template>
									<template v-slot:[`item.idProject`]="{ item }">
										<v-autocomplete
											v-model="item.idProject"
											single-line
											:items="projects"
											item-text="nombre"
											item-value="idProject"
											label="Project"
											clearable
											:disabled="loading || !isBuyer"
										></v-autocomplete>
									</template>
									<template v-slot:[`item.budgetQuote`]="{ item }">
										<v-checkbox
											v-model="item.budgetQuote"
											color="primary"
											class="mt-0"
											hide-details
											:disabled="loading || !isBuyer"
											@change="onChangeBudgetQuote(item)"
										></v-checkbox>
									</template>
									<template v-slot:[`item.idPurchaseClass`]="{ item }">
										<v-autocomplete
											v-model="item.idPurchaseClass"
											label="Purchase Class (Required)"
											single-line
											:items="purchaseClasses"
											item-text="nombre"
											item-value="idPurchaseClass"
											:disabled="loading || !isBuyer"
											:error-messages="item.errorPurchaseClass"
											@focus="$set(item,'errorPurchaseClass',null)"
											@change="onChangePurchaseClass(item)"
										></v-autocomplete>
									</template>
									<template v-slot:[`item.farNumber`]="{ item }">
										<v-text-field
											v-model="item.farNumber"
											label="FAR Number"
											v-mask="farNumberMask"
											single-line
											clearable
											:disabled="loading || item.idPurchaseClass != 2 || !isBuyer || item.budgetQuote"
											class="centered-input"
											:error-messages="item.errorFarNumber"
											@focus="$set(item,'errorFarNumber',null)"
										></v-text-field>
									</template>
									<template v-slot:[`item.ioNumber`]="{ item }">
										<v-text-field
											v-model="item.ioNumber"
											label="IO Number"
											v-mask="ioNumberMask"
											single-line
											clearable
											:disabled="loading || item.idPurchaseClass != 2 || !isBuyer || item.budgetQuote"
											class="centered-input"
											:error-messages="item.errorIONumber"
											@focus="$set(item,'errorIONumber',null)"
										></v-text-field>
									</template>
									<template v-slot:[`item.idCostCenter`]="{ item }">
										<v-autocomplete
											v-model="item.idCostCenter"
											label="Cost Center (Required)"
											single-line
											:items="costCenters"
											:item-text="customCodeName"
											item-value="idCostCenter"
											:disabled="loading || !isBuyer || item.budgetQuote"
											:error-messages="item.errorCostCenter"
											@focus="$set(item,'errorCostCenter',null)"
											@change="onChangeCostCenter(item)"
										></v-autocomplete>
									</template>
									<template v-slot:[`item.idGlAccount`]="{ item }">
										<v-autocomplete
											v-model="item.idGlAccount"
											label="GL Account (Required)"
											single-line
											:items="glAccounts"
											:item-text="customCodeName"
											item-value="idGLAccount"
											:disabled="loading || !isBuyer || item.budgetQuote"
											:error-messages="item.errorGLAccount"
											@focus="$set(item,'errorGLAccount',null)"
										></v-autocomplete>
									</template>
									<template v-slot:[`item.breakdownCosts`]="{ item }">
										<template v-for="breakdownItem,index in item.breakdownCosts">
											<div class="d-flex align-center justify-center" :key="`breakdownItem-${index}`">
												<v-autocomplete
													v-model="breakdownItem.idCostCenter"
													label="Cost Center"
													:items="availableCostCenters(item.breakdownCosts,breakdownItem.idCostCenter)"
													:item-text="customCodeName"
													item-value="idCostCenter"
													class="mr-1"
													single-line
													:disabled="disabledBreakdownCosts(item)"
													:error-messages="breakdownItem.errorCostCenter"
													@focus="$set(breakdownItem,'errorCostCenter',null)"
												></v-autocomplete>
												<v-text-field
													v-model="breakdownItem.percentage"
													single-line
													class="centered-input"
													suffix="%"
													type="number"
													hide-spin-buttons
													:disabled="disabledBreakdownCosts(item)"
													:error-messages="breakdownItem.errorPercentage"
													@focus="onFocusPercentage(breakdownItem,item)"
												></v-text-field>
												<v-icon
													v-if="index > 0"
													small
													class="ml-1"
													:disabled="disabledBreakdownCosts(item)"
													@click="deleteArea(item,index)"
													>mdi-delete</v-icon
												>
											</div>
										</template>
										<p v-if="item.errorPercentageSum" class="mb-0 error--text">{{ item.errorPercentageSum }}</p>
										<v-btn
											color="primary"
											text
											class="my-0"
											:disabled="disabledBreakdownCosts(item) || !areCostCentersAvailable(item.breakdownCosts)"
											@click="addBreakdownItem(item)"
										>
											<v-icon color="primary" left>mdi-plus</v-icon>
											Add Cost Center
										</v-btn>
									</template>
									<template v-slot:[`item.createAt`]="{ item }">
										{{ item.createAt || "N/A" }}
									</template>
									<template v-slot:[`item.lastUpdate`]="{ item }">
										{{ item.lastUpdate || "N/A" }}
									</template>
									<template v-slot:[`item.actions`]="{ index }">
										<v-tooltip
											bottom
											color="black"
											class="white--text"
										>
											<template v-slot:activator="{ on }">
												<v-icon
													v-on="on"
													small
													:disabled="loading"
													@click="deleteItemTicket(index)"
													>mdi-delete</v-icon
												>
											</template>
											<span class="white--text">Delete</span>
										</v-tooltip>
									</template>
									<template v-slot:no-data>
										No data available
									</template>
								</v-data-table>
								<v-col cols="12" class="pa-0 d-flex align-center justify-space-between">
									<v-btn text color="primary" :disabled="loading || dialogType == 2" @click="addItem()">
										<v-icon color="primary" left>mdi-plus</v-icon>
										Add Item
									</v-btn>
									<v-slide-y-transition>
										<p
											v-if="!!createItem.errorTable"
											class="mb-0 error--text"
										>
											{{ createItem.errorTable }}
										</p>
									</v-slide-y-transition>
								</v-col>
							</v-col>
						</v-row>
						<v-row v-if="idMMCallBuyTicket != null">
							<v-col cols="12">
								<v-textarea
									v-model="createItem.comment"
									label="Comment (Required)"
									clearable
									outlined
									auto-grow
									:disabled="loading || processingFormFiles"
									rows="3"
									:error-messages="createItem.errorComment"
									@focus="$set(createItem,'errorComment',null)"
								></v-textarea>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="loading || processingFormFiles"
						@click="closeDialog()"
						>Cancel</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="loading || processingFormFiles"
						:loading="loading || processingFormFiles"
						@click="save()"
						>{{ idMMCallBuyTicket == null ? 'Create' : 'Update' }} Ticket
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="codeDialog" max-width="500px" persistent key="codeDialog"> 
			<v-card>
				<v-card-text class="text-center">
					<v-icon x-large style="font-size:150px;" class="mt-2" color="green">mdi-checkbox-marked-circle</v-icon>
					<h3>Ticket Guardado Correctamente</h3>
					<template>
						<div>
							Código
							<h3> {{this.code}} </h3>
						</div>
					</template>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="red darken-1" text @click="close()">Aceptar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-col>	
</template>

<script>
import axios from 'axios';
import TicketFileInput from './TicketFileInput.vue';
import moment from 'moment';

export default {
	components: { TicketFileInput },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		projects: {
			type: Array,
			default: () => []
		},
		purchaseClasses: {
			type: Array,
			default: () => []
		},
		idMMCallBuyTicket: {
			type: Number,
			default: null
		},
		dialogType: {
			type: Number,
			default: 1
		},
		minimumDays: {
			type: Number,
			default: 1
		}
	},
	data() {
		return {
			defaultIdToolCribArea: 1,
			defaultGLCode: "9269400",
			farNumberMask: '####-####-#####',
			ioNumberMask: '#######',
			processingRFQFile: false,
			processingEmergencyFormFile: false,
			glAccounts: [],
			loadingGLAccounts: false,
			costCenters: [],
			loadingCostCenters: false,
			loading: false,
			purchaseAreas: [],
			codeDialog: false, //Para el diálogo al crear el ticket
			code: null,
			createItemHeaders: [
				{
					text: "# Item",
					align: "center",
                    sortable: false,
					value: "indexItem",
					width: "5%"
				},
				{
					text: "Description Item",
					align: "center",
                    sortable: false,
					value: "description",
				},
				{
					text: "Qty",
					align: "center",
                    sortable: false,
					value: "quantity",
					width: "150px"
				},
				{
					text: "UM",
					align: "center",
                    sortable: false,
					value: "idUnity",
					width: "150px"
				},
				{
					text: "Suggested Supplier",
					align: "center",
                    sortable: false,
					value: "supplier",
				},
				{
					text: "Vendor Number",
					align: "center",
                    sortable: false,
					value: "vendorNumber",
				},
				{
					text: "Material/SRV Need Date",
					align: "center",
                    sortable: false,
					value: "srvDate",
				},
				{
					text: "Area",
					align: "center",
                    sortable: false,
					value: "idCompraArea",
				},
				{
					text: "Tool Crib Code",
					align: "center",
                    sortable: false,
					value: "toolCrib",
				},
				{
					text: "Project (Optional)",
					align: "center",
                    sortable: false,
					value: "idProject",
				},
				{
					text: "Budget Quote",
					align: "center",
                    sortable: false,
					value: "budgetQuote",
					width: "100px"
				},
				{
					text: "Purchase Class",
					align: "center",
                    sortable: false,
					value: "idPurchaseClass",
				},
				{
					text: "FAR Number",
					align: "center",
                    sortable: false,
					value: "farNumber",
				},
				{
					text: "IO Number",
					align: "center",
                    sortable: false,
					value: "ioNumber",
				},
				{
					text: "Cost Center",
					align: "center",
                    sortable: false,
					value: "idCostCenter",
				},
				{
					text: "GL Account",
					align: "center",
                    sortable: false,
					value: "idGlAccount",
				},
				{
					text: "Breakdown Costs",
					align: "center",
                    sortable: false,
					value: "breakdownCosts",
				},
				{
					text: "Actions",
					align: "center",
                    sortable: false,
					value: "actions",
					width: "5%"
				},
			],
			fillFilters: {
				supplier: null,
				vendorNumber: null,
				srvDate: null,
				idCompraArea: null,
				toolCrib: null,
				idProject: null,
				idPurchaseClass: null,
				farNumber: null,
				ioNumber: null,
				idCostCenter: null,
				idGlAccount: null,
			},
			createItem: {
				//code: "",
				idMMCallBuyTicket: null,
				emergencyFormFileIds: [],
				emergencyFormFiles: [],
				rfqFileIds: [],
				rfqFiles: [],
				generalDescription: null,
				items: [],
                selected: [],
				comment: null,
				errorComment: null,
				errorRFQFiles: null,
				errorDescription: null,
				errorTable: null,
			},
			defaultCreateItem: {
				idMMCallBuyTicket: null,
				emergencyFormFileIds: [],
				emergencyFormFiles: [],
				rfqFileIds: [],
				rfqFiles: [],
				generalDescription: null,
				items: [],
                selected: [],
				comment: null,
				errorComment: null,
				errorRFQFiles: null,
				errorDescription: null,
				errorTable: null
			},
			ticketItem: {
				indexItem: null,
				description: null,
				quantity: null,
				idUnity: null,
				supplier: null,
				vendorNumber: null,
				srvDate: null,
				idCompraArea: null,
				toolCrib: null,
				idProject: null,
				budgetQuote: false,
				idPurchaseClass: null,
				farNumber: null,
				ioNumber: null,
				idCostCenter: null,
				idGlAccount: null,
				breakdownCosts: [],
				errorDescription: null,
                errorQuantity: null,
                errorUnity: null,
                errorSupplier: null,
				errorSRVDate: null,
				errorPurchaseArea: null,
				errorToolCribCode: null,
                errorPurchaseClass: null,
                errorFarNumber: null,
                errorIONumber: null,
                errorCostCenter: null,
                errorGLAccount: null
			},
			breakdownItem: {
				idCostCenter: null,
				percentage: null,
                errorCostCenter: null,
                errorPercentage: null,
			},
		};
	},
	computed: {
		isAdmin(){
			if(this.dialogType == 1) return true;
			return this.permisoVista('purchasingprocesses-admin','u');
		},
		isBuyer(){
			if(this.dialogType == 1) return true;
			return this.permisoVista('purchasingprocesses-buyer','u');
		},
		computedHeaders(){
			if(this.idMMCallBuyTicket != null){
				const headers = structuredClone(this.createItemHeaders);
				headers.splice(
					this.createItemHeaders.length - 1,
					this.dialogType == 1 ? 0 : 1,
					{
						text: "Created At",
						align: "center",
						value: "createAt",
						width: "150px"
					},
					{
						text: "Last Update",
						align: "center",
						value: "lastUpdate",
						width: "150px"
					}
				);
				return headers;
			}
			return this.createItemHeaders;
		},
		minDay(){
			let daysToAdd = this.minimumDays;
			if(this.createItem.emergencyFormFileIds.length > 0) daysToAdd = 0;
			return moment().add(daysToAdd,'day').format("YYYY-MM-DD");
		},
		processingFormFiles(){
			return this.processingEmergencyFormFile || this.processingRFQFile;
		},
		buttonDisabled() {
			return Object.values(this.fillFilters).every(
				(value) => value == null || value == ""
			) || this.createItem.selected.length == 0;
		},
	},
	watch: {
		value() {
			if (this.value) {
				this.initialize();
			}
		},
	},
	methods: {
		customCodeName: ({ codigo, nombre }) => 
			codigo ? `[${codigo}] - ${nombre}`.trim() : nombre.trim(),
		onUpdateEmergencyFiles(event){
			this.$set(this.createItem,'emergencyFormFiles',event);
			if(this.createItem.emergencyFormFileIds.length == 0){
				this.fillFilters.srvDate = null;
				this.createItem.items.forEach( (item) => {
					this.$set(item,'srvDate',null);
					this.$set(item,'errorSRVDate',null);
				});
			}
		},
		disabledBreakdownCosts(item){
			return (
				this.loading ||
				// breakdownItem.idMMCallBuyTicketItemCostCenter != null ||
				!this.isBuyer ||
				item.budgetQuote
			);
		},
		onChangePurchaseArea(item){
			this.$set(item,'toolCrib',null);
			this.$set(item,'errorToolCribCode',null);
		},
		onChangeBudgetQuote(item){
			if(!item.budgetQuote) return;
			this.$set(item,'farNumber',null);
			this.$set(item,'ioNumber',null);
			this.$set(item,'idCostCenter',null);
			this.$set(item,'idGlAccount',null);
			this.$set(item,'breakdownCosts',[]);
			this.addBreakdownItem(item);
		},
		onChangeCostCenter(item){
			this.prefillBreakDownCosts(item);
		},
		prefillBreakDownCosts(item){
			this.$set(item,'breakdownCosts',[]);
			this.addBreakdownItem(item);
			item.breakdownCosts[0].idCostCenter = item.idCostCenter;
			item.breakdownCosts[0].percentage = item.idCostCenter != null ? 100 : null;
		},
		onChangeFilterPurchaseClass(){
			if(this.fillFilters.idPurchaseClass != 2){
				this.fillFilters.farNumber = null;
				this.fillFilters.ioNumber = null;
			}
			// if(this.fillFilters.idPurchaseClass == 2){
			// 	this.fillFilters.idCostCenter = null;
			// 	const glAccount = this.glAccounts.find( ({ codigo }) => codigo == this.defaultGLCode );
			// 	this.fillFilters.idGlAccount = glAccount?.idGLAccount || null;
			// }
		},
		onChangePurchaseClass(item){
			if(item.idPurchaseClass != 2) {
				this.$set(item,'farNumber',null);
				this.$set(item,'errorFarNumber',null);
				this.$set(item,'ioNumber',null);
				this.$set(item,'errorIONumber',null);
			}
			// if(item.idPurchaseClass == 2){
			// 	this.$set(item,'idCostCenter',null);
			// 	this.$set(item,'errorCostCenter',null);
			// 	this.$set(item,'breakdownCosts',[]);
			// 	this.addBreakdownItem(item);
			// 	const glAccount = this.glAccounts.find( ({ codigo }) => codigo == this.defaultGLCode );
			// 	this.$set(item,'idGlAccount',glAccount?.idGLAccount || null);
			// }
		},
		onFocusPercentage(breakdownItem,item){
			this.$set(breakdownItem,'errorPercentage',null);
			this.$set(item,'errorPercentageSum',null);
		},
		areCostCentersAvailable(breakdownCosts){
			const available = this.availableCostCenters(breakdownCosts,null);
			return available.length > 0;
		},
		availableCostCenters(breakdownCosts = [],idCostCenter){
			const centerCostSelected = breakdownCosts.reduce( (acc,breakdownItem) => {
				if(breakdownItem.idCostCenter != null) acc.push(breakdownItem.idCostCenter);
				return acc;
			},[]);
			
			const costCentersFiltered = this.costCenters.filter( (costCenter) => !centerCostSelected.includes(costCenter.idCostCenter) || costCenter.idCostCenter == idCostCenter );
			return costCentersFiltered;
		},
		async getCostCenters() {
			this.loadingCostCenters = true;

			try {
				const response = await axios.get("/CostCenter/GetCostCenter");
				this.costCenters = response.data;
			} catch (error) {
				this.costCenters = [];
				console.log(error);
			} finally {
				this.loadingCostCenters = false;
			}
		},
		async getGLAccounts() {
			this.loadingGLAccounts = true;

			try {
				const response = await axios.get("/GLAccount/GetGLAccount");
				this.glAccounts = response.data;
			} catch (error) {
				this.glAccounts = [];
				console.log(error);
			} finally {
				this.loadingGLAccounts = false;
			}
		},
		clearObjectErrors(obj){
			Object.keys(obj).forEach((key) => {
				if (key.includes("error")) {
					obj[key] = null;
				}
			});
		},
		resetErrors(){
			this.clearObjectErrors(this.createItem);

			this.createItem.items.forEach( (ticketItem) => {
				this.clearObjectErrors(ticketItem);

				ticketItem.breakdownCosts.forEach( (breakdownItem) => {
					this.clearObjectErrors(breakdownItem);
				})
			});
		},
		validate(){
			this.resetErrors();
			let rowErrors = false;
			let percentageErrors = false;

			if(this.createItem.rfqFileIds.length == 0){
				this.$set(this.createItem,'errorRFQFiles','At least one file required');
			}

			if(!this.createItem.generalDescription){
				this.$set(this.createItem,'errorDescription','Required');
			}
			
			if(this.idMMCallBuyTicket != null){
				if(!this.createItem.comment){
					this.$set(this.createItem,'errorComment','Required');
				}
			}

			if(this.createItem.items.length == 0){
				this.$set(this.createItem,'errorTable','Add 1 item at least');
			}
			else {
				this.createItem.items.forEach( (ticketItem) => {
					if(!ticketItem.description){
						this.$set(ticketItem,'errorDescription','Required');
						rowErrors = true;
					}
					
					if(isNaN(parseFloat(ticketItem.quantity))){
						this.$set(ticketItem,'errorQuantity','Required');
						rowErrors = true;
					}
					else if(parseFloat(ticketItem.quantity) <= 0){
						this.$set(ticketItem,'errorQuantity','Invalid');
						rowErrors = true;
					}
					else {
						this.$set(ticketItem,'quantity',parseFloat(ticketItem.quantity));
					}

					if(ticketItem.idUnity == null){
						this.$set(ticketItem,'errorUnity','Required');
						rowErrors = true;
					}

					if(!ticketItem.supplier){
						this.$set(ticketItem,'errorSupplier','Required');
						rowErrors = true;
					}

					if(ticketItem.srvDate == null){
						this.$set(ticketItem,'errorSRVDate','Required');
						rowErrors = true;
					}

					if(ticketItem.idCompraArea == null){
						this.$set(ticketItem,'errorPurchaseArea','Required');
						rowErrors = true;
					}
					else if(ticketItem.idCompraArea == this.defaultIdToolCribArea && !ticketItem.toolCrib){
						this.$set(ticketItem,'errorToolCribCode','Required');
						rowErrors = true;
					}

					if(ticketItem.idPurchaseClass == null){
						this.$set(ticketItem,'errorPurchaseClass','Required');
						rowErrors = true;
					}

					if (!ticketItem.budgetQuote && ticketItem.idPurchaseClass == 2) {
						if(!ticketItem.farNumber){
							this.$set(ticketItem,'errorFarNumber','Required');
							rowErrors = true;
						}
						else if(ticketItem.farNumber.length != this.farNumberMask.length){
							this.$set(ticketItem,'errorFarNumber','Invalid');
							rowErrors = true;
						}

						if(!ticketItem.ioNumber){
							this.$set(ticketItem,'errorIONumber','Required');
							rowErrors = true;
						}
						else if(ticketItem.ioNumber.length != this.ioNumberMask.length){
							this.$set(ticketItem,'errorIONumber','Invalid');
							rowErrors = true;
						}
					}


					if (!ticketItem.budgetQuote) {
						if(ticketItem.idCostCenter == null){
							this.$set(ticketItem,'errorCostCenter','Required');
							rowErrors = true;
						}
					}

					if(!ticketItem.budgetQuote && ticketItem.idGlAccount == null){
						this.$set(ticketItem,'errorGLAccount','Required');
						rowErrors = true;
					}

					if (!ticketItem.budgetQuote) {
						ticketItem.breakdownCosts.forEach( (breakdownItem) => {
							if(breakdownItem.idCostCenter == null){
								this.$set(breakdownItem,'errorCostCenter','Required');
								percentageErrors = true;
							}
	
							if(isNaN(parseFloat(breakdownItem.percentage))){
								this.$set(breakdownItem,'errorPercentage','Required');
								percentageErrors = true;
							}
							else if(parseFloat(breakdownItem.percentage) <= 0){
								this.$set(breakdownItem,'errorPercentage','Invalid');
								percentageErrors = true;
							}
						});
	
						if(!percentageErrors){
							const percentageSum = ticketItem.breakdownCosts.reduce( (acc,breakdownItem) => {
								this.$set(breakdownItem,'percentage',parseFloat(breakdownItem.percentage));
								return acc + breakdownItem.percentage;
							},0);
							
							if(percentageSum != 100){
								this.$set(ticketItem,'errorPercentageSum',`Invalid percentage summation`);
								percentageErrors = true;
							}
						}
					}
				})
			}

			if(rowErrors || percentageErrors){
				this.$set(this.createItem,'errorTable','There are some errors, please correct them');
				setTimeout(() => {
					this.$set(this.createItem,'errorTable',null);
				}, 3000);
			}

			return Object.keys(this.createItem).every( (key) => key.includes("error") ? this.createItem[key] == null : true) &&
				!rowErrors &&
				!percentageErrors;
		},
		save(){
			if(!this.validate()) return;

			this.loading = true;
			//this.codeDialog = false;
			axios
				.post('/BuyTickets/CrearActualizarTicket',this.createItem)
				.then( (response) => {
					this.loading = false;
					this.codeDialog = true;
					this.code = response.data;
					console.log(this.code);
					this.closeDialog();
					this.$emit('refresh');
				})
				.catch( (error) => {
					this.loading = false;
					this.createItem.errorTable = 'An error has occurred, please try again';
					console.log(error);
				})
		},
		assignToItemsSelected(){
			this.createItem.selected.forEach( (ticketItem) => {
				Object.keys(this.fillFilters).forEach( (key) => {
					if(this.fillFilters[key] != null && this.fillFilters[key] != ""){
						if(
							!(ticketItem.budgetQuote && 
							(key == "farNumber" ||
							key == "ioNumber" ||
							key == "idCostCenter" ||
							key == "idGlAccount"))
						){
							ticketItem[key] = this.fillFilters[key];
							if(key == "idPurchaseClass"){
								if(ticketItem[key] != 2) {
									ticketItem.farNumber = null;
									ticketItem.ioNumber = null;
								}
								ticketItem.errorIONumber = null;
								ticketItem.errorFarNumber = null;
								ticketItem.errorCostCenter = null;
							}
							if(key == "idCostCenter"){
								ticketItem.errorCostCenter = null;
								this.prefillBreakDownCosts(ticketItem);
							}
						}
					}
				});
			});
			this.resetFillFilters();
		},
        deleteArea(item,index){
            item.breakdownCosts.splice(index,1);
        },
        addBreakdownItem(item){
            item.breakdownCosts.push(Object.assign({},this.breakdownItem));
        },
        deleteItemTicket(index){
            this.createItem.items.splice(index,1);
        },
		addItem() {
			const ticketItem = structuredClone(this.ticketItem);
			const maxIndexItem = Math.max(
				...this.createItem.items.map((item) => item.indexItem)
			);
			ticketItem.indexItem = maxIndexItem <= 0 ? 1 : maxIndexItem + 1;
			ticketItem.breakdownCosts.push(
				Object.assign({}, this.breakdownItem)
			);
			this.createItem.items.push(ticketItem);
			this.$set(this.createItem,'errorTable',null);
		},
		resetFillFilters() {
			Object.keys(this.fillFilters).forEach((key) => this.fillFilters[key] = null);
		},
		async initialize() {
			this.loading = true;
			await Promise.all([
				this.getUnities(),
				this.getPurchaseAreas(),
				this.getCostCenters(),
				this.getGLAccounts(),
			]);

			if(this.idMMCallBuyTicket == null){
				this.createItem = structuredClone( this.defaultCreateItem );
				this.loading = false;
			}
			else this.getTicketById(this.idMMCallBuyTicket);
		},
		getTicketById(idMMCallBuyTicket){
			this.loading = true;

			axios
				.get(`/BuyTickets/ListarTickets/${idMMCallBuyTicket}`)
				.then( (response) => {
					const defaultCreateItem = structuredClone(this.defaultCreateItem);
					this.createItem = structuredClone(Object.assign(defaultCreateItem,response.data));
					this.createItem.items.forEach( (item) => {
						if(item.breakdownCosts.length == 0) this.addBreakdownItem(item);
					});
					
					if(response.data.emergencyFormFiles.length > 0){
						const { ids, files } = response.data.emergencyFormFiles.reduce( (acc,file) => {
							acc.ids.push(file.emergencyFormFileId);
							const newFile = new File([1],file.emergencyFormFileOriginalName);
							newFile.idDocumento = file.emergencyFormFileId;
							newFile.fichero = file.emergencyFormFileName;
							newFile.idMMCallBuyTicket = this.idMMCallBuyTicket;
							acc.files.push(newFile);
							return acc;
						},{ ids: [], files: [] });
						this.createItem.emergencyFormFileIds = ids;
						this.createItem.emergencyFormFiles = files;
					}

					if(response.data.rfqFiles.length > 0){
						const { ids, files } = response.data.rfqFiles.reduce( (acc,file) => {
							acc.ids.push(file.rfqFileId);
							const newFile = new File([1],file.rfqFileOriginalName);
							newFile.idDocumento = file.rfqFileId;
							newFile.fichero = file.rfqFileName;
							newFile.idMMCallBuyTicket = this.idMMCallBuyTicket;
							acc.files.push(newFile);
							return acc;
						},{ ids: [], files: [] });
						this.createItem.rfqFileIds = ids;
						this.createItem.rfqFiles = files;
					}
				})
				.catch( (error) => {
					this.createItem = structuredClone(this.defaultCreateItem);
					console.log(error);
				})
				.finally( () => {
					this.loading = false;
				});
		},
		async getPurchaseAreas(){
			try {
				const response = await axios.get('/BuyTickets/GetCompraAreas');
				this.purchaseAreas = response.data;
			} catch (error) {
				this.purchaseAreas = [];
				console.log(error);
			}
		},
		async getUnities(){
			try {
				const response = await axios.get('/Unidades');
				this.unities = response.data;
			} catch (error) {
				this.unities = [];
				console.log(error);
			}
		},
		closeDialog() {
			this.$emit("input", false);

			setTimeout(() => {
				this.createItem = structuredClone(this.defaultCreateItem);
				this.resetFillFilters();
			}, 200);
		},
		close() {
			this.createItem = structuredClone(this.defaultCreateItem);
			this.resetFillFilters();
			this.codeDialog = false;
		}
	},
};
</script>
<style scoped>
.disabled-date-picker {
	pointer-events: none;
}

.disabled-date-picker >>> label {
	pointer-events: none;
}

.custom-width-header >>> th {
	min-width: 250px;
}

.centered-input >>> input {
	text-align: center;
}

.fill-filters-container {
	border: 1px solid #bdbdbd;
	border-radius: 8px;
}
</style>