<template>
  <!-- <v-container> -->
    <div>
      <v-row justify-sm="center">
        <v-card 
        :width="anchoMaximo"
        elevation="0"
        fill
        class="mt-4"
        v-if="notificacion"
      >
      <v-alert dense type="error" icon="mdi-web-sync" >
          <v-row align="center">
            <v-col class="grow">
              No se han recibido datos de MMCall desde {{fecha}}. Por favor, revise el equipo encargado de la sincronización.
            </v-col>
          </v-row>
        </v-alert>
      </v-card>
        <v-card 
        :width="anchoMaximo"
        fill
        :class="!notificacion ? 'mt-4' :''"
      >
        <v-tabs show-arrows v-model="tab" :background-color="colorTabs" centered dark icons-and-text>
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#tab-1" v-if="mostrarVista('dashboard') && !mqtt">
            Dashboard
            <v-icon>mdi-chart-box</v-icon>
          </v-tab>

          <v-tab href="#tab-2" v-if="!mqtt">
            Reportes<br/>Dinámicos
            <v-icon>mdi-file-chart</v-icon>
          </v-tab>

          <v-tab href="#tab-3" v-if="!mqtt">
            Analítica<br/>de Datos 
            <v-icon>mdi-chart-multiple</v-icon>
          </v-tab>

          <v-tab href="#tab-4" v-if="mqtt">
            Tickets<br>MMCall
            <v-icon>mdi-ticket-confirmation</v-icon>
          </v-tab>

          <v-tab href="#tab-6" v-if="mqtt">
            Reportes<br>MMCall
            <v-icon>mdi-file-chart-outline</v-icon>
          </v-tab>

          <v-tab href="#tab-5" v-if="mqtt">
            Reporte<br>MQTT
            <v-icon>mdi-chart-multiple</v-icon>
          </v-tab>

          <!--
          <v-tab href="#tab-7" v-if="mqtt">
            Reporte<br>OEE
            <v-icon>mdi-file-chart-outline</v-icon>
          </v-tab>
          -->

          <!-- <v-tab href="#tab-8">
            Reportes de<br/>almacén 
            <v-icon>mdi-warehouse</v-icon>
          </v-tab>

          <v-tab href="#tab-9">
            Reportes<br/>de RH 
            <v-icon>mdi-account-details</v-icon>
          </v-tab>

          <v-tab href="#tab-10">
            Reportes de<br/>producción 
            <v-icon>mdi-chart-histogram</v-icon>
          </v-tab> -->
          
        </v-tabs>

        <v-tabs-items v-model="tab" touchless>
          <!--<v-tab-item v-if="mostrarVista('maquinascomparativa')">-->

          <v-tab-item v-if="!mqtt" :key="1" :value="'tab-1'" >
            <v-card flat>
              <v-card-text class="pt-0">
                <Dashboard :tipo="1" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item v-if="!mqtt" :key="2" :value="'tab-2'" >
            <v-card flat>
              <v-card-text class="pa-0">
                <ReportesDelDia  />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item  v-if="!mqtt" :key="3" :value="'tab-3'" >
            <Dashboard :tipo="2" />
          </v-tab-item>
          
          <v-tab-item v-if="mqtt" :key="4" :value="'tab-4'" >
            <ListadoTicketsMMCall />
          </v-tab-item>
          
          <v-tab-item v-if="mqtt" :key="5" :value="'tab-5'" >
            <ReporteMonterrey />
          </v-tab-item>

          <v-tab-item v-if="mqtt" :key="6" :value="'tab-6'" >
            <ListadoReportesMMCall />
          </v-tab-item>

          <v-tab-item v-if="mqtt" :key="7" :value="'tab-7'" >
            <ReporteMontOEE />
          </v-tab-item>

        </v-tabs-items>
      </v-card>
     
      
      </v-row>
    </div>
  <!-- </v-container> -->
</template>

<script>
import axios from "axios";
import { mapState } from 'vuex';
import Dashboard from './Dashboard.vue';
import ReportesDelDia from './Reportes/ReportesDelDia.vue';
import ReporteMonterrey from "./Reportes/ReporteMonterrey.vue"
import ReporteMontOEE from './Reportes/ReporteMontOEE.vue';
import Matriz3 from './Dashboards/Matriz3.vue';
import ListadoReportesMMCall from './Reportes/ListadoReportesMMCall.vue';
import ListadoTicketsMMCall from './Reportes/ListadoTicketsMMCall.vue';

export default {
  data() {
    return {
      interval: null,
      tab: null,
      fecha: null,
      notificacion: true,
      segundos: 300,
    };
  },
  computed: {
    ...mapState(["anchoMaximo","mqtt","colorTabs"])
  },
  methods:{
    
  },
  components: {
    Dashboard,
    ReportesDelDia,
    ReporteMonterrey: ReporteMonterrey,
    ReporteMontOEE,
    Matriz3,
    ListadoReportesMMCall,
    ListadoTicketsMMCall
  },
  created(){
    this.getAsyncMqtt()
  },
  methods:{
    getAsyncMqtt(){
      axios
      .get("/AnaliticaMonterrey/UltimaSincronizacion")
      .then(response => {
        this.segundos = 300;
        this.fecha = response.data.fecha;
        if(response.data.minutos > 15)
          this.notificacion = true;
        else
          this.notificacion = false;
      })
      .catch(error => {
        console.log(error);
      });
    }
  },
  mounted(){
    this.interval = setInterval(() => {
      if(this.segundos == 0)
        this.getAsyncMqtt();
      else this.segundos -= 1;
    },1000);
  },
  destroyed(){
    clearInterval(this.interval);
  }
};
</script>