<template>
	<v-col cols="12" v-if="permisoVista('mantenimiento-historial','r')">
		<v-dialog v-model="dialogTerminar" persistent max-width="60%">
			<v-card>
				<v-card-title>Terminar Mantenimiento</v-card-title>
				<v-card-text>
					<Comprobaciones ref="checklist" />
					<v-row>
						<v-col cols="12" md="12" sm="12" class="mt-4">
							<v-textarea
                                v-model="recomendacion"
                                outlined
                                label="Recomendaciones"
                                auto-grow
                                rows="1"
                                hide-details
                            ></v-textarea>
						</v-col>
					</v-row>
					<ul class="my-3">
						<li class="red--text" v-for="ex in errores" :key="ex">
							{{ ex }}
						</li>
					</ul>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="green"
						text
						:disabled="loadingFinish"
						small
						@click="cerrarDialogTerminar"
						>Cancelar</v-btn
					>
					<v-btn
						color="red"
						text
						small
						:disabled="loadingFinish"
						:loading="loadingFinish"
						@click="terminarTarea"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-data-table
			:headers="headers"
			:items="mantenimientos"
			:loading="loading"
			loading-text="Cargando... Espere, Por Favor."
			:footer-props="{
				disableItemsPerPage: true,
				disablePagination: this.loading,
			}"
			:disable-sort="true"
		>
			<template v-slot:item.mecanicos="{ item }">
				<ul>
					<li
						v-for="(mecanico, idx) in item.mecanicos"
						:key="'mec-' + idx"
					>
						{{ mecanico }}
					</li>
				</ul>
			</template>
			<template v-slot:item.maquina="{ item }">
				{{ `${item.marca} - ${item.modelo}` }}
			</template>
			<template v-slot:item.inicioCronometro="{ item }">
				<chronometer :time="item.inicioCronometro"></chronometer>
			</template>
			<template v-slot:item.actions="{ item }">
				<v-tooltip
					
					bottom
					color="black"
					class="white--text"
					v-if="permisoVista('mantenimiento-historial','u')"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							@click="terminarMantenimiento(item)"
						>
							stop
						</v-icon>
					</template>
					<span class="white--text">Terminar</span>
				</v-tooltip>
			</template>
		</v-data-table>
	</v-col>
</template>

<script>
import axios from "axios";
import chronometer from "@/components/Chronometer.vue";
import Comprobaciones from '../Comprobaciones.vue';

export default {
	components: {
		chronometer,
		Comprobaciones,
	},
	data() {
		return {
			errores: [],
			recomendacion: "",
			mantenimientos: [],
			headers: [
				{
					text: "Tipo",
					align: "center",
					sort: false,
					value: "tipo",
				},
				{
					text: "Clave",
					align: "center",
					sort: false,
					value: "mantenimientoClave",
				},
				{
					text: "Nombre",
					align: "center",
					sort: false,
					value: "mantenimientoNombre",
				},
				{
					text: "Responsable",
					align: "center",
					sort: false,
					value: "responsable",
				},
				{
					text: "Realizado Por",
					align: "center",
					sort: false,
					value: "mecanicos",
				},
				{
					text: "Máquina",
					align: "center",
					sort: false,
					value: "maquina",
				},
				{
					text: "Instancia",
					align: "center",
					sort: false,
					value: "clave",
				},
				{
					text: "Inicio",
					align: "center",
					sort: false,
					value: "inicio",
				},
				{
					text: "Tiempo Transcurrido",
					align: "center",
					sort: false,
					value: "inicioCronometro",
				},
				{
					text: "Acciones",
					align: "center",
					sort: false,
					value: "actions",
				},
			],
			dialogTerminar: false,
			loadingFinish: false,
		};
	},
	mounted() {
		this.initialize();
	},
	methods: {
		nombreMaquina: (item) =>
			`${item.marca} - ${item.modelo} - ${item.clave}`,
		cerrarDialogTerminar(){
			this.dialogTerminar = false;
			this.errores = [];
			this.initialize();
		},
		validar(comprobaciones){
			this.errores = [];

			if( Array.isArray(comprobaciones) ){
				this.errores = [ ...this.errores,...comprobaciones ];
			}
			
			return this.errores.length == 0;
		},
		terminarTarea(){
			const item = this.$refs.checklist.terminarTarea();
			if ( !this.validar(item) ) return;
			this.loadingFinish = true;
			axios
				.put("/Mantenimientos/TerminarTareaMantenimiento", item)
				.then(() => {
					this.loadingFinish = false;
					this.cerrarDialogTerminar();
				})
				.catch((error) => {
					this.loadingFinish = false;
					console.log(error);
				});

		},
		initialize() {
			this.getMantenimientos();
		},
		getMantenimientos() {
			axios
				.get("/Mantenimientos/ListaTareaMantenimientoEnCurso")
				.then((response) => {
					this.mantenimientos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		terminarMantenimiento(item) {
			this.dialogTerminar = true;
			this.$nextTick( () => {
				this.$refs.checklist.terminarMantenimiento(item.idMantenimientoTarea);
			});
		},
	},
};
</script>