import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"color":_vm.colorCarta}},[_c(VCardText,[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"pa-0",attrs:{"cols":_vm.computedCols}},[_c('p',{class:'mb-0 text-body-2 font-weight-medium '+ _vm.getColorText},[_vm._v(" "+_vm._s(("ODT: " + (_vm.operacion.claveOT)))+" ")]),_c('p',{class:'mb-0 text-body-2 '+ _vm.getColorText},[_vm._v(" "+_vm._s(("[" + (_vm.operacion.articulo.codigo) + "] - " + (_vm.operacion.articulo.nombre)))+" ")]),(_vm.operacion.subpaso.length > 0)?_vm._l((_vm.operacion.subpaso),function(subpaso,idx){return _c('p',{key:idx,class:'mb-0 text-body-2 '+ _vm.getColorText},[_vm._v(" "+_vm._s(("• [" + (subpaso.codigo) + "] - " + (subpaso.descripcion)))+" ")])}):_vm._e()],2),(_vm.operacion.idsDependientes.length > 0)?_c(VCol,{staticClass:"align-self-center",attrs:{"cols":"2"}},[_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":_vm.getColor,"disabled":_vm.inhabilitar},on:{"click":_vm.setDependencias}},'v-icon',attrs,false),on),[_vm._v("mdi-graph-outline")])]}}],null,false,3205081928)},[_c('span',{staticClass:"white--text"},[_vm._v(" Mostrar "+_vm._s(this.operacion.idsDependientes.length)+" Operaciones Dependientes ")])])],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }