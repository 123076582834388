import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VTabs,{attrs:{"background-color":"transparent","grow":"","centered":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTab,{attrs:{"href":"#tab-1"},on:{"click":function($event){return _vm.updateDatosDiarios()}}},[_vm._v(" Tabla de Datos Diarios ")]),_c(VTab,{staticStyle:{"width":"200px"},attrs:{"href":"#tab-2"},on:{"click":function($event){return _vm.updateGraficas()}}},[_vm._v(" Gráficas ")]),_c(VTab,{attrs:{"href":"#tab-3"}},[_vm._v(" Reporte de Líneas Críticas ")]),_c(VTab,{attrs:{"href":"#tab-4"}},[_vm._v(" Líneas del Día en Riesgo ")]),_c(VTab,{attrs:{"href":"#tab-5"},on:{"click":_vm.updateConfiguracionEmails}},[_vm._v(" Emails para Notificar ")]),_c(VTab,{attrs:{"href":"#tab-6"},on:{"click":_vm.updateConfiguracionLineas}},[_vm._v(" Configuración de Líneas ")]),_c(VTabsItems,{attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,{key:1,attrs:{"value":'tab-1'}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('DatosDiariosMMCall',{ref:"datosDiarios"})],1)],1)],1),_c(VTabItem,{key:2,attrs:{"value":'tab-2'}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('ListadoGraficas',{ref:"listGraphs"})],1)],1)],1),_c(VTabItem,{key:3,attrs:{"value":'tab-3'}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('ReporteLineasCriticas',{ref:"lineasCriticas"})],1)],1)],1),_c(VTabItem,{key:4,attrs:{"value":'tab-4'}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('ReporteLineasDiaEnRiesgo',{ref:"lineasDiaEnRiesgo"})],1)],1)],1),_c(VTabItem,{key:5,attrs:{"value":'tab-5'}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('EmailsParaNotificar',{ref:"configemails"})],1)],1)],1),_c(VTabItem,{key:6,attrs:{"value":'tab-6'}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('ConfiguracionLineas',{ref:"configlineas"})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }