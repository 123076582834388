<template>
  <v-container fluid class="pa-0" v-if="permisoVista('optimizacion','r')">
    <div v-if="loading">
      <div
        class="d-flex justify-center align-center flex-column"
        style="min-height: 80vh"
      >
        <v-progress-circular
          :size="90"
          :width="6"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <p class="text-center text-h4">Cargando</p>
      </div>
    </div>

    <div v-if="!loading">
      <div class="d-flex justify-center">
        <div class="d-flex overflow-x-auto">
          <div v-if="areasProduccion.length == 0">
            <v-card-title primary-title class="justify-center">
              <div
                class="text-center d-flex flex-column align-center justify-center"
              >
                <div>
                  <v-icon color="yellow darken-2" style="font-size: 100px;">
                    mdi-alert
                  </v-icon>
                </div>
                <p class="text-center text-h5">
                  No hay datos que mostrar, al parecer aún no se ha realizado ninguna
                  simulación
                </p>
              </div>
            </v-card-title>
          </div>
          <div
            v-else
            v-for="(area, idxArea) in areasProduccion"
            :key="`area-${idxArea}`"
            class="pa-6 mr-3"
            style="background-color: rgba(207, 216, 220, 0.5)"
          >
            <p class="text-center text-h5 font-weight-bold">
              {{ area.nombre }}
            </p>
            <div class="d-flex" style="min-height: 80vh">
              <div
                v-for="(linea, idxLinea) in area.lineas"
                :key="`linea-${idxArea}-${idxLinea}`"
                :class="
                  (idxLinea < area.lineas.length - 1 ? 'mr-3 ' : '') +
                    linea.color +
                    ' pa-3 rounded-lg column-width mr-3 text-center'
                "
              >
                <p
                  class="
										white--text
										text-center text-h6
										font-weight-medium
									"
                  style="font-size: 1.125rem !important"
                >
                  {{ linea.nombre }}
                </p>

                <div class="d-flex justify-space-between align-center">
                  <div style="width: 24px"><!-- no eliminar --></div>
                  <v-tooltip
                    v-if="linea.maquinas != null && linea.maquinas.length > 0"
                    bottom
                    color="black"
                    class="white--text"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        color="white"
                      >mdi-robot-industrial-outline
                      </v-icon>
                    </template>
                    <span class="white--text">
                      Maquinas:
                      <ul>
                        <li v-for="(m, idxM) in linea.maquinas" :key="'m'+idxM">{{m}}</li>
                      </ul>
                    </span>
                  </v-tooltip>
                  <div v-else style="width: 24px"><!-- no eliminar --></div>
                  <div v-if="linea.operaciones.length > 0 && permisoVista('optimizacion','u')">
                    <v-tooltip
                      v-if="!linea.modoEditar"
                      bottom
                      color="black"
                      class="white--text"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          color="white"
                          @click="setModoEditar(linea)"
                        >mdi-pencil
                        </v-icon>
                      </template>
                      <span class="white--text">Editar</span>
                    </v-tooltip>
                    <template v-if="!!linea.modoEditar">
                      <v-tooltip
                        v-if="permisoVista('optimizacion','u')"
                        bottom
                        color="black"
                        class="white--text"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            color="white"
                            :disabled="!!linea.savingOrden"
                            @click="saveNuevoOrden(linea)"
                          >mdi-content-save
                          </v-icon>
                        </template>
                        <span class="white--text">Guardar</span>
                      </v-tooltip>
                      <v-tooltip
                        bottom
                        color="black"
                        class="white--text"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            color="white"
                            :disabled="!!linea.savingOrden"
                            @click="cancelModoEditar(linea)"
                          >mdi-cancel
                          </v-icon>
                        </template>
                        <span class="white--text">Cancelar</span>
                      </v-tooltip>
                    </template>
                  </div>
                  <div v-else style="width: 24px"><!-- no eliminar --></div>
                </div>

                <v-col v-if="!!linea.savingOrden" cols="12" class="pa-0 pt-3">
                  <v-progress-linear
                    indeterminate
                    color="white"
                  ></v-progress-linear>
                </v-col>

                <draggable
                  :list="linea.operaciones"
                  :animation="200"
                  ghost-class="ghost-card"
                  :disabled="!linea.modoEditar || !!linea.savingOrden"
                  @change="onChangeDraggable(linea,linea.operaciones)"
                >
                  <OrdenTrabajoCard
                    v-for="operacion in linea.operaciones"
                    :key="operacion.idOperacion"
                    :operacion="operacion"
                    :dependencias="dependencias"
                    class="mt-3"
                    :style="!linea.modoEditar ? 'cursor: default' : 'cursor: grab'"
                    @change="setDependencias"
                  />
                </draggable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import OrdenTrabajoCard from "./OptimizacionProduccion/OrdenTrabajoCard.vue";

export default {
  components: {
    draggable,
    OrdenTrabajoCard,
  },
  data() {
    return {
      areasProduccion: [],
      loading: false,
      dependencias: [],
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    saveNuevoOrden(linea){
      if(linea.nuevoOrden.length == 0){
        this.cancelModoEditar(linea);
        return;
      }

      this.$set(linea,'savingOrden',true);

      const operacionesOrdenadas = linea.nuevoOrden.slice().reverse().map((operacion,index) => ({
        idOperacion: operacion.idOperacion,
        idConfiguracionArea: operacion.idConfiguracionArea,
        prioridad: index
      }));

      axios
        .put('/OptimizacionProduccion/ModificarPrioridad',operacionesOrdenadas)
        .then( () => {
          this.$set(linea,'savingOrden',false);
          this.$set(linea,'modoEditar',false);
          this.$set(linea,'operaciones',linea.nuevoOrden.slice());
          this.$set(linea,'nuevoOrden',[]);
          this.$set(linea,'viejoOrden',[]);
        })
        .catch( error => {
          this.$set(linea,'savingOrden',false);
          console.log(error);
        })
    },
    cancelModoEditar(linea){
      this.$set(linea,'modoEditar',false);
      this.$set(linea,'operaciones',linea.viejoOrden.slice());
      this.$set(linea,'nuevoOrden',[]);
      this.$set(linea,'viejoOrden',[]);
    },
    setModoEditar(linea){
      this.$set(linea,'viejoOrden',linea.operaciones.slice());
      this.$set(linea,'nuevoOrden',[]);
      this.$set(linea,'modoEditar',true);
    },
    onChangeDraggable(linea,operaciones){
      this.$set(linea,'nuevoOrden',operaciones.slice());
    },
    initialize() {
      this.colorIndex = 0;
      this.loading = true;
      axios
        .get("/OptimizacionProduccion")
        .then((response) => {
          this.areasProduccion = response.data;
        })
        .catch(console.log)
        .finally(() => {
          this.loading = false;
        });
    },
    setDependencias(evt){
      this.dependencias = evt;
    }
  },
};
</script>
<style scoped>
.column-width {
  min-width: 250px;
  width: 250px;
}

.ghost-card {
  opacity: 0.5;
  background: rgb(247, 247, 247);
  border: 1px solid #546e7a;
}
</style>
