<template>
  <v-col cols="12" v-if="permisoVista('reporteextemporaneo', 'r')">
    <v-dialog v-model="dialogError" max-width="800px">
      <v-card>
        <v-card-text>
          <div class="text-center">
            <v-icon size="256" color="red">mdi-alert</v-icon>
          </div>
          <div class="text-h6 red--text text-center">
            Se han detectados los siguientes Errores
          </div>
          <br />
          <div v-for="(error, idx) in errores" :key="'error' + idx">
            <div class="text-subtitle-1">{{ error.mensaje }}</div>
            <ul>
              <li
                v-for="(detalle, idx2) in error.detalles"
                :key="'detalle-' + idx + '-' + idx2"
              >
                {{ detalle }}
              </li>
            </ul>
          </div>
          <v-btn block color="primary" @click="dialogError = false"
            >Aceptar</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEnviado" max-width="800px">
      <v-card>
        <v-card-text>
          <div class="text-center">
            <v-icon size="256" color="green">mdi-check-circle</v-icon>
          </div>
          <div class="text-h6 text-center">
            Reporte Extemporáneo Realizado Correctamente
          </div>
          <br />
          <v-btn block color="primary" @click="dialogEnviado = false"
            >Aceptar</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDesglosar" persistent max-width="95%">
      <v-card>
        <v-card-title>Reportar Incidencias</v-card-title>
        <v-card-text>
          <p class="mb-0 text--secondary">
            Cantidad con Defecto: {{ desglosarItem.cantidadDefecto }}
          </p>
          <p class="mb-0 text--secondary">
            Inicio Operación: {{ desglosarItem.inicio }}
          </p>
          <p class="mb-4 text--secondary">
            Fin Operación: {{ desglosarItem.fin }}
          </p>
          <v-col cols="12">
            <v-data-table
              :items="desglosarItem.desgloses"
              :headers="headersDesglose"
              disable-sort
              hide-default-footer
              :items-per-page="-1"
            >
              <template v-slot:item.posicion="{ item, index }">
                {{ index + 1 }}
              </template>
              <template v-slot:item.bloqueoProducion="{ item }">
                <v-btn-toggle v-model="item.bloqueoProducion" class="my-3">
                  <v-btn
                    depressed
                    small
                    class="ma-0"
                    :color="
                      item.bloqueoProducion == 0 ? 'success' : 'grey lighten-1'
                    "
                    >Paro</v-btn
                  >
                  <v-btn
                    depressed
                    small
                    class="ma-0"
                    :color="
                      item.bloqueoProducion == 1 ? 'error' : 'grey lighten-1'
                    "
                    >Incidencia</v-btn
                  >
                </v-btn-toggle>
              </template>
              <template v-slot:item.idParoTipo="{ item, index }">
                <v-autocomplete
                  v-model="item.idParoTipo"
                  :items="tipoParos"
                  item-value="idParoTipo"
                  item-text="nombre"
                  hide-details
                  clearable
                  dense
                  @change="changeTipoParo(index)"
                ></v-autocomplete>
              </template>
              <template v-slot:item.idSubParoTipo="{ item, index }">
                <v-autocomplete
                  v-model="item.idSubParoTipo"
                  :items="reporteTipoSubparos(index)"
                  item-value="idSubParoTipo"
                  item-text="nombreSubParoTipo"
                  hide-details
                  clearable
                  dense
                ></v-autocomplete>
              </template>
              <template v-slot:item.idMaquinaIns="{ item }">
                <v-autocomplete
                  v-model="item.idMaquinaIns"
                  :items="instanciasFiltradas"
                  :item-text="marcaModelo"
                  item-value="idMaquinaIns"
                  clearable
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>{{
                        data.item.marca + " - " + data.item.modelo
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        data.item.nombre
                      }}</v-list-item-subtitle>
                      <v-list-item-content>{{
                        $utils.strings.truncate(
                          data.item.descripcionGeneral,
                          50
                        )
                      }}</v-list-item-content>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </template>
              
              <template v-slot:item.cantidadReportada="{ item }">
                <number-input
                  v-model="item.cantidadReportada"
                  controls
                  inline
                  size="small"
                  :min="0"
                  :max="maxCantidad(item.cantidadReportada)"
                  center
                ></number-input>
              </template>
              <template v-slot:item.inicio="{ item, index }">
                <VueCtkDateTimePicker
                  v-model="item.inicio"
                  :min-date="desglosarItem.inicio"
                  :max-date="desglosarItem.fin"
                  format="YYYY-MM-DD HH:mm"
                  formatted="YYYY-MM-DD HH:mm"
                  noHeader
                  overlay
                  :key="`ctki-${index}-${ctikey}`"
                  buttonNowTranslation="Ahora"
                  label=""
                  noClearButton
                  @hook:mounted="actualizarFechas(item, index)"
                />
              </template>
              <template v-slot:item.fin="{ item, index }">
                <VueCtkDateTimePicker
                  v-if="item.bloqueoProducion == 0"
                  v-model="item.fin"
                  :min-date="item.inicio"
                  :max-date="desglosarItem.fin"
                  format="YYYY-MM-DD HH:mm"
                  formatted="YYYY-MM-DD HH:mm"
                  noHeader
                  overlay
                  :key="`ctkf-${index}-${ctfkey}`"
                  buttonNowTranslation="Ahora"
                  label=""
                  noClearButton
                />
              </template>
              <template v-slot:item.actions="{ item, index }">
                <v-tooltip
                  :nudge-left="50"
                  bottom
                  color="black"
                  class="white--text"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small @click="eliminarDesglose(index)">
                      mdi-delete
                    </v-icon>
                  </template>
                  <span class="white--text">Eliminar</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="4" class="py-0">
            <v-btn
              class="my-1"
              text
              color="primary"
              @click="agregarNewDesglose()"
              >Agregar Desglose</v-btn
            >
          </v-col>
          <ul class="mt-2">
            <li class="red--text" v-for="ex in erroresDesglose" :key="ex">
              {{ ex }}
            </li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="green"
            :disabled="loadingDesgloses"
            @click="cerrarDialogDesglose()"
            >Cancelar</v-btn
          >
          <v-btn
            text
            color="red"
            :disabled="loadingDesgloses"
            :loading="loadingDesgloses"
            @click="saveDesgloses()"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="3" md="3" sm="3" class="text-center">
        <div class="ma-0 pa-0">
          <v-select
            v-model="idLayout"
            label="Layout"
            :items="layoutsFiltrados()"
            item-value="idLayout"
            item-text="nombre"
            hide-details
            clearable
            @click:clear="idLayout = -1"
          >
            <template v-slot:append-outer>
              <v-btn
                v-if="permisoVista('reporteextemporaneo', 'c')"
                class="pb-5"
                x-small
                plain
                color="primary"
                light
                @click="cargarLayout"
              >
                Cargar
              </v-btn>
              <v-btn
                v-else
                disabled
                class="pb-5"
                icon
                color="primary"
                @click="cargarLayout"
              >
                <v-icon>mdi-upload</v-icon>
              </v-btn>
            </template>
          </v-select>
        </div>
      </v-col>
      <v-col cols="3" md="3" sm="3" class="text-center">
        <v-row class="ma-0 pa-0">
          <v-col cols="8" md="8" sm="8" class="ma-0 pa-0">
            <v-text-field-datetime
              label="Inicio"
              ref="inicioGlobal"
              placeholder="dd/mm/aaaa hh:mm"
            >
            </v-text-field-datetime>
          </v-col>
          <v-col cols="4" md="4" sm="4" class="ma-0 pa-0 align-self-center text-center">
            <v-btn
              x-small
              color="primary"
              light
              @click="aplicarInicio"
            >
            Aplicar
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3" md="3" sm="3" class="text-center">
        <v-row class="ma-0 pa-0">
          <v-col cols="8" md="8" sm="8" class="ma-0 pa-0">
            <v-text-field-datetime
              label="Fin"
              ref="finGlobal"
              placeholder="dd/mm/aaaa hh:mm"
            >
            </v-text-field-datetime>
          </v-col>
          <v-col cols="4" md="4" sm="4" class="ma-0 pa-0 align-self-center text-center">
            <!-- <v-btn color="primary" text @click="aplicarFin">Aplicar</v-btn> -->
            <v-btn
              x-small
              color="primary"
              light
              v-bind="attrs"
              v-on="on"
              @click="aplicarFin"
            >
              Aplicar
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3" md="3" sm="3" class="ma-0 pa-0 pt-1 text-center">
        <div>Ultimas Horas:</div>
        <v-btn-toggle v-model="toggle_exclusive" mandatory>
          <v-btn small @click="setHoras(1)">
            <v-icon>mdi-numeric-1-box-outline</v-icon>
          </v-btn>
          <v-btn small @click="setHoras(2)">
            <v-icon>mdi-numeric-2-box-outline</v-icon>
          </v-btn>
          <!-- <v-btn @click="setHoras(6)">
            <v-icon>mdi-numeric-6-box-outline</v-icon>
          </v-btn> -->
          <v-btn small @click="setHoras(8)">
            <v-icon>mdi-numeric-8-box-outline</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headersFiltrados"
      :items="reportes"
      disable-sort
      hide-default-footer
      :items-per-page="-1"
      :key="'table-' + random"
      :loading="loadingInformacion"
    >
      <template v-slot:item.posicion="{ item }">
        {{ reportes.indexOf(item) + 1 }}
      </template>
      <template v-slot:item.idArea="{ item }">
        <v-autocomplete
          v-model="item.idArea"
          :items="areas"
          item-text="nombre"
          item-value="idArea"
          hide-details
          dense
          @change="
            predecirMaquina($event, item);
            predecirOperacion(item.idTrabajoOrdenDetalle, item);
            resortPersonas(item.idArea);
          "
        >
        </v-autocomplete>
      </template>
      <template v-slot:item.idsOperadores="{ item }">
        <v-autocomplete
          v-model="item.idsOperadores"
          :items="personasReordenadas"
          item-text="nombre"
          item-value="idPersona"
          multiple
          hide-details
          dense
        >
        </v-autocomplete>
      </template>
      <template v-slot:item.idTrabajoOrden="{ item }">
        <v-autocomplete
          v-model="item.idTrabajoOrden"
          :items="trabajoOrdenesFiltrados(item.idArea)"
          item-text="clave"
          item-value="idTrabajoOrden"
          hide-details
          dense
          @change="predecirDetalle($event, item)"
        >
        </v-autocomplete>
      </template>
      <template v-slot:item.idTrabajoOrdenDetalle="{ item }">
        <v-autocomplete
          v-model="item.idTrabajoOrdenDetalle"
          :items="
            trabajoOrdenesDetallesFiltrados(item.idTrabajoOrden, item.idArea)
          "
          item-text="productoProducir"
          item-value="idTrabajoOrdenDetalle"
          hide-details
          dense
          @change="
            getUnidades(item);
            predecirOperacion($event, item);
          "
        >
        </v-autocomplete>
      </template>
      <template v-slot:item.idOperacion="{ item }">
        <v-autocomplete
          v-model="item.idOperacion"
          :items="
            operacionesFiltradas(
              item.idTrabajoOrden,
              item.idTrabajoOrdenDetalle,
              item.idArea
            )
          "
          item-value="idOperacion"
          item-text="articulo"
          hide-details
          dense
          @change="predecirMaquina($event, item)"
        >
          <template v-slot:selection="data">
            <div :class="colorOperacion(data.item)">
              {{ textoOperacion(data.item) }}
            </div>
          </template>
          <template v-slot:item="data">
            <div :class="colorOperacion(data.item)">
              {{ textoOperacion(data.item) }}
            </div>
          </template>
        </v-autocomplete>
      </template>
      <template v-slot:item.idsMaquinasIns="{ item }">
        <v-autocomplete
          v-model="item.idsMaquinasIns"
          :items="maquinasOrdenadas(item)"
          item-value="idMaquinaInstancia"
          item-text="nombre"
          hide-details
          dense
          multiple
          chips
          deletable-chips
        >
        </v-autocomplete>
      </template>
      <template v-slot:item.inicio="{ item }">
        <v-text-field-datetime
          v-model="item.inicio"
          placeholder="dd/mm/aaaa hh:mm"
          dense
          hide-details
          :name="Math.random()"
          :ref="'inicio' + reportes.indexOf(item)"
        ></v-text-field-datetime>
      </template>
      <template v-slot:item.fin="{ item }">
        <v-text-field-datetime
          v-model="item.fin"
          :min-datetime="item.inicio"
          placeholder="dd/mm/aaaa hh:mm"
          dense
          hide-details
          :name="Math.random()"
          :ref="'fin' + reportes.indexOf(item)"
        ></v-text-field-datetime>
      </template>
      <template v-slot:item.cantidad="{ item }">
        <v-text-field-number
          v-model="item.cantidad"
          :label="labelCantidadMaxima(item)"
          :max="cantidadMaxima(item)"
          hide-details
          dense
          decimal
          @input="
            () => {
              if (reporteAuxiliar && item.factorConversion != null)
                item.cantidadAuxiliar = item.cantidad * item.factorConversion;
            }
          "
          :suffix="item.unidadAbreviatura"
        ></v-text-field-number>
      </template>
      <template v-slot:item.cantidadAuxiliar="{ item }">
        <v-text-field-number
          v-model="item.cantidadAuxiliar"
          hide-details
          dense
          decimal
          :disabled="item.factorConversion == null"
          :suffix="item.unidadAuxiliarAbreviatura"
        ></v-text-field-number>
      </template>
      <template v-slot:item.cantidadDefecto="{ item }">
        <v-text-field-number
          v-model="item.cantidadDefecto"
          :label="labelCantidadMaximaDefecto(item)"
          :max="item.cantidad"
          hide-details
          dense
          decimal
          :suffix="item.unidadAbreviatura"
        ></v-text-field-number>
      </template>
      <template v-slot:item.accion="{ item,index }">
        <v-tooltip
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							class="mr-2"
              :class="
                (
                  item.inicio == '' ||
                  item.fin == '' ||
                  item.inicio.length < 16 ||
                  item.fin.length < 16 ||
                  isNaN(item.cantidad) ||
                  isNaN(item.cantidadDefecto)
                )
                ? 'text--disabled'
                : ''
              "
              @click="
                (
                  item.inicio == '' ||
                  item.fin == '' ||
                  item.inicio.length < 16 ||
                  item.fin.length < 16 ||
                  isNaN(item.cantidad) ||
                  isNaN(item.cantidadDefecto)
                )
                ? ''
                : desglosar(item,index)
              "
              :style="
                (
                  item.inicio == '' ||
                  item.fin == '' ||
                  item.inicio.length < 16 ||
                  item.fin.length < 16 ||
                  isNaN(item.cantidad) ||
                  isNaN(item.cantidadDefecto)
                )
                && 'cursor: default'
              "
							>mdi-file-tree</v-icon
						>
					</template>
					<span class="white--text">Agregar Incidencias</span>
				</v-tooltip>
        <v-tooltip :nudge-left="50" bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" small @click="eliminarReporte(item)">
              mdi-delete
            </v-icon>
          </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-btn
      v-if="permisoVista('reporteextemporaneo', 'c')"
      color="red"
      text
      :disabled="savingReporte || loadingInformacion"
      @click="agregarReporte"
      >Agregar Reporte</v-btn
    >
    <br />
    <v-btn
      v-if="permisoVista('reporteextemporaneo', 'c')"
      color="primary"
      block
      @click="save"
      :disabled="savingReporte || loadingInformacion"
      :loading="savingReporte"
      >Reportar</v-btn
    >
  </v-col>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import VTextFieldDatetime from "@/components/VTextFieldDatetime.vue";
import VTextFieldNumber from "@/components/VTextFieldNumber.vue";
import moment from "moment";

export default {
  components: {
    "v-text-field-datetime": VTextFieldDatetime,
    "v-text-field-number": VTextFieldNumber,
  },
  data: () => ({
    personasReordenadas: [],
    loadingInformacion: false,
    loadingDesgloses: false,
    headersDesglose: [
      {
        text: "#",
        align: "center",
        sortable: false,
        value: "posicion",
        width: "5%",
      },
      {
        text: "¿Bloquear Producción?",
        align: "center",
        sortable: false,
        value: "bloqueoProducion",
        width: "10%",
      },
      {
        text: "Motivo Incidencia",
        align: "center",
        sortable: false,
        value: "idParoTipo",
        width: "14%",
      },
      {
        text: "Submotivo Incidencia (Opcional)",
        align: "center",
        sortable: false,
        value: "idSubParoTipo",
        width: "12%",
      },
      {
        text: "Máquina (opcional)",
        align: "center",
        sortable: false,
        value: "idMaquinaIns",
        width: "12%",
      },
      {
        text: "Cantidad Reportada",
        align: "center",
        sortable: false,
        value: "cantidadReportada",
        width: "8%",
      },
      {
        text: "Inicio",
        align: "center",
        sortable: false,
        value: "inicio",
        width: "14%",
      },
      {
        text: "Fin",
        align: "center",
        sortable: false,
        value: "fin",
        width: "14%",
      },
      {
        text: "",
        align: "center",
        sortable: false,
        value: "actions",
        width: "5%",
      },
    ],
    dialogDesglosar: false,
    itemDesglosed: {
      idParoTipo: 0,
      idSubParoTipo: 0,
      idMaquinaIns: null,
      bloqueoProducion: 0,
      cantidadReportada: 0,
      cantidadAuxiliar: undefined,
      inicio: null,
      fin: null,
    },
    desglosarItem: {
      idDefectoNoDesglozado: 0,
      idsMaquinasIns: [],
      desgloses: [],
    },
    defaultDesglosarItem: {
      idDefectoNoDesglozado: 0,
      idsMaquinasIns: [],
      desgloses: [],
    },
    savingReporte: false,
    reportes: [],
    defaultItemReporte: {
      idsOperadores: [],
      idTrabajoOrden: -1,
      idTrabajoOrdenDetalle: -1,
      idArea: -1,
      idOperacion: -1,
      idsMaquinasIns: [],
      inicio: "",
      fin: "",
      cantidadAuxiliar: undefined,
      cantidad: 0,
      cantidadDefecto: 0,
      unidad: "",
      unidadAbreviatura: "",
      unidadAuxiliar: "",
      unidadAuxiliarAbreviatura: "",
      factorConversion: null,
      defectoParos: [],
    },
    headers: [
      {
        text: "#",
        align: "center",
        sort: false,
        value: "posicion",
        width: "50px",
      },
      {
        text: "Área",
        align: "center",
        sort: false,
        value: "idArea",
        width: "200px",
      },
      {
        text: "Operador(es)",
        align: "center",
        sort: false,
        value: "idsOperadores",
        width: "280px",
      },
      {
        text: "Orden de Trabajo",
        align: "center",
        sort: false,
        value: "idTrabajoOrden",
        width: "250px",
      },
      {
        text: "Producto Esperado",
        align: "center",
        sort: false,
        value: "idTrabajoOrdenDetalle",
        width: "350px",
      },
      {
        text: "Producto Intermedio",
        align: "center",
        sort: false,
        value: "idOperacion",
        width: "300px",
      },
      {
        text: "Máquina(s)",
        align: "center",
        sort: false,
        value: "idsMaquinasIns",
        width: "350px",
      },
      {
        text: "Inicio",
        align: "center",
        sort: false,
        value: "inicio",
        width: "180px",
      },
      {
        text: "Fin",
        align: "center",
        sort: false,
        value: "fin",
        width: "180px",
      },
      {
        text: "Cantidad Total",
        align: "center",
        sort: false,
        value: "cantidad",
        width: "150px",
      },
      {
        text: "Cantidad Auxiliar",
        align: "center",
        sort: false,
        value: "cantidadAuxiliar",
        width: "150px",
      },
      {
        text: "Cantidad con Defecto",
        align: "center",
        sort: false,
        value: "cantidadDefecto",
        width: "150px",
      },
      {
        text: "Acción",
        align: "center",
        sort: false,
        value: "accion",
        width: "80px",
      },
    ],
    areas: [],
    personas: [],
    trabajoOrdenes: [],
    maquinas: [],
    layouts: [],
    idLayout: -1,
    random: 0,
    errores: [],
    dialogError: false,
    dialogEnviado: false,
    erroresDesglose: [],
    tipoParos: [],
    tipoSubparos: [],
    instancias: [],
    toggle_exclusive: undefined,
    inicio: null,
    fin: null,
  }),
  computed: {
    headersFiltrados() {
      return this.reporteAuxiliar
        ? this.headers
        : this.headers.filter((head) => head.value != "cantidadAuxiliar");
    },
    instanciasFiltradas() {
      let maquinasFiltradas = [];
      this.instancias.forEach((ins) => {
        this.desglosarItem.idsMaquinasIns.forEach((maq) => {
          if (ins.idMaquinaIns == maq) maquinasFiltradas.push(ins);
        });
      });
      return maquinasFiltradas;
    },
    ...mapState(["idsTurnos", "reporteAuxiliar"]),
  },
  created() {
    this.initialize();
  },
  methods: {
    marcaModelo: (item) => item.marca.trim() + " - " + item.modelo.trim(),
    resortPersonas(idArea){
      if(idArea == -1) this.personasReordenadas = this.colocarHeadersPersonas(this.personas);
      
      const nuevoOrden = this.personas.sort( (a,b) => {
        if(a.idsAreas.includes(idArea) && b.idsAreas.includes(idArea)){
          if(a.idPersona < b.idPersona) return -1;
          else return 1;
        }
        else if(a.idsAreas.includes(idArea)){
          return -1;
        }
        else if(b.idsAreas.includes(idArea)){
          return 1;
        }
        else {
          if(a.idPersona < b.idPersona) return -1;
          else return 1;
        }
      });
      
      this.personasReordenadas = this.colocarHeadersPersonas(nuevoOrden);
    },
    colocarHeadersPersonas(arreglo){
      const conHeaders = [];
      const grouped = this.groupBy(arreglo,'group');
      grouped.forEach( (group,idx) => {
        conHeaders.push({ header: group[0] });
        conHeaders.push(...group[1]);
        if(idx < grouped.length - 1) conHeaders.push({ divider: true });
      });
      return conHeaders;
    },
    groupBy(arr, key = null){
			const initialValue = {};
			const res = arr.reduce((acc, cval) => {
				const myAttribute = cval[key];
				acc[myAttribute] = [...(acc[myAttribute] || []), cval]
				return acc;
			}, initialValue);
			return Object.entries(res)
		},
    actualizarFechas(item, index) {
      item.inicio = this.desglosarItem.defectoParos[index].inicio;
      item.fin = this.desglosarItem.defectoParos[index].fin;
    },
    getUnidades(item) {
      const resp = this.trabajoOrdenesDetallesFiltrados(
        item.idTrabajoOrden
      ).find(
        (ordt) => ordt.idTrabajoOrdenDetalle == item.idTrabajoOrdenDetalle
      );
      if (resp != undefined) {
        item.unidadAbreviatura = resp.unidadAbreviatura;
        item.unidadAuxiliarAbreviatura = resp.unidadAuxiliarAbreviatura;
        item.factorConversion = resp.factorConversion;
        item.unidad = resp.unidad;
        item.unidadAuxiliar = resp.unidadAuxiliar;
      }
    },
    validateDesgloses() {
      this.erroresDesglose = [];
      this.desglosarItem.desgloses.forEach((desglose, idx) => {
        if (desglose.idParoTipo == 0) {
          this.erroresDesglose.push(
            `Debe seleccionar el Motivo de la Incidencia en Renglon #${idx + 1}`
          );
        }

        if (desglose.bloqueoProducion == undefined) {
          this.erroresDesglose.push(
            `Debe indicar si desea Bloquear la Producción en Renglon #${idx +
              1}`
          );
        }

        if (
          isNaN(desglose.cantidadReportada) ||
          desglose.cantidadReportada < 0
        ) {
          this.erroresDesglose.push(
            `Debe introducir una Cantidad Reportada valida en Renglon #${idx +
              1}`
          );
        }

        if (desglose.inicio == null) {
          this.erroresDesglose.push(
            `Debe seleccionar una Fecha de Inicio valida en Renglon #${idx + 1}`
          );
        }

        if (desglose.inicio != null && desglose.bloqueoProducion == 1) {
          if (
            !moment(desglose.inicio).isBetween(
              this.desglosarItem.inicio,
              this.desglosarItem.fin
            )
          ) {
            this.erroresDesglose.push(
              `Debe seleccionar una Fecha de Inicio dentro del rango de fechas de la operacion en Renglon #${idx +
                1}`
            );
          }
        }

        if (desglose.fin == null) {
          this.erroresDesglose.push(
            `Debe seleccionar una Fecha de Fin valida en Renglon #${idx + 1}`
          );
        }

        if (desglose.fin != null) {
          if (!moment(desglose.fin).isBefore(this.desglosarItem.fin)) {
            this.erroresDesglose.push(
              `Debe seleccionar una Fecha de Fin menor a la fecha final de la operacion en Renglon #${idx +
                1}`
            );
          }
        }
      });

      return this.erroresDesglose.length == 0;
    },
    saveDesgloses() {
      if (!this.validateDesgloses()) return;
      this.loadingDesgloses = true;
      const desgloses = this.desglosarItem.desgloses.map((desglose) => ({
        ...desglose,
        bloqueoProducion: !desglose.bloqueoProducion,
        fin:
          !desglose.bloqueoProducion == false ? desglose.inicio : desglose.fin,
      }));
      this.reportes[this.desglosarItem.index].defectoParos = Object.assign(
        [],
        desgloses
      );
      this.loadingDesgloses = false;
      this.cerrarDialogDesglose();
    },
    changeTipoParo(index) {
      this.desglosarItem.desgloses[index].idSubParoTipo = null;
    },
    eliminarDesglose(index) {
      this.desglosarItem.desgloses.splice(index, 1);
    },
    reporteTipoSubparos(index) {
      return this.$utils.isValid(this.desglosarItem.desgloses[index].idParoTipo)
        ? this.tipoSubparos.filter(
            (sub) =>
              sub.idParoTipo == this.desglosarItem.desgloses[index].idParoTipo
          )
        : [];
    },
    maxCantidad(current) {
      let total = 0;
      this.desglosarItem.desgloses.forEach((desglose) => {
        total += desglose.cantidadReportada;
      });
      if (total < this.desglosarItem.cantidadDefecto) {
        return this.desglosarItem.cantidadDefecto - total + current;
      } else {
        return current;
      }
    },
    initialize() {
      this.getInformacion();
      this.getLayouts();
      this.getTipoSubParos();
      this.getTipoParos();
      this.getInstancias();
    },
    cerrarDialogDesglose() {
      this.dialogDesglosar = false;
      this.$nextTick(() => {
        this.desglosarItem = Object.assign({}, this.defaultDesglosarItem);
        this.desglosarItem.desgloses = [];
        this.erroresDesglose = [];
      });
    },
    agregarNewDesglose() {
      const obj = {
        ...this.itemDesglosed,
        inicio: this.desglosarItem.inicio,
        fin: this.desglosarItem.fin,
      };
      this.desglosarItem.desgloses.push(Object.assign({}, obj));
    },
    desglosar(item, index) {
      this.desglosarItem = {
        index,
        ...this.desglosarItem,
        ...item,
        inicio: moment(item.inicio, "DD/MM/YYYY HH:mm").format(
          "YYYY-MM-DD HH:mm"
        ),
        fin: moment(item.fin, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD HH:mm"),
        desgloses: item.defectoParos.map((defecto) => ({
          ...defecto,
          bloqueoProducion: defecto.bloqueoProducion ? 0 : 1,
        })),
      };

      this.dialogDesglosar = true;
    },
    nombrePersona: ({nombre,paterno,materno}) =>
       `${nombre?`${nombre} `:''}${paterno?`${paterno} `:''}${materno?`${materno} `:''}`.trim(),
    agregarReporte() {
      this.reportes.push(Object.assign({}, this.defaultItemReporte));
      this.random = Math.random();
    },
    eliminarReporte(item) {
      this.reportes.splice(this.reportes.indexOf(item), 1);
      this.random = Math.random();
    },
    getInformacion() {
      this.loadingInformacion = true;
      axios
        .get("/Reportes/ObtenerInformacionReporte")
        .then((response) => {
          this.areas = response.data.areas;
          this.turnos = response.data.turnos;
          this.personas = [];
          const idsTurnos = [
						...new Set(
							response.data.personas.map((x) => x.idTurno)
						),
					].sort();

          for (let pos = 0; pos < idsTurnos.length; pos++) {
            var posTurno = this.turnos.findIndex(
              (t) => t.idTurno == idsTurnos[pos]
            );
            if (posTurno >= 0) {
              // if (pos > 0) {
              //   this.personas.push({ divider: true });
              // }
              // this.personas.push({ header: this.turnos[posTurno].nombre });

              this.personas = this.personas.concat(
                response.data.personas
                  .filter((p) => p.idTurno == idsTurnos[pos])
                  .map((p) => {
                    //p.group = this.turnos[posTurno].nombre;
                    return {
                      idPersona: p.idPersona,
                      nombre: this.nombrePersona(p),
                      idsAreas: p.idsAreas,
                      group: this.turnos[posTurno].nombre,
                    };
                  })
              );
            }
          }

          this.trabajoOrdenes = response.data.trabajoOrdenes;
          this.maquinas = response.data.maquinas;
          this.loadingInformacion = false;
        })
        .catch((error) => {
          this.loadingInformacion = false;
          console.log(error);
        });
    },
    trabajoOrdenesFiltrados(idArea) {
      if (idArea <= 0) return [];
      else
        return this.trabajoOrdenes.filter(
          (ot) => ot.idsAreas.indexOf(idArea) >= 0
        );
    },
    trabajoOrdenesDetallesFiltrados(idTrabajoOrden, idArea) {
      var pos = this.trabajoOrdenes.findIndex(
        (ot) => ot.idTrabajoOrden == idTrabajoOrden
      );
      if (pos >= 0) {
        if (idArea <= 0) return [];
        else
          return this.trabajoOrdenes[pos].trabajoOrdenDetalles.filter(
            (otd) => otd.idsAreas.indexOf(idArea) >= 0
          );
      } else return [];
    },
    operacionesFiltradas(idTrabajoOrden, idTrabajoOrdenDetalle, idArea) {
      var posOT = this.trabajoOrdenes.findIndex(
        (ot) => ot.idTrabajoOrden == idTrabajoOrden
      );
      if (posOT >= 0) {
        var posOTD = this.trabajoOrdenes[posOT].trabajoOrdenDetalles.findIndex(
          (otd) => otd.idTrabajoOrdenDetalle == idTrabajoOrdenDetalle
        );
        if (posOTD >= 0) {
          return this.trabajoOrdenes[posOT].trabajoOrdenDetalles[
            posOTD
          ].operaciones.filter((o) => o.idArea == idArea);
        } else return [];
      } else return [];
    },
    predecirDetalle(event, item) {
      if (event <= 0) return;

      var detalles = this.trabajoOrdenesDetallesFiltrados(event);

      if (detalles.length == 1) {
        item.idTrabajoOrdenDetalle = detalles[0].idTrabajoOrdenDetalle;
      } else {
        item.idTrabajoOrdenDetalle = -1;
      }
      this.predecirOperacion(item.idTrabajoOrdenDetalle, item);
    },
    predecirOperacion(event, item) {
      if (event <= 0) return;

      var operaciones = this.operacionesFiltradas(
        item.idTrabajoOrden,
        event,
        item.idArea
      );

      if (operaciones.length == 1) {
        item.idOperacion = operaciones[0].idOperacion;
      } else {
        item.idOperacion = -1;
      }
    },
    predecirMaquina(event, item) {
      if (event <= 0) return;

      var maquinas = this.maquinas.filter((m) => m.idArea == event);

      if (maquinas.length > 0) {
        // item.idsMaquinasIns = maquinas;
      } else {
        // item.idsMaquinasIns = [];
      }
    },
    getInstancias() {
      axios
        .get("/MaquinaInstancias")
        .then((response) => {
          this.instancias = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTipoParos() {
      axios
        .get("/ParoTipos")
        .then((response) => {
          this.tipoParos = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTipoSubParos() {
      axios
        .get("/SubParoTipos")
        .then((response) => {
          this.tipoSubparos = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getLayouts() {
      axios
        .get("/Reportes/ListarLayouts")
        .then((response) => {
          this.layouts = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    layoutsFiltrados() {
      return this.layouts.filter((l) => this.idsTurnos.indexOf(l.idTurno) >= 0);
    },
    cargarLayout() {
      if (this.idLayout <= 0) return;

      axios
        .get("/Reportes/ObtenerLayout/" + this.idLayout)
        .then((response) => {
          this.idLayout = -1;
          this.reportes = [];
          response.data.forEach((asignacion) => {
            var reporte = Object.assign({}, this.defaultItemReporte);
            reporte.idsOperadores = [];

            reporte.idArea = asignacion.idArea;
            asignacion.idsPersonas.forEach((idPersona) => {
              reporte.idsOperadores.push(idPersona);
            });
            this.predecirMaquina(asignacion.idArea, reporte);
            this.reportes.push(reporte);
          });
          this.random = Math.random();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    aplicarInicio() {
      if (!this.$refs.inicioGlobal.anyError() && this.inicio == null) {
        for (let pos = 0; pos < this.reportes.length; pos++) {
          if (this.$refs["inicio" + pos].anyError() ||this.$refs["inicio" + pos].getValue().length <= 15) {
            this.reportes[pos].inicio = this.$refs.inicioGlobal.getValue();
          }
        }
        this.$refs.inicioGlobal.setValue("", false);
      }
      
      if(this.inicio!= null){
        this.$refs.inicioGlobal.setValue(this.inicio,false)
        for (let pos = 0; pos < this.reportes.length; pos++) {
          if (this.$refs["inicio" + pos].anyError() ||this.$refs["inicio" + pos].getValue().length <= 15) {
            this.reportes[pos].inicio = this.inicio;
          }
        }
      }
    },
    aplicarFin() {
      if (!this.$refs.finGlobal.anyError()  && this.fin == null) {
        for (let pos = 0; pos < this.reportes.length; pos++) {
          if (
            this.$refs["fin" + pos].anyError() ||
            this.$refs["fin" + pos].getValue().length <= 15
          ) {
            this.reportes[pos].fin = this.$refs.finGlobal.getValue();
          }
        }
        this.$refs.finGlobal.setValue("", false);
      }

      if(this.fin!= null){
        this.$refs.finGlobal.setValue(this.fin, false);
        for (let pos = 0; pos < this.reportes.length; pos++) {
          if (this.$refs["fin" + pos].anyError() || this.$refs["fin" + pos].getValue().length <= 15) {
            this.reportes[pos].fin = this.fin;
          }
        }
      }
    },
    maquinasOrdenadas(item) {
      var idsAreas = this.$utils.arrays
        .unique(this.maquinas.map((m) => m.idArea))
        .sort();

      if (item.idArea > 0) {
        idsAreas = idsAreas.filter((id) => id != item.idArea);
        idsAreas.unshift(item.idArea);
      }

      var result = [];
      for (let pos = 0; pos < idsAreas.length; pos++) {
        if (pos > 0) result.push({ divider: true });
        var posArea = this.areas.findIndex((a) => a.idArea == idsAreas[pos]);
        if (posArea >= 0) {
          result.push({ header: this.areas[posArea].nombre });
          result = result.concat(
            this.maquinas
              .filter((m) => m.idArea == idsAreas[pos])
              .map((m) => {
                return {
                  idMaquinaInstancia: m.idMaquinaInstancia,
                  nombre: m.nombre,
                  group: this.areas[posArea].nombre,
                };
              })
          );
        }
      }
      return result;
    },
    validate() {
      this.errores = [];
      if (this.reportes.length <= 0) {
        this.errores.push({
          mensaje: "No se ha agregado ningún reporte",
          detalles: [],
        });
        return false;
      } else {
        for (let pos = 0; pos < this.reportes.length; pos++) {
          var error = {
            mensaje: "Renglón #" + (pos + 1),
            detalles: [],
          };

          if (this.reportes[pos].idArea <= 0) {
            error.detalles.push("No se ha seleccionado una Área");
          }

          if (this.reportes[pos].idsOperadores.length <= 0) {
            error.detalles.push("Debe de seleccionar al menos un Operador");
          }

          if (this.reportes[pos].idTrabajoOrden <= 0) {
            error.detalles.push("No se ha seleccionado una Orden de Trabajo");
          }

          if (this.reportes[pos].idTrabajoOrdenDetalle <= 0) {
            error.detalles.push("No se ha seleccionado un Producto Esperado");
          }

          if (this.reportes[pos].idOperacion <= 0) {
            error.detalles.push("No se ha seleccionado un Producto Intermedio");
          }

          // if(this.reportes[pos].idsMaquinasIns.length == 0){
          //   error.detalles.push("No se ha seleccionado una Máquina");
          // }

          if (
            this.$refs["inicio" + pos].anyError() ||
            this.reportes[pos].inicio.length <= 15
          ) {
            error.detalles.push("La Fecha de Inicio es Invalida");
          }

          if (
            this.$refs["fin" + pos].anyError() ||
            this.reportes[pos].fin.length <= 15
          ) {
            error.detalles.push("La Fecha de Fin es Invalida");
          }

          if (
            isNaN(this.reportes[pos].cantidad) ||
            this.reportes[pos].cantidad < 0
          ) {
            error.detalles.push("La Cantidad debe ser Igual o Mayor a 0");
          }

          if (
            isNaN(this.reportes[pos].cantidadDefecto) ||
            this.reportes[pos].cantidadDefecto < 0
          ) {
            error.detalles.push(
              "La Cantidad con Defecto debe ser Igual o Mayor a 0"
            );
          }

          if (error.detalles.length > 0) this.errores.push(error);
        }
      }

      return this.errores.length == 0;
    },
    save() {
      if (!this.validate()) {
        this.dialogError = true;
        return;
      }
      this.savingReporte = true;
      axios
        .post("/Reportes/ReporteExtemporaneo", this.reportes)
        .then(() => {
          this.savingReporte = false;
          this.reportes = [];
          this.dialogEnviado = true;
        })
        .catch((error) => {
          this.savingReporte = false;
          console.log(error);
        });
    },
    cantidadMaxima(item) {
      var pos = this.reportes.indexOf(item);
      if (pos >= 0) {
        var cantidad = 0;
        this.reportes
          .filter(
            (r, index) => r.idOperacion == item.idOperacion && index != pos
          )
          .forEach((r) => {
            if (!isNaN(r.cantidad)) cantidad += r.cantidad;
          });

        var operaciones = this.operacionesFiltradas(
          item.idTrabajoOrden,
          item.idTrabajoOrdenDetalle,
          item.idArea
        );

        if (operaciones.length == 1) {
          var resultado = operaciones[0].cantidad - cantidad;
          if (resultado >= 0) return resultado;
          else return 0;
        } else if (operaciones.length > 1) {
          const res = operaciones.find(
            (oper) => oper.idOperacion == item.idOperacion
          );
          return res != undefined
            ? res.cantidad - cantidad >= 0
              ? res.cantidad - cantidad
              : 0
            : Infinity;
        } else {
          return Infinity;
        }
      } else {
        return Infinity;
      }
    },
    labelCantidadMaxima(item) {
      var cantidadMaxima = this.cantidadMaxima(item);
      if (cantidadMaxima != Infinity)
        return `Máximo: ${cantidadMaxima} ${
          item.unidad != null ? item.unidad : ""
        }`;
      else return null;
    },
    labelCantidadMaximaDefecto(item) {
      var cantidadMaxima = item.cantidad;
      if (
        !isNaN(cantidadMaxima) &&
        cantidadMaxima != Infinity &&
        cantidadMaxima != 0
      )
        return `Máximo: ${cantidadMaxima} ${
          item.unidad != null ? item.unidad : ""
        }`;
      else return null;
    },
    labelCantidadMaximaAuxiliar(item) {
      var cantidadMaxima = item.cantidad * item.factorConversion;
      if (
        !isNaN(cantidadMaxima) &&
        cantidadMaxima != Infinity &&
        cantidadMaxima != 0
      )
        return `Máximo: ${cantidadMaxima} ${
          item.unidadAuxiliar != null ? item.unidadAuxiliar : ""
        }`;
      else return null;
    },
    colorOperacion(item) {
      if (item.scrap) return "red--text";
      else if (item.rRetrabajo) return "blue--text";
      else return "black--text";
    },
    textoOperacion(item) {
      if (item.scrap) return `${item.articulo} - Scrap`;
      else if (item.rRetrabajo)
        return `${item.articulo} - Retrabajo - ${item.retrabajoNombre}`;
      else return item.articulo;
    },
    setHoras(intervaloHoras) {
      var now = moment(new Date());
      var today = moment(new Date()).startOf("day");

      if (now.minutes() >= 50) {
        var totalMinutos = now.diff(today, "seconds") / 60;
        var diferencia =
          (Math.trunc(totalMinutos / 60) + 1) * 60 - totalMinutos;
        var fecha = now.add(diferencia, "minutes");
        /* var fin = fecha.format("YYYY-MM-DD HH:mm"); */
        var fin = fecha.format("DD/MM/YYYY HH:mm");
        fecha.subtract(60 * intervaloHoras, "minutes");
        /* var inicio = fecha.format("YYYY-MM-DD HH:mm"); */
        var inicio = fecha.format("DD/MM/YYYY HH:mm");
        this.inicio = inicio;
        this.fin = fin;
      } else {
        var totalMinutos2 = now.diff(today, "seconds") / 60;
        var diferencia2 = totalMinutos2 - now.hours() * 60;
        var fecha2 = now.subtract(diferencia2, "minutes");
        /* var fin2 = fecha2.format("YYYY-MM-DD HH:mm"); */
        var fin2 = fecha2.format("DD/MM/YYYY HH:mm");
        fecha2.subtract(60 * intervaloHoras, "minutes");
        /* var inicio2 = fecha2.format("YYYY-MM-DD HH:mm"); */
        var inicio2 = fecha2.format("DD/MM/YYYY HH:mm");
        this.inicio = inicio2;
        this.fin = fin2;
      }
      this.aplicarInicio();
      this.aplicarFin();
    }
  },
};
</script>
<style>
.datetimepicker {
  position: fixed !important;
  top: 50% !important;
  right: -60% !important;
}

.btn-icons {
  padding-bottom: 30px;
}

#reportes .v-btn__content {
  white-space: normal;
  flex: auto;
  text-align: center;
}

#reportes .v-btn {
  min-height: 52px;
  height: 100% !important;
  align-items: center;
}

</style>
