<template>
	<v-col cols="12">
		<v-dialog v-model="dialogToggleAutenticar" persistent max-width="600px">
			<v-card>
				<v-card-text class="pt-2">
					<p class="text-h5 text--primary font-weight-medium">
						¿Está seguro que desea
						{{
							editedItem.autenticada
								? "autenticar"
								: "desautenticar"
						}}
						este Punto de Impresión?
					</p>
					<v-simple-table>
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-center th-text">
										Punto de Impresión
									</th>
									<th class="text-center th-text">
										Identificador
									</th>
									<th class="text-center th-text">
										Impresora Predeterminada
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td class="text-center">
										{{ editedItem.puntoImpresion }}
									</td>
									<td class="text-center">
										{{ editedItem.identificador }}
									</td>
									<td class="text-center">
										{{ editedItem.impresora }}
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="toggling"
						@click="cerrarDialog()"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="toggling"
						:loading="toggling"
						@click="toggleAutenticar()"
						>Aceptar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog" persistent max-width="500px">
			<v-card>
				<v-card-title>Editar Punto de Impresión</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.puntoImpresion"
									label="Punto de Impresión"
									clearable
									:error-messages="errorPuntoImpresion"
									@focus="errorPuntoImpresion = ''"
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.identificador"
									label="Identificador"
									clearable
									:error-messages="errorIdentificador"
									@focus="errorIdentificador = ''"
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-autocomplete
									v-model="editedItem.idImpresora"
									label="Impresora Predeterminada"
									:items="impresoras"
									item-text="nombre"
									item-value="idImpresora"
									clearable
									:error-messages="errorImpresora"
									@focus="errorImpresora = ''"
								></v-autocomplete>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="saving"
						@click="cerrarDialog()"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="saving"
						:loading="saving"
						@click="save()"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row class="justify-space-between">
			<v-col cols="4">
				<v-select
					v-model="filtros.autenticado"
					label="¿Está Autenticado?"
					:items="authenticated"
					item-text="text"
					item-value="value"
					hide-details
					clearable
					@change="getPuntosImpresion"
				></v-select>
			</v-col>
			<v-col cols="6">
				<v-text-field
					v-model="search"
					label="Buscar"
					append-icon="mdi-magnify"
					clearable
				></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
					:items="puntosImpresion"
					:headers="puntosImpresionHeaders"
					:search="search"
					:loading="loading"
				>
					<template v-slot:item.autenticada="{ item }">
						<v-chip
							:color="item.autenticada?'success':'error'"
							label
							text-color="white"
							style="width: 70px"
						>
							<v-icon left> {{item.autenticada?'mdi-check':'mdi-close'}} </v-icon>
							<p class="mb-0">{{item.autenticada?'SI':'NO'}}</p>
						</v-chip>
					</template>
					<template v-slot:item.actions="{ item }">
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									size="18"
									@click="showDialogToggle(item)"
									>{{
										item.autenticada
											? "mdi-cancel"
											: "mdi-check-circle"
									}}</v-icon
								>
							</template>
							<span class="white--text">{{
								item.autenticada
									? "Desautenticar"
									: "Autenticar"
							}}</span>
						</v-tooltip>
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									size="18"
									@click="showDialogEdit(item)"
									>edit</v-icon
								>
							</template>
							<span class="white--text">Editar</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			toggling: false,
			errorPuntoImpresion: "",
			errorIdentificador: "",
			errorImpresora: "",
			saving: false,
			impresoras: [],
			dialogToggleAutenticar: false,
			editedItem: {
				idPuntoImpresion: 0,
				puntoImpresion: "",
				identificador: "",
				idImpresora: null,
				impresora: null,
				autenticada: false,
			},
			defaultEditedItem: {
				idPuntoImpresion: 0,
				puntoImpresion: "",
				identificador: "",
				idImpresora: null,
				impresora: null,
				autenticada: false,
			},
			dialog: false,
			puntosImpresionHeaders: [
				{
					text: "Punto de Impresión",
					align: "center",
					value: "puntoImpresion",
				},
				{
					text: "Identificador",
					align: "center",
					value: "identificador",
				},
				{
					text: "Impresora Predeterminada",
					align: "center",
					value: "impresora",
				},
				{
					text: "¿Está Autenticada?",
					align: "center",
					value: "autenticada",
				},
				{
					text: "Acciones",
					align: "center",
					value: "actions",
				},
			],
			loading: false,
			puntosImpresion: [],
			search: null,
			filtros: {
				autenticado: null,
			},
			authenticated: [
				{
					text: "Sí",
					value: true,
				},
				{
					text: "No",
					value: false,
				},
			],
		};
	},
	mounted() {
		this.initialize();
	},
	methods: {
		toggleAutenticar() {
			this.toggling = true;
			axios
				.put(
					`/Seguridad/Toggle/${this.editedItem.idPuntoImpresion}`,
					this.editedItem
				)
				.then(() => {
					this.toggling = false;
					this.cerrarDialog();
					this.initialize();
				})
				.catch((error) => {
					this.toggling = false;
					console.log(error);
				});
		},
		validate() {
			this.errorPuntoImpresion = "";
			this.errorIdentificador = "";
			this.errorImpresora = "";

			if (
				this.editedItem.puntoImpresion == null ||
				this.editedItem.puntoImpresion.length <= 5
			) {
				this.errorPuntoImpresion =
					"El nombre del Punto de Impresion debe tener mínimo 5 caracteres";
			}

			if (
				this.editedItem.identificador == null ||
				this.editedItem.identificador.length <= 5
			) {
				this.errorIdentificador =
					"El Identificador debe tener mínimo 5 caracteres";
			}

			if (this.editedItem.idImpresora == null) {
				this.errorImpresora = "Debe seleccionar una impresora";
			}

			return (
				this.errorPuntoImpresion == "" &&
				this.errorIdentificador == "" &&
				this.errorImpresora == ""
			);
		},
		save() {
			if (!this.validate()) return;

			this.saving = true;

			axios
				.put("/Seguridad/ActualizarPuntoImpresion", this.editedItem)
				.then(() => {
					this.saving = false;
					this.cerrarDialog();
					this.initialize();
				})
				.catch((error) => {
					this.saving = false;
					console.log(error);
				});
		},
		cerrarDialog() {
			this.dialog = false;
			this.dialogToggleAutenticar = false;
			this.editedItem = { ...this.defaultEditedItem };
			this.errorPuntoImpresion = "";
			this.errorIdentificador = "";
			this.errorImpresora = "";
		},
		showDialogToggle(item) {
			this.editedItem = {
				...item,
				autenticada: !item.autenticada,
			};
			this.dialogToggleAutenticar = true;
		},
		showDialogEdit(item) {
			this.editedItem = { ...item };
			this.dialog = true;
		},
		initialize() {
			this.getPuntosImpresion();
		},
		getPuntosImpresion() {
			this.loading = false;
			axios
				.get("/Seguridad/PuntosImpresion", {
					params: {
						autenticado: this.filtros.autenticado,
					},
				})
				.then((response) => {
					this.puntosImpresion = response.data;
				})
				.catch((error) => {
					this.puntosImpresion = [
						{
							idPuntoImpresion: 1,
							puntoImpresion: "Punto de Impresion 1",
							identificador: "rxt4235xm",
							idImpresora: 1,
							impresora: "Brother QL-800",
							autenticada: false,
						},
						{
							idPuntoImpresion: 2,
							puntoImpresion: "Punto de Impresion 2",
							identificador: "yx9hl1x3",
							idImpresora: 1,
							impresora: "Brother QL-800",
							autenticada: true,
						},
					];
					console.log(error);
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>
<style scoped>
.th-text {
	font-size: 16px !important;
}
</style>