<template>
	<v-col cols="12" v-if="permisoVista('bloqueomaquina','r')">
		<v-dialog v-model="dialogBloqueo" persistent max-width="30%">
			<v-card>
				<v-card-title>{{
					itemBloqueo.idMaquinaInstanciaBloqueo == -1
						? "Crear Nuevo Bloqueo"
						: "Finalizar Bloqueo"
				}}</v-card-title>
				<v-card-text>
					<v-row>
						<v-col
							cols="12"
							v-if="itemBloqueo.idMaquinaInstanciaBloqueo == -1"
						>
							<v-autocomplete
								v-model="itemBloqueo.idMaquinaIns"
								:items="instancias"
								:item-text="marcaModelo"
								item-value="idMaquinaIns"
								label="Máquina a bloquear"
								clearable
								hide-details
							>
								<template v-slot:item="data">
									<v-list-item-content>
										<v-list-item-title>{{
											data.item.marca +
											" - " +
											data.item.modelo
										}}</v-list-item-title>
										<v-list-item-subtitle>{{
											data.item.nombre
										}}</v-list-item-subtitle>
										<v-list-item-content>{{
											$utils.strings.truncate(
												data.item.descripcionGeneral,
												50
											)
										}}</v-list-item-content>
									</v-list-item-content>
								</template>
							</v-autocomplete>
						</v-col>
						<v-col
							cols="12"
							v-if="itemBloqueo.idMaquinaInstanciaBloqueo == -1"
						>
							<VueCtkDateTimePicker
								v-model="itemBloqueo.inicio"
								:min-date="itemBloqueo.fechaAhora"
                                :key="`ctkin-${ctkin}`"
								label="Fecha Inicio del bloqueo"
								format="YYYY-MM-DD HH:mm"
								formatted="YYYY-MM-DD HH:mm"
								noHeader
								overlay
                                noClearButton
								buttonNowTranslation="Ahora"
							/>
						</v-col>
						<v-col cols="12" v-else class="mt-4">
							<VueCtkDateTimePicker
								v-model="itemBloqueo.fin"
								:min-date="itemBloqueo.inicio"
                                :key="`ctkfin-${ctkfin}`"
								label="Fecha Termino del bloqueo"
								format="YYYY-MM-DD HH:mm"
								formatted="YYYY-MM-DD HH:mm"
								noHeader
								overlay
                                noClearButton
								buttonNowTranslation="Ahora"
							/>
						</v-col>
					</v-row>
					<ul class="mt-6">
						<li
							class="red--text"
							v-for="(err, idx) in erroresBloqueo"
							:key="idx"
						>
							{{ err }}
						</li>
					</ul>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="green"
						:disabled="savingBloqueo"
						@click="cerrarDialogBloqueo()"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="red"
						:disabled="savingBloqueo"
						:loading="savingBloqueo"
						@click="saveBloqueo()"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row class="mt-0 mb-0">
			<v-col cols="6" md="6" sm="6" class="align-self-end pt-0">
				<VueCtkDateTimePicker
					v-model="filtros.inicio"
					label="Desde"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="getBloqueos()"
				/>
			</v-col>
			<v-col cols="6" md="6" sm="6" class="align-self-end pt-0">
				<VueCtkDateTimePicker
					v-model="filtros.fin"
					:min-date="filtros.inicio"
					label="Hasta"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="getBloqueos()"
				/>
			</v-col>
			<v-col cols="6" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idMaquinaIns"
					:items="instancias"
					:item-text="marcaModelo"
					item-value="idMaquinaIns"
					label="Máquina"
					clearable
					hide-details
					@change="getBloqueos()"
				>
					<template v-slot:item="data">
						<v-list-item-content>
							<v-list-item-title>{{
								data.item.marca + " - " + data.item.modelo
							}}</v-list-item-title>
							<v-list-item-subtitle>{{
								data.item.nombre
							}}</v-list-item-subtitle>
							<v-list-item-content>{{
								$utils.strings.truncate(
									data.item.descripcionGeneral,
									50
								)
							}}</v-list-item-content>
						</v-list-item-content>
					</template>
				</v-autocomplete>
			</v-col>
			<v-col cols="6" md="6" sm="6" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idArea"
					:items="areas"
					item-value="idArea"
					item-text="nombre"
					label="Área"
					hide-details
					clearable
					@change="getBloqueos()"
				></v-autocomplete>
			</v-col>
		</v-row>
		<v-row class="mt-0 mb-2">
			<v-col cols="4" class="my-0 py-0">
				<v-btn v-if="permisoVista('bloqueomaquina','c')" color="primary" @click="showDialogBloqueo()"
					>Nuevo Bloqueo</v-btn
				>
			</v-col>
		</v-row>
		<v-data-table
			:items="bloqueos"
			:headers="headersBloqueos"
			:loading="loading"
		>
			<template v-slot:item.maquina="{ item }">
				<p class="mb-0">
					{{
						item.marca != null &&
						item.modelo != null &&
						item.nombre != null
							? `[${item.marca.trim()} - ${item.modelo.trim()}] - ${item.nombre.trim()}`
							: "N/A"
					}}
				</p>
			</template>
			<template v-slot:item.idUsuarioInicio="{ item }">
				{{ getNombreUsuario(item.idUsuarioInicio) }}
			</template>
			<template v-slot:item.idUsuarioFin="{ item }">
				{{ getNombreUsuario(item.idUsuarioFin) }}
			</template>
			<template v-slot:item.fin="{ item }">
				{{
					item.fin == "0001-01-01 00:00" ||
					item.fin == "" ||
					item.fin == null
						? "N/A"
						: item.fin
				}}
			</template>
			<template v-slot:item.actions="{ item }">
				<v-tooltip
					v-if="permisoVista('bloqueomaquina','u')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-show="habilitarFinBloqueo(item)"
							v-on="on"
							small
							@click="finalizarBloqueo(item)"
						>
							mdi-stop
						</v-icon>
					</template>
					<span class="white--text">Finalizar Bloqueo</span>
				</v-tooltip>
			</template>
		</v-data-table>
	</v-col>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
	data() {
		return {
            ctkin: 0,
            ctkfin: 0,
			savingBloqueo: false,
			dialogBloqueo: false,
			itemBloqueo: {
				idMaquinaInstanciaBloqueo: -1,
				idMaquinaIns: 0,
				fechaAhora: null,
				inicio: null,
				fin: null,
			},
			defaultItemBloqueo: {
				idMaquinaInstanciaBloqueo: -1,
				idMaquinaIns: 0,
				fechaAhora: null,
				inicio: null,
				fin: null,
			},
			filtros: {
				inicio: null,
				fin: null,
				idMaquinaIns: null,
				idArea: null,
			},
			headersBloqueos: [
				{
					text: "Máquina",
					align: "center",
					sortable: false,
					value: "maquina",
				},
				{
					text: "Área",
					align: "center",
					sortable: false,
					value: "area",
				},
				{
					text: "Iniciado por",
					align: "center",
					sortable: false,
					value: "idUsuarioInicio",
				},
				{
					text: "Finalizado por",
					align: "center",
					sortable: false,
					value: "idUsuarioFin",
				},
				{
					text: "Inicio",
					align: "center",
					sortable: false,
					value: "inicio",
				},
				{
					text: "Fin",
					align: "center",
					sortable: false,
					value: "fin",
				},
				{
					text: "Acciones",
					align: "center",
					sortable: false,
					value: "actions",
				},
			],
			bloqueos: [],
			areas: [],
			instancias: [],
			usuarios: [],
			erroresBloqueo: [],
		};
	},
	mounted() {
		this.initialize();
	},
	methods: {
		marcaModelo: (item) => item.marca.trim() + " - " + item.modelo.trim(),
		habilitarFinBloqueo(item) {
			return (
				item.fin == "0001-01-01 00:00" ||
				item.fin == "" ||
				item.fin == null
			);
		},
		finalizarBloqueo(item) {
			this.itemBloqueo.idMaquinaInstanciaBloqueo =
				item.idMaquinaInstanciaBloqueo;
            this.itemBloqueo.inicio = item.inicio;
            this.itemBloqueo.fin = item.inicio;
			this.dialogBloqueo = true;
            this.ctkfin++;
		},
		showDialogBloqueo() {
            this.itemBloqueo.fechaAhora = moment().format("YYYY-MM-DD HH:mm");
            this.itemBloqueo.inicio = this.itemBloqueo.fechaAhora;
			this.dialogBloqueo = true;
            this.ctkin++;
		},
		cerrarDialogBloqueo() {
			this.dialogBloqueo = false;
			this.$nextTick(() => {
				this.itemBloqueo = Object.assign({}, this.defaultItemBloqueo);
				this.erroresBloqueo = [];
			});
		},
		validateBloqueo() {
			this.erroresBloqueo = [];

			if (this.itemBloqueo.idMaquinaInstanciaBloqueo == -1) {
				if (
					this.itemBloqueo.idMaquinaIns == 0 ||
					this.itemBloqueo.idMaquinaIns == null
				) {
					this.erroresBloqueo.push(
						"Debe seleccionar la Máquina a bloquear."
					);
				}

				if (this.itemBloqueo.inicio == null) {
					this.erroresBloqueo.push(
						"Debe indicar la Fecha de Inicio del bloqueo."
					);
				}
			} else {
				if (this.itemBloqueo.fin == null) {
					this.erroresBloqueo.push(
						"Debe indicar la Fecha de Termino del bloqueo."
					);
				}
			}

			return this.erroresBloqueo.length == 0;
		},
		saveBloqueo() {
			if (!this.validateBloqueo()) return;
			this.savingBloqueo = true;
			if (this.itemBloqueo.idMaquinaInstanciaBloqueo == -1) {
				axios
					.post(
						"/MaquinaInstanciaBloqueos/CrearMaquinaInstanciaBloqueo",
						{
							idMaquinaIns: this.itemBloqueo.idMaquinaIns,
							inicio: this.itemBloqueo.inicio,
						}
					)
					.then(() => {
						this.savingBloqueo = false;
						this.cerrarDialogBloqueo();
						this.getBloqueos();
					})
					.catch((error) => {
						this.savingBloqueo = false;
						console.log(error);
					});
			} else {
				axios
					.put(
						"/MaquinaInstanciaBloqueos/FinalizarMaquinaInstanciaBloqueo",
						{
							idMaquinaInstanciaBloqueo:
								this.itemBloqueo.idMaquinaInstanciaBloqueo,
							fin: this.itemBloqueo.fin,
						}
					)
					.then(() => {
						this.savingBloqueo = false;
						this.cerrarDialogBloqueo();
						this.getBloqueos();
					})
					.catch((error) => {
						this.savingBloqueo = false;
						if (error.response) {
							if (error.response.status == 400) {
								this.erroresBloqueo.push(
									"La Fecha del Termino debe ser mayor a la Fecha de Inicio del bloqueo."
								);
							}
						}
					});
			}
		},
		getNombreUsuario(idUsuario) {
			const search = this.usuarios.find(
				(user) => user.idUsuario == idUsuario
			);
			return search != undefined ? search.nombre : "N/A";
		},
		initialize() {
			this.getBloqueos();
			this.getInstancias();
			this.getAreas();
			this.getUsuarios();
		},
		getAreas() {
			axios
				.get("/Areas")
				.then((response) => {
					this.areas = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getInstancias() {
			axios
				.get("/MaquinaInstancias")
				.then((response) => {
					this.instancias = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getUsuarios() {
			axios
				.get("/Mantenimientos/GetUsuariosRespuestaAlerta")
				.then((response) => {
					this.usuarios = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getBloqueos() {
			this.loading = true;
			axios
				.get("/MaquinaInstanciaBloqueos/GetMaquinaInstanciaBloqueo", {
					params: {
						Inicio: this.filtros.inicio,
						Fin: this.filtros.fin,
						IdMaquinaIns: this.filtros.idMaquinaIns,
						IdArea: this.filtros.idArea,
					},
				})
				.then((response) => {
					this.loading = false;
					this.bloqueos = response.data;
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
	},
};
</script>