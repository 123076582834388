<template>
	<v-container fluid>
		<v-dialog
			v-model="cargaMasiva"
			persistent
			max-width="500px"
			key="cargaMasiva"
		>
			<v-card>
				<v-card-title
					>Bulk Upload of GL Accounts</v-card-title
				>
				<v-card-text v-if="errorCargaMasiva">
					<v-col cols="12" md="12" sm="12" class="text-center">
						<h5>An error has occurred. Please try again.</h5>
					</v-col>
					<v-col cols="12" md="12" sm="12" class="text-center">
						<v-btn
							depressed
							color="red"
							text
							@click="
								cargaMasiva = false;
								ficheroCarga = null;
								cargaCargaCompleta = false;
								loadingCargaMasiva = false;
								errorCargaMasiva = false;
								selectedUpdateFile = null;
							"
							>Close</v-btn
						>
					</v-col>
				</v-card-text>
				<v-card-text v-if="cargaCargaCompleta">
					<v-col cols="12" md="12" sm="12" class="text-center">
						<h4>Upload Successful</h4>
					</v-col>
					<v-col cols="12" md="12" sm="12" class="text-center">
						<v-btn
							depressed
							color="success"
							text
							@click="
								cargaMasiva = false;
								ficheroCarga = null;
								cargaCargaCompleta = false;
								loadingCargaMasiva = false;
								errorCargaMasiva = false;
								selectedUpdateFile = null;
							"
							>Close</v-btn
						>
					</v-col>
				</v-card-text>
				<v-card-text v-if="loadingCargaMasiva && !cargaCargaCompleta">
					<v-col cols="12" md="12" sm="12" class="text-center">
						<h4>Loading...</h4>
					</v-col>
					<v-col cols="12" md="12" sm="12" class="text-center">
						<v-progress-circular
							:size="75"
							color="green"
							indeterminate
						></v-progress-circular>
					</v-col>
				</v-card-text>
				<v-card-text
					v-if="
						!loadingCargaMasiva &&
						!cargaCargaCompleta &&
						!errorCargaMasiva
					"
					class="text-center"
				>
					<v-file-input
						v-model="ficheroCarga"
						accept=".csv"
						@change="cambioficheroCompras"
						show-size
						label="Fichero Carga .CSV"
					></v-file-input>

					<br />
					<br />
					<v-row>
						<v-spacer></v-spacer>
						<v-btn
							color="blue darken-1"
							text
							@click="
								cargaMasiva = false;
								ficheroCarga = null;
								cargaCargaCompleta = false;
								loadingCargaMasiva = false;
								errorCargaMasiva = false;
								selectedUpdateFile = null;
							"
							>Cancel</v-btn
						>
						<v-btn
							color="red darken-1"
							text
							@click="cargaMasivaPost"
							>Accept</v-btn
						>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogDelete" persistent max-width="450px">
			<v-card>
				<v-card-title style="word-break: break-word"
					>Are you sure that you want to delete this
					element?</v-card-title
				>
				<v-card-text>
					This action will be permanent and cannot be reversed
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="deleting"
						@click="closeDialog()"
						>Cancel</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="deleting"
						:loading="deleting"
						@click="deleteElement()"
						>Delete</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog" persistent max-width="450px">
			<v-card>
				<v-card-title
					>{{ editedItem.idGLAccount == null ? "Create" : "Edit" }} GL
					Account</v-card-title
				>
				<v-card-text>
					<v-container fluid>
						<v-row>
							<v-col cols="12">
								<v-text-field
									v-model.trim="editedItem.codigo"
									label="Codigo"
									clearable
									:error-messages="errorItem.errorCodigo"
									:disabled="saving"
									@focus="$set(errorItem, 'errorCodigo', null)"
								></v-text-field>
								<v-text-field
									v-model.trim="editedItem.nombre"
									label="Name"
									clearable
									:error-messages="errorItem.errorName"
									:disabled="saving"
									@focus="$set(errorItem, 'errorName', null)"
								></v-text-field>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="saving"
						@click="closeDialog()"
						>Cancel</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="saving"
						:loading="saving"
						@click="save()"
						>Save</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row v-if="permisoVista('tickets-catalogs','c')">
			<v-col cols="6">
				<v-btn color="primary" :disabled="loading" @click="showDialog()"
					>Create GL Account</v-btn
				>
			</v-col>
			<v-col cols="6" class="text-right">
				<download-btn color="blue darken-1" text @click="descargarFormatoCargaMasiva"
					label="Download Bulk<br>Upload Format"></download-btn>
				<v-btn text color="primary" class="mr-3" v-on="on" :disabled="loadingSubir || loadingDescargar"
					:loading="loadingSubir" @click="cargaMasiva = true">
					Bulk Upload of <br> GL Account
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" class="pt-0">
				<v-data-table
					:items="glAccounts"
					:headers="glAccountHeaders"
					:loading="loading"
					loading-text="Loading..."
				>
				<template v-slot:[`item.codigo`]="{ item }">
					<p v-if="item.codigo == null || item.codigo == '' ">N/D</p>
					<p v-else>{{ item.codigo }}</p>
				</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-tooltip v-if="permisoVista('tickets-catalogs','u')" bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									class="mr-2"
									:disabled="loading"
									@click="showDialog(item)"
								>
									mdi-pencil
								</v-icon>
							</template>
							<span class="white--text">Edit</span>
						</v-tooltip>
						<v-tooltip v-if="permisoVista('tickets-catalogs','d')" bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									class="mr-2"
									:disabled="loading"
									@click="showDialog(item, true)"
								>
									delete
								</v-icon>
							</template>
							<span class="white--text">Delete</span>
						</v-tooltip>
					</template>
					<template v-slot:no-data> No data available </template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import axios from "axios";
import moment from 'moment';
import { mapState } from 'vuex';
export default {
	data() {
		return {
			cargaMasiva: false,
			ficheroCarga: null,
			cargaCargaCompleta: false,
			loadingCargaMasiva: false,
			errorCargaMasiva: false,
			selectedUpdateFile: null,
			deleting: false,
			dialogDelete: false,
			saving: false,
			dialog: false,
			errorItem: {
				errorName: null,
				errorCodigo: null
			},
			editedItem: {
				idGLAccount: null,
				nombre: null,
				codigo: null
			},
			defaultEditedItem: {
				idGLAccount: null,
				nombre: null,
				codigo: null
			},
			glAccounts: [],
			glAccountHeaders: [
				{
					text: "Name",
					align: "center",
					value: "nombre",
				},
				{
					text: "Codigo",
					align: "center",
					value: "codigo",
				},
				{
					text: "Actions",
					align: "center",
					value: "actions",
					width: "5%",
				},
			],
			loading: false,
		};
	},
	mounted() {
		this.initialize();
	},
	computed:{
		...mapState(["token"])
		/* ssss */
	},
	methods: {
		deleteElement() {
			this.deleting = true;

			axios
				.put(`/GLAccount/Desactivar/${this.editedItem.idGLAccount}`)
				.then(() => {
					this.deleting = false;
					this.closeDialog();
					this.initialize();
				})
				.catch((error) => {
					this.deleting = false;
					console.log(error);
				});
		},
		resetErrors() {
			Object.keys(this.errorItem).forEach(
				(key) => (this.errorItem[key] = null)
			);
		},
		validate() {
			this.resetErrors();

			if (!this.editedItem.nombre) this.errorItem.errorName = "Required";

			return Object.values(this.errorItem).every(
				(value) => value == null
			);
		},
		save() {
			if (!this.validate()) return;

			this.saving = true;

			axios({
				url:
					this.editedItem.idGLAccount == null
						? "/GLAccount/Crear"
						: `/GLAccount/Actualizar/${this.editedItem.idGLAccount}`,
				method: this.editedItem.idGLAccount == null ? "POST" : "PUT",
				data: this.editedItem,
			})
				.then(() => {
					this.saving = false;
					this.closeDialog();
					this.initialize();
				})
				.catch((error) => {
					console.log(error);
					this.saving = false;
					if (error.response?.status == 409) {
						this.errorItem.errorName = "Already exists";
					}
				});
		},
		closeDialog() {
			this.dialog = false;
			this.dialogDelete = false;

			setTimeout(() => {
				this.editedItem = Object.assign({}, this.defaultEditedItem);
				this.resetErrors();
			}, 200);
		},
		showDialog(item = null, modeDelete = false) {
			this.editedItem = Object.assign({}, item || this.defaultEditedItem);

			if (modeDelete) this.dialogDelete = true;
			else this.dialog = true;
		},
		initialize() {
			this.getGLAccounts();
		},
		getGLAccounts() {
			this.loading = true;

			axios
				.get("/GLAccount/GetGLAccount")
				.then((response) => {
					this.glAccounts = response.data;
				})
				.catch((error) => {
					this.glAccounts = [];
					console.log(error);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		descargarFormatoCargaMasiva() {
			var datetime = moment().format("YYYYMMDDHHmmss");
			this.$utils.axios.downloadFile(
				"/GLAccount/CsvFormatGlAccounts?access_token=" +
				this.token,
				"GET",
				"Bulk-Upload-Format-for-General-Ledger-Accounts-" + datetime + ".csv",
				"text/csv"
			);
		},
		cargaMasivaPost() {
			this.loadingCargaMasiva = true;
			if (this.ficheroCarga !== null && this.ficheroCarga !== undefined) {
				let formData = new FormData();
				formData.append("formFile", this.ficheroCarga);
				axios
					.post("/GLAccount/CsvCostGlAccounts", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					})
					.then(() => {
						this.cargaCargaCompleta = true;
						this.loadingCargaMasiva = false;
						this.initialize();
						
					})
					.catch((error) => {
						console.log(error);
						this.errorCargaMasiva = true;
						this.loadingCargaMasiva = false;
					});
			}
		},
		cambioFicheroCompras(event) {
			if (typeof event === undefined || event === null)
				this.ficheroCarga = null;
			else this.ficheroCarga = event;
		},
	},
};
</script>