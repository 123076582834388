<template>
	<div>
		<v-tabs v-model="tab" background-color="transparent" grow centered>
			<v-tab
				href="#tab-1"
				@click="updateDatosDiarios()"
			>
				Tabla de Datos Diarios
			</v-tab>

			<v-tab
				href="#tab-2"
				@click="updateGraficas()"
				style="width: 200px"
			>
				Gráficas
			</v-tab>

			<v-tab
				href="#tab-3"
			>
				Reporte de Líneas Críticas
			</v-tab>

			<v-tab
				href="#tab-4"
			>
				Líneas del Día en Riesgo
			</v-tab>

			<v-tab
				href="#tab-5"
				@click="updateConfiguracionEmails"
			>
				Emails para Notificar
			</v-tab>

			<v-tab
				href="#tab-6"
				@click="updateConfiguracionLineas"
			>
				Configuración de Líneas
			</v-tab>

			<v-tabs-items v-model="tab" touchless>
				<v-tab-item  :key="1" :value="'tab-1'">
					<v-card flat>
						<v-card-text>
							<DatosDiariosMMCall ref="datosDiarios" />
						</v-card-text>
					</v-card>
				</v-tab-item>

				<v-tab-item  :key="2" :value="'tab-2'">
					<v-card flat>
						<v-card-text>
							<ListadoGraficas ref="listGraphs" />
						</v-card-text>
					</v-card>
				</v-tab-item>

				<v-tab-item  :key="3" :value="'tab-3'">
					<v-card flat>
						<v-card-text>
							<ReporteLineasCriticas ref="lineasCriticas" />
						</v-card-text>
					</v-card>
				</v-tab-item>

				<v-tab-item  :key="4" :value="'tab-4'">
					<v-card flat>
						<v-card-text>
							<ReporteLineasDiaEnRiesgo ref="lineasDiaEnRiesgo" />
						</v-card-text>
					</v-card>
				</v-tab-item>

				<v-tab-item  :key="5" :value="'tab-5'">
					<v-card flat>
						<v-card-text>
							<EmailsParaNotificar ref="configemails" />
						</v-card-text>
					</v-card>
				</v-tab-item>

				<v-tab-item  :key="6" :value="'tab-6'">
					<v-card flat>
						<v-card-text>
							<ConfiguracionLineas ref="configlineas" />
						</v-card-text>
					</v-card>
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>
	</div>
</template>


<script>
import ConfiguracionLineas from './ListadoReportesMMCall/ConfiguracionLineas.vue';
import DatosDiariosMMCall from './ListadoReportesMMCall/DatosDiariosMMCall.vue';
import EmailsParaNotificar from './ListadoReportesMMCall/EmailsParaNotificar.vue';
import ListadoGraficas from './ListadoReportesMMCall/ListadoGraficas.vue';
import ReporteLineasCriticas from './ListadoReportesMMCall/ReporteLineasCriticasMMCall.vue'
import ReporteLineasDiaEnRiesgo from './ListadoReportesMMCall/ReporteLineasDiaEnRiesgo.vue'

export default {
	components: {
		DatosDiariosMMCall,
		ReporteLineasCriticas,
		ConfiguracionLineas,
		EmailsParaNotificar,
		ReporteLineasDiaEnRiesgo,
		ListadoGraficas
	},
    data(){
        return {
		    tab: 0,
        }
    },
    methods:{
		updateGraficas(){
			if(typeof this.$refs.listGraphs === 'undefined') return;
        	this.$refs.listGraphs.initialize();
		},
		updateConfiguracionEmails(){
			if(typeof this.$refs.configemails === 'undefined') return;
        	this.$refs.configemails.initialize();
		},
		updateConfiguracionLineas(){
			if(typeof this.$refs.configlineas === 'undefined') return;
        	this.$refs.configlineas.initialize();
		},
    	initialize() {
    		this.updateDatosDiarios();
			},
      updateDatosDiarios(){
        if(typeof this.$refs.datosDiarios === 'undefined') return;
        this.$refs.datosDiarios.initialize();
      },
			updateDatosDiarios(){
        if(typeof this.$refs.lineasCriticas === 'undefined') return;
        this.$refs.lineasCriticas.initialize();
      }
    }
}
</script>