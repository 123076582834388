<template>
  <v-col cols="12" class="px-8" v-if="permisoVista('oee-reporte','r')">
    <v-row>
      <v-col cols="6" md="6" sm="6" class="align-self-end">
        <datePicker
          v-model="inicio"
          format="YYYY-MM-DD"
          :maxDate="fin"
          label="Desde"
          clearable
          maxToday
          @input="getEstadisticas"
        ></datePicker>
      </v-col>
      <v-col cols="6" md="6" sm="6" class="align-self-end">
        <datePicker
          v-model="fin"
          format="YYYY-MM-DD"
          :minDate="inicio"
          label="Hasta"
          clearable
          maxToday
          @input="getEstadisticas"
        ></datePicker>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="5" md="5" sm="5">
        <v-autocomplete
          v-model="idsAreas"
          label="Áreas"
          :items="areas"
          item-value="idArea"
          item-text="nombre"
          :loading="loadingAreas"
          multiple
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col cols="5" md="5" sm="5">
        <v-autocomplete
          v-model="idsMaquinaIns"
          label="Máquinas Instancias"
          :item-text="nombreMaquinaIns"
          item-value="idMaquinaIns"
          :items="maquinasFiltradas"
          :loading="loadingMaquinas"
          multiple
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col cols="2" md="2" sm="2">
        <v-btn color="primary" :disabled="inicio == null || fin == null" @click="getData">Calcular OEE</v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      disable-sort
      hide-default-footer
      id="oee"
    >
      <template v-slot:item="{ item, index }">
        <tr>
          <td class="text-center font-weight-bold">{{item.concepto}}</td>
          <td class="text-center">{{(item.global * 100).toFixed(2)}}</td>
          <td class="text-center">
            <v-icon :color="colorIcono(item.global,item.promedio)">{{obtenerIcono(item.global,item.promedio)}}</v-icon>
            {{(item.promedio * 100).toFixed(2)}}
          </td>
          <td class="text-center" v-for="id in item.idsMaquinaIns" :key="'maquina'+id">
            <v-icon :color="colorIcono(item.global,item['maquina'+id])">{{obtenerIcono(item.global,item['maquina'+id])}}</v-icon>
            {{(item['maquina'+id] * 100).toFixed(2)}}
            <v-icon v-if="index == 1 && item['maquinaOps'+id] > 0" color="yellow">mdi-alert</v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import axios from "axios"

export default {
  data:() => ({
    headers: [],
    items:[],
    loading: false,
    inicio:null,
    fin:null,
    areas: [],
    loadingAreas:false,
    idsAreas:[],
    maquinas:[],
    loadingMaquinas: false,
    idsMaquinaIns:[],
  }),
  mounted(){
    this.getAreas();
    this.getMaquinas();
  },
  computed:{
    maquinasFiltradas(){
      if(this.$utils.isValid(this.idsAreas) && this.idsAreas.length > 0) return this.maquinas.filter(m => this.idsAreas.indexOf(m.idArea) >= 0)
      else return this.maquinas;
    },
  },
  methods:{
    nombreMaquinaIns: (item) => `${item.marca} ${item.modelo} - [${item.nombre}]`,
    getAreas(){
      this.loadingAreas = true;
      axios
      .get("/Areas")
      .then(response => {
        this.loadingAreas = false;
        this.areas = response.data.filter(a => a.idArea > 1);
      })
    },
    getMaquinas(){
      this.loadingMaquinas = true;
      axios
				.get("/MaquinaInstancias")
				.then((response) => {
          this.loadingMaquinas = false;
					this.maquinas = response.data.filter(a => a.idArea > 1);
				})
				.catch((error) => {
					console.log(error);
				});
    },
    getData(){
      this.loading = true;
      
      var url = `/OEEReporte/ReporteOEE?FechaInicio=${this.inicio}&FechaFin=${this.fin}`;

      if(this.$utils.isValid(this.idsMaquinaIns) && this.idsMaquinaIns.length > 0){
        this.idsMaquinaIns.forEach(
					(x) => (url += `&IdsMaquinasInstancias=${x}`)
				);
      }
      else{
        this.maquinasFiltradas.forEach(
					(x) => (url += `&IdsMaquinasInstancias=${x.idMaquinaIns}`)
				);
      }
      
      axios
      .get(url)
      .then(response =>{
        this.loading = false;
        this.generateTable(response.data);
      })
      .catch(error => {
        this.loading = false;
        console.log(error);
      })
    },
    generateTable(data){
      this.headers = [];
      this.headers.push({
        text: "Concepto",
        value: "concepto",
        width: "125px",
        align: "center",
        
      });
      this.headers.push({
        text: "Global",
        value: "global",
        width: "125px",
        align: "center",
      });
      this.headers.push({
        text: "Promedio",
        value: "promedio",
        width: "125px",
        align: "center",
      });

      this.items = [];

      this.items.push({
        concepto: "Disponibilidad",
        global: data.global.disponibilidad,
        promedio: data.promedio.disponibilidad,
        idsMaquinaIns: data.maquinas.map(x => x.idMaquinaIns),
      });

      this.items.push({
        concepto: "Rendimiento",
        global: data.global.rendimiento,
        promedio: data.promedio.rendimiento,
        idsMaquinaIns: data.maquinas.map(x => x.idMaquinaIns),
      });

      this.items.push({
        concepto: "Calidad",
        global: data.global.calidad,
        promedio: data.promedio.calidad,
        idsMaquinaIns: data.maquinas.map(x => x.idMaquinaIns),
      });

      this.items.push({
        concepto: "OEE",
        global: data.global.disponibilidad * data.global.rendimiento * data.global.calidad,
        promedio: data.promedio.disponibilidad * data.promedio.rendimiento * data.promedio.calidad,
        idsMaquinaIns: data.maquinas.map(x => x.idMaquinaIns),
      });

      data.maquinas.forEach(maquina => {
        this.headers.push({
          text: `${maquina.marca} ${maquina.modelo} [${maquina.codigo}]`,
          value: `maquina${maquina.idMaquinaIns}`,
          width: "150px",
          align: "center",
        });

        this.items[0]['maquina'+maquina.idMaquinaIns] = maquina.disponibilidad;
        this.items[1]['maquina'+maquina.idMaquinaIns] =  maquina.rendimiento;
        this.items[1]['maquinaOps'+maquina.idMaquinaIns] = maquina.idsOperacionesRealesAlerta.length;
        this.items[2]['maquina'+maquina.idMaquinaIns] = maquina.calidad;
        this.items[3]['maquina'+maquina.idMaquinaIns] = maquina.disponibilidad * maquina.rendimiento * maquina.calidad;

      });

      
    },
    colorIcono(global,valor){
      var g = parseFloat((global * 100).toFixed(2));
      var v = parseFloat((valor * 100).toFixed(2));

      if(v > g) return 'green';
      else if(v < g) return 'red';
      else return '';
    },
    obtenerIcono(global,valor){
      var g = parseFloat((global * 100).toFixed(2));
      var v = parseFloat((valor * 100).toFixed(2));

      if(v > g) return 'mdi-arrow-up';
      else if(v < g) return 'mdi-arrow-down';
      else return 'mdi-equal';
    }
  }

}
</script>


<style>
  #oee > div > table > tbody > tr > td:nth-child(1), 
  #oee > div > table > thead > tr > th:nth-child(1) {
    position: sticky !important; 
    position: -webkit-sticky !important; 
    left: 0; 
    z-index: 7;
    background: white;
  }

  #oee > div > table > tbody > tr > td:nth-child(2), 
  #oee > div > table > thead > tr > th:nth-child(2) {
    position: sticky !important; 
    position: -webkit-sticky !important; 
    left: 125px; 
    z-index: 7;
    background: white;
  }

  #oee > div > table > tbody > tr > td:nth-child(3), 
  #oee > div > table > thead > tr > th:nth-child(3) {
    position: sticky !important; 
    position: -webkit-sticky !important; 
    left: 250px; 
    z-index: 7;
    background: white;
  }

  #oee > div > table > thead > tr > th:nth-child(1),
  #oee > div > table > thead > tr > th:nth-child(2),
  #oee > div > table > thead > tr > th:nth-child(3) {
    z-index: 8;
  }
</style>