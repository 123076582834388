import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.value,"persistent":"","max-width":"500px"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Configuración MRO")]),_c(VCardText,[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c('p',{staticStyle:{"font-size":"16px !important"}},[_vm._v("Días mínimos entre Fecha de Solicitud y Necesidad")]),_c('number-input',{attrs:{"size":"small","controls":"","inline":"","min":0,"center":""},model:{value:(_vm.minDays),callback:function ($$v) {_vm.minDays=$$v},expression:"minDays"}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"success","disabled":_vm.saving},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"text":"","color":"error","disabled":_vm.minDays < 0 || _vm.saving,"loading":_vm.saving},on:{"click":function($event){return _vm.save()}}},[_vm._v("Guardar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }