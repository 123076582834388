<template>
	<v-col cols="12">
		<v-dialog v-model="dialogDelete" persistent max-width="40%">
			<v-card>
				<v-card-text>
					<p class="text-h5 pt-4 font-weight-medium text--primary">
						¿Está seguro que desea eliminar este Bloqueo de Máquina?
					</p>
					<p class="subtitle-1 text--primary">
						Esta acción no se puede revertir y será permanente.
					</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="deleting"
						@click="cerrarDialog()"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="deleting"
						:loading="deleting"
						@click="deleteBloqueoMaquina()"
						>Eliminar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog" persistent max-width="500px">
			<v-card>
				<v-card-title
					>{{
						editedItem.id == 0 ? "Crear" : "Editar"
					}}
					Bloqueo de Máquina</v-card-title
				>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12" class="pl-2 mb-3">
								<VueCtkDateTimePicker
									id="fechaBloqueo"
									v-model="editedItem.fecha"
									label="Fecha"
									:max-date="fechaMaxima"
									format="YYYY-MM-DD HH:mm"
									formatted="YYYY-MM-DD HH:mm"
									noHeader
									overlay
									buttonNowTranslation="Ahora"
									:error="errorFecha!=''"
                                    @input="resetErrorFecha"
								/>
								<p v-if="errorFecha" class="red--text pt-1 mb-0" style="font-size: 12px !important">{{
									errorFecha
								}}</p>
							</v-col>
							<v-col cols="12">
								<v-autocomplete
									v-model="editedItem.idPersona"
									:items="operadores"
									item-value="idPersona"
									:item-text="nombrePersona"
									label="Operador"
									clearable
									:error-messages="errorOperador"
									@focus="errorOperador = ''"
								>
								</v-autocomplete>
							</v-col>
							<v-col cols="12">
								<v-autocomplete
									v-model="editedItem.idsMaquinas"
									:items="maquinasIns"
									item-value="idMaquinaIns"
									:item-text="nombreMaquina"
									label="Máquina(s)"
									clearable
									multiple
									chips
									deletable-chips
									:error-messages="errorMaquina"
									@focus="errorMaquina = ''"
								>
								</v-autocomplete>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="saving"
						@click="cerrarDialog()"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="saving"
						:loading="saving"
						@click="saveBloqueoMaquina()"
						>Aceptar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col cols="4" class="py-0 pt-2">
				<VueCtkDateTimePicker
					id="filtroFechaInicio"
					v-model="filtros.inicio"
					label="Fecha Inicio"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="getBloqueosAction(1)"
				/>
			</v-col>
			<v-col cols="4" class="py-0 pt-2">
				<VueCtkDateTimePicker
					id="filtroFechaFin"
					v-model="filtros.fin"
					label="Fecha Fin"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="getBloqueosAction(1)"
				/>
			</v-col>
			<v-col cols="4" class="py-0">
				<v-autocomplete
					v-model="filtros.idPersona"
					:items="operadores"
					item-value="idPersona"
					:item-text="nombrePersona"
					label="Operador"
					clearable
					hide-details
					@change="getBloqueosAction(1)"
				>
				</v-autocomplete>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="4">
				<v-btn
					color="primary"
					:disabled="loading"
					@click="showDialog()"
				>
					Nuevo Bloqueo de Máquina
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
					:items="bloqueosMaquina"
					:headers="bloqueosMaquinaHeaders"
					:loading="loading"
					loading-text="Cargando... Espere, Por Favor."
                    :server-items-length="bloqueos_total"
                    :items-per-page="bloqueos_items_per_page"
                    :page="bloqueos_actual_page"
                    @update:page="getBloqueosAction"
                    @update:items-per-page="(ipp) => (bloqueos_items_per_page = ipp)"
                    :footer-props="{
                        disableItemsPerPage: true,
                        disablePagination: this.loading,
                    }"
                    :disable-sort="true"
                    :disable-pagination="loading"
				>
					<template v-slot:item.fecha="{ item }">
						{{ formatFecha(item.fecha) }}
					</template>
					<template v-slot:item.operador="{ item }">
						{{ nombrePersona(item) }}
					</template>
					<template v-slot:item.maquinas="{ item }">
						<v-simple-table dense class="transparent">
							<template v-slot:default>
								<tbody>
									<tr class="transparent" v-for="maquina in item.maquinas" :key="`maquina-${maquina.idMaquinaBloqueada}`">
										<td>{{nombreMaquina(maquina)}}</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</template>
					<template v-slot:item.actions="{ item }">
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									class="mr-2"
									@click="showDialogEdit(item)"
								>
									edit
								</v-icon>
							</template>
							<span class="white--text">Editar</span>
						</v-tooltip>
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									class="mr-2"
									@click="showDialogDelete(item)"
								>
									delete
								</v-icon>
							</template>
							<span class="white--text">Eliminar</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import axios from "axios";
import moment from 'moment';
export default {
	data() {
		return {
			filtros: {
				inicio: null,
				fin: null,
				idPersona: null
			},
			errorFecha: "",
			errorOperador: "",
			errorMaquina: "",
			maquinasIns: [],
			operadores: [],
			saving: false,
			dialogDelete: false,
			bloqueosMaquinaHeaders: [
				{
					text: "Fecha",
					align: "center",
					value: "fecha",
				},
				{
					text: "Operador",
					align: "center",
					value: "operador",
				},
				{
					text: "Máquinas Involucradas",
					align: "center",
					value: "maquinas",
				},
				{
					text: "Acciones",
					align: "center",
					value: "actions",
				},
			],
			editedItem: {
				id: 0,
				fecha: null,
				idPersona: null,
				idsMaquinas: [],
			},
			defaultEditedItem: {
				id: 0,
				fecha: null,
				idPersona: null,
				idsMaquinas: [],
			},
			loading: false,
			deleting: false,
			bloqueosMaquina: [],
			//Paginacion bloqueos(server side)
			bloqueos_total: null,
			bloqueos_items_per_page: 10,
			bloqueos_actual_page: 1,
			//
		};
	},
	computed:{
		fechaMaxima(){
			return moment().endOf('day');
		}
	},
	mounted() {
		this.initialize();
	},
	methods: {
		nombreMaquina: (maquina) =>
			`[${maquina.nombre}] - ${maquina.marca} ${maquina.modelo}`,
		nombrePersona: ({ nombre, paterno, materno }) =>
			`${nombre ? `${nombre} ` : ""}${paterno ? `${paterno} ` : ""}${
				materno ? `${materno} ` : ""
			}`.trim(),
		formatFecha(fecha){
			return moment(fecha).format("YYYY-MM-DD HH:mm");
		},
        resetErrorFecha(){
            if(this.editedItem.fecha!= null){
                this.errorFecha = '';
            }
        },
		validate() {
			this.errorFecha = "";
			this.errorOperador = "";
			this.errorMaquina = "";

			if (this.editedItem.fecha == null) {
				this.errorFecha = "Debe seleccionar una Fecha";
			}

			if (this.editedItem.idPersona == null) {
				this.errorOperador = "Debe seleccionar un Operador";
			}

			if (this.editedItem.idsMaquinas.length == 0) {
				this.errorMaquina = "Debe seleccionar al menos una Máquina";
			}

			return (
				this.errorFecha == "" &&
				this.errorOperador == "" &&
				this.errorMaquina == ""
			);
		},
		saveBloqueoMaquina() {
			if (!this.validate()) return;
			this.saving = true;
			axios({
				url: this.editedItem.id == 0
					? "/MaquinasBloqueadaFalta/CrearBloqueoMaquina"
					: `/MaquinasBloqueadaFalta/Actualizar/${this.editedItem.id}`,
				method: this.editedItem.id == 0
					? 'POST'
					: 'PUT',
				data: this.editedItem
			})
				.then(() => {
					this.saving = false;
					this.cerrarDialog();
                    this.initialize();
				})
				.catch((error) => {
					this.saving = false;
					console.log(error);
				});
		},
		getMaquinasIns() {
			axios
				.get("/MaquinaInstancias")
				.then((response) => {
					this.maquinasIns = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getOperadores() {
			axios
				.get("/Personas/Operadores")
				.then((response) => {
					this.operadores = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		showDialogEdit(item) {
			this.editedItem = { ...item };
			this.dialog = true;
		},
		showDialogDelete(item) {
			this.editedItem = { ...item };
			this.dialogDelete = true;
		},
		deleteBloqueoMaquina() {
			this.deleting = true;
			axios
				.put(`/MaquinasBloqueadaFalta/Eliminar/${this.editedItem.id}`)
				.then( () => {
					this.deleting = false;
					this.cerrarDialog();
					this.initialize();
				})
				.catch( error => {
					this.deleting = false;
					console.log(error);
				})
		},
		cerrarDialog() {
			this.dialog = false;
			this.dialogDelete = false;
			this.editedItem = { ...this.defaultEditedItem };
			this.errorFecha = "";
			this.errorOperador = "";
			this.errorMaquina = "";
		},
		initialize() {
			this.getOperadores();
			this.getMaquinasIns();
			this.getBloqueosAction(1);
		},
		showDialog() {
			this.editedItem = { ...this.defaultEditedItem };
			this.dialog = true;
		},
		getBloqueos() {
			return new Promise( (resolve,reject) => {
				axios
					.get("/MaquinasBloqueadaFalta/ListarMaquinasPersonasFalta",{
						params: {
							paginar: true,
							size: this.bloqueos_items_per_page,
							page: this.bloqueos_actual_page,
							inicio: this.filtros.inicio,
							fin: this.filtros.fin,
							idPersona: this.filtros.idPersona,
						}
					})
					.then(resolve)
					.catch(reject);
			});
		},
		getBloqueosAction(page){
			this.loading = true;
			this.bloqueos_actual_page = page;
			this.getBloqueos()
				.then( response => {
					this.bloqueosMaquina = response.data.result.map( item => ({
						...item,
						idsMaquinas: item.maquinas.map( maquina => maquina.idMaquina)
					}));
					this.bloqueos_total = response.data.total;
				})
				.catch( error => {
					console.log(error);
				})
				.finally( () => {
					this.loading = false;
				})
		}
	},
};
</script>