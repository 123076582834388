<template>
  <v-col cols="12">
    <v-snackbar
      v-model="alerta"
      top
      color="error"
    >
      Existe un Horario con las mismas características.
      <v-btn
        text
        @click="alerta = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
    <v-dialog
      v-model="dialog"
      max-width="800px"
      persistent
    >
      <v-card>
        <v-card-title v-if="editedItem.idMantenimientoPreventivo == -1">Agregar Mantenimiento Preventivo</v-card-title>
        <v-card-title v-else>Editar Mantenimiento Preventivo</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6" md="6" sm="6">
              <v-text-field
                v-model="editedItem.codigo"
                label="Código"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <div class="d-flex align-center">
              <div class="text-subtitle-1">
                Color:
              </div>
              <div style="width:15px;"></div>
              <v-swatches
                v-model="editedItem.color"
                swatches="text-advanced" 
              ></v-swatches>
              </div>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
              v-model="editedItem.nombre"
                label="Nombre"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-textarea
              v-model="editedItem.descripcion"
                label="Descripción"
                hide-details
                auto-grow
                rows="1"
              ></v-textarea>
            </v-col>
            <v-col cols="6" md="6" sm="6" class="mb-0 pb-0">
              <time-picker
                class="py-3"
                v-model="editedItem.duracion"
                label="Duración"
              ></time-picker>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-autocomplete
                v-model="editedItem.idMaquina"
                label="Máquina"
                :items="maquinas"
                :item-text="marcaModelo"
                item-value="idMaquina"
                @change="resetMaquinaIns"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>{{data.item.marca+' - '+data.item.modelo}}</v-list-item-title>
                    <v-list-item-subtitle>{{data.item.categoria}}</v-list-item-subtitle>
                    <v-list-item-content v-if="data.item.descripcionGeneral!=null">{{$utils.strings.truncate(data.item.descripcionGeneral,50)}}</v-list-item-content>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="6" md="6" sm="6" class="mt-0 pt-0">
              <v-autocomplete
                chips
                multiple
                deletable-chips
                v-model="editedItem.idsMaquinaIns"
                label="Instancias"
                :items="computedIns"
                item-text="nombre"
                item-value="idMaquinaIns"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="editedItem.idSubdepartamento"
                label="Subdepartamento"
                :items="subdepartamentos"
                :item-text="nombreSubdepto"
                item-value="idSubdepartamento"
                clearable
              ></v-autocomplete>
            </v-col>

          </v-row>
          <br>
          <v-row class="justify-center">
            <div class="headline" v-if="permisoVista('cat-mantenimientopreventivo','$')" >Costo {{editedItem.costo}}</div>
            <v-btn color="primary" block @click="openInsumos()">Insumos ({{editedItem.insumos.length}})</v-btn>
          </v-row>
          <br>
          <ListadoPorHacer ref="todo" :item="editedItem" />
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-data-table
                :items="editedItem.horarios"
                :headers="headers"
                :items-per-page="-1"
                hide-default-footer
              >
                <template v-slot:top>
                  <v-btn color="primary" small @click="$refs.horario.lanzar()">Nuevo Horario</v-btn>
                </template>
                <template v-slot:no-data>
                  No hay horarios registrados.
                </template>
                <template v-slot:[`item.enunciado`]="{ item }">
                  {{$refs.horario.leyenda(item)}}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  
                  <v-tooltip bottom color="black" class="white--text">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on"
                        small
                        @click="editHorario(item)"
                      >
                        edit
                      </v-icon>
                    </template>
                    <span class="white--text">Editar</span>
                  </v-tooltip>
                  
                  <v-tooltip bottom color="black" class="white--text">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on"
                        small
                        @click="deleteHorario(item)"
                      >
                        delete
                      </v-icon>
                    </template>
                    <span class="white--text">Eliminar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row>
            <ul>
              <li class="red--text" v-for="(error,idx) in errores" :key="'error'+idx">{{error}}</li>
            </ul>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="green" 
            text 
						:disabled="guardando"
            @click="closeMantenimiento"
            >Cancelar</v-btn
          >
          <v-btn
            color="red"
            text
            :loading="guardando"
						:disabled="guardando" 
            @click="guardarMantenimiento"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialog-insumos
      v-model="editedItem.insumos"
      :show="dialogInsumos"
      :name="editedItem.nombre"
      :duracion="editedItem.duracion"
      @close="closeInsumos"
      @update:price="updateCosto"
    ></dialog-insumos>
    <dialog-horario
      @create="newHorario($event)"
      @update="updateHorario($event)"
      ref="horario"
    >
    </dialog-horario>
    <v-dialog
      v-model="dialogEliminar"
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <v-card-title class="headline">¿Está seguro que desea eliminar este mantenimiento?</v-card-title>
          <v-card-text>Esta acción no se puede revertir y será permanente.</v-card-text>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeEliminarMantenimiento">Cancelar</v-btn>
          <v-btn color="red darken-1" text @click="runRemoverMantenimiento" :disabled="deleting" :loading="deleting">
            Eliminar
            <template v-slot:loader>
              <v-progress-circular
                indeterminate
                :width="2"
                :size="24"
                color="red"
              ></v-progress-circular>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogAtemporal"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <v-card-title class="headline">Mantenimiento Atemporal</v-card-title>
          <v-card-text style="min-height: 280px;">
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <datePicker
                  v-model="atemporalInfo.inicio"
                  format="YYYY-MM-DD"
                  label="Inicio del Mantenimiento"
                  clearable
                ></datePicker>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-autocomplete
                  v-model="atemporalInfo.instancias"
                  :items="instanciasFiltradas()"
                  item-value="idMaquinaIns"
                  item-text="nombre"
                  label="Máquina Instancia"
                  clearable
                  hide-details
                  multiple
                  chips
                  deletable-chips
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <br>
            <br>
            <div>
              <ul>
                <li class="red--text" v-for="(error,idx) in errores" :key="'error-'+idx">{{error}}</li>
              </ul>
            </div>
            <div class="headline text-center">
              {{labelAtemporal}}
            </div>
          </v-card-text>
        </v-card-title>
        <v-card-actions style="min-height: 130px;">
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeLaunchMantenimiento">Cancelar</v-btn>
          <v-btn color="red darken-1" text @click="mantenimientoAtemporal" :disabled="saving" :loading="saving">
            Guardar
            <template v-slot:loader>
              <v-progress-circular
                indeterminate
                :width="2"
                :size="24"
                color="red"
              ></v-progress-circular>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Recomendaciones ref="recomen" />
    <v-data-table
      :items="horarios"
      :headers="headersHorario"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
    >
      <template v-slot:[`item.costo`] ="{ item }">
        $ {{item.costo.toFixed(2)}}
      </template>
      <template v-slot:top>
        <v-btn v-if="permisoVista('cat-mantenimientopreventivo','c')" color="primary" @click="dialog = true">Agregar Mantenimiento Preventivo</v-btn>
      </template>
      <template 
        v-slot:[`item.actions`] ="{ item }"
      >
        <v-tooltip
          v-if="permisoVista('cat-mantenimientopreventivo','c')"
          
          bottom
          color="black"
          class="white--text"
        >
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          
          small
          @click="launchMantenimiento(item)"
        >
          mdi-play
        </v-icon>
         </template>
          <span class="white--text">Programar Mantenimiento Atemporal</span>
        </v-tooltip>
        <v-tooltip
          v-if="permisoVista('cat-mantenimientopreventivo','u')"
           
          bottom color="black"
          class="white--text"
        >
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          small
          @click="editMantenimiento(item)"
        >
          edit
        </v-icon>
         </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
        <v-tooltip
					v-if="permisoVista('cat-mantenimientopreventivo','r')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
              :disabled="
								item.recomendaciones == null ||
								item.recomendaciones == undefined ||
                item.recomendaciones.length == 0
							"
							v-on="on"
							small
							@click="showRecomendaciones(item)"
						>
							mdi-eye
						</v-icon>
					</template>
					<span class="white--text">Ver Recomendaciones</span>
				</v-tooltip>
        <v-tooltip
          v-if="permisoVista('cat-mantenimientopreventivo','d')"
          
          bottom
          color="black"
          class="white--text"
        >
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          small
          @click="removeMantenimiento(item)"
        >
          delete
        </v-icon>
         </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import timepicker from '@/components/TimePicker'
import insumos from '../Insumos.vue'
import horario from '../Horario.vue'
import axios from "axios"
import VSwatches from 'vue-swatches'
import moment from 'moment'
import ListadoPorHacer from './ListadoPorHacer.vue'
import Recomendaciones from './Recomendaciones.vue'

export default {
  components:{
    'time-picker':timepicker,
    'dialog-insumos':insumos,
    'dialog-horario': horario,
    VSwatches,
    ListadoPorHacer,
    Recomendaciones,
  },
  data: () => ({
    subdepartamentos: [],
    guardando: false,
    dialog: false,
    dialogInsumos:false,
    editedItem:{
      idMantenimientoPreventivo: -1,
      idSubdepartamento: null,
      codigo:'',
      nombre:'',
      descripcion:'',
      duracion:0,
      idMaquina:0,
      costo:"$0.00",
      insumos:[],
      horarios:[],
      comprobaciones: [],
      color:"#1976D2",
      idsMaquinaIns: []
    },
    defaultEditeditem:{
      idMantenimientoPreventivo: -1,
      idSubdepartamento: null,
      codigo:'',
      nombre:'',
      descripcion:'',
      duracion:0,
      idMaquina:0,
      costo:"$0.00",
      insumos:[],
      horarios:[],
      comprobaciones: [],
      color:"#1976D2",
      idsMaquinaIns: []
    },
    maquinas:[],
    headers:[
      {
        text: "Enunciado",
        align: "left",
        sortable: false,
        value: "enunciado",
        width: '80%,'
      },
      {
        text: "Acciones",
        align: "center",
        sortable: false,
        value: "actions",
        width: '20%,'
      },
    ],
    alerta:false,
    errores:[],
    horarios:[],
    loading:false,
    headersHorario:[
      {
        text: "Código",
        align: "left",
        sortable: false,
        value: "codigo",
        width: '22%,'
      },
      {
        text: "Nombre",
        align: "left",
        sortable: false,
        value: "nombre",
        width: '22%,'
      },
      {
        text: "Máquina",
        align: "left",
        sortable: false,
        value: "maquina",
        width: '22%,'
      },
      {
        text: "Costo",
        align: "left",
        sortable: false,
        value: "costo",
        width: '22%,'
      },
      {
        text: "Acciones",
        align: "center",
        sortable: false,
        value: "actions",
        width: '12%,'
      },
    ],
    dialogEliminar:false,
    eliminarItem:{
      idMantenimientoPreventivo: -1,
      codigo:'',
      nombre:'',
      descripcion:'',
      duracion:0,
      idMaquina:0,
      costo:"$0.00",
      insumos:[],
      horarios:[],
      color:"#1976D2",
      idsMaquinaIns: []
    },
    deleting:false,
    dialogAtemporal:false,
    instancias:[],
    atemporalItem:{
      idMantenimientoPreventivo: -1,
      codigo:'',
      nombre:'',
      descripcion:'',
      duracion:0,
      idMaquina:0,
      costo:"$0.00",
      insumos:[],
      horarios:[],
      color:"#1976D2",
      idsMaquinaIns: []
    },
    atemporalInfo:{
      inicio:null,
      instancias:[],
    },
    saving: false,
  }),
  created(){
    this.initialize();
    if(!this.permisoVista('cat-mantenimientopreventivo','$')) this.headersHorario = this.headersHorario.filter(x => x.value != 'costo');
  },
  computed:{
    computedIns(){
      const ins = this.instancias.filter( instancia =>  instancia.idMaquina === this.editedItem.idMaquina)
      return ins;
    },
    plural(){
      return (this.$utils.isValid(this.atemporalInfo.instancias) && this.atemporalInfo.instancias.length > 1);
    },
    maquinaLabel(){
      var maquina = this.maquinas.find(maquina => maquina.idMaquina == this.atemporalItem.idMaquina);
      return (this.$utils.isValid(maquina))?maquina.marca+" - "+maquina.modelo:null;
    },
    labelAtemporal(){
      if(this.$utils.isValid(this.atemporalInfo.inicio) && this.$utils.isValid(this.atemporalInfo.instancias) && this.atemporalInfo.instancias.length > 0){
        var localLocale = moment(this.atemporalInfo.inicio, "YYYY-MM-DD");
        localLocale.locale('es');
        return "Mantenimiento programado para " +
        localLocale.format('[el día] dddd DD [de] MMMM  [del] YYYY');
      }
      else return null;
    }
  },
  methods:{
    nombreSubdepto: ({ codigo, nombre }) =>
      codigo ? `[${codigo}] - ${nombre}`.trim() : nombre.trim(),
    showRecomendaciones(item){
			this.$refs.recomen.showDialogRecomendaciones(item);
		},
    guardarMantenimiento(){
      // this.$refs.todo.togglePanel(null);
			this.save();
    },
    resetMaquinaIns(){
      this.editedItem.idsMaquinaIns =[];
    },
    marcaModelo: item => item.marca +' - '+item.modelo,
    initialize(){
      this.getSubdepartamentos();
      this.getMaquinas();
      this.getMantenimientos();
      this.getInstancias();
    },
    getSubdepartamentos(){
      axios
        .get('/Subdepartamento')
        .then( response => {
          this.subdepartamentos = response.data;
        })
        .catch(console.log)
    },
    openInsumos(){
      this.dialog = false;
      this.dialogInsumos = true;
    },
    closeInsumos(){
      this.dialogInsumos = false;
      this.dialog = true;
    },
    updateCosto(event){
      if(this.dialogInsumos) this.editedItem.costo = event;
    },
    closeMantenimiento(){
      this.dialog = false;
      this.editedItem = JSON.parse(JSON.stringify(this.defaultEditeditem));
      this.errores = [];
      this.getMantenimientos();
			this.$refs.todo.togglePanel(0);
    },
    getMaquinas(){
      axios
      .get("/Maquinas")
      .then(response =>  {
        this.maquinas = response.data.filter(function(el) {
          return (el.esMaquina == true);
        });
      })
      .catch(error => {
        console.log(error);
      });
    },
    getInstancias(){
      axios
      .get("/MaquinaInstancias")
      .then(response =>  {
        this.instancias = response.data;
      })
      .catch(error => {
        console.log(error);
      });
    },
    getMantenimientos(){
      this.loading = true;
      axios
      .get("/Mantenimientos/Preventivos")
      .then(response =>    {
        this.loading = false;
        this.horarios = response.data;
      })
      .catch(error => {
        this.loading = false;
        console.log(error);
      })
    },
    editMantenimiento(item){
      item.comprobaciones = item.comprobaciones.map( comprobacion => {
				if ( Array.isArray(comprobacion.recursos) ){
					let idsDocumentos = [];
					let links = [];
	
					comprobacion.recursos.forEach(recurso => {
						if (recurso.idDocumento != null){
							idsDocumentos.push({
								nombre: recurso.documento,
								idDocumento: recurso.idDocumento
							});
						}
	
						if( recurso.link != null ){
							links.push({
								titulo: recurso.tituloLink,
								url: recurso.link
							});
						}
					});
	
					comprobacion.recursos = {
						idsDocumentos,
						links,
					}
				}
				return comprobacion;
			});
      this.editedItem = Object.assign({}, item);
      this.editedItem.insumos = item.insumos.map(i => {
        return Object.assign({},i);
      });
      this.editedItem.horarios = item.horarios.map(h => {
        return Object.assign({},h);
      });
      this.dialog = true;
    },
    removeMantenimiento(item){
      this.dialogEliminar = true;
      this.eliminarItem = item;
    },
    runRemoverMantenimiento(){
      this.deleting = true;
      axios
      .put("/Mantenimientos/DesactivarPreventivo/"+this.eliminarItem.idMantenimientoPreventivo)
      .then(response => {
        this.deleting = false;
        this.closeEliminarMantenimiento();
        this.getMantenimientos();
      })
      .catch(error => {
        this.deleting = false;
        console.log(error);
      })
    },
    closeEliminarMantenimiento(){
      this.dialogEliminar = false;
      this.eliminarItem = Object.assign({}, this.defaultEditeditem);
    },
    launchMantenimiento(item){
      this.dialogAtemporal = true;
      this.atemporalItem = item;
    },
    closeLaunchMantenimiento(){
      this.dialogAtemporal = false;
      this.atemporalItem = Object.assign({}, this.defaultEditeditem);
      this.atemporalInfo.inicio = null;
      this.atemporalInfo.instancias = [];
    },
    instanciasFiltradas(){
      return this.instancias.filter(instancia => {
        return instancia.idMaquina == this.atemporalItem.idMaquina;
      })
    },
    validateAtemporal(){
      this.errores = [];
      if(!this.$utils.isValid(this.atemporalInfo.inicio)){
        this.errores.push("Falta Indicar una Fecha de Inicio del Mantenimiento");
      }

      if(!this.$utils.isValid(this.atemporalInfo.instancias) || this.atemporalInfo.instancias <= 0){
        this.errores.push("Falta Indicar la(s) instancia(s) a las que se realizara el mantenimiento");
      }

      return (this.errores.length == 0);
    },
    mantenimientoAtemporal(){
      if(this.validateAtemporal()){
        var obj = {
          IdMantenimientoPreventivo: this.atemporalItem.idMantenimientoPreventivo,
          IdsMaquinasInstancias: this.atemporalInfo.instancias,
          InicioMantenimiento: this.atemporalInfo.inicio
        }

        this.saving = true;
        
        axios
        .post("/Mantenimientos/PreventivoAtemporal",obj)
        .then(() => {
          this.saving = false;
          this.closeLaunchMantenimiento();
        })
        .catch(error => {
          this.saving = false;
        })
      }
    },
    updateHorario($event){
      var posExist = this.editedItem.horarios.findIndex(h => {
        return this.$utils.arrays.equals(h.diasSemana,$event.diasSemana) &&
          this.$utils.arrays.equals(h.meses,$event.meses) &&
          this.$utils.arrays.equals(h.diasMes,$event.diasMes) &&
          h.horaMinuto == $event.horaMinuto;
      });
      var pos = this.editedItem.horarios.findIndex(h => h.idMantenimientoPreventivoHorario == $event.idMantenimientoPreventivoHorario);
      if(posExist >= 0 && pos != posExist){
        this.alerta = true;
        return;
      }
      if(pos >= 0){
        this.editedItem.horarios[pos] = null;
        this.editedItem.horarios[pos] = $event;
      }
      else{
        this.editedItem.horarios.push($event);
      }
    },
    editHorario(item){
      this.$refs.horario.lanzar(item);
    },
    deleteHorario(item){
      var pos = this.editedItem.horarios.indexOf(item);
      if(pos >= 0)this.editedItem.horarios.splice(pos,1);
    },
    validate(){
      this.errores = [];
      if(this.editedItem.codigo.length < 5 || this.editedItem.codigo.length > 100){
        this.errores.push("El Código debe de tener entre 5 y 100 caracteres.");
      }
      if(this.editedItem.nombre.length < 5 || this.editedItem.nombre.length > 1000){
        this.errores.push("El Nombre debe de tener entre 5 y 1000 caracteres.");
      }
      if(this.editedItem.idMaquina <= 0){
        this.errores.push("Debe de seleccionar una Máquina");
      }
      if(this.editedItem.horarios.length <= 0){
        this.errores.push("Debe ingresar al menos un Horario");
      }
      if(this.editedItem.idSubdepartamento == null){
        this.errores.push("Debe seleccionar un Subdepartamento")
      }
      
      return (this.errores.length == 0);
    },
    save(){
      this.guardando = true;
      if(!this.validate()){
				this.guardando = false;
        return;
      }
      else{
        const comprobaciones = this.$refs.todo.getListado();
        var obj = {
          IdMantenimientoPreventivo: this.editedItem.idMantenimientoPreventivo,
          Codigo: this.editedItem.codigo,
          Color: this.editedItem.color,
          Nombre: this.editedItem.nombre,
          Descripcion: this.editedItem.descripcion,
          Duracion: this.editedItem.duracion,
          IdMaquina: this.editedItem.idMaquina,
          Insumos: this.editedItem.insumos.map(element => {
            return {
              IdArticulo: (typeof element.idArticulo === 'undefined')?null:element.idArticulo,
              IdMaquina: (typeof element.idMaquina === 'undefined')?null:element.idMaquina,
              IdPuesto: (typeof element.idPuesto === 'undefined')?null:element.idPuesto,
              Cantidad: (element.conversion && element.numDimensiones == 1)? 1 : this.$utils.numbers.validFloat(element.cantidad),
              Tiempo: (typeof element.tiempo === 'undefined')?null:element.tiempo,
              Proporcional: element.conversion,
              Fraccion: (element.conversion)? (  (element.numDimensiones == 1)? (this.$utils.numbers.validFloat(element.cantidad)/element.factorConversion) : me.calcularFraccion(element.numDimensiones,element._ejeX,element._ejeY,element._ejeZ)  ) :null,
              EjeX: (element.conversion)? ( element.numDimensiones == 1 ? (this.$utils.numbers.validFloat(element.cantidad)/element.factorConversion) : this.$utils.numbers.validFloat(element._ejeX) ):null,
              EjeY: (element.conversion)?this.$utils.numbers.validFloat(element._ejeY):null,
              EjeZ: (element.conversion)?this.$utils.numbers.validFloat(element._ejeZ):null,
            }
          }),
          Horarios: this.editedItem.horarios.map(h => {
            return {
              IdMantenimientoPreventivoHorario: h.idMantenimientoPreventivoHorario,
              DiasSemana: h.diasSemana,
              Meses: h.meses,
              DiasMes: h.diasMes,
              HoraMinuto: h.horaMinuto
            };
          }),
          idsMaquinaIns: this.editedItem.idsMaquinaIns,
          idSubdepartamento: this.editedItem.idSubdepartamento,
          comprobaciones
        }

        if(this.editedItem.idMantenimientoPreventivo == -1){
          axios
          .post("/Mantenimientos/CrearPreventivo",obj)
          .then(response => {
            this.closeMantenimiento();
						this.guardando = false;
          })
          .catch(error => {
            console.log(error);
						this.guardando = false;
          })
        }
        else{
          axios
          .put("/Mantenimientos/ActualizarPreventivo",obj)
          .then(response => {
            this.closeMantenimiento();
						this.guardando = false;
          })
          .catch(error => {
            console.log(error);
						this.guardando = false;
          })
        }
      }
    }
  }
}
</script>

<style>
  .vue-swatches__trigger__wrapper{
    border: 1px solid rgb(0, 0, 0) !important;
    border-radius: 12px !important;
  }
</style>