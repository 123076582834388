import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"60%"},model:{value:(_vm.modoOrdenar),callback:function ($$v) {_vm.modoOrdenar=$$v},expression:"modoOrdenar"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Ordenar OT'S")]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"12","sm":"12"}},[_c(VDataTable,{ref:"orderots",staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"loading-text":"Cargando... Espere, Por Favor.","loading":_vm.loading,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{key:("row-" + (item.idTrabajoOrden)),staticClass:"sortableRow"},[_c('td',{staticClass:"px-1",staticStyle:{"width":"0.1%"}},[_c(VBtn,{staticClass:"sortHandle",staticStyle:{"cursor":"move"},attrs:{"icon":""}},[_c(VIcon,{attrs:{"color":"black"}},[_vm._v("mdi-menu")])],1)],1),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.clave)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.comercialLinea)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.cliente)+" ")])])]}}])})],1)],1)],1)],1),_c(VCardActions,{staticClass:"d-flex justify-center"},[(_vm.permisoVista('areas', 'u'))?_c(VBtn,{staticClass:"mt-0",attrs:{"color":"primary","disabled":_vm.savingOrdenar},on:{"click":_vm.cancelarOrdenar}},[_vm._v(" Cancelar ")]):_vm._e(),(_vm.permisoVista('areas', 'u'))?_c(VBtn,{staticClass:"mt-0",attrs:{"color":"error","disabled":_vm.savingOrdenar,"loading":_vm.savingOrdenar},on:{"click":_vm.saveOrden}},[_vm._v(" Guardar Orden ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }