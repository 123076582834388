<template>
	<v-container>
		<v-dialog v-model="dialogRecursos" persistent max-width="40%">
			<v-card>
				<v-card-title>Agregar Evidencias</v-card-title>
				<v-card-text>
					<v-file-input
						v-model="itemRecurso.idsDocumentos"
						label="Seleccione sus archivos"
						accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.jpg,.jpeg,.png,.gif,.svg"
						counter
						multiple
						show-size
						:disabled="subiendoArchivos"
						@change="cambioFicheroRecurso"
					></v-file-input>
					<v-progress-linear
						v-model="porcentajeCarga"
						v-show="subiendoArchivos"
						height="20"
						class="my-4"
					>
						<span class="font-weight-medium">
							{{ Math.ceil(porcentajeCarga) }}%
						</span>
					</v-progress-linear>
					<div class="d-flex justify-center">
						<v-btn
							color="red"
							class="mx-2"
							:disabled="subiendoArchivos"
							@click="cerrarDialogRecursos"
							>Cancelar</v-btn
						>
						<v-btn
							color="green"
							class="mx-2"
							:loading="subiendoArchivos"
							:disabled="validBtn || subiendoArchivos"
							@click="agregarRecurso"
							>Agregar</v-btn
						>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-simple-table>
			<template v-slot:default>
				<thead>
					<tr>
						<th
							class="text-center"
							width="40%"
							style="font-size: 16px"
						>
							Comprobación
						</th>
						<th
							class="text-center"
							width="60%"
							style="font-size: 16px"
						>
							Estado
						</th>
					</tr>
				</thead>
				<tbody>
					<template v-if="tareaMantenimiento.length == 0 || loading">
						<td colspan="3">
							<v-alert color="blue-grey" text class="mb-0">
								<div
									v-if="tareaMantenimiento.length == 0 && !loading"
									class="text-center"
									style="font-size: 14px"
								>
									No hay comprobaciones.
								</div>
								<div
									v-if="loading"
									class="text-center d-flex justify-center align-center"
									style="font-size: 14px"
								>
									<v-progress-circular
										indeterminate
										color="blue-grey"
										class="mr-2"
									></v-progress-circular>
									Cargando
								</div>
							</v-alert>
						</td>
					</template>
					<template v-if="tareaMantenimiento.length > 0">
						<tr
							v-for="(item, idx) in tareaMantenimiento"
							:key="idx"
						>
							<td colspan="2" style="vertical-align: top">
								<v-row>
									<v-col cols="6">
										<div
											class="
												d-flex
												align-center
												justify-center
											"
										>
											<p
												class="pt-4 pb-2"
												style="
													font-size: 14px !important;
												"
											>
												{{ item.comprobacion }}
											</p>
										</div>
										<div class="text-center">
											<v-chip-group column>
												<v-chip
													v-for="(documento, idx) in item.documentos"
													:key="'docs-'+idx"
													outlined
													color="blue"
													:href="`/api/Documentos/Descargar/${documento}?access_token=${token}`"
												>
													<v-icon left>mdi-file-outline</v-icon>
													{{ documento }}
													<v-icon right size="18">mdi-download</v-icon>
												</v-chip>
												<v-chip
													v-for="(link, idx) in item.links"
													:key="'links-'+idx"
													outlined
													color="blue"
													:href="link.url.includes('http') ? link.url : `https://${link.url}`"
													target="_blank"
												>
													<v-icon left>mdi-link</v-icon>
													{{ link.titulo }}
													<v-icon right size="18">mdi-open-in-new</v-icon>
												</v-chip>
											</v-chip-group>
										</div>
									</v-col>
									<v-col cols="6">
										<p class="pt-3 mb-0 text-center">
											¿Satisfactoria?
										</p>
										<div class="d-flex justify-center">
											<v-btn-toggle
												v-model="item.satisfactoria"
												class="my-3"
												@change="
													item.idsDocumentos = [];
													item.comentario = null;
													item.tipo = undefined;
													item.idMantenimiento = -1;
													item.horaInicio = null;
													item.horaFin = null;
													item.resuelto = undefined;
												"
											>
												<v-btn
													depressed
													small
													class="ma-0"
													:color="
														item.satisfactoria == 0
															? 'success'
															: 'grey lighten-1'
													"
													>Si</v-btn
												>
												<v-btn
													depressed
													small
													class="ma-0"
													:color="
														item.satisfactoria == 1
															? 'error'
															: 'grey lighten-1'
													"
													>No</v-btn
												>
											</v-btn-toggle>
										</div>
									</v-col>
								</v-row>
								<v-row v-if="item.satisfactoria == 1">
									<v-col
										cols="4"
										class="d-flex align-self-center"
									>
										<v-textarea
											v-model="item.comentario"
											outlined
											label="Comentarios"
											auto-grow
											rows="1"
											hide-details
										></v-textarea>
									</v-col>
									<v-col cols="8" class="d-flex">
										<v-col cols="8" class="text-center">
											<template
												v-for="(
													documento, idx
												) in item.idsDocumentos"
											>
												<div
													class="
														d-inline-block
														mx-2
														py-1
													"
													:key="'links-' + idx"
													style="font-size: 14px"
												>
													<a
														class="
															text-decoration-none
														"
														>{{
															documento.nombre
														}}</a
													>
													<v-icon
														small
														@click="
															removerRecurso(
																item,
																documento
															)
														"
														>mdi-close</v-icon
													>
												</div>
											</template>
											<div
												class="
													d-block d-flex
													justify-center
													align-center
												"
												:class="
													item.idsDocumentos.length ==
														0 && 'pt-2'
												"
											>
												<v-btn
													color="light-blue lighten-1"
													outlined
													dark
													small
													@click="
														showDialogRecursos(
															item.idMantenimientoComprobacion
														)
													"
													>Agregar Evidencia</v-btn
												>
											</div>
										</v-col>
										<v-col cols="4">
											<p class="mb-0 text-center">
												¿Se solucionó?
											</p>
											<div class="d-flex justify-center">
												<v-btn-toggle
													v-model="item.resuelto"
													class="my-3"
													@change="
														item.tipo = undefined;
														item.idMantenimiento =
															-1;
														item.horaInicio = null;
														item.horaFin = null;
													"
												>
													<v-btn
														depressed
														small
														class="ma-0"
														:color="
															item.resuelto == 0
																? 'success'
																: 'grey lighten-1'
														"
														>Si</v-btn
													>
													<v-btn
														depressed
														small
														class="ma-0"
														:color="
															item.resuelto == 1
																? 'error'
																: 'grey lighten-1'
														"
														>No</v-btn
													>
												</v-btn-toggle>
											</div>
										</v-col>
									</v-col>
								</v-row>
								<v-row
									v-if="
										item.satisfactoria == 1 &&
										item.resuelto == 1
									"
								>
									<v-col cols="12" class="py-0 text-center">
										<p class="mb-0">
											Programar Mantenimiento
										</p>
									</v-col>
									<v-col class="d-flex pt-0">
										<v-col cols="6">
											<v-select
												v-model="item.tipo"
												class=""
												:items="tiposMantenimientos"
												label="Tipo de Mantenimiento"
												item-text="nombre"
												item-value="id"
												@change="
													item.idMantenimiento = -1
												"
												hide-details
											></v-select>
											<template>
												<VueCtkDateTimePicker
													v-model="item.horaInicio"
													class="mt-2 mb-3"
													label="Inicio Planeado"
													format="YYYY-MM-DD HH:mm"
													formatted="YYYY-MM-DD HH:mm"
													noHeader
													overlay
													buttonNowTranslation="Ahora"
												/>
											</template>
										</v-col>
										<v-col cols="6">
											<v-autocomplete
												v-if="item.tipo == 1"
												v-model="item.idMantenimiento"
												class="pb-2"
												:items="preventivos"
												item-value="idMantenimientoPreventivo"
												:item-text="nombreMantenimiento"
												label="Seleccione un Mantenimiento"
												hide-details
											></v-autocomplete>
											<v-autocomplete
												v-if="item.tipo == 2"
												v-model="item.idMantenimiento"
												class="pb-2"
												:items="correctivos"
												item-value="idMantenimientoCorrectivo"
												:item-text="nombreMantenimiento"
												label="Seleccione un Mantenimiento"
												hide-details
											></v-autocomplete>
											<div
												:class="
													(item.tipo == 0 ||
														item.tipo == null ||
														item.tipo ==
															undefined) &&
													'pt-14'
												"
											>
												<VueCtkDateTimePicker
													v-model="item.horaFin"
													:min-date="item.horaInicio"
													label="Termino Planeado"
													format="YYYY-MM-DD HH:mm"
													formatted="YYYY-MM-DD HH:mm"
													noHeader
													overlay
													buttonNowTranslation="Ahora"
												/>
											</div>
										</v-col>
									</v-col>
								</v-row>
							</td>
						</tr>
					</template>
				</tbody>
			</template>
		</v-simple-table>
	</v-container>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
	data() {
		return {
			loading: false,
			errores: [],
			idMantenimientoTarea: 0,
			dialogTerminar: false,
			dialogRecursos: false,
			tareaMantenimiento: [],
			itemRecurso: {
				idMantenimientoComprobacion: -1,
				idsDocumentos: [],
			},
			subiendoArchivos: false,
			porcentajeCarga: 0,
			tiposMantenimientos: [
				{
					id: 1,
					nombre: "Preventivo",
				},
				{
					id: 2,
					nombre: "Correctivo",
				},
			],
			validacionItem: {
				status: 0,
				solucionados: 0,
				tipos: 0,
				idMantenimientos: 0,
				fecInicios: 0,
				fecFin: 0,
			},
			defaultValidacionItem: {
				status: 0,
				solucionados: 0,
				tipos: 0,
				idMantenimientos: 0,
				fecInicios: 0,
				fecFin: 0,
			},
		};
	},
	computed: {
		validBtn() {
			return !(
				this.itemRecurso.idsDocumentos !== null &&
				this.itemRecurso.idsDocumentos !== undefined &&
				this.itemRecurso.idsDocumentos?.length > 0
			);
		},
		...mapState(["token"]),
	},
	mounted() {
		this.getMantenimientosPreventivos();
		this.getMantenimientosCorrectivos();
	},
	methods: {
		nombreMantenimiento: (item) => `[${item.codigo}] - ${item.nombre}`,
		getMantenimientosPreventivos() {
			axios
				.get("/Mantenimientos/Preventivos")
				.then((response) => {
					this.preventivos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getMantenimientosCorrectivos() {
			axios
				.get("/Mantenimientos/MantenimientoCorrectivos")
				.then((response) => {
					this.correctivos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		validarComprobaciones() {
			this.errores = [];
			this.validacionItem = Object.assign({}, this.defaultValidacionItem);

			this.tareaMantenimiento.forEach((tarea) => {
				if (tarea.satisfactoria == undefined)
					this.validacionItem.status += 1;
				else if (tarea.satisfactoria == 1) {
					if (tarea.resuelto == undefined)
						this.validacionItem.solucionados += 1;
					else if (tarea.resuelto == 1) {
						if (
							tarea.tipo == 0 ||
							tarea.tipo == null ||
							tarea.tipo == undefined
						)
							this.validacionItem.tipos += 1;
						else if (tarea.idMantenimiento == -1)
							this.validacionItem.idMantenimientos += 1;
						if (tarea.horaInicio == null)
							this.validacionItem.fecInicios += 1;
						if (tarea.horaFin == null)
							this.validacionItem.fecFin += 1;
					}
				}
			});

			if (this.validacionItem.status > 0) {
				this.errores.push(
					"Debe indicar el estado de cada comprobación."
				);
			}
			if (this.validacionItem.solucionados > 0) {
				this.errores.push(
					"Debe indicar si se solucionó la comprobación."
				);
			}
			if (this.validacionItem.tipos > 0) {
				this.errores.push(
					"Debe seleccionar el Tipo de Mantenimiento para programar un Mantenimiento."
				);
			}
			if (this.validacionItem.idMantenimientos > 0) {
				this.errores.push(
					"Debe seleccionar un Mantenimiento en la comprobacion que corresponda."
				);
			}
			if (this.validacionItem.fecInicios > 0) {
				this.errores.push(
					"Debe indicar la Fecha de Inicio planeada en la comprobacion que corresponda."
				);
			}
			if (this.validacionItem.fecFin > 0) {
				this.errores.push(
					"Debe indicar la Fecha de Termino planeada en la comprobacion que corresponda."
				);
			}
			return this.errores.length == 0;
		},
		terminarTarea() {
			if (!this.validarComprobaciones()) return this.errores;

			const item = {
				idMantenimientoTarea: this.idMantenimientoTarea,
				comprobaciones: this.tareaMantenimiento.map((tarea) => ({
					idMantenimientoComprobacion:
						tarea.idMantenimientoComprobacion,
					satisfactoria: !tarea.satisfactoria,
					comentario: tarea.comentario,
					idsDocumentos: tarea.idsDocumentos.map(
						(doc) => doc.idDocumento
					),
					idMantenimientoPreventivo:
						tarea.tipo == 1 ? tarea.idMantenimiento : null,
					idMantenimientoCorrectivo:
						tarea.tipo == 2 ? tarea.idMantenimiento : null,
					horaInicio: tarea.horaInicio,
					horaFin: tarea.horaFin,
					resuelto: !tarea.resuelto,
				})),
			};

			return item;
		},
		showDialogRecursos(idMantenimientoComprobacion) {
			this.itemRecurso.idMantenimientoComprobacion =
				idMantenimientoComprobacion;
			this.dialogRecursos = true;
		},
		resetComprobaciones(){
			this.errores = [];
			this.idMantenimientoTarea = 0;
			this.tareaMantenimiento = [];
		},
		cerrarDialogRecursos() {
			this.itemRecurso = {
				idMantenimientoComprobacion: -1,
				idsDocumentos: [],
			};
			this.dialogRecursos = false;
		},
		agregarRecurso() {
			if (
				this.itemRecurso.idsDocumentos !== null &&
				this.itemRecurso.idsDocumentos?.length > 0
			) {
				let documentos = new FormData();
				this.itemRecurso.idsDocumentos.forEach((doc) => {
					documentos.append("ficheros", doc);
				});

				this.subiendoArchivos = true;

				axios
					.post("/Documentos/Cargar", documentos, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
						onUploadProgress: function (progressEvent) {
							this.porcentajeCarga = parseInt(
								Math.round(
									(progressEvent.loaded /
										progressEvent.total) *
										100
								)
							);
						}.bind(this),
					})
					.then((response) => {
						const documentosSubidos = response.data.map((doc) => ({
							idDocumento: doc.idDocumento,
							nombre: doc.nombre,
						}));

						if (documentosSubidos.length > 0) {
							this.tareaMantenimiento =
								this.tareaMantenimiento.map((item) => {
									if (
										item.idMantenimientoComprobacion ==
										this.itemRecurso
											.idMantenimientoComprobacion
									) {
										item.idsDocumentos =
											item.idsDocumentos.concat(
												documentosSubidos
											);
									}
									return item;
								});
						}
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => {
						this.subiendoArchivos = false;
						this.cerrarDialogRecursos();
					});
			}

			if (
				this.itemRecurso.idsDocumentos == null ||
				this.itemRecurso.idsDocumentos?.length == 0
			)
				this.cerrarDialogRecursos();
		},
		removerRecurso(itemDelete, resourceDelete) {
			axios
				.put(`/Documentos/Eliminar/${resourceDelete.nombre}`)
				.then(() => {
					this.tareaMantenimiento = this.tareaMantenimiento.map(
						(item) => {
							if (
								item.idMantenimientoComprobacion ==
								itemDelete.idMantenimientoComprobacion
							) {
								item.idsDocumentos = item.idsDocumentos.filter(
									(doc) =>
										doc.idDocumento !=
										resourceDelete.idDocumento
								);
							}
							return item;
						}
					);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		cambioFicheroRecurso(event) {
			if (typeof event === "undefined" || event === null)
				this.itemRecurso.idsDocumentos = null;
			else this.itemRecurso.idsDocumentos = event;
		},
		cerrarDialogTerminar() {
			this.errores = [];
			this.tareaMantenimiento = [];
			// this.$emit("refresh");
		},
		terminarMantenimiento(idMantenimientoTarea) {
			this.loading = true;
			this.idMantenimientoTarea = idMantenimientoTarea;
			axios
				.get(
					`/Mantenimientos/TareaMantenimientoComprobaciones/${idMantenimientoTarea}`
				)
				.then((response) => {
					this.tareaMantenimiento = response.data.map((tarea) => ({
						...tarea,
						idsDocumentos: [],
						tipo: 0,
						idMantenimiento: -1,
						horaInicio: null,
						horaFin: null,
						resuelto: undefined
					}));
				})
				.catch((error) => {
					console.log(error);
				})
				.finally( () => {
					this.loading = false;
				})
		},
		terminarInspecciones(
			idMantenimientoInspeccion = null,
			idMantenimientoCalendarioInspeccion = null
		) {
			this.loading = true;
			axios
				.get("/Mantenimientos/MantenimientoInspeccionComprobaciones", {
					params: {
						IdMantenimientoCalendarioInspeccion:
							idMantenimientoCalendarioInspeccion,
						IdMantenimientoInspeccion: idMantenimientoInspeccion,
					},
				})
				.then((response) => {
					this.tareaMantenimiento = response.data.map((tarea) => ({
						...tarea,
						idsDocumentos: [],
						tipo: 0,
						idMantenimiento: -1,
						horaInicio: null,
						horaFin: null,
						resuelto: undefined,
					}));
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.loading = false;
				})
		},
		comprobacionesCorrectivo(idMantenimientoCorrectivo) {
			this.loading = true;
			axios
				.get(
					`/Mantenimientos/ComprobacionMantenimientoCorrectivo/${idMantenimientoCorrectivo}`
				)
				.then((response) => {
					this.tareaMantenimiento = response.data.map((tarea) => ({
						...tarea,
						idsDocumentos: [],
						tipo: 0,
						idMantenimiento: -1,
						horaInicio: null,
						horaFin: null,
						resuelto: undefined,
					}));
				})
				.catch((error) => {
					console.log(error);
				})
				.finally( () => {
					this.loading = false;
				})
		},
	},
};
</script>

<style>
</style>