<template>
  <!-- <v-container> -->
  <v-row justify-sm="center">
    <v-card class="mt-4" :width="anchoMaximo" fill>
        <v-tabs show-arrows v-model="tab" :background-color="colorTabs" centered dark icons-and-text>
          <v-tabs-slider></v-tabs-slider>

          <v-tab v-tooltip.bottom="modoAyuda && $help.produccion.listaODT" href="#tab-1" v-if="mostrarVista('listas')" @click="updateOTS()">
            Ordenes<br>de Trabajo
            <v-icon>mdi-clipboard-list-outline</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.produccion.nuevaODT" href="#tab-2" v-if="permisoVista('listas','c')" @click="updateNuevaOT()">
            Nueva Orden<br/>de Trabajo
            <v-icon>mdi-playlist-plus</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.produccion.avancesProduccion" href="#tab-3" v-if="mostrarVista('reporteextemporaneo')" @click="updateReporteExtemporaneo()">
            Cargar avances<br />de Producción
            <v-icon>mdi-clock-outline</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.produccion.reporteArea" href="#tab-4" v-if="mostrarVista('listas')" @click="updateReportePorAreas()">
            Reporte<br>por Áreas
            <v-icon>mdi-chart-areaspline-variant</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.produccion.avancesProduccion" href="#tab-5" v-if="mostrarVista('optimizacion')" @click="updateOptimizacionProduccion()">
            Optimización<br>de Producción
            <v-icon>mdi-clock-fast</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.produccion.listaArticulos" href="#tab-6" v-if="mostrarVista('listas')" @click="updateArticulos()">
            Lista por<br/>Artículos
            <v-icon>mdi-tag</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.produccion.avancesProduccion" href="#tab-7" v-if="mostrarVista('trazabilidad')" @click="updateTrazabilidad()">
            Trazabilidad
            <v-icon>mdi-transit-connection-variant</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.produccion.gantt" href="#tab-8" v-if="mostrarVista('gantt')" @click="updateGantt()">
            Gantt
            <v-icon>mdi-chart-areaspline</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.configuracion.simulacion" href="#tab-9" v-if="mostrarVista('simulacion')" @click="updateSimulacion()">
            Simulación
            <v-icon>mdi-cube-outline</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.produccion.actividadesProgramadas" href="#tab-10" v-if="mostrarVista('actividadesprogramadas')" @click="updateActividades">
            Actividades<br>Programadas
            <v-icon>mdi-calendar-clock</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.produccion.gestionEnsambles" href="#tab-11" v-if="mostrarVista('articulos2')" @click="updateEnsambles()">
            Gestión de<br>Ensambles
            <v-icon>mdi-state-machine</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.produccion.disponibilidadMaquinas" href="#tab-12" v-if="mostrarVista('disponibilidad')" @click="updateDisponibilidad()">
            Disponibilidad de<br/>Operarios en Maquinas
            <v-icon>mdi-checkbox-marked-circle</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.produccion.piezasDisparo" href="#tab-13" v-if="mostrarVista('multiplicadores')" @click="updatePiezasTrigger()">
            Piezas por<br/>Disparo
            <v-icon>mdi-wrench-clock</v-icon>
          </v-tab>

        </v-tabs>

        <v-tabs-items v-model="tab">

          <v-tab-item :key="1" :value="'tab-1'" v-if="mostrarVista('listas')">
            <v-card flat>
              <v-card-text>
                <OTSLista ref="ots"></OTSLista>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="2" :value="'tab-2'">
            <v-card flat color="gray">
              <v-card-text>
                <ListaODT ref="details" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item
            :key="3"
            :value="'tab-3'"
            v-if="mostrarVista('reporteextemporaneo')"
          >
            <ReporteExtemporaneo ref="reporteextemporaneo" />
          </v-tab-item>

          

          <v-tab-item :key="4" :value="'tab-4'" v-if="mostrarVista('trazabilidad')">
            <v-card flat color="gray">
              <v-card-text>
                <ReporteProduccionAreas ref="reporteAreas" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="5" :value="'tab-5'" v-if="mostrarVista('optimizacion')">
            <v-card flat>
              <v-card-text class="pa-0" style="background-color: rgb(247, 247, 247)">
                <OptimizacionProduccion ref="optProduccion" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="6" :value="'tab-6'" v-if="mostrarVista('listas')">
            <v-card flat>
              <v-card-text>
                <OTSArticulos ref="articulos"></OTsArticulos>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="7" :value="'tab-7'" v-if="mostrarVista('trazabilidad')">
            <v-card flat>
              <v-card-text>
                <Trazabilidad ref="traz" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="8" :value="'tab-8'" v-if="mostrarVista('gantt')">
            <v-card flat>
              <v-card-text>
                <OTSGantt ref="gantt"></OTSGantt>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="9" :value="'tab-9'">
            <v-card flat color="gray">
              <v-card-text>
                <ListaSimulacion ref="listsimulation" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="10" :value="'tab-10'" v-if="mostrarVista('actividadesprogramadas')">
            <v-card flat>
              <v-card-text>
                <ActividadesProgramadas ref="programadas" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="11" :value="'tab-11'" v-if="mostrarVista('articulos2')">
            <v-card flat color="gray">
              <v-card-text>
                <Ensambles ref="ensam" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="12" :value="'tab-12'" v-if="mostrarVista('disponibilidad')">
            <v-card flat color="gray">
              <v-card-text>
                <Disponibilidad ref="dispo" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="13" :value="'tab-13'" >
            <v-card flat color="gray">
              <v-card-text>
                <PiezasDisparo ref="pztrigger" />
              </v-card-text>
            </v-card>
          </v-tab-item>

        </v-tabs-items>
      </v-card>
    </v-row>
  <!-- </v-container> -->
</template>

<script>
import Lista from "./OTS/Lista.vue";
import Articulos from "./OTS/Articulos.vue";
import Gantt from "./OTS/Gantt.vue";
import Disponibilidad from './Produccion/Disponibilidad.vue';
import ActividadesProgramadas from './Perfil/ActividadesProgramadas.vue';
import ReporteExtemporaneo from "./Reportes/Extemporaneo.vue";
import Ensambles from './Produccion/Ensambles.vue';
import { mapState } from 'vuex';
import PiezasDisparo from './Produccion/PiezasDisparo.vue';
import ListaODT from './Produccion/ListaODT.vue';
import Trazabilidad from "./Produccion/Trazabilidad.vue";
import ReporteProduccionAreas from './Produccion/ReporteProduccionAreas.vue';
import OptimizacionProduccion from './Produccion/OptimizacionProduccion.vue';
import ListaSimulacion from './Configuracion/ListaSimulacion.vue';

export default {
  data() {
    return {
      tab: null,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },
  computed: {
    ...mapState(["anchoMaximo","colorTabs","modoAyuda"])
  },
  components: {
    ActividadesProgramadas,
    Disponibilidad,
    OTSLista: Lista,
    OTSArticulos: Articulos,
    OTSGantt: Gantt,
		ReporteExtemporaneo: ReporteExtemporaneo,
    Ensambles,
    PiezasDisparo,
    ListaODT,
    Trazabilidad,
    ReporteProduccionAreas,
    OptimizacionProduccion,
    ListaSimulacion,
  },
  methods: {
    updateSimulacion(){
      if(typeof this.$refs.listsimulation === 'undefined') return;
      this.$refs.listsimulation.initialize();
    },
    updateReportePorAreas(){
      if (typeof this.$refs.reporteAreas === "undefined") return;
			this.$refs.reporteAreas.initialize();
    },
    updateOptimizacionProduccion(){
      if (typeof this.$refs.optProduccion === "undefined") return;
			this.$refs.optProduccion.initialize();
    },
    updateReporteExtemporaneo() {
			if (typeof this.$refs.reporteextemporaneo === "undefined") return;
			this.$refs.reporteextemporaneo.initialize();
		},
    updateActividades(){
      if( typeof this.$refs.programadas === 'undefined' ) return;
      this.$refs.programadas.initialize();
    },
    updateOTS(){
      if(typeof this.$refs.ots === 'undefined') return;
      this.$refs.ots.initialize();
    },
    updateArticulos() {
      if (typeof this.$refs.articulos === "undefined") return;
      this.$refs.articulos.initialize();
    },
    updateGantt() {
      if (typeof this.$refs.gantt === "undefined") return;
      this.$refs.gantt.initialize();
    },
    updateNuevaOT() {
      if (typeof this.$refs.details === "undefined") return;
      this.$refs.details.initialize();
    },
    updateDisponibilidad() {
      if (typeof this.$refs.dispo === "undefined") return;
      this.$refs.dispo.initialize();
    },
    updateEnsambles() {
      if (typeof this.$refs.ensam === "undefined") return;
      this.$refs.ensam.initialize();
    },
    updatePiezasTrigger() {
      if (typeof this.$refs.pztrigger === "undefined") return;
      this.$refs.pztrigger.initialize();
    },

    updateTrazabilidad() {
      if (typeof this.$refs.traz === "undefined") return;
      this.$refs.traz.initialize();
    },
  },
};
</script>