<template>
  <v-col cols="12" v-if="permisoVista('mantenimientopreventivo-calendario','r')">
    <v-sheet
      tile
      color="grey lighten-3"
      class="d-flex"
    >
      <v-row>
        <v-col cols="1" md="1" sm="1">
          <v-btn
            icon
            class="ma-2"
            @click="prevCalendar"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="10" md="10" sm="10">
          <v-select
            v-model="type"
            :items="types"
            :loading="loading"
            dense
            outlined
            hide-details
            class="ma-2"
            label="Vista"
            item-value="value"
            item-text="text"
            @change="refreshAux"
          ></v-select>
        </v-col>
        <v-col cols="1" md="1" sm="1">
          <v-btn
            icon
            class="ma-2"
            @click="nextCalendar"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>  
    </v-sheet>
    <div class="text-h4 text-center pa-4">
      {{title}}
    </div>
    <v-calendar
      ref="calendar"
      v-model="value"
      :weekdays="weekday"
      :type="type"
      :events="mantenimientos"
      :event-color="getEventColor"
      :event-text-color="getEventTextColor"
      @click:date="refreshAux"
      @change="getData"
      @click:event="showEvent"
    >
    </v-calendar>
    <v-dialog
      v-model="dialogDetalles"
      max-width="40%"
      persistent
    >
      <v-card>
        <v-card-title>
          {{eventMantenimiento.name}}
        </v-card-title>
        <v-card-text>
          <div v-if="eventMantenimiento.extras.idMantenimientoTarea == -1" class="red--text text-subtitle-1">
            [ Mantenimiento Futuro No Programado Aún ]
          </div>
          Inicio: {{eventMantenimiento.extras.inicioProgramado}}
          <br>
          Fin: {{eventMantenimiento.extras.finProgramado}}
          <br>
          Máquina: {{eventMantenimiento.extras.maquina}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="green" @click="closeDetalles">Cerrar</v-btn>
          <v-btn v-if="eventMantenimiento.tipo != 3 && eventMantenimiento.extras.idMantenimientoTarea > 0 && permisoVista('mantenimientopreventivo-calendario','c')" text color="red" @click="checkMantenimiento">Reporte<br>Extemporáneo</v-btn>
          <v-btn v-if="eventMantenimiento.tipo != 3 && eventMantenimiento.extras.idMantenimientoTarea > 0 && permisoVista('mantenimientopreventivo-calendario','c')" text color="blue" @click="checkMantenimientoReal">Reporte<br>Tiempo Real</v-btn>
          <v-btn v-if="eventMantenimiento.tipo == 3 && permisoVista('mantenimientopreventivo-calendario','c')" text color="red" @click="reporteExt">Reporte<br>Extemporáneo</v-btn>
          <v-btn v-if="eventMantenimiento.tipo == 3 && permisoVista('mantenimientopreventivo-calendario','c')" text color="blue" @click="reporteReal">Reporte<br>Tiempo Real</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogMantenimiento"
      max-width="55%"
      persistent
    >
      <v-card>
        <v-card-text
          style="min-height: 300px;"
        >
          <p class="text-h6 text--primary pt-4">Reporte Extemporáneo</p>
          <p class="text-subtitle-1 text--primary">{{eventMantenimiento.name}}</p>
          Inicio: {{eventMantenimiento.extras.inicioProgramado}}
          <br>
          Fin: {{eventMantenimiento.extras.finProgramado}}
          <br>
          Máquina: {{eventMantenimiento.extras.maquina}}
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-autocomplete
                v-model="editedItem.responsable"
                :items="personas"
                item-value="idPersona"
                :item-text="nombrePersona"
                label="Responsable"
                hide-details
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-autocomplete
                v-model="editedItem.mecanicos"
                :items="personas"
                item-value="idPersona"
                :item-text="nombrePersona"
                label="Realizado por"
                multiple
                chips
                deletable-chips
                hide-details
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <VueCtkDateTimePicker
                v-model="editedItem.inicio"
                label="Inicio"
                format="YYYY-MM-DD HH:mm"
                formatted="YYYY-MM-DD HH:mm"
                noHeader
                overlay
                buttonNowTranslation="Ahora"
              />
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <VueCtkDateTimePicker
                v-model="editedItem.fin"
                :min-date="editedItem.inicio"
                label="Termino"
                format="YYYY-MM-DD HH:mm"
                formatted="YYYY-MM-DD HH:mm"
                noHeader
                overlay
                buttonNowTranslation="Ahora"
              />
            </v-col>
          </v-row>
          <v-row>
            <Comprobaciones 
              ref="checklist"
              :key="mtkey"
            />
          </v-row>
          <v-row>
						<v-col cols="12" md="12" sm="12">
							<v-textarea
                  v-model="editedItem.recomendacion"
                  outlined
                  label="Recomendación"
                  auto-grow
                  rows="1"
                  hide-details
              ></v-textarea>
						</v-col>
					</v-row>
          <ul>
            <li class="red--text" v-for="(error,idx) in errores" :key="'error-'+idx">{{error}}</li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="green" @click="closeMantenimiento">Cancelar</v-btn>
          <v-btn text color="red" @click="completeMantenimiento" :disabled="saving" :loading="saving">
                Guardar
                <template v-slot:loader>
                  <v-progress-circular
                    indeterminate
                    :width="2"
                    :size="24"
                    color="red"
                  ></v-progress-circular>
                </template>
              </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogMantenimientoReal"
      max-width="35%"
      persistent
    >
      <v-card>
        <v-card-text
          style="min-height: 300px;"
        >
          <p class="text-h6 text--primary pt-4">Reporte en Tiempo Real</p>
          <p class="text-subtitle-1 text--primary">{{eventMantenimiento.name}}</p>
          Inicio: {{eventMantenimiento.extras.inicioProgramado}}
          <br>
          Fin: {{eventMantenimiento.extras.finProgramado}}
          <br>
          Máquina: {{eventMantenimiento.extras.maquina}}
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-autocomplete
                v-model="editedItem.responsable"
                :items="personas"
                item-value="idPersona"
                :item-text="nombrePersona"
                label="Responsable"
                hide-details
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-autocomplete
                v-model="editedItem.mecanicos"
                :items="personas"
                item-value="idPersona"
                :item-text="nombrePersona"
                label="Realizado por"
                multiple
                chips
                deletable-chips
                hide-details
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <ul>
            <li class="red--text" v-for="(error,idx) in errores" :key="'error-'+idx">{{error}}</li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="green" :disabled="saving" @click="closeMantenimiento">Cancelar</v-btn>
          <v-btn text color="red" @click="completeMantenimientoReal" :disabled="saving" :loading="saving">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogExt" max-width="55%" persistent>
			<v-card>
				<v-card-title class="pa-4 pb-4 pt-5">
					Reporte Extemporáneo
				</v-card-title>
				<v-card-text style="min-height: 300px">
					<p class="text-subtitle-1 text--primary">{{ reportedItem.name }}</p>
          Inicio: {{reportedItem.extras.inicioProgramado}}
          <br>
          Fin: {{reportedItem.extras.finProgramado}}
          <br>
          Máquina: {{reportedItem.extras.maquina}}
					<v-row>
						<v-col cols="12" md="12" sm="12">
							<v-autocomplete
								v-model="reportedItem.responsable"
								:items="personas"
								item-value="idPersona"
								:item-text="nombrePersona"
								label="Responsable"
								hide-details
							>
							</v-autocomplete>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="12" sm="12">
							<VueCtkDateTimePicker
								v-model="reportedItem.fechaInicioRealizo"
								label="Inicio"
								format="YYYY-MM-DD HH:mm"
								formatted="YYYY-MM-DD HH:mm"
								noHeader
								overlay
								buttonNowTranslation="Ahora"
							/>
						</v-col>
						<v-col cols="12" md="12" sm="12">
							<VueCtkDateTimePicker
								v-model="reportedItem.fechaFinRealizo"
								:min-date="reportedItem.fechaInicioRealizo"
								label="Termino"
								format="YYYY-MM-DD HH:mm"
								formatted="YYYY-MM-DD HH:mm"
								noHeader
								overlay
								buttonNowTranslation="Ahora"
							/>
						</v-col>
					</v-row>
					<v-row>
						<Comprobaciones
							ref="checklist" 
              :key="chkey"
						/>
					</v-row>
					<ul class="pt-6">
						<li
							class="red--text"
							v-for="(error, idx) in erroresReported"
							:key="'error-' + idx"
						>
							{{ error }}
						</li>
					</ul>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="green"
						@click="closeReported"
						:disabled="savingReported"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="red"
						@click="completeExt"
						:disabled="savingReported"
						:loading="savingReported"
					>
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogReal" max-width="500px" persistent>
			<v-card>
				<v-card-title class="pa-4 pb-4 pt-5">
					Reporte En Tiempo Real
				</v-card-title>
				<v-card-text>
					<p class="text-subtitle-1 text--primary">{{ reportedItem.name }}</p>
          Inicio: {{reportedItem.extras.inicioProgramado}}
          <br>
          Fin: {{reportedItem.extras.finProgramado}}
          <br>
          Máquina: {{reportedItem.extras.maquina}}
					<v-row>
						<v-col cols="12" md="12" sm="12">
							<v-autocomplete
								v-model="reportedItem.responsable"
								:items="personas"
								item-value="idPersona"
								:item-text="nombrePersona"
								label="Responsable"
								hide-details
							>
							</v-autocomplete>
						</v-col>
					</v-row>
					<ul>
						<li
							class="red--text"
							v-for="(error, idx) in erroresReported"
							:key="'error-' + idx"
						>
							{{ error }}
						</li>
					</ul>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="green"
						@click="closeReported"
						:disabled="savingReported"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="red"
						@click="completeReal"
						:disabled="savingReported"
						:loading="savingReported"
					>
						Iniciar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
    <v-row>
      <v-col cols="12" class="mt-10">
        <v-data-table
          :items="mantenimientos"
          :headers="headersMantenimientos"
          :loading="loading"
          loading-text="Cargando... Espere, Por Favor."
          :footer-props="{
            disableItemsPerPage: true,
            disablePagination: this.loading,
          }"
        >
          <template v-slot:item.tipo="{ item }">
            {{ getTipoInspeccion(item.tipo) }}
          </template>
          <template v-slot:item.extras="{ item }">
            {{ item.extras.maquina }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip
              
              bottom
              color="black"
              class="white--text"
              v-if="item.tipo!=3"
            >
              <template v-slot:activator="{ on }">
                <v-icon v-if="permisoVista('mantenimientopreventivo-calendario','c')" v-on="on" small @click="clickFromTable=true;checkMantenimiento(item)">
                  mdi-clipboard-text-play
                </v-icon>
              </template>
              <span class="white--text">Reporte Extemporáneo</span>
            </v-tooltip>
            <v-tooltip
              
              bottom
              color="black"
              class="white--text"
              v-if="item.tipo==3"
            >
              <template v-slot:activator="{ on }">
                <v-icon v-if="permisoVista('mantenimientopreventivo-calendario','c')" v-on="on" small @click="clickFromTable=true;reporteExt(item)">
                  mdi-clipboard-text-play
                </v-icon>
              </template>
              <span class="white--text">Reporte Extemporáneo</span>
            </v-tooltip>
            <v-tooltip
              
              bottom
              color="black"
              class="white--text"
              v-if="item.tipo!=3"
            >
              <template v-slot:activator="{ on }">
                <v-icon v-if="permisoVista('mantenimientopreventivo-calendario','c')" v-on="on" small @click="clickFromTable=true;checkMantenimientoReal(item)">
                  mdi-script-text-play
                </v-icon>
              </template>
              <span class="white--text">Reporte Tiempo Real</span>
            </v-tooltip>
            <v-tooltip
              
              bottom
              color="black"
              class="white--text"
              v-if="item.tipo==3"
            >
              <template v-slot:activator="{ on }">
                <v-icon v-if="permisoVista('mantenimientopreventivo-calendario','c')" v-on="on" small @click="clickFromTable=true;reporteReal(item)">
                  mdi-script-text-play
                </v-icon>
              </template>
              <span class="white--text">Reporte Tiempo Real</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import axios from "axios";
import Comprobaciones from './Comprobaciones.vue';

export default {
	components: { Comprobaciones },
  data: () => ({
    mtkey: 0,
    chkey: 0,
    erroresReported: [],
    savingReported: false,
    dialogReal: false,
    dialogExt: false,
    loading: false,
    dialogMantenimientoReal: false,
    mantenimientos:[],
    start:null,
    end:null,
    value: '',
    title:'',
    weekday: [0, 1, 2, 3, 4, 5, 6],
    type: 'month',
    headersMantenimientos: [
			{
				text: "Tipo",
				align: "left",
				sortable: false,
				value: "tipo",
        width: "14%",
			},
			{
				text: "Nombre",
				align: "left",
				sortable: false,
				value: "name",
        width: "22%",
			},
			{
				text: "Máquina",
				align: "left",
				sortable: false,
				value: "extras",
        width: "18%",
			},
      {
				text: "Inicio",
				align: "left",
				sortable: false,
				value: "start",
        width: "18%",
			},
      {
				text: "Fin",
				align: "left",
				sortable: false,
				value: "end",
        width: "18%",
			},
			{
				text: "Acciones",
				align: "center",
				sortable: false,
				value: "actions",
				width: "10%,",
			},
		],
    types: [
      {
        value:'month',
        text:"Mensual",
      },
      {
        value:'week',
        text:"Semanal",
      },
      {
        value:'day',
        text:"Día"
      },
      {
        value:'4day',
        text:'4 Dias'
      }
    ],
    dialogDetalles: false,
    dialogMantenimiento: false,
    reportedItem: {
			fechaInicioRealizo: null,
      fechaFinRealizo: null,
      responsable: 0,
      extras:{
        idMantenimientoCalendarioInspeccion: -1,
        idMantenimientoTarea:-1,
        inicioProgramado:"",
        finProgramado:"",
        maquina: ""
      }
		},
		defaultReportedItem: {
			fechaInicioRealizo: null,
      fechaFinRealizo: null,
      responsable: 0,
      extras:{
        idMantenimientoCalendarioInspeccion: -1,
        idMantenimientoTarea:-1,
        inicioProgramado:"",
        finProgramado:"",
        maquina: ""
      }
		},
    eventMantenimiento: {
      name:"", 
      start:"",
      end:"",
      color:"",
      timed: false,
      extras:{
        idMantenimientoTarea:-1,
        inicioProgramado:"",
        finProgramado:"",
      }
    },
    defaultEventMantenimiento: {
      name:"", 
      start:"",
      end:"",
      color:"",
      timed: false,
      extras:{
        idMantenimientoTarea:-1,
        inicioProgramado:"",
        finProgramado:"",
      }
    },
    loading: false,
    personas:[],
    editedItem:{
      responsable:-1,
      mecanicos:[],
      inicio:null,
      fin:null,
      recomendacion: ""
    },
    defaultEditedItem:{
      responsable:-1,
      mecanicos:[],
      inicio:null,
      fin:null,
      recomendacion: ""
    },
    errores:[],
    erroresReported: [],
    saving:false,
    clickFromTable: false,
  }),
  mounted(){
    this.refreshAux();
    this.getPersonas();
  },
  methods:{
    nombrePersona: persona => persona.nombre + " " + persona.paterno + " " + persona.materno,
    getTipoInspeccion(tipo){
      return ['Preventivo','Correctivo','Inspección'][tipo-1];
    },
    validExt(comprobaciones) {
			this.erroresReported = [];
			if (
				this.reportedItem.responsable == undefined ||
				this.reportedItem.responsable?.length <= 0) {
				this.erroresReported.push("Debe de Seleccionar un Responsable");
			}
			if (
				this.reportedItem.fechaInicioRealizo === null ||
				this.reportedItem.fechaInicioRealizo == undefined
			) {
				this.erroresReported.push(
					"Falta indicar cuando se inicio el Mantenimiento"
				);
			}
			if (
				this.reportedItem.fechaFinRealizo === null ||
				this.reportedItem.fechaFinRealizo == undefined
			) {
				this.erroresReported.push(
					"Falta indicar cuando se finalizo el Mantenimiento"
				);
			}

			if( Array.isArray(comprobaciones) ){
				this.erroresReported = [ ...this.erroresReported,...comprobaciones ];
			}

			return this.erroresReported.length == 0;
		},
		validRealIns() {
			this.erroresReported = [];
			if (this.reportedItem.responsable <= 0 || this.reportedItem.responsable == undefined) {
				this.erroresReported.push("Debe de Seleccionar un Responsable");
			}

			return this.erroresReported.length == 0;
		},
		completeReal() {
			if (this.validRealIns()) {
				var obj = {
					idMantenimientoCalendarioInspeccion: this.reportedItem.extras.idMantenimientoCalendarioInspeccion,
					idResponsable: this.reportedItem.responsable,
				};

				this.savingReported = true;
				axios
					.put(
						"/Mantenimientos/IniciarInspeccion",
						obj
					)
					.then(() => {
						this.dialogReal = false;
						this.reportedItem = Object.assign(
							{},
							this.defaultReportedItem
						);
						this.erroresReported = [];
						this.savingReported = false;
            this.clickFromTable = false;
            this.$refs.checklist.resetComprobaciones();
            this.initialize();
					})
					.catch((error) => {
						console.log(error);
						this.savingReported = false;
					});
			}
		},
		completeExt() {
      const item = this.$refs.checklist.terminarTarea();
			if (this.validExt(item)) {
				var obj = {
					idMantenimientoCalendarioInspeccion: this.reportedItem.extras.idMantenimientoCalendarioInspeccion,
					fechaInicioRealizo: this.reportedItem.fechaInicioRealizo,
					fechaFinRealizo: this.reportedItem.fechaFinRealizo,
					idResponsable: this.reportedItem.responsable,
					comprobaciones: item.comprobaciones
				};

				this.savingReported = true;
				axios
					.put(
						"/Mantenimientos/InspeccionExtemporanea",
						obj
					)
					.then(() => {
						this.dialogExt = false;
						this.reportedItem = Object.assign(
							{},
							this.defaultReportedItem
						);
						this.erroresReported = [];
						this.savingReported = false;
            this.clickFromTable = false;
            this.$refs.checklist.resetComprobaciones();
						this.initialize();
					})
					.catch((error) => {
						console.log(error);
						this.savingReported = false;
					});
			}
		},
    reporteExt(item) {
      if(this.clickFromTable) this.reportedItem = Object.assign({}, item);
      else this.reportedItem = Object.assign({},this.eventMantenimiento);
      this.dialogDetalles = false;
      this.dialogExt = true;
      this.chkey += 1;
			this.$nextTick( () => {
        this.$refs.checklist.terminarInspecciones(null,
            this.reportedItem.extras.idMantenimientoCalendarioInspeccion
          );
      })
		},
		reporteReal(item){
			if(this.clickFromTable) this.reportedItem = Object.assign({}, item);
      else this.reportedItem = Object.assign({},this.eventMantenimiento);
      this.dialogDetalles = false;
			this.dialogReal = true;
		},
    closeReported() {
      if(!this.clickFromTable) this.dialogDetalles = true;
			this.dialogExt = false;
			this.dialogReal = false;
			this.erroresReported = [];
      this.clickFromTable = false;
      this.$nextTick( () => {
        this.reportedItem = Object.assign({}, this.defaultReportedItem);
      });
		},
    initialize(){
      this.refreshAux();
      this.getData();
    },
    showEvent ({ nativeEvent, event }) {
      this.dialogDetalles = true;
      this.$nextTick( () => {
        this.eventMantenimiento = Object.assign({}, event);
      });
    },
    closeDetalles(){
      this.dialogDetalles = false;
      this.$nextTick( () => {
        this.eventMantenimiento = Object.assign({},this.defaultEventMantenimiento);
      });
    },
    intervalFormat(interval) {
      return interval.time
    },
    getEventColor(event){
      return event.color
    },
    getEventTextColor(event, black = '#000000', white = '#FFFFFF'){
      var rgb = this.hexToRgb(event.color);
      return this.colourIsLight(rgb[0],rgb[1],rgb[2]) ? black : white;
    },
    hexToRgb(hex){
      try {
        return hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
         ,(m, r, g, b) => '#' + r + r + g + g + b + b)
        .substring(1).match(/.{2}/g)
        .map(x => parseInt(x, 16));
      } catch (error) {
        return [0,0,0];
      }
    },
    colourIsLight(r, g, b){ 
      var a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
      return (a < 0.5);
    },
    getTitle(){
      if(!this.$utils.isValid(this.$refs.calendar.title)){
        setTimeout(() => {
          this.getTitle();
        },100);
      }
      else this.title = this.$refs.calendar.title.toUpperCase();
    },
    refreshAux(){
      setTimeout(() => {
        const btnClasses = document.querySelectorAll('.v-btn__content');
        btnClasses.forEach(element => {
          if(element.parentElement.parentElement.classList[0] == 'v-calendar-weekly__day-label' || element.parentElement.parentElement.classList[0] == 'v-calendar-daily_head-day-label' || element.parentElement.parentElement.className == 'col-sm-1 col-md-1 col-1') element.style.color = "Black"
        });

        const numberClass = document.querySelectorAll('button.v-btn.v-btn--depressed.v-btn--fab.v-btn--round.theme--light.v-size--default.transparent');
        numberClass.forEach(element => {
          element.style.display = "inline-flex"
        });
        this.getTitle();
      },100);
    },
    prevCalendar(){
      this.$refs.calendar.prev();
      this.refreshAux();      
    },
    nextCalendar(){
      this.$refs.calendar.next();
      this.refreshAux();
    },
    getData($event = null){
      if($event != null){
        if(this.$utils.isValid($event.start)){
          this.start = this.getDate($event.start);
        }
        if(this.$utils.isValid($event.end)){
          this.end = this.getDate($event.end);
        }
      }
      this.loading = true;
      axios
      .get("/Mantenimientos/PreventivoTareas/Desde/"+this.start+"/Hasta/"+this.end)
      .then(response => {
        this.loading = false;
        this.mantenimientos = response.data;
      })
      .catch(error => {
        this.loading = false;
        console.log(error);
      });
    },
    getDate(date){
      if(typeof date === 'object') return date.date;
      else if(typeof date === 'string') return date;
      else return null;
    },
    getPersonas(){
      axios
      .get("/Personas/Operadores")
      .then(response => {
        this.personas = response.data;
      })
      .catch(error => {
        console.log(error);
      })
    },
    checkMantenimientoReal(item = {}){
      if(this.clickFromTable) this.eventMantenimiento = item;
      this.dialogDetalles = false;
      this.dialogMantenimientoReal = true;
      this.editedItem = Object.assign({},this.defaultEditedItem);
      this.editedItem.mecanicos = [];
      this.errores = [];
    },
    checkMantenimiento(item = {}){
      if(this.clickFromTable) this.eventMantenimiento = item;  
      this.dialogDetalles = false;
      this.dialogMantenimiento = true;
      this.editedItem = Object.assign({},this.defaultEditedItem);
      this.editedItem.mecanicos = [];
      this.errores = [];
      this.mtkey += 1;
      this.$nextTick( () => {
        this.$refs.checklist.terminarMantenimiento(
          this.eventMantenimiento.extras.idMantenimientoTarea
        );
      });
    },
    closeMantenimiento(){
      this.errores = [];
      if(!this.clickFromTable) this.dialogDetalles = true;
      this.dialogMantenimiento = false;
      this.dialogMantenimientoReal = false;
      this.editedItem = Object.assign({},this.defaultEditedItem);
      this.editedItem.mecanicos = [];
      this.clickFromTable = false;
    },
    valid(comprobaciones){
      this.errores = [];
      if(this.editedItem.responsable <= 0){
        this.errores.push("Debe de Seleccionar un Responsable");
      }
      if(this.editedItem.mecanicos.length <= 0){
        this.errores.push("Falta indicar quien realizo el Mantenimiento");
      }
      if(this.editedItem.inicio == null){
        this.errores.push("Falta indicar cuando se inicio el Mantenimiento");
      }
      if(this.editedItem.fin == null){
        this.errores.push("Falta indicar cuando se finalizo el Mantenimiento");
      }
      if( Array.isArray(comprobaciones) ){
				this.errores = [ ...this.errores,...comprobaciones ];
      }
      
      return (this.errores.length == 0);
    },
    validReal(){
      this.errores = [];
      if(this.editedItem.responsable <= 0){
        this.errores.push("Debe de Seleccionar un Responsable");
      }
      if(this.editedItem.mecanicos.length <= 0){
        this.errores.push("Falta indicar quien realizo el Mantenimiento");
      }

      return (this.errores.length == 0);
    },
    completeMantenimientoReal(){
      if(this.validReal()){
        var obj = {
          IdMantenimientoTarea:this.eventMantenimiento.extras.idMantenimientoTarea,
          Responsable: this.editedItem.responsable,
          Mecanicos: this.editedItem.mecanicos,
        };

        this.saving = true;
        axios
        .post("/Mantenimientos/IniciarTareaMantenimientoPreventivo",obj)
        .then( () => {
          this.dialogMantenimientoReal = false;
          this.eventMantenimiento = Object.assign({},this.defaultEventMantenimiento);
          this.editedItem = Object.assign({},this.defaultEditedItem);
          this.editedItem.mecanicos = [];
          this.getData();
          this.errores = [];
          this.clickFromTable = false;
          this.saving = false;
        })
        .catch(error => {
          console.log(error);
          this.saving = false;
        })
      }
    },
    completeMantenimiento(){
      const item = this.$refs.checklist.terminarTarea();
      if(this.valid(item)){
        var obj = {
          IdMantenimientoTarea:this.eventMantenimiento.extras.idMantenimientoTarea,
          Responsable: this.editedItem.responsable,
          Mecanicos: this.editedItem.mecanicos,
          Inicio: this.editedItem.inicio,
          Fin: this.editedItem.fin,
          recomendacion: this.editedItem.recomendacion,
          comprobaciones: item.comprobaciones
        };

        this.saving = true;
        axios
        .post("/Mantenimientos/Completado",obj)
        .then( () => {
          this.dialogMantenimiento = false;
          this.eventMantenimiento = Object.assign({},this.defaultEventMantenimiento);
          this.editedItem = Object.assign({},this.defaultEditedItem);
          this.editedItem.mecanicos = [];
          this.getData();
          this.errores = [];
          this.clickFromTable = false;
          this.saving = false;
        })
        .catch(error => {
          console.log(error);
          this.saving = false;
        })
      }
    },
  },
}
</script>