// https://vuex.vuejs.org/en/state.html

const getIdsReportes = () => {
  try {
    return JSON.parse(localStorage.getItem('idsReportes'));
  } catch (error) {
    return [];
  }
};


export default {
  authStatus: '',
  token: localStorage.getItem('token') || '',
  user: localStorage.getItem('user') || '',  
  routes: JSON.parse(localStorage.getItem('routes')),
  idUser: parseInt(localStorage.getItem('idUser')),
  idRol: parseInt(localStorage.getItem('idRol')),
  idsTurnos: [],
  //idsReportes: localStorage.getItem('idsReportes')!=null?JSON.parse(localStorage.getItem('idsReportes')):[],
  idsReportes: getIdsReportes(),
  validado:  (localStorage.getItem('validado') == null) ? true : localStorage.getItem('validado') == 'true',
  cambiarPassword: (localStorage.getItem('cambiarPassword') == null) ? false : localStorage.getItem('cambiarPassword') == 'true',
  desconocidos: true,
  desconocidosIds:[],
  netError: false,
  internetStatus:0,
  nombreEmpresa: "Managrx",
  clavePersonalizada: false,
  calculadoraRollos: false,
  maquila: false,
  cargasMasivas: false,
  inventario: false,
  finalizarLabel: "Finalizar",
  pinLength:4,
  loginOpsModo: 1,
  loginOpsLength: 8,
  pinType:"numbers",
  rfid: false,
  rutasAprendizaje:false,
  reporteAuxiliar: false,
  pantografo: true,
  anchoMaximo: "95%",
  qrOperador: false,
  qr: false,
  mqtt: true,
  webPushKey: "BNc5fgO5KiglWjC3bc1hrnw2sPfuE4N1WXXxrxgBFZ2xwNTlIzW-pxKZIKIMIWrCtafbWh_BSmvDae36UWokPIA",
  colorTabs: "blue darken-3", //default: blue lighten-2 accent-4
  modoAyuda: JSON.parse(localStorage.getItem('modoAyuda') || false),
  numOTS: 1,
  trazabilidadInventario: true,
  movimientosFactura: false
}
