<template>
  <v-col cols="12">
    <v-dialog v-model="dialogDelete" persistent max-width="40%">
      <v-card>
        <v-card-text>
          <p class="text-h5 pt-4 font-weight-medium text--primary">
            ¿Está seguro que desea eliminar esta Subcausa?
          </p>
          <p class="subtitle-1 text--primary">
            Esta acción no se puede revertir y será permanente.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="success"
            :disabled="deleting"
            @click="cerrarDialog()"
            >Cancelar</v-btn
          >
          <v-btn
            text
            color="error"
            :disabled="deleting"
            :loading="deleting"
            @click="deleteCausa()"
            >Eliminar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-card-title
          >{{
            editedItem.idSubCausa == 0 ? "Crear Nueva" : "Editar"
          }}
          Subcausa</v-card-title
        >
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="editedItem.idCausa"
                  :items="causasLista"
                  item-value="idCausa"
                  :item-text="nombreCausa"
                  label="Causas"
                  clearable
                  @change="nombreDepartamento"
                ></v-autocomplete>
              </v-col>
              <!-- 
                DepartamentoCausa: null,
      AreaCausa: null
               -->
              <v-col cols="6">
                <v-text-field label="Departamento" readonly v-model="DepartamentoCausa">
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Área" readonly v-model="AreaCausa">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.codigosubcausa"
                  label="Código de la Subcausa (Opcional)"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.subcausa"
                  label="Nombre de la Subcausa"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="errores.length > 0">
                <ul>
                  <li
                    v-for="(error, idx) in errores"
                    :key="'e-' + idx"
                    class="red--text"
                  >
                    {{ error }}
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="success" :disabled="saving" @click="cerrarDialog()"
            >Cancelar</v-btn
          >
          <v-btn
            text
            color="error"
            :disabled="saving"
            :loading="saving"
            @click="saveSubcausa()"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="justify-space-between">
      <v-col cols="4">
        <v-btn
          color="primary"
          :disabled="loading"
          @click="showDialog()"
          class="px-8"
        >
          Nueva Subcausa
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Buscar"
          single-line
          clearable
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :items="Subcausas"
          :headers="SubcausasHeaders"
          :search="search"
          :loading="loading"
        >
          <template v-slot:item.causa="{ item }">
            {{ item.codigoCausa == null ? item.causa : "[" + item.codigoCausa + "] - " + item.causa }}
          </template>
          <template v-slot:item.codigosubcausa="{ item }">
            {{ item.codigosubcausa == null ? "N/A" : item.codigosubcausa }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom color="black" class="white--text">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  small
                  class="mr-2"
                  @click="showDialogEdit(item)"
                >
                  edit
                </v-icon>
              </template>
              <span class="white--text">Editar</span>
            </v-tooltip>
            <v-tooltip bottom color="black" class="white--text">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  small
                  class="mr-2"
                  @click="showDialogDelete(item)"
                >
                  delete
                </v-icon>
              </template>
              <span class="white--text">Eliminar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      search: "",
      errores: [],
      saving: false,
      dialogDelete: false,
      SubcausasHeaders: [
        {
          text: "Departamento",
          align: "center",
          width: "18%",
          value: "departamento",
        },
        {
          text: "Area",
          align: "center",
          width: "18%",
          value: "area",
        },
        {
          text: "Causa",
          align: "center",
          width: "18%",
          value: "causa",
        },
        {
          text: "Codigo Subcausa",
          align: "center",
          width: "18%",
          value: "codigosubcausa",
        },
        {
          text: "Subcausa",
          align: "center",
          width: "18%",
          value: "subcausa",
        },
        {
          text: "Acciones",
          align: "center",
          width: "10%",
          value: "actions",
        },
      ],
      editedItem: {
        idSubCausa: 0,
        idCausa: 0,
        codigosubcausa: null,
        subcausa: null,
      },
      defaultEditedItem: {
        idSubCausa: 0,
        idCausa: 0,
        codigosubcausa: null,
        subcausa: null,
      },
      loading: false,
      deleting: false,
      Subcausas: [],
      causasLista: [],
      DepartamentoCausa: null,
      AreaCausa: null
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    nombreCausa: (causa) => `${causa.codigo == null ? `${causa.causa}`: `[${causa.codigo}] - ${causa.causa}`}`,
    nombreDepartamento(causa){
      var informacion = causa == null ? null : this.causasLista.find(a=> a.idCausa == causa)
      this.DepartamentoCausa = causa == null ? null : informacion.departamento
      this.AreaCausa = causa == null ? null : informacion.area
    },
    validate() {
      this.errores = [];

      if (this.editedItem.subcausa == null || this.editedItem.subcausa == "") {
        this.errores.push("La Subcausa es Obligatoria");
      } else if (this.editedItem.subcausa.length < 5) {
        this.errores.push("La Subcausa debe tener mínimo 5 caracteres");
      }

      if (this.editedItem.idCausa == null || this.editedItem.idCausa.length < 1) {
        this.errores.push("La Causa es Obligatoria");
      }

      return this.errores.length == 0;
    },
    saveSubcausa() {
      if (!this.validate()) return;
      this.saving = true;

      axios({
        url:
          this.editedItem.idSubCausa == 0
            ? "/SubCausas/Crear"
            : `/SubCausas/Actualizar/${this.editedItem.idSubCausa}`,
        method: this.editedItem.idSubCausa == 0 ? "POST" : "PUT",
        data: {
          IdCausa: this.editedItem.idCausa,
          Codigo: this.editedItem.codigosubcausa,
          SubCausa: this.editedItem.subcausa,
        },
      })
        .then(() => {
          this.saving = false;
          this.cerrarDialog();
          this.initialize();
        })
        .catch((error) => {
          this.saving = false;
          if (error.response) {
            if (error.response.status == 409) {
              this.errores.push(
                "Existe una Subcausa con el mismo Nombre o Código"
              );
            }
          }
          console.log(error);
        });
    },
    showDialogEdit(item) {
      this.editedItem = { ...item };
      this.nombreDepartamento(item.idCausa)
      this.dialog = true;
    },
    showDialogDelete(item) {
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    deleteCausa() {
      this.deleting = true;
      axios
        .put(`/SubCausas/Eliminar/${this.editedItem.idSubCausa}`)
        .then(() => {
          this.deleting = false;
          this.cerrarDialog();
          this.initialize();
        })
        .catch((error) => {
          this.deleting = false;
          console.log(error);
        });
    },
    cerrarDialog() {
      this.dialog = false;
      this.dialogDelete = false;
      this.editedItem = { ...this.defaultEditedItem };
      this.errores = [];
      this.DepartamentoCausa = null
      this.AreaCausa = null
    },
    initialize() {
      this.getSubcausas();
      this.getCausas();
    },
    showDialog() {
      this.editedItem = { ...this.defaultEditedItem };
      this.dialog = true;
    },
    getSubcausas() {
      this.loading = true;
      axios
        .get("/SubCausas")
        .then((response) => {
          this.Subcausas = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCausas() {
      axios
        .get("/Causas")
        .then((response) => {
          this.causasLista = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
