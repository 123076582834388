<template>
  <v-col cols="12">
    <template>
        <v-dialog v-model="eliminar" persistent max-width="500px" :key="eliminar">
          <v-card>
            <v-card-title class="headline">¿Está seguro que desea eliminar<br/>esta máquina?</v-card-title>
            <v-card-text>Esta acción no se puede revertir y será permanente.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="eliminar = false; delItem = '';">Cancelar</v-btn>
              <v-btn color="red darken-1" text @click="eliminar = false; deleteItemDB()">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </template>
    <template>
        <v-dialog v-model="dialogAddImg" persistent max-width="500px" :key="dialogAddImg">
          <v-card>
            <v-card-title class="headline">Agregar Nueva Imagen</v-card-title>
            <v-card-text>
              <v-col v-if="erroresImagen.length>0" justify="center" cols="12" sm="12" md="12">
                 <ul>
                    <li class="red--text" v-for="ex in erroresImagen" :key="ex">{{ ex }}</li>
                  </ul>
              </v-col>
              <v-col justify="center" cols="12" sm="12" md="12">
                <v-text-field v-model="uploadImg.descripcion" label="Descripción de la Imagen"></v-text-field>
                <v-file-input v-model="imagenArchivo" @change="vistaPrevia()" accept=".jpg,.jpeg,.png,.gif" label="Selecione una Imagen"></v-file-input>
                Vista Previa de la Imagen:
                <v-img :src="imagenVistaPrevia" :key="imagenVistaPrevia"></v-img>
              </v-col>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogAddImg = false; imagenVistaPrevia = ''; imagenArchivo=null; uploadImg = defaultUploadImg; erroresImagen=[];">Cancelar</v-btn>
              <v-btn color="red darken-1" text @click="eliminar = false; agregarImagenDB()">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </template>
    <template>
        <v-dialog v-model="dialogImg" persistent max-width="500px" :key="dialogImg">
          <v-card>
            <v-card-text>
              <br>
              <v-text-field readonly v-model="imagen.descripcion" label="Descripción de la Imagen"></v-text-field>
                <v-card flat tile class="d-flex">
                  <v-img
                    :src="imagen.img"
                    aspect-ratio="1"
                    class="grey lighten-2"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="dialogImg = false; deleteImgDB();">Eliminar</v-btn>
              <v-btn color="blue darken-1" text @click="dialogImg = false; imagen=defaultImagen;">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </template>
    <template>
        <v-dialog v-model="view" persistent max-width="500px" :key="view">
          <v-card>
            <v-card-title class="headline">Detalles de {{viewedItem.nombre}}</v-card-title>
            <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        v-model="viewedItem.esMaquina"
                        label="Tipo"
                        :items="tipos"
                        item-value="value"
                        item-text="name"
                        readonly
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="12">
                      <v-autocomplete
                        v-model="viewedItem.idMaquinaTipo"
                        :items="clasificaciones"
                        label="Grupo de Máquinas"
                        item-text="nombre"
                        readonly
                        item-value="idMaquinaTipo"
                      ></v-autocomplete>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="12">
                      <v-autocomplete
                        v-model="viewedItem.idArea"
                        :items="areas"
                        label="Área"
                        item-text="nombre"
                        readonly
                        item-value="idArea"
                      ></v-autocomplete>
                    </v-col>
                    <v-col justify="center" cols="6" sm="6" md="6">
                      <v-text-field readonly v-model="viewedItem.marca" :label="'Marca de la '+tipoLabel"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="6" sm="6" md="6">
                      <v-text-field readonly v-model="viewedItem.modelo" :label="'Modelo de la '+tipoLabel"></v-text-field>
                    </v-col>
                    <v-subheader>Número de Personas para Operar esta {{tipoLabel}}</v-subheader>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <br>
                      <v-slider
                        v-model="viewedItem.numOperadores"
                        min="0"
                        max="10"
                        thumb-label="always"
                        readonly
                      >
                      </v-slider>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12" v-if="permisoVista('maquinas','$')">
                      <v-text-field readonly type="number" v-model="viewedItem.costoHora" :label="'Costo Por Hora de la '+tipoLabel"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field readonly v-model="viewedItem.descripcionGeneral" :label="'Descripción General de la '+tipoLabel"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field readonly v-model="viewedItem.descripcionTecnica" :label="'Descripción Técnica de la '+tipoLabel"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="6" sm="6" md="6">
                      <v-text-field readonly v-model="viewedItem.fechaFabricacion" :label="'Fecha de Fabricación de la '+tipoLabel"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="6" sm="6" md="6">
                      <v-text-field readonly v-model="viewedItem.fechaCompra" :label="'Fecha de Compra de la '+tipoLabel"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-autocomplete
                        v-model="viewedItem.habilidades"
                        :items="habilidades"
                        label="Habilidades"
                        item-text="nombre"
                        chips
                        multiple
                        readonly
                        item-value="idHabilidad"
                      ></v-autocomplete>
                    </v-col>
                    <v-expansion-panels cols="12" sm="12" md="12" multiple accordion>
                      <v-expansion-panel>
                        <v-expansion-panel-header>Imagenes:</v-expansion-panel-header>
                        <v-expansion-panel-content cols="12" sm="12" md="12">
                          <v-row>
                          <v-col class ="d-flex child-flex" cols="4">
                            <v-card flat tile class="d-flex" outlined @click="agregarImagen()">
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-icon>add</v-icon>
                              </v-row>
                            </v-card>
                          </v-col>
                          <v-col
                            v-for="img in this.viewedItem.imagenes"
                            :key="img.idImagen"
                            @click="imagenCompleta(img)"
                            class="d-flex child-flex"
                            cols="4"
                          >
                            <v-card flat tile class="d-flex">
                              <v-img
                                :src="img.img"
                                aspect-ratio="1"
                                class="grey lighten-2"
                              >
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                  >
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-card>
                          </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          Operarios
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                           <v-simple-table>
                              <thead>
                                <tr>
                                  <th class="text-center">Nombre</th>
                                  <th class="text-center"># Habilidades</th>
                                  <th class="text-center">Porcentaje</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="persona in viewedItem.personas" :key="persona.idPersona">
                                  <td class="text-center">{{ getNombreCompleto(persona.idPersona)  }}</td>
                                  <td class="text-center">{{ persona.nHabilidades }}</td>
                                  <td class="text-center">{{ ( isNaN((persona.nHabilidades/persona.hRequeridas)*100) ? 100 : ((persona.nHabilidades/persona.hRequeridas)*100) ).toFixed(2)  }}%</td>
                                </tr>
                              </tbody>
                          </v-simple-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-row>
                </v-container>
              </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="view = false ">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </template>
    <v-row>

      <v-col class="d-flex justify-start" cols="6"><v-btn color="primary" dark class="mb-2" @click="dialog=true" v-if="permisoVista('maquinas','c')">Nueva Máquina/Herramienta</v-btn></v-col>
      <v-col class="d-flex justify-end" cols="6">
        
          <DownloadBtn
          text
          color="primary"  
          @click="descargarFormato()"
          label="Descargar formato de <br> carga masiva"
        />

        <v-btn
					
					text
					color="primary"
					class="mr-3"
					v-on="on"
					:disabled="loadingSubir || loadingDescargar"
					:loading="loadingSubir"
					@click="cargaMasiva = true"
				>
					subir archido para <br> Carga Masiva
				</v-btn>
        
        </v-col>
     <!--  <v-col class="d-flex justify-center" cols="4">
        <v-btn
					
					text
					color="primary"
					class="mr-3"
					v-on="on"
					:disabled="loadingSubir || loadingDescargar"
					:loading="loadingSubir"
					@click="cargaMasiva = true"
				>
					subir archido para <br> Carga Masiva
				</v-btn>
        
        </v-col> -->
      <v-col cols="12">
        <v-data-table
          v-if="permisoVista('maquinas','r')"
          :headers="headers"
          :items="maquinas"
          :search="search"
          :loading="loading"
          loading-text="Cargando... Espere, Por Favor."
      >
      <template v-slot:top>
        <v-toolbar flat color="white">
         <!--  <v-dialog v-model="dialog" persistent max-width="500px" :key="dialog">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on" v-if="permisoVista('maquinas','c')">Nueva Máquina/Herramienta</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-autocomplete
                        v-model="editedItem.esMaquina"
                        label="Tipo"
                        :items="tipos"
                        item-value="value"
                        item-text="name"
                        @change="resetIdMaquina()"
                        :error-messages="errorEsMaquina"
                        @focus="()=>{
                          errorEsMaquina = '';
                        }"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-autocomplete
                        v-model="editedItem.idMaquinaCategoria"
                        label="Categoría"
                        :items="categorias"
                        item-value="idMaquinaCategoria"
                        item-text="nombre"
                        @change="resetIdMaquina()"
                        :error-messages="erroridMaquinaCategoria"
                        @focus="()=>{
                          erroridMaquinaCategoria = '';
                        }"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col class="d-flex" cols="11" sm="11">
                      <v-autocomplete
                        v-model="editedItem.idMaquina"
                        :items="catMaquinasFiltrado"
                        :label="'Modelos de '+tipoLabel+'s'"
                        :item-text="marcaModelo"
                        :disabled="loadingArea"
                        :loading="loadingArea"
                        item-value="idMaquina"
                        :error-messages="erroridMaquina"
                        @focus=" () => {
                          erroridMaquina = '';
                        }"
                      >
                        <template v-slot:item="data">
                          <v-list-item-content>
                            <v-list-item-title v-html="data.item.marca+' - '+data.item.modelo"></v-list-item-title>
                            <v-list-item-subtitle v-html="data.item.descripcionGeneral"></v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-icon
                      class="mr-2"
                      :disabled="loadingArea"
                      @click="getCatMaquinas()"
                    >
                      mdi-reload
                    </v-icon>
                    <v-col class="d-flex" cols="11" sm="11">
                      <v-autocomplete
                        v-model="editedItem.idMaquinaTipo"
                        :items="clasificaciones"
                        label="Grupo de Máquinas"
                        item-text="nombre"
                        :disabled="loadingClasificacion"
                        :loading="loadingClasificacion"
                        item-value="idMaquinaTipo"
                      ></v-autocomplete>
                    </v-col>
                    <v-icon
                      class="mr-2"
                      :disabled="loadingClasificacion"
                      @click="getClasificaciones()"
                    >
                      mdi-reload
                    </v-icon>
                    <v-col class="d-flex" cols="11" sm="11">
                      <v-autocomplete
                        v-model="editedItem.idArea"
                        :items="areas"
                        label="Área"
                        item-text="nombre"
                        :disabled="loadingArea"
                        :loading="loadingArea"
                        item-value="idArea"
                        :error-messages="erroridArea"
                        @focus=" () => {
                          erroridArea = '';
                        }"
                      ></v-autocomplete>
                    </v-col>
                    <v-icon
                      class="mr-2"
                      :disabled="loadingArea"
                      @click="getAreas()"
                    >
                      mdi-reload
                    </v-icon>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field 
                        v-model="editedItem.nombre" 
                        :label="'Nombre / Código de la '+tipoLabel"
                        :error-messages="errornombre"
                        @focus=" () => {
                          errornombre = '';
                        }"
                      ></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="6" sm="6" md="6">
                      <datePicker
                        v-model="editedItem.fechaFabricacion"
                        :label="'Fecha de Fabricación de la '+tipoLabel"
                        format="YYYY-MM-DD"
                        :errorMessages="errorfechaFabricacion"
                        @focus=" () => {
                          errorfechaFabricacion = '';
                        }"
                        :hideDetails="false"
                        clearable
                        maxToday
                      ></datePicker>
                    </v-col>
                    <v-col justify="center" cols="6" sm="6" md="6">
                      <datePicker
                        v-model="editedItem.fechaCompra"
                        :label="'Fecha de Compra de la '+tipoLabel"
                        format="YYYY-MM-DD"
                        :errorMessages="errorfechaCompra"
                        @focus=" () => {
                          errorfechaCompra = '';
                        }"
                        :hideDetails="false"
                        clearable
                        maxToday
                      ></datePicker>
                    </v-col>
                    <v-col cols="12">
                      <InputToken
                        :key="`intok1-${chkey}`"
                        v-model="editedItem.token"
                        label="Token 1"
                        appendIcon1="mdi-lock"
                        appendIcon2="mdi-lock-open"
                        :locked="editedIndex!=-1"
                        :error-messages="errortoken"
                        @focus=" () => {
                          errortoken = '';
                        }"
                        @input="
                          (value) => {
                            editedItem.token=value
                          }
                        "
                      />
                    </v-col>
                    <v-col cols="12">
                      <InputToken 
                        :key="`intok2-${chkey}`"
                        v-model="editedItem.token2"
                        label="Token 2"
                        appendIcon1="mdi-lock"
                        appendIcon2="mdi-lock-open"
                        :locked="editedIndex!=-1"
                        :error-messages="errortoken2"
                        @focus=" () => {
                          errortoken2 = '';
                        }"
                        @input="
                          (value) => {
                            editedItem.token2=value
                          }
                        "
                      />
                    </v-col>
                  </v-row>
                  <ul>
                    <li class="red--text" v-for="ex in errores" :key="ex">{{ ex }}</li>
                  </ul>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog=false; errores=[];">Cancelar</v-btn>
                <v-btn color="red darken-1" text @click="save()" :disabled="saving" :loading="saving">
                  Guardar
                  <template v-slot:loader>
                    <v-progress-circular
                      indeterminate
                      :width="2"
                      :size="24"
                      color="red"
                    ></v-progress-circular>
                  </template>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer> -->
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.maquinaTipo="{ item }">
        {{ `${item.maquinaTipo==null?'N/A':item.maquinaTipo}` }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('maquinas','r')"
          small
          class="mr-2"
          @click="viewItem(item)"
        >
          mdi-eye
        </v-icon>
         </template>
          <span class="white--text">Ver detalles</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('maquinas','u')"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          edit
        </v-icon>
         </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('maquinas','d')"
          small
          @click="deleteItem(item)"
        >
          delete
        </v-icon>
         </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
    </v-data-table>
      </v-col>
      <v-dialog v-model="dialog" persistent max-width="500px" :key="dialog">
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-autocomplete
                        v-model="editedItem.esMaquina"
                        label="Tipo"
                        :items="tipos"
                        item-value="value"
                        item-text="name"
                        @change="resetIdMaquina()"
                        :error-messages="errorEsMaquina"
                        @focus="()=>{
                          errorEsMaquina = '';
                        }"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-autocomplete
                        v-model="editedItem.idMaquinaCategoria"
                        label="Categoría"
                        :items="categorias"
                        item-value="idMaquinaCategoria"
                        item-text="nombre"
                        @change="resetIdMaquina()"
                        :error-messages="erroridMaquinaCategoria"
                        @focus="()=>{
                          erroridMaquinaCategoria = '';
                        }"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col class="d-flex" cols="11" sm="11">
                      <v-autocomplete
                        v-model="editedItem.idMaquina"
                        :items="catMaquinasFiltrado"
                        :label="'Modelos de '+tipoLabel+'s'"
                        :item-text="marcaModelo"
                        :disabled="loadingArea"
                        :loading="loadingArea"
                        item-value="idMaquina"
                        :error-messages="erroridMaquina"
                        @focus=" () => {
                          erroridMaquina = '';
                        }"
                      >
                        <template v-slot:item="data">
                          <v-list-item-content>
                            <v-list-item-title v-html="data.item.marca+' - '+data.item.modelo"></v-list-item-title>
                            <v-list-item-subtitle v-html="data.item.descripcionGeneral"></v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-icon
                      class="mr-2"
                      :disabled="loadingArea"
                      @click="getCatMaquinas()"
                    >
                      mdi-reload
                    </v-icon>
                    <v-col class="d-flex" cols="11" sm="11">
                      <v-autocomplete
                        v-model="editedItem.idMaquinaTipo"
                        :items="clasificaciones"
                        label="Grupo de Máquinas"
                        item-text="nombre"
                        :disabled="loadingClasificacion"
                        :loading="loadingClasificacion"
                        item-value="idMaquinaTipo"
                      ></v-autocomplete>
                    </v-col>
                    <v-icon
                      class="mr-2"
                      :disabled="loadingClasificacion"
                      @click="getClasificaciones()"
                    >
                      mdi-reload
                    </v-icon>
                    <v-col class="d-flex" cols="11" sm="11">
                      <v-autocomplete
                        v-model="editedItem.idArea"
                        :items="areas"
                        label="Área"
                        item-text="nombre"
                        :disabled="loadingArea"
                        :loading="loadingArea"
                        item-value="idArea"
                        :error-messages="erroridArea"
                        @focus=" () => {
                          erroridArea = '';
                        }"
                      ></v-autocomplete>
                    </v-col>
                    <v-icon
                      class="mr-2"
                      :disabled="loadingArea"
                      @click="getAreas()"
                    >
                      mdi-reload
                    </v-icon>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field 
                        v-model="editedItem.nombre" 
                        :label="'Nombre / Código de la '+tipoLabel"
                        :error-messages="errornombre"
                        @focus=" () => {
                          errornombre = '';
                        }"
                      ></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="6" sm="6" md="6">
                      <datePicker
                        v-model="editedItem.fechaFabricacion"
                        :label="'Fecha de Fabricación de la '+tipoLabel"
                        format="YYYY-MM-DD"
                        :errorMessages="errorfechaFabricacion"
                        @focus=" () => {
                          errorfechaFabricacion = '';
                        }"
                        :hideDetails="false"
                        clearable
                        maxToday
                      ></datePicker>
                    </v-col>
                    <v-col justify="center" cols="6" sm="6" md="6">
                      <datePicker
                        v-model="editedItem.fechaCompra"
                        :label="'Fecha de Compra de la '+tipoLabel"
                        format="YYYY-MM-DD"
                        :errorMessages="errorfechaCompra"
                        @focus=" () => {
                          errorfechaCompra = '';
                        }"
                        :hideDetails="false"
                        clearable
                        maxToday
                      ></datePicker>
                    </v-col>
                    <v-col cols="12">
                      <InputToken
                        :key="`intok1-${chkey}`"
                        v-model="editedItem.token"
                        label="Token 1"
                        appendIcon1="mdi-lock"
                        appendIcon2="mdi-lock-open"
                        :locked="editedIndex!=-1"
                        :error-messages="errortoken"
                        @focus=" () => {
                          errortoken = '';
                        }"
                        @input="
                          (value) => {
                            editedItem.token=value
                          }
                        "
                      />
                    </v-col>
                    <v-col cols="12">
                      <InputToken 
                        :key="`intok2-${chkey}`"
                        v-model="editedItem.token2"
                        label="Token 2"
                        appendIcon1="mdi-lock"
                        appendIcon2="mdi-lock-open"
                        :locked="editedIndex!=-1"
                        :error-messages="errortoken2"
                        @focus=" () => {
                          errortoken2 = '';
                        }"
                        @input="
                          (value) => {
                            editedItem.token2=value
                          }
                        "
                      />
                    </v-col>
                  </v-row>
                  <ul>
                    <li class="red--text" v-for="ex in errores" :key="ex">{{ ex }}</li>
                  </ul>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog=false; errores=[];">Cancelar</v-btn>
                <v-btn color="red darken-1" text @click="save()" :disabled="saving" :loading="saving">
                  Guardar
                  <template v-slot:loader>
                    <v-progress-circular
                      indeterminate
                      :width="2"
                      :size="24"
                      color="red"
                    ></v-progress-circular>
                  </template>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
    </v-row>
    <v-dialog
			v-model="cargaMasiva"
			persistent
			max-width="500px"
			key="cargaMasiva"
		>
			<v-card>
				<v-card-title
					>Carga Masiva de inventario</v-card-title
				>
				<v-card-text v-if="errorCargaMasiva">
					<v-col cols="12" md="12" sm="12" class="text-center">
						<h5>Se ha producido un error. Intente una vez más.</h5>
					</v-col>
					<v-col cols="12" md="12" sm="12" class="text-center">
						<v-btn
							depressed
							color="red"
							text
							@click="
								cargaMasiva = false;
								ficheroCarga = null;
								cargaCargaCompleta = false;
								loadingCargaMasiva = false;
								errorCargaMasiva = false;
								selectedUpdateFile = null;
							"
							>Cerrar</v-btn
						>
					</v-col>
				</v-card-text>
				<v-card-text v-if="cargaCargaCompleta">
					<v-col cols="12" md="12" sm="12" class="text-center">
						<!-- errores -->
            <h4 v-if="erroresCarga.length <= 0">Carga Realizada Correctamente</h4>
            <h4 v-else>Uno o varios errores al intentar la cargar</h4>
            <v-list-item v-if="erroresCarga.length > 0">
              <v-list-item-content>
                <v-list-item-title v-for="(item, index) in erroresCarga" :key="index"> <v-icon color="red">mdi-alert-circle-outline</v-icon> {{item}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
					</v-col>
					<v-col cols="12" md="12" sm="12" class="text-center">
						<v-btn
							depressed
							color="success"
							text
							@click="
								cargaMasiva = false;
								ficheroCarga = null;
								cargaCargaCompleta = false;
								loadingCargaMasiva = false;
								errorCargaMasiva = false;
								selectedUpdateFile = null;
							"
							>Cerrar</v-btn
						>
					</v-col>
				</v-card-text>
				<v-card-text v-if="loadingCargaMasiva && !cargaCargaCompleta">
					<v-col cols="12" md="12" sm="12" class="text-center">
						<h4>Cargando</h4>
					</v-col>
					<v-col cols="12" md="12" sm="12" class="text-center">
						<v-progress-circular
							:size="75"
							color="green"
							indeterminate
						></v-progress-circular>
					</v-col>
				</v-card-text>
				<v-card-text
					v-if="
						!loadingCargaMasiva &&
						!cargaCargaCompleta &&
						!errorCargaMasiva
					"
					class="text-center"
				>
					<v-file-input
						v-model="ficheroCarga"
						accept=".csv"
						@change="cambioficheroCompras"
						show-size
						label="Fichero Carga .CSV"
					></v-file-input>

					<br />
					<br />
					<v-row>
						
						<v-btn
							color="blue darken-1"
							text
							@click="
								cargaMasiva = false;
								ficheroCarga = null;
								cargaCargaCompleta = false;
								loadingCargaMasiva = false;
								errorCargaMasiva = false;
								selectedUpdateFile = null;
							"
							>Cancelar</v-btn
						>
						<v-btn
							color="red darken-1"
							text
							@click="cargaMasivaPost"
							>Aceptar</v-btn
						>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog>
    <v-snackbar
      v-model="alerta"
      top
      color="error"
    >
      Existe una Máquina/Herramienta Activa con el Mismo Código
      <v-btn
        text
        @click="alerta = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
  </v-col>
</template>

<script>
import axios from "axios";
import datePicker from "@/components/DatePicker";
import { VueDatePicker } from "@mathieustan/vue-datepicker";
import InputToken from './InputToken.vue';
import DownloadBtn from '@/components/DownloadBtn.vue';
import { mapState } from "vuex"
import moment from "moment";

  export default {
    components: {
      datePicker: datePicker,
      InputToken,
      DownloadBtn
    },
    data: () => ({
      errorEsMaquina: "",
      erroridMaquinaCategoria: "",
      erroridArea: "",
      erroridMaquina: "",
      errornombre: "",
      errorfechaFabricacion: "",
      errorfechaCompra: "",
      errortoken: "",
      errortoken2: "",
      chkey: 0,
      saving: false,
      tipos:[
        {
          name:'Máquina',
          value:true
        },
        {
          name:'Herramienta',
          value:false
        }
      ],
      loadingCatMaquinas: false,
      catMaquinas: [],
      loadingHabilidades: false,
      habilidades: [],
      loadingOperador: false,
      personas: [],
      alerta:false,
      imagenVistaPrevia: '',
      imagenArchivo: null,
      dialogAddImg: false,
      dialogImg: false,
      areas:[],
      erroresCarga:[],
      defaultImagen:{
        img: '',
        ruta: '',
        idImagen: '',
        descripcion: 'Cargando... Espere por Favor',
      },
      imagen: {
        img: '',
        ruta: '',
        idImagen: '',
        descripcion: 'Cargando... Espere por Favor',
      },
      loadingArea: false,
      dialog: false,
      eliminar: false,
      view: false,
      search: '',
      headers: [
        {
          text: 'Tipo',
          align: 'left',
          sortable: true,
          value: 'tipo',
        },
        {
          text: 'Área',
          align: 'left',
          sortable: true,
          value: 'area',
        },
        {
          text: 'Categoría',
          align: 'left',
          sortable: true,
          value: 'categoria',
        },
        {
          text: 'Grupo',
          align: 'left',
          sortable: true,
          value: 'maquinaTipo',
        },
        {
          text: 'Nombre/Código',
          align: 'left',
          sortable: true,
          value: 'nombre',
        },
        {
          text: 'Marca',
          align: 'left',
          sortable: true,
          value: 'marca',
        },
        {
          text: 'Modelo',
          align: 'left',
          sortable: true,
          value: 'modelo',
        },
        {
          text: 'Descripción General',
          align: 'left',
          sortable: true,
          value: 'descripcionGeneral',
        },
        { text: 'Acciones', value: 'action', sortable: false, width:'15%', align:'center' },
      ],
      loading: false,
      maquinas: [],
      delItem: '',
      action: 0,
      errores: [],
      erroresImagen:[],
      uploadImg:{
        idMaquina: '',
        mime: '',
        imagen:'',
        descripcion:'',
      },
      defaultUploadImg:{
        idMaquina: '',
        mime: '',
        imagen:'',
        descripcion:'',
      },
      viewedIndex: -1,
      viewedItem: {
        idMaquinaTipo: null,
        categoria: '',
        nombre: '',
        marca:'',
        modelo:'',
        esMaquina:true,
        numOperadores:0,
        descripcionGeneral:'',
        descripcionTecnica:'',
        fechaFabricacion:null,
        fechaCompra:null,
        imagenes: [],
        costoHora:0.0,
        habilidades: [],
      },
      editedIndex: -1,
      editedItem: {
        idArea:-1,
        idMaquina:-1,
        idMaquinaCategoria: null,
        idMaquinaTipo: null,
        nombre: '',
        esMaquina:true,
        fechaFabricacion:null,
        fechaCompra:null,
        imagenes: [],
        token: "",
        token2: ""
      },
      defaultItem: {
        idArea:-1,
        idMaquina:-1,
        idMaquinaCategoria: null,
        idMaquinaTipo: null,
        nombre: '',
        esMaquina:true,
        fechaFabricacion:null,
        fechaCompra:null,
        imagenes: [],
        token: "",
        token2: ""
      },
      categorias:[],
      loadingCategoria: false,
      clasificaciones:[],
      loadingClasificacion: false,
      cargaMasiva: false,
			ficheroCarga: null,
			cargaCargaCompleta: false,
			loadingCargaMasiva: false,
			errorCargaMasiva: false,
			selectedUpdateFile: null,
    }),

    computed: {
      ...mapState(["token"]),
      formTitle () {
        return this.editedIndex === -1 ? 'Nueva '+this.tipoLabel : 'Editar '+this.tipoLabel
      },
      tipoLabel(){
        if(this.view){
          return  this.viewedItem.esMaquina?'Máquina' : 'Herramienta';
        }
        if(this.dialog){
          return  this.editedItem.esMaquina?'Máquina' : 'Herramienta';
        }
      },
      catMaquinasFiltrado(){
        let me = this;
        return this.catMaquinas.filter(function(el) {
          return (el.esMaquina === me.editedItem.esMaquina && el.idMaquinaCategoria == me.editedItem.idMaquinaCategoria);
        });
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
      this.initialize();
    },
    methods: {
      marcaModelo: item => item.marca +' - '+item.modelo,
      resetIdMaquina(){
        this.editedItem.idMaquina = -1;
      },
      getNombreCompleto(idPersona){
        var o = this.personas.find(x => x.idPersona == idPersona);
        if(o != null) return o.nombre+" "+o.paterno+" "+o.materno;
        else return "";
      },
      agregarImagenDB(){
        let me = this;
        let mime = me.imagenVistaPrevia.substring(5,me.imagenVistaPrevia.indexOf(";"));
        let data = me.imagenVistaPrevia.substring(me.imagenVistaPrevia.indexOf("base64,")+7);
        me.erroresImagen = [];
        
        if(data == null || data == '' || mime == null || mime == ''){
          me.erroresImagen.push('Falta Seleccionar una Imagen');
        }

        if(me.uploadImg.descripcion.length<5){
          me.erroresImagen.push('La Descripción debe de tener al menos de 5 caracteres');
        }

        if(me.erroresImagen.length>0){
          return;
        }
        axios
          .post("/MaquinaInstancias/AgregarImagen", {
            IdMaquinaIns: me.viewedItem.idMaquinaIns,
            Mime:mime,
            Imagen: data,
            Descripcion:me.uploadImg.descripcion
          })
          .then(response =>  {
            me.dialogAddImg = false;
            me.imagenVistaPrevia = '';
            me.imagenArchivo=null;
            me.uploadImg = me.defaultUploadImg;
            me.getThumbnails(me.viewedItem.idMaquinaIns);
          })
          .catch(error => {
            console.log(error);
        });
      },
      getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      },
      vistaPrevia(){
        let me = this;
        if(me.imagenArchivo != null){
          this.getBase64(me.imagenArchivo).then(
            data => {
              me.imagenVistaPrevia = data;
            }
          );
        }
        else{
          me.imagenVistaPrevia = '';
        }
      },
      agregarImagen(){
        this.dialogAddImg = true;
      },
      getCatMaquinas (){
        let me = this;
        me.loadingCatMaquinas = true;
        axios
        .get("/Maquinas/Basico")
        .then(response =>  {
          me.catMaquinas = response.data;
          me.loadingCatMaquinas = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      getAreas (){
        let me = this;
        me.loadingArea = true;
        axios
        .get("/Areas")
        .then(response =>  {
          me.areas = response.data;
          me.loadingArea = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      getClasificaciones(){
        this.loadingClasificacion = true;
        axios
        .get("/MaquinaTipos")
        .then(response =>  {
          this.clasificaciones = response.data;
          this.loadingClasificacion = false;
        })
        .catch(error => {
          this.loadingClasificacion = false;
          console.log(error);
        });
      },
      getCategorias(){
        this.loadingCategoria = true;
        axios
        .get("/MaquinaCategorias")
        .then(response =>  {
          response.data.push({
            idMaquinaCategoria: null,
            nombre: "SIN ASIGNAR",
            descripcion:"",
            condicion: true,
          });
          this.categorias = response.data;
          this.loadingCategoria = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      getOperadores (){
        let me = this;
        me.loadingOperador = true;
        axios
        .get("/Personas/OperadoresBasico")
        .then(response =>  {
          me.personas = response.data;
          me.loadingOperador = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      getHabilidades (){
        let me = this;
        me.loadingHabilidades = true;
        axios
        .get("/Habilidades")
        .then(response =>  {
          me.habilidades = response.data;
          me.loadingHabilidades = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      imagenCompleta(item){
        let me = this;
        me.dialogImg = true;
        axios
        .get("/MaquinaInstancias/ImagenCompleta/"+me.viewedItem.idMaquinaIns+"/imagen/"+item.idImagen)
        .then(response =>  {
          me.imagen = response.data;
        })
        .catch(error => {
          console.log(error);
        });
      },
      initialize () {
        let me = this;
        me.loading = true;
        me.getAreas();
        me.getHabilidades();
        me.getOperadores();
        me.getCatMaquinas();
        me.getCategorias();
        this.getClasificaciones();
        axios
        .get("/MaquinaInstancias")
        .then(response =>  {
          me.maquinas = response.data;
          me.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      getThumbnails(idMaquina){
        let me = this;
        axios
        .get("/MaquinaInstancias/GetThumbnails/"+idMaquina)
        .then(response =>  {
          me.viewedItem.imagenes = response.data;
        })
        .catch(error => {
          console.log(error);
        });
      },
      viewItem (item) {
        this.viewedIndex = this.maquinas.indexOf(item)
        this.viewedItem = Object.assign({}, item)
        this.getThumbnails(this.viewedItem.idMaquinaIns);
        this.view = true
      },
      editItem (item) {
        this.action = 1;
        this.editedIndex = this.maquinas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.eliminar = true;
        this.delItem = item;
      },
      deleteImgDB(){
        let me = this;
        axios
        .delete("/MaquinaInstancias/EliminarImagen/"+me.viewedItem.idMaquinaIns+"/imagen/"+me.imagen.idImagen)
        .then(response =>  {
          me.imagen=me.defaultImagen;
          me.getThumbnails(me.viewedItem.idMaquinaIns);
        })
        .catch(error => {
          console.log(error);
        });
      },
      deleteItemDB(){
        let me = this;
        let item = this.delItem;
        this.delItem = '';
        axios
          .put("/MaquinaInstancias/Desactivar/" + item.idMaquina)
          .then(response =>  {
            me.initialize();
          })
          .catch(error => {
            console.log(error);
          });
      },
      close () {
        this.errorEsMaquina = "";
        this.erroridMaquinaCategoria = "";
        this.erroridArea = "";
        this.erroridMaquina = "";
        this.errornombre = "";
        this.errorfechaFabricacion = "";
        this.errorfechaCompra = "";
        this.errortoken = "";
        this.errortoken2 = "";
        this.action = 0,
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },
      makeValidation(string, min, max, msgError){
        if (string?.length < min || string?.length > max)
        {
            return msgError;
        }
        else return null;
      },
      validate(){
        this.errores =  [];
        this.errorEsMaquina = "";
        this.erroridMaquinaCategoria = "";
        this.erroridArea = "";
        this.erroridMaquina = "";
        this.errornombre = "";
        this.errorfechaFabricacion = "";
        this.errorfechaCompra = "";
        this.errortoken = "";
        this.errortoken2 = "";

        if(this.editedItem.esMaquina == null){
          this.errorEsMaquina = "Debe indicar el Tipo";
        }

        if(this.editedItem.idMaquinaCategoria == null){
          this.erroridMaquinaCategoria = "Debe indicar la Categoría";
        }

        if(this.editedItem.idArea == -1 || this.editedItem.idArea == null){
          this.erroridArea = 'Debe de seleccionar una Área';
        }
        if(this.editedItem.idMaquina == -1 || this.editedItem.idMaquina == null){
          this.erroridMaquina = 'Debe de seleccionar una '+this.tipoLabel;
        }

        var val = null;
        val = this.makeValidation(this.editedItem.nombre,1,50,"El nombre / código debe tener más de 1 caracteres y menos de 50.");
        if(val != null) this.errornombre = val;

        if(this.editedItem.fechaFabricacion == null ){
          this.errorfechaFabricacion = "Debe indicar la Fecha de Fabricación";
        }

        if(this.editedItem.fechaCompra == null ){
          this.errorfechaCompra = "Debe indicar la Fecha de Compra";
        }

        if(this.editedItem.token?.length > 255){
          this.errortoken = 'El Token debe tener una longitud menor a 255 caracteres.';
        }

        if(this.editedItem.token2?.length > 255){
          this.errortoken2 = 'El Token2 debe tener una longitud menor a 255 caracteres.';
        }

        return this.erroridArea == "" && this.erroridMaquina == "" && this.errornombre == "" &&
          this.errorfechaFabricacion == "" && this.errorfechaCompra == "" && 
          this.errortoken == "" && this.errortoken2 == "";
      },
      save () {
        this.action = 0;
        if(this.validate()){
          let me = this;
          me.saving = true;
          if (this.editedIndex > -1)
          {
            //Código para editar
            axios
              .put("/MaquinaInstancias/Actualizar/"+me.maquinas[me.editedIndex].idMaquinaIns, {
                IdArea: me.editedItem.idArea,
                IdMaquina:me.editedItem.idMaquina,
                idMaquinaTipo: this.editedItem.idMaquinaTipo,
                Nombre:me.editedItem.nombre,
                FechaFabricacion: me.editedItem.fechaFabricacion,
                FechaCompra: me.editedItem.fechaCompra,
                token: this.editedItem.token,
                token2: this.editedItem.token2
              })
              .then(response =>  {
                me.saving = false;
                me.close();
                me.initialize();
              })
              .catch(error => {
                me.saving = false;
                if (error.response) {
                  if(error.response.status == 409){
                    me.alerta = true;
                    me.errores.push("Existe una Máquina/Herramienta Activa con el Mismo Código");
                  }
                }
              });
          }
          else
          {
            axios
              .post("/MaquinaInstancias/Crear", {
                IdArea: me.editedItem.idArea,
                IdMaquina:me.editedItem.idMaquina,
                idMaquinaTipo: this.editedItem.idMaquinaTipo,
                Nombre:me.editedItem.nombre,
                FechaFabricacion: me.editedItem.fechaFabricacion,
                FechaCompra: me.editedItem.fechaCompra,
                token: this.editedItem.token,
                token2: this.editedItem.token2
              })
              .then(response =>  {
                me.saving = false;
                me.close();
                me.initialize();
              })
              .catch(error => {
                me.saving = false;
                if (error.response) {
                  if(error.response.status == 409){
                    me.alerta = true;
                    me.errores.push("Existe una Máquina/Herramienta Activa con el Mismo Código");
                  }
                }
              });
          }
        }
      },
      cargaMasivaPost(){
			this.loadingCargaMasiva = true;
			if (this.ficheroCarga !== null && this.ficheroCarga !== undefined) {
				let formData = new FormData();
				formData.append("formfile", this.ficheroCarga);
				axios
					.post("/MaquinaInstancias/CargaMasivaMaquinasInstancia", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					})
					.then((response) => {
            //console.log(response.data)
            this.erroresCarga = response.data
            console.log(this.erroresCarga)
						this.cargaCargaCompleta = true;
						this.loadingCargaMasiva = false;
						this.initialize();
					})
					.catch((error) => {
						console.log(error);
						this.errorCargaMasiva = true;
						this.loadingCargaMasiva = false;
					});
			}
		
    },
    descargarFormato(){
      var datetime = moment().format('YYYYMMDDHHmmss');
      this.$utils.axios.downloadFile('/MaquinaInstancias/PlantillaCargaMasivaMaquinasInstancia?access_token='+this.token,'GET','CargaMasiva_Maquinas_'+datetime+'.csv','text/csv');
    }
    },
  }
</script>