import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VTabs,{attrs:{"background-color":"transparent","grow":"","centered":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTab,{attrs:{"href":"#tab-1"},on:{"click":_vm.updateSemanal}},[_vm._v(" Semanal ")]),_c(VTab,{attrs:{"href":"#tab-2"},on:{"click":_vm.updateMensual}},[_vm._v(" Mensual ")]),_c(VTab,{attrs:{"href":"#tab-3"},on:{"click":_vm.updateComparativa}},[_vm._v(" Comparativo Mensual ")]),_c(VTabsItems,{attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,{key:1,attrs:{"value":'tab-1'}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,{staticClass:"pa-0"},[_c('GraficasMMCall',{ref:"graphweek",attrs:{"selectores":_vm.selectores,"departamentos":_vm.departamentos,"areas":_vm.areas,"type":1}})],1)],1)],1),_c(VTabItem,{key:2,attrs:{"value":'tab-2'}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,{staticClass:"pa-0"},[_c('GraficasMMCall',{ref:"graphMonth",attrs:{"selectores":_vm.selectores,"departamentos":_vm.departamentos,"areas":_vm.areas,"type":2}})],1)],1)],1),_c(VTabItem,{key:3,attrs:{"value":'tab-3'}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,{staticClass:"pa-0"},[_c('ComparativoMensual',{ref:"comparativa",attrs:{"areas":_vm.areas,"departamentos":_vm.departamentos}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }