<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12" v-if="!loading" class="text-center">
        <div class="text-h6">Líneas del Día en Riesgo</div>
      </v-col>
    </v-row>
    <v-row>
			<v-col cols="4">
				<v-btn 
					text 
					color="primary" 
					class="my-0"
					:loading="sending"
					@click="enviarReporte()"
					>Enviar Reporte de<br />Líneas en Riesgo</v-btn
				>
			</v-col>
		</v-row>
    <v-row>
      <v-col cols="12">
        <v-progress-linear v-if="loading" color="primary" height="5" indeterminate ></v-progress-linear>
        <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import axios from "axios"

export default {
  data:() => ({
    loading: false,
    sending: false,
    series:[],
    chartOptionsDefault: {
      colors: ['#B71C1C'],
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: '% Percentage'
        },
        labels: {
          formatter: function (val) {
            return val.toFixed(2)+"%";
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val.toFixed(2) +"%";
          }
        }
      }
    },
    chartOptions: {
      colors: ['#B71C1C'],
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: '% Percentage'
        },
        labels: {
          formatter: function (val) {
            return val.toFixed(2)+"%";
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val.toFixed(2) +"%";
          }
        }
      }
    }
  }),
  mounted(){
    this.initialize();
  },
  methods:{
  	initialize() {
  		this.getDatos();
		},
    getDatos(){
      this.loading = true;
      axios
      .get("/AnaliticaMonterrey/LineasEnRiesgoGrafica")
      .then(response => {
        this.series = response.data.series;
        this.chartOptions = Object.assign({},this.chartOptionsDefault);
        this.chartOptions.xaxis.categories = response.data.categorias;
        this.loading = false;
      })
      .catch(error => {
        console.log(error);
        this.loading = false;
      })
    },
    enviarReporte(){
      this.sending = true;
      axios
      .get("/AnaliticaMonterrey/LineasEnRiesgoEmail")
      .then(() => {
        this.sending = false;
      })
      .catch(error => {
        this.sending = false;
      })
    }
  }
}
</script>