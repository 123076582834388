<template>
  <!-- <v-container> -->
    <v-row justify-sm="center">
      <v-card 
        class="mt-4"
        :width="anchoMaximo"
        fill
      >
        <v-tabs show-arrows v-model="tab" :background-color="colorTabs" centered dark icons-and-text>
          <v-tabs-slider></v-tabs-slider>

          <v-tab v-tooltip.bottom="modoAyuda && $help.configuracion.planos" href="#tab-1" v-if="mostrarVista('planos')" @click="updatePlano()">
            Planos
            <v-icon>mdi-map</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.configuracion.areas" href="#tab-2" v-if="mostrarVista('areas')" @click="updateAreas()">
            Áreas
            <v-icon>mdi-texture-box</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.configuracion.almacenes" href="#tab-3"  v-if="mostrarVista('almacenes') && inventario" @click="updateAlmacenes()">
            Almacenes
            <v-icon>mdi-warehouse</v-icon>
          </v-tab>
          
          <v-tab href="#tab-4" v-if="mostrarVista('desconocidos') && desconocidos" @click="updateDesconocidos()">
            Desconocidos
            <v-icon>mdi-help</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.configuracion.maquinasHerramientas.title" href="#tab-5" v-if="mostrarVista('maquinas')" @click="updateMaquinas()">
            Máquinas y<br>Herramientas
            <v-icon>mdi-robot-industrial</v-icon>
          </v-tab>
          <v-tab v-tooltip.bottom="modoAyuda && $help.almacen.configuracionAlmacen.title" href="#tab-14" v-if="mostrarVista('almacen')" @click="updateAlmacen()">
            Configuración<br/>Almacén
            <v-icon>mdi-warehouse</v-icon>
          </v-tab>
          <v-tab v-tooltip.bottom="modoAyuda && $help.configuracion.articulos" href="#tab-6" v-if="mostrarVista('articulos2')" @click="updateArticulos2()">
            Artículos
            <v-icon>mdi-clipboard-text-outline</v-icon>
          </v-tab>
          
          <v-tab v-tooltip.bottom="modoAyuda && $help.configuracion.recetas" href="#tab-7" v-if="mostrarVista('recetas')" @click="updateRecetas()">
            Recetas
            <v-icon>mdi-cog-transfer-outline</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.configuracion.recetasAutomaticas" href="#tab-8" v-if="mostrarVista('recetasautomaticas')" @click="updateRecetasAutomaticas()">
            Recetas<br/>Automáticas
            <v-icon>mdi-file-cog</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.configuracion.catsubpasos" href="#tab-9" v-if="mostrarVista('catsubpasos')" @click="updateCatalogoSubpasos()">
            Catálogo de<br>Subpasos
            <v-icon>mdi-ballot-outline</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.configuracion.costodestajo" href="#tab-10" v-if="mostrarVista('costodestajo')" @click="updateCostosDestajo()">
            Configuración<br>de Subpasos
            <v-icon>mdi-multicast</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.configuracion.usuarios" href="#tab-11"  v-if="mostrarVista('usuarios')" @click="updateUsuarios()">
            Usuarios
            <v-icon>mdi-account</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.configuracion.estaciones" href="#tab-12"  v-if="mostrarVista('produccionusuarios')" @click="updateProduccionUsuarios()">
            Estaciones de<br>Trabajo
            <v-icon>mdi-desktop-classic</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.configuracion.sincronizacion" href="#tab-13" v-if="mostrarVista('sincronizacion-externa')" @click="updateSync()">
            Sincronización<br>Externa
            <v-icon>mdi-file-refresh</v-icon>
          </v-tab>
          
        </v-tabs>

        <v-tabs-items v-model="tab">

          <v-tab-item :key="1" :value="'tab-1'">
            <v-card flat>
              <v-card-text>
                <OTSPlano 
                  @detalleIncidencia="detalleIncidencia" 
                  ref="planos" 
                />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="2" :value="'tab-2'" v-if="mostrarVista('areas')">
            <v-card flat color="gray">
              <v-card-text>
                <configuracionArea ref="areas"></configuracionArea>
              </v-card-text>
            </v-card>
          </v-tab-item> 

          <v-tab-item :key="3" :value="'tab-3'"  v-if="mostrarVista('almacenes') && inventario">
            <v-card flat>
              <v-card-text>
                <ListaAlmacen ref="listadoAlmacen" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="4" :value="'tab-4'" v-if="mostrarVista('desconocidos') && desconocidos">
            <v-card flat color="gray">
              <v-card-text>
                <configuracionDesconocido ref="desconocidos"></configuracionDesconocido>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="5" :value="'tab-5'" v-if="mostrarVista('maquinas')">
            <v-card flat>
              <v-card-text class="pt-2">
                <Recursos ref="maquinas" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="6" :value="'tab-6'" v-if="mostrarVista('articulos2')">
            <v-card flat color="gray">
              <v-card-text>
                <produccionArticulo ref="articulos2"></produccionArticulo>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="7" :value="'tab-7'" v-if="mostrarVista('recetas')">
            <v-card flat color="gray">
              <v-card-text>
                <produccionReceta ref="recetas"></produccionReceta>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="8" :value="'tab-8'">
            <v-card flat color="gray">
              <v-card-text>
                <RecetasAuto ref="recetas" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="9" :value="'tab-9'" v-if="mostrarVista('catsubpasos')">
            <v-card flat color="gray">
              <v-card-text>
                <CatalogoSubpasos ref="catsubs" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="10" :value="'tab-10'" v-if="mostrarVista('costodestajo')">
            <v-card flat color="gray">
              <v-card-text>
                <CostoDestajo ref="destajo" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="11" :value="'tab-11'" v-if="mostrarVista('usuarios')">
            <v-card flat color="gray">
              <v-card-text>
                <seguridadUsuario ref="usuarios"></seguridadUsuario>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="12" :value="'tab-12'" v-if="mostrarVista('produccionusuarios')">
            <v-card flat color="gray">
              <v-card-text>
                <seguridadProduccionUsuario ref="produccionusuarios"></seguridadProduccionUsuario>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="13" :value="'tab-13'"  v-if="mostrarVista('sincronizacion-externa')">
            <v-card flat color="gray">
              <v-card-text>
                <Sincronizacion ref="sync" />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item :key="14" :value="'tab-14'"  v-if="mostrarVista('almacen')">
            <v-card flat color="gray">
              <v-card-text>
                <configuracionAlmacen ref="almacen"></configuracionAlmacen>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-row>
  <!-- </v-container> -->
</template>

<script>
import Area from "./Configuracion/Area.vue";
import Desconocido from "./Configuracion/Desconocido.vue";
import { mapState } from 'vuex'
import VSelectTags from '@/components/VSelectTags.vue';
import Plano from './OTS/Planos.vue';
import Articulo from "./Produccion/Articulo.vue";
import Receta from "./Produccion/Receta.vue";
import usuario from "./Seguridad/Usuario.vue";
import produccionusuario from "./Seguridad/ProduccionUsuario.vue";
import Sincronizacion from './Seguridad/Sincronizacion.vue';
import MaquinaInstancia from "./Recursos/MaquinaInstancia.vue";
import Recursos from './Recursos.vue';
import CostoDestajo from './Configuracion/CostoDestajo.vue';
import CatalogoSubpasos from './Configuracion/CatalogoSubpasos.vue';
import RecetasAuto from './Inventario/RecetasAuto.vue';
import ListaAlmacen from './Inventario/ListaAlmacen.vue';
import Almacen from "./Configuracion/Almacen.vue";

export default {
  data() {
    return {
      tab: null,
    };
  },
  computed: {
    ...mapState({
        desconocidos: 'desconocidos',
        inventario: 'inventario',
        anchoMaximo: 'anchoMaximo',
        colorTabs: 'colorTabs',
        modoAyuda: 'modoAyuda'
    })
  },
  methods:{
    updateRecetasAutomaticas(){
      if(typeof this.$refs.recetas === 'undefined') return;
      this.$refs.recetas.initialize();
    },
    updateCatalogoSubpasos(){
      if(typeof this.$refs.catsubs === 'undefined') return;
      this.$refs.catsubs.initialize();
    },
    updateCostosDestajo(){
      if(typeof this.$refs.destajo === 'undefined') return;
      this.$refs.destajo.initialize();
    },
    detalleIncidencia(idArea){
      this.$router.push(`/dashboard/calidad?tab=tab-4&idArea=${idArea}`)
    },
    updateAlmacenes(){
      if(typeof this.$refs.listadoAlmacen === 'undefined') return;
      this.$refs.listadoAlmacen.initialize();
    },
    updatePlano(){
      if(typeof this.$refs.planos === 'undefined') return;
      this.$refs.planos.initialize();
    },
    updateAreas() {
      if(typeof this.$refs.areas === 'undefined') return;
      this.$refs.areas.initialize();
    },
    updateDesconocidos() {
      if(typeof this.$refs.desconocidos === 'undefined') return;
      this.$refs.desconocidos.initialize();
    },
    updateArticulos2(){
      if(typeof this.$refs.articulos2 === 'undefined') return;
      this.$refs.articulos2.initialize();
    },
    updateRecetas(){
      if(typeof this.$refs.recetas === 'undefined') return;
      this.$refs.recetas.initialize();
    },
    updateUsuarios(){
      if(typeof this.$refs.usuarios === 'undefined') return;
      this.$refs.usuarios.initialize();
    },
    updateProduccionUsuarios(){
      if(typeof this.$refs.produccionusuarios === 'undefined') return;
      this.$refs.produccionusuarios.initialize();
    },
    updateSync(){
      if(typeof this.$refs.sync === 'undefined') return;
      this.$refs.sync.initialize();
    },
    updateMaquinas(){
      if(typeof this.$refs.maquinas === 'undefined') return;
      this.$refs.maquinas.initialize();
    },
    updateAlmacen(){
      if(typeof this.$refs.almacen === 'undefined') return;
      this.$refs.almacen.refresh();
    }
  },
  components: {
    configuracionArea: Area,
    configuracionDesconocido: Desconocido,
    VSelectTags,
    OTSPlano: Plano,
    produccionArticulo: Articulo,
    produccionReceta: Receta,
    seguridadUsuario: usuario,
    seguridadProduccionUsuario: produccionusuario,
    Sincronizacion,
    recursosMaquinaInstancia: MaquinaInstancia,
    Recursos,
    CostoDestajo,
    CatalogoSubpasos,
    RecetasAuto,
    ListaAlmacen,
    configuracionAlmacen: Almacen,
  }
};
</script>