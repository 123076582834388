<template>
	<div>
		<v-tabs v-model="tab" background-color="transparent" grow>
			<v-tab
				v-tooltip.bottom="modoAyuda && $help.calidad.listasComprobacion.resultados"
				@click="resultadosClick"
			>
				Resultados
			</v-tab>
			<v-tab
				v-tooltip.bottom="modoAyuda && $help.calidad.listasComprobacion.configuracion"
				@click="configuracionClick"
			>
				Configuración
			</v-tab>

			<v-tabs-items v-model="tab" touchless>
				<v-tab-item
					eager
				>
					<Resultados ref="resul" />
				</v-tab-item>
				<v-tab-item
					eager
				>
					<Configuracion ref="config" />
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import Configuracion from './ListasComprobacion/Configuracion.vue';
import Resultados from './ListasComprobacion/Resultados.vue';

export default {
	components: {
        Resultados,
        Configuracion
	},
	data: () => ({
		tab: 0,
	}),
	computed:{
		...mapState(["modoAyuda"])
	},
	methods: {
		initialize() {
			this.resultadosClick();
			this.configuracionClick();
		},
		resultadosClick(){
			if(typeof this.$refs.resul === 'undefined') return;
			this.$refs.resul.initialize();
		},
		configuracionClick(){
			if(typeof this.$refs.config === 'undefined') return;
			this.$refs.config.initialize();
		},
	},
};
</script>