import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.value,"persistent":"","max-width":"600px"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Download "+_vm._s(_vm.downloadItem.type == 1 ? "Emergency Form" : "RFQ")+" Files ")]),_c(VCardText,[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"items":_vm.downloadItem.files,"headers":_vm.downloadsHeaders,"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.filename",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.downloadItem.type == 1 ? item.emergencyFormFileOriginalName : item.rfqFileOriginalName)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"color":"primary","disabled":item.downloading},on:{"click":function($event){return _vm.downloadFile(
													item,
													_vm.downloadItem.type
												)}}},on),[_vm._v("mdi-file-download")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Download File")])])]}}],null,true)})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"success"},on:{"click":function($event){return _vm.$emit('click:close')}}},[_vm._v("Close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }