<template>
	<v-container fluid>
		<v-row>
			<v-col cols="2">
				<v-autocomplete
					v-model="filtros.desde"
					label="Desde (Obligatorio)"
					:items="meses"
					item-text="nombre"
					item-value="valor"
					clearable
					hide-details
					:disabled="loadingMeses || loading"
					:loading="loadingMeses"
					@change="onChangeDesde"
				></v-autocomplete>
			</v-col>
			<v-col cols="2">
				<v-autocomplete
					v-model="filtros.hasta"
					label="Hasta (Obligatorio)"
					:items="mesesPermitidos"
					item-text="nombre"
					item-value="valor"
					:placeholder="
						filtros.desde == null ? 'Seleccione la fecha Desde' : ''
					"
					:persistent-placeholder="filtros.desde == null"
					clearable
					:disabled="filtros.desde == null || loading"
					hide-details
					@change="onChangeHasta"
				></v-autocomplete>
			</v-col>
			<v-col cols="3">
				<v-autocomplete
					v-model="filtros.area"
					label="Área (Opcional)"
					:items="areas"
					clearable
					:disabled="loading"
					hide-details
				></v-autocomplete>
			</v-col>
			<v-col cols="3">
				<v-autocomplete
					v-model="filtros.departamento"
					label="Departamento (Opcional)"
					:items="departamentos"
					clearable
					:disabled="loading"
					hide-details
				></v-autocomplete>
			</v-col>
			<v-col cols="2">
				<v-btn
					color="primary"
					style="min-width: 150px"
					:disabled="loading || loadingMeses"
					@click="onClickBuscar"
					>Buscar</v-btn
				>
			</v-col>
		</v-row>
		<v-row v-if="loading">
			<v-col cols="12" class="text-center">
				<p class="text-h4 text-center">Cargando</p>
				<v-progress-circular
					:size="80"
					:width="5"
					color="primary"
					indeterminate
				></v-progress-circular>
			</v-col>
		</v-row>
		<template v-else-if="hayData && filtrosValidos">
			<v-row>
				<v-col
					cols="12"
					class="d-flex align-center justify-space-between"
				>
					<v-btn
						text
						color="primary"
						class="my-0"
						:disabled="downloading1"
						:loading="downloading1"
						@click="descargarGraficas(1)"
						>Descargar Gráficas</v-btn
					>
					<v-btn
						text
						color="primary"
						class="my-0"
						:disabled="downloading2"
						:loading="downloading2"
						@click="descargarGraficas(2)"
						>Descargar Gráficas<br />para Plotter</v-btn
					>
				</v-col>
			</v-row>
			<v-row class="align-center justify-center">
				<v-col cols="6" v-for="index in 2" :key="`colgraph-${index}`">
					<p
						class="mb-1 text-h5 text-center font-weight-bold"
						:key="`title-${index}`"
					>
						{{ getKPIType(index) }}
					</p>
					<apexchart
						type="bar"
						height="350"
						:options="getChartOptions(index)"
						:series="getSeries(index)"
					></apexchart>
				</v-col>
				<v-col cols="6">
					<p class="mb-1 text-h5 text-center font-weight-bold">
						{{ getKPIType(3) }}
					</p>
					<apexchart
						type="bar"
						height="350"
						:options="getChartOptions(3)"
						:series="getSeries(3)"
					></apexchart>
				</v-col>
				<v-col cols="6">
					<p class="mb-1 text-h5 text-center font-weight-bold">
						{{ getKPIType(4) }}
					</p>
					<apexchart
						type="bar"
						height="350"
						:options="getChartOptions(4)"
						:series="getSeries(4)"
					></apexchart>
				</v-col>
			</v-row>
		</template>
		<v-row v-else-if="!filtrosValidos">
			<v-col cols="12">
				<v-alert text dense color="blue darken-2" class="mb-0">
					<div class="d-flex align-center justify-center py-3">
						<p class="mb-0" style="font-size: 16px !important">
							Debe seleccionar un intervalo de fechas
						</p>
					</div>
				</v-alert>
			</v-col>
		</v-row>
		<v-row v-else-if="!hayData && busquedaRealizada">
			<v-col cols="12">
				<v-alert text dense color="grey darken-2" class="mb-0">
					<div class="d-flex align-center justify-center py-3">
						<p class="mb-0" style="font-size: 16px !important">
							No hay datos disponibles
						</p>
					</div>
				</v-alert>
			</v-col>
		</v-row>
		<v-row v-else>
			<v-col cols="12">
				<v-alert text dense color="blue darken-2" class="mb-0">
					<div class="d-flex align-center justify-center py-3">
						<p class="mb-0" style="font-size: 16px !important">
							De click en el boton "Buscar"
						</p>
					</div>
				</v-alert>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import moment from 'moment';
export default {
	props: {
		areas: {
			type: Array,
			default: () => [],
		},
		departamentos: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			downloading1: false,
			downloading2: false,
			busquedaRealizada: false,
			loading: false,
			loadingMeses: false,
			seriesMTBF: [],
			seriesMTTR: [],
			seriesReliability: [],
			defaultChartOptions: {
				chart: {
					type: "bar",
					height: 350,
				},
				plotOptions: {
					bar: {
						horizontal: false,
						columnWidth: "55%",
						endingShape: "rounded",
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					show: true,
					width: 2,
					colors: ["transparent"],
				},
				xaxis: {
					labels: {
						rotate: -90,
					},
					categories: [],
				},
				yaxis: {
					min: 0,
				},
				fill: {
					opacity: 1,
				},
				tooltip: {},
			},
			chartOptionsDownTime: null,
			chartOptionsMTTR: null,
			chartOptionsMTBF: null,
			seriesDownTime: [],
			chartOptionsReliability: null,
			reliability: [],
			loadingReliability: false,
			filtros: {
				desde: null,
				hasta: null,
				area: null,
				departamento: null,
			},
			meses: [],
		};
	},
	computed: {
		hayData() {
			return (
				this.seriesDownTime.length > 0 &&
				this.seriesMTTR.length > 0 &&
				this.seriesMTBF.length > 0 &&
				this.seriesReliability.length > 0)
		},
		mesesPermitidos() {
			return this.meses.filter((mes) => mes.valor > this.filtros.desde);
		},
		filtrosValidos() {
			return this.filtros.desde != null && this.filtros.hasta != null;
		},
		...mapState(["token"]),
	},
	watch: {
		filtros() {
			if (this.filtros.desde == null || this.filtros.hasta == null) {
				this.resetValues();
			}
		},
	},
	beforeMount() {
		this.setDefaultChartOptions();
	},
	mounted() {
		this.initialize();
	},
	methods: {
		getYearValue: (value) => Number(value.toString().slice(0, 4)),
		getMonthValue: (value) => Number(value.toString().slice(4)),
		descargarGraficas(opcion) {
			const datetime = moment().format("YYYYMMDDHHmmss");
			const filename =
				`reporte-comparativoMensual-${opcion == 2 ? "tabloide-" : ""}` +
				datetime +
				".pdf";
			const mime = "application/pdf";
			this[`downloading${opcion}`] = true;
			axios({
				url:
					opcion == 1
						? "/AnaliticaMonterrey/DescargarPDFgraficasAgrupadasMes"
						: "/AnaliticaMonterrey/DescargarPDFGraficasTabloideAgrupadasMes",
				method: "GET",
				responseType: "blob",
				params: {
					access_token: this.token,
					anhioDesde: this.getYearValue(this.filtros.desde),
					mesDesde: this.getMonthValue(this.filtros.desde),
					anhioHasta: this.getYearValue(this.filtros.hasta),
					mesHasta: this.getMonthValue(this.filtros.hasta),
					area: this.filtros.area,
					departamento: this.filtros.departamento,
				},
			})
				.then((response) => {
					let blob = new Blob([response.data], { type: mime });
					let a = document.createElement("a");
					let blobURL = URL.createObjectURL(blob);
					a.download = filename;
					a.href = blobURL;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this[`downloading${opcion}`] = false;
				});
		},
		getSeries(index) {
			if (index == 1) return this.seriesDownTime;
			else if (index == 2) return this.seriesMTTR;
			else if(index == 3) return this.seriesMTBF;
			else return this.seriesReliability;
		},
		getChartOptions(index) {
			if (index == 1) return this.chartOptionsDownTime;
			else if (index == 2) return this.chartOptionsMTTR;
			else if(index == 3) return this.chartOptionsMTBF;
			else return this.chartOptionsReliability;
		},
		getKPIType(type) {
			return type == 1 ? "DOWN TIME" : type == 2 ? "MTTR" : type == 3 ? "MTBF" : "RELIABILITY";
		},
		setDefaultsDownTime() {
			this.seriesDownTime = [];
			this.chartOptionsDownTime = { ...this.defaultChartOptions };
			this.chartOptionsDownTime.colors = ["#D32F2F"];
			this.chartOptionsDownTime.fill = {
				colors: [
					function ({ value }) {
						if (value >= 0 && value <= 3.8) return "#008000";
						else return "#D32F2F";
					},
				],
			};
			this.chartOptionsDownTime.xaxis = Object.assign(
				{},
				this.chartOptionsDownTime.xaxis,
				{ categories: [] }
			);
			this.chartOptionsDownTime.yaxis = Object.assign(
				{},
				this.chartOptionsDownTime.yaxis,
				{
					title: {
						text: "% Percentage",
					},
					labels: {
						formatter: function (val) {
							return val.toFixed(2) + "%";
						},
					},
					min: 0,
					y: {
						formatter: function (val) {
							return val.toFixed(2) + "%";
						},
					},
				}
			);
			this.chartOptionsDownTime.tooltip = Object.assign(
				{},
				this.chartOptionsDownTime.tooltip,
				{
					y: {
						formatter: function (val) {
							return val.toFixed(2) + "%";
						},
					},
				}
			);
		},
		setDefaultsMTTR() {
			this.seriesMTTR = [];
			this.chartOptionsMTTR = { ...this.defaultChartOptions };
			this.chartOptionsMTTR.colors = ["#F57C00"];
			this.chartOptionsMTTR.fill = {
				colors: [
					function ({ value }) {
						if (value >= 0 && value <= 0.25) return "#008000";
						else return "#D32F2F";
					},
				],
			};
			this.chartOptionsMTTR.xaxis = Object.assign(
				{},
				this.chartOptionsMTTR.xaxis,
				{ categories: [] }
			);
			this.chartOptionsMTTR.yaxis = Object.assign(
				{},
				this.chartOptionsMTTR.yaxis,
				{
					title: {
						text: "Hours",
					},
					labels: {
						formatter: function (val) {
							return val.toFixed(2);
						},
					},
					min: 0,
				}
			);
			this.chartOptionsMTTR.tooltip = Object.assign(
				{},
				this.chartOptionsMTTR.tooltip,
				{
					y: {
						formatter: function (val) {
							return `${val.toFixed(2)} hrs`;
						},
					},
				}
			);
		},
		setDefaultsMTBF() {
			this.seriesMTBF = [];
			this.chartOptionsMTBF = { ...this.defaultChartOptions };
			this.chartOptionsMTBF.colors = ["#1976D2"];
			this.chartOptionsMTBF.fill = {
				colors: [
					function ({ value }) {
						if (value >= 12 && value <= 744) return "#008000";
						else return "#D32F2F";
					},
				],
			};
			this.chartOptionsMTBF.xaxis = Object.assign(
				{},
				this.chartOptionsMTBF.xaxis,
				{ categories: [] }
			);
			this.chartOptionsMTBF.yaxis = Object.assign(
				{},
				this.chartOptionsMTBF.yaxis,
				{
					title: {
						text: "Hours",
					},
					labels: {
						formatter: function (val) {
							return val.toFixed(2);
						},
					},
					min: 0,
				}
			);
			this.chartOptionsMTBF.tooltip = Object.assign(
				{},
				this.chartOptionsMTBF.tooltip,
				{
					y: {
						formatter: function (val) {
							return `${val.toFixed(2)} hrs`;
						},
					},
				}
			);
		},
		setDefaultsReliability() {
			this.seriesReliability = [];
			this.chartOptionsReliability = { ...this.defaultChartOptions };
			this.chartOptionsReliability.colors = ["#1976D2"];
			this.chartOptionsReliability.fill = {
			  colors: [function({ value, seriesIndex, w }) {
			    if(value >= 12 && value <= 744) return '#008000';
					else return "#D32F2F";
			  }]
			};
			this.chartOptionsReliability.xaxis = Object.assign(
				{},
				this.chartOptionsReliability.xaxis,
				{ categories: [] }
			);
			this.chartOptionsReliability.yaxis = Object.assign(
				{},
				this.chartOptionsReliability.yaxis,
				{
					title: {
						text: "% Percentage",
					},
					labels: {
						formatter: function (val) {
							return val.toFixed(2)+"%";
						},
					},
					min: 0,
				}
			);
			this.chartOptionsReliability.tooltip = Object.assign(
				{},
				this.chartOptionsReliability.tooltip,
				{
					y: {
						formatter: function (val) {
							return `${val.toFixed(2)}%`;
						},
					},
				}
			);
		},
		setDefaultChartOptions() {
			this.setDefaultsDownTime();
			this.setDefaultsMTTR();
			this.setDefaultsMTBF();
			this.setDefaultsReliability();
		},
		onChangeHasta() {
			this.resetValues();
		},
		onChangeDesde() {
			this.filtros.hasta = null;
		},
		initialize() {
			this.getMeses();
		},
		getMeses() {
			this.loadingMeses = true;

			axios
				.get("/MMCallEquipos/FechasAgrupadas")
				.then((response) => {
					this.meses = response.data;
				})
				.catch((error) => {
					this.meses = [];
					console.log(error);
				})
				.finally(() => {
					this.loadingMeses = false;
				});
		},
		getDatosGraficas() {
			this.loading = true;

			axios
				.get("/AnaliticaMonterrey/GraficasAgrupadasMes", {
					params: {
						anhioDesde: this.getYearValue(this.filtros.desde),
						mesDesde: this.getMonthValue(this.filtros.desde),
						anhioHasta: this.getYearValue(this.filtros.hasta),
						mesHasta: this.getMonthValue(this.filtros.hasta),
						area: this.filtros.area,
						departamento: this.filtros.departamento,
					},
				})
				.then((response) => {
					this.chartOptionsDownTime.xaxis = Object.assign(
						{},
						this.chartOptionsDownTime.xaxis,
						{ categories: response.data.downTime.categories }
					);
					this.seriesDownTime = response.data.downTime.series;

					this.chartOptionsMTTR.xaxis = Object.assign(
						{},
						this.chartOptionsMTTR.xaxis,
						{ categories: response.data.mttr.categories }
					);
					this.seriesMTTR = response.data.mttr.series;

					this.chartOptionsMTBF.xaxis = Object.assign(
						{},
						this.chartOptionsMTBF.xaxis,
						{ categories: response.data.mtbf.categories }
					);
					this.seriesMTBF = response.data.mtbf.series;

					this.chartOptionsReliability.xaxis = Object.assign(
						{},
						this.chartOptionsReliability.xaxis,
						{ categories: response.data.reliability.categories }
					);
					this.seriesReliability = response.data.reliability.series;
				})
				.catch((error) => {
					this.setDefaultChartOptions();
					console.log(error);
				})
				.finally(() => {
					this.busquedaRealizada = true;
					this.loading = false;
				});
		},
		onClickBuscar() {
			if (this.filtros.desde == null || this.filtros.hasta == null) {
				this.resetValues();
				return;
			}

			this.getDatosGraficas();
		},
		resetValues(resetFiltros = false) {
			this.loading = false;
			this.busquedaRealizada = false;
			if (resetFiltros)
				Object.keys(this.filtros).forEach(
					(key) => (this.filtros[key] = null)
				);
			this.setDefaultChartOptions();
		},
	},
};
</script>