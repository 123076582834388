<template>
	<v-card elevation="3">
		<v-card-text :class="computedBorderColor">
			<v-container fluid>
				<v-row>
					<v-col cols="12" class="pa-0 text-left">
						<p class="mb-0 text-body-2 text--primary">
							<span
								class="
									text-body-2 text--primary
									font-weight-bold
								"
								>{{ computedTipoText }}:</span
							>
							{{ getNombreTarea }}
						</p>
						<p class="mb-0 text-body-2 text--primary">
							<span
								class="
									text-body-2 text--primary
									font-weight-bold
								"
								>Máquina:</span
							>
							{{ item.maquinaIns }}
						</p>
						<p class="mb-0 text-body-2 text--primary">
							<span
								class="
									text-body-2 text--primary
									font-weight-bold
								"
								>Subdepartamento:</span
							>
							{{ item.nombreSubdepartamento || "N/D" }}
						</p>
						<p class="mb-0 text-body-2 text--primary">
							<span
								class="
									text-body-2 text--primary
									font-weight-bold
								"
								>Inicio:</span
							>
							{{ item.inicio }}
						</p>
						<p class="mb-0 text-body-2 text--primary">
							<span
								class="
									text-body-2 text--primary
									font-weight-bold
								"
								>Duración:</span
							>
							{{ item.duracion }}
						</p>
					</v-col>
				</v-row>
			</v-container>
		</v-card-text>
	</v-card>
</template>
<script>
export default {
	props: {
		item: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		getNombreTarea() {
			return `[${this.item.codigo}] - ${this.item.nombre}`.trim();
		},
		computedTipoText() {
			switch (this.item?.tipo) {
				case 1:
					return "Preventivo";
				case 2:
					return "Correctivo";
				case 3:
					return "Inspección";
				default:
					return "N/A";
			}
		},
		computedBorderColor() {
			switch (this.item.tipo) {
				case 1:
					return "border-left-green";
				case 3:
					return "border-left-pink";
				default:
					return "border-left-blue";
			}
		},
	},
};
</script>
<style scoped>
.border-left-green {
	border-left: 0.5rem solid #4caf50;
}

.border-left-pink {
	border-left: 0.5rem solid #e91e63;
}

.border-left-blue {
	border-left: 0.5rem solid #2196f3;
}
</style>