<template>
	<div>
		<v-tabs v-model="tab" background-color="transparent" grow>
			<v-tab
				v-tooltip.bottom="modoAyuda && $help.mantenimiento.catalogosMantenimiento.inspecciones"
				v-if="permisoVista('mantenimiento-inspecciones', 'r')"
				@click="inspeccionesClick"
			>
				Inspecciones
			</v-tab>
			<v-tab
				v-tooltip.bottom="modoAyuda && $help.mantenimiento.catalogosMantenimiento.mantPreventivo"
				v-if="permisoVista('cat-mantenimientopreventivo', 'r')"
				@click="preventivoClick"
			>
				Mantenimiento Preventivo
			</v-tab>
			<v-tab
				v-tooltip.bottom="modoAyuda && $help.mantenimiento.catalogosMantenimiento.mantCorrectivo"
				v-if="permisoVista('cat-mantenimientocorrectivo', 'r')"
				@click="correctivoClick"
			>
				Mantenimiento Correctivo
			</v-tab>

			<v-tabs-items v-model="tab" touchless>
				<v-tab-item
					eager
					v-if="permisoVista('mantenimiento-inspecciones', 'r')"
				>
					<Inspecciones ref="inspect" />
				</v-tab-item>
				<v-tab-item
					eager
					v-if="permisoVista('cat-mantenimientopreventivo', 'r')"
				>
					<CatalogoPreventivo ref="preventivo" />
				</v-tab-item>
				<v-tab-item
					eager
					v-if="permisoVista('cat-mantenimientocorrectivo', 'r')"
				>
					<CatalogoCorrectivo ref="correctivo" />
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>
	</div>
</template>

<script>
import CatalogoPreventivo from "./Catalogos/CatalogoPreventivo.vue";
import CatalogoCorrectivo from "./Catalogos/CatalogoCorrectivo.vue";
import Inspecciones from './Catalogos/Inspecciones.vue';
import { mapState } from 'vuex';

export default {
	components: {
		CatalogoPreventivo,
		CatalogoCorrectivo,
		Inspecciones,
	},
	data: () => ({
		tab: 0,
	}),
	computed:{
		...mapState(["modoAyuda"])
	},
	methods: {
		// cambio(event) {
		// 	if (this.tab == 0) this.$refs.preventivo.cambio(event);
		// 	if (this.tab == 1) this.$refs.correctivo.cambio(event);
		// },
		initialize() {
			this.preventivoClick();
			this.correctivoClick();
			this.inspeccionesClick();
		},
		preventivoClick() {
			if(typeof this.$refs.preventivo === 'undefined') return;
			this.$refs.preventivo.initialize();
		},
		correctivoClick() {
			if(typeof this.$refs.correctivo === 'undefined') return;
			this.$refs.correctivo.initialize();
		},
		inspeccionesClick(){
			if(typeof this.$refs.inspect === 'undefined') return;
			this.$refs.inspect.initialize();
		}
	},
};
</script>