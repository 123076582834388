<template>
	<v-dialog :value="value" persistent max-width="600px">
		<v-card>
			<v-card-title>
				Download
				{{ downloadItem.type == 1 ? "Emergency Form" : "RFQ" }} Files
			</v-card-title>
			<v-card-text>
				<v-container fluid>
					<v-row>
						<v-col cols="12">
							<v-data-table
								:items="downloadItem.files"
								:headers="downloadsHeaders"
								hide-default-footer
								:items-per-page="-1"
							>
								<template v-slot:[`item.filename`]="{ item }">
									{{
										downloadItem.type == 1
											? item.emergencyFormFileOriginalName
											: item.rfqFileOriginalName
									}}
								</template>
								<template v-slot:[`item.actions`]="{ item }">
									<v-tooltip
										bottom
										color="black"
										class="white--text"
									>
										<template v-slot:activator="{ on }">
											<v-icon
												v-on="on"
												color="primary"
												:disabled="item.downloading"
												@click="
													downloadFile(
														item,
														downloadItem.type
													)
												"
												>mdi-file-download</v-icon
											>
										</template>
										<span class="white--text"
											>Download File</span
										>
									</v-tooltip>
								</template>
							</v-data-table>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text color="success" @click="$emit('click:close')"
					>Close</v-btn
				>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import axios from 'axios';
export default {
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		downloadItem: {
			type: Object,
			default: () => ({
				type: null,
				files: [],
			}),
		},
	},
	data() {
		return {
			downloadsHeaders: [
				{
					text: "Filename",
					align: "center",
					value: "filename",
				},
				{
					text: "Actions",
					align: "center",
					sortable: false,
					value: "actions",
					width: "10%",
				},
			],
		};
	},
	methods: {
		downloadFile(item, type) {
			const fileName =
				type == 1 ? item.emergencyFormFileName : item.rfqFileName;
			const originalName =
				type == 1
					? item.emergencyFormFileOriginalName
					: item.rfqFileOriginalName;
			this.$set(item, "downloading", true);
			axios({
				url: `/Documentos/Descargar/${fileName}`,
				method: "GET",
				responseType: "blob",
			})
				.then((response) => {
					let blob = new Blob([response.data], {
						type: "application/octet-stream",
					});
					let a = document.createElement("a");
					let blobURL = URL.createObjectURL(blob);
					a.download = originalName;
					a.href = blobURL;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.$set(item, "downloading", false);
				});
		},
	},
};
</script>