import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCol,{attrs:{"cols":"12"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.desserts,"loading":_vm.cargando,"loading-text":"Loading... Please wait","sort-by":"calories"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{staticClass:"col-12"},[_c('div',{staticClass:"text-center"},[_c(VAutocomplete,{attrs:{"items":_vm.tags,"item-value":"idUsuarioReloj","item-text":"nombre","chips":"","label":"Usuario Reloj Checador","multiple":"","deletable-chips":""},model:{value:(_vm.idsSelec),callback:function ($$v) {_vm.idsSelec=$$v},expression:"idsSelec"}})],1)])],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Guardar ")])],1)],1)],1)]},proxy:true},{key:"item.idRelojChecador",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[(item.idRelojChecador.length <= 0)?_c(VChip,{staticClass:"d-flex align-center justify-center",staticStyle:{"min-width":"100px"},attrs:{"color":"red","text-color":"white"}},[_vm._v("Pendiente")]):_c(VChip,{staticClass:"d-flex align-center justify-center",staticStyle:{"min-width":"100px"},attrs:{"color":"green","text-color":"white"}},[_vm._v("Configurado")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"no-data",fn:function(){return [_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }