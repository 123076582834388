<template>
  <v-container>
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="eliminar"
          persistent
          max-width="500px"
          :key="eliminar"
        >
          <v-card>
            <v-card-title class="headline"
              >¿Está seguro que desea eliminar esta familia?</v-card-title
            >
            <v-card-text
              >Esta acción no se puede revertir y será permanente.</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="
                  eliminar = false;
                  delItem = '';
                "
                >Cancelar</v-btn
              >
              <v-btn
                color="red darken-1"
                text
                @click="
                  eliminar = false;
                  deleteItemDB();
                "
                >Aceptar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-dialog v-model="dialog" persistent max-width="500px" :key="dialog">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col justify="center" cols="12" sm="12" md="12">
                <v-text-field
                  v-model="editedItem.nombre"
                  label="Nombre de la Familia de Productos"
                ></v-text-field>
              </v-col>
              <v-col justify="center" cols="12" sm="12" md="12">
                <v-text-field
                  v-model="editedItem.descripcion"
                  label="Descripción de la Familia de Productos"
                ></v-text-field>
              </v-col>
            </v-row>
            <ul>
              <li class="red--text" v-for="ex in errores" :key="ex">
                {{ ex }}
              </li>
            </ul>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              dialog = false;
              errores = [];
            "
            >Cancelar</v-btn
          >
          <v-btn
            color="red darken-1"
            text
            @click="save()"
            :disabled="saving"
            :loading="saving"
          >
            Guardar
            <template v-slot:loader>
              <v-progress-circular
                indeterminate
                :width="2"
                :size="24"
                color="red"
              ></v-progress-circular>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" class="pa-0 d-flex align-center justify-space-between">
        <v-col v-if="!modoOrdenar">
          <v-btn
            v-if="permisoVista('almacen', 'c')"
            color="primary"
            dark
            class="mr-2"
            @click="dialog = true"
            >Nueva Familia de Productos</v-btn
          >
        </v-col>
        <v-col v-else>
          <v-btn
            color="red darken-2"
            class="mr-2"
            :disabled="savingOrden"
            @click="cancelModoOrdenar()"
            >Cancelar</v-btn
          >
          <v-btn
            color="success"
            :disabled="savingOrden"
            @click="saveOrden()"
            >Guardar</v-btn
          >
        </v-col>
        <v-col class="d-flex justify-center align-center">
          <v-btn
            v-if="permisoVista('almacen', 'u')"
            color="teal darken-2"
            class="mr-4"
            :disabled="modoOrdenar"
            :loading="savingOrden"
            @click="toggleModoOrdenar"
            >Ordenar</v-btn
          >
          <v-text-field
            v-model="search"
            append-icon="search"
            class="mb-4"
            label="Buscar"
            :disabled="modoOrdenar"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-col>
    </v-row>
    <v-data-table
      v-if="permisoVista('almacen', 'r')"
      :headers="computedHeaders"
      :items="familias"
      :search="search"
      :loading="loading"
      :items-per-page="computedItemsPerPage"
      :footer-props="{
        disableItemsPerPage: modoOrdenar 
      }"
      loading-text="Cargando... Espere, Por Favor."
      item-key="idArticuloFamilia"
    >
      <template v-slot:body="props">
        <draggable
          :list="props.items"
          tag="tbody"
          :disabled="!modoOrdenar"
          @change="listChanged(props.items)"
        >
          <DataTableRowHandler
            v-for="(item,index) in props.items"
            :key="index"
            :item="item"
            :headers="computedHeaders"
            :itemClass="getClass"
          >
            <template v-slot:[`item.handleIcon`]>
              <v-icon>mdi-menu</v-icon>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <div class="d-flex justify-center align-center">
                <v-tooltip bottom color="black" class="white--text">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on"
                      v-if="permisoVista('almacen', 'u')"
                      small
                      class="mr-2"
                      :disabled="modoOrdenar"
                      @click="editItem(item)"
                    >
                      edit
                    </v-icon>
                  </template>
                  <span class="white--text">Editar</span>
                </v-tooltip>
                <v-tooltip bottom color="black" class="white--text">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on"
                      v-if="permisoVista('almacen', 'd')"
                      small
                      :disabled="modoOrdenar"
                      @click="deleteItem(item)"
                    >
                      delete
                    </v-icon>
                  </template>
                  <span class="white--text">Eliminar</span>
                </v-tooltip>
              </div>
            </template>
          </DataTableRowHandler>
        </draggable>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
    </v-data-table>
    <v-snackbar v-model="alerta" top color="error">
      Existe una Familia Activa con el mismo nombre
      <v-btn text @click="alerta = false"> Cerrar </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";
import axios from "axios";
import DataTableRowHandler from '../../DataTableRowHandler.vue';

export default {
  components: {
    draggable,
    DataTableRowHandler
  },
  data: () => ({
    savingOrden: false,
    modoOrdenar: false,
    saving: false,
    dialog: false,
    alerta: false,
    eliminar: false,
    search: "",
    headers: [
      {
        text: "",
        align: "center",
        value: "handleIcon",
        sortable: false,
        width: "0.1%",
      },
      {
        text: "Familia de Productos",
        align: "left",
        sortable: true,
        value: "nombre",
      },
      {
        text: "Acciones",
        value: "action",
        sortable: false,
        width: "35%",
        align: "center",
      },
    ],
    loading: false,
    familias: [],
    nuevoOrden: [],
    delItem: "",
    action: 0,
    errores: [],
    editedIndex: -1,
    editedItem: {
      nombre: "",
      descripcion: "",
    },
    defaultItem: {
      nombre: "",
      descripcion: "",
    },
  }),

  computed: {
    computedItemsPerPage(){
      return this.modoOrdenar ? -1 : 10;
    },
    getClass(){
      let clases = "table-font";
      if(this.modoOrdenar) clases += ' item-movible'
      return clases;
    },
    computedHeaders(){
      return this.modoOrdenar 
        ? this.headers.map( header => ({
          ...header,
          sortable: false
        }))
        : this.headers.filter( header => !(header.value == "handleIcon"));
    },
    formTitle() {
      return this.editedIndex === -1 ? "Nueva Familia de Productos" : "Editar Familia de Productos";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    cancelModoOrdenar(){
      this.nuevoOrden = [];
      this.modoOrdenar = false;
    },
    toggleModoOrdenar(){
      this.nuevoOrden = [];
      this.modoOrdenar = !this.modoOrdenar;
    },
    saveOrden(){
      this.savingOrden = true;

      const guardarOrden = this.nuevoOrden.map( (item,index) => ({
        idArticuloFamilia: item.idArticuloFamilia,
        prioridad: index
      }));

      axios
        .put('/ArticuloFamilias/ModificarPosicionesFamilia',guardarOrden)
        .then( () => {})
        .catch(console.log)
        .finally( async() => {
          await this.getArticulosFamilias();
          this.savingOrden = false;
          this.cancelModoOrdenar();
        })

    },
    listChanged(items){
      this.nuevoOrden = [...items];
    },
    initialize() {
      this.cancelModoOrdenar();
      this.getArticulosFamilias();
    },
    getArticulosFamilias(){
      return new Promise( resolve => {
        this.loading = true;
        axios
          .get("/ArticuloFamilias")
          .then((response) => {
            this.familias = response.data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally( () => {
            resolve();
          });
      });
    },
    editItem(item) {
      this.action = 1;
      this.editedIndex = this.familias.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.eliminar = true;
      this.delItem = item;
    },
    deleteItemDB() {
      let me = this;
      let item = this.delItem;
      this.delItem = "";
      axios
        .put("/ArticuloFamilias/Desactivar/" + item.idArticuloFamilia)
        .then((response) => {
          me.initialize();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    close() {
      (this.action = 0), (this.dialog = false);
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    validate() {
      this.errores = [];
      if (
        this.editedItem.nombre.length < 2 ||
        this.editedItem.nombre.length > 50
      ) {
        this.errores.push(
          "El nombre debe tener más de 4 caracteres y menos de 50."
        );
        return false;
      }
      return true;
    },
    save() {
      this.action = 0;
      if (this.validate()) {
        let me = this;
        me.saving = true;
        if (this.editedIndex > -1) {
          //Código para editar
          axios
            .put(
              "/ArticuloFamilias/Actualizar/" +
                me.familias[me.editedIndex].idArticuloFamilia,
              {
                Nombre: me.editedItem.nombre,
                Descripcion: me.editedItem.descripcion,
              }
            )
            .then((response) => {
              me.saving = false;
              me.close();
              me.initialize();
            })
            .catch((error) => {
              me.saving = false;
              if (error.response) {
                if (error.response.status == 409) {
                  me.alerta = true;
                  me.errores.push(
                    "Existe una Familia de Productos Activa con el Mismo Nombre"
                  );
                }
              }
            });
        } else {
          axios
            .post("/ArticuloFamilias/Crear", {
              Nombre: me.editedItem.nombre,
              Descripcion: me.editedItem.descripcion,
            })
            .then((response) => {
              me.saving = false;
              me.close();
              me.initialize();
            })
            .catch((error) => {
              if (error.response) {
                me.saving = false;
                if (error.response.status == 409) {
                  me.alerta = true;
                  me.errores.push(
                    "Existe una Familia de Productos Activa con el Mismo Nombre"
                  );
                }
              }
            });
        }
      }
    },
  },
};
</script>
<style scoped>
.table-font >>> div {
  font-size: .875rem !important;
}

.item-movible {
  cursor: move;
}
</style>