// https://vuex.vuejs.org/en/actions.html
import axios from 'axios'
import router from '@/router'

//

// The login action passes vuex commit helper that we will use to trigger mutations.
export default {
  getIdsTurnos({commit}){
    var idsTurnos = [];    
    try{
      idsTurnos = JSON.parse(localStorage.getItem('idsTurnos'));
    }
    catch(er){
      try{
        idsTurnos = localStorage.getItem('idsTurnos').split(',').map(x => parseInt(x));
      }
      catch(er2){
        idsTurnos = [];
      }
    }
    if(idsTurnos == null) idsTurnos = [];
    commit('set_ids_turnos',idsTurnos);
  },
  toggleModoAyuda({ commit }){
    return new Promise( (resolve) => {
      const ayuda = JSON.parse(localStorage.getItem('modoAyuda') || false);
      localStorage.setItem('modoAyuda',!ayuda);
      commit('toggleModoAyuda',!ayuda);
      resolve();
    });
  },
  login ({ commit }, userData) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      axios.post('/Usuarios/Login', { email: userData.username, password: userData.password, ancho:userData.width , alto: userData.height })
        .then(response => {
          if(typeof response.data.token !== 'undefined'){
            const token = response.data.token

            // obtain claims
            var ca = token
            var base64Url = ca.split('.')[1];
            var payload = JSON.parse(window.atob(base64Url));
            const user = payload.Nombre;
            const idRol = parseInt(payload.IdRol);
            const idUser = parseInt(payload["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"]);
            var idsTurnos = response.data.turnos;
            const validado = response.data.validado;
            const cambiarPassword = response.data.cambiarPassword;
            const idsReportes = response.data.idsReportes;

            if ( typeof idsTurnos === 'undefined' ) idsTurnos = [];
            
            // storing jwt in localStorage. https cookie is safer place to store
            localStorage.setItem('token', token);
            localStorage.setItem('user', user);
            localStorage.setItem('idUser', idUser);
            localStorage.setItem('idRol', idRol);
            localStorage.setItem('routes',JSON.stringify(response.data.permisos));
            localStorage.setItem('idsTurnos',JSON.stringify(idsTurnos));
            localStorage.setItem('default_route', response.data.permisos[0] ? (response.data.permisos[0].to === '/dashboard' ? '/dashboard/reportes' : response.data.permisos[0].to) : '/dashboard/reportes');
            localStorage.setItem('validado',validado.toString());
            localStorage.setItem('cambiarPassword',cambiarPassword.toString());
            localStorage.setItem('idsReportes',JSON.stringify(idsReportes));

            commit('routes_change',response.data.permisos);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
            
            // mutation to change state properties to the values passed along
            commit('auth_success', { token, user, idRol, idUser,  idsTurnos, validado, cambiarPassword, idsReportes})
            resolve('auth_success')
          }
          if(typeof response.data.msg !== 'undefined' && response.data.msg == 'DF'){
            commit('double_factor_required')
            resolve('double_factor_required')
          }
        })
        .catch(err => {
          console.log('login error')
          commit('auth_error')
          localStorage.removeItem('token')
          localStorage.removeItem('user')
          localStorage.removeItem('idUser');
          localStorage.removeItem('idRol');
          localStorage.removeItem('idsTurnos');
          localStorage.removeItem('validado');
          localStorage.removeItem('cambiarPassword');
          localStorage.removeItem('idsReportes');
          reject(err)
        })
    })
  },
  loginDF ({ commit }, userData) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      axios.post('/Usuarios/LoginDF', { email: userData.username, code: userData.code })
        .then(response => {
          if(typeof response.data.token !== 'undefined'){
            const token = response.data.token

            // obtain claims
            var ca = token
            var base64Url = ca.split('.')[1];
            var payload = JSON.parse(window.atob(base64Url));
            const user = payload.Nombre;
            const idRol = parseInt(payload.IdRol);
            const idUser = parseInt(payload["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"]);
            const idsTurnos = response.data.turnos;
            const validado = response.data.validado;
            const cambiarPassword = response.data.cambiarPassword;
            const idsReportes = response.data.idsReportes;

            // storing jwt in localStorage. https cookie is safer place to store
            localStorage.setItem('token', token);
            localStorage.setItem('user', user);
            localStorage.setItem('idUser', idUser);
            localStorage.setItem('idRol', idRol);
            localStorage.setItem('routes',JSON.stringify(response.data.permisos));
            localStorage.setItem('validado',validado.toString());
            localStorage.setItem('cambiarPassword',cambiarPassword.toString());
            if(typeof idsTurnos !== 'undefined') localStorage.setItem('idsTurnos',JSON.stringify(idsTurnos));
            localStorage.setItem('default_route', response.data.permisos[0] ? (response.data.permisos[0].to === '/dashboard' ? '/dashboard/reportes' : response.data.permisos[0].to) : '/dashboard/reportes');
            localStorage.setItem('idsReportes',JSON.stringify(idsReportes));
            commit('routes_change',response.data.permisos);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
            
            // mutation to change state properties to the values passed along
            commit('auth_success', { token, user, idRol, idUser,  idsTurnos, validado, cambiarPassword,idsReportes})
            resolve('auth_success')
          }
        })
        .catch(err => {
          console.log('login error')
          commit('auth_error')
          localStorage.removeItem('token')
          localStorage.removeItem('user')
          localStorage.removeItem('idUser');
          localStorage.removeItem('idRol');
          localStorage.removeItem('idsTurnos');
          localStorage.removeItem('validado');
          localStorage.removeItem('cambiarPassword');
          localStorage.removeItem('idsReportes');
          reject(err)
        })
    })
  },
  logout ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('logout');
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.removeItem('routes')
      localStorage.removeItem('idUser');
      localStorage.removeItem('idRol');
      localStorage.removeItem('idsTurnos');
      localStorage.removeItem('validado');
      localStorage.removeItem('cambiarPassword');
      localStorage.removeItem('idsReportes');
      commit('routes_change',null);
      delete axios.defaults.headers.common['Authorization']
      resolve()
    })
  },
  refreshtoken ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/Usuarios/Refresh')
      .then(response => {
        // obtain claims
        const token = response.data.token
        var ca = token
        var base64Url = ca.split('.')[1];
        var payload = JSON.parse(window.atob(base64Url));
        const user = payload.Nombre;
        const idRol = parseInt(payload.IdRol);
        const idUser = parseInt(payload["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"]);
        const idsTurnos = response.data.turnos;
        const validado = response.data.validado;
        const cambiarPassword = response.data.cambiarPassword;
        const idsReportes = response.data.idsReportes;

        // storing jwt in localStorage. https cookie is safer place to store
        localStorage.setItem('token', token);
        localStorage.setItem('user', user);
        localStorage.setItem('idUser', idUser);
        localStorage.setItem('idRol', idRol);
        localStorage.setItem('routes',JSON.stringify(response.data.permisos));
        localStorage.setItem('validado',validado.toString());
        localStorage.setItem('cambiarPassword',cambiarPassword.toString());
        if(typeof idsTurnos !== 'undefined') localStorage.setItem('idsTurnos',JSON.stringify(idsTurnos));
        localStorage.setItem('default_route', response.data.permisos[0] ? (response.data.permisos[0].to === '/dashboard' ? '/dashboard/reportes' : response.data.permisos[0].to) : '/dashboard/reportes');
        localStorage.setItem('idsReportes',JSON.stringify(idsReportes));
        commit('routes_change',response.data.permisos);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
        
        // mutation to change state properties to the values passed along
        commit('auth_success', { token, user, idRol, idUser,  idsTurnos, validado, cambiarPassword, idsReportes})
        resolve()
      })
      .catch(error => {
        console.log('refresh token error')
        console.log(error)
        commit('logout')
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        localStorage.removeItem('routes')
        localStorage.removeItem('idUser');
        localStorage.removeItem('idRol');
        localStorage.removeItem('idsTurnos');
        localStorage.removeItem('validado');
        localStorage.removeItem('cambiarPassword');
        localStorage.removeItem('idsReportes');
        commit('routes_change',null)
        delete axios.defaults.headers.common['Authorization']  
        router.push("/").catch(err => {})
        reject()
      })
    })
  },
  ignoreEmailAlert({ commit }){
    return new Promise((resolve) => {
      commit( 'ignore_email_alert' );
      localStorage.setItem( 'validado',"true" );
      resolve('ignore_email_alert');
    })
  },
  ignoreChangePassword({ commit }){
    return new Promise((resolve) => {
      commit( 'ignore_change_password' );
      localStorage.setItem( 'cambiarPassword',"false" );
      resolve('ignore_change_password');
    })
  },
}
