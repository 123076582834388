<template>
	<div>
		<v-dialog v-model="dialogSaved" persistent max-width="40%">
			<v-card>
				<v-card-text class="text-center">
					<lottie
						id="alertaSaved"
						:options="checkOptions"
						:height="200"
						:width="200"
					/>
					<h3>Movimiento Realizado Correctamente</h3>
					<a
						class="headline"
						target="__blank"
						:href="
							'/api/Inventario/DescargarQR/' +
							qrPDF +
							'?access_token=' +
							token
						"
						><v-icon color="primary" large>mdi-download</v-icon>
						Descargar Códigos QR</a
					>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text color="success" @click="cerrarDialogSaved()"
						>Cerrar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog :value="value" persistent width="90%">
			<signalr
				path="/hub/inventario"
				@change="notificacionInventario"
			></signalr>
			<notifications
				group="inventario"
				position="bottom right"
				:duration="10000"
			/>
			<v-card>
				<v-card-title>Movimiento entre Almacenes</v-card-title>
				<v-card-text>
					<v-row>
						<v-col
							cols="3"
							class="d-flex flex-column"
						>
							<div class="mb-auto">
								<QrcodeStream
									@decode="onDecode"
									style="height: 15.625rem"
								/>
								<v-text-field
									v-model="codigo"
									label="Código de Identificación"
									hide-details
									@keydown.enter="getCode"
									@keyup="setCodeStyle"
									maxlength="11"
									:error="codigoValido"
									class="py-4"
									:disabled="saving"
								></v-text-field>
								<v-select
									v-model="selectCode"
									label="Lectura Remota"
									:loading="loadingLecturasRemotas"
									:items="lecturasRemotas"
									item-value="codigo"
									item-text="codigo"
									@change="selectedCode"
									:disabled="saving"
								>
									<template v-slot:item="data">
										<v-list-item-content>
											<v-list-item-title>{{
												data.item.codigo
											}}</v-list-item-title>
											<v-list-item-subtitle
												>Artículo:
												{{
													data.item.articulo
												}}</v-list-item-subtitle
											>
											<v-list-item-subtitle
												>Almácen:
												{{
													data.item.almacen
												}}</v-list-item-subtitle
											>
										</v-list-item-content>
									</template>
								</v-select>
							</div>
							<!--
							<div>
								<v-btn
									color="success"
									block
									outlined
									@click="cerrarDialog()"
									:disabled="saving"
									>Cerrar</v-btn
								>
								<v-btn
									color="error"
									block
									outlined
									@click="save()"
									:disabled="saving"
									:loading="saving"
									>Guardar</v-btn
								>
							</div>
							-->
						</v-col>
						<v-col cols="9">
							<v-data-table
								:items="datosQR"
								:headers="datosQRHeaders"
								:items-per-page="-1"
								:loading="loading"
								hide-default-footer
							>
								<template v-slot:[`item.row`]="{ index }">
									{{ index + 1 }}
								</template>
								<template v-slot:[`item.detalles`]="{ item }">
									<div class="py-1">
										<p class="mb-2">
											<span
												class="font-weight-bold"
												style="
													font-size: 14px !important;
												"
												>QR:</span
											>
											{{
												item.codigo
													.match(/.{1,3}/g)
													.join("-")
											}}
										</p>
										<p class="mb-2">
											<span
												class="font-weight-bold"
												style="
													font-size: 14px !important;
												"
												>Artículo:</span
											>
											{{ item.articulo }}
										</p>
										<p class="mb-2">
											<span
												class="font-weight-bold"
												style="
													font-size: 14px !important;
												"
												>Almacén:</span
											>
											{{ item.almacen }}
										</p>
										<p class="mb-2">
											<span
												class="font-weight-bold"
												style="
													font-size: 14px !important;
												"
												>Subalmacén:</span
											>
											{{ item.subalmacen || "N/A" }}
										</p>
										<p class="mb-2">
											<span
												class="font-weight-bold"
												style="
													font-size: 14px !important;
												"
												>Cantidad restante:</span
											>
											{{
												`${
													item.maximo
												} ${$utils.strings.pluralizar(
													item.abreviatura,
													item.maximo,
													false
												)}`
											}}
										</p>
									</div>
								</template>
								<template
									v-slot:[`item.almacenDestino`]="{ item }"
								>
									<v-autocomplete
										v-model="item.idAlmacenDestino"
										label="Almacén"
										:items="almacenes"
										:item-text="nombreAlmacen"
										item-value="idAlmacen"
										clearable
										:disabled="saving"
										:error-messages="item.errorAlmacen"
										@focus="$set(item, 'errorAlmacen', '')"
										@change="changeAlmacenDestino(item)"
									></v-autocomplete>
								</template>
								<template
									v-slot:[`item.subalmacenDestino`]="{ item }"
								>
									<v-autocomplete
										v-model="item.idSubalmacenDestino"
										label="[Opcional] Subalmacén"
										:items="subalmacenesFiltrados(item)"
										:item-text="nombreSubalmacen"
										item-value="idSubalmacen"
										clearable
										:disabled="saving"
									></v-autocomplete>
								</template>
								<template v-slot:[`item.cantidad`]="{ item }">
									<v-text-field
										v-model="item.cantidad"
										:label="`Máximo: ${
											item.maximo
										} ${$utils.strings.pluralizar(
											item.abreviatura,
											item.maximo,
											false
										)}`"
										type="number"
										hide-spin-buttons
										:suffix="
											$utils.strings.pluralizar(
												item.abreviatura,
												item.cantidad,
												false
											)
										"
										:error="
											parseFloat(item.cantidad) >
											parseFloat(item.maximo)
										"
										:disabled="saving"
										:error-messages="item.errorCantidad"
										@focus="$set(item, 'errorCantidad', '')"
									></v-text-field>
								</template>
								<template v-slot:[`item.actions`]="{ item }">
									<v-tooltip
										bottom
										color="black"
										class="white--text"
									>
										<template v-slot:activator="{ on }">
											<v-icon
												v-on="on"
												small
												:disabled="saving"
												@click="deleteItemQR(item)"
											>
												delete
											</v-icon>
										</template>
										<span class="white--text"
											>Eliminar</span
										>
									</v-tooltip>
								</template>
							</v-data-table>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
									color="success"
									text
									@click="cerrarDialog()"
									:disabled="saving"
									>Cerrar</v-btn
								>
								<v-btn
									color="error"
									text
									@click="save()"
									:disabled="saving"
									:loading="saving"
									>Guardar</v-btn
								>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import axios from "axios";
import { QrcodeStream } from "vue-qrcode-reader";
import { mapState } from "vuex";
import signalr from "@/components/SignalR";
import { Howl } from "howler";
import Lottie from "vue-lottie";
import * as checkData from "@/assets/check.json";

export default {
	components: {
		lottie: Lottie,
		QrcodeStream,
		signalr,
	},
	props: {
		value: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			checkOptions: {
				animationData: checkData.default,
				loop: false,
				speeed: 0.5,
			},
			dialogSaved: false,
			qrPdf: "",
			saving: false,
			loading: false,
			almacenes: [],
			subalmacenes: [],
			soundBeep: null,
			soundError: null,
			lecturasRemotas: [],
			loadingLecturasRemotas: false,
			selectCode: null,
			codigo: "",
			datosQR: [],
			datosQRHeaders: [
				{
					text: "#",
					align: "center",
					value: "row",
					width: "5%",
				},
				{
					text: "Detalles del QR",
					align: "center",
					value: "detalles",
				},
				{
					text: "Almacén Destino",
					align: "center",
					value: "almacenDestino",
				},
				{
					text: "Subalmacén Destino",
					align: "center",
					value: "subalmacenDestino",
				},
				{
					text: "Cantidad a Transferir",
					align: "center",
					value: "cantidad",
					width: "15%",
				},
				{
					text: "",
					align: "center",
					value: "actions",
					width: "5%",
				},
			],
		};
	},
	computed: {
		...mapState(["idUser", "token"]),
		codigoValido() {
			return (
				(!this.onlyNumberAndLetters(this.codigo.replace(/-/g, "")) &&
					this.codigo.length >= 1) ||
				(this.codigo.length >= 1 && this.codigo.length < 11) ||
				this.codigo.length > 11
			);
		},
	},
	mounted() {
		this.initialize();
	},
	methods: {
		cerrarDialogSaved() {
			this.dialogSaved = false;
			this.$nextTick( () => {
				this.qrPDF = "";
			})
		},
		nombreSubalmacen: ({ clave, subalmacen }) =>
			`[${clave}] - ${subalmacen}`.trim(),
		nombreAlmacen: ({ codigo, nombre }) => `[${codigo}] - ${nombre}`.trim(),
		onlyNumberAndLetters: (str) => str.match("^[A-Z0-9-]+$"),
		validate() {
			let hayErrores = false;

			if (this.datosQR.length == 0) {
				this.soundError.play();
				this.$notify({
					group: "inventario",
					title: "Debe ingresar al menos un código QR",
					type: "error",
				});
				hayErrores = true;
			} else {
				this.datosQR.forEach((item) => {
					this.$set(item, "errorAlmacen", "");
					this.$set(item, "errorCantidad", "");

					if (item.idAlmacenDestino == null) {
						this.$set(item, "errorAlmacen", "Requerido");
						hayErrores = true;
					}

					if (
						isNaN(parseFloat(item.cantidad)) ||
						parseFloat(item.cantidad) <= 0
					) {
						this.$set(item, "errorCantidad", "Cantidad invalida");
						hayErrores = true;
					} else if (parseFloat(item.cantidad) > item.maximo) {
						this.$set(
							item,
							"errorCantidad",
							`Máximo: ${
								item.maximo
							} ${this.$utils.strings.pluralizar(
								item.abreviatura,
								item.maximo,
								false
							)}`
						);
						hayErrores = true;
					}
				});
			}

			return !hayErrores;
		},
		save() {
			if (!this.validate()) return;

			const movimientos = this.datosQR.map((item) => ({
				idAlmacen: item.idAlmacenDestino,
				idSubalmacen: item.idSubalmacenDestino,
				idElemento: item.idElemento,
				cantidad: parseFloat(item.cantidad),
			}));

			this.saving = true;
			axios
				.post("/Inventario/CrearMovimientoEntreAlmacenes", movimientos)
				.then((response) => {
					this.saving = false;
					this.cerrarDialog();
					this.$emit("initialize");
					this.qrPDF = response.data;
					this.dialogSaved = true;
				})
				.catch((error) => {
					this.saving = false;
					console.log(error);
				});
		},
		deleteItemQR(item) {
			const index = this.datosQR.indexOf(item);
			this.datosQR.splice(index, 1);
		},
		subalmacenesFiltrados({ idAlmacenDestino }) {
			if (idAlmacenDestino == null) return [];

			return this.subalmacenes.filter(
				(subalm) => subalm.idAlmacen == idAlmacenDestino
			);
		},
		changeAlmacenDestino(item) {
			this.$set(item, "idSubalmacenDestino", null);
		},
		notificacionInventario(event) {
			const orden = event.toLowerCase().split("-");
			if (orden[0] == "lecturaremota") {
				if (this.idUser == parseInt(orden[1])) {
					this.getLecturasRemotas();
				}
			}
		},
		selectedCode(event) {
			this.onDecode(event);
			setTimeout(() => {
				this.selectCode = null;
			}, 200);
		},
		setCodeStyle() {
			if (this.codigo.length >= 1) {
				try {
					this.codigo = this.codigo
						.toUpperCase()
						.replace(/-/g, "")
						.match(/.{1,3}/g)
						.join("-");
				} catch {
					this.codigo = "";
				}
			}
		},
		getCode() {
			const codigo = this.codigo.toUpperCase().replace(/-/g, "");
			if (codigo.length == 9 && this.onlyNumberAndLetters(codigo)) {
				this.onDecode(codigo);
				this.codigo = "";
			}
		},
		onDecode(code) {
			if (this.datosQR.find((item) => item.codigo == code)) {
				this.soundError.play();
				this.$notify({
					group: "inventario",
					title:
						"Se ha seleccionado un Elemento con el Código " +
						code.match(/.{1,3}/g).join("-"),
					type: "error",
				});
				return;
			}

			this.loading = true;
			axios
				.get("/Inventario/InformacionBasica", {
					params: {
						codigo: code,
						tipoMovimiento: 6,
					},
				})
				.then((response) => {
					if (response.data.enUso) {
						this.soundError.play();
						this.$notify({
							group: "inventario",
							title:
								"No se puede agregar el Elemento con el Código " +
								code.match(/.{1,3}/g).join("-") +
								" se encuentra en uso en otra ODT",
							type: "error",
						});
						return;
					}

					if (response.data.maximo <= 0) {
						this.soundError.play();
						this.$notify({
							group: "inventario",
							title:
								"La existencia de este Elemento es 0.0 " +
								response.data.abreviatura,
							type: "error",
						});
						return;
					}

					const idsAlmacenes = this.almacenes
						.filter((a) => a.idCliente == null)
						.map((a) => a.idAlmacen);

					if (idsAlmacenes.indexOf(response.data.idAlmacen) >= 0) {
						response.data.cantidad = 0;
						this.datosQR.push(response.data);
						this.soundBeep.play();
					} else {
						this.soundError.play();
						this.$notify({
							group: "inventario",
							title:
								"No se puede agregar el Elemento con el Código " +
								code.match(/.{1,3}/g).join("-"),
							type: "error",
						});
					}
				})
				.catch((error) => {
					this.soundError.play();
					this.$notify({
						group: "inventario",
						title:
							"No Existe un Elemento con el Código " +
							code.match(/.{1,3}/g).join("-"),
						type: "error",
					});
				})
				.finally(() => {
					this.loading = false;
				});
		},
		cerrarDialog() {
			this.$emit("input", false);
			this.$nextTick(() => {
				this.codigo = "";
				this.selectCode = null;
				this.datosQR = [];
			});
		},
		getLecturasRemotas() {
			this.loadingLecturasRemotas = true;
			axios
				.get("/Inventario/ListarQRRemotos")
				.then((response) => {
					this.lecturasRemotas = response.data;
				})
				.catch(console.log)
				.finally(() => {
					this.loadingLecturasRemotas = false;
				});
		},
		initializeSounds() {
			this.soundBeep = new Howl({
				src: ["/sounds/beep.webm", "/sounds/beep.mp3"],
			});
			this.soundError = new Howl({
				src: ["/sounds/error.webm", "/sounds/error.mp3"],
			});
		},
		getAlmacenes() {
			axios
				.get("/Almacenes")
				.then((response) => {
					this.almacenes = response.data;
				})
				.catch(console.log);
		},
		getSubalmacenes() {
			axios
				.get("/Subalmacenes")
				.then((response) => {
					this.subalmacenes = response.data;
				})
				.catch(console.log);
		},
		initialize() {
			this.initializeSounds();
			this.getAlmacenes();
			this.getSubalmacenes();
			this.getLecturasRemotas();
		},
	},
};
</script>
<style scoped>
.centered-input >>> input {
	text-align: center;
}
</style>