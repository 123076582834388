<template>
	<v-container fluid>
		<v-dialog v-model="dialog" persistent max-width="60%">
			<v-card>
				<v-card-title>Faltantes de Materia Prima - {{ getNombreArticulo }}</v-card-title>
				<v-card-text class="pb-0">
					<v-container fluid class="pa-0 pt-2">
						<v-tabs v-model="tab" background-color="transparent" grow>
							<v-tab> Faltantes Específicos </v-tab>

							<v-tab> Faltantes Jerárquicos </v-tab>

							<v-tabs-items v-model="tab" touchless>
								<v-tab-item eager>
									<v-container fluid>
										<v-row>
											<v-col cols="12" class="px-0">
												<v-data-table
													:items="editedItem.materiasFaltantesEspecificas || []"
													:headers="faltantesHeaders"
													:loading="loadingFaltantesEspecificos"
												>
													<template v-slot:[`item.faltante`]="{ item }">
														{{ item.cantidad.toFixed(2) }} {{ item.unidad }}
													</template>
												</v-data-table>
											</v-col>
										</v-row>
									</v-container>
								</v-tab-item>
								<v-tab-item eager>
									<v-container fluid>
										<v-row>
											<v-col cols="12" class="px-0">
												<v-data-table
													:items="editedItem.materiasFaltantesJerarquicas || []"
													:headers="faltantesHeaders"
													:loading="loadingFaltantesJerarquicos"
												>
													<template v-slot:[`item.faltante`]="{ item }">
														{{ item.cantidad.toFixed(2) }} {{ item.unidad }}
													</template>
												</v-data-table>
											</v-col>
										</v-row>
									</v-container>
								</v-tab-item>
							</v-tabs-items>
						</v-tabs>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn color="success" text @click="closeDialog()">Cerrar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog
			v-model="dialogActualizacionYaSolicitada"
			persistent
			max-width="450px"
		>
			<v-card>
				<v-card-title>Actualizar Reporte</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12" class="py-0">
								<lottie
									id="inProgressAnimation"
									:options="inProgressAnimationOptions"
									:height="300"
									:width="300"
									:key="`error-${inProgressAnimationKey}`"
									class="py-0"
								/>
							</v-col>
							<v-col cols="12" class="pt-0">
								<p
									class="
										text-subtitle-1
										text-justify
										text--primary
									"
								>
									Ya se ha solicitado una actualización del
									reporte de proyeccion de producción.<br />
									La acción puede demorar aproximadamente 15
									minutos, por favor espere a que el proceso
									termine para realizar otra solicitud de
									actualización.
								</p>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text color="success" @click="cerrarDialog();getDatos()"
						>Aceptar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogRefreshReporte" persistent max-width="450px">
			<v-card>
				<v-card-title>Actualizar Reporte</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12" class="pb-0">
								<lottie
									id="attentionAnimation"
									:options="attentionAnimationOptions"
									:height="200"
									:width="200"
									:key="`error-${attentionAnimationKey}`"
									class="py-0"
								/>
							</v-col>
							<v-col cols="12" class="pt-0">
								<p
									class="
										text-subtitle-1
										text-justify
										text--primary
									"
								>
									Se procederá a realizar la actualización del
									reporte de proyección de producción.<br />
									Esta acción demorará aproximadamente 15
									minutos, por lo que los datos pueden no
									estar actualizados durante ese tiempo.<br />
									¿Desea continar con esta acción?
								</p>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-btn
						text
						color="success"
						:disabled="updatingReporte"
						@click="cerrarDialog()"
						>No, Cancelar</v-btn
					>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="error"
						:disabled="updatingReporte"
						:loading="updatingReporte"
						@click="actualizarCacheReporte()"
						>Sí, Actualizar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col cols="4">
				<v-select
					v-model="tipoFecha"
					label="Tipo de selección (Obligatorio)"
					:items="tiposSeleccionFecha"
					:disabled="loading || temporalBlock"
					hide-details
					@change="onChangeSelectFecha"
				></v-select>
			</v-col>
			<template v-if="tipoFecha == 1">
				<v-col cols="4">
					<datePicker
						v-model="fechaInicio"
						format="YYYY-MM-DD"
						label="Fecha de Inicio (Obligatorio)"
						:disabled="loading || temporalBlock"
						@input="getDatos()"
						:class="temporalBlock && 'disabled-input'"
					></datePicker>
				</v-col>
				<v-col cols="4">
					<datePicker
						v-model="fechaFin"
						format="YYYY-MM-DD"
						label="Fecha de Fin (Opcional)"
						:disabled="loading || temporalBlock"
						clearable
						:minDate="fechaInicio"
						@input="getDatos()"
						:class="temporalBlock && 'disabled-input'"
					></datePicker>
				</v-col>
			</template>
			<template v-else>
				<v-col cols="4">
					<v-autocomplete
						v-model="year"
						label="Año (Obligatorio)"
						:items="years"
						:disabled="loading || temporalBlock || loadingYearsWeeks"
						:loading="loadingYearsWeeks"
						hide-details
						@change="onChangeYear"
					></v-autocomplete>
				</v-col>
				<v-col cols="4">
					<v-autocomplete
						v-model="week"
						label="Semana (Obligatorio)"
						:items="weeks"
						item-text="name"
						item-value="week"
						:disabled="loading || temporalBlock || loadingYearsWeeks"
						:loading="loadingYearsWeeks"
						@change="onChangeWeek"
						hide-details
						return-object
					></v-autocomplete>
				</v-col>
			</template>
			<v-col cols="4">
				<v-select
					v-model="idAlmacen"
					label="Almacén (Obligatorio)"
					:items="almacenes"
					item-value="idAlmacen"
					:item-text="nombreAlmacen"
					:disabled="loading || temporalBlock"
					:loading="loadingAlmacenes"
					hide-details
					@change="getDatos()"
				></v-select>
			</v-col>
			<v-col cols="4">
				<v-autocomplete
					v-model="idArticuloFamilia"
					label="Familia de Productos (Opcional)"
					:items="articulosFamilias"
					item-value="idArticuloFamilia"
					item-text="nombre"
					:disabled="loading || temporalBlock"
					:loading="loadingArticuloFamilias"
					hide-details
					clearable
					@change="getDatos()"
				></v-autocomplete>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" class="d-flex align-center justify-space-between">
				<download-btn
					color="blue darken-1"
					text
					@click="descargarReporte()"
					:disabled="loading || fechaInicio==null || temporalBlock"
					label="Descargar Reporte de<br/>Proyección de Producción"
				></download-btn>
				<div class="d-flex flex-column">
					<div class="d-flex justify-end">
						<p v-if="temporalBlock" class="mb-0 text--disabled">
							Cargando, espere {{ contadorTemporalBlock }} segundos
						</p>
						<!-- <download-btn
							color="blue darken-1"
							text
							className="my-0"
							:disabled="loading"
							label="Refrescar"
							left
							icon="mdi-refresh"
							icon-size="22"
							waitingText=""
							:retryTime="90"
							@click="getDatos"
						/> -->
					</div>
					<div class="d-flex align-center">
						<p class="mb-0 mr-1">
							Ultima Actualización: {{ fechaUltimaActualizacion }}
						</p>
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-btn
									icon
									text
									class="my-0"
									v-on="on"
									color="grey darken-1"
									@click="showDialogRefreshReporte"
									:disabled="loading || temporalBlock"
								>
									<v-icon size="24">mdi-sync</v-icon>
								</v-btn>
							</template>
							<span class="white--text">Actualizar</span>
						</v-tooltip>
					</div>
				</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
					:items="datos"
					:headers="datosHeaders"
					:loading="loading || temporalBlock"
				>
					<template v-slot:[`item.prioridad`]="{ item }">
						{{ item.prioridad || "N/A" }}
					</template>
					<template v-slot:[`item.codigo`]="{ item }">
						{{item.codigo || item.nombreArticulo.split('-')[0]}}
					</template>
					<template v-slot:[`item.nombreArticulo`]="{ item }">
						{{ item.codigo ? item.nombreArticulo : item.nombreArticulo.split('-')[1] }}
					</template>
					<template v-slot:[`item.cantidadObjetivo`]="{ item }">
						{{
							item.cantidadObjetivo != null
								? `${item.cantidadObjetivo.toFixed(2)} ${
										item.unidad
								  }`
								: "N/A"
						}}
					</template>
					<template v-slot:[`item.cantidadJerarquica`]="{ item }">
						{{
							item.cantidadJerarquica != null
								? `${item.cantidadJerarquica.toFixed(2)} ${
										item.unidad
								  }`
								: "N/A"
						}}
					</template>
					<template v-slot:[`item.cantidadEspecifica`]="{ item }">
						{{
							`${item.cantidadEspecifica.toFixed(2)} ${
								item.unidad
							}`
						}}
					</template>
					<template v-slot:[`item.diferencia`]="{ item }">
						{{ calcularDiferencia(item) }}
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									v-show="hayDiferencia(item)"
									small
									@click="showDialogEdit(item)"
								>
									mdi-eye
								</v-icon>
							</template>
							<span class="white--text">Detalles</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import Lottie from "vue-lottie";
import DownloadBtn from "../../DownloadBtn.vue";
import * as attentionAnimation from "@/assets/attention.json";
import * as inProgressAnimation from "@/assets/inProgress.json";
import axios from "axios";
import moment from "moment";
import { mapState } from 'vuex';

export default {
	components: { DownloadBtn, lottie: Lottie },
	data() {
		return {
			year: null,
			week: null,
			tipoFecha: 2,
			loadingYearsWeeks: false,
			tiposSeleccionFecha: [
				{
					text: "Por Fechas",
					value: 1
				},
				{
					text: "Por Semanas",
					value: 2
				}
			],
			interval: null,
			contadorTemporalBlock: 30,
			temporalBlock: false,
			fechaInicio: null,
			fechaFin: null,
			loadingArticuloFamilias: false,
			articulosFamilias: [],
			editedItem: {
				codigo: "",
				nombreArticulo: "",
				materiasFaltantesEspecificas: [],
				materiasFaltantesJerarquicas: []
			},
			defaultEditedItem: {
				codigo: "",
				nombreArticulo: "",
				materiasFaltantesEspecificas: [],
				materiasFaltantesJerarquicas: []
			},
			loadingFaltantesJerarquicos: false,
			tab: 0,
			faltantesHeaders: [
				{
					text: "Código",
					align: "center",
					value: "codigo"
				},
				{
					text: "Artículo",
					align: "center",
					value: "nombre"
				},
				{
					text: "Faltante Total",
					align: "center",
					value: "faltante"
				}
			],
			loadingFaltantesEspecificos: false,
			dialog: false,
			fechaUltimaActualizacion: "Cargando",
			loadingAlmacenes: false,
			almacenes: [],
			idAlmacen: null,
			idArticuloFamilia: null,
			fechaActualizacionYaSolicitada: "",
			updatingReporte: false,
			inProgressAnimationOptions: {
				animationData: inProgressAnimation.default,
				loop: true,
				speeed: 1,
			},
			inProgressAnimationKey: 0,
			dialogActualizacionYaSolicitada: false,
			attentionAnimationOptions: {
				animationData: attentionAnimation.default,
				loop: false,
				speeed: 2,
			},
			attentionAnimationKey: 0,
			dialogRefreshReporte: false,
			loading: false,
			datos: [],
			datosHeaders: [
				{
					text: "Prioridad",
					align: "center",
					value: "prioridad",
				},
				{
					text: "Código",
					align: "center",
					value: "codigo",
				},
				{
					text: "Artículo",
					align: "center",
					value: "nombreArticulo",
				},
				{
					text: "Cantidad Objetivo",
					align: "center",
					value: "cantidadObjetivo",
				},
				{
					text: "Cantidad Factible Jerárquica",
					align: "center",
					value: "cantidadJerarquica",
				},
				{
					text: "Cantidad Factible Específica",
					align: "center",
					value: "cantidadEspecifica",
				},
				{
					text: "Diferencia",
					align: "center",
					value: "diferencia",
				},
				{
					text: "Acciones",
					align: "center",
					value: "actions"
				}
			],
			yearsWeekData: [],
		};
	},
	computed: {
		...mapState(["token"]),
		years(){
			return this.yearsWeekData.map(item => ({
				text: item.year,
				value: item.year
			}));
		},
		weeks(){
			if(!this.year) return [];

			const index = this.yearsWeekData.findIndex( item => item.year == this.year);
			return this.yearsWeekData[index].weeks || [];
		},
		getNombreArticulo(){
			return this.editedItem.codigo
				? `[${this.editedItem.codigo.trim()}] - ${this.editedItem.nombreArticulo.trim()}`
				: this.editedItem.nombreArticulo.trim();
		}
	},
	beforeMount(){
		if(this.tipoFecha == 1) this.precargarFechas();
	},
	mounted() {
		this.initialize();
	},
	methods: {
		nombreAlmacen: ({ codigo, nombre }) => `[${codigo}] - ${nombre}`.trim(),
		precargarFechas(){
			moment.locale('es');
			let currentDate = moment().add(1,'weeks');
			let weekStart = currentDate.clone().startOf('week').format('YYYY-MM-DD');
			let weekEnd = currentDate.clone().endOf('week').format('YYYY-MM-DD');
			this.fechaInicio = weekStart;
			this.fechaFin = weekEnd;
		},
		onChangeWeek({ start, end }){
			this.fechaInicio = start;
			this.fechaFin = end;
			this.getDatos();
		},
		onChangeYear(){
			this.week = null;
			this.fechaInicio = null;
			this.fechaFin = null;
		},
		onChangeSelectFecha(){
			this.fechaInicio = null;
			this.fechaFin = null;
			this.year = null;
			this.week = null;

			if(this.tipoFecha == 1) {
				this.precargarFechas();
				this.getDatos();
			}
		},
		hayDiferencia({cantidadObjetivo, cantidadJerarquica}){
			const diferencia = cantidadObjetivo - cantidadJerarquica;
			return diferencia > 0;
		},
		closeDialog(){
			this.dialog = false;
			this.$nextTick( () => {
				this.editedItem = JSON.parse(JSON.stringify(this.defaultEditedItem));
				this.tab = 0;
			});
		},
		showDialogEdit(item){
			this.editedItem = JSON.parse(JSON.stringify(item));
			this.dialog = true;
		},
		descargarReporte() {
			var datetime = moment().format("YYYYMMDDHHmmss");
			this.$utils.axios.downloadFile(
				`/Reportes/ReporteReservasXlsx/${this.idAlmacen || 1}?access_token=${this.token}&idFamilia=${this.idArticuloFamilia}&desde=${this.fechaInicio}&hasta=${this.fechaFin}`,
				"GET",
				"reporte-proyeccion-produccion-" + datetime + ".xlsx",
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			);
		},
		calcularDiferencia({ cantidadObjetivo, cantidadJerarquica, unidad }) {
			if (cantidadObjetivo == null) {
				return "N/A";
			}

			const diferencia = cantidadObjetivo - cantidadJerarquica;

			if (diferencia > 0)
				return `Faltantes por cubrir: ${diferencia.toFixed(
					2
				)} ${unidad}`;
			else return "N/A";
		},
		actualizarCacheReporte() {
			this.updatingReporte = true;
			axios
				.get("/Cache/ReporteReservasHangfire",{
					params: {
						desde: this.fechaInicio,
						hasta: this.fechaFin
					}
				})
				.then((response) => {
					this.updatingReporte = false;
					this.cerrarDialog();
					this.bloqueoTemporal();
					setTimeout(()=> {
						this.getDatos();

					},15000);
				})
				.catch((error) => {
					if(error.response){
						if (error.response.status == 409) {
							// this.fechaActualizacionYaSolicitada =
							// 	response.data.mensaje.split("el ")[1];
							this.inProgressAnimationKey++;
							this.dialogRefreshReporte = false;
							this.dialogActualizacionYaSolicitada = true;
						}
					}
					this.updatingReporte = false;
					console.log(error);
				});
		},
		cerrarDialog() {
			this.dialogRefreshReporte = false;
			this.dialogActualizacionYaSolicitada = false;
			this.fechaActualizacionYaSolicitada = "";
		},
		showDialogRefreshReporte() {
			this.attentionAnimationKey++;
			this.dialogRefreshReporte = true;
		},
		async initialize() {
			this.loading = true;
			await this.getHeadersFiltros();
			await this.getAlmacenes();
			await this.getArticuloFamilias()
			this.getDatos();
		},
		getHeadersFiltros(){
			return new Promise( resolve => {
				this.loadingYearsWeeks = true;

				axios
					.get('/Auxiliares/Headers')
					.then( response => {
						this.yearsWeekData = response.data;
					})
					.catch(console.log)
					.finally( () => {
						this.loadingYearsWeeks = false;
						resolve();
					});
			});
		},
		getArticuloFamilias(){
			return new Promise((resolve) => {
				this.loadingArticuloFamilias = true;
	
				axios
					.get('/ArticuloFamilias')
					.then( response => {
						this.idArticuloFamilia = response.data[0].idArticuloFamilia || null;
						this.articulosFamilias = response.data;
					})
					.catch(console.log)
					.finally( () => {
						this.loadingArticuloFamilias = false;
						resolve();
					});
			})
		},
		getAlmacenes() {
			return new Promise((resolve) => {
				this.loadingAlmacenes = true;
				axios
					.get("/Almacenes")
					.then((response) => {
						this.idAlmacen = response.data[0].idAlmacen || null;
						this.almacenes = response.data;
					})
					.catch(console.log)
					.finally(() => {
						this.loadingAlmacenes = false;
						resolve();
					});
			})
		},
		stopInterval() {
			clearInterval(this.interval);
			this.contadorTemporalBlock = 30;
			this.temporalBlock = false;
		},
		bloqueoTemporal(){
			if(this.temporalBlock) return;
			this.temporalBlock = true;
			this.interval = setInterval(() => {
				this.contadorTemporalBlock -= 1;
				if (this.contadorTemporalBlock == 0) {
					this.stopInterval();
					this.getDatos();
					return;
				}
			}, 1000);
		},
		getDatos() {
			if(this.fechaInicio == null){
				this.loading = false;
				return;
			}

			this.loading = true;
			axios
				.get(`/Reportes/ReporteReservas/${this.idAlmacen}`,{
					params: {
						idFamilia: this.idArticuloFamilia,
						desde: this.fechaInicio,
						hasta: this.fechaFin
					}
				})
				.then((response) => {
					if (response.data.respuesta) {
						const [fecha, hora] = response.data.fecha.split('T');
						this.datos = response.data.respuesta;
						this.fechaUltimaActualizacion = `${fecha} ${hora}`;
						this.stopInterval();
					}
					else {
						this.datos = [];
						this.fechaUltimaActualizacion = "Ninguna";
						this.bloqueoTemporal();
					}
				})
				.catch(error => {
					console.log(error);
					this.bloqueoTemporal();
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>
<style scoped>
.disabled-input {
	cursor: default !important;
}

.disabled-input >>> .vd-activator {
	pointer-events: none;
}

.disabled-input >>> label {
	pointer-events: none;
}
</style>