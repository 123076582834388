<template>
  <v-col cols="12" class="px-8" v-if="permisoVista('dashboard','r')">
    <v-row class="mt-0">
      <v-col cols="4" md="4" sm="4" class="align-self-end">
        <datePicker
          v-model="filtros.desde"
					format="YYYY-MM-DD"
					label="Desde (Obligatorio)"
					clearable
					maxToday
					@input="obtenerDatos"
					:disabled="cargando"
          :maxDate="filtros.hasta"
				></datePicker>
      </v-col> 
      <v-col cols="4" md="4" sm="4" class="align-self-end">
        <datePicker
        v-model="filtros.hasta"
					format="YYYY-MM-DD"
					label="Hasta (Obligatorio)"
					clearable
					maxToday
					@input="obtenerDatos"
					:disabled="cargando"
          :minDate="filtros.desde"
				></datePicker>
      </v-col> 
      <v-col cols="4" md="4" sm="4">
        <v-autocomplete
          v-model="filtros.idsTurnos"
          :items="turnos"
          item-value="idTurno"
          item-text="nombre"
          label="Turnos (Opcional)"
          hide-details
          clearable
          multiple
          chips
          deletable-chips
          :disabled="cargando"
          @input="obtenerDatos"
          @click:clear="resetCampo(1)"
        >
        </v-autocomplete>
      </v-col> 
    </v-row>
    <v-row>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div>
              <v-icon>mdi-target</v-icon> Filtros Avanzados (Opcionales)
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="4" md="4" sm="4">
                <v-autocomplete
                  v-model="filtros.idsAreas"
                  :items="areas"
                  item-value="idArea"
                  item-text="nombre"
                  label="Áreas"
                  hide-details
                  clearable
                  multiple
                  chips
                  deletable-chips
                  :disabled="cargando"
                  @input="obtenerDatos"
                  @click:clear="resetCampo(2)"
                >
                </v-autocomplete>
              </v-col> 
              <v-col cols="4" md="4" sm="4">
                <v-autocomplete
                  v-model="filtros.idsOTS"
                  :items="ots"
                  item-value="idTrabajoOrden"
                  item-text="clave"
                  label="Orden de Trabajo"
                  hide-details
                  clearable
                  multiple
                  chips
                  deletable-chips
                  :disabled="cargando"
                  @input="obtenerDatos"
                  @click:clear="resetCampo(3)"
                >
                </v-autocomplete>
              </v-col> 
              <v-col cols="4" md="4" sm="4">
                <v-autocomplete
                  v-model="filtros.idsArticulos"
                  :items="articulos"
                  item-value="idArticulo"
                  :item-text="nombreArticulo"
                  label="Artículos"
                  hide-details
                  clearable
                  multiple
                  chips
                  deletable-chips
                  :disabled="cargando"
                  @input="obtenerDatos"
                  @click:clear="resetCampo(4)"
                >
                </v-autocomplete>
              </v-col> 
            </v-row>
            <v-row>
              <v-col cols="4" md="4" sm="4">
                <v-autocomplete
                  v-model="filtros.idsMaquinaInstancias"
                  :items="maquinasIns"
                  item-value="idMaquinaIns"
                  :item-text="nombreMaquina"
                  label="Maquinas Instancias"
                  hide-details
                  clearable
                  multiple
                  chips
                  deletable-chips
                  :disabled="cargando"
                  @input="obtenerDatos"
                  @click:clear="resetCampo(5)"
                >
                </v-autocomplete>
              </v-col> 
              <v-col cols="4" md="4" sm="4">
                <v-autocomplete
                  v-model="filtros.idsPersonas"
                  :items="operadores"
					        item-value="idPersona"
					        :item-text="nombrePersona"
                  label="Personas"
                  hide-details
                  clearable
                  multiple
                  chips
                  deletable-chips
                  :disabled="cargando"
                  @input="obtenerDatos"
                  @click:clear="resetCampo(6)"
                >
                </v-autocomplete>
              </v-col> 
              <v-col cols="4" md="4" sm="4">
                <v-autocomplete
                  v-model="filtros.idsClientes"
                  :items="clientes"
                  item-text="nombre"
                  item-value="idCliente"
                  label="Clientes"
                  hide-details
                  clearable
                  multiple
                  chips
                  deletable-chips
                  :disabled="cargando"
                  @input="obtenerDatos"
                  @click:clear="resetCampo(7)"
                >
                </v-autocomplete>
              </v-col> 
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-row>
      <download-btn
        className="my-4"
        color="blue darken-1"
        text
        @click="descargarReporte"
        label="Descargar Reporte de OEE"
      />
    </v-row>
    <br>
    <div v-if="viewData">
      <v-row v-if="realizarPeticion && sameDay" class="px-0 ma-0">
      <v-col cols="12" class="ma-0 text-right text-subtitle-2">
        Proxima Actualización en {{tiempo}}
      </v-col>
    </v-row>
		<v-row class="justify-center ma-0 pa-0">
			<v-col cols="3" md="3" sm="3">
				<v-card class="fill-height d-flex" color="#2a6099" dark>
					<v-card-text
						class="text-center align-self-center white--text"
					>
						<div class="subtitle-1 font-weight-bold">
							Disponibilidad
						</div>
						<ICountUp
							:delay="1000"
							:endVal="this.datos.disponibilidad * 100"
							class="subtitle-1 font-weight-medium"
							:options="{
                decimalPlaces: 2,
                decimal: '.',
								suffix: '%'
              }"
						/>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="3" md="3" sm="3">
				<v-card class="fill-height d-flex" color="#2a6099" dark>
					<v-card-text
						class="text-center align-self-center white--text"
					>
						<div class="subtitle-1 font-weight-bold">
							Rendimiento
						</div>
						<ICountUp
							:delay="1000"
							:endVal="this.datos.eficiencia * 100"
							class="subtitle-1 font-weight-medium"
							:options="{
                decimalPlaces: 2,
                decimal: '.',
								suffix: '%'
              }"
						/>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="3" md="3" sm="3">
				<v-card class="fill-height d-flex" color="#2a6099" dark>
					<v-card-text
						class="text-center align-self-center white--text"
					>
						<div class="subtitle-1 font-weight-bold">Calidad</div>
						<ICountUp
							:delay="1000"
							:endVal="this.datos.calidad * 100"
							class="subtitle-1 font-weight-medium"
							:options="{
                decimalPlaces: 2,
                decimal: '.',
								suffix: '%'
              }"
						/>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="3" md="3" sm="3">
				<v-card class="fill-height d-flex" color="#2a6099" dark>
					<v-card-text
						class="text-center align-self-center white--text"
					>
						<div class="subtitle-1 font-weight-bold">OEE</div>
						<ICountUp
							:delay="1000"
							:endVal="this.datos.oee * 100"
							class="subtitle-1 font-weight-medium"
							:options="{
                decimalPlaces: 2,
                decimal: '.',
								suffix: '%'
              }"
						/>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
    <v-row v-if="!cargando && renderizar">
      <v-col cols="6" md="6" sm="6" class="text-center">
        <div class="text-subtitle-2">Disponibilidad</div>
        <apexchart height="220" :options="serie1" :series="chartOptions1"></apexchart>
      </v-col>
      <v-col cols="6" md="6" sm="6" class="text-center">
        <div class="text-subtitle-2">Rendimiento</div>
        <apexchart height="220" :options="serie2" :series="chartOptions2"></apexchart>
      </v-col>
      <v-col cols="6" md="6" sm="6" class="text-center">
        <div class="text-subtitle-2">Calidad</div>
        <apexchart height="220" :options="serie3" :series="chartOptions3"></apexchart>
      </v-col>
      <v-col cols="6" md="6" sm="6" class="text-center">
        <div class="text-subtitle-2">OEE</div>
        <apexchart height="220" :options="serie4" :series="chartOptions4"></apexchart>
      </v-col>
    </v-row>
    <v-row v-if="!cargando && renderizar && datos.operaciones.length > 0">
      <v-col cols="12" md="12" sm="12">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div>
                <v-icon>mdi-cog</v-icon> Operaciones
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="headers"
                :items="datos.operaciones"
                item-key="idOperacionReal"
                show-expand
                :expanded.sync="expanded"
              >
                <template v-slot:item.disponibilidad="{ item }">
                  {{(item.disponibilidad * 100).toFixed(2)}}%
                </template>
                <template v-slot:item.eficiencia="{ item }">
                  {{(item.eficiencia * 100).toFixed(2)}}%
                </template>
                <template v-slot:item.calidad="{ item }">
                  {{(item.calidad * 100).toFixed(2)}}%
                </template>
                <template v-slot:item.oee="{ item }">
                  {{(item.oee * 100).toFixed(2)}}%
                </template>
                <template v-slot:item.maquinas="{ item }">
                  <ul>
                    <li v-for="(m,idx) in item.maquinas" :key="'m-'+idx">{{m}}</li>
                  </ul>
                </template>
                <template v-slot:item.personas="{ item }">
                  <ul>
                    <li v-for="(p,idx) in item.personas" :key="'p-'+idx">{{p}}</li>
                  </ul>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <br>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center">
                              Inicio
                            </th>
                            <th class="text-center">
                              Fin
                            </th>
                            <th class="text-center">
                              Tiempo de Operación
                            </th>
                            <th class="text-center">
                              Total Piezas
                            </th>
                            <th class="text-center">
                              Piezas Teoricas
                            </th>
                            <th class="text-center">
                              Piezas Ok
                            </th>
                            <th class="text-center">
                              Total No Ok
                            </th>
                            <th class="text-center">
                              Paros
                            </th>
                            <th class="text-center">
                              Scrap
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center">{{item.inicioString}}</td>
                            <td class="text-center">{{item.finString}}</td>
                            <td class="text-center">{{$utils.strings.toTime(item.tiempoOperacionSeg)}}</td>
                            <td class="text-center">{{item.totalPiezas}}</td>
                            <td class="text-center">{{item.piezasTeoricas}}</td>
                            <td class="text-center">{{item.piezasOk}}</td>
                            <td class="text-center">{{item.piezasNoOk}}</td>
                            <td class="text-center">
                              {{item.paroReporte.length}} {{ item.paroReporte.length == 1 ? 'Paro' : 'Paros'    }}
                              <br>
                              {{$utils.strings.toTime(item.tiempoParos)}}
                            </td>
                            <td class="text-center">{{(item.scrap * 100).toFixed(2)}}%</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <br>
                  </td>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    </div>
    <div v-if="cargandoData">
      <v-col cols="12" md="12" sm="12" class="text-center">
						<h4>Cargando</h4>
					</v-col>
					<v-col cols="12" md="12" sm="12" class="text-center">
						<v-progress-circular
							:size="75"
							color="green"
							indeterminate
						></v-progress-circular>
					</v-col>
    </div>
    

  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import axios from "axios";
import ICountUp from "vue-countup-v2";
import moment from "moment";
import DownloadBtn from '../../DownloadBtn.vue';

export default {
	components: {
		ICountUp,
    DownloadBtn
	},
  props: {
		active: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
    ...mapState({
       nombreEmpresa: 'nombreEmpresa',
     }),
		realizarPeticion() {
			return this.$utils.isValid(this.filtros.desde) && this.$utils.isValid(this.filtros.hasta);
		},
		url() {
      var url = `/OEEV2/OEERango?Desde=${this.filtros.desde}&Hasta=${this.filtros.hasta}`;
      url += this.$utils.arrays.urlQuery(this.filtros.idsTurnos,"IdsTurnos",false);
      url += this.$utils.arrays.urlQuery(this.filtros.idsAreas,"IdsAreas",false);
      url += this.$utils.arrays.urlQuery(this.filtros.idsOTS,"IdsOTS",false);
      url += this.$utils.arrays.urlQuery(this.filtros.idsArticulos,"IdsArticulos",false);
      url += this.$utils.arrays.urlQuery(this.filtros.idsMaquinaInstancias,"IdsMaquinasInstancias",false);
      url += this.$utils.arrays.urlQuery(this.filtros.idsPersonas,"IdsPersonas",false);
      url += this.$utils.arrays.urlQuery(this.filtros.idsClientes,"IdsClientes",false);
      return url;
		},
		tiempo(){
      return this.$utils.strings.toTime(this.segundos);
    },
    sameDay(){
      var desde = this.$utils.isValid(this.filtros.desde) ? moment(this.filtros.desde, "YYYY-MM-DD") : moment(1);
      var hasta = this.$utils.isValid(this.filtros.hasta) ? moment(this.filtros.hasta, "YYYY-MM-DD") : moment(1);
      return desde.isSameOrAfter(moment(),'day') || hasta.isSameOrAfter(moment(),'day');
    }
	},
	data(){
    return {
    cargandoData:false,
    viewData:false,
    contador: 8,
		interval: null,
		segundos: 60,
		cargando: false,
    expanded: [],
    filtros: {
      desde: null,
      hasta: null,
      idsTurnos: [],
      idsAreas: [],
      idsOTS: [],
      idsArticulos: [],
      idsMaquinaInstancias: [],
      idsPersonas: [],
      idsClientes: []
    },
    turnos:[],
    areas:[],
    ots:[],
    articulos:[],
    maquinasIns:[],
    operadores:[],
    clientes:[],
    datos:{
      calidad: 0.0,
      disponibilidad: 0.0,
      eficiencia: 0.0,
      fecha: null,
      oee: 0.0,
      operaciones: [],
      piezasNoOk: 0,
      piezasOk: 0,
      piezasTeoricas: 0,
      resumenDias: [],
      scrap: 0.0,
      tiempoOperacionSeg: 0,
      tiempoParos: 0,
      totalPiezas: 0
    },
    datosDefault:{
      calidad: 0.0,
      disponibilidad: 0.0,
      eficiencia: 0.0,
      fecha: null,
      oee: 0.0,
      operaciones: [],
      piezasNoOk: 0,
      piezasOk: 0,
      piezasTeoricas: 0,
      resumenDias: [],
      scrap: 0.0,
      tiempoOperacionSeg: 0,
      tiempoParos: 0,
      totalPiezas: 0
    },
    serie1: [],
    chartOptions1:{
      chart: {
        id: 'disponibilidad',
        group: 'oee',
        type: 'line',
        height: 160,
        toolbar: {
          show: false
        }
      },
      colors: ['#118dff'],
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false
        },
        tooltip: {
          enabled: false
        }
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val.toFixed(2)+'%';
          }
        },
        min: 0,
        tickAmount: 5,
      },
    },
    serie2: [],
    chartOptions2:{
      chart: {
        id: 'rendimiento',
        group: 'oee',
        type: 'line',
        height: 160,
        toolbar: {
          show: false
        }
      },
      colors: ['#ff8410'],
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false
        },
        tooltip: {
          enabled: false
        }
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val.toFixed(2)+'%';
          }
        },
        min: 0,
        max: 100,
        tickAmount: 5,
      },
    },
    serie3: [],
    chartOptions3:{
      chart: {
        id: 'calidad',
        group: 'oee',
        type: 'line',
        height: 160,
        toolbar: {
          show: false
        }
      },
      colors: ['#10ff60'],
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false
        },
        tooltip: {
          enabled: false
        }
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val.toFixed(2)+'%';
          }
        },
        min: 0,
        tickAmount: 5,
      }
    },
    serie4: [],
    chartOptions4:{
      chart: {
        id: 'OEE',
        group: 'oee',
        type: 'line',
        height: 160,
        toolbar: {
          show: false
        }
      },
      colors: ['#ff372b'],
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false
        },
        tooltip: {
          enabled: false
        }
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val.toFixed(2)+'%';
          }
        },
        min: 0,
        max: 100,
        tickAmount: 5,
      }
    },
    renderizar: false,
    headers:[
      {
        text: "OT",
        align: "center",
        sortable: false,
        value: "ot",
        // width: "50%",
      },
      {
        text: "Área",
        align: "center",
        sortable: false,
        value: "area",
        // width: "50%",
      },
      {
        text: "Artículo",
        align: "center",
        sortable: false,
        value: "articuloFase",
        // width: "50%",
      },
      {
        text: "Operador(es)",
        align: "center",
        sortable: false,
        value: "personas",
        // width: "50%",
      },
      {
        text: "Máquina(s)",
        align: "center",
        sortable: false,
        value: "maquinas",
        // width: "50%",
      },
      {
        text: "Disponibilidad",
        align: "center",
        sortable: false,
        value: "disponibilidad",
        // width: "50%",
      },
      {
        text: "Rendimiento",
        align: "center",
        sortable: false,
        value: "eficiencia",
        // width: "50%",
      },
      {
        text: "Calidad",
        align: "center",
        sortable: false,
        value: "calidad",
        // width: "50%",
      },
      {
        text: "OEE",
        align: "center",
        sortable: false,
        value: "oee",
        // width: "50%",
      },
      { text: '', value: 'data-table-expand' },
    ]
    }
  },
	methods: {
    nombreArticulo:(articulo) => `[${articulo.codigo}] - ${articulo.nombre}`,
    nombreMaquina: maquina => ` [${maquina.nombre}] - ${maquina.marca} ${maquina.modelo}`,
    nombrePersona: (persona) => persona.nombre + " " + persona.paterno + " " + persona.materno,
		generarSerie(nombre, campo){
      var serie = {
        name: nombre,
        data: []
      };

      if(this.datos.resumenDias != null && this.datos.resumenDias.length > 0){
        serie.data = this.datos.resumenDias.map(d =>{
          return {
            x: d.fecha,
            y: (d[campo] * 100)
          }
        });
      }

      return [serie]; 
    },
    chartOptionsGenerador(id,color = '#008FFB'){
      const start = moment(this.filtros.desde,"YYYY-MM-DD");
      const end = moment(this.filtros.hasta,"YYYY-MM-DD");
      const diferenciaDias = Math.abs(moment.duration(start.diff(end)).asDays());
      const forzarFechas = diferenciaDias <= 2;
      return {
        chart: {
          id: id,
          group: 'oee',
          type: 'line',
          height: 220,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        colors: [color],
        xaxis: {
          type: forzarFechas ? 'category' : 'datetime' ,
          labels: {
            formatter: forzarFechas ? (value) => moment(value).format("DD MMM") : undefined,
            format: forzarFechas ? undefined : "dd MMM",
            datetimeUTC: false,
          },
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return val.toFixed(2)+'%';
            }
          },
          min: 0,
          max: 100,
          tickAmount: 5,
        },
        tooltip: {
          x: {
            formatter: (value) => {
              return moment(value).format("DD MMM");
            }
          }
        }
      };
    },
    initialize() {
      this.getTurnos();
      this.getAreas();
      this.getOTS();
      this.getArticulos();
      this.getMaquinasIns();
      this.getOperadores();
      this.getClientes();
		},
    resetCampo(posicion){
      switch (posicion) {
        case 1:
          this.filtros.idsTurnos = [];
          break;
        case 2:
          this.filtros.idsAreas = [];
          break;
        case 3:
          this.filtros.idsOTS = [];
          break;
        case 4:
          this.filtros.idsArticulos = [];
          break;
        case 5:
          this.filtros.idsMaquinaInstancias = [];
          break;
        case 6:
          this.filtros.idsPersonas = [];
          break;
        case 7:
          this.filtros.idsClientes = [];
          break;
        default:
          break;
      }
    },
		obtenerDatos() {
			if (!this.realizarPeticion || this.cargando) return;
      /* cargandoData:false,
    viewData:false, */
			this.cargando = true;
			this.segundos = 60;
      this.cargandoData = true;
			axios
			.get(this.url)
			.then((response) => {
        this.datos = response.data;

        
        this.chartOptions1 = this.generarSerie('Disponibilidad','disponibilidad');
        this.serie1 = this.chartOptionsGenerador('Disponibilidad','#118dff');
        
        this.chartOptions2 = this.generarSerie('Rendimiento','eficiencia');
        this.serie2 = this.chartOptionsGenerador('Rendimiento','#ff8410');
        
        this.chartOptions3 = this.generarSerie('Calidad','calidad');
        this.serie3 = this.chartOptionsGenerador('Calidad','#10ff60');

        this.chartOptions4 = this.generarSerie('OEE','oee');
        this.serie4 = this.chartOptionsGenerador('OEE','#ff372b');

        this.renderizar = true;
        this.cargando = false;
        this.cargandoData = false;
        this.viewData = true;
        
			})
			.catch((error) => {
				this.cargando = false;
        this.viewData = true;
        this.cargandoData = false;
				console.log(error);
			});
		},
    getAreas() {
			axios
			.get("/Areas")
			.then((response) => {
				this.areas = response.data.filter(a => a.idArea > 1);
			})
			.catch((error) => {
				console.log(error);
			});
		},
		getTurnos() {
			axios
			.get("/Turnos")
			.then((response) => {
				this.turnos = response.data;
			})
			.catch((error) => {
				console.log(error);
			});
		},
    getOTS() {
			axios
				.get("/TrabajoOrdenes/TrabajoOrdenHeaders")
				.then((response) => {
					this.ots = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
    getArticulos() {
			axios
				.get("/Articulos/OTS")
				.then((response) => {
					this.articulos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
    getMaquinasIns(){
      axios
      .get("/MaquinaInstancias")
      .then(response => {
        this.maquinasIns = response.data;
      })
      .catch(error => {
        console.log(error);
      })
    },
    getOperadores() {
			axios
			.get("/Personas/Operadores")
			.then((response) => {
				this.operadores = response.data;
			})
			.catch((error) => {
				console.log(error);
			});
		},
    getClientes(){
      axios
      .get("/Clientes")
      .then(response => {
        response.data.unshift({
          idCliente: -1,
          nombre: this.nombreEmpresa,
        });
        this.clientes = response.data;
      })
      .catch(error => {
        console.log(error);
      });
    },
    url2(path) {
      var url = `${path}?Desde=${this.filtros.desde}&Hasta=${this.filtros.hasta}`;
      url += this.$utils.arrays.urlQuery(this.filtros.idsTurnos,"IdsTurnos",false);
      url += this.$utils.arrays.urlQuery(this.filtros.idsAreas,"IdsAreas",false);
      url += this.$utils.arrays.urlQuery(this.filtros.idsOTS,"IdsOTS",false);
      url += this.$utils.arrays.urlQuery(this.filtros.idsArticulos,"IdsArticulos",false);
      url += this.$utils.arrays.urlQuery(this.filtros.idsMaquinaInstancias,"IdsMaquinasInstancias",false);
      url += this.$utils.arrays.urlQuery(this.filtros.idsPersonas,"IdsPersonas",false);
      url += this.$utils.arrays.urlQuery(this.filtros.idsClientes,"IdsClientes",false);
      return url;
		},
    descargarReporte(){
      var datetime = moment().format('YYYYMMDDHHmmss');
      this.$utils.axios.downloadFile(this.url2("/OEEV2/OEEReport"),'GET','reporte-oee-'+datetime+'.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    }
	},
	mounted() {
		this.interval = setInterval(() => {
			if (!this.active) return;

			if(this.segundos <= 0 && this.sameDay) this.obtenerDatos();
      else this.segundos -= 1;
		}, 1000);
		this.initialize();
	},
	destroyed() {
		clearInterval(this.interval);
	},
};
</script>