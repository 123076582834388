import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"60%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Materias Primas Consumidas ")]),_c(VCardSubtitle,{staticClass:"mt-1"},[_c('p',{staticClass:"mb-0"},[_vm._v("Almacén: "+_vm._s(_vm.editedItem.codigoAlmacen)+" - "+_vm._s(_vm.editedItem.nombreAlmacen))])]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VAutocomplete,{attrs:{"label":"Orden de Trabajo Detalle","items":_vm.editedItem.otDetalles,"item-text":"nombre","item-value":"idTrabajoOrdenDetalle","hide-details":"","clearable":""},on:{"change":function($event){return _vm.getRawMaterialsConsumed()}},model:{value:(_vm.filtros.idOtd),callback:function ($$v) {_vm.$set(_vm.filtros, "idOtd", $$v)},expression:"filtros.idOtd"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"items":_vm.rawMaterialsConsumed,"headers":_vm.rawMaterialsHeaders,"loading":_vm.loading,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.articulo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNombreArticulo(item))+" ")]}},{key:"item.porcentaje",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.porcentaje.toFixed(2))+" % ")]}}])})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"success"},on:{"click":function($event){return _vm.cerrarDialog()}}},[_vm._v("Cerrar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }