<template>
	<v-dialog :value="value" persistent max-width="500px">
		<v-card>
			<v-card-title>Configuración MRO</v-card-title>
			<v-card-text>
				<v-container fluid>
					<v-row>
						<v-col cols="12" class="text-center">
                            <p style="font-size: 16px !important">Días mínimos entre Fecha de Solicitud y Necesidad</p>
							<number-input
								v-model="minDays"
                                size="small"
								controls
								inline
								:min="0"
								center
							></number-input>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					text
					color="success"
					@click="closeDialog()"
					:disabled="saving"
					>Cancelar</v-btn
				>
				<v-btn
					text
					color="error"
					@click="save()"
					:disabled="minDays < 0 || saving"
					:loading="saving"
					>Guardar</v-btn
				>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import axios from "axios";
export default {
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		minimumDays: {
			type: Number,
			default: 1,
		},
	},
	data() {
		return {
			minDays: 1,
			saving: false,
		};
	},
	watch: {
		value() {
			if (this.value) {
				this.minDays = this.minimumDays;
			}
		},
	},
	methods: {
		save() {
			this.saving = true;

			axios
				.put(`/BuyTickets/DiasMinimos/${this.minDays}`)
				.then(() => {
					this.closeDialog();
					this.$emit("refresh:minimumDays");
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.saving = false;
				});
		},
		closeDialog() {
			this.$emit("input", false);
			setTimeout(() => {
				this.minDays = 1;
			}, 200);
		},
	},
};
</script>