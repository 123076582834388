// https://vuex.vuejs.org/en/mutations.html

export default {
  auth_request(state) {
    state.authStatus = 'loading'
  },
  auth_success(state, { token, user, idRol, idUser, idsTurnos, validado, cambiarPassword, idsReportes }) {
    state.authStatus = 'success'
    state.token = token
    state.user = user
    state.idRol = idRol
    state.idUser = idUser
    state.idsTurnos = idsTurnos
    state.validado = validado
    state.cambiarPassword = cambiarPassword
    state.idsReportes = idsReportes
  },
  auth_error(state) {
    state.authStatus = 'error'
  },
  toggleModoAyuda(state,value){
    state.modoAyuda = value;
  },
  double_factor_required(state) {
    state.authStatus = 'double_factor_required'
  },
  logout(state) {
    state.authStatus = ''
    state.token = ''
  },
  routes_change(state, routes) {
    state.routes = routes;
  },
  update_internet_status(state, value) {
    state.internetStatus = value;
  },
  set_ids_turnos(state, ids) {
    state.idsTurnos = ids;
  },
  ignore_email_alert(state) {
    state.validado = true;
  },
  ignore_change_password(state) {
    state.cambiarPassword = false;
  },
  add_report(state, id) {
    if (state.idsReportes.indexOf(id) == -1){
      state.idsReportes.push(id)
    }
  },
  delete_report(state, id) {
    if (state.idsReportes.indexOf(id) >= 0) {
      var index = state.idsReportes.indexOf(id)
      state.idsReportes.splice(index, 1);
    }
  }
}
