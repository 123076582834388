<template>
	<v-dialog :value="value" persistent max-width="800px">
		<v-card>
			<v-card-title style="word-break: break-word">
				Details of Purchase Process<br />
				{{ detailsItem.item }} - {{ detailsItem.description }} -
				{{ detailsItem.quantity }} {{ detailsItem.unity }}
			</v-card-title>
			<v-card-text class="pb-0">
				<v-container fluid>
					<v-row class="justify-space-between">
						<v-col cols="6">
							<v-simple-table>
								<template v-slot:default>
									<tbody>
										<tr>
											<td class="font-weight-bold">
												Item:
											</td>
											<td>{{ detailsItem.item }}</td>
										</tr>
										<tr>
											<td class="font-weight-bold">
												PR Request Date:
											</td>
											<td>{{ detailsItem.prDate }}</td>
										</tr>
										<tr>
											<td class="font-weight-bold">
												PO Need Date:
											</td>
											<td>{{ detailsItem.poDate }}</td>
										</tr>
										<tr>
											<td class="font-weight-bold">
												Material/SRV Need Date:
											</td>
											<td>
												{{ detailsItem.srvDate }}
											</td>
										</tr>
										<tr>
											<td class="font-weight-bold">
												Aging:
											</td>
											<td>
												<div
													class="d-flex align-center justify-start"
												>
													<v-icon
														class="mr-2"
														:class="{
															'success--text':
																detailsItem.aging >=
																3,
															'yellow--text':
																detailsItem.aging >=
																	1 &&
																detailsItem.aging <=
																	2,
															'error--text':
																detailsItem.aging <=
																0,
														}"
														small
														>mdi-circle</v-icon
													>
													<p class="mb-0">
														{{ detailsItem.aging }}
														days
													</p>
												</div>
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
						<v-col cols="6">
							<v-simple-table>
								<template v-slot:default>
									<tbody>
										<tr>
											<td class="font-weight-bold">
												Requester:
											</td>
											<td>
												{{ detailsItem.requester }}
											</td>
										</tr>
										<tr>
											<td class="font-weight-bold">
												Area Manager:
											</td>
											<td>
												{{ detailsItem.areaManager }}
											</td>
										</tr>
										<tr>
											<td class="font-weight-bold">
												Areas:
											</td>
											<td>
												<p
													v-for="(
														area, index
													) in detailsItem.areas"
													class="mb-0"
													:key="`area-${index}`"
												>
													{{
														detailsItem.areas
															.length > 1
															? "•"
															: ""
													}}
													{{ area }}
												</p>
											</td>
										</tr>
										<tr>
											<td class="font-weight-bold">
												Project:
											</td>
											<td>
												{{
													detailsItem.project || "N/A"
												}}
											</td>
										</tr>
										<tr>
											<td class="font-weight-bold">
												Purchase Class:
											</td>
											<td>
												{{ detailsItem.purchaseClass }}
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
					</v-row>
					<v-row v-if="detailsItem.dialogType == 1">
						<v-col cols="12">
							<v-textarea
								v-model="detailsItem.comment"
								label="Comment (Required)"
								clearable
								outlined
								auto-grow
								:disabled="detailsItem.updating"
								rows="6"
							></v-textarea>
						</v-col>
					</v-row>
					<v-row v-else>
						<v-col
							v-if="loadingDetails"
							cols="12"
							class="mt-4 text-center"
						>
							<v-progress-circular
								indeterminate
								color="primary"
								size="70"
								width="4"
							></v-progress-circular>
							<p class="text-h5 text--secondary">Loading...</p>
						</v-col>
						<v-col
							v-else-if="detailsItem.comments.length == 0"
							cols="12"
							class="mt-8 text-center"
						>
							<v-icon size="80" color="grey"
								>mdi-clipboard-text-clock</v-icon
							>
							<p
								class="text-h5 font-weight-medium text--disabled"
							>
								Item history not available
							</p>
						</v-col>
						<v-col v-else cols="12">
							<v-timeline
								:reverse="true"
								:dense="$vuetify.breakpoint.smAndDown"
							>
								<v-timeline-item
									v-for="(
										comment, index
									) in detailsItem.comments"
									:key="`comment-${index}`"
									:color="comment.color"
								>
									<span
										class="text-subtitle-1"
										slot="opposite"
										>{{ comment.date }}</span
									>
									<v-card
										class="elevation-2"
										:color="comment.color"
									>
										<v-card-title
											class="py-3 text-h5 comment-title white--text"
											style="word-break: break-word"
										>
											{{ comment.title }}
										</v-card-title>
										<v-card-text
											class="pt-4 white text--primary"
										>
											<p
												v-for="(
													line, index
												) in comment.body.split('<br>')"
												class="mb-2"
												:key="`line-${index}`"
											>
												{{ line.trim() }}
											</p>
										</v-card-text>
									</v-card>
								</v-timeline-item>
							</v-timeline>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
				
					text
					color="success"
					:disabled="loadingDetails || detailsItem.updating"
					@click="$emit('click:close')"
				>
					Cancel
				</v-btn>
				<v-btn
					v-if="detailsItem.dialogType == 1"
					text
					color="error"
					:disabled="detailsItem.updating || !detailsItem.comment"
					:loading="detailsItem.updating"
					@click="$emit('click:update')"
					>
					Update
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import axios from 'axios';
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        detailsItem: {
            type: Object,
            required: true
        },
    },
    data(){
        return {
            loadingDetails: false
        }
    },
    watch: {
        value(){
            if(this.value && this.detailsItem.dialogType == 2){
                this.getItemDetails(this.detailsItem.idMMCallBuyTicketItem)
            }
        }
    },
    methods: {
        getItemDetails(idMMCallBuyTicketItem){
			this.loadingDetails = true;

			axios
				.get(`/BuyTickets/ItemHistorial/${idMMCallBuyTicketItem}`)
				.then( (response) => {
					this.detailsItem.comments = response.data;
				})
				.catch( (error) => {
					this.detailsItem.comments = [];
					console.log(error);
				})
				.finally( () => {
					this.loadingDetails = false;
				});
		},
    }
};
</script>