<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-dialog v-model="dialogExcedentes" persistent max-width="800px">
          <v-card>
            <v-card-title>
              Acción a Tomar con los Excedentes
            </v-card-title>
            <v-card-text>
              <v-row class="ma-0 pa-0 justify-center">
                <div class="headline red--text">Existen Artículos Excedentes. ¿Qué desea hacer con ellos?</div>
              </v-row>
              <v-row class="ma-0 pa-0">
                <div class="v-btn v-btn--contained green theme--dark headline ma-5 pa-5" @click="finalizarOperacionSend(false)">
                  Mantener Operacion
                </div>
                <v-spacer></v-spacer>
                <div class="v-btn v-btn--contained red theme--dark headline ma-5 pa-5" @click="finalizarOperacionSend(true)">
                  Enviar a Almacén
                </div>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogExcedenteAlmacen" persistent max-width="800px">
          <v-card>
            <v-card-title>
              Excedentes a Enviar al Almacén
            </v-card-title>
            <v-card-text>
              <v-row class="ma-0 pa-0">
                <ul>
                  <li class="headline" v-for="(excedente,idx) in listaExcedentes" :key="'exc'+idx">{{excedente.cantidad}} {{excedente.unidad}} - {{excedente.articulo}}</li>
                </ul>
              </v-row>
              <v-row class="ma-0 pa-0 justify-center">
                <div class="v-btn v-btn--contained red theme--dark headline ma-5 pa-5" @click="cerrarListaExcedentes()">
                  Cerrar
                </div>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogIndicarCantidad" persistent max-width="800px">
          <v-card>
            <v-card-title>Indicar Cantidad Producida</v-card-title>
            <v-card-text>
              <v-row justify="center" class="ma-2 pa-0">
                <v-col cols="6" md="6" sm="6" class="pa-0 ma-0">
                  <v-text-field
                    v-model="cantidad"
                    type="number"
                    min="0"
                    :label="'Máximo: '+((!operacion.excedentes)?operacion.cantidad:'Ilimitado')"
                    :max="(!operacion.excedentes)?operacion.cantidad:Infinity"
                    outlined
                    dense
                    hide-details
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="ma-2 pa-0">
                <v-progress-linear
                  v-model="porcentajeAvance"
                  :color="(operacion.cantidad - cantidad >= 0)?'primary':'red'"
                  height="25"
                >
                  <strong>{{ isFinite(porcentajeAvance)?porcentajeAvance.toFixed(2)+"%":'Ilimitada' }}</strong>
                </v-progress-linear>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-switch
                    label="Especificar Hora de Termino"
                    v-model="horaDiferente"
                  >
                  </v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="horaDiferente" cols="12" md="12" sm="12">
                  <VueCtkDateTimePicker
                    v-model="horaFin"
                    label="Fecha de Ingreso"
                    format="YYYY-MM-DD HH:mm"
                    formatted="YYYY-MM-DD HH:mm"
                    noHeader
                    buttonNowTranslation="Ahora"
                  />
                </v-col>
              </v-row>

            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green" text @click="resetFinalizarOperacion">Cancelar</v-btn>
              <v-btn color="red" :loading="loadBottom" text @click="finalizarOperacion">Terminar Operación</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogParoTipo" persistent max-width="800px">
          <v-card>
            <v-card-title>Seleccione el Motivo del Paro</v-card-title>
            <v-card-text>
              <v-select
                v-model="paroTipo"
                :items="paroTipos"
                item-text="nombre"
                item-value="idParoTipo"
              >
              </v-select>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green" text @click="resetParo">Cancelar</v-btn>
              <v-btn color="red" text @click="iniciarParo">Iniciar Paro</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogErrorTermino" persistent max-width="500px">
          <v-card>
            <v-card-text class="text-center justify-center">
              <v-icon size="200px" color="red">mdi-alert</v-icon>
              <div class="headline">La Fecha y Hora de Termino de la Operación es Inferior a la Fecha y Hora de Inicio de la Operación.</div>
              <br>
              <div class="v-btn v-btn--contained blue theme--dark display-2 ma-5 pa-5" @click="dialogErrorTermino = false">
                Cerrar
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-card>
          <v-row>
            <v-col cols="6" md="6" sm="6">
              <v-card-title>
                <div v-if="paro != null" class="red--text headline">
                  Operación Pausada: {{paroMotivo}}
                </div>
                <div class="headline">
                  {{operacion.clave != null && operacion.clave.length > 0 ? operacion.clave :'ODT-'+operacion.idTrabajoOrden }} - {{operacion.cantidadProducir}} {{operacion.articuloProducir}}
                </div>
              </v-card-title>  
              <v-card-subtitle>
                <div>
                  {{operacion.cantidad}} <div v-if="operacion.cantidadExcedente > 0" class="red--text d-inline-flex">+ {{operacion.cantidadExcedente}}</div> - {{operacion.articulo}}
                </div>
                <div v-if="operacion.idSubpaso!=null">
                  [{{operacion.codigoSubpaso}}] - {{operacion.nombreSubpaso}}
                </div>
                <div class="red--text" v-if="operacion.mensaje">
                  Especial: {{operacion.mensaje}}
                </div>
                <br>
                <div v-if="sesion.integrantes == null || sesion.integrantes.length == 0">
                  Operador: &nbsp; {{sesion.nombre}}
                </div>
                <div v-else>
                  Operadores:
                  <ul>
                    <li v-for="(operador,idx) in sesion.integrantes" :key="'operador-'+idx">{{operador.nombre}}</li>
                  </ul>
                </div>
              </v-card-subtitle>
            </v-col>
            <v-col cols="2" md="2" sm="2" class="align-self-center">
              <div v-if="!operacionPausada && permisoVista('control','c')" class="red v-btn v-btn--contained theme--dark display-1 pa-4" @click="mostrarParo">
                <v-icon x-large color="white">mdi-pause-circle-outline</v-icon>
              </div>
              <div v-if="operacionPausada && permisoVista('control','c')" class="green v-btn v-btn--contained theme--dark display-1 pa-4" @click="terminarParo">
                <v-icon x-large color="white">mdi-play-circle-outline</v-icon>
              </div>
            </v-col>
            <v-col cols="2" md="2" sm="2" class="align-self-center">
              <div class="headline">
                {{formatoTiempo(tiempo)}}
              </div>
            </v-col>
            <v-col cols="2" md="" sm="2" class="align-self-center">
              <v-btn v-if="this.permisoVista('control','c')" color="green" @click="indicarCantidad">{{finalizarLabel}}</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <br>
  </v-container>
</template>

<script>
import moment from "moment";
import underscore from "underscore";
import axios from "axios";
import { mapState } from "vuex";

export default {
  props:{
    operacion:{
      type:Object,
      default:{
        clave:"",
        idTrabajoOrden:0,
        operadoresReales:[],
      },
    },
    sesiones:{
      type:Array,
      default:[],
    },
    paros:{
      type:Array,
      default:[],
    },
    paroTipos:{
      type:Array,
      default:[],
    },
    area:{
      type:Object,
      default:{
        area:'',
        idArea:-1,
        operacionesCurso: 0,
      },
    },
    detalle:{
      type:Object,
      default:{
        idOperacionReal:0,
        comenzo:"",
        operadoresReales:[],
      },
    },
  },
  data: () => ({
    tiempo:0,
    sesion:{
      idEquipo:-1,
      idOperador:-1,
      integrantes:null,
      nombre:"",
      cantidad:0,
      cantidadExcedente:0,
      idOperacion:-1,
    },
    listaExcedentes:[],
    cantidad:0,
    dialogExcedentes:false,
    dialogExcedenteAlmacen:false,
    dialogIndicarCantidad:false,
    paro: null,
    dialogParoTipo:false,
    paroTipo:-1,
    horaFin: null,
    horaDiferente:false,
    dialogErrorTermino:false,
    loadBottom:false
  }),
  created(){
    this.actualizarTiempo();
    this.getOperadores();
  },
  computed:{
    ...mapState(["finalizarLabel"]),
    porcentajeAvance: {
      get(){
        if(parseFloat(this.cantidad) <= 0){
          return 0.0;
        }
        var x = (parseFloat(this.cantidad)/parseFloat(this.operacion.cantidad))*100.0;
        if(!isFinite(x)){
          x = 100.0;
        }
        if(this.operacion.cantidad == 0){
          x += (parseFloat(this.cantidad)/parseFloat(this.operacion.cantidadExcedente))*100.0;
        }

        return isNaN(x)?0.0:x;
      },
      set(){}
    },
    operacionPausada(){
      this.paro = this.paros.find(p => p.idOperacionReal == this.detalle.idOperacionReal);
      if(typeof this.paro === 'undefined')this.paro = null;
      return (this.paro != null);
    },
    paroMotivo(){
      if(this.paro == null) return "";
      else{
        var tipo = this.paroTipos.find(pt => pt.idParoTipo == this.paro.idParoTipo);
        if(typeof tipo === 'undefined' || tipo == null) return "";
        else return tipo.nombre;
      }
    },
  },
  methods:{
    actualizarTiempo(){
      setTimeout( () => {
        this.calcularTiempo();
        this.actualizarTiempo(); 
      }, 1000);
    },
    calcularTiempo(){
      if(this.operacion == null || typeof this.detalle.comenzo === 'undefinied'){
        this.tiempo = 0;
      }
      else{
        this.tiempo = moment().utc().diff(moment(this.detalle.comenzo),'seconds');
      }
    },
    formatoTiempo(tiempo){
      var negativo = false;
      if(tiempo<0){
        negativo = true;
        tiempo = Math.abs(tiempo);
      }
      if(tiempo == 0 || typeof tiempo === 'undefined') return "00:00:00";
      var horas = Math.trunc(tiempo/3600);
      if(horas > 0) tiempo -= (horas * 3600);
      var minutos = Math.trunc(tiempo/60);
      if(minutos > 0) tiempo -= (minutos * 60);
      return (negativo?'-':'')+ this.dosDigitos(horas) +":"+this.dosDigitos(minutos)+":"+this.dosDigitos(tiempo);
    },
    dosDigitos(numero){
      return ("0" + numero).slice(-2)
    },
    getOperadores(){
      if(this.detalle.operadoresReales.length == 0 || this.sesiones.length == 0){
        setTimeout( () => {
          this.getOperadores();
        }, 500);
      }
      else{
        if(this.detalle.operadoresReales.length == 1){
          this.sesion = this.sesiones.find(s => s.idOperador == this.detalle.operadoresReales[0]);
        }
        else if(this.detalle.operadoresReales.length > 1){
          this.sesion = this.sesiones.find(s =>  {
            if(s.integrantes == null) return false;
            return underscore.isMatch(s.integrantes.map(i => i.idOperador).sort(),this.detalle.operadoresReales.sort());
          });
          if(typeof this.sesion === 'undefinied') this.sesion = {
            idEquipo:-1,
            idOperador:-1,
            integrantes:null,
            nombre:"",
          };
        }
      }
    },
    resetFinalizarOperacion(){
      this.cantidad = 0;
      this.dialogIndicarCantidad = false;
      this.horaFin = null;
      this.horaDiferente = false;
    },
    indicarCantidad(){
      this.dialogIndicarCantidad = true;
    },
    finalizarOperacion(){
      if( (this.cantidad > 0 && this.cantidad >= this.operacion.cantidad && (this.operacion.cantidad + this.operacion.cantidadExcedente - this.cantidad) > 0) || (this.operacion.cantidad == 0 && this.cantidad < this.operacion.cantidadExcedente)){
        this.dialogExcedentes = true;
      }
      else{
        this.finalizarOperacionSend();
      }
    },
    finalizarOperacionSend(enviarAlmacen = false){
      this.dialogExcedentes = false;
      this.loadBottom=true;
      axios
        .put("/ComandoYControl/TerminarOperacion",{
          IdArea: this.area.idArea,
          IdOperacionReal: this.detalle.idOperacionReal,
          Cantidad: this.cantidad,
          EnviarAlmacen: enviarAlmacen,
          Termino: this.horaDiferente ? this.horaFin: null,
        })
        .then(response => {
          if(response.data.length){
            this.listaExcedentes = response.data;
            this.dialogExcedenteAlmacen = true;
          }
          this.resetFinalizarOperacion();
          this.$emit('change',null);
          this.loadBottom=false;
        })
        .catch(error => {
          if(error.response.status == 463){
            this.resetFinalizarOperacion();
            this.dialogErrorTermino = true;
          }
          else console.log(error);
        });
    },
    resetParo(){
      this.dialogParoTipo = false;
      this.paroTipo = -1;
    },
    mostrarParo(){
      this.dialogParoTipo = true;
    },
    iniciarParo(){
      axios
      .post("/ComandoYControl/IniciarParo",{
        IdArea:this.area.idArea,
        IdParoTipo:this.paroTipo,
        IdTrabajoOrden:this.operacion.idTrabajoOrden,
        IdTrabajoOrdenDetalle:this.operacion.idTrabajoOrdenDetalle,
        IdOperacionReal:this.detalle.idOperacionReal
      })
      .then( () => {
        this.resetParo();
        this.$emit('update',null);
      })
      .catch(error => {
        console.log(error);
      })
    },
    terminarParo(){
      axios
      .put("/ComandoYControl/TerminarParo",{
        IdArea:this.area.idArea,
        IdParo:(this.paro != null)?this.paro.idParo:0,
        IdTrabajoOrden:this.operacion.idTrabajoOrden,
        IdTrabajoOrdenDetalle:this.operacion.idTrabajoOrdenDetalle,
        IdOperacionReal:this.detalle.idOperacionReal,
      })
      .then( () => {
        this.$emit('update',null);
      })
      .catch(error => {
        console.log(error);
      });
    }
  }
}
</script>