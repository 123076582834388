<template>
  <v-col cols="12">
    <v-dialog v-model="dialogDelete" persistent max-width="40%">
      <v-card>
        <v-card-text>
          <p class="text-h5 pt-4 font-weight-medium text--primary">
            ¿Está seguro que desea eliminar esta Solución?
          </p>
          <p class="subtitle-1 text--primary">
            Esta acción no se puede revertir y será permanente.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="success"
            :disabled="deleting"
            @click="cerrarDialog()"
            >Cancelar</v-btn
          >
          <v-btn
            text
            color="error"
            :disabled="deleting"
            :loading="deleting"
            @click="deleteSolucion()"
            >Eliminar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-card-title
          >{{
            editedItem.idSolucion == 0 ? "Nueva" : "Editar"
          }}
          Solución</v-card-title
        >
        <v-card-text>
          <v-container>
            <v-row>
              <!--
              <v-col cols="12">
                <v-autocomplete
                  v-model="editedItem.departamento"
                  :items="departamentos"
                  label="Departamento"
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="editedItem.area"
                  :items="areas"
                  label="Área"
                  clearable
                ></v-autocomplete>
              </v-col>
              -->
              <v-col cols="12">
								<v-switch
									v-model="editedItem.abierto"
									label="¿Cualquier Causa?"
									clearable
								></v-switch>
							</v-col>
              <v-col cols="12"
                v-if="!editedItem.abierto"
              >
                <v-autocomplete
                  :loading="loadingCausas"
                  :disabled="loadingCausas"
                  v-model="editedItem.idCausa"
                  :items="causasLista"
                  item-value="idCausa"
                  :item-text="nombreCausa"
                  label="Causa"
                  clearable
                  @change="filtroSubcausas()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12"
                v-if="!editedItem.abierto"
              >
                <v-autocomplete
                  :loading="loadingSubcausas"
                  :disabled="loadingSubcausas"
                  v-model="editedItem.idSubcausa"
                  :items="subcausasFiltradas"
                  item-value="idSubCausa"
                  :item-text="nombreSubCausa"
                  label="Subcausa (Opcional)"
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12"
                v-if="editedItem.abierto"
              >
								<v-autocomplete
									v-model="editedItem.departamento"
									:items="departamentos"
									label="Departamento"
									clearable
								></v-autocomplete>
							</v-col>
							<v-col cols="12"
                v-if="editedItem.abierto"
              >
								<v-autocomplete
									v-model="editedItem.area"
									:items="areas"
									label="Área"
									clearable
								></v-autocomplete>
							</v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.codigo"
                  label="Código de la Solución (Opcional)"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.solucion"
                  label="Nombre de la Solución"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="errores.length > 0">
                <ul>
                  <li
                    v-for="(error, idx) in errores"
                    :key="'e-' + idx"
                    class="red--text"
                  >
                    {{ error }}
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="success" :disabled="saving" @click="cerrarDialog()"
            >Cancelar</v-btn
          >
          <v-btn
            text
            color="error"
            :disabled="saving"
            :loading="saving"
            @click="saveSolucion()"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="justify-space-between">
      <v-col cols="4">
        <v-btn
          color="primary"
          :disabled="loading"
          @click="showDialog()"
          class="px-8"
        >
          Nueva Solución
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Buscar"
          single-line
          clearable
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :items="soluciones"
          :headers="solucionesHeaders"
          :search="search"
          :loading="loading"
        >
          <template v-slot:item.abierto="{ item }">
						{{item.abierto ? "Sí":"No"}}
					</template>
				  <template v-slot:item.causas="{ item }">
						{{ item.abierto ? "-" : (item.codigoCausa == null ? item.causa : "["+item.codigoCausa+"] - "+item.causa) }}
          </template>
					<template v-slot:item.subcausas="{ item }">
						{{  item.abierto ? "-" : (item.codigoSubcausa == null ? item.subcausa : "["+item.codigoSubcausa+"] - "+item.subcausa) }}
          </template>
          <template v-slot:item.codigo="{ item }">
            {{ item.codigo == null ? "N/A" : item.codigo }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom color="black" class="white--text">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  small
                  class="mr-2"
                  @click="showDialogEdit(item)"
                >
                  edit
                </v-icon>
              </template>
              <span class="white--text">Editar</span>
            </v-tooltip>
            <v-tooltip bottom color="black" class="white--text">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  small
                  class="mr-2"
                  @click="showDialogDelete(item)"
                >
                  delete
                </v-icon>
              </template>
              <span class="white--text">Eliminar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import axios from "axios";
import moment from "moment";
import inputTime from '@/components/InputTime';
export default {
  data() {
    return {
      search: "",
      errores: [],
      saving: false,
      dialogDelete: false,
      solucionesHeaders: [
        {
          text: "Departamento",
          align: "center",
          width: "13%",
          value: "departamento",
        },
        {
          text: "Area",
          align: "center",
          width: "13%",
          value: "area",
        },
        {
					text: "¿Cualquier Opción?",
					align: "center",
          width: "13%",
					value: "abierto",
				},
        {
          text: "Causa",
          align: "center",
          width: "13%",
          value: "causas",
        },
				{
          text: "Subcausa",
          align: "center",
          width: "13%",
          value: "subcausas",
        },
        {
          text: "Código de la Solucion",
          align: "center",
          width: "13%",
          value: "codigo",
        },
        {
          text: "Solución",
          align: "center",
          width: "13%",
          value: "solucion",
        },
        {
          text: "Acciones",
          align: "center",
          width: "9%",
          value: "actions",
        },
      ],
      editedItem: {
        idSolucion: 0,
        departamento: null,
        area: null,
        solucion: "",
        abierto: false,
        condicion: true,
				idCausa: null,
				codigoCausa: null,
				causa: null,
				idSubcausa: null,
				codigoSubcausa: "",
				subcausa: ""
      },
      defaultEditedItem: {
        idSolucion: 0,
        departamento: null,
        area: null,
        solucion: "",
        abierto: false,
        condicion: true,
				idCausa: null,
				codigo: null,
				causa: null,
				idSubcausa: null,
				codigo: "",
				subcausa: ""
      },
      loading: false,
      deleting: false,
      soluciones: [],
      areas: [],
      departamentos: [],
      causasLista: [],
      subcausasLista: [],
      loadingCausas: false,
      loadingSubcausas: false,
			subcausasFiltradas: []
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    nombreCausa: (causa) =>`${causa.codigo == null? `${causa.causa}`: `[${causa.codigo}] - ${causa.causa}`}`,
    nombreSubCausa: (subcausa) =>`${subcausa.codigo == null? `${subcausa.subcausa}`: `[${subcausa.codigo}] - ${subcausa.subcausa}`}`,
    validate() {
      this.errores = [];

      if (this.editedItem.solucion == null || this.editedItem.solucion == "") {
        this.errores.push("La Solución es Obligatoria");
      } else if (this.editedItem.solucion.length < 5) {
        this.errores.push("La Solución debe tener mínimo 5 caracteres");
      }

      /*
      if (
        this.editedItem.departamento == null ||
        this.editedItem.departamento.length <= 0
      ) {
        this.errores.push("El Departamento es Obligatorio");
      }

      if (this.editedItem.area == null || this.editedItem.area.length <= 0) {
        this.errores.push("El Área es Obligatoria");
      }
      */

      return this.errores.length == 0;
    },
    saveSolucion() {
      if (!this.validate()) return;
      this.saving = true;
      if(this.editedItem.abierto){
        this.editedItem.idCausa = null;
        this.editedItem.idSubcausa = null;
      }
      else{
        this.editedItem.departamento = null;
        this.editedItem.area = null;
      }

      console.log();

      axios({
        url:
          this.editedItem.idSolucion == 0
            ? "/Soluciones/Crear"
            : `/Soluciones/Actualizar/${this.editedItem.idSolucion}`,
        method: this.editedItem.idSolucion == 0 ? "POST" : "PUT",
        data: this.editedItem,
      })
        .then(() => {
          this.saving = false;
          this.cerrarDialog();
          this.initialize();
        })
        .catch((error) => {
          this.saving = false;
          if (error.response) {
            if (error.response.status == 409) {
              this.errores.push(
                "Existe una Solucion con el mismo Nombre o Código"
              );
            }
          }
          console.log(error);
        });
    },
    showDialogEdit(item) {
      this.editedItem = { ...item };
			this.filtroSubcausas();
      this.dialog = true;			
    },
    showDialogDelete(item) {
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    deleteSolucion() {
      this.deleting = true;
      axios
        .put(`/Soluciones/Eliminar/${this.editedItem.idSolucion}`)
        .then(() => {
          this.deleting = false;
          this.cerrarDialog();
          this.initialize();
        })
        .catch((error) => {
          this.deleting = false;
          console.log(error);
        });
    },
    cerrarDialog() {
      this.dialog = false;
      this.dialogDelete = false;
      this.editedItem = { ...this.defaultEditedItem };
      this.errores = [];
    },
    initialize() {
      this.getSoluciones();
      this.getAreas();
      this.getDepartamentos();
      this.getCausas();
			this.getSubcausas();
    },
    showDialog() {
      this.editedItem = { ...this.defaultEditedItem };
      this.dialog = true;
    },
    getSoluciones() {
      this.loading = true;
      axios
        .get("/Soluciones")
        .then((response) => {
          this.soluciones = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getAreas() {
      this.loading = true;
      axios
        .get("/CausaSolucionRecords/ListarLineaAreas")
        .then((response) => {
          this.areas = response.data.map(element => {
            return element.toUpperCase();
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDepartamentos() {
      axios
        .get("/CausaSolucionRecords/ListarDepartamento")
        .then((response) => {
          this.departamentos = response.data.map(element => {
            return element.toUpperCase();
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCausas() {
      this.loadingCausas = true;
      axios
        .get("/Causas")
        .then((response) => {
          this.causasLista = response.data;
          this.loadingCausas = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingCausas = false;
        });
    },
    getSubcausas() {
      this.loadingSubcausas = true;
      axios
        .get("/SubCausas")
        .then((response) => {
          this.subcausasLista = response.data;
          this.loadingSubcausas = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingSubcausas = false;
        });
    },
		filtroSubcausas(){
			let IdCausa = this.editedItem.idCausa;			
      if (IdCausa < 0) return;
			this.subcausasFiltradas = this.subcausasLista.filter(fil=> fil.idCausa == IdCausa );
		}
  },
};
</script>
