<template>
	<v-col cols="12" v-if="permisoVista('mantenimiento-inspecciones','r')">
    <v-dialog v-model="dialogComprobaciones" persistent max-width="85%">
			<v-card>
				<v-card-title>Comprobaciones</v-card-title>
				<v-card-text>
					<p class="text-subtitle-1 text--secondary">
						Máquina: {{ getNombreMaquina() }}
					</p>
					<v-data-table
						:items="itemComprobaciones.comprobaciones"
						:headers="headersComprobaciones"
						loading-text="Cargando... Espere, Por Favor."
						:loading="loadingComprobaciones"
						:footer-props="{
							disableItemsPerPage: true,
							disablePagination: this.loadingComprobaciones,
						}"
					>
						<template v-slot:[`item.satisfactoria`]="{ item }">
							<p
								class="mb-0 font-weight-bold"
								:class="
									item.satisfactoria
										? 'success--text'
										: 'error--text'
								"
								style="font-size: 14px !important"
							>
								{{ item.satisfactoria ? "SI" : "NO" }}
							</p>
						</template>
						<template v-slot:[`item.comentario`]="{ item }">
							<p class="mb-0">
								{{
									item.comentario == null
										? "N/A"
										: item.comentario
								}}
							</p>
						</template>
						<template v-slot:[`item.documentos`]="{ item }">
							<p
								v-if="
									item.documentos == null ||
									item.documentos.length == 0
								"
								class="mb-0"
							>
								N/A
							</p>
							<div class="text-center">
								<template
									v-for="(documento, idx) in item.documentos"
								>
									<div
										class="d-inline-block mx-2 py-2"
										:key="'links-' + idx"
										style="font-size: 14px"
									>
										<a
											:href="`/api/Documentos/Descargar/${documento.nombre}?access_token=${token}`"
											class="text-decoration-none"
											target="_blank"
											>{{ documento.nombre }}</a
										>
									</div>
								</template>
							</div>
						</template>
						<template v-slot:[`item.resuelta`]="{ item }">
							<p
								class="mb-0 font-weight-bold"
								:class="
									item.resuelta
										? 'success--text'
										: 'error--text'
								"
								style="font-size: 14px !important"
							>
								{{ item.resuelta ? "SI" : "NO" }}
							</p>
						</template>
						<template v-slot:[`item.reparacion`]="{ item }">
							<p v-if="item.reparacion == null" class="mb-0">
								N/A
							</p>
							<template v-else>
								<v-col cols="12" class="d-flex pa-0">
									<v-col cols="6" class="py-0 px-1">
										<v-text-field
											:value="
												item.reparacion.tipo == null
													? 'N/A'
													: item.reparacion.tipo
											"
											readonly
											hide-details
										>
											<template v-slot:label>
												<p class="primary--text">
													Tipo
												</p>
											</template>
										</v-text-field>
									</v-col>
									<v-col cols="6" class="pa-0">
										<v-text-field
											:value="
												getStatusReparacion(
													item.reparacion
												)
											"
											readonly
											hide-details
										>
											<template v-slot:label>
												<p class="primary--text">
													Estado
												</p>
											</template>
										</v-text-field>
									</v-col>
								</v-col>
								<v-col cols="12" class="py-0 px-2">
										<v-text-field
											:value="`[${item.reparacion.codigo}] ${item.reparacion.nombre}`"
											readonly
											hide-details
										>
											<template v-slot:label>
												<p class="primary--text">
													Código
												</p>
											</template>
										</v-text-field>
									</v-col>
								<v-col cols="12" class="d-flex pa-0">
									<v-col cols="6" class="py-0 px-1">
										<v-text-field
											:value="
												item.reparacion
													.fechaInicioProgramada ==
												null
													? 'N/A'
													: item.reparacion
															.fechaInicioProgramada
											"
											readonly
											hide-details
										>
											<template v-slot:label>
												<p class="primary--text">
													Inicio Programado
												</p>
											</template>
										</v-text-field>
									</v-col>
									<v-col cols="6" class="py-0 px-1">
										<v-text-field
											:value="
												item.reparacion
													.fechaFinProgramada == null
													? 'N/A'
													: item.reparacion
															.fechaFinProgramada
											"
											readonly
											hide-details
										>
											<template v-slot:label>
												<p class="primary--text">
													Fin Programado
												</p>
											</template>
										</v-text-field>
									</v-col>
								</v-col>
								<v-col cols="12" class="d-flex pa-0">
									<v-col cols="6" class="py-0 px-1">
										<v-text-field
											:value="
												item.reparacion
													.fechaInicioRealizo == null
													? 'N/A'
													: item.reparacion
															.fechaInicioRealizo
											"
											readonly
											hide-details
										>
											<template v-slot:label>
												<p class="primary--text">
													Inicio Realizo
												</p>
											</template>
										</v-text-field>
									</v-col>
									<v-col cols="6" class="py-0 px-1">
										<v-text-field
											:value="
												item.reparacion
													.fechaFinRealizo == null
													? 'N/A'
													: item.reparacion
															.fechaFinRealizo
											"
											readonly
											hide-details
										>
											<template v-slot:label>
												<p class="primary--text">
													Fin Realizo
												</p>
											</template>
										</v-text-field>
									</v-col>
								</v-col>
							</template>
						</template>
					</v-data-table>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="green"
						text
						small
						@click="cerrarDialogComprobaciones"
						>Cerrar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
    <v-dialog v-model="dialogInspeccion" persistent max-width="70%">
			<v-card>
				<v-card-title>Inspecciones</v-card-title>
				<v-card-text>
					<v-data-table
						:items="itemInspeccion"
						:headers="headersInspeccion"
						loading-text="Cargando... Espere, Por Favor."
						:loading="loadingInspeccion"
						:footer-props="{
							disableItemsPerPage: true,
							disablePagination: this.loadingInspeccion,
						}"
					>
						<template v-slot:[`item.completo`]="{ item }">
							<p
								class="mb-0 font-weight-bold"
								:class="
									item.completo
										? 'success--text'
										: 'error--text'
								"
								style="font-size: 14px !important"
							>
								{{ item.completo ? "SI" : "NO" }}
							</p>
						</template>
						<template v-slot:[`item.fechaInicio`]="{ item }">
							{{
								item.fechaInicio == null
									? "N/A"
									: item.fechaInicio
							}}
						</template>
						<template v-slot:[`item.fechaFin`]="{ item }">
							{{ item.fechaFin == null ? "N/A" : item.fechaFin }}
						</template>
						<template v-slot:[`item.maquina`]="{ item }">
							{{ `${item.marca} - ${item.modelo}` }}
						</template>
						<template v-slot:[`item.actions`]="{ item }">
							<v-tooltip
								
								bottom
								color="black"
								class="white--text"
							>
								<template v-slot:activator="{ on }">
									<v-icon
										:class="
											item.comprobaciones.length == 0
											? 'text--disabled'
											: ''
										"
										v-on="on"
										small
										@click="
											item.comprobaciones.length != 0
											&& verComprobaciones(item)
										"
										:style="
											item.comprobaciones.length == 0
											&& 'cursor: default'
										"
									>
										mdi-order-bool-ascending-variant
									</v-icon>
								</template>
								<span class="white--text"
									>Ver Comprobaciones</span
								>
							</v-tooltip>
						</template>
					</v-data-table>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="green"
						text
						small
						@click="cerrarDialogInspeccion"
						>Cerrar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col cols="6" class="pt-0">
				<datePicker
					v-model="filtros.inicio"
					format="YYYY-MM-DD"
					label="Desde"
					clearable
					@input="mantenimientos_update_page_action()"
				></datePicker>
			</v-col>
			<v-col cols="6" class="pt-0">
				<datePicker
					v-model="filtros.fin"
					:minDate="filtros.inicio"
					format="YYYY-MM-DD"
					label="Hasta"
					clearable
					@input="mantenimientos_update_page_action()"
				></datePicker>
			</v-col>
			<v-col cols="6">
				<v-select
					v-model="filtros.completo"
					:items="itemsCompleto"
					item-text="nombre"
					item-value="value"
					label="Completos"
					class="pt-1"
					hide-details
					clearable
					@change="mantenimientos_update_page_action()"
				></v-select>
			</v-col>
			<v-col cols="6">
				<v-autocomplete
					v-model="filtros.idsInspeccion"
					:items="inspecciones"
					:item-text="nombreInspeccion"
					item-value="idMantenimientoInspeccion"
					label="Inspección"
					class="mt-0 pt-0 mb-3"
					clearable
					hide-details
					multiple
					chips
					deletable-chips
					@change="mantenimientos_update_page_action()"
				></v-autocomplete>
			</v-col>
		</v-row>
    <v-data-table
			:items="inspeccionCiclos"
			:headers="headersInspeccionCiclos"
			loading-text="Cargando... Espere, Por Favor."
			:loading="loading"
			:footer-props="{
				disableItemsPerPage: true,
				disablePagination: this.loading,
			}"
      item-key="idMantenimentoInspeccion"
      show-expand
      :expanded.sync="expanded"
		>
      <template v-slot:[`item.porcentaje`]="{ item }">
        <v-progress-linear
					:value="porcentajeInspecciones(item)"
					:color="porcentajeInspecciones(item) == 100 ? 'green' : 'blue'"
					height="25"
					rounded
				>
					<strong :class="porcentajeInspecciones(item) == 100 ? 'white--text' : 'black--text'">
            {{ porcentajeInspecciones(item).toFixed(2) }}%
          </strong>
				</v-progress-linear>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <br>
        <!--
        {{item.ciclos}}
        -->
        <v-data-table
          :items="item.ciclos"
          :headers="headersCiclos"
          :items-per-page="-1"
          hide-default-footer
        >
          <template v-slot:[`item.porcentaje`]="{ item }">
			    	<v-progress-linear
			    		:value="item.porcentaje"
			    		:color="item.porcentaje == 100 ? 'green' : 'blue'"
			    		height="25"
			    		rounded
			    	>
			    		<strong
			    			:class="
			    				item.porcentaje == 100
			    					? 'white--text'
			    					: 'black--text'
			    			"
			    			>{{ Math.ceil(item.porcentaje) }}%</strong
			    		>
			    	</v-progress-linear>
			    </template>
			    <template v-slot:[`item.actions`]="{ item }">
			    	<v-tooltip
			    		
			    		bottom
			    		color="black"
			    		class="white--text"
			    	>
			    		<template v-slot:activator="{ on }">
			    			<v-icon v-on="on" small @click="verInspecciones(item)">
			    				mdi-eye
			    			</v-icon>
			    		</template>
			    		<span class="white--text">Ver Inspecciones</span>
			    	</v-tooltip>
			    </template>
        </v-data-table>
        <br>
        <br>
      </td>
    </template>
    </v-data-table>
	</v-col>
</template>

<script>
import axios from "axios";

export default {
	data: () => ({
	expanded: [],
    inspecciones:[],
    loading: false,
		filtros: {
			completo: null,
			idsInspeccion: [],
			inicio: null,
			fin: null,
		},
    inspeccionCiclos:[],
    headersInspeccionCiclos:[
      {
				text: "Código Inspección",
				align: "center",
				sort: false,
				value: "codigo",
			},
      {
				text: "Nombre Inspeccíon",
				align: "center",
				sort: false,
				value: "nombre",
			},
      {
				text: "Porcentaje Completado",
				align: "center",
				sort: false,
				value: "porcentaje",
        width: "50%"
			},
      { text: '', value: 'data-table-expand' },
    ],
    itemsCompleto: [
			{
				value: true,
				nombre: "SI"
			},
			{
				value: false,
				nombre: "NO"
			}
		],
    headersCiclos: [
			{
				text: "# Ciclo",
				align: "center",
				sort: false,
				value: "numero",
			},
			{
				text: "%",
				align: "center",
				sort: false,
				value: "porcentaje",
			},
			{
				text: "Inicio",
				align: "center",
				sort: false,
				value: "fechaInicio",
			},
			{
				text: "Acciones",
				align: "center",
				sort: false,
				value: "actions",
			},
		],
    dialogInspeccion: false,
		itemInspeccion: [],
    headersInspeccion: [
			{
				text: "Completo",
				align: "center",
				sort: false,
				value: "completo",
			},
			{
				text: "Responsable",
				align: "center",
				sort: false,
				value: "responsable",
			},
			{
				text: "Máquina",
				align: "center",
				sort: false,
				value: "maquina",
			},
			{
				text: "Instancia",
				align: "center",
				sort: false,
				value: "maquinaInstancia",
			},
			{
				text: "Inicio",
				align: "center",
				sort: false,
				value: "fechaInicio",
			},
			{
				text: "Fin",
				align: "center",
				sort: false,
				value: "fechaFin",
			},
			{
				text: "Acciones",
				align: "center",
				sort: false,
				value: "actions",
			},
		],
    loadingInspeccion: false,
    itemComprobaciones: {
			maquinaInstancia: "",
			marca: "",
			modelo: "",
		},
		defaultItemComprobaciones: {
			maquinaInstancia: "",
			marca: "",
			modelo: "",
		},
    dialogComprobaciones: false,
    headersComprobaciones: [
			{
				text: "Comprobación",
				align: "center",
				sort: false,
				value: "comprobacion",
			},
			{
				text: "Fecha",
				align: "center",
				sort: false,
				value: "fecha",
			},
			{
				text: "Satisfactoria",
				align: "center",
				sort: false,
				value: "satisfactoria",
			},
			{
				text: "Comentario",
				align: "center",
				sort: false,
				value: "comentario",
			},
			{
				text: "Documentos",
				align: "center",
				sort: false,
				value: "documentos",
			},
			{
				text: "Resuelta",
				align: "center",
				sort: false,
				value: "resuelta",
			},
			{
				text: "Reparación",
				align: "center",
				sort: false,
				value: "reparacion",
			},
		],
    loadingComprobaciones: false,
    
  }),
  mounted() {
		this.initialize();
	},
	methods: {
    nombreInspeccion: ins => `[${ins.codigo}] ${ins.nombre}`,
		initialize() {
			this.mantenimientos_update_page_action();
			this.getInspecciones();
		},
		getStatusReparacion(reparacion) {
			if (
				reparacion.fechaInicioRealizo == null &&
				reparacion.fechaFinRealizo == null
			) {
				return "Programada";
			} else if (
				reparacion.fechaInicioRealizo != null &&
				reparacion.fechaFinRealizo == null
			) {
				return "En Progreso";
			} else if (
				reparacion.fechaInicioRealizo != null &&
				reparacion.fechaFinRealizo != null
			) {
				return "Realizada";
			}
		},
		getInspecciones(){
			axios
				.get("/Mantenimientos/GetMantenimientoInspeccion")
				.then( response => {
					this.inspecciones = response.data;
				})
				.catch( error => {
					console.log( error );
				})
		},
    mantenimientos_update_page_action() {
      let idsInspecciones = "";
			if(this.filtros.idsInspeccion.length > 0){
				this.filtros.idsInspeccion.forEach((ins)=>{
					idsInspecciones += `&IdsInspeccion=${ins}`;
				});
			}
			axios
			.get(`/Mantenimientos/EstadoInspeccionPorcentajes?${idsInspecciones}`,{
				params: {
					Completo: this.filtros.completo,
					Inicio: this.filtros.inicio,
					Fin: this.filtros.fin
				}
			})
			.then((response) => {
        this.inspeccionCiclos = response.data;
      })
      .catch(error => {
        console.log(error)
      });
		},
    porcentajeInspecciones(item){
      var completados = 0;
      var total = 0;
      item.ciclos.forEach(ciclo => {
        completados += ciclo.inspecciones.filter(x => x.completo == true).length;
        total += ciclo.inspecciones.length;
      })
      if(total == 0) return 0.0;

      return parseFloat(((completados / total)*100.0).toFixed(2));
    },
    verInspecciones(item) {
			this.itemInspeccion = item.inspecciones;
			this.dialogInspeccion = true;
		},
    cerrarDialogInspeccion() {
			this.itemInspeccion = [];
			this.dialogInspeccion = false;
		},
    verComprobaciones(item) {
			this.itemComprobaciones = Object.assign({}, item);
			this.dialogComprobaciones = true;
		},
    cerrarDialogComprobaciones() {
			this.itemComprobaciones = Object.assign({},this.defaultItemComprobaciones);
			this.dialogComprobaciones = false;
		},
    getNombreMaquina() {
			return `[${this.itemComprobaciones.maquinaInstancia}] - ${this.itemComprobaciones.marca.trim()} ${this.itemComprobaciones.modelo.trim()}`;
		},
	},
};
</script>

<style>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>