<template>
  <!-- <v-container> -->
    <v-row justify-sm="center">
      <v-card 
        class="mt-4"
        :width="anchoMaximo"
        fill
      >
        <v-tabs show-arrows v-model="tab" :background-color="colorTabs" centered dark icons-and-text>
          <v-tabs-slider></v-tabs-slider>

          <v-tab v-if="mostrarVista('tickets-catalogs')" v-tooltip.bottom="modoAyuda && $help.compras.catalogs" href="#tab-1" @click="updateCatalogsList()">
            Catalogs
            <v-icon>mdi-clipboard-list</v-icon>
          </v-tab>

          <v-tab v-if="mostrarVista('tickets')" v-tooltip.bottom="modoAyuda && $help.compras.myTickets" href="#tab-2" @click="updateMyTickets()">
            My Tickets
            <v-icon>mdi-ticket-confirmation</v-icon>
          </v-tab>

          <v-tab v-if="mostrarVista('purchasingprocesses')" v-tooltip.bottom="modoAyuda && $help.compras.purchasingProcesses" href="#tab-3" @click="updatePurchasingProcesses()">
            Purchasing <br> Processes
            <v-icon>mdi-file-percent</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.compras.evolucionCostos" href="#tab-4" v-if="mostrarVista('evolucioncostos')" @click="updateEvolucionCostos()">
            Evolución de Costos
            <v-icon>mdi-chart-line</v-icon>
          </v-tab>
          
          <v-tab v-tooltip.bottom="modoAyuda && $help.compras.proveedores" href="#tab-5" v-if="mostrarVista('proveedores') || mostrarVista('proveedortipos')" @click="updateProveedores()">
            Proveedores
            <v-icon>mdi-store-outline</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.compras.ingresosMP" href="#tab-6" v-if="mostrarVista('ingresos')" @click="updateIngresos()">
            Ingresos M.P.
            <v-icon>mdi-cart-arrow-down</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.compras.historicoArticulos" href="#tab-7" v-if="mostrarVista('preciohistorico')" @click="updatePrecioArticulos()">
            Histórico Precios<br/>de Articulos
            <v-icon>mdi-chart-histogram</v-icon>
          </v-tab>

        </v-tabs>

        <v-tabs-items v-model="tab">

          <v-tab-item :key="1" :value="'tab-1'">
            <v-card flat>
              <v-card-text>
                <CatalogsList ref="catalogslist" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="2" :value="'tab-2'">
            <v-card flat>
              <v-card-text>
                <MyTickets ref="mytickets" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="3" :value="'tab-3'">
            <v-card flat>
              <v-card-text>
                <PurchasingProcesses ref="purchasingprocesses" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="4" :value="'tab-4'" v-if="mostrarVista('evolucioncostos')">
            <v-card flat>
              <v-card-text>
                <comprasEvolucionCostos ref="evolucioncostos"></comprasEvolucionCostos>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="5" :value="'tab-5'">
            <v-card flat>
              <v-card-text>
                <ListaProveedores ref="providers"/>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="6" :value="'tab-6'" v-if="mostrarVista('ingresos')">
            <v-card flat>
              <v-card-text>
                <comprasIngresos ref="ingresos"></comprasIngresos>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="7" :value="'tab-7'" v-if="mostrarVista('preciohistorico')">
            <v-card flat>
              <v-card-text>
                <PreciosArticulos ref="updatePrecios" />
              </v-card-text>
            </v-card>
          </v-tab-item>

        </v-tabs-items>
      </v-card>
    </v-row>
  <!-- </v-container> -->
</template>

<script>
import Ingresos from "./Compras/IngresosMP.vue";
import EvolucionCostos from "./Compras/EvolucionCostos.vue";
import PreciosArticulos from './Compras/PreciosArticulos.vue';
import ListaProveedores from './Compras/ListaProveedores.vue';
import MyTickets from './Compras/MyTickets.vue';
import PurchasingProcesses from './Compras/PurchasingProcesses.vue';
import { mapState } from 'vuex';
import CatalogsList from './Compras/CatalogsList.vue';

export default {
  data() {
    return {
      tab: null,
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    };
  },
  methods:{
    updateCatalogsList(){
      if(typeof this.$refs.catalogslist === 'undefined') return;
      this.$refs.catalogslist.initialize();
    },
    updatePurchasingProcesses(){
      if(typeof this.$refs.purchasingprocesses === 'undefined') return;
      this.$refs.purchasingprocesses.initialize();
    },
    updateProveedores(){
      if(typeof this.$refs.providers === 'undefined') return;
      this.$refs.providers.initialize();
    },
    updateIngresos(){
      if(typeof this.$refs.ingresos === 'undefined') return;
      this.$refs.ingresos.initialize();
    },
    updateMyTickets(){
      if(typeof this.$refs.mytickets === 'undefined') return;
      this.$refs.mytickets.initialize();
    },
    updateEvolucionCostos(){
      if(typeof this.$refs.evolucioncostos === 'undefined') return;
      this.$refs.evolucioncostos.initialize();
    },
    updatePrecioArticulos(){
      if(typeof this.$refs.updatePrecios === 'undefined') return;
      this.$refs.updatePrecios.initialize();
    }
  },
  computed: {
    ...mapState(["anchoMaximo","colorTabs","modoAyuda"])
  },
  components: {
    ComprasIngresos: Ingresos,
    ComprasEvolucionCostos: EvolucionCostos,
    PreciosArticulos,
    ListaProveedores,
    MyTickets,
    PurchasingProcesses,
    CatalogsList
  }
};
</script>