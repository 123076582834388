<template>
  <div>
    <v-col cols="12">
      <v-row class="justify-space-between">
        <v-col cols="4">
          <v-btn color="primary" dark class="px-8" @click="dialog = true">
            Nuevo Subfactor
          </v-btn>
          <v-dialog v-model="dialog" persistent max-width="500px">
            <v-card>
              <v-card-title
                >{{
                  editedItem.idFactor == 0 ? "Crear Nuevo " : "Editar "
                }}
                Subfactor</v-card-title
              >
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.subFactor"
                        label="Nombre"
                        clearable
                      ></v-text-field>
                      <v-autocomplete
                        v-model="editedItem.idFactor"
                        :items="factores"
                        item-value="idFactor"
                        item-text="nombre"
                        label="Factor"
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" v-if="errores.length > 0">
                      <ul>
                        <li
                          v-for="(error, idx) in errores"
                          :key="'e-' + idx"
                          class="red--text"
                        >
                          {{ error }}
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="success"
                  :disabled="saving"
                  @click="cerrarDialog()"
                  >Cancelar</v-btn
                >
                <v-btn
                  text
                  color="error"
                  :disabled="saving"
                  :loading="saving"
                  @click="saveChanges()"
                  >Guardar</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" persistent max-width="40%">
            <v-card>
              <v-card-text>
                <p class="text-h5 pt-4 font-weight-medium text--primary">
                  ¿Está seguro que desea eliminar esta Subfactor?
                </p>
                <p class="subtitle-1 text--primary">
                  Esta acción no se puede revertir y será permanente.
                </p>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="success"
                  :disabled="deleting"
                  @click="cerrarDialog()"
                  >Cancelar</v-btn
                >
                <v-btn
                  text
                  color="error"
                  :disabled="deleting"
                  :loading="deleting"
                  @click="deleteSubfactor()"
                  >Eliminar</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="Header"
            :items="subfactores"
            :loading="loading"
            class="elevation-1"
          >
            <template v-slot:item.subFactores="{ item }">
              <!-- <span v-if="item.subFactores.length == 0">N/A</span> -->
              <div v-for="(sub, i) in item.subFactores" :key="i">
                {{ sub.nombre }}
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom color="black" class="white--text">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    small
                    class="mr-2"
                    @click="showDialogEdit(item)"
                  >
                    edit
                  </v-icon>
                </template>
                <span class="white--text">Editar</span>
              </v-tooltip>
              <v-tooltip bottom color="black" class="white--text">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    small
                    class="mr-2"
                    @click="showDialogDelete(item)"
                  >
                    delete
                  </v-icon>
                </template>
                <span class="white--text">Eliminar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      Header: [
        {
          text: "Departamento",
          align: "center",
          value: "departamento",
        },
        {
          text: "Factor",
          align: "center",
          value: "factor",
        },
        {
          text: "Subfactor",
          align: "center",
          value: "subFactor",
        },
        {
          text: "Acciones",
          align: "center",
          width: "10%",
          value: "actions",
        },
      ],
      subfactores: [],
      factores: [],
      errores: [],
      dialog: false,
      editedItem: {
        idFactor: 0,
        idSubFactor: 0,
        subFactor: null,
      },
      saving: false,
      loading: false,
      dialogDelete: false,
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    validate() {
      this.errores = [];

      if (this.editedItem.subFactor == null) {
        this.errores.push("El subfactor es obligatorio");
      } else if (this.editedItem.subFactor == "") {
        this.errores.push("El subfactor es obligatorio");
      } else if (this.editedItem.subFactor.length < 5) {
        this.errores.push("El subfactor debe tener mínimo 5 caracteres");
      }

      if (this.editedItem.idFactor == null) {
        this.errores.push("El factor es obligatorio");
      } else if (this.editedItem.idFactor == 0) {
        this.errores.push("El factor es obligatorio");
      }

      return this.errores.length == 0;
    },
    cerrarDialog() {
      this.dialog = false;
      this.dialogDelete = false;
      this.editedItem = { ...this.defaultEditedItem };
      this.errores = [];
    },
    showDialogEdit(item) {
      this.editedItem = { ...item };
      this.dialog = true;
    },
    showDialogDelete(item) {
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    deleteSubfactor() {
      this.deleting = true;
      axios
        .put(`/Factores/EliminarSubFactor/${this.editedItem.idSubFactor}`)
        .then(() => {
          this.deleting = false;
          this.cerrarDialog();
          this.initialize();
        })
        .catch((error) => {
          this.deleting = false;
          console.log(error);
        });
    },
    saveChanges() {
      if (!this.validate()) return;

      this.saving = true;
      axios({
        url:
          this.editedItem.idSubFactor == 0
            ? "/Factores/CrearSubFactor"
            : `/Factores/EditarSubFactor/${this.editedItem.idSubFactor}`,
        method: this.editedItem.idSubFactor == 0 ? "POST" : "PUT",
        data: {
          idFactor: this.editedItem.idFactor,
          nombre: this.editedItem.subFactor,
        },
      })
        .then(() => {
          this.saving = false;
          this.cerrarDialog();
          this.initialize();
        })
        .catch((error) => {
          this.saving = false;
          if (error.response) {
            if (error.response.status == 409) {
              this.errores.push(
                "Existe una Causa con el mismo Nombre o Código"
              );
            }
          }
          console.log(error);
        });
    },
    initialize() {
      this.getSubfactores();
      this.getFactores();
    },
    getSubfactores() {
      this.loading = true;
      axios
        .get("/Factores/ListarSubFactores")
        .then((response) => {
          this.subfactores = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getFactores() {
      this.loading = true;
      axios
        .get("/Factores/Listar")
        .then((response) => {
          this.factores = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
