<template>
	<v-col cols="12">
		<v-dialog v-model="dialogDetalles" persistent max-width="90%">
			<v-card>
				<v-card-title
					>Detalles de la Orden de Trabajo Madre
					{{ editedItem.clave }}</v-card-title
				>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12">
								<Resumen
									:key="`odtresumen-${resumenKey}`"
									:idTrabajoOrdenMadre="
										editedItem.idTrabajoOrdenMadre
									"
								/>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text color="success" @click="cerrarDialog()"
						>Cerrar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog" persistent max-width="500px">
			<v-card>
				<v-card-title
					>{{
						editedItem.idTrabajoOrdenMadre == null
							? "Crear Nueva"
							: "Editar"
					}}
					ODT Madre</v-card-title
				>
				<v-card-text>
					<v-row>
						<v-col cols="12">
							<v-text-field
								v-model="editedItem.clave"
								label="Clave"
								clearable
								:disabled="saving"
								:error-messages="errorClave"
								@focus="errorClave = ''"
							></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field
								v-model="editedItem.nombre"
								label="Nombre"
								clearable
								:disabled="saving"
								:error-messages="errorNombre"
								@focus="errorNombre = ''"
							></v-text-field>
						</v-col>
						<v-col cols="12" class="pl-2 mb-2">
							<VueCtkDateTimePicker
								id="fechaCompromiso"
								v-model="editedItem.fechaCompromiso"
								label="Fecha de Compromiso"
								format="YYYY-MM-DD HH:mm"
								formatted="YYYY-MM-DD HH:mm"
								noHeader
								overlay
								buttonNowTranslation="Ahora"
								:error="errorFecha != ''"
								@input="resetErrorFecha"
							/>
							<p
								v-if="errorFecha"
								class="red--text pt-1 mb-0"
								style="font-size: 12px !important"
							>
								{{ errorFecha }}
							</p>
						</v-col>
						<v-col cols="12">
							<v-autocomplete
								v-model="editedItem.idsOrdenTrabajo"
								label="Ordenes de Trabajo"
								:items="ordenesTrabajo"
								item-text="clave"
								item-value="idTrabajoOrden"
								multiple
								chips
								deletable-chips
								clearable
								:loading="loadingODTDisponibles"
								:disabled="saving || loadingODTDisponibles"
								:error-messages="errorODTS"
								@focus="errorODTS = ''"
							></v-autocomplete>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="saving"
						@click="cerrarDialog()"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="saving"
						:loading="saving"
						@click="save()"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col cols="4">
				<v-btn color="primary" :disabled="loading" @click="showDialog()"
					>Crear Nueva ODT Madre</v-btn
				>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
					:items="odtMadres"
					:headers="odtMadresHeaders"
					:loading="loading"
					:page.sync="page"
					hide-default-footer
				>
					<template v-slot:item.cliente="{ item }">
						<p v-for="cliente,idx in item.cliente" class="mb-0 py-2" :key="idx">
							• {{ cliente }}
						</p>
					</template>
					<template v-slot:item.costoEstimadoTotal="{ item }">
						$ {{ item.costoEstimadoTotal.toFixed(2) }}
					</template>
					<template v-slot:item.costoEstimadoActual="{ item }">
						$ {{ item.costoEstimadoActual.toFixed(2) }}
					</template>
					<template v-slot:item.costoReal="{ item }">
						$ {{ item.costoReal.toFixed(2) }}
					</template>
					<template v-slot:item.porcentajeCompletado="{ item }">
						<p
							class="mb-0"
							:class="colorAvance(item.porcentajeCompletado)"
						>
							{{ item.porcentajeCompletado.toFixed(2) }} %
						</p>
						<p class="mb-0 pt-2">
							<v-progress-linear
								:color="colorAvance(item.porcentajeCompletado).split('--')[0]"
								 buffer-value="0"
								:value="item.porcentajeCompletado"
								stream
							></v-progress-linear>
						</p>
					</template>
					<template v-slot:item.fechaCompromiso="{ item }">
						<p
							class="mb-0"
							:class="colorDiasRestantes(item.diasRestantes)"
						>
							{{ formatFechaCompromiso(item.fechaCompromiso) }}
						</p>
					</template>
					<template v-slot:item.diasRestantes="{ item }">
						<p
							class="mb-0"
							:class="colorDiasRestantes(item.diasRestantes)"
						>
							{{ item.diasRestantes }}
						</p>
					</template>
					<template v-slot:item.actions="{ item }">
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									class="mr-2"
									@click="showDialogEdit(item)"
								>
									edit
								</v-icon>
							</template>
							<span class="white--text">Editar</span>
						</v-tooltip>
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									@click="showDialogDetalles(item)"
								>
									mdi-eye
								</v-icon>
							</template>
							<span class="white--text">Ver Detalles</span>
						</v-tooltip>
					</template>
					<template v-slot:footer="{ props }">
						<v-col cols="12" class="d-flex justify-end align-center border-footer">
							<p class="mb-0 mr-8" style="font-size: 12px !important">Filas por página:</p>
							<v-text-field
								:value="props.pagination.itemsPerPage"
								dense
								hide-details
								disabled
								class="centered-input footer-input mt-0 mr-10"
							></v-text-field>
							<p class="mb-0 mr-10" style="font-size: 12px !important">
								{{props.pagination.itemsLength ? getPageText(props.pagination): '-'}}
							</p>
							<v-pagination
								v-model="page"
								:length="props.pagination.pageCount"
								:total-visible="7"
								:disabled="loading"
							></v-pagination>
						</v-col>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import axios from "axios";
import Resumen from "./Resumen.vue";
import moment from "moment";
export default {
	components: { Resumen },
	data() {
		return {
			page: 1,
			loadingODTDisponibles: false,
			resumenKey: 0,
			dialogDetalles: false,
			ordenesTrabajo: [],
			saving: false,
			errorClave: "",
			errorNombre: "",
			errorFecha: "",
			errorODTS: "",
			odtMadres: [],
			odtMadresHeaders: [
				{
					text: "Clave",
					align: "center",
					value: "clave",
				},
				{
					text: "Nombre",
					align: "center",
					value: "nombre",
				},
				{
					text: "Clientes",
					align: "center",
					value: "cliente",
				},
				{
					text: "Costo Estimado Total",
					align: "center",
					value: "costoEstimadoTotal",
				},
				{
					text: "Costo Estimado Actual",
					align: "center",
					value: "costoEstimadoActual",
				},
				{
					text: "Costo Real",
					align: "center",
					value: "costoReal",
				},
				{
					text: "% Completado",
					align: "center",
					value: "porcentajeCompletado",
				},
				{
					text: "Fecha de Compromiso",
					align: "center",
					value: "fechaCompromiso",
				},
				{
					text: "Días Restantes",
					align: "center",
					value: "diasRestantes",
				},
				{
					text: "Acciones",
					align: "center",
					value: "actions",
				},
			],
			loading: false,
			dialog: false,
			editedItem: {
				idTrabajoOrdenMadre: null,
				clave: null,
				nombre: null,
				fechaCompromiso: null,
				idsOrdenTrabajo: [],
			},
			defaultEditedItem: {
				idTrabajoOrdenMadre: null,
				clave: null,
				nombre: null,
				fechaCompromiso: null,
				idsOrdenTrabajo: [],
			},
		};
	},
	mounted() {
		this.initialize();
	},
	methods: {
		getPageText: ({ pageStart, pageStop, itemsLength }) => `${pageStart+1}-${pageStop} de ${itemsLength}`,
		colorDiasRestantes(dias) {
			if (dias > 5) return "green--text";
			else if (dias <= 5 && dias > 0) return "orange--text";
			return "red--text";
		},
		colorAvance(porcentaje) {
			if (porcentaje <= 0.0) return "red--text";
			else if (porcentaje < 100.0) return "orange--text";
			else return "green--text";
		},
		formatFechaCompromiso(fechaCompromiso) {
			return moment(fechaCompromiso).format("YYYY-MM-DD HH:mm");
		},
		showDialogDetalles(item) {
			this.editedItem = { ...item };
			this.dialogDetalles = true;
			this.resumenKey++;
		},
		showDialogEdit(item) {
			this.getTrabajoOrdenes(item.idTrabajoOrdenMadre);
			this.editedItem = { ...item };
			this.dialog = true;
		},
		validate() {
			this.errorClave = "";
			this.errorNombre = "";
			this.errorFecha = "";
			this.errorODTS = "";

			if (this.editedItem.clave == null) {
				this.errorClave = "Debe introducir una clave";
			} else if (this.editedItem.clave.length < 5) {
				this.errorClave = "La clave debe tener mínimo 5 caracteres";
			}

			if (this.editedItem.nombre == null) {
				this.errorNombre = "Debe introducir un nombre";
			} else if (this.editedItem.nombre.length < 5) {
				this.errorNombre = "El nombre debe tener mínimo 5 caracteres";
			}

			if (this.editedItem.fechaCompromiso == null) {
				this.errorFecha = "Debe indicar una fecha de compromiso";
			}

			if (this.editedItem.idsOrdenTrabajo.length == 0) {
				this.errorODTS = "Debe seleccionar al menos una ODT";
			}

			return (
				this.errorClave == "" &&
				this.errorNombre == "" &&
				this.errorFecha == "" &&
				this.errorODTS == ""
			);
		},
		save() {
			if (!this.validate()) return;

			this.saving = true;

			axios({
				url:
					this.editedItem.idTrabajoOrdenMadre == null
						? "/TrabajoOrdenMadres/Crear"
						: `/TrabajoOrdenMadres/ModificarOrdenTrabajoMadre/${this.editedItem.idTrabajoOrdenMadre}`,
				method:
					this.editedItem.idTrabajoOrdenMadre == null
						? "POST"
						: "PUT",
				data: this.editedItem,
			})
				.then(() => {
					this.saving = false;
					this.cerrarDialog();
					this.initialize();
				})
				.catch((error) => {
					this.saving = false;
					if (error.response) {
						if ((error.response.status = 409)) {
							this.errorClave = this.errorNombre = "La clave o nombre ya existe";
						}
					}
					console.log(error);
				});
		},
		cerrarDialog() {
			this.dialog = false;
			this.dialogDetalles = false;
			this.editedItem = { ...this.defaultEditedItem };
			this.errorClave = "";
			this.errorNombre = "";
			this.errorFecha = "";
			this.errorODTS = "";
		},
		showDialog() {
			this.getTrabajoOrdenes();
			this.editedItem = { ...this.defaultEditedItem };
			this.dialog = true;
		},
		resetErrorFecha() {
			if (this.editedItem.fechaCompromiso != null) {
				this.errorFecha = "";
			}
		},
		initialize() {
			this.getTrabajoOrdenMadres();
		},
		getTrabajoOrdenes(idTrabajoOrdenMadre = null) {
			this.loadingODTDisponibles = true;
			axios
				.get("/TrabajoOrdenMadres/ListaOdtDisponibles",{
					params: {
						IdOrdenTrabajo: idTrabajoOrdenMadre
					}
				})
				.then((response) => {
					this.ordenesTrabajo = response.data;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally( () => {
					this.loadingODTDisponibles = false;
				});
		},
		getTrabajoOrdenMadres() {
			this.loading = true;
			axios
				.get("/TrabajoOrdenMadres")
				.then((response) => {
					response.data.forEach((item) => {
						item.cliente = item.cliente.filter(
							(cliente) => cliente != null
						);
					});
					this.odtMadres = response.data;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style scoped>
.centered-input >>> input {
	text-align: center !important;
}

.footer-input {
  font-size: 12px !important;
  max-width: 40px !important;
}

.border-footer {
  border-top-width: thin;
  border-top-style: solid;
  border-top-color: rgba(0, 0, 0, 0.12);
}
</style>