<template>
	<v-file-input
		:value="formFiles"
		:accept="accept"
		:label="label"
		:disabled="disabled || processing"
		chips
		:multiple="multiple"
		:key="`formFiles-${key}`"
		:loading="processing"
		:clearable="false"
		truncate-length="40"
		:error-messages="errorMessages"
		@focus="$emit('focus')"
		@change="onChange"
	>
		<template v-slot:selection="{ file, text }">
			<v-chip
				:disabled="disabled || processing"
				:close="canDeleteFile(file)"
				@click.stop=""
				@click:close="deleteFile(file)"
			>
				{{ text }}
			</v-chip>
		</template>
	</v-file-input>
</template>

<script>
import axios from "axios";
export default {
	props: {
		value: {
			type: Array,
			default: () => [],
		},
		formFiles: {
			type: Array,
			default: () => [],
		},
		label: {
			type: String,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		accept: {
			type: String,
			default: ".pdf",
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		errorMessages: {
			type: [String | Array],
			default: () => []
		}
	},
	data() {
		return {
			uploading: false,
			deleting: false,
			key: 0,
		};
	},
	computed: {
		processing() {
			return this.uploading || this.deleting;
		},
	},
	watch: {
		processing() {
			this.$emit("update:processing", this.processing);
		},
	},
	methods: {
		canDeleteFile(file){
			if(file.idMMCallBuyTicket == null) return true;
			return file.idMMCallBuyTicket != null && !this.permisoVista('purchasingprocesses-buyer','u');
		},
		deleteFile(file){
			if(!file) return;
			this.deleting = true;
			const newFiles = this.formFiles.filter( (formFile) => formFile.idDocumento != file.idDocumento );
			const newIds = this.value.filter( (idDocumento) => idDocumento != file.idDocumento );
			this.$emit('input',newIds);
			this.$emit('update:formFiles',newFiles);
		},
		onClickClear() {
			if (this.formFiles.length == 0) return;
			this.deleting = true;
			this.$emit("update:formFiles", []);
			this.$emit("input", []);
			this.deleting = false;
		},
		async onChange(event) {
			if(this.deleting == true){
				this.deleting = false;
				return;
			}
			if (event == null || event?.length == 0) {
				this.key += 1;
				return;
			}

			this.uploading = true;

			let formData = new FormData();
			event.forEach((file) => {
				formData.append(`ficheros`, file);
			});
			
			axios
				.post(`/Documentos/CargarVariosArchivos`, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				})
				.then((response) => {
					const { newIds, newFiles } = response.data.reduce(
						(acc, file) => {
							acc.newIds.push(file.idDocumento);
							const newFile = new File([1], file.nombre);
							newFile.idDocumento = file.idDocumento;
							newFile.fichero = file.fichero;
							acc.newFiles.push(newFile);
							return acc;
						},
						{ newIds: [], newFiles: [] }
					);
					this.$emit("input", [...this.value, ...newIds]);
					this.$emit("update:formFiles", [...this.formFiles, ...newFiles]);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.uploading = false;
				});
		},
	},
};
</script>